import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions } from "..";
import {
  downloadDocumentsInZip,
  fetchAssetAndPackageDocuments,
  fetchDocumentsCounts,
  fetchProjectDocuments,
} from "services/documents/documents.service";
import { message } from "antd";
import { MESSAGES } from "constants/messages";

export const getProjectDocuments: SourceActionType = (
  subProjectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setDocumentsLoader(true));
    try {
      const response = await fetchProjectDocuments(subProjectId, params);
      dispatch(actions.getProjectDocuments(response));
      dispatch(actions.setDocumentsLoader(false));
    } catch (ex) {
      dispatch(actions.setDocumentsLoader(false));
    }
  };
};

export const getAssetDocuments: SourceActionType = (
  assetId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setDocumentsLoader(true));
    try {
      const response = await fetchAssetAndPackageDocuments(assetId, params);
      dispatch(actions.getAssetDocuments(response));
      dispatch(actions.setDocumentsLoader(false));
    } catch (ex) {
      dispatch(actions.setDocumentsLoader(false));
    }
  };
};

export const getAssetPackageDocuments: SourceActionType = (
  assetPackageId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setDocumentsLoader(true));
    try {
      const response = await fetchAssetAndPackageDocuments(
        assetPackageId,
        params,
      );
      dispatch(actions.getAssetPackageDocuments(response));
      dispatch(actions.setDocumentsLoader(false));
    } catch (ex) {
      dispatch(actions.setDocumentsLoader(false));
    }
  };
};

export const downloadDocumentInZipAction: SourceActionType = (
  s3Keys: string[],
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setDocumentsLoader(true));
      await downloadDocumentsInZip(s3Keys);
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setDocumentsLoader(false));
      message.warning(MESSAGES.DOCUMENTS.DOWNLOAD_LIMIT_REACHED);
    } finally {
      dispatch(actions.setDocumentsLoader(false));
    }
  };
};

export const getDocumentsCount: SourceActionType = (subProjectId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchDocumentsCounts(subProjectId);
      dispatch(actions.getDocumentsCount(response));
    } catch (ex) {}
  };
};

// Upload Documents Sources

export const setUploadedDocuments: SourceActionType = (documents: any[]) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setUploadedDocuments(documents));
  };
};

export const clearUploadedDocuments: SourceActionType = () => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.clearUploadedDocuments());
  };
};
