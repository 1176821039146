import { createSlice } from "@reduxjs/toolkit";

export const createActionAndReducers = <T extends {}>(
  reducerName: string,
  initialState: any,
  affects: any,
): { actions: T; reducer: any } => {
  const slice = createSlice({
    name: reducerName,
    initialState,
    reducers: {
      ...affects,
    },
  });
  const { actions, reducer } = slice;
  return {
    actions: actions as T,
    reducer,
  };
};
