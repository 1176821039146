import React from "react";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { displayDateTime, renderAmount } from "utils/helpers";
import AlectifyText from "static/texts.json";
import { IUser } from "redux/components/Auth";
import AvatarGroup from "components/shared/avatar-group";
import { ISparePartDraw } from "redux/components/spare-parts";
import { truncate } from "lodash";
import TextToLink from "components/shared/text-to-link";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { CloseOutlined } from "@ant-design/icons";
import AssetDetail from "components/shared/asset-detail";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { Tooltip } from "antd";

const useDrawHistoryColumns = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      width: 180,
      dataIndex: "createdAt",
      visible: true,
      ellipsis: true,
      title: AlectifyText.DATE,
      render: (created_at) => displayDateTime(created_at, true),
    },
    {
      width: 250,
      dataIndex: "activity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.ACTIVITY,
    },
    {
      width: 80,
      dataIndex: "quantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.QUANTITY,
      searchable: true,
    },
    {
      width: 150,
      dataIndex: ["manageOrder", "poNumber"],
      visible: true,
      ellipsis: true,
      title: AlectifyText.PO_NUMBER,
    },
    {
      width: 140,
      dataIndex: ["manageOrder", "unitPrice"],
      visible: true,
      ellipsis: true,
      title: AlectifyText.ORDER_UNIT_PRICE,
      render: (value) => (value ? renderAmount(value, "standard") : "-"),
    },
    {
      width: 140,
      dataIndex: "price",
      visible: true,
      ellipsis: true,
      title: AlectifyText.AVERAGE_UNIT_PRICE,
      render: (value) => renderAmount(value, "standard"),
    },
    {
      width: 100,
      dataIndex: ["asset", "name"],
      visible: true,
      ellipsis: true,
      title: AlectifyText.ASSET,
      searchable: true,
      render: (_, record: ISparePartDraw) => {
        const name = record.asset?.name || record.area?.name;
        return (
          <Tooltip title={name}>
            <TextToLink
              text={truncate(name, {
                length: 20,
                omission: "...",
              })}
              onClick={() => {
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: record?.asset?.id ? 500 : 900,
                  title: name,
                  name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () =>
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    }),
                  children: record?.asset?.id ? (
                    <AssetDetail
                      assetId={record.asset.id}
                      subProjectId={record.subProject.id}
                      isDetailButton
                    />
                  ) : (
                    <PackageDetailAssets
                      assetPackageId={record.area.id}
                      subProjectId={record.subProject.id}
                      isDetailsbutton
                    />
                  ),
                });
              }}
            />
          </Tooltip>
        );
      },
    },
    // {
    //   title: AlectifyText.ASSET_CATEGORY,
    //   dataIndex: "subProject",
    //   visible: true,
    //   ellipsis: true,
    //   width: 150,
    //   render: (masterProject: IMasterProject) => (
    //     <Tooltip placement={"topLeft"} title={masterProject?.name}>
    //       <TextToLink
    //         underline={false}
    //         text={truncate(masterProject?.name, {
    //           length: 20,
    //           omission: "...",
    //         })}
    //       />
    //     </Tooltip>
    //   ),
    // },
    // {
    //   title: AlectifyText.SITE,
    //   dataIndex: "project",
    //   visible: true,
    //   ellipsis: true,
    //   width: 150,
    //   render: (masterProject: IMasterProject) => (
    //     <Tooltip placement={"topLeft"} title={masterProject?.name}>
    //       <TextToLink
    //         underline={false}
    //         text={truncate(masterProject?.name, {
    //           length: 20,
    //           omission: "...",
    //         })}
    //       />
    //     </Tooltip>
    //   ),
    // },
    {
      title: AlectifyText.ACTIVITY_BY,
      dataIndex: "user",
      visible: true,
      ellipsis: true,
      align: "center",
      width: 130,
      render: (user: IUser) => <AvatarGroup users={[user]} maxCount={2} />,
    },
    {
      title: AlectifyText.COMMENTS,
      dataIndex: "comments",
      visible: true,
      ellipsis: true,
    },
  ];
  return React.useMemo(() => columns, []);
};

export default useDrawHistoryColumns;
