import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

interface IDocumentsIcon {
  stroke?: string | null;
}

const DocumentsIcon: React.FC<IDocumentsIcon> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
    >
      <path
        d="M19.6868 8.2V12.7C19.6868 17.2 17.8868 19 13.3868 19H7.98677C3.48677 19 1.68677 17.2 1.68677 12.7V7.3C1.68677 2.8 3.48677 1 7.98677 1H12.4868"
        stroke={props.stroke || PROJECT_THEME_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.6863 8.2H16.0863C13.3863 8.2 12.4863 7.3 12.4863 4.6V1L19.6863 8.2Z"
        stroke={props.stroke || PROJECT_THEME_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.18677 10.9001H11.5868"
        stroke={props.stroke || PROJECT_THEME_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.18677 14.5H9.78677"
        stroke={props.stroke || PROJECT_THEME_COLOR}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DocumentsIcon;
