export enum CalendarEventTypesEnum {
  TASKS = "TASK",
  PM_INTERNAL = "PM_INTERNAL",
  PM_EXTERNAL = "PM_EXTERNAL",
}

export enum CalendarEventStatusEnum {
  PENDING = "PENDING",
  DELAYED = "DELAYED",
  ON_TIME_COMPLETED = "ON_TIME_COMPLETED",
  DELAYED_COMPLETED = "DELAYED_COMPLETED",
  SKIPPED = "SKIPPED",
}

export enum CalendarActionEventsEnum {
  NEXT = "NEXT",
  DATE = "DATE",
  TODAY = "TODAY",
  PREVIOUS = "PREV",
}
