import { Breadcrumb, Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import "./contacts.styles.scss";
import ContactsTable from "./contacts-table/ContactsTable";

const Contacts: React.FC = () => {
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        className=""
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: AlectifyText.CONTACTS,
          },
        ]}
      />
      <div className="sub-project-tabs">
        <Col span={24}>
          <Row justify={"space-between"} align={"middle"}>
            <Col span={12}>
              <Typography.Title level={4} className="mt-10">
                {AlectifyText.CONTACTS}
              </Typography.Title>
            </Col>
          </Row>
        </Col>
        <ContactsTable />
      </div>
    </>
  );
};

export default Contacts;
