import { AxiosResponse } from "axios";
import { MULTIPART_HEADERS, http } from "utils/client";
import { IParams } from "redux/interfaces";

import {
  CREATE_PACKAGE,
  DELETE_PACKAGE,
  GET_ALL_PACKAGES,
  GET_ASSET_PACKAGE_BY_SEARCH,
  GET_ASSET_PACKAGE_DETAIL,
  GET_PACKAGES_DETAIL_ASSETS_URL,
  GET_PROJECT_ASSET_PACKAGES_URL,
  RESET_ORDER,
  UPDATE_PACKAGE,
  UPLOAD_ASSET_PACKAGE_IMAGE,
} from "./asset-packages.enpoints";
import { IAssetPackageResponse } from "redux/components/asset-packages";
import { IAssetsResponse } from "redux/components/assets";
import { IPackagePayload } from "components/shared/asset-packages-table/AssetPackages.interface";

/**
 * Fetches assets for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch asset packages for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project asset packages data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectAssetPackages = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IAssetPackageResponse> => {
  try {
    const url = GET_PROJECT_ASSET_PACKAGES_URL.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response: AxiosResponse<IAssetPackageResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches asset package detail for a given asset package ID.
 *
 * @param subProjectId - The ID of the sub project to fetch asset packages for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project asset packages data.
 * @throws If an error occurs during the request.
 */
export const fetchAssetPackageDetail = async (
  subProjectId: string,
  assetPackageId: string,
): Promise<IAssetPackageResponse> => {
  try {
    const url = GET_ASSET_PACKAGE_DETAIL.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetPackageId}", assetPackageId);
    const response: AxiosResponse<IAssetPackageResponse> = await http.get(url);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches assets for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch assets for.
 * @param assetPackageId - The ID of the asset package to fetch assets for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the package assets data.
 * @throws If an error occurs during the request.
 */
export const fetchPackageDetailAssets = async (
  subProjectId: string,
  assetPackageId: string,
  params?: IParams, // Make params optional
): Promise<IAssetsResponse> => {
  try {
    const url = GET_PACKAGES_DETAIL_ASSETS_URL.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{assetPackageId}", assetPackageId);
    const response: AxiosResponse<IAssetsResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches assets for a given sub project ID.
 *
 * @param subProjectId - The ID of the sub project to fetch asset packages for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project asset packages data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectAssetPackagesBySearch = async (
  subProjectId: string,
  params?: IParams, // Make params optional
): Promise<IAssetPackageResponse> => {
  try {
    const url = GET_ASSET_PACKAGE_BY_SEARCH.replace(
      "{subProjectId}",
      subProjectId,
    );
    const response: AxiosResponse<IAssetPackageResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetch all individual packages for a subProject.
 *
 * @param {string} subProjectId - The ID of the subProject.
 * @param {Object} [params] - Optional parameters for the request.
 * @returns {Promise<any>} - A Promise that resolves to the data from the HTTP response.
 */

export const getAllIndividualPackages = async (
  subProjectId: string,
  params?: Object, // Make params optional
) => {
  try {
    const response = await http.get(
      GET_ALL_PACKAGES.replace("{subProjectId}", subProjectId),
      params,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Create a package for a subProject.
 *
 * @param {string} subProjectId - The ID of the subProject.
 * @param {IPackagePayload} body - The payload containing data for creating the package.
 * @returns {Promise<any>} - A Promise that resolves to the data from the HTTP response.
 */
export const createPackage = async (
  subProjectId: string,
  body: IPackagePayload,
) => {
  try {
    const response = await http.post(
      CREATE_PACKAGE.replace("{subProjectId}", subProjectId),
      body,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Update a package for a subProject.
 *
 * @param {string} subProjectId - The ID of the subProject.
 * @param {IPackagePayload} body - The payload containing data for updating the package.
 * @returns {Promise<any>} - A Promise that resolves to the data from the HTTP response.
 */
export const updatePackage = async (
  subProjectId: string,
  packageId: string,
  body: IPackagePayload,
) => {
  try {
    const response = await http.patch(
      UPDATE_PACKAGE.replace("{subProjectId}", subProjectId).replace(
        "{packageId}",
        packageId,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Delete a package for a subProject.
 *
 * @param {string} subProjectId - The ID of the subProject.
 * @param {string} packageId - The ID of the package to be deleted.
 * @returns {Promise<any>} - A Promise that resolves to the data from the HTTP response.
 */
export const deletePackage = async (
  subProjectId: string,
  packageId: string,
) => {
  try {
    const response = await http.delete(
      DELETE_PACKAGE.replace("{subProjectId}", subProjectId).replace(
        "{packageId}",
        packageId,
      ),
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * Reset a package for a subProject.
 *
 * @param {string} subProjectId - The ID of the subProject.
 * @param {string | undefined} payload - Optional payload for the reset operation.
 * @returns {Promise<any>} - A Promise that resolves to the data from the HTTP response.
 */
export const restPackage = async (subProjectId: string, payload?: string) => {
  try {
    const response = await http.post(
      RESET_ORDER.replace("{subProjectId}", subProjectId),
      payload,
    );
    return response.data;
  } catch (ex) {
    console.log(ex);
  }
};

/**
 * add Asset Packages Image
 *
 * @returns A Promise that create the new contact.
 * @throws If an error occurs during the request.
 */
export const uploadAssetPackageImage = async (
  projectId: any,
  tagId: string,
  body: any,
): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.post(
      UPLOAD_ASSET_PACKAGE_IMAGE.replace("{projectid}", projectId).replace(
        "{tagid}",
        tagId,
      ),
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};
