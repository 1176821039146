interface Props {
  defaultBlack?: boolean;
}

const DrawColoredIcon: React.FC<Props> = (props: Props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="18"
      viewBox="0 0 20 18"
      fill="none"
    >
      <path
        d="M19.2108 12.7578H5.93468L6.60127 11.4001L17.677 11.38C18.0515 11.38 18.3725 11.1125 18.4394 10.7424L19.9732 2.15696C20.0134 1.93179 19.9532 1.69993 19.806 1.52381C19.7333 1.43713 19.6426 1.36731 19.5402 1.31919C19.4378 1.27107 19.3262 1.2458 19.213 1.24513L5.12318 1.19832L5.00279 0.632046C4.92699 0.270882 4.60149 0.0078125 4.23141 0.0078125H0.78698C0.57826 0.0078125 0.378089 0.0907262 0.230501 0.238314C0.0829137 0.385901 0 0.586073 0 0.794793C0 1.00351 0.0829137 1.20368 0.230501 1.35127C0.378089 1.49886 0.57826 1.58177 0.78698 1.58177H3.5938L4.11994 4.08317L5.41523 10.3545L3.74763 13.0766C3.66103 13.1935 3.60887 13.3323 3.59705 13.4772C3.58523 13.6222 3.61422 13.7676 3.68075 13.897C3.81451 14.1623 4.08427 14.3295 4.38301 14.3295H5.78308C5.4846 14.726 5.32338 15.2088 5.32382 15.7051C5.32382 16.9669 6.34935 17.9924 7.61119 17.9924C8.87304 17.9924 9.89856 16.9669 9.89856 15.7051C9.89856 15.2079 9.73359 14.7241 9.4393 14.3295H13.0309C12.7324 14.726 12.5712 15.2088 12.5716 15.7051C12.5716 16.9669 13.5971 17.9924 14.859 17.9924C16.1208 17.9924 17.1464 16.9669 17.1464 15.7051C17.1464 15.2079 16.9814 14.7241 16.6871 14.3295H19.213C19.6455 14.3295 20 13.9773 20 13.5425C19.9987 13.334 19.915 13.1345 19.7671 12.9875C19.6193 12.8405 19.4193 12.7579 19.2108 12.7578ZM5.4509 2.74998L18.2878 2.79234L17.0304 9.8328L6.94683 9.85064L5.4509 2.74998ZM7.61119 16.4096C7.22328 16.4096 6.9067 16.093 6.9067 15.7051C6.9067 15.3171 7.22328 15.0006 7.61119 15.0006C7.99911 15.0006 8.31568 15.3171 8.31568 15.7051C8.31568 15.8919 8.24146 16.0711 8.10934 16.2032C7.97723 16.3353 7.79803 16.4096 7.61119 16.4096ZM14.859 16.4096C14.4711 16.4096 14.1545 16.093 14.1545 15.7051C14.1545 15.3171 14.4711 15.0006 14.859 15.0006C15.2469 15.0006 15.5635 15.3171 15.5635 15.7051C15.5635 15.8919 15.4893 16.0711 15.3571 16.2032C15.225 16.3353 15.0458 16.4096 14.859 16.4096Z"
        fill={props?.defaultBlack ? "#141B34" : "#0954F1"}
      />
    </svg>
  );
};

export default DrawColoredIcon;
