import {
  Avatar,
  Badge,
  Button,
  Col,
  Divider,
  Dropdown,
  message,
  Row,
  Space,
  Tooltip,
} from "antd";
import { debounce, isEmpty, isNumber } from "lodash";
import {
  SettingOutlined,
  LogoutOutlined,
  DownOutlined,
} from "@ant-design/icons";
import type { MenuProps } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IRootState, resetAllState } from "redux/rootReducer";
import { headerDividerStyles, siteFilterStyle } from "./Layout.syles";
import { FEATURE_FLAGS, actions } from "redux/components/Auth";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import TodaysTasks from "components/shared/todays-tasks";
import AlectifyText from "static/texts.json";
import {
  isExternalUser,
  isFeatureEnabled,
  isTokenExpired,
} from "utils/helpers";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import { UserProfileForm } from "components/shared/user-profile-form";
import { useForm } from "antd/es/form/Form";
import { useLocation, useNavigate } from "react-router-dom";
import { ChatBotIcon, TodayTasksEmbedIcon } from "components/icons";
import { MaintenancePrediction } from "components/shared/maintenance-prediction";
import ChatBot from "components/chatbot-ui";
import { ROUTES } from "routes/Routes.constants";
import { deleteFirebaseToken, logoutRequest } from "services/auth/auth.service";
import BellIcon from "components/icons/BellIcon";
import NotificationsMain from "components/shared/notifications";
import { IPredictionState } from "components/shared/maintenance-prediction/MaintenancePrediction.interface";
import { useEffect, useState } from "react";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { fetchPredictionData } from "services/prediction/prediction.service";
import {
  actions as notificationActions,
  NotificationTypes,
} from "redux/components/notifications";
import ProjectFilter from "components/shared/project-filter/ProjectFilter";
import FortuneGlass from "components/icons/FortuneGlass";
import AlectifyInput from "components/shared/input";
import SearchIcon from "components/icons/SearchIcon";
import GlobalSearch from "components/shared/global-search";
import {
  getAssetAndPackage,
  resetGetAssetAndPkg,
} from "redux/components/global-search/sources";
import { IClickGlobalSearchEvent } from "components/shared/global-search/GlobalSearch.interface";
import { cancelApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
// import { AppEnvEnum, config } from "utils/configs";
import WorkOrderDueIcon from "components/icons/WorkOrderDueIcon";
import "./Header.scss";
import { clearLocalStorage, getFromLocal } from "utils/cache";
import OpenEnvelopIcon from "components/icons/OpenEnvelopIcon";
import ClosedEnvelopIcon from "components/icons/ClosedEnvelopIcon";

const MainHeader = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [FormInstance] = useForm();
  const user = useSelector((state: IRootState) => state.auth.user);
  const { unreadCounts, unReadSystemGenerated } = useSelector(
    (state: IRootState) => state.notifications,
  );
  const location = useLocation();
  const [isSystemDropdownOpen, setIsSystemDropdownOpen] = useState(false);
  const [isUserDropdownOpen, setIsUserDropdownOpen] = useState(false);
  const [globalSearchedValue, setGlobalSearchedValue] = useState<string>("");
  const [predictionState, setPredictionState] = useState<IPredictionState>({
    data: [],
    fetching: false,
    meta: {
      next: "",
      previous: "",
      total_pages: 0,
      total_count: 0,
    },
  });
  const [globalSearchIsVisible, setGlobalSearchVisible] =
    useState<boolean>(false);
  const [notificationType, setNotificationType] = useState<string>("global");

  const getPredictions = async (options?: IPagination) => {
    try {
      setPredictionState((prevState) => ({
        ...prevState,
        fetching: true,
      }));

      const params = {
        ...options,
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };

      const response = await fetchPredictionData(params);
      setPredictionState((prevState: any) => ({
        ...prevState,
        data: response.data,
        meta: response.meta,
        fetching: false,
      }));
    } catch (error) {
      setPredictionState((prevState) => ({
        ...prevState,
        fetching: false,
      }));
      console.log(error);
    }
  };

  const onProfileModalClose = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.USER_PROFILE_MODAL,
    });
  };
  const items: MenuProps["items"] = [
    {
      key: "1",
      label: <>Profile Settings</>,
      icon: <SettingOutlined />,
      onClick: () => {
        ModalServiceInstance.open(AlectifyModal, {
          title: AlectifyText.MY_PROFILE,
          name: MODAL_NAMES.USER_PROFILE_MODAL,
          width: 800,
          style: { top: 20 },
          okText: "Save Changes",
          onCancel: () => {
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.USER_PROFILE_MODAL,
            });
          },
          onOk: () => {
            FormInstance.submit();
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.USER_PROFILE_MODAL,
            });
          },
          children: (
            <div className="user-profile-modal-container">
              <UserProfileForm
                user={user}
                isEditing
                onCloseDrawer={onProfileModalClose}
                FormInstance={FormInstance}
              />
            </div>
          ),
        });
      },
    },
    {
      key: "2",
      label: <span>Logout</span>,
      icon: <LogoutOutlined />,
      onClick: async () => {
        try {
          message.loading("Logging out", 500);
          const refresh_token = getFromLocal("refresh_token");
          if (refresh_token && !isTokenExpired(refresh_token)) {
            await logoutRequest({
              refresh_token: refresh_token,
              fcm_token: user?.fcmToken || "",
            });
          }
        } catch (error) {}
        clearLocalStorage();
        dispatch(resetAllState() as any);
        dispatch(actions.logout());
        navigate(ROUTES.LOGIN);
        message.destroy();
      },
    },
  ];

  const onGlobalSearchChange = debounce(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const searchedValue = e.target.value;
      cancelApiRequests(SERVICE_UNIQUE_ID.UNIQUE_FETCH_GLOBAL_EQUIPMENTS);
      if (!isEmpty(searchedValue)) {
        setGlobalSearchVisible(true);
        dispatch(resetGetAssetAndPkg());
        const params = { page: 1, per_page: 10, search: searchedValue };
        setGlobalSearchedValue(searchedValue);
        dispatch(getAssetAndPackage(params));
      } else {
        dispatch(resetGetAssetAndPkg());
      }
    },
    800,
  );

  const onClickGlobalSearch = (e: IClickGlobalSearchEvent) => {
    e.stopPropagation();
    setGlobalSearchVisible(!globalSearchIsVisible);
    if (isEmpty(globalSearchedValue)) {
      dispatch(resetGetAssetAndPkg());
    }
  };

  useEffect(() => {
    getPredictions();
  }, []);

  return (
    <>
      <Row
        justify={"space-between"}
        align={"middle"}
        className="header-row"
        wrap={false}
      >
        <Col span={12}>
          <div className="d-flex align-items-center">
            {!isExternalUser(user) && location.pathname !== ROUTES?.ADMIN && (
              <div style={siteFilterStyle}>
                <ProjectFilter />
              </div>
            )}
            <div className="company-details-container">
              {user?.company?.image_url && (
                <img
                  alt="company-logo"
                  className="company-logo"
                  src={user?.company?.image_url}
                />
              )}
            </div>
          </div>
        </Col>

        <Col span={12} className="text-align-right">
          <div className="user-details-container">
            <Space size={10}>
              {!isExternalUser(user) && (
                <div className="global-search">
                  <AlectifyInput
                    name="Search"
                    type="text"
                    placeholder={AlectifyText.SEARCH_ASSET_PACKAGES}
                    onChange={onGlobalSearchChange}
                    prefix={<SearchIcon />}
                    className="global-search-input-wrapper"
                    onClick={(e) => {
                      onClickGlobalSearch(e);
                    }}
                    allowClear
                  />
                  {globalSearchIsVisible && (
                    <div className="global-search-result-body">
                      <GlobalSearch
                        globalSearchedValue={globalSearchedValue}
                        setGlobalSearchVisible={setGlobalSearchVisible}
                        globalSearchIsVisible={globalSearchIsVisible}
                      />
                    </div>
                  )}
                </div>
              )}
              {isFeatureEnabled(FEATURE_FLAGS.NOTIFICATION) && (
                <>
                  <div
                    className="bell-icon-container"
                    onClick={(e) => {
                      e?.stopPropagation();
                      setNotificationType("system");
                    }}
                  >
                    <Dropdown
                      overlayClassName="notification-overlayClass"
                      menu={{
                        items: [
                          {
                            key: "4",
                            label: (
                              <NotificationsMain
                                key={NotificationTypes.SYSTEM}
                                fullScreenActive={false}
                                type={notificationType as any}
                                setIsUserDropdownOpen={setIsUserDropdownOpen}
                              />
                            ),
                          },
                        ],
                      }}
                      trigger={["click"]}
                      placement="bottomRight"
                      // open={isUserDropdownOpen}
                      onOpenChange={(open) => setIsUserDropdownOpen(open)}
                    >
                      <Tooltip title={AlectifyText.USER_NOTIFICATIONS}>
                        <span
                          className="bell-icon-container"
                          // onClick={() => {
                          //   dispatch(
                          //     notificationActions.toggleNotificationDropdown(
                          //       false,
                          //     ),
                          //   );
                          //   dispatch(
                          //     notificationActions.toggleSystemNotificationDropdown(
                          //       !isSystemDropdownOpen,
                          //     ),
                          //   );
                          // }}
                        >
                          <Badge
                            count={isNumber(unreadCounts) ? unreadCounts : 0}
                            overflowCount={99}
                            offset={[4, -2]}
                            size="small"
                          >
                            {isUserDropdownOpen ? (
                              <OpenEnvelopIcon />
                            ) : (
                              <ClosedEnvelopIcon />
                            )}
                          </Badge>
                        </span>
                      </Tooltip>
                    </Dropdown>
                  </div>
                  <div
                    className="bell-icon-container"
                    onClick={(e) => {
                      e?.stopPropagation();
                      setNotificationType("global");
                    }}
                  >
                    <Dropdown
                      overlayClassName="notification-overlayClass"
                      menu={{
                        items: [
                          {
                            key: "1",
                            label: (
                              <NotificationsMain
                                setIsSystemDropdownOpen={
                                  setIsSystemDropdownOpen
                                }
                                key={NotificationTypes.GLOBAL}
                                fullScreenActive={false}
                                type={notificationType as any}
                              />
                            ),
                          },
                        ],
                      }}
                      trigger={["click"]}
                      placement="bottomRight"
                      // open={isSystemDropdownOpen}
                      onOpenChange={(open) => setIsSystemDropdownOpen(open)}
                    >
                      <Tooltip title={AlectifyText.SYSTEM_NOTIFICATIONS}>
                        <span
                          className="bell-icon-container"
                          // onClick={() => {
                          //   dispatch(
                          //     notificationActions.toggleSystemNotificationDropdown(
                          //       false,
                          //     ),
                          //   );
                          //   dispatch(
                          //     notificationActions.toggleNotificationDropdown(
                          //       !isDropdownOpen,
                          //     ),
                          //   );
                          // }}
                        >
                          <Badge
                            count={
                              isNumber(unReadSystemGenerated)
                                ? unReadSystemGenerated
                                : 0
                            }
                            overflowCount={99}
                            offset={[4, -2]}
                            size="small"
                          >
                            <BellIcon
                              className={
                                isSystemDropdownOpen ? "bell-rotated" : ""
                              }
                              fill={isSystemDropdownOpen ? " #0954f1" : "none"}
                            />
                          </Badge>
                        </span>
                      </Tooltip>
                    </Dropdown>
                  </div>
                </>
              )}
              {!isExternalUser(user) && (
                <>
                  {isFeatureEnabled(FEATURE_FLAGS.LLM_DOC_SEARCH) && (
                    <Tooltip title={AlectifyText.AI_BASED_SEARCHING}>
                      <ChatBotIcon
                        className="chatBot-icon"
                        onClick={() => {
                          DrawerServiceInstance.open(AlectifyDrawer, {
                            width: 500,
                            className: "chatbot",
                            title: "",
                            name: DRAWER_CONSTANTS.CHATBOT,
                            onClose: () =>
                              DrawerServiceInstance.close(AlectifyDrawer, {
                                name: DRAWER_CONSTANTS.CHATBOT,
                              }),
                            children: <ChatBot />,
                          });
                        }}
                      />
                    </Tooltip>
                  )}

                  {isFeatureEnabled(FEATURE_FLAGS.ML_BREAKDOWN_PREDICTION) && (
                    <FortuneGlass
                      isActive={!isEmpty(predictionState.data)}
                      onClick={() => {
                        DrawerServiceInstance.open(AlectifyDrawer, {
                          width: 1600,
                          showFooter: true,
                          cancelText: AlectifyText.CLOSE,
                          readOnly: true,
                          title: AlectifyText.BREAKDOWN_PREDICTION,
                          name: DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
                          onClose: () =>
                            DrawerServiceInstance.close(AlectifyDrawer, {
                              name: DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
                            }),
                          children: <MaintenancePrediction />,
                        });
                      }}
                    />
                  )}
                  {isFeatureEnabled(FEATURE_FLAGS.WORK_ORDER_DUE_TODAY) && (
                    <div
                      onClick={() => {
                        DrawerServiceInstance.open(AlectifyDrawer, {
                          width: 570,
                          title: AlectifyText.ALL_WORK_ORDERS_DUE_TODAY,
                          name: DRAWER_CONSTANTS.DRAWER_TODAY_TASK,
                          onClose: () =>
                            DrawerServiceInstance.close(AlectifyDrawer, {
                              name: DRAWER_CONSTANTS.DRAWER_TODAY_TASK,
                            }),
                          children: <TodaysTasks navigate={navigate} />,
                        });
                      }}
                      // Temp inline styling for hotfix
                      style={{
                        marginTop: 25,
                      }}
                      className="cursor-pointer"
                    >
                      <Tooltip title={AlectifyText.WORK_ORDER_NOTIFICATIONS}>
                        <WorkOrderDueIcon />
                      </Tooltip>
                    </div>
                  )}
                </>
              )}
            </Space>
            <Divider type="vertical" style={headerDividerStyles} />
            <div className=" avatar-container">
              <Avatar
                src={user?.image}
                className="mr-10 avatar-container"
                size={35}
              />
            </div>
            <Dropdown
              menu={{
                items: items.filter(
                  (item: any) => item.key !== "3" && item.key !== "4",
                ),
              }}
            >
              <Space>
                <Button className="current-user-btn" size="small">
                  <Space>
                    {user && `${user.first_name} ${user.last_name}`}
                    <DownOutlined />
                  </Space>
                </Button>
              </Space>
            </Dropdown>
          </div>
        </Col>
      </Row>
    </>
  );
};
export default MainHeader;
