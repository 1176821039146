import { Dispatch } from "react";
import {
  IPmInternalByDueDateResponse,
  IPmInternalResponse,
} from "../pm-internal.interface";
import {
  IGetPMByDueDateParams,
  IParams,
  SourceActionType,
} from "redux/interfaces";
import {
  fetchPmInternalPms,
  fetchPrevMaintenanceByDueDate,
  fetchPrevMaintenanceCounts,
  fetchPrevMaintenanceDetail,
  fetchPrevMaintenanceDocuments,
} from "services/pm-internal/pm-internal.service";
import { actions } from "..";
import { actions as PmExternalActions, PM_TYPES } from "../../pm-external";
import { actions as TaskActions } from "../../tasks";
import { IPmCountsResponse } from "services/pm-internal/pm-internal.interface";

export const fetchPmInternal: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  pmType: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setPmInternalLoader(true));
      const response: IPmInternalResponse = await fetchPmInternalPms(
        masterProjectId,
        subProjectId,
        pmType,
        params,
      );

      dispatch(actions.getPmInternal(response));
      dispatch(getPmsCounts(masterProjectId, subProjectId, params));
      dispatch(actions.setPmInternalLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setPmInternalLoader(false));
    }
  };
};

export const getPmsCounts: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response: IPmCountsResponse = await fetchPrevMaintenanceCounts(
        masterProjectId,
        subProjectId,
        params,
      );
      dispatch(actions.setPmInternalCounts(response.data.pmInternal));
      dispatch(PmExternalActions.setPmExternalCounts(response.data.pmExternal));
      dispatch(TaskActions.setTasksCounts(response.data.task));
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const getPmInternalAttachments: SourceActionType = (
  id: string,
  params?: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setPmInternalLoader(true));
      const response = await fetchPrevMaintenanceDocuments(id, params);
      dispatch(actions.getPmAttachments(response));
      dispatch(actions.setPmInternalLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setPmInternalLoader(false));
    }
  };
};

export const getPmDetail: SourceActionType = (recordId: string) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setPmInternalLoader(true));
      const response: IPmInternalResponse =
        await fetchPrevMaintenanceDetail(recordId);
      dispatch(actions.setPrevMaintenanceDetail(response));
      dispatch(actions.setPmInternalLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setPmInternalLoader(false));
    }
  };
};

export const getTodaysPrevMaintenances: SourceActionType = (
  params: IGetPMByDueDateParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response: IPmInternalByDueDateResponse =
        await fetchPrevMaintenanceByDueDate(params.startDate, params.endDate);
      const pmInternals = filterDataByPmType(
        PM_TYPES.PM_INTERNAL,
        response.data,
      );
      const pmExternals = filterDataByPmType(
        PM_TYPES.PM_EXTERNAL,
        response.data,
      );
      const tasks = filterDataByPmType(PM_TYPES.TASK, response.data);
      dispatch(actions.setTodayPmInternal(pmInternals));
      dispatch(TaskActions.setTodayTasks(tasks));
      dispatch(PmExternalActions.setTodayPmExternal(pmExternals));
    } catch (ex) {
      console.log(ex);
    }
  };
};

const filterDataByPmType = (pmType: PM_TYPES, data: any) => {
  return data.filter((pm: any) => pm.pmType === pmType);
};

export const deletePmAttachments: SourceActionType = (
  id: string,
  params?: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setPmInternalLoader(true));
      const response = await fetchPrevMaintenanceDocuments(id, params);
      dispatch(actions.getPmAttachments(response));
      dispatch(actions.setPmInternalLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setPmInternalLoader(false));
    }
  };
};
