import { createActionAndReducers } from "redux/utils/reducer.helper";

import { IpmExternalActions } from "./pm-external.interface";

import { reducerName } from "./pm-external.constants";

import pmExternalInitialState from "./pm-external.initialState";

import * as pmExternalEffects from "./affects";

export * from "./pm-external.interface";

export * from "./pm-external.constants";

export * from "./pm-external.enum";

const { actions, reducer } = createActionAndReducers<IpmExternalActions>(
  reducerName,
  pmExternalInitialState,
  pmExternalEffects,
);

export { actions, pmExternalInitialState, reducer };
