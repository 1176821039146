import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

const AlectifyBulletIcon: React.FC = () => {
  return (
    <svg
      width="9"
      height="8"
      viewBox="0 0 9 8"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        id="Ellipse 322"
        cx="4.04222"
        cy="4"
        rx="4.04222"
        ry="4"
        fill={PROJECT_THEME_COLOR}
      />
    </svg>
  );
};

export default AlectifyBulletIcon;
