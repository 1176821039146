import React from "react";
import {
  MessageOutlined,
  PaperClipOutlined,
  RightOutlined,
} from "@ant-design/icons";
import { Avatar, Col, Row, Space, Tag, Tooltip, Typography } from "antd";
import { PM_STATUS, TaskPriorityEnum, TaskStatusEnum } from "enums";
import AlectifyText from "static/texts.json";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import { PM_STATUS_COMPONENTS } from "../pm-internal-table/effects/usePmInternalColumns";
import {
  AssetPackageTag,
  AssetTag,
  NonRecurringTag,
  RecurringTag,
} from "../tags";
import {
  TASK_PROIRITY_COMPONENTS,
  TASK_STATUS_COMPONENTS,
} from "../tasks-table/effects/useTasksColumns";
import { ITodayTaskDetailsProps } from "./TodayTaskDetails.interface";
import "./TodayTasks.styles.scss";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { ROUTES } from "routes/Routes.constants";
import { PM_TYPES } from "redux/components/pm-external";

const TodayTaskDetails: React.FC<ITodayTaskDetailsProps> = (
  props: ITodayTaskDetailsProps,
) => {
  const closeTodaysTaskModal = () =>
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_TODAY_TASK,
    });

  const navigateToDetailsPage = (task: any) => {
    const isPM = true;

    const projectID = isPM
      ? task?.project?.id || task.pmInternal.project.id
      : task.project_id || task.task.projects[0].id;

    const subProjectID = isPM
      ? task?.subProject.id || task.pmInternal.subProject.id
      : task.subProjectId || task.sub_projects[0].id;

    const taskID = task.id;

    const navigateTo = isPM
      ? `${ROUTES.MY_ITEMS}/pm`
      : `${ROUTES.MY_ITEMS}/task`;

    props?.navigate &&
      props.navigate(
        `${navigateTo}/${projectID}/${subProjectID}/${taskID}${
          isPM ? `?pmType=${task?.pmType}` : ""
        }`,
      );

    closeTodaysTaskModal();
  };

  return (
    <div className="grey-card alectify-today-tasks-container">
      {props.tasks.map((task: any) => (
        <div className="body alectify-today-tasks-cards" key={task.id}>
          <Row className="mb-10">
            {/* <Col span={}>
              
            </Col> */}
            <Col span={23}>
              <Space>
                <Avatar src={NO_IMAGE_AVATAR} size={"large"} />
                <Space className="full-width" direction="vertical" size={0}>
                  <Typography.Title
                    className="value no-border no-margin cursor-pointer"
                    onClick={() => navigateToDetailsPage(task)}
                  >
                    {task?.pmType ? task?.workTitle || "-" : task?.name || "-"}
                  </Typography.Title>
                  <Typography.Text className="value no-margin no-padding">
                    {AlectifyText.WORK_ID}: {task?.wid || task?.workId}
                  </Typography.Text>
                </Space>
              </Space>
            </Col>
            {props.navigate && (
              <Col span={1}>
                <div
                  className="cursor-pointer"
                  onClick={() => navigateToDetailsPage(task)}
                >
                  <RightOutlined />
                </div>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={18}>
              <Space className="full-width" size={0}>
                {task?.pmType
                  ? PM_STATUS_COMPONENTS[task.status as PM_STATUS]
                  : TASK_STATUS_COMPONENTS[task.status as TaskStatusEnum]}
                {task?.pmType ? (
                  task?.isRecurring ? (
                    <RecurringTag />
                  ) : (
                    <NonRecurringTag />
                  )
                ) : (
                  TASK_PROIRITY_COMPONENTS[task.priorities as TaskPriorityEnum]
                )}
                {!props.hideEquipmentTag &&
                  (task?.asset?.name ? <AssetTag /> : <AssetPackageTag />)}
              </Space>
            </Col>
            <Col span={6} className="text-align-right">
              <Space size={0}>
                <Tooltip
                  placement="topLeft"
                  title={AlectifyText.MESSAGING_CENTER}
                >
                  <Tag
                    color="#eee"
                    className="round-tag"
                    style={{ color: "#555" }}
                  >
                    <Space size={2}>
                      <MessageOutlined className="icon-font-size-16" />
                      {task?.pmType
                        ? task?.comments || 0
                        : task?.comments_counts || 0}
                    </Space>
                  </Tag>
                </Tooltip>
                <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
                  <Tag
                    color="#eee"
                    className="round-tag"
                    style={{ color: "#555" }}
                  >
                    <Space size={2}>
                      <PaperClipOutlined className="icon-font-size-16" />
                      {task?.pmType
                        ? task?.documents || 0
                        : task?.documents_counts || 0}
                    </Space>
                  </Tag>
                </Tooltip>
              </Space>
            </Col>
          </Row>
        </div>
      ))}
    </div>
  );
};

export default TodayTaskDetails;
