import { memo } from "react";

interface IWarningIcon {
  height?: number | string;
  width?: number | string;
  fill?: string;
}

const WarningIcon: React.FC<IWarningIcon> = (props: any) => {
  return (
    <svg
      {...props}
      width={props.width || "70"}
      height={props.height || "62"}
      viewBox="0 0 70 62"
      style={{ verticalAlign: "middle" }}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="warning (1)">
        <path
          id="Path 641"
          d="M68.5578 46.4007L43.827 5.00963C42.9146 3.48227 41.6216 2.21775 40.0743 1.33968C38.5269 0.461598 36.7783 0 34.9992 0C33.2201 0 31.4714 0.461598 29.9241 1.33968C28.3767 2.21775 27.0837 3.48227 26.1714 5.00963L1.44167 46.4007C0.51206 47.9632 0.0146142 49.7448 0.000317122 51.5629C-0.01398 53.381 0.455385 55.1702 1.36031 56.7471C2.2469 58.3276 3.54121 59.6413 5.10831 60.5513C6.67542 61.4613 8.45793 61.9342 10.27 61.9209H59.7305C61.5426 61.9342 63.3251 61.4613 64.8922 60.5513C66.4593 59.6413 67.7537 58.3276 68.6402 56.7471C69.545 55.1701 70.0142 53.3809 69.9997 51.5628C69.9852 49.7447 69.4875 47.9632 68.5578 46.4007Z"
          fill={props.fill || "#E50027"}
        />
        <path
          id="Path 642"
          d="M68.6403 56.743C67.7537 58.3234 66.4594 59.6371 64.8923 60.5471C63.3252 61.4571 61.5426 61.9301 59.7305 61.9167H34.9998V1.31034e-05C36.7792 -0.00283137 38.5289 0.457474 40.0766 1.33568C41.6243 2.21388 42.9168 3.47974 43.827 5.00881L68.5578 46.3988C69.4872 47.961 69.9846 49.7421 69.9991 51.5597C70.0136 53.3774 69.5446 55.1662 68.6403 56.743Z"
          fill={props.fill || "#C1001F"}
        />
        <path
          id="Path 643"
          d="M10.2684 57.8123C9.18064 57.8279 8.10909 57.5475 7.16834 57.0011C6.22759 56.4548 5.45303 55.663 4.92748 54.7105C4.37555 53.759 4.09027 52.6763 4.10165 51.5764C4.11302 50.4765 4.42064 49.4 4.99213 48.4601L29.6995 7.11022C30.2501 6.19261 31.0295 5.43367 31.9615 4.90766C32.8934 4.38165 33.9459 4.10659 35.016 4.1094C36.0862 4.1122 37.1372 4.39279 38.0664 4.92368C38.9955 5.45458 39.771 6.2176 40.3167 7.13808L65.0286 48.4946C65.5911 49.4323 65.8915 50.5038 65.8983 51.5972C65.9052 52.6907 65.6183 53.7659 65.0676 54.7105C64.5418 55.6627 63.7672 56.4541 62.8264 57C61.8857 57.546 60.8142 57.826 59.7267 57.8101H10.2684V57.8123Z"
          fill={props.fill || "#FD003A"}
        />
        <path
          id="Path 644"
          d="M59.7326 57.8107C60.8203 57.8264 61.8917 57.5462 62.8325 57C63.7732 56.4539 64.5478 55.6623 65.0735 54.71C65.6241 53.7646 65.9105 52.6885 65.9029 51.5945C65.8952 50.5004 65.5938 49.4285 65.03 48.4908L40.3171 7.13538C39.7696 6.21342 38.9916 5.4497 38.0598 4.91918C37.1279 4.38867 36.0741 4.1096 35.0018 4.10938V57.8141L59.7326 57.8107Z"
          fill={props.fill || "#E50027"}
        />
        <path
          id="Path 645"
          d="M39.106 16.7525V33.1754C39.106 34.2644 38.6734 35.3088 37.9034 36.0788C37.1333 36.8488 36.0889 37.2814 35 37.2814C33.911 37.2814 32.8666 36.8488 32.0966 36.0788C31.3266 35.3088 30.894 34.2644 30.894 33.1754V16.7525C30.894 16.2133 31.0002 15.6794 31.2065 15.1812C31.4128 14.683 31.7153 14.2304 32.0966 13.8491C32.4779 13.4678 32.9305 13.1654 33.4287 12.959C33.9268 12.7527 34.4608 12.6465 35 12.6465C35.5392 12.6465 36.0731 12.7527 36.5713 12.959C37.0694 13.1654 37.5221 13.4678 37.9034 13.8491C38.2846 14.2304 38.5871 14.683 38.7934 15.1812C38.9998 15.6794 39.106 16.2133 39.106 16.7525Z"
          fill="#E1E4FB"
        />
        <path
          id="Path 646"
          d="M39.1058 16.7531V33.176C39.1011 34.2635 38.667 35.3052 37.898 36.0742C37.129 36.8432 36.0873 37.2773 34.9998 37.282V12.6504C36.0867 12.6551 37.1279 13.0887 37.8968 13.857C38.6657 14.6253 39.1002 15.6661 39.1058 16.7531Z"
          fill="#C5C9F7"
        />
        <path
          id="Path 647"
          d="M35.0002 41.3867C33.7821 41.3867 32.5913 41.7479 31.5784 42.4247C30.5656 43.1014 29.7762 44.0634 29.31 45.1888C28.8439 46.3142 28.7219 47.5526 28.9595 48.7473C29.1972 49.942 29.7838 51.0395 30.6451 51.9008C31.5065 52.7622 32.6039 53.3487 33.7986 53.5864C34.9934 53.824 36.2317 53.7021 37.3572 53.2359C38.4826 52.7698 39.4445 51.9803 40.1212 50.9675C40.798 49.9546 41.1592 48.7638 41.1592 47.5457C41.1604 46.7366 41.0019 45.9352 40.6928 45.1874C40.3837 44.4396 39.93 43.7602 39.3579 43.188C38.7857 42.6159 38.1063 42.1623 37.3585 41.8532C36.6108 41.544 35.8093 41.3856 35.0002 41.3867Z"
          fill="#E1E4FB"
        />
        <path
          id="Path 648"
          d="M41.1588 47.5413C41.1604 48.3507 41.0023 49.1525 40.6934 49.9006C40.3845 50.6488 39.9309 51.3286 39.3587 51.9011C38.7865 52.4736 38.1069 52.9276 37.3589 53.2369C36.6109 53.5462 35.8092 53.7048 34.9998 53.7036V41.3867C35.8086 41.3856 36.6096 41.5439 37.3571 41.8527C38.1046 42.1615 38.7839 42.6148 39.356 43.1864C39.928 43.7581 40.3817 44.437 40.6911 45.1843C41.0005 45.9316 41.1594 46.7325 41.1588 47.5413Z"
          fill="#C5C9F7"
        />
      </g>
    </svg>
  );
};

export default memo(WarningIcon);
