import { Space, Tag } from "antd";
import AlectifyText from "static/texts.json";

const MOPTag: React.FC = () => {
  return (
    <Tag color={"#DC8686"} className="round-tag">
      <Space size={2}>{AlectifyText.METHOD_OF_PROCEDURE}</Space>
    </Tag>
  );
};

export default MOPTag;
