export const WORK_ORDER_FORM_FIELD_TYPES = {
  PM_TRACKING: "pm_tracking",
  ASSETS: "assets",
  ASSET_PACKAGE: "area",
  ASSIGNEES: "assignees",
  APPROVERS: "approvers",
  DETAILS: "detail",
  TEAM_MEMBERS: "team_members",
  MASTER_PROJECT: "masterProject",
  SUB_PROJECT: "subProject",
  EQUIPMENT: "equipment",
  PREFERRED_SUPPLIER: "preferredSupplierName",
  WORK_TITLE: "workTitle",
  CONTRACT_END_DATE: "contractEndDate",
  DUE_DATE: "dueDate",
  ESTIMATED_HOURS: "estimatedHours",
  ESTIMATED_COST: "estimatedCost",
  RECURRING: "recurring",
  FREQUENCY: "frequency",
  FREQUENCY_DAY: "frequency_day",
  DAY_DATE: "dayType",
  DAY: "day",
  DATE: "date",
  WEEK: "week",
  CATEGORY: "category",
  TASK_CATEGORY: "taskCategory",
  FREQUENCY_TYPE: "frequencyType",
  TASK_PRIORITY: "priority",
  TEAM_MEMBERS_DROPDOWN: "teamMembersDropdown",
  TEAM_MEMBERS_LIST: "teamMembers",
  MEMBERS: "members",
  //need to change on backend
  workOrderType: "workOrderType",
};

export const WORK_ORDER_FREQUENCIES = [
  {
    value: 1,
    label: "1",
  },
  {
    value: 2,
    label: "2",
  },
  {
    value: 3,
    label: "3",
  },
  {
    value: 4,
    label: "4",
  },
  {
    value: 5,
    label: "5",
  },
  {
    value: 6,
    label: "6",
  },
  {
    value: 7,
    label: "7",
  },
  {
    value: 8,
    label: "8",
  },
  {
    value: 9,
    label: "9",
  },
  {
    value: 10,
    label: "10",
  },
  {
    value: 11,
    label: "11",
  },
  {
    value: 12,
    label: "12",
  },

  {
    value: 13,
    label: "13",
  },
  {
    value: 14,
    label: "14",
  },
  {
    value: 15,
    label: "15",
  },
  {
    value: 16,
    label: "16",
  },
  {
    value: 17,
    label: "17",
  },
  {
    value: 18,
    label: "18",
  },
  {
    value: 19,
    label: "19",
  },
  {
    value: 20,
    label: "20",
  },
  {
    value: 21,
    label: "21",
  },
  {
    value: 22,
    label: "22",
  },
  {
    value: 23,
    label: "23",
  },
  {
    value: 24,
    label: "24",
  },
];

export const WORK_ORDER_DATES = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },
  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
  {
    label: 11,
    value: 11,
  },
  {
    label: 12,
    value: 12,
  },
  {
    label: 13,
    value: 13,
  },
  {
    label: 14,
    value: 14,
  },
  {
    label: 15,
    value: 15,
  },
  {
    label: 16,
    value: 16,
  },
  {
    label: 17,
    value: 17,
  },
  {
    label: 18,
    value: 18,
  },
  {
    label: 19,
    value: 19,
  },
  {
    label: 20,
    value: 20,
  },
  {
    label: 21,
    value: 21,
  },
  {
    label: 22,
    value: 22,
  },
  {
    label: 23,
    value: 23,
  },
  {
    label: 24,
    value: 24,
  },
  {
    label: 25,
    value: 25,
  },
  {
    label: 26,
    value: 26,
  },
  {
    label: 27,
    value: 27,
  },
  {
    label: 28,
    value: 28,
  },
];
export const WORK_ORDER_DAYS_NAME = [
  { label: "Sunday", value: "sunday" },
  { label: "Monday", value: "monday" },
  { label: "Tuesday", value: "tuesday" },
  { label: "Wednesday", value: "wednesday" },
  { label: "Thursday", value: "thursday" },
  { label: "Friday", value: "friday" },
  { label: "Saturday", value: "saturday" },
];
export const WORK_ORDER_DAYS_NAME_SHORT = [
  { label: "Sun", value: "sunday" },
  { label: "Mon", value: "monday" },
  { label: "Tue", value: "tuesday" },
  { label: "Wed", value: "wednesday" },
  { label: "Thu", value: "thursday" },
  { label: "Fri", value: "friday" },
  { label: "Sat", value: "saturday" },
];
export const WORK_ORDER_WEEKS = [
  {
    label: "First",
    value: "first",
  },
  {
    label: "Second",
    value: "second",
  },
  {
    label: "Third",
    value: "third",
  },
  {
    label: "Last",
    value: "last",
  },
];

export const WORK_ORDER_EDITING_TYPES = {
  THIS_EVENT: "this_event",
  ALL_EVENTS: "all_events",
};
