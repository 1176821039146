import { memo } from "react";

const ContactUsIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="22"
      viewBox="0 0 23 22"
      fill="none"
    >
      <g clipPath="url(#clip0_5350_26050)">
        <path
          d="M20.8282 7.08827H19.7648C18.268 4.07526 15.1295 2 11.501 2C7.8726 2 4.73375 4.07526 3.23727 7.08827H2.17216C1.25257 7.08827 0.5 7.83134 0.5 8.73931V12.3137C0.5 13.2217 1.25257 13.9648 2.17216 13.9648H3.50476C3.63192 13.9648 3.75561 13.9501 3.87446 13.9234C3.53663 13.04 3.35091 12.0807 3.35091 11.0804C3.35091 9.7116 3.69706 8.42217 4.30723 7.29285C5.68002 4.75847 8.38541 3.03319 11.5007 3.03319C14.616 3.03319 17.3214 4.75847 18.6942 7.29285C19.3043 8.42183 19.6505 9.71126 19.6505 11.0797H19.6467C19.6467 12.0718 19.4658 13.0376 19.1089 13.9514C18.1027 16.5395 15.7868 18.4126 13.09 18.9412C12.9674 18.7831 12.7768 18.6794 12.561 18.6794H10.9092C10.5413 18.6794 10.2405 18.9767 10.2405 19.3397C10.2405 19.7027 10.5416 20 10.9092 20H12.561C12.9019 20 13.1819 19.7441 13.2217 19.4177C16.0695 18.8471 18.5123 16.8642 19.5756 14.1283C19.5968 14.0742 19.6151 14.0192 19.6352 13.9644H20.8278C21.7474 13.9644 22.5 13.2213 22.5 12.3134V8.73897C22.5 7.831 21.7474 7.08792 20.8278 7.08792L20.8282 7.08827Z"
          fill="#141B35"
        />
        <path
          d="M11.4997 4.24744C7.67797 4.24744 4.57966 7.30662 4.57966 11.0801C4.57966 12.7377 5.1777 14.2574 6.17212 15.4404C5.83464 16.531 5.19676 17.803 4.00171 18.375C3.83956 18.4469 3.63721 18.5016 3.63721 18.7202C3.63721 18.9912 3.95702 19.036 3.95702 19.036C3.95702 19.036 7.11423 19.3367 9.2486 17.541C9.95475 17.7808 10.7115 17.9128 11.5001 17.9128C15.3219 17.9128 18.4202 14.8537 18.4202 11.0801C18.4202 7.30662 15.3219 4.24744 11.5001 4.24744H11.4997ZM11.5541 16.5509C8.44094 16.5509 5.91711 14.0589 5.91711 10.985C5.91711 7.91114 8.44094 5.41918 11.5541 5.41918C14.6674 5.41918 17.1912 7.91114 17.1912 10.985C17.1912 14.0589 14.6674 16.5509 11.5541 16.5509Z"
          fill="#141B35"
        />
        <path
          d="M8.63852 12.0004C9.19908 12.0004 9.6535 11.5517 9.6535 10.9983C9.6535 10.4448 9.19908 9.99609 8.63852 9.99609C8.07796 9.99609 7.62354 10.4448 7.62354 10.9983C7.62354 11.5517 8.07796 12.0004 8.63852 12.0004Z"
          fill="#141B35"
        />
        <path
          d="M11.6356 12.0004C12.1962 12.0004 12.6506 11.5517 12.6506 10.9983C12.6506 10.4448 12.1962 9.99609 11.6356 9.99609C11.075 9.99609 10.6206 10.4448 10.6206 10.9983C10.6206 11.5517 11.075 12.0004 11.6356 12.0004Z"
          fill="#141B35"
        />
        <path
          d="M14.4852 12.0004C15.0458 12.0004 15.5002 11.5517 15.5002 10.9983C15.5002 10.4448 15.0458 9.99609 14.4852 9.99609C13.9246 9.99609 13.4702 10.4448 13.4702 10.9983C13.4702 11.5517 13.9246 12.0004 14.4852 12.0004Z"
          fill="#141B35"
        />
      </g>
      <defs>
        <clipPath id="clip0_5350_26050">
          <rect
            width="22"
            height="22"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default memo(ContactUsIcon);
