import React from "react";
import { ISVGToComponentProps } from "./icons.interface";

const SVGToComponent: React.FC<ISVGToComponentProps> = (
  props: ISVGToComponentProps,
) => {
  const { icon, height, width } = props;
  return (
    <img
      src={icon}
      height={height ? height : "20px"}
      width={width ? width : "20px"}
      alt="icon"
    />
  );
};

export default SVGToComponent;
