import { IReduxActionResponse } from "redux/interfaces";
import {
  IAssetPackage,
  IAssetPackageResponse,
} from "redux/components/asset-packages/asset-packages.interface";
import { assetPackagesInitialState } from "../asset-package.initialState";

export const getProjectAssetPackages = (
  state: any,
  action: IReduxActionResponse<IAssetPackageResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    data: payload?.data,
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const setAssetPackageLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
  };
};

export const setAssetPackageDetailLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    assetPackageDetail: {
      ...state.assetPackageDetail,
      fetching: payload,
    },
  };
};

export const setAssetPackageDetail = (
  state: any,
  action: IReduxActionResponse<IAssetPackage>,
) => {
  const { payload } = action;
  return {
    ...state,
    assetPackageDetail: {
      ...state.assetPackageDetail,
      data: payload,
    },
  };
};

export const resetAssetPackagesState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...assetPackagesInitialState,
  };
};
