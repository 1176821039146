import React from "react";
import { Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { ITeams } from "services/teams/teams.interface";
import EditIcon from "components/icons/EditIcon";
import DeleteIcon from "components/icons/DeleteIcon";
import AvatarGroup from "components/shared/avatar-group";
import { SyncOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";

const UseTeamsColumn = (props: any) => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.TEAM_NAME,
      dataIndex: "name",
      align: "left",
      visible: true,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: ITeams) => (
        <Tooltip placement="topLeft" title={value}>
          <span className={!record.is_active ? "disable-cell" : ""}>
            {value}
          </span>
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.TEAM_MEMBERS,
      dataIndex: "team_members",
      align: "left",
      visible: true,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (team_members, record: ITeams) =>
        team_members && (
          <span>
            <AvatarGroup users={team_members} maxCount={3} showImage />
          </span>
        ),
    },
    {
      title: AlectifyText.DESCRIPTION,
      dataIndex: "description",
      align: "left",
      visible: true,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: ITeams) => (
        <Tooltip placement="topLeft" title={value}>
          <span className={!record.is_active ? "disable-cell" : ""}>
            {value}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      align: "center",
      visible: isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT),
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: ITeams) => (
        <Space>
          <span
            className={`cursor-pointer ${
              !record.is_active ? "disable-cell" : ""
            }`}
            onClick={() => props.onEdit(record)}
          >
            <EditIcon />
          </span>
          <span
            className={`cursor-pointer ${
              !record.is_active ? "disable-cell" : ""
            }`}
            onClick={() => props.onDelete(record.id)}
          >
            <DeleteIcon />
          </span>
          {!record.is_active && (
            <Tooltip title={AlectifyText.RESTORE}>
              <span
                className="sync-active"
                onClick={() => props.onRestore(record.id)}
              >
                <SyncOutlined className="sync-icon" />
              </span>
            </Tooltip>
          )}
        </Space>
      ),
    },
  ];

  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};
export default UseTeamsColumn;
