import { FETCH_TIMELINE } from "./timeline.endpoints";
import { pmHttp } from "utils/client";

export interface IFetchTimelineParams {
  page: number;
  limit: number;
}

/**
 * Fetches timeline
 *
 * @param {IFetchTimelineParams} params - params to fetch timeline
 * @returns A Promise that gets the timeline data
 * @throws If an error occurs during the request.
 */
export const fetchTimeline = async (params: IFetchTimelineParams) => {
  try {
    const response = await pmHttp.get(FETCH_TIMELINE, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};
