import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./sub-project.constants";
import * as SubProjectAffects from "./affects";
import { subProjectInitialState } from "./sub-project.initialState";
import { ISubProjectActions } from "./sub-project.interface";

export * from "./sub-project.constants";
export * from "./sub-project.interface";

const { actions, reducer } = createActionAndReducers<ISubProjectActions>(
  reducerName,
  subProjectInitialState,
  SubProjectAffects,
);

export { actions, subProjectInitialState, reducer };
