import React from "react";

interface IEmptyIconProps {
  height?: string;
  width?: string;
}

const EmptyIcon: React.FC<IEmptyIconProps> = (props) => {
  return (
    <svg
      width={props.width || "104"}
      height={props.height || "133"}
      viewBox="0 0 104 133"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1212_14448)">
        <path
          d="M93.6042 60.1226C93.6042 60.1226 99.8882 59.7602 99.8882 53.7464V12.4545C99.8882 8.43379 101.349 4.5489 103.999 1.52246H71.7382C65.4312 1.52246 60.3174 6.63059 60.3174 12.9332V26.5706C60.3174 26.5706 63.3119 60.1238 76.2035 60.1238"
          fill="#EFEFF4"
        />
        <g opacity="0.26">
          <path
            d="M74.0085 22.979C73.9613 22.9226 73.9417 22.8582 73.9486 22.7845L73.9993 22.2552C74.0534 21.696 74.2101 21.2241 74.4692 20.841C74.7295 20.4578 75.0255 20.1517 75.3595 19.9238C75.6935 19.696 76.1278 19.4371 76.661 19.1459C77.2542 18.8467 77.693 18.5763 77.9763 18.3358C78.2597 18.0953 78.4186 17.7915 78.4543 17.4233C78.4923 17.0263 78.3679 16.6983 78.08 16.4405C77.792 16.1828 77.3912 16.0297 76.8753 15.9802C76.3155 15.9262 75.8571 16.0125 75.5012 16.2392C75.1453 16.4647 74.9472 16.7846 74.9081 17.1966L74.885 17.4394C74.87 17.6016 74.7733 17.6741 74.5971 17.6569L71.4712 17.2242C71.3975 17.2173 71.3376 17.1816 71.2915 17.1183C71.2455 17.055 71.2259 16.986 71.2328 16.9123L71.2397 16.8456C71.3284 15.9181 71.6428 15.1206 72.183 14.4509C72.7232 13.7823 73.435 13.2898 74.3207 12.9734C75.2064 12.6569 76.1934 12.551 77.2841 12.6558C78.3599 12.7582 79.2813 13.0332 80.0483 13.4774C80.8154 13.9227 81.3844 14.5004 81.7552 15.2115C82.1249 15.9227 82.2689 16.7202 82.1837 17.6039C82.1203 18.2668 81.9476 18.818 81.6677 19.2587C81.3878 19.6994 81.0676 20.0515 80.7083 20.3139C80.3489 20.5763 79.894 20.8525 79.3469 21.1413C78.7987 21.4313 78.3875 21.6925 78.1122 21.9249C77.837 22.1585 77.6815 22.46 77.6469 22.8271L77.617 23.1367C77.6101 23.2103 77.5778 23.2702 77.5214 23.3173C77.4649 23.3645 77.3993 23.3841 77.3256 23.3772L74.1871 23.0768C74.1133 23.0699 74.0534 23.0377 74.0062 22.9813L74.0085 22.979ZM73.8242 27.6855C73.4971 27.2908 73.3612 26.8133 73.4154 26.254C73.4707 25.6798 73.6918 25.2333 74.0788 24.9134C74.4669 24.5935 74.9392 24.4612 75.5001 24.5153C76.0598 24.5693 76.5032 24.7891 76.8303 25.1769C77.1574 25.5647 77.2933 26.0457 77.238 26.62C77.1862 27.1643 76.9616 27.6038 76.5654 27.9376C76.1692 28.2713 75.6912 28.4105 75.1315 28.3576C74.5867 28.3058 74.1502 28.0814 73.8242 27.6867V27.6855Z"
            fill="#A5AAC4"
          />
        </g>
        <path
          opacity="0.33"
          d="M63.0985 26.5694V12.932C63.0985 6.63052 68.2112 1.52124 74.5193 1.52124H71.7401C65.4331 1.52124 60.3193 6.62937 60.3193 12.932V26.5694C60.3193 26.5694 63.3139 60.1226 76.2055 60.1226H78.9847C66.0931 60.1226 63.0985 26.5694 63.0985 26.5694Z"
          fill="#C8C8DB"
        />
        <path
          d="M76.2107 60.1226H93.6091C93.6091 60.1226 86.5615 60.1226 86.5615 53.851V32.1895C86.5615 30.5072 85.8762 28.8685 84.5908 27.7799C84.0069 27.2851 83.2767 26.8685 82.3771 26.6764C79.2939 26.0158 76.2107 28.6568 76.2107 28.6568L72.1357 35.6981L76.2107 60.1226Z"
          fill="#C8C8DB"
        />
        <path
          d="M81.5442 26.5716C81.5442 26.5716 76.1113 26.2782 76.1113 32.659V125.14C76.1113 129.29 72.8887 132.815 68.7389 132.99C68.5927 132.995 68.4441 132.999 68.2932 132.999H13.2324V41.5519C13.2324 33.277 19.946 26.5693 28.2283 26.5693H81.543L81.5442 26.5716Z"
          fill="#EFEFF4"
        />
        <g opacity="0.33">
          <path
            d="M81.5412 26.5716C81.5309 26.5716 81.448 26.567 81.3086 26.5716H81.5412Z"
            fill="#C8C8DB"
          />
          <path
            d="M31.521 26.5706H28.2292C19.947 26.5706 13.2334 33.2782 13.2334 41.552V133H16.5332V41.552C16.5332 33.2805 23.2433 26.574 31.521 26.5706Z"
            fill="#C8C8DB"
          />
        </g>
        <path
          d="M68.6223 133C68.6223 133 63.4095 132.046 63.4095 127.352V119.77H0.201172V124.235C0.201172 129.076 4.12866 133 8.97409 133H68.6223Z"
          fill="#C8C8DB"
        />
        <path
          d="M92.2131 113.838C89.4316 115.983 85.1021 114.763 83.0981 111.274L80.6287 106.976L78.9046 103.972L74.142 95.6747L73.7539 94.9992L76.0551 93.2225L78.6397 91.2283L82.0707 94.5976L85.6607 98.1235L85.6677 98.1269L88.7659 101.168L92.0346 104.375C94.9093 107.197 94.9946 111.692 92.2131 113.837V113.838Z"
          fill="#767D9B"
        />
        <g opacity="0.2">
          <path
            d="M92.2126 113.838C91.92 114.067 91.6056 114.256 91.282 114.408C93.2699 112.092 92.9428 108.198 90.3548 105.66L76.9668 92.5194L78.6391 91.2283L92.0341 104.377C94.9088 107.198 94.9941 111.693 92.2126 113.838Z"
            fill="#596291"
          />
        </g>
        <g>
          <path
            d="M88.7668 101.169C88.7668 101.169 86.674 106.023 80.6296 106.976L78.9043 103.971C78.9043 103.971 84.4143 102.666 85.6616 98.1245L85.6686 98.128L88.7668 101.169Z"
            fill="#B8BDE0"
          />
        </g>
        <g opacity="0.17">
          <path
            d="M81.772 94.3042C77.1661 96.5562 74.1416 95.6736 74.1416 95.6736L76.0547 93.2225L78.6392 91.2283L81.7455 94.28L81.772 94.3042Z"
            fill="#596291"
          />
        </g>
        <path
          d="M63.3786 61.1146C53.3687 62.6106 46.4686 71.9304 47.9659 81.9315C49.4631 91.9325 58.7912 98.8266 68.8011 97.3306C78.811 95.8347 85.7112 86.5148 84.2139 76.5138C82.7166 66.5127 73.3885 59.6186 63.3786 61.1146ZM68.4498 94.9831C59.7379 96.2846 51.6192 90.2847 50.3154 81.5805C49.0117 72.8763 55.0181 64.7647 63.7299 63.4621C72.4418 62.1606 80.5605 68.1606 81.8643 76.8648C83.1669 85.5689 77.1617 93.6805 68.4498 94.9831Z"
          fill="#838AB5"
        />
        <path
          d="M66.0896 93.3766C73.9142 93.3766 80.2573 87.0391 80.2573 79.2214C80.2573 71.4037 73.9142 65.0662 66.0896 65.0662C58.265 65.0662 51.9219 71.4037 51.9219 79.2214C51.9219 87.0391 58.265 93.3766 66.0896 93.3766Z"
          fill="#EAEBF0"
        />
        <g opacity="0.52">
          <path
            d="M59.7188 70.188C59.4504 70.2283 59.1717 70.1155 59.0116 69.875C58.7904 69.5436 58.8814 69.096 59.212 68.8762C60.7346 67.8647 62.4288 67.2134 64.2509 66.9418C64.6448 66.8831 65.0122 67.1547 65.071 67.5483C65.1297 67.9418 64.8579 68.3089 64.464 68.3676C62.8538 68.6081 61.3554 69.1834 60.0101 70.0764C59.9192 70.1374 59.819 70.1742 59.7176 70.1892L59.7188 70.188Z"
            fill="white"
          />
        </g>
        <g opacity="0.52">
          <path
            d="M54.6178 81.6656C54.2239 81.7243 53.8565 81.4528 53.7977 81.0592C53.458 78.7911 53.7505 76.4827 54.642 74.3815C55.2017 73.0627 55.978 71.8602 56.9512 70.805C57.2219 70.5115 57.678 70.4943 57.9705 70.7624C58.2631 71.0317 58.2827 71.4874 58.0132 71.7808C57.1528 72.7141 56.4664 73.7785 55.9711 74.9442C55.1833 76.8003 54.9253 78.8406 55.2248 80.8452C55.2835 81.2387 55.0117 81.6058 54.6178 81.6645V81.6656Z"
            fill="white"
          />
        </g>
        <path
          d="M57.4745 81.1697C57.1958 81.2111 56.9286 81.0304 56.8664 80.7508C56.8019 80.4597 56.9862 80.172 57.2776 80.1075C57.4146 80.0765 60.5566 79.3285 60.7397 76.0385C60.7559 75.7405 61.0139 75.5115 61.3099 75.5287C61.6082 75.5449 61.8362 75.8003 61.8201 76.0984C61.5909 80.2203 57.5528 81.1536 57.5114 81.1616C57.4987 81.1651 57.4872 81.1662 57.4745 81.1685V81.1697Z"
          fill="#8F90A2"
        />
        <path
          d="M72.6572 74.2213C70.1337 74.5988 68.1861 72.9072 68.1619 72.8853C67.9385 72.6874 67.9189 72.3456 68.117 72.1224C68.3151 71.8992 68.656 71.8796 68.8795 72.0764C68.982 72.1673 71.4352 74.272 74.2086 72.486C74.4597 72.3238 74.7949 72.3963 74.9561 72.6471C75.1185 72.898 75.046 73.2329 74.7949 73.394C74.0589 73.8681 73.3368 74.1189 72.6584 74.2213H72.6572Z"
          fill="#8F90A2"
        />
        <path
          d="M62.7599 83.6564C61.235 83.8843 59.8345 83.4194 59.7573 83.3929C59.3807 83.2652 59.1791 82.8555 59.307 82.4792C59.4348 82.103 59.8437 81.9016 60.2203 82.0282C60.2399 82.0351 62.1829 82.6691 63.5846 81.8947C64.271 81.5149 64.7179 80.8613 64.9506 79.8958C65.0439 79.5092 65.4332 79.271 65.8202 79.3642C66.2071 79.4574 66.4456 79.8464 66.3523 80.233C66.0229 81.5978 65.3237 82.5817 64.2733 83.1593C63.7839 83.4286 63.2656 83.5805 62.7599 83.6553V83.6564Z"
          fill="#8F90A2"
        />
        <path
          d="M71.5162 79.9165C69.9913 80.1443 68.5908 79.6794 68.5136 79.6529C68.137 79.5252 67.9343 79.1155 68.0633 78.7393C68.1911 78.363 68.6 78.1616 68.9766 78.2882C69.0054 78.2974 70.9438 78.9268 72.3409 78.1547C73.0274 77.7749 73.4742 77.1213 73.7069 76.1558C73.8002 75.7692 74.1895 75.531 74.5765 75.6242C74.9634 75.7174 75.2019 76.1064 75.1086 76.493C74.7792 77.8578 74.0801 78.8417 73.0297 79.4193C72.5402 79.6886 72.0219 79.8405 71.5162 79.9153V79.9165Z"
          fill="#8F90A2"
        />
        <path
          d="M67.292 88.3031C67.1895 88.3181 67.0812 88.3123 66.9752 88.279C66.5963 88.1627 66.3821 87.7646 66.4949 87.386C66.9096 86.0028 68.7397 83.4896 72.6868 83.8785C73.083 83.9177 73.3721 84.2709 73.3329 84.6668C73.2938 85.0627 72.939 85.3515 72.544 85.3124C68.818 84.943 67.9116 87.6875 67.8736 87.8037C67.7884 88.0753 67.5546 88.2628 67.2897 88.3031H67.292Z"
          fill="#8F90A2"
        />
        <g opacity="0.14">
          <path
            d="M36.788 84.3295C36.6406 84.1522 36.5796 83.9497 36.6014 83.7184L36.7604 82.0568C36.9285 80.303 37.4192 78.8255 38.2346 77.6218C39.0489 76.4193 39.9807 75.4607 41.0276 74.745C42.0746 74.0315 43.4348 73.2179 45.1071 72.3065C46.9684 71.3664 48.3424 70.5206 49.2316 69.7669C50.1184 69.0131 50.6183 68.0603 50.73 66.9061C50.8498 65.6599 50.4582 64.6334 49.5575 63.8245C48.6557 63.0166 47.3968 62.5345 45.7809 62.3803C44.0245 62.2123 42.5894 62.4827 41.4734 63.1904C40.3562 63.8993 39.7365 64.8993 39.6121 66.1904L39.5396 66.9522C39.4912 67.4608 39.1894 67.6875 38.6354 67.6334L28.8375 66.2767C28.606 66.2548 28.4182 66.1432 28.2754 65.943C28.1303 65.7439 28.0704 65.5287 28.0923 65.2963L28.1119 65.0891C28.3906 62.1824 29.3753 59.6807 31.0684 57.5829C32.7603 55.4862 34.9947 53.9419 37.7705 52.95C40.545 51.9581 43.6421 51.6266 47.0605 51.9534C50.4317 52.2757 53.3203 53.1353 55.7275 54.5299C58.1335 55.9246 59.9176 57.7371 61.0785 59.9672C62.2395 62.1973 62.6875 64.6967 62.4226 67.4665C62.2234 69.5436 61.6844 71.2732 60.8067 72.6564C59.9279 74.0384 58.9247 75.1431 57.7972 75.9659C56.6696 76.7898 55.246 77.6552 53.5276 78.5608C51.8081 79.4687 50.5181 80.2881 49.6554 81.0199C48.7916 81.753 48.3056 82.6966 48.1962 83.8496L48.1029 84.8185C48.081 85.0498 47.9808 85.2385 47.8046 85.3835C47.626 85.5308 47.4233 85.5918 47.1918 85.57L37.3535 84.6287C37.122 84.6068 36.9331 84.5078 36.788 84.3295ZM36.2121 99.0842C35.1882 97.8449 34.7609 96.3489 34.9291 94.5952C35.1019 92.7954 35.7952 91.395 37.0103 90.3939C38.2254 89.3927 39.7089 88.9761 41.4653 89.1441C43.2206 89.3122 44.6096 90.0038 45.6358 91.2201C46.6609 92.4353 47.0882 93.9439 46.9154 95.7436C46.7519 97.4513 46.0481 98.8276 44.8065 99.8725C43.5638 100.919 42.0654 101.357 40.3112 101.189C38.602 101.026 37.2361 100.325 36.2121 99.0854V99.0842Z"
            fill="#A5AAC4"
          />
        </g>
        <path
          d="M90.4158 72.2179C90.3859 72.2179 90.3617 72.1938 90.3617 72.1638C90.3617 69.8888 88.5097 68.0385 86.2338 68.0385C86.2039 68.0385 86.1797 68.0143 86.1797 67.9844C86.1797 67.9544 86.2039 67.9303 86.2338 67.9303C88.5097 67.9303 90.3617 66.0799 90.3617 63.806C90.3617 63.7761 90.3859 63.752 90.4158 63.752C90.4458 63.752 90.47 63.7761 90.47 63.806C90.47 66.0799 92.322 67.9303 94.599 67.9303C94.629 67.9303 94.6531 67.9544 94.6531 67.9844C94.6531 68.0143 94.629 68.0385 94.599 68.0385C92.322 68.0385 90.47 69.8888 90.47 72.1638C90.47 72.1938 90.4458 72.2179 90.4158 72.2179ZM86.9099 67.9855C88.7055 68.2744 90.1268 69.6944 90.4158 71.4895C90.7049 69.6955 92.1262 68.2744 93.9229 67.9855C92.1273 67.6967 90.7049 66.2767 90.4158 64.4827C90.1268 66.2767 88.7055 67.6967 86.9099 67.9855Z"
          fill="#767D9B"
        />
        <path
          d="M90.4174 62.5045C90.3875 62.5045 90.3633 62.4804 90.3633 62.4504V61.4125C90.3633 61.3826 90.3875 61.3584 90.4174 61.3584C90.4474 61.3584 90.4715 61.3826 90.4715 61.4125V62.4504C90.4715 62.4804 90.4474 62.5045 90.4174 62.5045Z"
          fill="#767D9B"
        />
        <path
          d="M83.8891 68.0441C83.8591 68.0441 83.835 68.0199 83.835 67.9912C83.835 67.9612 83.8591 67.9371 83.8879 67.9371L84.9268 67.9302C84.9568 67.9302 84.981 67.9543 84.981 67.9831C84.981 68.013 84.9568 68.0372 84.928 68.0372L83.8891 68.0441Z"
          fill="#767D9B"
        />
        <path
          d="M90.5121 74.5251C90.4822 74.5251 90.4591 74.5009 90.458 74.4722L90.4453 73.4342C90.4453 73.4043 90.4683 73.3801 90.4983 73.3801C90.5282 73.3801 90.5513 73.4043 90.5524 73.4331L90.5651 74.471C90.5651 74.5009 90.5421 74.5251 90.5121 74.5251Z"
          fill="#767D9B"
        />
        <path
          d="M95.9194 67.8877C95.8906 67.8877 95.8664 67.8647 95.8652 67.8348C95.8652 67.8049 95.8883 67.7807 95.9182 67.7796L96.9559 67.76C96.987 67.76 97.0101 67.783 97.0112 67.8129C97.0112 67.8429 96.9882 67.867 96.9582 67.8682L95.9205 67.8877H95.9194Z"
          fill="#767D9B"
        />
        <path
          d="M10.0754 33.1148C10.0293 33.1148 9.99245 33.078 9.99245 33.032C9.99245 29.5498 7.15683 26.7167 3.67277 26.7167C3.6267 26.7167 3.58984 26.6799 3.58984 26.6339C3.58984 26.5878 3.6267 26.551 3.67277 26.551C7.15798 26.551 9.99245 23.7179 9.99245 20.2369C9.99245 20.1909 10.0293 20.1541 10.0754 20.1541C10.1214 20.1541 10.1583 20.1909 10.1583 20.2369C10.1583 23.719 12.9939 26.551 16.4791 26.551C16.5252 26.551 16.5621 26.5878 16.5621 26.6339C16.5621 26.6799 16.5252 26.7167 16.4791 26.7167C12.9939 26.7167 10.1583 29.5498 10.1583 33.032C10.1583 33.078 10.1214 33.1148 10.0754 33.1148ZM4.70705 26.635C7.45628 27.0769 9.63195 29.2518 10.0754 31.9986C10.5176 29.2518 12.6945 27.0769 15.4437 26.635C12.6945 26.1931 10.5176 24.0182 10.0754 21.2714C9.6331 24.0182 7.45628 26.192 4.70705 26.635Z"
          fill="#767D9B"
        />
        <path
          d="M10.0771 18.2449C10.031 18.2449 9.99414 18.2081 9.99414 18.1621V16.5741C9.99414 16.528 10.031 16.4912 10.0771 16.4912C10.1231 16.4912 10.16 16.528 10.16 16.5741V18.1621C10.16 18.2081 10.1231 18.2449 10.0771 18.2449Z"
          fill="#767D9B"
        />
        <path
          d="M0.0829263 26.7247C0.0380079 26.7247 0.00115175 26.6879 0 26.643C0 26.597 0.0368562 26.5602 0.0817746 26.5602L1.6712 26.5498C1.71611 26.5498 1.75297 26.5866 1.75412 26.6315C1.75412 26.6775 1.71727 26.7144 1.67235 26.7144L0.0829263 26.7247Z"
          fill="#767D9B"
        />
        <path
          d="M10.2216 36.6475C10.1767 36.6475 10.1399 36.6119 10.1387 36.5658L10.1191 34.9778C10.1191 34.9318 10.1548 34.8949 10.2009 34.8938C10.2458 34.8938 10.2827 34.9295 10.2838 34.9755L10.3034 36.5635C10.3034 36.6095 10.2677 36.6464 10.2216 36.6475Z"
          fill="#767D9B"
        />
        <path
          d="M18.5009 26.4855C18.456 26.4855 18.4191 26.4498 18.418 26.4049C18.418 26.3589 18.4537 26.3221 18.4986 26.3209L20.088 26.291C20.1352 26.291 20.1709 26.3267 20.1721 26.3716C20.1721 26.4176 20.1364 26.4544 20.0915 26.4556L18.502 26.4855H18.5009Z"
          fill="#767D9B"
        />
        <path
          d="M17.9894 16.6236C17.9434 16.6236 17.9065 16.5868 17.9065 16.5408C17.9065 13.0586 15.0709 10.2255 11.5868 10.2255C11.5408 10.2255 11.5039 10.1887 11.5039 10.1426C11.5039 10.0966 11.5408 10.0598 11.5868 10.0598C15.072 10.0598 17.9065 7.22668 17.9065 3.74569C17.9065 3.69967 17.9434 3.66284 17.9894 3.66284C18.0355 3.66284 18.0724 3.69967 18.0724 3.74569C18.0724 7.22783 20.908 10.0598 24.3932 10.0598C24.4393 10.0598 24.4761 10.0966 24.4761 10.1426C24.4761 10.1887 24.4393 10.2255 24.3932 10.2255C20.908 10.2255 18.0724 13.0586 18.0724 16.5408C18.0724 16.5868 18.0355 16.6236 17.9894 16.6236ZM12.6211 10.1438C15.3703 10.5857 17.546 12.7606 17.9894 15.5074C18.4317 12.7606 20.6085 10.5857 23.3578 10.1438C20.6085 9.70191 18.4317 7.52702 17.9894 4.78021C17.5472 7.52702 15.3703 9.70076 12.6211 10.1438Z"
          fill="#767D9B"
        />
        <path
          d="M17.9911 1.75372C17.9451 1.75372 17.9082 1.7169 17.9082 1.67087V0.0828531C17.9082 0.0368236 17.9451 0 17.9911 0C18.0372 0 18.0741 0.0368236 18.0741 0.0828531V1.67087C18.0741 1.7169 18.0372 1.75372 17.9911 1.75372Z"
          fill="#767D9B"
        />
        <path
          d="M7.99699 10.2335C7.95207 10.2335 7.91521 10.1967 7.91406 10.1518C7.91406 10.1058 7.95092 10.0689 7.99584 10.0689L9.58526 10.0586C9.63018 10.0586 9.66703 10.0954 9.66819 10.1403C9.66819 10.1863 9.63133 10.2231 9.58641 10.2231L7.99699 10.2335Z"
          fill="#767D9B"
        />
        <path
          d="M18.1357 20.1551C18.0908 20.1551 18.0539 20.1194 18.0528 20.0734L18.0332 18.4854C18.0332 18.4393 18.0689 18.4025 18.115 18.4014C18.1599 18.4014 18.1968 18.437 18.1979 18.4831L18.2175 20.0711C18.2175 20.1171 18.1818 20.1539 18.1357 20.1551Z"
          fill="#767D9B"
        />
        <path
          d="M26.414 9.99428C26.3691 9.99428 26.3322 9.95861 26.3311 9.91373C26.3311 9.8677 26.3668 9.83087 26.4117 9.82972L28.0011 9.7998C28.0483 9.7998 28.084 9.83548 28.0852 9.88036C28.0852 9.92639 28.0495 9.96321 28.0046 9.96436L26.4151 9.99428H26.414Z"
          fill="#767D9B"
        />
        <path
          d="M99.3619 66.0235C99.3457 66.0235 99.3331 66.0108 99.3331 65.9947C99.3331 64.7864 98.3495 63.8037 97.1401 63.8037C97.124 63.8037 97.1113 63.791 97.1113 63.7749C97.1113 63.7588 97.124 63.7461 97.1401 63.7461C98.3495 63.7461 99.3331 62.7634 99.3331 61.5551C99.3331 61.539 99.3457 61.5264 99.3619 61.5264C99.378 61.5264 99.3907 61.539 99.3907 61.5551C99.3907 62.7634 100.374 63.7461 101.584 63.7461C101.6 63.7461 101.612 63.7588 101.612 63.7749C101.612 63.791 101.6 63.8037 101.584 63.8037C100.374 63.8037 99.3907 64.7864 99.3907 65.9947C99.3907 66.0108 99.378 66.0235 99.3619 66.0235ZM97.4995 63.7749C98.4531 63.928 99.2087 64.6828 99.3619 65.6357C99.515 64.6828 100.271 63.928 101.224 63.7749C100.271 63.6219 99.515 62.867 99.3619 61.9142C99.2087 62.867 98.4531 63.6219 97.4995 63.7749Z"
          fill="#767D9B"
        />
        <path
          d="M99.3618 60.8636C99.3457 60.8636 99.333 60.851 99.333 60.8349V60.2837C99.333 60.2675 99.3457 60.2549 99.3618 60.2549C99.3779 60.2549 99.3906 60.2675 99.3906 60.2837V60.8349C99.3906 60.851 99.3779 60.8636 99.3618 60.8636Z"
          fill="#767D9B"
        />
        <path
          d="M95.894 63.8061C95.8779 63.8061 95.8652 63.7934 95.8652 63.7773C95.8652 63.7612 95.8779 63.7486 95.894 63.7486L96.4457 63.7451C96.4618 63.7451 96.4745 63.7578 96.4745 63.7739C96.4745 63.79 96.4618 63.8027 96.4457 63.8027L95.894 63.8061Z"
          fill="#767D9B"
        />
        <path
          d="M99.4127 67.2501C99.3965 67.2501 99.3839 67.2374 99.3839 67.2213L99.377 66.6701C99.377 66.654 99.3896 66.6414 99.4057 66.6414C99.4219 66.6414 99.4345 66.654 99.4345 66.6701L99.4415 67.2213C99.4415 67.2374 99.4288 67.2501 99.4127 67.2501Z"
          fill="#767D9B"
        />
        <path
          d="M102.285 63.7232C102.269 63.7232 102.256 63.7106 102.256 63.6956C102.256 63.6795 102.269 63.6669 102.284 63.6669L102.835 63.6565C102.851 63.6565 102.864 63.6692 102.864 63.6841C102.864 63.7002 102.851 63.7129 102.836 63.7129L102.285 63.7232Z"
          fill="#767D9B"
        />
      </g>
      <defs>
        <clipPath id="clip0_1212_14448">
          <rect width="104" height="133" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default EmptyIcon;
