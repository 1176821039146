import React from "react";
import EmptyIcon from "components/icons/EmptyIcon";

const AlectifyEmpty: React.FC<IAlectifyEmptyProps> = ({
  description,
  height,
  width,
  className,
}) => {
  return (
    <div className="text-align-center">
      <EmptyIcon height={height} width={width} />

      <p className={className || "color-light-grey"}>{description}</p>
    </div>
  );
};

export default AlectifyEmpty;

export interface IAlectifyEmptyProps {
  description: string;
  height?: string;
  width?: string;
  className?: string;
}
