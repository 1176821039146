import { IParams } from "redux/interfaces";
import {
  CREATE_MASTER_PROJECT,
  GET_DROPDOWN_MASTER_PROJECT,
  GET_MASTER_PROJECT,
  UPDATE_MASTER_PROJECT,
} from "./masterProject.endpoints";
import { http } from "utils/client";
import { AxiosResponse } from "axios";
import {
  IMasterProjectDetailsBody,
  IMasterProjectPayload,
  IMasterProjectCreationResponse,
} from "./masterProject.interface";
import {
  IDropdownMasterProject,
  IMasterProjectResponse,
} from "redux/components/master-project";

/**
 * Fetches master projects using the provided parameters.
 *
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves with the master projects data.
 * @throws {Error} If an error occurs during the request.
 */
export const fetchMasterProjects = async (
  params: IParams,
): Promise<IMasterProjectResponse> => {
  try {
    const response: AxiosResponse<IMasterProjectResponse> = await http.get(
      `${GET_MASTER_PROJECT}`,
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches master projects without counts for dropdowns using the provided parameters.
 *
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves with the master projects data.
 * @throws {Error} If an error occurs during the request.
 */
export const fetchDropdownMasterProjects = async (
  params: IParams,
): Promise<{ data: IDropdownMasterProject[] }> => {
  try {
    const response: AxiosResponse<{ data: IDropdownMasterProject[] }> =
      await http.get(`${GET_DROPDOWN_MASTER_PROJECT}`, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Create master projects using provided items.
 * @returns A Promise that resolves with the master projects data.
 * @throws {Error} If an error occurs during the request.
 */

export const createMasterProjects = async (
  body: IMasterProjectPayload,
): Promise<IMasterProjectCreationResponse> => {
  try {
    const url = CREATE_MASTER_PROJECT;
    const response: AxiosResponse<IMasterProjectCreationResponse> =
      await http.post(url, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Update master projects using provided items masterproject Id.
 * @returns A Promise that resolves with the master projects data.
 * @throws {Error} If an error occurs during the request.
 */

export const updateMasterProjectDetails = async (
  masterProjectId: string,
  body: IMasterProjectPayload,
): Promise<IMasterProjectCreationResponse> => {
  try {
    const url = UPDATE_MASTER_PROJECT.replace(
      "{masterProjectId}",
      masterProjectId,
    );
    const response: AxiosResponse<IMasterProjectCreationResponse> =
      await http.patch(url, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches master projects using the provided parameters.
 *
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves with the master projects data.
 * @throws {Error} If an error occurs during the request.
 */
export const fetchMasterProjectDetails = async (
  body: IMasterProjectDetailsBody,
) => {
  try {
    const url = GET_MASTER_PROJECT;
    const response = await http.post(url, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
