import React, { useState } from "react";
import ContactImg from "assets/images/ContactUsImage.jpg";
import { Col, Form, Row, Spin, message } from "antd";
import "./ContactUsForm.scss";
import AlectifyInput from "../input";
import PhoneNumberInput from "../phone-number/PhoneNumber";
import { contactSupport } from "services/contact-us/contactUs.service";
import AlectifyButton from "../button";
import { MESSAGES } from "constants/messages";

const ContactUsForm: React.FC<any> = ({ onCloseModal }) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onSubmit = async (values: any) => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("description", values.description);
      formData.append("email", values.email);
      formData.append("name", values?.first_name + values?.last_name);
      if (values.phone_number_prefix && values.phone_number) {
        formData.append(
          "phone_number",
          `${values.phone_number_prefix}${values.phone_number}`,
        );
      }
      const response = await contactSupport(formData);
      if (response.success) {
        onCloseModal();
        message.success(MESSAGES.CONTACT_US_MESSAGE.FORM_SUMBITTED);
        form.resetFields();
      } else {
        message.error(MESSAGES.CONTACT_US_MESSAGE.FORM_NOT_SUMBITTED);
      }
      setLoading(false);
    } catch (ex) {
      console.log(ex);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Row gutter={40} className="p-10">
        <Col span={12}>
          <img src={ContactImg} alt="contactUS" style={{ width: "100%" }} />
        </Col>
        <Col span={12}>
          <Form form={form} name="basic" layout="vertical" onFinish={onSubmit}>
            <Row gutter={16}>
              <Col span={24}>
                <AlectifyInput
                  label="Email Address"
                  name="email"
                  type="email"
                  className="contact-us-input"
                  rules={[
                    {
                      type: "email",
                      message:
                        MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_VAILD_EMAIL,
                    },
                    {
                      required: true,
                      message: MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_EMAIL,
                    },
                  ]}
                  placeholder="email address"
                />
              </Col>

              <Col span={12}>
                <AlectifyInput
                  label="First Name"
                  name="first_name"
                  type="text"
                  className="contact-us-input"
                  rules={[
                    {
                      required: true,
                      message:
                        MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_FIRST_NAME,
                    },
                  ]}
                  placeholder="eg John"
                />
              </Col>
              <Col span={12}>
                <AlectifyInput
                  label="Last Name"
                  type="text"
                  name="last_name"
                  className="contact-us-input"
                  rules={[
                    {
                      required: true,
                      message:
                        MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_LAST_NAME,
                    },
                  ]}
                  placeholder="eg Doe"
                />
              </Col>

              <Col span={24}>
                <PhoneNumberInput
                  name="phone_number"
                  label="Contact Number"
                  prefixName="phone_number_prefix"
                  required
                  form={form}
                  isContactUs
                />
              </Col>

              <Col span={24}>
                <AlectifyInput
                  label="Enquiry"
                  name="description"
                  type="textarea"
                  className="contact-us-input"
                  placeholder="type here..."
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_QUERY,
                    },
                  ]}
                />
              </Col>

              <Col offset={15}>
                <AlectifyButton
                  text="Send Enquiry"
                  type="primary"
                  htmlType="submit"
                />
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Spin>
  );
};
export default ContactUsForm;
