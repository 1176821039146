const PlusIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <rect width="22" height="22" rx="11" fill="#E8F1FD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11 5C11.4142 5 11.75 5.33579 11.75 5.75V10.25H16.25C16.6642 10.25 17 10.5858 17 11C17 11.4142 16.6642 11.75 16.25 11.75H11.75V16.25C11.75 16.6642 11.4142 17 11 17C10.5858 17 10.25 16.6642 10.25 16.25V11.75H5.75C5.33579 11.75 5 11.4142 5 11C5 10.5858 5.33579 10.25 5.75 10.25H10.25V5.75C10.25 5.33579 10.5858 5 11 5Z"
        fill="#0954F1"
      />
    </svg>
  );
};
export default PlusIcon;
