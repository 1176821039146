import { IReduxActionResponse } from "redux/interfaces";
import sparePartsInitialState from "redux/components/spare-parts/spare-parts.initialState";
import {
  IProjectSparePart,
  IProjectSparePartCategory,
  ISparePartResponse,
  ISparePartsInitialState,
} from "../spare-parts.interface";
import { IOrganization } from "redux/components/Auth";

export const getSpareParts = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<ISparePartResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      data: payload?.data,
      fetching: false,
      meta: payload?.meta,
      message: payload?.message,
    };
  }
  return state;
};

export const setSparePartsExterLoader = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      fetching: payload,
    };
  }
  return state;
};

export const setRecentlyAddedSpareParts = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<IProjectSparePart>,
): ISparePartsInitialState => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      recentlyAddedSpareParts: [...state.recentlyAddedSpareParts, payload],
    };
  }
  return state;
};

export const updateRecentlyAddedSparePart = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<IProjectSparePart>,
): ISparePartsInitialState => {
  const { payload } = action;
  if (payload) {
    const partsToUpdate = [...state.recentlyAddedSpareParts];
    const idx = partsToUpdate.findIndex(({ id }) => id === payload.id);
    partsToUpdate[idx] = payload;
    return {
      ...state,
      recentlyAddedSpareParts: partsToUpdate,
    };
  }
  return state;
};

export const deleteRecentlyAddedSpareParts = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<string[]>,
): ISparePartsInitialState => {
  const { payload } = action;
  if (payload) {
    const parts = state.recentlyAddedSpareParts.filter(
      (existingPart) => !payload.includes(existingPart.id),
    );
    return {
      ...state,
      recentlyAddedSpareParts: parts,
    };
  }
  return state;
};

export const clearRecentlyAddedSpareParts = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<any>,
): ISparePartsInitialState => {
  return {
    ...state,
    recentlyAddedSpareParts: [],
  };
};

export const resetSparePartsState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...sparePartsInitialState,
  };
};

export const setSparePartAssociatedCategories = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<IProjectSparePartCategory[]>,
): ISparePartsInitialState => {
  return {
    ...state,
    categories: action.payload ?? [],
  };
};

export const setSparePartAssociatedVendors = (
  state: ISparePartsInitialState,
  action: IReduxActionResponse<IOrganization[]>,
): ISparePartsInitialState => {
  return {
    ...state,
    vendors: action.payload ?? [],
  };
};
