import { memo, useEffect, useState } from "react";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import {
  AlectifyDetailCard,
  AlectifyDetailCardImage,
  AlectifyDetailCardText,
} from "../detail-card";
import { displayDateTime, renderAmount } from "utils/helpers";
import { ISparePartDetailProps } from "./SparePartDetail.interface";
import { Col, Row, Typography } from "antd";
import { getSparePartStatus } from "../spare-parts-table/SparePartsTable.helpers";
import { SPARE_PART_STATUS_COMPONENTS } from "../spare-parts-table/effects/useSparePartsColumns";
import { IProjectSparePart } from "redux/components/spare-parts";
import { fetchSparePartsByPartNumber } from "services/spare-parts/spare-parts.service";
import AlectifyText from "static/texts.json";
import SimilarSpareParts from "../similar-spare-parts";

const SparePartDetail: React.FC<ISparePartDetailProps> = (
  props: ISparePartDetailProps,
) => {
  const { sparePart } = props;
  const [similarParts, setSimilarParts] = useState<{
    data: IProjectSparePart[];
    fetching: boolean;
  }>({ data: [], fetching: false });
  const status = getSparePartStatus(sparePart);

  const getSparePartByPartNumber = async (id: string) => {
    try {
      if (id) {
        setSimilarParts({ ...similarParts, fetching: true });
        const response = await fetchSparePartsByPartNumber(id || ""); //12123123
        setSimilarParts({ data: response.data || [], fetching: false });
      } else {
        setSimilarParts({ data: [], fetching: false });
      }
    } catch (error) {
      setSimilarParts({ ...similarParts, fetching: false });
    }
  };

  useEffect(() => {
    if (props.showSimilarSparePart) {
      getSparePartByPartNumber(sparePart.id);
    }
  }, []);

  return (
    <Row gutter={30}>
      <Col span={props.showSimilarSparePart ? 12 : 24}>
        <AlectifyDetailCard>
          <>
            <AlectifyDetailCardImage
              src={NO_IMAGE_AVATAR} // @TODO: task thumbnail will be added here
            />
            <div className="alectify-detail-card-title">
              <Typography.Paragraph>
                <span
                  className="dangerousHTML"
                  dangerouslySetInnerHTML={{
                    __html: sparePart?.sparePart?.description || "-",
                  }}
                />
              </Typography.Paragraph>
              {status && SPARE_PART_STATUS_COMPONENTS[status]}
            </div>
            <AlectifyDetailCardText
              heading={AlectifyText.BASIC_INFORMATION}
              label={AlectifyText.PART_NUMBER}
              value={sparePart?.sparePart?.partNumber}
            />
            <AlectifyDetailCardText
              label={AlectifyText.CATEGORY}
              value={
                sparePart?.projectSparePartCategory?.sparePartCategory?.category
              }
            />
            <AlectifyDetailCardText
              label={AlectifyText.SYSTEM}
              value={sparePart?.system}
            />
            <AlectifyDetailCardText
              label={AlectifyText.VENDOR}
              value={sparePart?.preferredSupplier.name}
            />
            <AlectifyDetailCardText
              label={AlectifyText.FIRMWARE_VERSION}
              value={sparePart?.firmwareVersion}
            />
            <AlectifyDetailCardText
              heading={AlectifyText.STORAGE}
              label={AlectifyText.ROOM}
              value={sparePart?.room}
            />
            <AlectifyDetailCardText
              label={AlectifyText.RACK}
              value={sparePart?.rack}
            />
            <AlectifyDetailCardText
              label={AlectifyText.SHELF}
              value={sparePart?.shelf}
            />
            <AlectifyDetailCardText
              heading={AlectifyText.QUANTITY}
              label={AlectifyText.MIN_QTY}
              value={sparePart?.minimumQuantity}
            />
            <AlectifyDetailCardText
              label={AlectifyText.IN_HAND_QTY}
              value={sparePart?.remainingQuantity || "0"}
            />
            <AlectifyDetailCardText
              label={AlectifyText.AVERAGE_UNIT_PRICE}
              value={renderAmount(sparePart?.price)}
            />
            {props.showOrderDetail && sparePart?.manageOrders?.length ? (
              <>
                <AlectifyDetailCardText
                  heading={AlectifyText.CURRENT_ORDER_DETAILS}
                  label={AlectifyText.ORDER_QUANTITY}
                  value={sparePart?.manageOrders[0]?.quantity}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.ORDER_DATE}
                  value={displayDateTime(
                    sparePart?.manageOrders[0]?.orderedDate,
                  )}
                />
                <AlectifyDetailCardText
                  label={AlectifyText.PENDING_QTY}
                  value={sparePart?.manageOrders[0]?.remainingQuantity}
                />
              </>
            ) : null}
            <Typography.Title
              level={5}
              className="alectify-bordered-row-text-title"
            >
              {AlectifyText.NOTES}
            </Typography.Title>
            <div className="alectify-bordered-row-text">
              <Typography.Title level={5} style={{ width: "100%" }}>
                <span
                  className="dangerousHTML"
                  dangerouslySetInnerHTML={{
                    __html: sparePart.comments || "-",
                  }}
                />
              </Typography.Title>
            </div>
          </>
        </AlectifyDetailCard>
      </Col>
      {props.showSimilarSparePart && (
        <Col span={12}>
          <SimilarSpareParts
            loading={similarParts.fetching}
            similarParts={similarParts.data}
            masterProjectId={sparePart.project.id}
          />
        </Col>
      )}
    </Row>
  );
};

export default memo(SparePartDetail);
