import React, { useEffect, useRef, useCallback } from "react";
import { Form } from "antd";
import Quill from "quill";
import "quill/dist/quill.snow.css";
import { FormInstance } from "antd/lib";
import debounce from "lodash/debounce";
import "./TextEditor.scss";

interface FormTextEditorProps {
  form?: FormInstance;
  name?: any;
  label?: string;
  rules?: any[];
  initialValue?: string;
  readOnly?: boolean;
  customClass?: string;
  onChange?: (value: string) => void;
}

const TOOLBAR_OPTIONS = [
  ["bold", "italic", "underline", "strike"],
  ["link"],
  [{ list: "ordered" }, { list: "bullet" }],
  [{ align: [] }],
  // [{ color: [] }, { background: [] }],
];

const TextEditor: React.FC<FormTextEditorProps> = ({
  name,
  label,
  rules,
  form,
  initialValue = "",
  readOnly = false,
  customClass = "",
  onChange,
}) => {
  const quillRef = useRef<HTMLDivElement | null>(null);
  const quillInstanceRef = useRef<Quill | null>(null);

  const handleTextChange = useCallback(
    debounce(() => {
      if (quillInstanceRef.current) {
        let content = quillInstanceRef.current.root.innerHTML;
        const isEmpty = quillInstanceRef.current.getText().trim().length === 0;
        content = isEmpty ? "" : content;
        form?.setFieldsValue({ [name]: content });
        form?.validateFields([name]);
        onChange && onChange(content);
      }
    }, 300),
    [form, name],
  );

  useEffect(() => {
    if (quillRef.current && !quillInstanceRef.current) {
      const quill = new Quill(quillRef.current, {
        theme: "snow",
        readOnly,
        placeholder: readOnly
          ? ""
          : "Type your incident detailed report here...",
        modules: {
          toolbar: readOnly ? false : TOOLBAR_OPTIONS,
        },
      });

      if (initialValue) {
        quill.root.innerHTML = initialValue;
      }

      if (!readOnly) {
        quill.on("text-change", handleTextChange);
      }

      quillInstanceRef.current = quill;
    }

    return () => {
      if (quillInstanceRef.current && !readOnly) {
        quillInstanceRef.current.off("text-change", handleTextChange);
      }
      quillInstanceRef.current = null;
    };
  }, [readOnly, handleTextChange]);

  useEffect(() => {
    if (quillInstanceRef.current && initialValue !== undefined) {
      const quill = quillInstanceRef.current;
      if (quill.root.innerHTML !== initialValue) {
        quill.root.innerHTML = initialValue;
      }
    }
  }, [initialValue]);

  return (
    <Form.Item name={name} label={label} rules={rules} valuePropName="data">
      <div ref={quillRef} className={`quill-container ${customClass || ""}`} />
    </Form.Item>
  );
};

export default React.memo(TextEditor);
