import { memo } from "react";
import { Typography } from "antd";
import { IHeadingProps } from "./AlectifyHeading.interface";
import AlectifyButton from "../button";
import "./AlectifyHeading.scss";

const AlecitfyHeading: React.FC<IHeadingProps> = (props: IHeadingProps) => {
  return (
    <div className={`alectify-heading mb-20 ${props.className ?? ""}`}>
      <Typography.Title>{props.title}</Typography.Title>
      {props.showButton ? (
        <AlectifyButton
          type="primary"
          text={props.buttonText}
          onClick={props.onClick}
        />
      ) : (
        <></>
      )}
    </div>
  );
};

AlecitfyHeading.defaultProps = {
  title: "",
  buttonText: "",
  showButton: false,
  buttonType: "primary",
  onClick: () => {},
};

export default memo(AlecitfyHeading);
