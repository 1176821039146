import { memo } from "react";
interface ICircleCheckIconProps {
  fill?: string;
}
const CircleCheckIcon: React.FC<ICircleCheckIconProps> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <path
        d="M7.5 15.5C11.6421 15.5 15 12.1421 15 8C15 3.85786 11.6421 0.5 7.5 0.5C3.35786 0.5 0 3.85786 0 8C0 12.1421 3.35786 15.5 7.5 15.5Z"
        fill="white"
      />
      <path
        d="M7.5 1.5C6.21442 1.5 4.95772 1.88122 3.8888 2.59545C2.81988 3.30968 1.98676 4.32484 1.49479 5.51256C1.00282 6.70028 0.874095 8.00721 1.1249 9.26809C1.3757 10.529 1.99477 11.6872 2.90381 12.5962C3.81285 13.5052 4.97104 14.1243 6.23192 14.3751C7.49279 14.6259 8.79973 14.4972 9.98744 14.0052C11.1752 13.5132 12.1903 12.6801 12.9046 11.6112C13.6188 10.5423 14 9.28558 14 8C14 7.14641 13.8319 6.30117 13.5052 5.51256C13.1786 4.72394 12.6998 4.00739 12.0962 3.40381C11.4926 2.80023 10.7761 2.32144 9.98744 1.99478C9.19883 1.66813 8.35359 1.5 7.5 1.5ZM6.2 11.25L2.95 8L3.866 7.084L6.2 9.41L11.134 4.477L12.05 5.4L6.2 11.25Z"
        fill={props.fill || "#3CAC15"}
      />
    </svg>
  );
};

export default memo(CircleCheckIcon);
