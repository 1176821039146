import { IUser } from "redux/components/Auth";
import { IMasterProject } from "redux/components/master-project";
import { IMetaNest } from "redux/interfaces";

/**
 ***** Interfaces *****
 **/
export interface IProcedure {
  createdAt: Date;
  id: string;
  updatedAt: string;
  jobType: ProcedureJobTypeEnum;
  name: string;
  reference: string;
  description: string;
  comments: boolean;
  fileUpload: boolean;
  procedureSteps: IProcedureCheckList[];
  procedureCategory: IProcedureCategory;
  procedureLibraryId?: string;
  procedureLibrary?: IProcedureLibrary;
  imageUrl?: string;
  procedureStepCheckedTotalCount?: number;
  procedureStepTotalCount?: number;
  createdBy?: IUser;
  project: IMasterProject;
}
export interface IProcedureLibrary {
  createdAt: string;
  id: string;
  updatedAt: string;
  jobType: ProcedureJobTypeEnum;
  name: string;
  reference: string;
  description: string;
  comments: boolean;
  fileUpload: boolean;
  imageUrl: string;
}
export interface IProcedureCheckList {
  createdAt?: Date;
  id: string;
  updatedAt?: Date;
  name: string;
  order?: string;
  isChecked?: boolean;
  durationMins?: string;
  comments?: string;
  imageUrl?: string;
  defaultImageUrl?: string;
  draft?: boolean;
}
export interface IProcedureCheckListCreation {
  createdAt?: Date;
  id?: string;
  updatedAt?: Date;
  name: string;
  order?: string;
}
export interface IProcedureCategory {
  createdAt: Date;
  id: string;
  updatedAt: Date;
  name: string;
}

export interface IProcedureListResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: IProcedure[];
  meta: IMetaNest;
}
export interface IProcedureCreationResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: IProcedure;
  meta: IMetaNest;
}
export interface IProcedureResponse {
  message: string;
  data: IProcedure;
}
export interface IProcedureStepsResponse {
  message: string;
  data: IProcedureCheckList[];
  status: boolean;
  success: boolean;
  error?: string;
}
export interface IProcedureCreationPayload {
  jobType: string;
  reference: string;
  fileUpload: boolean;
  comments: boolean;
  name: string;
  description: string;
  categoryId?: string;
  categoryName: string;
  projectId: string;
  // procedureSteps: IProcedureCheckListCreation[];
}

export interface IProcedureStepCreationPayload {
  procedureLibraryId: string;
  name: string;
  description?: string;
  order: string;
  image?: File;
}
export interface IProcedureCategoriesResponse {
  status: boolean;
  statusCode: number;
  message: string;
  data: IProcedureCategory[];
}

export interface IUpdateStepsPayload {
  taskType: string;
  date: string;
  status: boolean;
  stepId: string;
  durationMins?: string;
  comments?: string;
  file?: File | null;
}

export interface IStepsOrder {
  id: string;
  order: number;
}

/**
 ***** Enums *****
 **/
export enum ProcedureJobTypeEnum {
  JOB_PLAN = "Job Plan",
  SOP = "Standard Operating Procedure",
  MOP = "Method of Procedure",
}
