import React from "react";
import { IAlectifyDrawerProps } from "components/drawer/AlectifyDrawer.interface";
import { IDrawerService } from "./DrawerService.interface";

class DrawerService implements IDrawerService {
  on = (event: string, callback: Function) => {
    document.addEventListener(event, (e: any) => callback(e.detail));
  };

  open = (
    component: React.FunctionComponent<any>,
    props: IAlectifyDrawerProps,
  ) => {
    document.dispatchEvent(
      new CustomEvent("openDrawer", { detail: { component, props } }),
    );
  };

  close = (
    component: React.FunctionComponent<any>,
    props?: { name: string | string[] },
  ) => {
    document.dispatchEvent(
      new CustomEvent("closeDrawer", { detail: { component, props } }),
    );
  };

  forceCloseAll = () => {
    document.dispatchEvent(new CustomEvent("forceCloseAll"));
  };

  showLoader = (component: React.FunctionComponent<any>) => {
    document.dispatchEvent(
      new CustomEvent("showLoader", { detail: { component } }),
    );
  };
  hideLoader = (component: React.FunctionComponent<any>) => {
    document.dispatchEvent(
      new CustomEvent("hideLoader", { detail: { component } }),
    );
  };
  updateDrawerProps(name: string, newProps: Record<string, any>): void {
    document.dispatchEvent(
      new CustomEvent("updateDrawerProps", {
        detail: {
          name,
          props: newProps,
        },
      }),
    );
  }
}
const DrawerServiceInstance = new DrawerService();

export default DrawerServiceInstance;
