export const GET_PROJECT_DOCUMENTS = "/projects/{subProjectId}/documents/";
export const GET_ASSET_DOCUMENTS = "/documents/{assetId}";
export const GET_ASSET_DOCUMENTS_COUNT = "/documents/counts/{assetId}";
export const GET_ASSET_PACKAGE_DOCUMENTS =
  "/projects/{subProjectId}/packages/documents/";
export const UPLOAD_ASSET_DOCUMENT = "/projects/{subProjectId}/tags/documents/";
export const DOWNLOAD_ZIP_FILES = "/create-docs-zip/";
export const GET_FILES_SIGNED_URLS = "/document-link/";
export const GET_DOCUMENTS_COUNT = "/projects/{projectId}/documents/insights/";
export const GET_ALL_DOCUMENTS = "/documents/";
export const DELETE_DOCUMENT =
  "/projects/{masterProjectId}/{docType}/documents/";
export const RESTORE_ASSET_DOCUMENT =
  "/projects/{subProjectId}/tags/documents/{assetDocId}/restore/";
export const UPDATE_ASSET_DOCUMENT =
  "/projects/{subProjectId}/tags/documents/{assetDocId}/";
export const DELETE_ASSET_DOCUMENT = "/projects/{subProjectId}/tags/documents/";
export const RESTORE_ASSET_PACKAGE_DOCUMENT =
  "/projects/{subProjectId}/packages/documents/{assetPackageDocId}/restore/";
export const UPDATE_ASSET_PACKAGE_DOCUMENT =
  "/projects/{subProjectId}/packages/documents/{assetPackageDocId}/";
export const DELETE_ASSET_PACKAGE_DOCUMENT =
  "/projects/{subProjectId}/packages/documents/";
export const RESTORE_PROJECT_DOCUMENT =
  "/projects/{subProjectId}/documents/{projectDocId}/restore/";
export const UPDATE_PROJECT_DOCUMENT =
  "/projects/{subProjectId}/documents/{projectDocId}/";
export const DELETE_PROJECT_DOCUMENT = "/projects/{subProjectId}/documents/";
