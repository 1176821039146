import React, { useEffect, useState } from "react";
import { Col, Form, Image, Row, Upload, Typography, message, Spin } from "antd";
import { MailOutlined, CameraOutlined } from "@ant-design/icons";

import { IProfileFormValues } from "./UserProfileForm.interface";
import AlectifyInput from "components/shared/input";

import {
  CreateContacts,
  updateContact,
} from "services/contacts/contacts.service";

import { actions, IUser } from "redux/components/Auth";

import PhoneNumberInput from "components/shared/phone-number/PhoneNumber";
import {
  generateFormInitialValues,
  getBase64,
  prepareFormData,
} from "./UserProfileForm.helper";
import type { UploadProps } from "antd/es/upload";
import type { UploadFile } from "antd/es/upload/interface";
import { contactsTableConstants } from "pages/contacts/contacts-table/ContactsTable.constants";
import { UserProfileFormProps } from "./UserProfileForm.interface";
import {
  fetchUserProfile,
  updateUserProfile,
  validateEmail,
} from "services/user/user.services";
import { useDispatch, useSelector } from "react-redux";
import "./UserProfileForm.scss";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import AlectifyDatePicker from "../datepicker";
import { IRootState } from "redux/rootReducer";
import { isEmpty } from "lodash";
import { extractPhoneNumber } from "../phone-number/PhoneNumber.helper";
import OrganizationsAutoComplete from "../autocomplete/OrganizationsAutoComplete";

const UserProfileForm: React.FC<UserProfileFormProps> = (
  props: UserProfileFormProps,
) => {
  const { user, isEditing, FormInstance, iscontactForm } = props;
  const auth = useSelector((state: IRootState) => state.auth);
  const [existingUser, setExistingUser] = useState<IUser | null>();
  const [loading, setLoading] = useState<boolean>(false);
  const [fileList, setFileList] = useState<UploadFile[]>();
  const [previewImage, setPreviewImage] = useState<string>(
    props?.user?.image
      ? props?.user?.image
      : contactsTableConstants?.DEFAULT_IMAGE,
  );

  const dispatch = useDispatch();

  const handleImageUpload: UploadProps["onChange"] = ({
    fileList: newFileList,
  }) => {
    setFileList(newFileList);
    if (newFileList.length > 0) {
      handleImagePreview(newFileList[0].originFileObj);
    }
  };

  const handleImagePreview = async (file: any) => {
    if (file) {
      file.preview = await getBase64(file);
      setPreviewImage(file.preview as string);
    }
  };

  const fetchUserAndDispatch = async () => {
    const userProfileResponse = await fetchUserProfile();
    dispatch(actions.loginUserPartialUpdate(userProfileResponse));
  };

  const Submit = async (values: IProfileFormValues) => {
    try {
      const formData = prepareFormData(values, fileList);
      const response = await updateUserProfile(formData);
      if (response.success) {
        message.success(MESSAGES.USER_PROFILE_MESSAGE.PROFILE_UPDATED);
        await fetchUserAndDispatch();
      }
    } catch (error) {
      message.success(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      throw error;
    }
  };

  const onSubmit = async (values: IProfileFormValues) => {
    setLoading(true);
    try {
      const formData = prepareFormData(values, fileList, existingUser);
      if (isEditing) {
        const response = await updateContact(formData, user ? user.id : "");
        if (response.success) {
          props?.getContactList?.();
          props.onCloseDrawer();
          message.success(MESSAGES.USER_PROFILE_MESSAGE.CONTACT_UPDATED);
        }
        setLoading(false);
      } else {
        const response = await CreateContacts(formData);
        if (response.success) {
          props?.getContactList?.();
          props.onCloseDrawer();
          message.success(MESSAGES.USER_PROFILE_MESSAGE.CONTACT_CREATED);
        }
        setLoading(false);
      }
    } catch (error: any) {
      setLoading(false);
      if (!error?.response?.data?.message) {
        message.success(MESSAGES.API_RESPONSE_ERRORS.SOMETHING_WENT_WRONG);
      }
    }
  };

  useEffect(() => {
    if (isEditing) {
      FormInstance.setFieldsValue(generateFormInitialValues(user));
    } else {
      FormInstance.resetFields();
    }
  }, [user, FormInstance, isEditing]);

  const validateUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const email = e.target.value.trim();
      if (email === auth.user.email) {
        return;
      }
      setLoading(true);
      const user = await validateEmail({ email });
      if (!isEmpty(user.data)) {
        setExistingUser(user.data);
        setPreviewImage(user.data.image || "");
        FormInstance.setFieldsValue({
          ...user.data,
          organization: user.data.organization?.id,
          organization_name: user.data.organization?.name || "",
          contact_number: user.data.contact_number
            ? extractPhoneNumber(user?.data.contact_number).number
            : "",
          contact_number_prefix: user.data.contact_number
            ? extractPhoneNumber(user?.data.contact_number).code
            : "",
          business_phone_number: user.data.business_phone_number
            ? extractPhoneNumber(user?.data.business_phone_number).number
            : "",
          business_phone_number_prefix: user.data.business_phone_number
            ? extractPhoneNumber(user?.data.business_phone_number).code
            : "",
        });
        // props.existingUserRef.current = user.data;
      } else {
        setPreviewImage(contactsTableConstants.DEFAULT_IMAGE);
        setExistingUser(null);
        FormInstance.resetFields();
        FormInstance.setFieldValue("email", email);
      }
      setLoading(false);
    } catch (ex) {
      setLoading(false);
    }
  };

  const validateOwnerEmail = (_: any, value: string) => {
    if (value === auth.user.email) {
      return Promise.reject("Can't add your own email");
    }
    return Promise.resolve();
  };

  return (
    <>
      <Spin size="default" spinning={loading}>
        <Form
          form={FormInstance}
          name="basic"
          layout="vertical"
          onFinish={iscontactForm ? onSubmit : Submit}
          initialValues={generateFormInitialValues(user)}
          className="alectify-profile-modal-container"
        >
          <Row justify="center">
            <Col>
              <Form.Item label="" name="image_url" noStyle>
                <div className="profile-image-container">
                  <div className="image-wrapper">
                    <Image
                      preview={false}
                      src={previewImage}
                      className="contact-profile-image"
                    />
                    <Upload
                      maxCount={1}
                      accept="image/*"
                      showUploadList={false}
                      beforeUpload={() => false}
                      onChange={handleImageUpload}
                    >
                      <CameraOutlined className="camera-icon" />
                    </Upload>
                  </div>
                </div>
              </Form.Item>
              {user?.email && (
                <div className="email-container">
                  <MailOutlined />
                  <span>{user?.email}</span>
                </div>
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Typography.Title className="profile-heading">
                {AlectifyText.PERSONAL_INFORMATION}
              </Typography.Title>
            </Col>
            {!props?.isEditing && (
              <Col span={24}>
                <AlectifyInput
                  label="Email"
                  name="email"
                  type="text"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_EMAIL,
                    },
                    {
                      message: "Can't add your own email",
                      validator: validateOwnerEmail,
                    },
                  ]}
                  placeholder="Email"
                  disabled={user?.email ? true : false}
                  onBlur={validateUser}
                />
              </Col>
            )}
            <Col span={12}>
              <AlectifyInput
                label="First Name"
                name="first_name"
                type="text"
                rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_FIRST_NAME,
                  },
                ]}
                placeholder="eg John"
                disabled={!isEmpty(existingUser?.first_name)}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                label="Last Name"
                type="text"
                name="last_name"
                rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_LAST_NAME,
                  },
                ]}
                placeholder="eg Doe"
                disabled={!isEmpty(existingUser?.last_name)}
              />
            </Col>

            <Col span={24}>
              {/* <AlectifyInput
                label="Organization Name"
                name="organization_name"
                type="text"
                rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_ORGANIZATION,
                  },
                ]}
                placeholder="Organization Name"
                disabled={!isEmpty(existingUser?.organization?.name)}
              /> */}
              <OrganizationsAutoComplete
                required
                name="organization_name"
                label="Organization Name"
                placeholder="Organization Name"
                disabled={!isEmpty(existingUser?.organization?.name)}
              />
            </Col>

            {props?.iscontactForm && (
              <Col span={24}>
                <Form.Item label="Access expiry date" name="access_expiry_date">
                  <AlectifyDatePicker className="full-width-picker" />
                </Form.Item>
              </Col>
            )}

            <Col span={24}>
              <PhoneNumberInput
                name="contact_number"
                label="Contact Number"
                prefixName="contact_number_prefix"
                required={false}
                form={FormInstance}
                disabled={!isEmpty(existingUser?.contact_number)}
              />
            </Col>

            <Col span={24}>
              <AlectifyInput
                label="Address"
                name="address"
                type="textarea"
                placeholder="Personal Address"
                /*  rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.FORM_FIELD_REQUIRED_MESSAGE
                        .ENTER_PERSONAL_ADDRESS,
                  },
                ]} */
                disabled={!isEmpty(existingUser?.address)}
              />
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={24}>
              <Typography.Title className="profile-heading">
                {AlectifyText.BUSINESS_INFORMARION}
              </Typography.Title>
            </Col>

            <Col span={24}>
              <PhoneNumberInput
                name="business_phone_number"
                label="Contact Number"
                prefixName="business_phone_number_prefix"
                required={false}
                form={FormInstance}
                disabled={!isEmpty(existingUser?.business_phone_number)}
              />
            </Col>

            <Col span={24}>
              <AlectifyInput
                label="Address"
                name="business_address"
                type="textarea"
                /* rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.FORM_FIELD_REQUIRED_MESSAGE
                        .ENTER_BUSINESS_ADDRESS,
                  },
                ]} */
                placeholder="Business Address"
                disabled={!isEmpty(existingUser?.business_address)}
              />
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default UserProfileForm;
