import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./assets.constants";
import { assetsInitialState } from "./assets.initialState";
import { IAssetsActions } from "./assets.interface";
import * as AssetsAffects from "./affects";

export * from "./assets.constants";
export * from "./assets.interface";

const { actions, reducer } = createActionAndReducers<IAssetsActions>(
  reducerName,
  assetsInitialState,
  AssetsAffects,
);

export { actions, assetsInitialState, reducer };
