import React, { useEffect } from "react";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { ROUTES } from "./Routes.constants";
import { IRootState } from "redux/rootReducer";
import MainLayout from "components/layouts";

interface PrivateRouteProps {
  isAuthenticated?: boolean;
}

const PrivateRoutes: React.FC<PrivateRouteProps> = () => {
  const navigate = useNavigate();
  // Get authentication details from Redux store
  const { user } = useSelector((state: IRootState) => state.auth);

  // Check if the user is authenticated
  const isUserAuthenticated = !isEmpty(user);

  // Check if the user is not authenticated, redirect them to login page.
  useEffect(() => {
    if (!isUserAuthenticated) navigate(ROUTES.LOGIN);
  }, [user]);
  if (!isUserAuthenticated) return <></>;
  // Display the main layout if the user is authenticated, otherwise redirect to login
  return (
    <MainLayout>
      <Outlet />
    </MainLayout>
  );
};

export default PrivateRoutes;
