import { IMeta, IReduxActionResponse } from "redux/interfaces";
import { assetsInitialState } from "../assets.initialState";
import { IAsset, IAssetsResponse, IAssetsState } from "../assets.interface";

export const setProjectAssets = (
  state: IAssetsState,
  action: IReduxActionResponse<IAssetsResponse>,
): IAssetsState => {
  const { payload } = action;
  return {
    ...state,
    assets: {
      ...state.assets,
      data: payload?.data as IAsset[],
      meta: payload?.meta as IMeta,
      message: payload?.message as string,
    },
  };
};

export const setAssetsLoader = (
  state: IAssetsState,
  action: IReduxActionResponse<boolean>,
): IAssetsState => {
  const { payload } = action;
  return {
    ...state,
    assets: {
      ...state.assets,
      fetching: payload,
    },
  };
};

export const setAssetDetail = (
  state: IAssetsState,
  action: IReduxActionResponse<IAsset>,
): IAssetsState => {
  const { payload } = action;
  return {
    ...state,
    assetDetail: {
      ...state.assetDetail,
      data: payload,
    },
  };
};

export const setAssetDetailLoader = (
  state: IAssetsState,
  action: IReduxActionResponse<boolean>,
): IAssetsState => {
  const { payload } = action;
  return {
    ...state,
    assetDetail: {
      ...state.assetDetail,
      fetching: payload as boolean,
    },
  };
};

export const resetAssetState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...assetsInitialState,
  };
};
