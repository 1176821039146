export const DEFAULT_QUESTIONS = [
  {
    question: "GE Entellisys Low Voltage Switchgear Summary?",
  },
  {
    question: "How to maintain the Switchgear?",
  },
];

export const TITLES = {
  AI_BASED_SEARCH: "AI Based Document Searching",
  HOW_CAN_HELP: "How can I help you today?",
};
