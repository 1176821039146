const InformationIcon: React.FC = () => {
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.875 12.5C21.875 17.6777 17.6779 21.875 12.5 21.875C7.32212 21.875 3.125 17.6777 3.125 12.5C3.125 7.32226 7.32212 3.125 12.5 3.125C17.6779 3.125 21.875 7.32226 21.875 12.5ZM10.4725 10.4023H13.7875V17.4639C13.7875 18.1474 13.2333 18.7016 12.5498 18.7016C11.8662 18.7016 11.3121 18.1474 11.3121 17.4639V12.5973H10.4725C9.86636 12.5973 9.375 12.1059 9.375 11.4998C9.375 10.8937 9.86636 10.4023 10.4725 10.4023ZM10.9528 7.70699C10.9528 7.51216 10.9874 7.33045 11.056 7.16187C11.1304 6.98806 11.2308 6.83795 11.3579 6.71155C11.4851 6.58515 11.6332 6.48512 11.8029 6.41134C11.88 6.37875 11.9595 6.35336 12.0418 6.33516C12.1462 6.3123 12.2546 6.30074 12.367 6.30074C12.5576 6.30074 12.7375 6.33756 12.9072 6.41134C12.9532 6.43123 12.9974 6.4531 13.04 6.4768C13.1552 6.54099 13.2591 6.61929 13.3521 6.71155C13.4792 6.83795 13.5797 6.98806 13.654 7.16187C13.6926 7.24976 13.7216 7.34117 13.7397 7.43612C13.7567 7.52345 13.7653 7.61373 13.7653 7.70699C13.7653 7.81195 13.7545 7.91394 13.7329 8.01284C13.7148 8.09749 13.6881 8.17987 13.654 8.26C13.5797 8.42859 13.4792 8.57602 13.3521 8.70242C13.2869 8.76717 13.2165 8.82502 13.1404 8.87594C13.0678 8.92433 12.9901 8.96666 12.9072 9.00263C12.7375 9.07641 12.5576 9.11324 12.367 9.11324C12.2676 9.11324 12.1717 9.10435 12.0792 9.08643C11.9833 9.06809 11.8914 9.04016 11.8029 9.00263C11.6332 8.92885 11.4851 8.82883 11.3579 8.70242C11.2308 8.57602 11.1304 8.42859 11.056 8.26C10.9874 8.0862 10.9528 7.90181 10.9528 7.70699Z"
        fill="#545454"
      />
    </svg>
  );
};

export default InformationIcon;
