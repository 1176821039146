import { memo } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
// import PmExternalTable from "components/shared/pm-external-table";
import { isExternalUser } from "utils/helpers";
import TasksTable from "components/shared/tasks-table";
import { PM_TYPES } from "redux/components/pm-external";

interface IPmExternal {
  scroll?: {
    x?: number | string;
    y?: number | string;
  };
}

const PmExternal: React.FC<IPmExternal> = (props) => {
  const {
    common,
    auth: { user },
  } = useSelector((state: IRootState) => state);
  const params = {
    assignees: [user?.id],
    approvers: [user?.id],
    createdById: user?.id,
  };

  return (
    <TasksTable
      scroll={props.scroll}
      myItemsColumns
      subProjectId="all"
      masterProjectId="all"
      allowCreation={!isExternalUser(user)}
      triggerCountsApi={false}
      defaultParams={
        common.showMyWorkOrders
          ? params
          : { teamMembers: [user?.id], createdById: user?.id }
      }
      type={PM_TYPES.PM_EXTERNAL}
      myWorkdOrder
    />
  );
};

export default memo(PmExternal);
