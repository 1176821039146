import { isEmpty } from "lodash";
import { memo, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Col, Form, message, Row, Spin, Typography } from "antd";
import { MESSAGES } from "constants/messages";
import { IRootState } from "redux/rootReducer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import { ISparePartCategoryState } from "services/spare-parts/spare-parts.interface";
import {
  ICreateEditSparePartProps,
  ICreateSparePartPayload,
} from "./CreateEditSparePart.interface";
import {
  createSparePart,
  updateSparePart,
  fetchSparePartsCategories,
  fetchSparePartsByPartNumber,
} from "services/spare-parts/spare-parts.service";
import {
  setRecentlyAddedSpareParts,
  updateRecentlyAddedSparePart,
} from "redux/components/spare-parts/sources";
import { IProjectSparePart } from "redux/components/spare-parts";
import AlectifyText from "static/texts.json";
import AlectifyInput from "../input";
import AlectifySelect from "../select";
import AlectifyDrawer from "components/drawer";
import SimilarSpareParts from "../similar-spare-parts";
import AlectifyAutoComplete from "../autocomplete";
import AlectifyQuantityInput from "../quantity-input";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import OrganizationsAutoComplete from "../autocomplete/OrganizationsAutoComplete";
import "./CreateEditSparePart.scss";
import AlectifyEmpty from "../empty/AlectifyEmpty";

const CreateEditSparePart: React.FC<ICreateEditSparePartProps> = (
  props: ICreateEditSparePartProps,
) => {
  const dispatch = useDispatch();
  const masterProjects = useSelector(
    ({ MasterProject }: IRootState) => MasterProject,
  );
  const [categories, setCategories] = useState<ISparePartCategoryState>({
    data: [],
    fetching: false,
  });
  const [selectedMasterProject, setSelectedMasterProject] =
    useState<string>("");
  const [prefferedSupplierId, setPrefferedSupplierId] = useState<string | null>(
    null,
  );
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [similarParts, setSimilarParts] = useState<IProjectSparePart[]>([]);

  const onChangeMasterProject = (value: string) => {
    setSelectedMasterProject(value);
  };

  const getCategories = async (masterProjectId: string) => {
    try {
      setCategories({ ...categories, fetching: true });
      const response = await fetchSparePartsCategories(masterProjectId);
      setCategories({ fetching: false, data: response.data });
    } catch (error) {
      setCategories({ ...categories, fetching: false });
    }
  };

  const setFormFieldsValueOnEdit = () => {
    const { existingSparePart } = props;
    props.formInstance.setFieldsValue({
      ...existingSparePart,
      price: existingSparePart?.price?.toString() || undefined,
      projectId: existingSparePart?.project.id,
      partNumber: existingSparePart?.sparePart.partNumber,
      description: existingSparePart?.sparePart.description,
      minimumQuantity: existingSparePart?.minimumQuantity.toString(),
      remainingQuantity: existingSparePart?.remainingQuantity.toString(),
      preferredSupplierName: existingSparePart?.preferredSupplier.name,
      category:
        existingSparePart?.category ||
        existingSparePart?.projectSparePartCategory?.sparePartCategory
          ?.category,
    });
    setSelectedMasterProject(existingSparePart?.project.id || "");
  };

  useEffect(() => {
    if (!isEmpty(props.existingSparePart)) {
      setFormFieldsValueOnEdit();
    }
    getCategories("");
  }, []);

  useEffect(() => {
    if (selectedMasterProject) {
      getCategories(selectedMasterProject);
    }
  }, [selectedMasterProject]);

  const onSubmit = async (values: ICreateSparePartPayload) => {
    try {
      setSubmitting(true);
      values = {
        ...values,
        price: parseInt(values.price as any),
        minimumQuantity: parseInt(values.minimumQuantity as any),
        remainingQuantity: parseInt(values.remainingQuantity as any),
        preferredSupplierId: prefferedSupplierId,
      };
      console.log("propsssss", values);
      if (!isEmpty(props.existingSparePart)) {
        const response = await updateSparePart(
          props.existingSparePart.id,
          values,
        );
        dispatch(updateRecentlyAddedSparePart(response.data));
        message.success(MESSAGES.SPARE_PART_MESSAGES.UPDATED);
      } else {
        const response = await createSparePart(values);
        dispatch(setRecentlyAddedSpareParts(response.data));
        message.success(MESSAGES.SPARE_PART_MESSAGES.CREATED);
      }
      setSubmitting(false);
      props.formInstance.resetFields();
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.ADD_NEW_SPARE_PART_DRAWER,
      });
      props?.fetchSpareParts && props.fetchSpareParts();
    } catch (error) {
      console.log("error: ", error);
      setSubmitting(false);
    }
  };

  const onSelectSupplier = (id: string, option: any) => {
    props.formInstance.setFieldsValue({ preferredSupplierName: option.name });
    setPrefferedSupplierId(option.id);
  };

  const onClearSearchSupplier = () => {
    // if (!prefferedSupplierId) {
    //   props.formInstance.setFieldsValue({ preferredSupplierName: "" });
    //   setPrefferedSupplierId(null);
    // }
  };

  const onSupplierChange = () => {
    setPrefferedSupplierId(null);
  };

  const openSimilarPartsDrawer = (similarParts: IProjectSparePart[]) => {
    const partNumber = props.formInstance.getFieldValue("partNumber");
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 400,
      title: `${AlectifyText.PART_NUMBER}: ${partNumber}`,
      name: DRAWER_CONSTANTS.SIMILAR_PARTS_DRAWER,
      showFooter: false,
      children: (
        <SimilarSpareParts
          similarParts={similarParts}
          masterProjectId={selectedMasterProject}
        />
      ),
      closeIcon: true,
      closable: true,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.SIMILAR_PARTS_DRAWER,
        }),
    });
  };

  const getSparePartByPartNumber = async (partNumber: string) => {
    try {
      if (partNumber) {
        setSubmitting(true);
        const response = await fetchSparePartsByPartNumber(partNumber || ""); //12123123
        setSimilarParts(response.data || []);
        setSubmitting(false);
        response.data.length && openSimilarPartsDrawer(response.data);
      } else {
        setSimilarParts([]);
      }
    } catch (error) {
      setSubmitting(false);
      setSimilarParts([]);
    }
  };
  const nonNegativeIntegerRule = {
    validator: (_: any, value: any) => {
      if (value < 0) {
        return Promise.reject("Value cannot be negative");
      }
      return Promise.resolve();
    },
  };
  return (
    <Spin spinning={submitting}>
      <div className="drawer-form-wrapper pb-10">
        <Form
          form={props.formInstance}
          layout="vertical"
          onFinish={onSubmit}
          onFinishFailed={console.log}
        >
          <Typography.Title
            level={4}
            className="form-heading"
            style={{ marginTop: "-23px" }}
          >
            Basic Information
          </Typography.Title>
          <Row gutter={30}>
            <Col span={12}>
              <AlectifySelect
                name="projectId"
                label={`${AlectifyText.SITE}`}
                placeholder="Please Select"
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                disabled={!isEmpty(props.existingSparePart)}
                options={masterProjects.data.map((masterProject) => ({
                  value: masterProject.id,
                  label: masterProject.name,
                }))}
                onChange={onChangeMasterProject}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="partNumber"
                label="Part #"
                type="text"
                placeholder="Enter part"
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                onBlur={(ev) => getSparePartByPartNumber(ev.target.value)}
                extra={
                  similarParts.length ? (
                    <Typography.Text
                      className="similar-part-text"
                      underline
                      onClick={() => openSimilarPartsDrawer(similarParts)}
                    >
                      {AlectifyText.SIMILAR_PART_FOUND}
                    </Typography.Text>
                  ) : (
                    <></>
                  )
                }
              />
            </Col>
            <Col span={12}>
              <Form.Item
                name="category"
                label="Category"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
                className="alectify-organization-autocomplete"
              >
                <AlectifyAutoComplete
                  placeholder="Search or enter category"
                  notFoundContent={
                    <div className="text-center">
                      {categories.fetching ? (
                        <Spin size="small" spinning={categories.fetching} />
                      ) : (
                        <AlectifyEmpty
                          description={MESSAGES.CATAGORY_EMPTY.EMPTY_MESSAGE}
                        />
                      )}
                    </div>
                  }
                  options={(categories?.data || []).map(
                    ({ sparePartCategory }) => ({
                      value: sparePartCategory.category,
                      label: sparePartCategory.category,
                    }),
                  )}
                  filterOption={(inputValue, option: any) =>
                    option.label
                      .toUpperCase()
                      .indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <OrganizationsAutoComplete
                placeholder="Search or enter vendor"
                name="preferredSupplierName"
                label="Vendor"
                required
                onSelect={onSelectSupplier}
                onChange={onSupplierChange}
                onBlur={onClearSearchSupplier}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="system"
                label="System"
                type="text"
                placeholder="Enter System"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={24}>
              <AlectifyInput
                name="description"
                label="Description"
                type="textarea"
                placeholder=""
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
                max={255}
              />
            </Col>
          </Row>
          <Typography.Title level={4} className="form-heading">
            Storage & Version
          </Typography.Title>
          <Row gutter={30}>
            <Col span={12}>
              <AlectifyInput
                name="room"
                label="Room"
                type="text"
                placeholder="Enter room"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="rack"
                label="Rack"
                type="text"
                placeholder="Enter rack"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="shelf"
                label="Shelf"
                type="text"
                placeholder="Enter shelf"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="firmwareVersion"
                label="Firmware Version"
                type="text"
                placeholder="Enter firmware version"
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
          </Row>
          <Typography.Title level={4} className="form-heading">
            Quantity & Pricing
          </Typography.Title>
          <Row gutter={30}>
            <Col span={12}>
              <AlectifyQuantityInput
                name="remainingQuantity"
                label="Quantity In Hand"
                placeholder=""
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                form={props.formInstance}
                // disabled={props.disableQuantityFields}
              />
            </Col>
            <Col span={12}>
              <AlectifyQuantityInput
                name="minimumQuantity"
                label="Minimum Quantity"
                placeholder=""
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                form={props.formInstance}
              />
            </Col>
            <Col span={12}>
              <AlectifyInput
                name="price"
                min={1}
                label="Average Unit Price"
                type="number"
                placeholder="Enter Average Unit Price"
                // rules={[
                //   ...BASIC_REQUIRED_VALIDATION_RULES,
                //   nonNegativeIntegerRule as any,
                // ]}
                // disabled={props.disableQuantityFields}
              />
            </Col>
          </Row>
          <Typography.Title level={4} className="form-heading">
            Other Information
          </Typography.Title>
          <AlectifyInput
            name="comments"
            label="Note"
            type="textarea"
            placeholder=""
          />
        </Form>
      </div>
    </Spin>
  );
};

export default memo(CreateEditSparePart);
