import React from "react";
import { Button } from "antd";
import { IAlectifyButtonProps } from "./AlectifyButton.interface";
import "./AlectifyButton.scss";

const AlectifyButton: React.FC<IAlectifyButtonProps> = (
  props: IAlectifyButtonProps,
) => {
  const { className, text, rightIcon } = props;
  return (
    <Button
      {...props}
      htmlType={props?.htmlType || "button"}
      className={`alectify-regular-button ${className ? className : ""}`}
    >
      {text}
      {rightIcon && rightIcon}
    </Button>
  );
};

export default AlectifyButton;
