import { memo } from "react";

const DetailIcon: React.FC = (props: any = {}) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.65"
        y="0.65"
        width="18.7"
        height="18.7"
        rx="3.35"
        stroke="#141B34"
        stroke-width="1.3"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 4.75C4 4.33579 4.33579 4 4.75 4L15.75 4C16.1642 4 16.5 4.33579 16.5 4.75C16.5 5.16421 16.1642 5.5 15.75 5.5L4.75 5.5C4.33579 5.5 4 5.16421 4 4.75Z"
        fill="#141B34"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 10.1972C4 9.78299 4.15906 9.4472 4.35526 9.4472L8.14474 9.4472C8.34094 9.4472 8.5 9.78299 8.5 10.1972C8.5 10.6114 8.34094 10.9472 8.14474 10.9472L4.35526 10.9472C4.15906 10.9472 4 10.6114 4 10.1972Z"
        fill="#141B34"
      />
      <path
        d="M15.9226 13.6751L15.9225 13.6752L15.9258 13.6782C16.0404 13.783 16.1611 13.8077 16.2663 13.7884C16.3652 13.7702 16.4407 13.7151 16.4806 13.6753C16.6398 13.5178 16.6398 13.2757 16.4806 13.1181C16.4805 13.118 16.4805 13.118 16.4804 13.1179L14.9666 11.6028C15.3721 11.0783 15.6122 10.4197 15.6122 9.70613C15.6122 7.99083 14.2214 6.60001 12.5061 6.60001C10.7908 6.60001 9.4 7.99083 9.4 9.70613C9.4 11.4214 10.7908 12.8122 12.5061 12.8122C13.2227 12.8122 13.8827 12.5693 14.4086 12.1611L15.9226 13.6751ZM10.1867 9.70613C10.1867 8.42531 11.2253 7.38668 12.5061 7.38668C13.787 7.38668 14.827 8.42538 14.827 9.70613C14.827 10.987 13.7884 12.0256 12.5075 12.0256C11.2266 12.0256 10.1867 10.9869 10.1867 9.70613Z"
        fill="#141B34"
        stroke="#141B34"
        stroke-width="0.2"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M4 15.6444C4 15.2302 4.33579 14.8944 4.75 14.8944L12.75 14.8944C13.1642 14.8944 13.5 15.2302 13.5 15.6444C13.5 16.0586 13.1642 16.3944 12.75 16.3944L4.75 16.3944C4.33579 16.3944 4 16.0586 4 15.6444Z"
        fill="#141B34"
      />
    </svg>
  );
};

export default memo(DetailIcon);
