import { Space, Tag } from "antd";
import { CircleCheckIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const ClosedTag: React.FC<{ text?: string; color?: string; fill?: string }> = ({
  text,
  color,
  fill,
}) => {
  return (
    <Tag color={color || "#3CAC15"} className="round-tag">
      <Space size={3}>
        <CircleCheckIcon fill={fill} /> {text || AlectifyText.CLOSED}
      </Space>
    </Tag>
  );
};

export default ClosedTag;
