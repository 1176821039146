import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

interface IDataRoundsIcon {
  fill?: string;
}
const DataRoundsIcon: React.FC<IDataRoundsIcon> = (props: IDataRoundsIcon) => {
  return (
    <svg
      id="Layer_1"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 102.34 122.88"
      fill={props.fill || PROJECT_THEME_COLOR}
    >
      <path d="M57.53,88.57c.28,1.32,2.42,2.65,5.75,3.76a52.88,52.88,0,0,0,16,2.21,52.78,52.78,0,0,0,16-2.21c3.58-1.19,5.79-2.64,5.79-4h0V78.57a19.32,19.32,0,0,1-4.82,2.28,55.84,55.84,0,0,1-17,2.38,55.81,55.81,0,0,1-17-2.38,19.45,19.45,0,0,1-4.77-2.24v10ZM20,57.75a2.83,2.83,0,0,1-2.69-2.93A2.79,2.79,0,0,1,20,51.9h22.9a2.83,2.83,0,0,1,2.68,2.92,2.79,2.79,0,0,1-2.68,2.93Zm65.85-40h9.61a6.73,6.73,0,0,1,4.84,2,6.82,6.82,0,0,1,2,4.83V57.25H96.42V24.64a1,1,0,0,0-1-1H85.84V57.25H80V6.86a1,1,0,0,0-.28-.7,1,1,0,0,0-.7-.28H6.82a1,1,0,0,0-.69.28,1,1,0,0,0-.28.7V88.38a.94.94,0,0,0,1,1H45v5.89H22.31v10.93a1,1,0,0,0,.28.69,1,1,0,0,0,.7.28H45V113H23.32a6.77,6.77,0,0,1-4.84-2,6.84,6.84,0,0,1-2-4.84V95.24H6.86a6.77,6.77,0,0,1-4.84-2,6.85,6.85,0,0,1-2-4.84V6.86A6.77,6.77,0,0,1,2,2,6.83,6.83,0,0,1,6.86,0H79a6.73,6.73,0,0,1,4.83,2,6.79,6.79,0,0,1,2,4.84V17.79ZM20,26.35a2.83,2.83,0,0,1-2.68-2.92A2.79,2.79,0,0,1,20,20.5H65.79a2.84,2.84,0,0,1,2.68,2.93,2.78,2.78,0,0,1-2.68,2.92Zm0,15.7a2.83,2.83,0,0,1-2.68-2.92A2.79,2.79,0,0,1,20,36.2H65.79a2.83,2.83,0,0,1,2.68,2.93,2.78,2.78,0,0,1-2.68,2.92Zm43.3,35.3a52.91,52.91,0,0,0,16,2.2,52.81,52.81,0,0,0,16-2.2c3.58-1.19,5.79-2.64,5.79-4S99,70.44,95.38,69.24a52.81,52.81,0,0,0-16-2.2,52.91,52.91,0,0,0-16,2.2c-7.59,2.54-7.8,5.51,0,8.11Zm37.9,30.24a19.6,19.6,0,0,1-4.81,2.28,56.17,56.17,0,0,1-17,2.37,56.15,56.15,0,0,1-17-2.37,19.82,19.82,0,0,1-4.77-2.25v9.3c.28,1.32,2.42,2.65,5.75,3.76a52.91,52.91,0,0,0,16,2.2,52.81,52.81,0,0,0,16-2.2,13.06,13.06,0,0,0,5.18-2.74c.74-.8.62-1.33.62-2.29v-8.06Zm-43.65-4.7c.28,1.32,2.42,2.65,5.75,3.76a52.91,52.91,0,0,0,16,2.2,52.81,52.81,0,0,0,16-2.2c3.58-1.2,5.79-2.64,5.79-4.06h0v-9a19.68,19.68,0,0,1-4.82,2.28,56.17,56.17,0,0,1-17,2.37,56.15,56.15,0,0,1-17-2.37,19.82,19.82,0,0,1-4.77-2.25v9.3Z" />
    </svg>
  );
};

export default DataRoundsIcon;
