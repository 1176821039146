import { memo, useEffect } from "react";
import { isEmpty, isNil, isNumber } from "lodash";
import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { IAlectifyQuantityInputProps } from "./AlectifyQuantityInput.interface";
import AlectifyInput from "../input";
import "./AlectifyQuantityInput.scss";

const AlectifyQuantityInput: React.FC<IAlectifyQuantityInputProps> = (
  props: IAlectifyQuantityInputProps,
) => {
  const { form, ...rest } = props;

  const onDecreament = () => {
    if (!props.disabled) {
      const value = props.form.getFieldValue(props.name);
      if (value && isNumber(parseInt(value))) {
        const newValue = parseInt(value) - 1;
        if (newValue >= 0) {
          props.form.setFieldValue(props.name, newValue.toString());
          if (props.onChange) {
            props.onChange(newValue.toString());
          }
        }
      }
    }
  };

  const onIncreament = () => {
    if (!props.disabled) {
      const value = props.form.getFieldValue(props.name);
      if ((value || value === 0) && isNumber(parseInt(value))) {
        const newValue = parseInt(value) + 1;

        // If drawType is "return", skip the max warning check
        if (
          props.drawType !== "return" &&
          !isNil(props.max) &&
          newValue > props.max
        ) {
          props.form.setFields([
            {
              name: props.name,
              warnings: [props.maxQtyErrorMsg || "Invalid Qty"],
            },
          ]);
          return;
        } else {
          // Clear warnings if no issue
          props.form.setFields([{ name: props.name, warnings: [] }]);
        }

        // Set the new value in the form
        props.form.setFieldValue(props.name, newValue.toString());
        if (props.onChange) {
          props.onChange(newValue.toString());
        }
      }
    }
  };

  useEffect(() => {
    const value = props.form.getFieldValue(props.name);
    if (isEmpty(value)) {
      props.form.setFieldValue(props.name, "0");
    }
  }, []);

  return (
    <>
      <AlectifyInput
        {...rest}
        min={0}
        type="number"
        className="alectify-quantity-input"
        addonBefore={
          <MinusOutlined
            onClick={onDecreament}
            // style={{ color: "#E25555", fontSize: 20 }}
          />
        }
        addonAfter={
          <PlusOutlined
            onClick={onIncreament}
            // style={{ color: "#70D043", fontSize: 20 }}
          />
        }
        disabled={props.disabled}
        extra={props.extra}
        onChange={(ev) => {
          if (props.onChange) {
            props.onChange(ev.target.value);
          }
        }}
        onKeyDown={(e: any) => {
          if (e.key === "-" || e.key === "e" || e.key === ".") {
            e.preventDefault();
          }
        }}
      />
    </>
  );
};

export default memo(AlectifyQuantityInput);
