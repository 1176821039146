import { clearLocalStorage } from "utils/cache";
import { ILoginResponse } from "services/auth/auth.interface";
import { IReduxActionResponse } from "redux/interfaces";
import { authInitialState } from "redux/components/Auth/auth.initialState";

export const loginUserAction = (
  state: any,
  action: IReduxActionResponse<ILoginResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    user: payload?.data,
    token: payload?.data.token,
  };
};

export const loginUserPartialUpdate = (
  state: any,
  action: IReduxActionResponse<ILoginResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    user: {
      ...state.user,
      ...payload?.data,
    },
  };
};

export const logout = (state: any, action: IReduxActionResponse<any>) => {
  clearLocalStorage();
  return {
    ...state,
    user: null,
    token: null,
  };
};

export const setAuthLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  return {
    ...state,
    fetching: action.payload,
  };
};

export const setFirebaseToken = (
  state: any,
  action: IReduxActionResponse<string>,
) => {
  return {
    ...state,
    user: {
      ...state.user,
      fcmToken: action.payload ?? "",
    },
  };
};

export const resetAuthState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...authInitialState,
  };
};
