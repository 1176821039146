import { Breadcrumb, Col, Row, Typography } from "antd";
import SparePartsTable from "components/shared/spare-parts-table";
import AlectifyText from "static/texts.json";

const CompanyWideSpareParts: React.FC = () => {
  return (
    <>
      <Breadcrumb
        className=""
        items={[
          {
            title: "Home",
          },
          {
            title: AlectifyText.ALL_SITES,
          },
          {
            title: AlectifyText.SPARE_PARTS,
          },
        ]}
      />

      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Typography.Title level={4} className="mt-10">
            {AlectifyText.ALL_SITES} {AlectifyText.READ_ONLY}
          </Typography.Title>
        </Col>
      </Row>
      <SparePartsTable isGlobal scroll={{ y: "calc(100vh - 330px)" }} />
    </>
  );
};

export default CompanyWideSpareParts;
