import React from "react";
import { ILoaderService } from "./LoaderService.interface";

class LoaderService implements ILoaderService {
  on = (event: string, callback: Function) => {
    document.addEventListener(event, (e: any) => callback(e.detail));
  };

  show = (component?: React.FunctionComponent<any>, props = {}) => {
    document.dispatchEvent(
      new CustomEvent("showLoader", { detail: { component, props } }),
    );
  };

  hide = (component?: React.FunctionComponent<any>) => {
    document.dispatchEvent(
      new CustomEvent("hideLoader", { detail: { component } }),
    );
  };

  // singleton object
  private static instance: LoaderService;

  private constructor() {
    // Initialize your class here
  }

  public static getInstance(): LoaderService {
    if (!LoaderService.instance) {
      LoaderService.instance = new LoaderService();
    }
    return LoaderService.instance;
  }
}
// Create an instance of the LoaderService class
const loaderServiceInstance = LoaderService.getInstance();

// Export the instance as the default export
export default loaderServiceInstance;
