import React from "react";

const EyeIconFilledIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="16"
      viewBox="0 0 22 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.07322 8C8.07322 8.68338 8.37094 9.33877 8.90089 9.82199C9.43084 10.3052 10.1496 10.5767 10.8991 10.5767C11.6485 10.5767 12.3673 10.3052 12.8973 9.82199C13.4272 9.33877 13.7249 8.68338 13.7249 8C13.7249 7.31662 13.4272 6.66123 12.8973 6.17801C12.3673 5.69478 11.6485 5.42331 10.8991 5.42331C10.1496 5.42331 9.43084 5.69478 8.90089 6.17801C8.37094 6.66123 8.07322 7.31662 8.07322 8ZM21.8543 7.40644C19.4624 2.81212 15.8468 0.5 11 0.5C6.15063 0.5 2.53758 2.81212 0.145696 7.40874C0.0497568 7.59398 0 7.79629 0 8.00115C0 8.20601 0.0497568 8.40832 0.145696 8.59356C2.53758 13.1879 6.15315 15.5 11 15.5C15.8494 15.5 19.4624 13.1879 21.8543 8.59126C22.0486 8.21856 22.0486 7.78604 21.8543 7.40644ZM10.8991 12.0491C8.44663 12.0491 6.45845 10.2362 6.45845 8C6.45845 5.7638 8.44663 3.95092 10.8991 3.95092C13.3515 3.95092 15.3397 5.7638 15.3397 8C15.3397 10.2362 13.3515 12.0491 10.8991 12.0491Z"
        fill="#0954F1"
      />
    </svg>
  );
};

export default EyeIconFilledIcon;
