//libs
import { memo, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/es/form/Form";

//absolute
import { PAGINATION } from "constants/index";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import AlectifyButton from "components/shared/button";
import AlectifyTable from "components/shared/table";
import AlectifyText from "static/texts.json";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { fetchContacts } from "services/contacts/contacts.service";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { IProfileFormValues } from "components/shared/user-profile-form/UserProfileForm.interface";
import { UserProfileForm } from "components/shared/user-profile-form";

//internal
import contactsColumn from "./effects/useContactsColumns";

const ContactsTable: React.FC = () => {
  const [contacts, setContacts] = useState<any>([]);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loader, setLoader] = useState<boolean>(false);
  const [FormInstance] = useForm();

  const onCloseDrawer = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_CREATE_NEW_CONTACT,
    });
  };

  const openCreationDrawer = (
    _: any,
    isEditing: boolean = false,
    user?: IProfileFormValues,
  ) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 630,
      title: isEditing ? AlectifyText.EDIT_CONTACT : AlectifyText.ADD_CONTACT,
      name: DRAWER_CONSTANTS.DRAWER_CREATE_NEW_CONTACT,
      showFooter: true,
      onClose: onCloseDrawer,
      onSubmit: FormInstance.submit,
      closeIcon: null,
      closable: false,
      children: (
        <UserProfileForm
          FormInstance={FormInstance}
          user={user}
          isEditing={isEditing}
          getContactList={getContactList}
          onCloseDrawer={onCloseDrawer}
          iscontactForm
        />
      ),
    });
  };

  const columns = contactsColumn({
    openEditDrawer: openCreationDrawer,
  });

  const getContactList = async (options?: IPagination) => {
    setLoader(true);
    try {
      const params = {
        ...options,
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };
      const response = await fetchContacts(params);
      setContacts(response.data);
      setTotalCount(response.meta.total_count);
      setLoader(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getContactList();
  }, []);

  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      columns={columns}
      scroll={{ y: "calc(100vh - 310px)" }}
      dataSource={contacts}
      loading={loader}
      total={totalCount}
      onDataFetch={getContactList}
      extraButton={
        <AlectifyButton
          text={AlectifyText.NEW_CONTACT}
          type="primary"
          icon={<PlusOutlined />}
          onClick={openCreationDrawer}
        />
      }
      columnFilterAsString
      customSearchKey="search"
    />
  );
};

ContactsTable.defaultProps = {
  allowCreation: true,
};

export default memo(ContactsTable);
