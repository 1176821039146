import { isEmpty } from "lodash";
import { IIncidentDetail } from "pages/incident-report/Incident.interface";
import { IUser } from "redux/components/Auth";

export const createEmailBodyForIncidentReport = (
  incidentReport: IIncidentDetail,
  sender: IUser,
) => {
  if (!isEmpty(incidentReport)) {
    return `
  <h2 class="incident-title"><span>${incidentReport.title}</span></h2>
  <p class="report-id">Report ID: <span>${incidentReport.incidentId}</span></p>
  <p class="report-id">Status: <span>${incidentReport.status}</span></p>
  <p class="created-by">Created By: <span>${incidentReport.createdBy
    ?.first_name} ${incidentReport.createdBy?.last_name}</span></p>
  <p class="project-name">Site: <span>${incidentReport.project.name}</span></p>
  <p class="project-name">CC: <span>${sender.email}</span></p>
  ${incidentReport.description || ""}
  
  <br />
  <p>Regards,</p>
  <p>${sender.first_name} ${sender.last_name}</p>
  `;
  }
  return "";
};
