import React, { memo, useEffect, useState } from "react";
import { Col, Form, Row, Spin, Upload } from "antd";
import AlectifyInput from "components/shared/input";
import AlectifyDatePicker from "components/shared/datepicker";
import AlectifySelect from "components/shared/select";
import AlectifyButton from "components/shared/button";
import TextEditor from "components/text-editor/TextEditor";
import AttachmentIcon from "components/icons/AttachmentIcon";
import SelectMasterProject from "components/shared/master-project-select";
import TeamMembersDropdown from "components/shared/team-members-dropdown";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { isEmpty } from "lodash";
import { setActiveMasterProject } from "redux/components/common/sources";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import { TaskPriorityEnum } from "enums";
import {
  capitalizeFirstLetter,
  snakeCaseToTitle,
  extractTeamMembersId,
} from "utils/helpers";
import { ICreateIncidentReportProps } from "pages/incident-report/Incident.interface";
import AlectifyText from "static/texts.json";
import "./IncidentReport.scss";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

const CreateIncidentReport: React.FC<ICreateIncidentReportProps> = memo(
  ({
    formInstance,
    onReportSubmit,
    onUserTypeChange,
    initialFileList,
    reportRecord,
    onSaveAsDraft,
    isEditModeRef,
    onCloseDrawer,
    onDocumentDelete,
  }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [fileList, setFileList] = useState(initialFileList || []);
    const { activeMasterProject } = useSelector(
      ({ common }: IRootState) => common,
    );
    useEffect(() => {
      if (activeMasterProject) {
        dispatch(setActiveMasterProject(activeMasterProject));
        formInstance.setFieldValue("masterProject", activeMasterProject.id);
      }
    }, [activeMasterProject, dispatch, formInstance]);

    useEffect(() => {
      setFileList(initialFileList || []);
    }, [initialFileList]);

    const handleFileChange = ({ fileList }: any) => {
      setFileList(fileList);
    };

    const handleSubmit = async (values: any) => {
      try {
        setIsLoading(true);
        await onReportSubmit(values);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        onCloseDrawer();
      }
    };

    const handleDraftSubmit = async (values: any) => {
      try {
        setIsLoading(true);
        await onSaveAsDraft(values);
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
        onCloseDrawer();
      }
    };

    const triggerSubmit = async (isDraft: boolean, onCancel = false) => {
      try {
        if (onCancel) {
          DrawerServiceInstance.close(AlectifyDrawer, {
            name: DRAWER_CONSTANTS.CREATE_INCIDENT_REPORT,
          });
          return;
        }
        await formInstance.validateFields();
        const values = formInstance.getFieldsValue();
        isDraft ? await handleDraftSubmit(values) : await handleSubmit(values);
      } catch (error) {
        console.error(error);
      }
    };

    const handleDelete = async (file: any) => {
      try {
        if (!isEmpty(file)) {
          setIsLoading(true);
          await onDocumentDelete(file?.id);
          setFileList((prevFileList: any) =>
            prevFileList.filter((f: any) => f.id !== file.id),
          );
        }
      } catch (error) {
        console.error(error);
      } finally {
        setIsLoading(false);
      }
    };

    return (
      <Spin spinning={isLoading}>
        <div className="create-incident-container">
          <Form form={formInstance} layout="vertical">
            <Row className="create-row-container">
              <Col span={6} className="create-col-containers">
                <Row gutter={16}>
                  <Col span={24}>
                    <SelectMasterProject
                      onSelect={(value, masterProject) => {
                        dispatch(setActiveMasterProject(masterProject));
                        formInstance.setFieldValue("teamMembers", []);
                      }}
                      disabled={!isEmpty(reportRecord)}
                      onChange={() =>
                        formInstance.setFieldValue("subProject", "")
                      }
                      rules={BASIC_REQUIRED_VALIDATION_RULES}
                    />
                  </Col>
                  <Col span={24}>
                    <AlectifyInput
                      label="Incident Title"
                      type="text"
                      name="title"
                      rules={[{ required: true, message: "Required" }]}
                      placeholder="e.g. Doe"
                    />
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="incidentDate"
                      label="Incident Date"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <AlectifyDatePicker className="full-width-picker" />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Form.Item
                      name="teamMembers"
                      label="Team Members"
                      rules={[{ required: true, message: "Required" }]}
                    >
                      <TeamMembersDropdown
                        formItem={false}
                        selectedOptions={extractTeamMembersId(
                          reportRecord?.teamMembers,
                        )}
                        projectId={
                          reportRecord?.project?.id || activeMasterProject?.id
                        }
                        showTeamOption={isEmpty(reportRecord)}
                        onSelectType={onUserTypeChange}
                        disable={!isEmpty(reportRecord)}
                      />
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <AlectifySelect
                      name="priority"
                      label="Priority"
                      defaultValue={reportRecord?.priority}
                      placeholder="Please Select"
                      rules={BASIC_REQUIRED_VALIDATION_RULES}
                      options={[
                        {
                          label: capitalizeFirstLetter(
                            snakeCaseToTitle(TaskPriorityEnum.NORMAL),
                          ),
                          value: TaskPriorityEnum.NORMAL,
                        },
                        {
                          label: capitalizeFirstLetter(
                            TaskPriorityEnum.CRITICAL,
                          ),
                          value: TaskPriorityEnum.CRITICAL,
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
              <Col span={18} className="create-col-containers">
                <div className="text-editor-container">
                  <TextEditor
                    form={formInstance}
                    name="description"
                    label="Briefly Explain Incident:"
                    initialValue={formInstance.getFieldValue("description")}
                    rules={BASIC_REQUIRED_VALIDATION_RULES}
                  />
                  <Form.Item name="documents">
                    <Upload
                      multiple
                      accept="*"
                      onChange={handleFileChange}
                      showUploadList
                      fileList={fileList.map((file: any) => ({
                        ...file,
                        name: file.fileName || file.name,
                      }))}
                      beforeUpload={() => false}
                      onRemove={handleDelete}
                    >
                      <div className="file-attachment-container active-container">
                        <AttachmentIcon />
                        <span className="ml-5">{AlectifyText.ATTACH_FILE}</span>
                      </div>
                    </Upload>
                  </Form.Item>
                </div>
                <div className="create-buttons">
                  <AlectifyButton
                    text={
                      isEditModeRef.current.active
                        ? "Cancel"
                        : AlectifyText.SAVE_DRAFT
                    }
                    type="default"
                    htmlType="button"
                    className="light-blue-button mr-10"
                    onClick={() =>
                      triggerSubmit(true, isEditModeRef.current.active)
                    }
                  />
                  <AlectifyButton
                    text={
                      isEditModeRef.current.active
                        ? AlectifyText.UPDATE_REPORT
                        : AlectifyText.SUBMIT_REPORT
                    }
                    type="primary"
                    htmlType="button"
                    onClick={() => triggerSubmit(false)}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
    );
  },
);

export default CreateIncidentReport;
