const SearchIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M16.9399 15.5624L13.4474 12.0699C14.4549 10.7675 14.9999 9.17496 14.9999 7.49997C14.9999 5.49498 14.2174 3.61498 12.8024 2.19749C11.3874 0.779996 9.50246 0 7.49997 0C5.49748 0 3.61248 0.782496 2.19749 2.19749C0.779996 3.61248 0 5.49498 0 7.49997C0 9.50246 0.782496 11.3874 2.19749 12.8024C3.61248 14.2199 5.49498 14.9999 7.49997 14.9999C9.17496 14.9999 10.765 14.4549 12.0674 13.4499L15.5599 16.9399C15.579 16.959 15.6016 16.9741 15.6264 16.9844C15.6513 16.9947 15.678 17 15.7049 17C15.7318 17 15.7585 16.9947 15.7834 16.9844C15.8083 16.9741 15.8309 16.959 15.8499 16.9399L16.9399 15.8524C16.959 15.8334 16.9741 15.8108 16.9844 15.7859C16.9947 15.761 17 15.7343 17 15.7074C17 15.6805 16.9947 15.6538 16.9844 15.6289C16.9741 15.6041 16.959 15.5815 16.9399 15.5624ZM11.46 11.46C10.4 12.5174 8.99496 13.0999 7.49997 13.0999C6.00497 13.0999 4.59998 12.5174 3.53998 11.46C2.48249 10.4 1.89999 8.99496 1.89999 7.49997C1.89999 6.00497 2.48249 4.59748 3.53998 3.53998C4.59998 2.48249 6.00497 1.89999 7.49997 1.89999C8.99496 1.89999 10.4025 2.47999 11.46 3.53998C12.5174 4.59998 13.0999 6.00497 13.0999 7.49997C13.0999 8.99496 12.5174 10.4025 11.46 11.46Z"
        fill="#545454"
      />
    </svg>
  );
};

export default SearchIcon;
