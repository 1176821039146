import { IMasterProjectInitialState } from "./MasterProject.interface";

export const masterProjectInitialState: IMasterProjectInitialState = {
  data: [],
  message: "",
  meta: {
    total_count: 0,
    total_pages: 0,
    next: "",
    previous: "",
  },
  dropdownMasterProjects: [],
};
