import React from "react";
import { Typography } from "antd";
import AlectifyButton from "../button";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import AlectifyText from "static/texts.json";
import { ISuccessfulMessageProps } from "./SuccessfulMessage.interface";
import { MODAL_NAMES } from "constants/modal.constants";
import "./SuccessfulMessage.scss";

const SuccessfulMessageModal: React.FC<ISuccessfulMessageProps> = ({
  text,
  modalName,
}) => {
  const actionCompleted = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.SUCCESSFUL_MESSAGE_MODAL,
    });

    setTimeout(() => {
      ModalServiceInstance.close(AlectifyModal, {
        name: modalName,
      });
    }, 100);
  };

  return (
    <>
      <div className="text-align-center">
        <embed
          type="video/webm"
          src="https://lottie.host/embed/ad8833ff-669e-4ef7-9059-8d6cd789ae8c/0SJMiqdd72.json"
          width="400"
          height="300"
        />
      </div>
      <Typography.Title level={2} className="success-text">
        {AlectifyText.SUCCESSFUL}
      </Typography.Title>
      <Typography.Text className="info-text text-align-center">
        {text}
      </Typography.Text>
      <div className="done-button-container ">
        <AlectifyButton
          type="primary"
          className="done-button"
          onClick={actionCompleted}
          text={"Done"}
        />
      </div>
      ,
    </>
  );
};

export default SuccessfulMessageModal;
