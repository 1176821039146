import { Space, Tag } from "antd";
import OutOfStockIcon from "components/icons/OutOfStockIcon";
import AlectifyText from "static/texts.json";

const OutOfStock: React.FC = () => {
  return (
    <Tag color={"#D92323"} className="round-tag">
      <Space size={3}>
        <OutOfStockIcon /> {AlectifyText.OUT_OF_STOCK}
      </Space>
    </Tag>
  );
};

export default OutOfStock;
