import { memo } from "react";
import { Typography } from "antd";
import { ITextToLinkProp } from "./TextToLink.interface";
import "./TextToLink.scss";

const TextToLink: React.FC<ITextToLinkProp> = (props: ITextToLinkProp) => {
  return (
    <Typography.Text
      {...props}
      onClick={props.onClick}
      underline={props.underline}
      className={`alectify-link-to-text ${props.className}`}
    >
      {props.text}
    </Typography.Text>
  );
};

TextToLink.defaultProps = {
  className: "",
  underline: true,
  onClick: () => {},
};

export default memo(TextToLink);
