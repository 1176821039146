export const pmInternalInitialState = {
  fetching: false,
  data: [],
  message: "",
  meta: {
    totalPages: 0,
    totalItems: 0,
  },
  pmAttachments: {
    // imagesDocuments: [],
    // videosDocuments: [],
    // pmReportsDocuments: [],
    // pmInstructionsDocuments: [],
    // commercialDocuments: [],
    data: [],
    fetching: false,
  },
  pendingCounts: 0,
  skippedCounts: 0,
  completedCounts: 0,

  pmDetail: {
    data: null,
    fetching: false,
  },
  todayPmInternal: [],
};
