import { memo } from "react";

const BulbIcon: React.FC<any> = (props: any) => {
  if (props.active) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
      >
        <circle cx="20" cy="20" r="20" fill="#E8F1FD" />
        <ellipse
          cx="20.1571"
          cy="18.5567"
          rx="13.1571"
          ry="13.3799"
          fill="url(#paint0_radial_1530_38668)"
        />
        <path
          d="M9.85352 31.9369C9.85352 30.1283 11.3197 28.6621 13.1283 28.6621H27.4645C29.2731 28.6621 30.7393 30.1283 30.7393 31.9369V31.9369H9.85352V31.9369Z"
          fill="#0E5378"
        />
        <path
          d="M7.56055 34.0168C7.56055 32.2585 8.986 30.833 10.7444 30.833H29.8497C31.608 30.833 33.0335 32.2585 33.0335 34.0168V34.0168H7.56055V34.0168Z"
          fill="#0D76AD"
        />
        <path
          d="M25.127 9.20117C28.0622 10.7301 32.9403 15.8648 28.9704 24.1717"
          stroke="white"
          strokeOpacity="0.14"
          strokeWidth="2"
          strokeLinecap="round"
        />
        <defs>
          <radialGradient
            id="paint0_radial_1530_38668"
            cx="0"
            cy="0"
            r="1"
            gradientUnits="userSpaceOnUse"
            gradientTransform="translate(20.1571 18.5567) rotate(90) scale(13.3799 13.1571)"
          >
            <stop stopColor="#BFECFF" />
            <stop offset="1" stopColor="#4EBFFF" />
          </radialGradient>
        </defs>
      </svg>
    );
  }
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#E8F1FD" />
      <ellipse
        cx="19.8439"
        cy="18.3802"
        rx="13.1564"
        ry="13.3802"
        fill="url(#paint0_radial_1542_44292)"
      />
      <path
        d="M9.53711 31.7602C9.53711 29.9516 11.0033 28.4854 12.812 28.4854H27.1468C28.9555 28.4854 30.4217 29.9516 30.4217 31.7602V31.7602H9.53711V31.7602Z"
        fill="#0E5378"
      />
      <path
        d="M7.24609 33.8402C7.24609 32.0817 8.67158 30.6562 10.43 30.6562H29.5337C31.2921 30.6562 32.7176 32.0817 32.7176 33.8402V33.8402H7.24609V33.8402Z"
        fill="#0D76AD"
      />
      <path
        d="M24.8086 9.02637C27.7437 10.5554 32.6215 15.6902 28.6518 23.9973"
        stroke="white"
        strokeOpacity="0.14"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id="paint0_radial_1542_44292"
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(19.8439 18.3802) rotate(90) scale(13.3802 13.1564)"
        >
          <stop stopColor="#DBDBDB" />
          <stop offset="1" stopColor="#B8B8B8" />
        </radialGradient>
      </defs>
    </svg>
  );
};

export default memo(BulbIcon);
