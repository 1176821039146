import { memo } from "react";

const FullScreenIcon: React.FC<{ className?: string; onClick?: () => void }> = (
  props,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M18.2019 2.57858H15.2857C15.0565 2.57858 14.8366 2.48752 14.6746 2.32544C14.5125 2.16335 14.4214 1.94352 14.4214 1.7143C14.4214 1.48507 14.5125 1.26524 14.6746 1.10315C14.8366 0.94107 15.0565 0.850012 15.2857 0.850012H20.2853H20.2857V1.00001L18.2019 2.57858ZM18.2019 2.57858L10.3872 10.3864C10.3872 10.3864 10.3872 10.3864 10.3871 10.3864C10.3062 10.4668 10.2419 10.5623 10.198 10.6676C10.1542 10.7729 10.1316 10.8859 10.1316 11C10.1316 11.1141 10.1542 11.2271 10.198 11.3324C10.2418 11.4375 10.306 11.533 10.3868 11.6132C10.467 11.694 10.5625 11.7582 10.6676 11.802C10.7729 11.8458 10.8859 11.8684 11 11.8684C11.1141 11.8684 11.2271 11.8458 11.3324 11.802C11.4376 11.7581 11.5332 11.6938 11.6135 11.6129C11.6136 11.6129 11.6136 11.6128 11.6136 11.6128L18.2019 2.57858ZM20.8993 1.10152L20.8993 1.10152L21.15 1.71516L21 1.7143H21.15V1.71482C21.1506 1.60119 21.1288 1.48856 21.0858 1.38336C21.0428 1.27806 20.9794 1.18228 20.8993 1.10152ZM19.4214 3.805L19.5714 3.655V3.44287L19.4214 3.59287V3.805ZM21.15 17.4286V11C21.15 10.7708 21.0589 10.5509 20.8968 10.3889C20.7348 10.2268 20.5149 10.1357 20.2857 10.1357C20.0565 10.1357 19.8366 10.2268 19.6746 10.3889C19.5125 10.5509 19.4214 10.7708 19.4214 11V17.4286C19.4214 17.9571 19.2115 18.464 18.8377 18.8377C18.464 19.2115 17.9571 19.4214 17.4286 19.4214H4.57143C4.04289 19.4214 3.536 19.2115 3.16226 18.8377C2.78853 18.464 2.57857 17.9571 2.57857 17.4286V4.57144C2.57857 4.0429 2.78853 3.53601 3.16226 3.16228C3.536 2.78854 4.04289 2.57858 4.57143 2.57858H11C11.2292 2.57858 11.4491 2.48752 11.6111 2.32544C11.7732 2.16335 11.8643 1.94352 11.8643 1.7143C11.8643 1.48507 11.7732 1.26524 11.6111 1.10316C11.4491 0.94107 11.2292 0.850012 11 0.850012H4.57143C3.58444 0.850012 2.63788 1.24209 1.93998 1.93999C1.24208 2.6379 0.85 3.58445 0.85 4.57144V17.4286C0.85 18.4156 1.24208 19.3621 1.93998 20.06C2.63788 20.7579 3.58444 21.15 4.57143 21.15H17.4286C18.4155 21.15 19.3621 20.7579 20.06 20.06C20.7579 19.3621 21.15 18.4156 21.15 17.4286Z"
        fill="#252525"
        stroke="#252525"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default memo(FullScreenIcon);
