import React, { useState, useEffect, useMemo, useCallback } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ICalendarEventState, ICalendarFilters } from "./Calendar.interface";
import {
  ICalendarEvent,
  IPmCalendarEventResponse,
} from "services/calendar/calendar.interface";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import { useLocation, useNavigate } from "react-router-dom";
import { Popover, Spin } from "antd";
import EventPopoverContent from "./EventPopoverContent";
import { ROUTES } from "routes/Routes.constants";
import { get } from "lodash";
import dayjs from "dayjs";
import CalendarToolbar from "./CalendarToolbar";
import "./Calendar.scss";
import { CalendarEventStatusEnum } from "enums";
import CheckIcon from "components/icons/CheckIcon";
// import { WarningIcon } from "components/icons";

const EventComponent = ({ event }: any) => {
  const projectColor = event?.master_project[0]?.color || "grey";

  const isDelayed =
    event.event_status === CalendarEventStatusEnum.DELAYED ||
    event.event_status === CalendarEventStatusEnum.DELAYED_COMPLETED;

  const isCompleted =
    event.event_status === CalendarEventStatusEnum.ON_TIME_COMPLETED ||
    event.event_status === CalendarEventStatusEnum.DELAYED_COMPLETED;

  const textColor = isDelayed ? "#F00707" : isCompleted ? "black" : "inherit";
  // const backgroundColor = isDelayed
  //   ? "inherit"
  //   : isCompleted
  //   ? "#d4faea"
  //   : "inherit";

  const IconComponent =
    isCompleted || isDelayed ? (isCompleted ? CheckIcon : null) : null;
  const iconFillColor = isDelayed ? "#F73E3E" : "#0BB731";
  return (
    <Popover
      content={<EventPopoverContent event={event} />}
      trigger="hover"
      placement="left"
      arrow={false}
      overlayClassName="events-popover"
    >
      <div className="custom-event-title">
        <div
          className="title-border"
          style={{
            borderLeft: `4px solid ${projectColor}`,
          }}
        />
        <span
          className="link-text-calendar text-ellipsis"
          style={{
            color: textColor,
            // background: backgroundColor,
            padding: "2px 5px",
            borderRadius: "5px",
          }}
        >
          <span>
            {IconComponent && (
              <span>
                <IconComponent height={12} width={12} fill={iconFillColor} />
              </span>
            )}
            <span className="ml-3 text-ellipsis">
              {event.title || "(No title)"}
            </span>
          </span>
        </span>
      </div>
    </Popover>
  );
};

const FullMonthViewCalendar: React.FC<ICalendarFilters> = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const startDateQ = new URLSearchParams(location.search).get("start") || "";
  const endDateQ = new URLSearchParams(location.search).get("end") || "";
  const calendarType =
    new URLSearchParams(location.search).get("calendarType") || "";

  const localizer = momentLocalizer(moment);
  const { common } = useSelector((state: IRootState) => state);

  useEffect(() => {
    if (startDateQ) {
      getCalendarEvent();
    }
  }, [
    startDateQ,
    endDateQ,
    calendarType,
    common.showCalendarMyWorkOrders,
    common.activeMasterProject?.id,
  ]);
  const [calendarEventMonthly, setCalendarEventMonthly] =
    useState<ICalendarEventState>({
      data: [],
      fetching: false,
    });

  const selectedMonthStartDate = moment({
    month: Number(props.miniMonthlyCalendarMonthAndYear?.current.month),
    year: props.miniMonthlyCalendarMonthAndYear?.current.year,
  });

  const getCalendarEvent = async () => {
    setCalendarEventMonthly({ fetching: true });
    try {
      const startDate = selectedMonthStartDate
        .clone()
        .startOf("week")
        .format("YYYY-MM-DD");
      const endDate = selectedMonthStartDate
        .clone()
        .endOf("month")
        .endOf("week")
        .format("YYYY-MM-DD");
      const projectId = common.activeMasterProject?.id ?? "all";
      const subProjectId = "all";
      const options =
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
          ? {}
          : { global: "true" };

      const pmEvents: IPmCalendarEventResponse = await fetchPmCalendarEvents(
        projectId,
        subProjectId,
        startDate,
        endDate,
        options,
      );
      setCalendarEventMonthly({ fetching: false, data: pmEvents.data });
    } catch (error) {
      console.error(error);
      setCalendarEventMonthly({ fetching: false });
    }
  };

  const eventPropGetter = (event: any) => {
    return {
      className: "custom-box",
      style: {
        backgroundColor: "transparent",
        color: "black",
        border: "none",
        display: "block",
        fontSize: "12px",
        borderRadius: "0px",
      },
    };
  };

  const goToPmDetailPage = useCallback(
    (event: any) => {
      const {
        master_project: masterProject,
        sub_project: subProject,
        id: pmId,
        type: pmType,
      } = event;
      const masterProjectId = get(masterProject, "[0].id");
      const subProjectId = get(subProject, "[0].id");
      const url = `${ROUTES.MY_ITEMS}/pm/${masterProjectId}/${subProjectId}/${pmId}?pmType=${pmType}`;
      navigate(url);
    },
    [navigate],
  );

  const events = useMemo(
    () =>
      calendarEventMonthly?.data?.map((event: ICalendarEvent) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        title: event.title || "(No title)",
      })),
    [calendarEventMonthly.data],
  );

  const onChangeCalendarPrev = () => {
    const { month, year } = props.miniMonthlyCalendarMonthAndYear.current;

    const newStartDate = dayjs()
      .year(year)
      .month(month)
      .subtract(1, "month")
      .startOf("month");

    const firstVisibleDay = newStartDate.startOf("week");

    const newEndDate = newStartDate.endOf("month");

    const lastVisibleDay = newEndDate.endOf("week");

    const newMonth = newStartDate.month();
    const newYear = newStartDate.year();
    props.miniMonthlyCalendarMonthAndYear.current = {
      month: newMonth,
      year: newYear,
    };

    const params = new URLSearchParams(location.search);
    params.set("start", firstVisibleDay.format("YYYY-MM-DD"));
    params.set("end", lastVisibleDay.format("YYYY-MM-DD"));

    navigate(`?${params.toString()}`, { replace: true });
  };

  const onChangeCalendarNext = () => {
    const { month, year } = props.miniMonthlyCalendarMonthAndYear.current;

    const newStartDate = dayjs()
      .year(year)
      .month(month)
      .add(1, "month")
      .startOf("month");

    const firstVisibleDay = newStartDate.startOf("week");
    const newEndDate = newStartDate.endOf("month");
    const lastVisibleDay = newEndDate.endOf("week");

    const newMonth = newStartDate.month();
    const newYear = newStartDate.year();

    props.miniMonthlyCalendarMonthAndYear.current = {
      month: newMonth,
      year: newYear,
    };

    const params = new URLSearchParams(location.search);
    params.set("start", firstVisibleDay.format("YYYY-MM-DD"));
    params.set("end", lastVisibleDay.format("YYYY-MM-DD"));

    navigate(`?${params.toString()}`, { replace: true });
  };

  const dayPropGetter = (date: Date) => {
    const currentMonth = moment()
      .month(props.miniMonthlyCalendarMonthAndYear.current.month)
      .year(props.miniMonthlyCalendarMonthAndYear.current.year);
    const isCurrentMonth = currentMonth.isSame(moment(date), "month");

    const isToday = moment().isSame(moment(date), "day");

    return {
      style: {
        backgroundColor: isToday
          ? "#d3e7ff"
          : isCurrentMonth
          ? "inherit"
          : "#F5f5f5",
        color: isCurrentMonth ? "black" : "white",
      },
    };
  };

  return (
    <Spin spinning={calendarEventMonthly.fetching}>
      <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        onNavigate={() => {}}
        endAccessor="end"
        views={["month"]}
        defaultView="month"
        date={moment({
          year: props?.miniMonthlyCalendarMonthAndYear.current?.year,
          month: Number(props.miniMonthlyCalendarMonthAndYear.current?.month),
        }).toDate()}
        eventPropGetter={eventPropGetter}
        dayPropGetter={dayPropGetter}
        components={{
          event: EventComponent,
          toolbar: (value: any) => (
            <CalendarToolbar
              {...value}
              endDateQ={endDateQ}
              startDateQ={startDateQ}
              calendarEvents={calendarEventMonthly}
              selectedMonthStartDate={selectedMonthStartDate}
              calendarSwitch={props.calendarSwitch}
              miniMonthlyCalendarMonthAndYear={
                props.miniMonthlyCalendarMonthAndYear
              }
              onChangeCalendarPrev={onChangeCalendarPrev}
              onChangeCalendarNext={onChangeCalendarNext}
            />
          ),
        }}
        onSelectEvent={goToPmDetailPage}
        popup
        dayLayoutAlgorithm="no-overlap"
      />
    </Spin>
  );
};

export default FullMonthViewCalendar;
