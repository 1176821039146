import { memo } from "react";

const ApproverIcon: React.FC = () => {
  return (
    <svg
      width="14"
      height="11"
      viewBox="0 0 14 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Vector"
        d="M12.9419 3.19955C13.5247 2.62217 13.5247 1.68031 12.9419 1.10294C12.3382 0.504819 11.3553 0.538938 10.7945 1.17748L6.36972 6.21579C5.69907 6.97942 4.49569 6.93242 3.88664 6.11881L3.14616 5.12961C2.63092 4.4413 1.62623 4.36711 1.01546 4.97226C0.456163 5.52642 0.456174 6.43036 1.01548 6.9845L3.94233 9.88431C4.56594 10.5022 5.57091 10.5022 6.19453 9.88433L12.9419 3.19955Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(ApproverIcon);
