export enum UserTypeEnum {
  ADMIN = 1,
  INTERNAL = 2,
  EXTERNAL = 3,
}

export const USER_ACCESS_TYPES: { [key: number]: string } = {
  1: "Admin",
  2: "Internal",
  3: "External",
};
