import AssetDocumentsTable from "components/shared/asset-documents-table";
import { useParams } from "react-router-dom";

interface IWorkOrderDocumentsProps {
  activeTab: string;
  fetchAllCounts?: () => void;
}

const WorkOrderDocuments: React.FC<IWorkOrderDocumentsProps> = ({
  activeTab,
  fetchAllCounts,
}) => {
  const { equipmentId, subProjectId } = useParams();
  return (
    <>
      <AssetDocumentsTable
        activeTab={activeTab}
        workOrderAttachments
        assetId={equipmentId}
        subProjectId={subProjectId}
        scroll={{ x: 1200 }}
        rowSelection
        downloadable
        fetchAllCounts={fetchAllCounts}
      />
    </>
  );
};

export default WorkOrderDocuments;
