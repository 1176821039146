import React, { ReactElement, useState } from "react";
import { Layout } from "antd";
import {
  contentStyles,
  footerStyle,
  headerStyle,
  mainLayoutContainerStyles,
} from "./Layout.syles";
import MainHeader from "./Header";
import MainFooter from "./Footer";
import MainSidebar from "./Sidebar";
import MasterProjectSidebarListing from "pages/master-project/sidebar-listing";
import AssetDetailSidebarListing from "components/shared/asset-detail/AssetDetailSidebarListing";

const { Content, Header, Footer } = Layout;

const MainLayout: React.FC<{ children: ReactElement }> = (props) => {
  const [collapsed, setCollapsed] = useState(true);

  return (
    <Layout style={mainLayoutContainerStyles}>
      <div style={{ width: "80px" }}>
        <MainSidebar collapsed={collapsed} setCollapsed={setCollapsed} />
      </div>
      <Layout>
        <Header style={headerStyle}>
          <MainHeader />
        </Header>
        <MasterProjectSidebarListing collapsed={collapsed} />
        <AssetDetailSidebarListing />
        <Content style={contentStyles}>{props.children}</Content>
        <Footer style={footerStyle}>
          <MainFooter />
        </Footer>
      </Layout>
    </Layout>
  );
};

export default MainLayout;
