import { memo } from "react";
import AlectifyTable from "components/shared/table";
import useLicenseAndPermissions from "./effects/useLicenseAndPermissions";
import { IUseLicenseAndPermissionsProps } from "./Admin.interface";

const LicenseAndPermissionsTable: React.FC<IUseLicenseAndPermissionsProps> = (
  props,
) => {
  const columns = useLicenseAndPermissions(props);
  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      columns={columns}
      dataSource={props.allUsers?.data}
      total={props.allUsers?.meta?.total_count || 0}
      onDataFetch={props.getUsers}
      customSearchKey="search"
      loading={props.allUsers.fetching}
      scroll={{ y: "calc(100vh - 375px)" }}
    />
  );
};

export default memo(LicenseAndPermissionsTable);
