import { Breadcrumb, Space, Typography } from "antd";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import DataRoundProjectTabs from "./tabs";
import { useEffect, useState } from "react";
import { IDataRoundProject } from "services/data-round/data-round.interface";
import { getDRProjectDetails } from "services/data-round/data-round.service";
import "./style.scss";
const DataRoundProjectDetail: React.FC = () => {
  const [project, setProject] = useState<IDataRoundProject | null>(null);

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const navigate = useNavigate();
  const { projectId } = useParams();

  const getDetails = async () => {
    try {
      const response = await getDRProjectDetails(projectId || "");
      if (response.success) {
        setProject(response.data);
      }
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    if (projectId) {
      getDetails();
    }
  }, [activeMasterProject]);

  return (
    <div className="data-rounds-main">
      <Breadcrumb
        items={[
          {
            title: AlectifyText.DATA_ROUND,
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: activeMasterProject?.name,
            href: `#`,
            onClick: () =>
              navigate(`${ROUTES.DATA_ROUND}/${activeMasterProject?.id}`),
          },
          { title: project?.name },
        ]}
      />
      <div className="sub-project-detail-header">
        <Space size={10}>
          <Typography.Title>{project?.name}</Typography.Title>
        </Space>
      </div>
      <DataRoundProjectTabs />
    </div>
  );
};

export default DataRoundProjectDetail;
