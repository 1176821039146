import { pmHttp } from "utils/client";
import { IParams } from "redux/interfaces";
import {
  GET_NOTIFICATIONS,
  GET_UNREAD_NOTIFICATIONS_COUNT,
  MARK_NOTIFICATION_AS_READ,
} from "./notifications.endpoints";
import { INotificationsResponse } from "./notifications.interface";
import { AxiosResponse } from "axios";
import { INotificationCountResponse } from "redux/components/notifications";

/**
 * Fetches notifications from the server.
 * @param params Optional parameters for customizing the request (e.g., query parameters).
 * @returns A promise that resolves to an object representing the response containing notifications.
 * @throws Throws an error if the request fails.
 */
export const fetchNotifications = async (
  params?: IParams,
): Promise<INotificationsResponse> => {
  try {
    const url = GET_NOTIFICATIONS;
    const response: AxiosResponse<INotificationsResponse> = await pmHttp.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the count of unread notifications from the server.
 * @returns A promise that resolves to an object representing the response from the server.
 *          The response typically contains the count of unread notifications.
 * @throws Throws an error if the request to fetch the unread notifications count fails.
 */
export const fetchUnreadNotificationsCount =
  async (): Promise<INotificationCountResponse> => {
    try {
      const url = GET_UNREAD_NOTIFICATIONS_COUNT;
      const response: AxiosResponse<INotificationCountResponse> =
        await pmHttp.get(url);
      return response.data;
    } catch (error) {
      throw error;
    }
  };

/**
 * Marks notifications with the specified IDs as read.
 * @param body An object containing the IDs of the notifications to be marked as read.
 * @returns A promise that resolves to an object representing the response from the server.
 *          The response typically contains data indicating the success or failure of the operation.
 * @throws Throws an error if the request to mark notifications as read fails.
 */
export const markNotificationsAsRead = async (body: {
  ids: string[];
  isSystemGenerated: boolean;
}): Promise<{
  data: any;
}> => {
  try {
    const url = MARK_NOTIFICATION_AS_READ;
    const response: AxiosResponse<{ data: any }> = await pmHttp.post(url, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};
