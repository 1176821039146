import { IAssetsState } from "./assets.interface";

export const assetsInitialState: IAssetsState = {
  assets: {
    fetching: false,
    data: [],
    message: "",
    meta: {
      next: "",
      previous: "",
      total_pages: 0,
      total_count: 0,
    },
  },
  assetDetail: {
    data: null,
    fetching: false,
  },
};
