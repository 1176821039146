// chartConstants.ts
export const MONTHS_2023 = [
  "Jan,2023",
  "Feb,2023",
  "Mar,2023",
  "Apr,2023",
  "May,2023",
  "Jun,2023",
  "Jul,2023",
  "Aug,2023",
  "Sep,2023",
  "Oct,2023",
  "Nov,2023",
  "Dec,2023",
];

export const CategoryLabels: { [key: string]: string } = {
  CORRECTIVE_MAINTENANCE: "Corrective Maintenance",
  PREVENTIVE_MAINTENANCE: "Preventive Maintenance",
};
