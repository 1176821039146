//libs
import { Avatar, Col, Form, Row, Space, Upload } from "antd";
import { useRef, useState } from "react";
import type { FC } from "react";
import type { Identifier, XYCoord } from "dnd-core";
import { useDrag, useDrop } from "react-dnd";
import { debounce } from "lodash";
import { useForm } from "antd/lib/form/Form";
import { LoadingOutlined } from "@ant-design/icons";

//components
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import ViewImage from "components/shared/view-image/ViewImage";
import AlectifyInput from "components/shared/input";
import { getBase64 } from "components/shared/profile/Profile.helper";
import DeleteIcon from "components/icons/DeleteIcon";

//services
import { IProcedureStepCreationPayload } from "services/procedures/procedures.interface";
import { updateProcedureSteps } from "services/procedures/procedures.service";

//interfaces
import { ICardProps, IDragItem } from "./ProcedureCheckList.interface";

//constants
import { MESSAGES } from "constants/messages";
import DragIcon from "components/icons/DragIcon";
import CameraIcon from "components/icons/CameraIcon";

//styles
import "./CheckListCard.scss";

export const Card: FC<ICardProps> = ({
  id,
  index,
  text,
  procedureId,
  order,
  imageUrl,
  draft,
  moveCard,
  removeCard,
  addNew,
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [image, setImageUrl] = useState<string | null>(imageUrl || "");
  const [loader, setLoader] = useState<boolean>(false);

  const [FormInstance] = useForm();
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const dialogRef = useRef();
  const [{ handlerId }, drop] = useDrop<
    IDragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: "card",
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: IDragItem, monitor) {
      if (!ref.current) {
        return;
      }

      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Update the index in your state or wherever you store the list of cards
      // This assumes you have a function like `updateCardIndex` to update the index
      moveCard(dragIndex, hoverIndex);

      // Update the index property of the dragged item
      item.index = hoverIndex;
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: "card",
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  const handleImageUpload: any = async (fileObj: any, index: number) => {
    try {
      setLoader(true);
      FormInstance.setFieldValue("image", fileObj.fileList[0].originFileObj);
      if (fileObj.fileList.length > 0) {
        handleImagePreview(fileObj.fileList[0].originFileObj);
        let payload: IProcedureStepCreationPayload = {
          name: text,
          order: String(index + 1),
          procedureLibraryId: procedureId || "",
          image: FormInstance.getFieldValue("image"),
        };
        const formData = new FormData();
        formData.append("image", payload.image || "");
        const response = await updateProcedureSteps(id, formData);
        if (response.status) {
          setLoader(false);
        }
      }
    } catch (ex) {
      console.log(ex);
    }
  };
  const handleImagePreview = async (file: any) => {
    if (file) {
      file.preview = await getBase64(file);
      setPreviewImage(file.preview as string);
    }
  };

  const debouncedOnChange = debounce((value) => {
    // Handle the changed value here
    onCreateStep(value);
  }, 300);

  const onCreateStep = async (value: string) => {
    let payload: IProcedureStepCreationPayload = {
      name: value,
      order: String(index + 1),
      procedureLibraryId: procedureId || "",
    };
    FormInstance.setFieldValue("name", value);
    try {
      const formData = new FormData();
      formData.append("name", payload.name);
      formData.append("order", payload.order);
      formData.append("procedureLibraryId", payload.procedureLibraryId);
      await updateProcedureSteps(id, formData);
    } catch (ex) {
      console.log(ex);
    }
  };

  /* useEffect(() => {
    FormInstance.setFieldValue("name", text);
    FormInstance.setFieldValue("serial-number", (order && order) || index + 1);
  }, [procedureId, index]); */

  return (
    <div className="alectify-checklist-card-container">
      <div
        ref={ref}
        style={{ opacity }}
        data-handler-id={handlerId}
        className="alectify-checklist-dnd-cards"
      >
        <Form form={FormInstance}>
          <Row justify={"start"} align={"middle"} gutter={15}>
            <Col span={1} className="alectify-checklist-dnd-cards-holder-icon">
              <DragIcon />
            </Col>
            <Col span={21}>
              <Row justify={"start"} align={"middle"}>
                <Col span={1}>{index + 1}</Col>
                <Col span={23}>
                  <AlectifyInput
                    className="alectify-checklist-jop-description-input mb-0"
                    name={"name"}
                    autoFocus
                    placeholder="Description"
                    type="text"
                    defaultValue={text}
                    onChange={(e) => debouncedOnChange(e.target.value)}
                    rules={[
                      {
                        required: true,
                        message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                          "{fieldName}",
                          "Job Description",
                        ),
                      },
                    ]}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") addNew();
                      return;
                    }}
                  />
                </Col>
              </Row>
            </Col>
            <Col span={2} className="alectify-checklist-dnd-cards-close-icon">
              <Space>
                <div style={{ position: "relative", display: "inline-block" }}>
                  {previewImage || image ? (
                    <div className="image-viewer text-align-center">
                      {!loader ? (
                        <Avatar
                          size={45}
                          src={previewImage || image}
                          onClick={() => {
                            ModalServiceInstance.open(AlectifyModal, {
                              name: "view-image",
                              title: "Image",
                              footer: null,
                              onCancel: () =>
                                ModalServiceInstance.close(AlectifyModal, {
                                  name: "view-image",
                                }),
                              children: (
                                <ViewImage
                                  imageUrl={previewImage || image || ""}
                                />
                              ),
                            });
                          }}
                        />
                      ) : (
                        <div className="text-align-center">
                          <LoadingOutlined />
                        </div>
                      )}
                      <span
                        className="remove-text"
                        onClick={() => {
                          setPreviewImage(null);
                          setImageUrl(null);
                        }}
                      >
                        Remove
                      </span>
                    </div>
                  ) : (
                    <Avatar
                      size={45}
                      className="alectify-procedure-image ml-15"
                    >
                      <Upload
                        maxCount={1}
                        accept=".png,.jpg,jpeg"
                        ref={dialogRef}
                        showUploadList={false}
                        beforeUpload={() => false}
                        onChange={(e) => handleImageUpload(e, index)}
                      >
                        <div className="camera-icon">
                          <CameraIcon />
                        </div>
                      </Upload>
                    </Avatar>
                  )}
                </div>
                {/* <>
                {editing ? (
                  <AlectifyButton
                    loading={loader}
                    onClick={() => onCreateStep(index)}
                    text={"Save"}
                    type="default"
                    className="light-blue-button"
                  />
                ) : (
                  <EditIconFilledIcon
                    onClick={() => {
                      setEditing(true);
                    }}
                  />
                )}
              </> */}
              </Space>
            </Col>
            {/* <Col
            span={2}
            className="alectify-checklist-dnd-cards-close-icon text-align-center"
          ></Col> */}
          </Row>
        </Form>
      </div>
      <div onClick={() => removeCard(id, draft)}>
        <DeleteIcon />
      </div>
    </div>
  );
};
