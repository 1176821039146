import moment from "moment";
import { Spin } from "antd";
import { memo, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import {
  ICalendarEvent,
  IPmCalendarEventResponse,
} from "services/calendar/calendar.interface";
import { fetchPmCalendarEvents } from "services/calendar/calendar.service";
import { ICalendarEventState, ICalendarFilters } from "./Calendar.interface";
import CalendarEvent from "./CalendarEvent";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import dayjs from "dayjs";

import "./Calendar.scss";
import CalendarToolbar from "./CalendarToolbar";
import { SERVER_DATE_FORMAT } from "constants/index";

const WeeklyCalendar: React.FC<ICalendarFilters> = (
  props: ICalendarFilters,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const startDateQ = new URLSearchParams(location.search).get("start") || "";
  const endDateQ = new URLSearchParams(location.search).get("end") || "";
  const calendarType =
    new URLSearchParams(location.search).get("calendarType") || "";

  const localizer = momentLocalizer(moment);
  const { common } = useSelector((state: IRootState) => state);

  useEffect(() => {
    if (startDateQ) {
      getCalendarEvent();
    }
  }, [
    startDateQ,
    calendarType,
    endDateQ,
    common.showCalendarMyWorkOrders,
    common.activeMasterProject?.id,
  ]);

  const [calendarEvents, setCalendarEvents] = useState<ICalendarEventState>({
    data: [],
    fetching: false,
  });

  const getCalendarEvent = async () => {
    try {
      let pmEvents: IPmCalendarEventResponse;
      setCalendarEvents({ fetching: true });

      const startDate = dayjs(startDateQ)
        .subtract(1, "day")
        .format(SERVER_DATE_FORMAT); // Adjust start date
      const endDate = dayjs(endDateQ).add(1, "day").format(SERVER_DATE_FORMAT); // Adjust end date
      if (
        common.showCalendarMyWorkOrders ||
        common.showCalendarMyWorkOrders === undefined
      ) {
        [pmEvents] = await Promise.all([
          fetchPmCalendarEvents(
            common.activeMasterProject?.id ?? "all",
            "all",
            startDate,
            endDate,
          ),
        ]);
        setCalendarEvents({
          fetching: false,
          data: [...pmEvents.data],
        });
      } else {
        pmEvents = await fetchPmCalendarEvents(
          common.activeMasterProject?.id ?? "all",
          "all",
          startDate,
          endDate,
          { global: "true" },
        );
        setCalendarEvents({
          fetching: false,
          data: [...pmEvents.data],
        });
      }
    } catch (error) {
      console.log(error);
      setCalendarEvents({ fetching: false });
    }
  };

  const dayStyleGetter = (date: Date) => {
    const formattedSelectedDate = new Date(
      props?.selectedDate && props?.selectedDate,
    );
    const isSelected = dayjs(date).isSame(formattedSelectedDate, "day");
    return {
      style: {
        backgroundColor: isSelected ? "#e8f1fd" : "transparent",
      },
    };
  };

  /*  const onButtonChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setShowCalendarMyWorkOrders(value));
  }; */

  const onChangeCalendarPrev = () => {
    if (startDateQ && endDateQ) {
      const newStartDate = dayjs(startDateQ)
        .subtract(1, "week")
        .startOf("week");
      const newEndDate = dayjs(startDateQ).subtract(1, "week").endOf("week");

      const newMonth = newStartDate.month();
      const newYear = newStartDate.year();

      props.miniMonthlyCalendarMonthAndYear.current = {
        month: newMonth,
        year: newYear,
      };

      const params = new URLSearchParams(location.search);
      params.set("start", newStartDate.format("YYYY-MM-DD"));
      params.set("end", newEndDate.format("YYYY-MM-DD"));
      params.set("current", dayjs().format("YYYY-MM-DD"));
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  const onChangeCalendarNext = () => {
    if (startDateQ && endDateQ) {
      const newStartDate = dayjs(startDateQ).add(1, "week").startOf("week");
      const newEndDate = dayjs(startDateQ).add(1, "week").endOf("week");

      const newMonth = newStartDate.month();
      const newYear = newStartDate.year();

      props.miniMonthlyCalendarMonthAndYear.current = {
        month: newMonth,
        year: newYear,
      };

      const params = new URLSearchParams(location.search);
      params.set("start", newStartDate.format("YYYY-MM-DD"));
      params.set("end", newEndDate.format("YYYY-MM-DD"));
      navigate(`?${params.toString()}`, { replace: true });
    }
  };

  return (
    <Spin spinning={calendarEvents.fetching}>
      <Calendar
        localizer={localizer}
        dayPropGetter={dayStyleGetter}
        formats={{
          dayRangeHeaderFormat: ({ start, end }, culture, local: any) => {
            const startMonth = local.format(start, "MMM", culture);
            const endMonth = local.format(end, "MMM", culture);
            const startYear = local.format(start, "YYYY", culture);
            const endYear = local.format(end, "YYYY", culture);

            if (startYear === endYear && startMonth === endMonth) {
              return `${startMonth} ${startYear}`;
            } else if (startYear === endYear) {
              return `${startMonth}-${endMonth} ${startYear}`;
            } else if (startYear !== endYear) {
              return `${startMonth} ${startYear} - ${endMonth} ${endYear}`;
            } else {
              return `${startMonth}-${endMonth} ${startYear}`;
            }
          },
        }}
        events={calendarEvents?.data?.map((event: ICalendarEvent) => ({
          ...event,
          start: moment(event.start).format() as any,
          end: moment(event.end).format() as any,
          allDay: true,
        }))}
        startAccessor="start"
        endAccessor="end"
        views={["week"]}
        onNavigate={() => {}}
        defaultView="week"
        date={startDateQ ? moment(startDateQ).format() : moment().format()}
        components={{
          week: {
            header: ({ date, localizer }: any) =>
              localizer.format(date, "ddd, D"),
          },
          toolbar: (value: any) => {
            return (
              <CalendarToolbar
                {...value}
                endDateQ={endDateQ}
                startDateQ={startDateQ}
                calendarEvents={calendarEvents}
                calendarSwitch={props.calendarSwitch}
                onChangeCalendarPrev={onChangeCalendarPrev}
                onChangeCalendarNext={onChangeCalendarNext}
                miniMonthlyCalendarMonthAndYear={
                  props.miniMonthlyCalendarMonthAndYear
                }
              />
            );
          },
          eventWrapper: (data: { event: ICalendarEvent }) => (
            <CalendarEvent {...data.event} />
          ),
        }}
        showAllEvents
      />
    </Spin>
  );
};

export default memo(WeeklyCalendar);
