import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions, IProjectSparePart } from "..";
import { notification } from "antd";
import {
  fetchSparePartAssociatedCategories,
  fetchSparePartAssociatedVendors,
  fetchSpareParts,
} from "services/spare-parts/spare-parts.service";
import { MESSAGES } from "constants/messages";

export const getSpareParts: SourceActionType = (
  params: IParams,
  isGlobal?: boolean,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setSparePartsExterLoader(true));
    try {
      const response = await fetchSpareParts(params, isGlobal);
      dispatch(actions.setSparePartsExterLoader(false));
      dispatch(actions.getSpareParts(response));
    } catch (ex: any) {
      dispatch(actions.setSparePartsExterLoader(false));
      notification.error({
        message:
          ex.data?.response?.message ||
          MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG,
      });
    }
  };
};

export const setRecentlyAddedSpareParts: SourceActionType = (
  payload: IProjectSparePart,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setRecentlyAddedSpareParts(payload));
  };
};

export const updateRecentlyAddedSparePart: SourceActionType = (
  payload: IProjectSparePart,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.updateRecentlyAddedSparePart(payload));
  };
};

export const deleteRecentlyAddedSpareParts: SourceActionType = (
  payload: string[],
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.deleteRecentlyAddedSpareParts(payload));
  };
};

export const clearRecentlyAddedSpareParts: SourceActionType = () => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.clearRecentlyAddedSpareParts());
  };
};

export const getSparePartAssociatedCategories: SourceActionType = (
  params: any,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchSparePartAssociatedCategories(params);
      dispatch(actions.setSparePartAssociatedCategories(response.data));
    } catch (ex: any) {
      notification.error({
        message: ex.data?.response?.message || "",
      });
    }
  };
};

export const getSparePartAssociatedVendors: SourceActionType = (
  params: any,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchSparePartAssociatedVendors(params);
      dispatch(actions.setSparePartAssociatedVendors(response.data));
    } catch (ex: any) {
      notification.error({
        message: ex.data?.response?.message || "",
      });
    }
  };
};
