import { memo } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import { ROUTES } from "routes/Routes.constants";
import { DoubleCheckIcon } from "components/icons";
import { actions, NotificationTypes } from "redux/components/notifications";
import { actions as notificationActions } from "redux/components/notifications";

import { INotificationsHeaderProps } from "./Notification.interface";
import TextToLink from "../text-to-link";
import AlectifyText from "static/texts.json";
import "./Notification.scss";
import AlectifyButton from "../button";
import CrossIcon from "components/icons/CrossIcon";
import { Space } from "antd";

const NotificationsHeader: React.FC<INotificationsHeaderProps> = (
  props: INotificationsHeaderProps,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const navigateToNotifications = () => {
    props.setIsUserDropdownOpen && props.setIsUserDropdownOpen(false);
    props.setIsSystemDropdownOpen && props.setIsSystemDropdownOpen(false);

    dispatch(actions.toggleNotificationDropdown(false));
    navigate(`${ROUTES.NOTIFICATIONS}?type=${props.type}`);
  };

  return (
    <div className="header-container">
      <div
        className={`${
          props.fullScreenActive
            ? "header-title"
            : "header-title header-title-reduce"
        }`}
      >
        <p>
          <Space>
            {props.type === NotificationTypes.GLOBAL
              ? AlectifyText.SYSTEM_NOTIFICATIONS
              : AlectifyText.USER_NOTIFICATIONS}
            {!props.fullScreenActive && (
              <TextToLink
                text="See all"
                className="see-all-notification"
                onClick={navigateToNotifications}
              />
            )}
          </Space>
        </p>
      </div>
      <div className="header-options">
        <div
          className={`${
            props.fullScreenActive
              ? "header-mark-read-text"
              : "header-mark-read-text header-mark-read-text-reduce"
          }`}
          onClick={(ev) => {
            ev.stopPropagation();
            props.markAllAsRead();
          }}
        >
          <DoubleCheckIcon className="double-check-icon" />
          <p>{AlectifyText.MARK_ALL_READ}</p>
        </div>
        {/* {!props.fullScreenActive ? (
          <div
            className="full-screen-container"
            onClick={navigateToNotifications}
          >
            <FullScreenIcon />
          </div>
        ) : null} */}
        {!props.fullScreenActive && (
          <AlectifyButton
            // text="Close"
            icon={<CrossIcon fill={"#545454"} />}
            size="small"
            type="default"
            className="alectify-default-button border-none"
            onClick={() => {
              props.setIsUserDropdownOpen && props.setIsUserDropdownOpen(false);
              props.setIsSystemDropdownOpen &&
                props.setIsSystemDropdownOpen(false);

              if (props.type === NotificationTypes.GLOBAL) {
                dispatch(notificationActions.toggleNotificationDropdown(false));
              } else {
                dispatch(
                  notificationActions.toggleSystemNotificationDropdown(false),
                );
              }
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(NotificationsHeader);
