import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions } from "..";
import { fetchPMExternal } from "services/pm-external/pm-external.service";
// import { notification } from "antd";
// import { MESSAGES } from "constants/messages";
import { getPmsCounts } from "redux/components/pm-internal/sources";

export const getPmExternal: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setPMExterLoader(true));
    try {
      const response = await fetchPMExternal(
        masterProjectId,
        subProjectId,
        params,
      );
      dispatch(actions.getPmExternal(response));
      dispatch(getPmsCounts(masterProjectId, subProjectId, params));
      dispatch(actions.setPMExterLoader(false));
    } catch (ex: any) {
      // write your code here
      // dispatch(actions.setPMExterLoader(true));
      dispatch(actions.setPMExterLoader(false));

      if (ex?.code === "ERR_CANCELED") return;
      // notification.error({
      //   message:
      //     ex.data?.response?.message ||
      //     MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG,
      // });
    }
  };
};

export const setWorkOrderCardSwitcher: SourceActionType = (
  payload: boolean,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setWorkOrderCardSwitcher(payload));
  };
};
