import { Dispatch } from "redux";
import { actions } from ".."; // Import your Redux Toolkit actions
import { ILoginPayload } from "services/auth/auth.interface";
import {
  ForgetpasswordRequest,
  loginRequest,
  requestResetPassword,
  verifyResetPasswordToken,
} from "services/auth/auth.service";
import { saveToLocal } from "utils/cache";
import { LoaderService } from "components/shared/CaaS/loader";
import { SourceActionType } from "redux/interfaces";
import { notification } from "antd";
import {
  IResetPasswordIds,
  IResetPasswordPayload,
} from "pages/reset-password/ResetPassword.interface";
import { IForgetPasswordPayload } from "pages/forget-password/ForgetPassword.interface";

export const login: SourceActionType = (payload: ILoginPayload) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setAuthLoader(true));
      const response = await loginRequest(payload);
      saveToLocal(response?.data.token, "token");
      saveToLocal(response?.data?.refresh_token || "", "refresh_token");
      dispatch(actions.loginUserAction(response));
      dispatch(actions.setAuthLoader(false));
    } catch (e) {
      dispatch(actions.setAuthLoader(false));
      console.error("error:", e);
    }
  };
};

export const forgetpassword: SourceActionType = (
  payload: IForgetPasswordPayload,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      LoaderService.show();
      const response = await ForgetpasswordRequest(payload);
      if (response.success) {
        notification.success({
          message: "Request Sent",
          description: response.message,
          duration: 6000,
        });
      }
      LoaderService.hide();
    } catch (e) {
      notification.error({
        message: "Invalid email",
        description: "User doesn't exist with your requested email",
        duration: 10000,
      });
      LoaderService.hide();
      console.error("error:", e);
      throw e;
    }
  };
};

export const resetpassword: SourceActionType = (payload: {
  resetPasswordData: IResetPasswordIds;
  verifypassword: IResetPasswordPayload;
  navigation: () => void;
}) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const { resetPasswordData, verifypassword } = payload;
      LoaderService.show();
      const response = await verifyResetPasswordToken(
        resetPasswordData.uidb64,
        resetPasswordData.token,
      );

      if (response.success) {
        try {
          const response = await requestResetPassword(verifypassword);
          if (response.success) {
            notification.success({
              message: "Password changed",
              description:
                "Your password has been successfully changed, please login to your account",
              duration: 3000,
            });
          }
        } catch (e) {
          LoaderService.hide();
          console.error("error:", e);
          throw e;
        }
      }
      LoaderService.hide();
    } catch (e: any) {
      LoaderService.hide();
      console.error("error:", e);
      notification.error({
        message: "Invalid Token",
        description: e.response?.data?.message || "An error occurred",
        duration: 3000,
      });
      throw e;
    }
  };
};

export const setFirebaseToken: SourceActionType = (token: string) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setFirebaseToken(token));
  };
};
