interface IListIconProps {
  fill?: string | null;
  className?: string | null;
}
const ListIcon: React.FC<IListIconProps> = (props: IListIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      className={props.className || ""}
    >
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" fill="white" />
      <rect
        x="0.5"
        y="0.5"
        width="23"
        height="23"
        rx="3.5"
        stroke={props.fill || "#787879"}
      />

      <g clip-path="url(#clip0_3750_196372)">
        <path
          d="M14.4174 6.81472C15.7948 6.81472 17.1718 6.81472 18.5484 6.81472C18.7963 6.7882 19.0457 6.84833 19.2542 6.98487C19.4628 7.12141 19.6176 7.32594 19.6924 7.56372C19.7354 7.71915 19.7421 7.8824 19.7118 8.04081C19.6815 8.19922 19.6152 8.34853 19.5179 8.47715C19.4206 8.60578 19.295 8.71026 19.1508 8.7825C19.0066 8.85474 18.8477 8.8928 18.6864 8.89372C18.6384 8.89372 18.5864 8.89372 18.5414 8.89372H10.2534C10.001 8.91606 9.74854 8.85054 9.53881 8.70824C9.32909 8.56593 9.17494 8.35557 9.10243 8.11272C9.06528 7.96722 9.06029 7.81537 9.08782 7.66775C9.11535 7.52013 9.17474 7.38028 9.26184 7.25796C9.34894 7.13564 9.46167 7.03377 9.59216 6.95947C9.72266 6.88516 9.86778 6.8402 10.0174 6.82772C10.1294 6.81672 10.2424 6.81572 10.3554 6.81572L14.4174 6.81472Z"
          fill={props.fill || "#787879"}
        />
        <path
          d="M14.3836 17.1856C13.0063 17.1856 11.6293 17.1856 10.2526 17.1856C10.0208 17.209 9.78759 17.1575 9.58714 17.0387C9.38668 16.92 9.2295 16.7401 9.13863 16.5256C9.07855 16.3675 9.05765 16.1973 9.07771 16.0294C9.09776 15.8615 9.15819 15.7009 9.25381 15.5615C9.34944 15.422 9.47742 15.3078 9.6268 15.2286C9.77619 15.1494 9.94254 15.1075 10.1116 15.1066C10.1596 15.1066 10.2116 15.1066 10.2566 15.1066H18.5426C18.8073 15.0811 19.0721 15.1533 19.2872 15.3098C19.5022 15.4662 19.6525 15.6959 19.7096 15.9556C19.7368 16.0978 19.7337 16.2441 19.7005 16.385C19.6673 16.5258 19.6048 16.6582 19.5171 16.7733C19.4293 16.8884 19.3183 16.9837 19.1912 17.053C19.0642 17.1223 18.9239 17.1641 18.7796 17.1756C18.6676 17.1866 18.5546 17.1876 18.4416 17.1876H14.3836"
          fill={props.fill || "#787879"}
        />
        <path
          d="M14.4169 13.0386C12.9996 13.0386 11.5822 13.0386 10.1649 13.0386C9.96804 13.0479 9.77256 13.0013 9.60109 12.9041C9.42961 12.807 9.28914 12.6632 9.19591 12.4896C9.10138 12.334 9.05383 12.1543 9.05897 11.9723C9.06412 11.7903 9.12174 11.6136 9.22491 11.4636C9.32766 11.3005 9.47239 11.168 9.64392 11.0801C9.81546 10.9921 10.0075 10.9519 10.1999 10.9636C11.6419 10.9636 13.0836 10.9636 14.5249 10.9636C15.8939 10.9636 17.2629 10.9576 18.6319 10.9636C18.8191 10.9523 19.0058 10.992 19.1721 11.0786C19.3383 11.1652 19.478 11.2954 19.576 11.4552C19.674 11.6151 19.7267 11.7986 19.7284 11.986C19.7302 12.1735 19.6809 12.358 19.5859 12.5196C19.4846 12.6886 19.3387 12.8265 19.1643 12.9182C18.9899 13.0099 18.7936 13.0519 18.5969 13.0396L14.4169 13.0386Z"
          fill={props.fill || "#787879"}
        />
        <path
          d="M7.45436 12.0053C7.45322 12.2121 7.41087 12.4166 7.32977 12.6069C7.24868 12.7972 7.13047 12.9693 6.98205 13.1134C6.83364 13.2574 6.658 13.3704 6.4654 13.4458C6.2728 13.5212 6.06711 13.5574 5.86036 13.5523C5.65211 13.5592 5.44461 13.5241 5.25024 13.4491C5.05586 13.3741 4.8786 13.2606 4.72903 13.1156C4.57946 12.9705 4.46065 12.7968 4.37969 12.6048C4.29873 12.4129 4.25728 12.2065 4.25782 11.9982C4.25836 11.7898 4.30087 11.5837 4.38282 11.3921C4.46477 11.2006 4.58447 11.0275 4.73479 10.8832C4.88511 10.7389 5.06295 10.6264 5.25771 10.5524C5.45247 10.4783 5.66015 10.4443 5.86836 10.4523C6.07471 10.449 6.27967 10.4867 6.47133 10.5633C6.66299 10.6398 6.83752 10.7537 6.9848 10.8983C7.13208 11.0429 7.24916 11.2153 7.32926 11.4055C7.40935 11.5957 7.45085 11.7999 7.45135 12.0063"
          fill={props.fill || "#787879"}
        />
        <path
          d="M5.85372 6.3055C6.06001 6.29883 6.26556 6.33343 6.4583 6.40727C6.65105 6.4811 6.8271 6.59269 6.97614 6.73549C7.12517 6.87828 7.24419 7.04941 7.3262 7.23882C7.40821 7.42822 7.45156 7.63211 7.45372 7.8385C7.45254 8.04605 7.41038 8.25134 7.32965 8.44255C7.24892 8.63377 7.13122 8.80716 6.98329 8.95276C6.83537 9.09836 6.66014 9.21331 6.46766 9.29101C6.27519 9.3687 6.06927 9.40761 5.86172 9.4055C5.44465 9.40876 5.04303 9.2478 4.74365 8.9574C4.44427 8.667 4.27116 8.27048 4.26172 7.8535C4.26446 7.64728 4.30782 7.44362 4.38934 7.25417C4.47086 7.06473 4.58892 6.89321 4.73678 6.74944C4.88465 6.60566 5.0594 6.49245 5.25106 6.41628C5.44271 6.3401 5.6475 6.30246 5.85372 6.3055Z"
          fill={props.fill || "#787879"}
        />
        <path
          d="M5.85372 17.695C5.6475 17.698 5.44271 17.6604 5.25106 17.5842C5.0594 17.508 4.88465 17.3948 4.73678 17.251C4.58892 17.1073 4.47086 16.9357 4.38934 16.7463C4.30782 16.5569 4.26446 16.3532 4.26172 16.147C4.27064 15.7296 4.44349 15.3324 4.7429 15.0414C5.04231 14.7504 5.44421 14.589 5.86172 14.592C6.07019 14.5888 6.27722 14.627 6.47081 14.7044C6.66441 14.7818 6.84072 14.8969 6.98953 15.0429C7.13835 15.1889 7.25671 15.363 7.33777 15.5551C7.41882 15.7472 7.46095 15.9535 7.46172 16.162C7.45956 16.3684 7.41621 16.5723 7.3342 16.7617C7.25219 16.9511 7.13317 17.1222 6.98414 17.265C6.8351 17.4078 6.65905 17.5194 6.4663 17.5932C6.27356 17.6671 6.06801 17.7016 5.86172 17.695"
          fill={props.fill || "#787879"}
        />
      </g>
    </svg>
  );
};
export default ListIcon;
