import { useEffect, useRef } from "react";

export const useIdleCallback = (callback: () => void) => {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  }, [callback]);

  useEffect(() => {
    let idleCallbackId: number | ReturnType<typeof setTimeout>;

    if (typeof requestIdleCallback === "function") {
      idleCallbackId = requestIdleCallback(() => {
        callbackRef.current();
      });
    } else {
      idleCallbackId = setTimeout(() => {
        callbackRef.current();
      }, 1); // A small timeout to defer execution
    }

    return () => {
      if (typeof cancelIdleCallback === "function") {
        cancelIdleCallback(idleCallbackId as number); // Cast to number for cleanup
      } else {
        clearTimeout(idleCallbackId as ReturnType<typeof setTimeout>); // Cast for cleanup
      }
    };
  }, []);
};
