import { createActionAndReducers } from "redux/utils/reducer.helper";

import { IDocumentsActions } from "./documents.interface";

import { reducerName } from "./documents.constants";

import { documentInitialState } from "./documents.initialState";

import * as documentsEffects from "./affects";

export * from "./documents.interface";

export * from "./documents.constants";

export * from "./documents.enum";

const { actions, reducer } = createActionAndReducers<IDocumentsActions>(
  reducerName,
  documentInitialState,
  documentsEffects,
);

export { actions, documentInitialState, reducer };
