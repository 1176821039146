export const GET_PM_EXTERNAL =
  "/preventive-maintenances/{masterProjectId}/{subProjectId}/PM_EXTERNAL";
export const CREATE_PM = "/preventive-maintenances/";
export const CREATE_NEW_COMMENT_PM = "/comments/{subProjectId}/{pmId}";
export const CREATE_PM_EXTERNAL_ASSIGNEE = "/pm-assignees/{pmId}";
export const CREATE_PM_EXTERNAL_APPROVERS = "/pm-approvers/{pmId}";
export const CREATE_MASTER_PM_EXTERNAL_ASSIGNEE = "/pm-assignees/master/{pmId}";
export const CREATE_MASTER_PM_EXTERNAL_APPROVERS =
  "/pm-approvers/master/{pmId}";
export const CREATE_PM_INTERNAL_ASSIGNEE = "/pm-assignees/master/{pmId}";
export const REMOVE_PM_ASSIGNEE = "/pm-assignees/{pmId}/{userId}";
export const REMOVE_PM_APPROVERS = "/pm-approvers/{pmId}/{userId}";
export const REMOVE_MASTER_PM_ASSIGNEE = "/pm-assignees/master/{pmId}/{userId}";
export const REMOVE_MASTER_PM_APPROVERS =
  "/pm-approvers/master/{pmId}/{userId}";
