import { memo } from "react";

interface IFillColorProps {
  fill?: string | null | undefined;
}

const CircleIcon: React.FC<IFillColorProps> = (props: IFillColorProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="7.5" fill={props.fill!} />
    </svg>
  );
};

export default memo(CircleIcon);
