import { IParams } from "redux/interfaces";
import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import {
  CREATE_MASTER_PM_EXTERNAL_APPROVERS,
  CREATE_MASTER_PM_EXTERNAL_ASSIGNEE,
  CREATE_NEW_COMMENT_PM,
  CREATE_PM,
  CREATE_PM_EXTERNAL_APPROVERS,
  CREATE_PM_EXTERNAL_ASSIGNEE,
  GET_PM_EXTERNAL,
  REMOVE_MASTER_PM_APPROVERS,
  REMOVE_MASTER_PM_ASSIGNEE,
  REMOVE_PM_APPROVERS,
  REMOVE_PM_ASSIGNEE,
} from "./pm-external.enpoints";
import {
  IPMExternalDetailResponse,
  IPmAssgineeCreationPayload,
  IPmCommentCreationPayload,
} from "./pm-external.interface";
import { IPmExternalResponse } from "redux/components/pm-external";
import { AxiosResponse } from "axios";
import {
  GET_PREVENTIVE_MAINTENANCE_DETAIL,
  GET_PREVENTIVE_MAINTENANCE_DETAIL_MASTER,
} from "services/pm-internal/pm-internal.endpoints";
import { SERVICE_UNIQUE_ID } from "utils/constants";

/**
 * Fetches PM External for a given master project, sub project ID, pmtype.
 *
 * @param masterProjectId - The ID of the master project to fetch pm external.
 * @param subProjectId - The ID of the sub project to fetch pm external for.
 * @param pmType - The PM type.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project pm internal data.
 * @throws If an error occurs during the request.
 */
export const fetchPMExternal = async (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
) => {
  try {
    const response: AxiosResponse<IPmExternalResponse> = await pmHttp.get(
      GET_PM_EXTERNAL.replace("{masterProjectId}", masterProjectId).replace(
        "{subProjectId}",
        subProjectId,
      ),
      params,
      SERVICE_UNIQUE_ID.UNIQUE_FETCH_PM_EXTERNAL,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Creates new PM External with given payload.
 * @param pmType - The PM type.
 * @param { FormData } body - body of a post request to create PM External.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project pm internal data.
 * @throws If an error occurs during the request.
 */

export const createPrevMaintenances = async (
  pmType: string,
  body: FormData,
) => {
  try {
    const response = await pmHttp.post(
      `${CREATE_PM}${pmType}`,
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * updates PM External with given payload.
 * @param { string } pmId - The PM type.
 * @param { IPMExternalCreationPayload } body - body of a post request to create PM External.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project pm internal data.
 * @throws If an error occurs during the request.
 */

export const updatePrevMaintenances = async (pmId: string, body: FormData) => {
  try {
    const response = await pmHttp.put(
      `${CREATE_PM}${pmId}`,
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * updates PM External status with given status.
 * @param { string } pmId - The PM type.
 * @param { string } status - The PM status to update.
 * @param { Date } body - contains date in string.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project pm internal data.
 * @throws If an error occurs during the request.
 */

export const updatePrevMaintenanceStatus = async (
  pmId: string,
  status: string,
  body: {
    date: Date;
    estimatedHours?: string;
    estimatedCost?: string;
    commentId?: string;
  },
) => {
  try {
    const response = await pmHttp.patch(`${CREATE_PM}${pmId}/${status}`, body);
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Creates a new comment for pm.
 *
 * @param {string} subProjectId - ID of the subproject.
 * @param {string} pmId - ID of the task.
 * @param {IPmCommentCreationPayload} body - The comment data to be created.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createNewPmComment = async (
  subProjectId: string,
  pmId: string,
  body: IPmCommentCreationPayload,
) => {
  try {
    const response = await pmHttp.post(
      CREATE_NEW_COMMENT_PM.replace("{subProjectId}", subProjectId).replace(
        "{pmId}",
        pmId,
      ),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new assignee for pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {IPmAssgineeCreationPayload} body - The user data to be created in pm assignee.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createPmAssignees = async (
  pmId: string,
  body: IPmAssgineeCreationPayload,
) => {
  try {
    const response = await pmHttp.post(
      CREATE_PM_EXTERNAL_ASSIGNEE.replace("{pmId}", pmId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new assignee for master pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {IPmAssgineeCreationPayload} body - The user data to be created in pm assignee.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createMasterPmAssignees = async (
  pmId: string,
  body: IPmAssgineeCreationPayload,
) => {
  try {
    const response = await pmHttp.post(
      CREATE_MASTER_PM_EXTERNAL_ASSIGNEE.replace("{pmId}", pmId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a new assignee for pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {string} userId - The user id to be deleted from pm assignee.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const removePmAssignees = async (pmId: string, userId: string) => {
  try {
    const response = await pmHttp.delete(
      REMOVE_PM_ASSIGNEE.replace("{pmId}", pmId).replace("{userId}", userId),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes an assignee for master pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {string} userId - The user id to be deleted from pm assignee.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const removeMasterPmAssignees = async (pmId: string, userId: string) => {
  try {
    const response = await pmHttp.delete(
      REMOVE_MASTER_PM_ASSIGNEE.replace("{pmId}", pmId).replace(
        "{userId}",
        userId,
      ),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new approvers for pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {IPmAssgineeCreationPayload} body - The user data to be created in pm approvers.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createPmApprovers = async (
  pmId: string,
  body: IPmAssgineeCreationPayload,
) => {
  try {
    const response = await pmHttp.post(
      CREATE_PM_EXTERNAL_APPROVERS.replace("{pmId}", pmId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Creates a new approvers for master pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {IPmAssgineeCreationPayload} body - The user data to be created in pm approvers.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createMasterPmApprovers = async (
  pmId: string,
  body: IPmAssgineeCreationPayload,
) => {
  try {
    const response = await pmHttp.post(
      CREATE_MASTER_PM_EXTERNAL_APPROVERS.replace("{pmId}", pmId),
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a new approvers for pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {string} userId - The user id to be deleted from pm approvers.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const removePmApprovers = async (pmId: string, userId: string) => {
  try {
    const response = await pmHttp.delete(
      REMOVE_PM_APPROVERS.replace("{pmId}", pmId).replace("{userId}", userId),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes an approvers for master pm.
 *
 * @param {string} pmId - ID of the preventive maintenacne.
 * @param {string} userId - The user id to be deleted from pm approvers.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const removeMasterPmApprovers = async (pmId: string, userId: string) => {
  try {
    const response = await pmHttp.delete(
      REMOVE_MASTER_PM_APPROVERS.replace("{pmId}", pmId).replace(
        "{userId}",
        userId,
      ),
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Fetches the details of a external PM.
 *
 * @param pmId - The ID of the external preventive maintenance.
 * @returns A Promise resolving to the details of the preventive maintenance.
 * @throws An error if the request fails.
 */
export const fetchExternalPMDetails = async (
  pmId: string,
): Promise<IPMExternalDetailResponse> => {
  try {
    const url = GET_PREVENTIVE_MAINTENANCE_DETAIL.replace("{pmId}", pmId);
    const response: AxiosResponse<IPMExternalDetailResponse> =
      await pmHttp.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches the details of a maintenance master.
 *
 * @param pmId - The ID of the external preventive maintenance master.
 * @returns A Promise resolving to the details of the preventive maintenance master.
 * @throws An error if the request fails.
 */
export const fetchPMDetailsMaster = async (
  pmId: string,
): Promise<IPMExternalDetailResponse> => {
  try {
    const url = GET_PREVENTIVE_MAINTENANCE_DETAIL_MASTER.replace(
      "{pmId}",
      pmId,
    );
    const response: AxiosResponse<IPMExternalDetailResponse> =
      await pmHttp.get(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
/**
 * Deletes pm external.
 *
 * @param pmId - The ID of the external preventive maintenance.
 * @returns A Promise resolving to the details of the preventive maintenance.
 * @throws An error if the request fails.
 */
export const deletePMExternal = async (
  pmId: string,
): Promise<IPMExternalDetailResponse> => {
  try {
    const url = GET_PREVENTIVE_MAINTENANCE_DETAIL.replace("{pmId}", pmId);
    const response: AxiosResponse<IPMExternalDetailResponse> =
      await pmHttp.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
