import { useParams, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row, Typography, message } from "antd";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";

import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import PasswordIcon from "assets/images/alectify-key-icon.svg";
import SVGToComponent from "components/shared/icons/icons";
import {
  IResetPasswordIds,
  IResetPasswordPayload,
} from "./ResetPassword.interface";
import "./ResetPassword.scss";
import {
  requestResetPassword,
  verifyResetPasswordToken,
} from "services/auth/auth.service";
import { MESSAGES } from "constants/messages";
import { ROUTES } from "routes/Routes.constants";
import { currentYear } from "utils/helpers";

const { Text, Title } = Typography;

const commonPasswords = [
  "12345678",
  "password",
  "123456789",
  "qwerty",
  "abc123",
];

const passwordValidationRules = [
  {
    required: true,
    message: "Please enter your password",
  },
  {
    min: 9,
    message: "Password must contain at least 9 characters.",
  },
  {
    validator: (_: any, value: string) => {
      if (commonPasswords.includes(value)) {
        return Promise.reject(
          new Error("Password is too common. Please choose another one."),
        );
      }
      return Promise.resolve();
    },
    message: "Password is too common. Please choose another one.",
  },
  {
    validator: (_: any, value: string) => {
      if (/^\d+$/.test(value)) {
        return Promise.reject(
          new Error("Password should not be entirely numeric."),
        );
      }
      return Promise.resolve();
    },
    message: "Password should not be entirely numeric.",
  },
  {
    pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d).+$/,
    message:
      "Password must contain at least one uppercase letter, one lowercase letter, and one digit.",
  },
];

const ResetPassword: React.FC = () => {
  const auth = useSelector((state: any) => state.auth);
  const [loader, setLoader] = useState<boolean>(false);

  const { uidb64, token } = useParams();
  const navigate = useNavigate();

  const onSubmit = async (values: any) => {
    const { password, confirmPassword } = values;
    setLoader(true);
    try {
      if (password === confirmPassword) {
        const resetPasswordData: IResetPasswordIds = {
          uidb64: uidb64 || "",
          token: token || "",
        };
        const verifypassword: IResetPasswordPayload = {
          uidb64: uidb64 || "",
          token: token || "",
          password: confirmPassword,
        };

        const response = await verifyResetPasswordToken(
          resetPasswordData.uidb64,
          verifypassword.token,
        );
        if (response.success) {
          const verified = await requestResetPassword(verifypassword);
          if (verified.success) {
            setLoader(false);
            message.success(
              MESSAGES.AUTHENTICATION_MESSAGES.PASSWORD_CHANGED,
              1000,
            );
            navigate(ROUTES.LOGIN);
          }
        } else {
          message.error(response?.message || "Internal server error.");
        }
      } else {
        setLoader(false);
        message.error(MESSAGES.AUTHENTICATION_MESSAGES.PASSWORD_DOESNOT_MATCH);
      }
    } catch (ex: any) {
      setLoader(false);
      console.error("Error data:", ex);
    }
  };

  useEffect(() => {
    if (!isEmpty(auth.user)) {
      navigate("/");
    }
  }, [auth, navigate]);

  return (
    <Row
      className="auth-container height-100vh"
      align="middle"
      justify="center"
    >
      <Col xs={24} sm={20} md={18} lg={12} xl={10} xxl={10}>
        <Card className="auth-form-container">
          <div className="auth-form-header">
            <div className="auth-form-logo-container" />
            <Title className="auth-form-title">
              Welcome to <span className="company-name">Alectify!</span>
            </Title>
            <p>Reset your Password</p>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            validateTrigger="onSubmit"
          >
            <AlectifyInput
              label="New Password"
              name="password"
              type="password"
              prefix={<SVGToComponent icon={PasswordIcon} />}
              rules={passwordValidationRules}
              placeholder="Enter your New Password here"
            />

            <AlectifyInput
              label="Confirm New Password"
              name="confirmPassword"
              type="password"
              prefix={<SVGToComponent icon={PasswordIcon} />}
              rules={[
                {
                  required: true,
                  message: "Please confirm your password!",
                },
                {
                  min: 9,
                  message: "Password must contain at least 9 characters.",
                },
              ]}
              placeholder="Enter your password here"
            />
            <ul>
              <li>
                <Text type="secondary">
                  Password must be at least 9 characters long.
                </Text>
              </li>
              <li>
                <Text type="secondary">
                  Should not be a common password (e.g., 12345678, password).
                </Text>
              </li>
              <li>
                <Text type="secondary">Should not be entirely numeric.</Text>
              </li>
              <li>
                <Text type="secondary">
                  Must contain at least one uppercase letter, one lowercase
                  letter, and one digit.
                </Text>
              </li>
            </ul>
            <Form.Item className="mt-50">
              <AlectifyButton
                type="primary"
                className="alectify-btn-block"
                text="Submit"
                htmlType="submit"
                loading={loader}
              />
            </Form.Item>
          </Form>
        </Card>
        <Text className="auth-form-copyright-text">
          © Copyrights Alectify {currentYear}. All Rights Reserved.
        </Text>
      </Col>
    </Row>
  );
};

export default ResetPassword;
