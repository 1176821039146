import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";
import { memo } from "react";

interface IAttachmentIcon {
  color?: string;
  stroke?: string;
}
const AttachmentIcon: React.FC<IAttachmentIcon> = (props) => {
  return (
    <svg
      {...props}
      className="attachment-icon"
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Path 46579"
        d="M12.8855 8.03011L6.69019 13.5532C6.01016 14.1594 5.08784 14.5 4.12613 14.5C3.16442 14.5 2.2421 14.1594 1.56207 13.5532C0.882038 12.9469 0.5 12.1247 0.5 11.2673C0.5 10.41 0.882038 9.58775 1.56207 8.98151L10.3755 1.13061C10.5999 0.930623 10.8663 0.772003 11.1595 0.663803C11.4527 0.555603 11.7669 0.499943 12.0842 0.5C12.4015 0.500057 12.7157 0.555831 13.0088 0.664137C13.302 0.772443 13.5683 0.93116 13.7926 1.13123C14.017 1.33129 14.1949 1.56879 14.3163 1.83015C14.4376 2.09152 14.5001 2.37164 14.5 2.65452C14.4999 2.9374 14.4374 3.2175 14.3159 3.47882C14.1944 3.74014 14.0164 3.97757 13.7919 4.17756L4.97573 12.0371L4.98266 12.0297M9.56332 4.90038L3.27652 10.5075C3.04899 10.7085 2.92036 10.9819 2.91893 11.2675C2.91822 11.4089 2.94876 11.549 3.00881 11.6799C3.06886 11.8108 3.15724 11.9299 3.2689 12.0303C3.38056 12.1308 3.51332 12.2106 3.6596 12.2653C3.80588 12.32 3.96281 12.3485 4.12143 12.3491C4.44178 12.3504 4.74959 12.2382 4.97712 12.0371"
        stroke={props.color || PROJECT_THEME_COLOR}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(AttachmentIcon);
