import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
// import { notification } from "antd";
// import { MESSAGES } from "constants/messages";
import { getPmsCounts } from "redux/components/pm-internal/sources";
import { ITaskResponse, actions } from "..";
import { fetchTasksService } from "services/tasks/tasks.service";
// import { PM_TYPES } from "redux/components/pm-external";

export const fetchTasks: SourceActionType = (
  masterProjectId: string,
  subProjectId: string,
  params: IParams,
  type: string,
  requestId?: string | null,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.showTasksLoader(true));
    try {
      const response: ITaskResponse = await fetchTasksService(
        masterProjectId,
        subProjectId,
        params,
        type,
        requestId,
      );
      dispatch(actions.getProjectTasks(response));
      dispatch(getPmsCounts(masterProjectId, subProjectId, params));
      dispatch(actions.showTasksLoader(false));
    } catch (ex: any) {
      dispatch(actions.showTasksLoader(false));
      if (ex?.code === "ERR_CANCELED") return;
      // notification.error({
      //   message:
      //     ex.data?.response?.message ||
      //     MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG,
      // });
    }
  };
};
