import { Space, Tag } from "antd";
import { RightCircleIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const SkippedTag: React.FC = () => {
  return (
    <Tag color={"#7047E3"} className="round-tag">
      <Space size={3} align="center">
        <RightCircleIcon /> {AlectifyText.CANCELLED}
      </Space>
    </Tag>
  );
};

export default SkippedTag;
