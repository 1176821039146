import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "redux/components/master-project";
import { masterProjectInitialState } from "redux/components/master-project/MasterProject.initialState";
import { IMasterProjectActions } from "./MasterProject.interface";
import * as MasterProjectAffects from "./affects";

export * from "./MasterProject.constants";
export * from "./MasterProject.interface";

const { actions, reducer } = createActionAndReducers<IMasterProjectActions>(
  reducerName,
  masterProjectInitialState,
  MasterProjectAffects,
);

export { actions, masterProjectInitialState, reducer };
