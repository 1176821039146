import React from "react";
import { IAlectifyTabsProps } from "./AlectifyTabs.interface";
import AlectifyButton from "../button";

const AlectifyTabs: React.FC<IAlectifyTabsProps> = (
  props: IAlectifyTabsProps,
) => {
  return (
    <div className={props.className || ""}>
      {props.items?.map((option: any, index: number) => (
        <>
          <AlectifyButton
            text={option.label}
            key={option.key}
            type="primary"
            className={`${index > 0 ? "ml-10" : ""} ${
              props.currentTab === option.key ? "" : "light-blue-button"
            }`}
            onClick={() => props.onTabChange(option.key)}
          />
        </>
      ))}
      {props.items?.map(
        (option: any) =>
          props.currentTab === option.key && (
            <div key={option.key}>{option.children}</div>
          ),
      )}
    </div>
  );
};

export default AlectifyTabs;
