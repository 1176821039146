const DnDUploadIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="71"
      height="57"
      viewBox="0 0 71 57"
      fill="none"
    >
      <path
        d="M57.3159 40.5387L52.9824 40.5387C52.3412 40.5387 51.76 40.046 51.76 39.3656C51.76 38.6851 52.3412 38.1925 52.9824 38.1925H57.3162C63.048 38.1925 67.651 33.8541 67.651 28.5958C67.651 23.3375 63.048 18.9991 57.3162 18.9991H57.212C56.8701 18.9991 56.5361 18.8613 56.3 18.6073C56.0627 18.3521 55.9486 18.0043 56.0028 17.6541L57.3159 40.5387ZM57.3159 40.5387C64.329 40.5387 70.0954 35.2146 70.0954 28.5958C70.0954 22.3653 64.9845 17.2809 58.5356 16.7074M57.3159 40.5387L58.5356 16.7074M58.5356 16.7074C58.6823 10.79 53.5509 5.8836 47.2034 5.8836C45.2286 5.8836 43.3243 6.35259 41.6281 7.25241C35.9163 -1.12282 23.0626 -1.84584 16.3011 5.86249L16.3011 5.86251C13.621 8.91821 12.3916 12.8043 12.8419 16.7C6.35309 17.2366 1.19702 22.3386 1.19702 28.5963C1.19702 35.2146 6.96343 40.5392 13.9765 40.5392H18.3104C18.9516 40.5392 19.5327 40.0465 19.5327 39.3661C19.5327 38.6856 18.9516 38.193 18.3104 38.193H13.9765C8.24481 38.193 3.64173 33.8546 3.64173 28.5963C3.64173 23.338 8.24481 18.9996 13.9765 18.9996H14.266C14.9948 18.9996 15.6304 18.3588 15.4625 17.5862L15.4624 17.586C14.6731 13.9625 15.6556 10.2419 18.1714 7.37389C24.1652 0.541323 35.7322 1.61098 40.1454 9.44213L40.1455 9.44232C40.5036 10.0772 41.347 10.2384 41.9309 9.83854L41.9311 9.83845C43.4657 8.78689 45.2875 8.22928 47.2037 8.22928C52.1388 8.22928 56.0939 11.9638 56.0939 16.4798C56.0939 16.8636 56.0635 17.2592 56.0028 17.6538L58.5356 16.7074ZM41.6485 9.42599C41.2925 9.66976 40.7868 9.56158 40.581 9.19665L41.6485 9.42599Z"
        fill="#0954F1"
        stroke="#0954F1"
      />
      <path
        d="M16.9915 38.9852C16.9915 48.573 25.3501 56.3132 35.5497 56.3132C45.7494 56.3132 54.1079 48.5729 54.1079 38.9852C54.1079 29.3974 45.7494 21.6572 35.5497 21.6572C25.35 21.6572 16.9915 29.3975 16.9915 38.9852ZM19.4364 38.9852C19.4364 30.7577 26.6315 24.0038 35.5497 24.0038C44.4678 24.0038 51.663 30.7575 51.663 38.9852C51.663 47.2128 44.4678 53.9667 35.5497 53.9667C26.6316 53.9667 19.4364 47.2129 19.4364 38.9852Z"
        fill="#0954F1"
        stroke="#0954F1"
      />
      <path
        d="M34.8516 45.7597C34.8516 46.3406 35.3461 46.7525 35.8804 46.7525C36.4145 46.7525 36.9092 46.3411 36.9092 45.7597V32.9469C36.9092 32.366 36.4147 31.9541 35.8804 31.9541C35.3461 31.9541 34.8516 32.366 34.8516 32.9469V45.7597Z"
        fill="#0954F1"
        stroke="#0954F1"
      />
      <path
        d="M36.2656 32.5882L36.5951 32.2347L40.8256 36.177C41.2444 36.5672 41.2444 37.2151 40.8258 37.6054C40.6223 37.7955 40.3615 37.884 40.1109 37.884L36.2656 32.5882ZM36.2656 32.5882L36.5951 32.2347M36.2656 32.5882L36.5951 32.2347M36.5951 32.2347C36.1968 31.8635 35.564 31.8629 35.1655 32.2347L36.5951 32.2347ZM39.396 37.6055C39.5988 37.7945 39.8592 37.884 40.1108 37.884L35.1651 32.235L30.9349 36.177C30.5162 36.5672 30.5162 37.2153 30.9349 37.6055L31.2758 37.2397L30.9349 37.6055C31.3334 37.9768 31.9664 37.9773 32.3649 37.6052L35.8803 34.3293L39.396 37.6055C39.396 37.6055 39.396 37.6055 39.396 37.6055Z"
        fill="#0954F1"
        stroke="#0954F1"
      />
    </svg>
  );
};

export default DnDUploadIcon;
