import { IReduxActionResponse } from "redux/interfaces";
import { globalSearchInitialState } from "../GlobalSearch.initialState";

export const setGlobalSearchAction = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;

  return {
    ...state,
    globalSearchItems: payload.data
      ? [...state.globalSearchItems, ...payload.data]
      : [],
    meta: payload.meta,
  };
};

export const resetGlobalSearchState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return { ...globalSearchInitialState };
};

export const setGlobalSearchLoader = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    globalSearchLoader: payload,
  };
};

export const setGlobalSearchPageState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    page: payload,
  };
};
