import { Tabs } from "antd";
import { TabsProps } from "antd/lib";
import PmExternalIcon from "components/icons/PmExternalIcon";
// import PmInternalIcon from "components/icons/PmInternalIcon";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
// import { getTodaysTasks } from "redux/components/tasks/sources";
import AlectifyText from "static/texts.json";
import { getTodaysPrevMaintenances } from "redux/components/pm-internal/sources";
import { IRootState } from "redux/rootReducer";
import TodayTaskDetails from "./TodayTaskDetails";
import { ITodaysTasksProps } from "./TodayTaskDetails.interface";
import TasksIcon from "components/icons/TasksIcon";

const TodaysTasks: React.FC<ITodaysTasksProps> = (props: ITodaysTasksProps) => {
  const { navigate } = props;
  const dispatch = useDispatch();
  const { tasks, pmExternal } = useSelector((state: IRootState) => state);
  const todayDate = dayjs().format("YYYY-MM-DD");
  useEffect(() => {
    // dispatch(
    //   getTodaysTasks({
    //     startDate: todayDate,
    //     endDate: todayDate,
    //   }),
    // );
    dispatch(
      getTodaysPrevMaintenances({
        startDate: todayDate,
        endDate: todayDate,
      }),
    );
  }, []);

  const items: TabsProps["items"] = [
    {
      key: "pm-external",
      label: (
        <>
          <PmExternalIcon /> {AlectifyText.PM_EXTERNAL} (
          {pmExternal?.todayPmExternal.length || 0})
        </>
      ),
      children: (
        <TodayTaskDetails
          tasks={pmExternal.todayPmExternal}
          navigate={navigate}
        />
      ),
    },
    {
      key: "tasks",
      label: (
        <>
          <TasksIcon /> {AlectifyText.TASKS} ({tasks?.todayTask.length || 0})
        </>
      ),
      children: (
        <TodayTaskDetails tasks={tasks.todayTask} navigate={navigate} />
      ),
    },
    /*  {
      key: "pm-internal",
      label: (
        <>
          <PmInternalIcon /> {AlectifyText.PM_INTERNAL} (
          {pmInternal?.todayPmInternal.length || 0})
        </>
      ),
      children: (
        <TodayTaskDetails
          tasks={pmInternal.todayPmInternal}
          navigate={navigate}
        />
      ),
    }, */
  ];

  return (
    <div className="sub-project-tabs">
      <Tabs items={items} defaultActiveKey="pm-external" />
    </div>
  );
};

export default TodaysTasks;
