import { Popover, Tag, Typography } from "antd";
import { IMarkerProps } from "./marker.interface";
import { MarkerIcon } from "components/icons";

import "./marker.scss";
import { useState } from "react";
import MarkerHoveredIcon from "components/icons/MarkerHoveredIcon";

export const Marker: React.FC<IMarkerProps> = (props: IMarkerProps) => {
  const { onClick, project } = props;
  const [isHovered, setHovered] = useState<boolean>(false);
  const content = (
    <div className="cursor-pointer" onClick={() => project && onClick(project)}>
      <Typography.Paragraph>{project?.description || "-"}</Typography.Paragraph>
      <Tag color="#2AAFA7">Parent Assets: {project?.pkg_count || 0}</Tag>
      <Tag color="#0A95C1">Sub Assets: {project?.tags_count || 0}</Tag>
    </div>
  );

  const title = (
    <Typography.Paragraph
      onClick={() => project && onClick(project)}
      className="cursor-pointer"
    >
      {project?.name || "-"}
    </Typography.Paragraph>
  );

  return (
    <div className="marker-container">
      {project ? (
        <Popover
          content={content}
          title={title}
          trigger="hover"
          placement="topLeft"
        >
          <div
            style={{ position: "relative" }}
            onClick={() => onClick(project)}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div style={{ display: isHovered ? "none" : "block" }}>
              <MarkerIcon />
            </div>
            <div style={{ display: isHovered ? "block" : "none" }}>
              <MarkerHoveredIcon />
            </div>
          </div>
        </Popover>
      ) : (
        <Popover title={props?.title || "-"} trigger="hover" placement="top">
          <div
            style={{ position: "relative" }}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
          >
            <div style={{ display: isHovered ? "none" : "block" }}>
              <MarkerIcon />
            </div>
            <div style={{ display: isHovered ? "block" : "none" }}>
              <MarkerHoveredIcon />
            </div>
          </div>
        </Popover>
      )}
    </div>
  );
};

export default Marker;
