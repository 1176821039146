import { ICreateBranchResponse } from "pages/admin/Admin.interface";
import {
  ADMIN_COMPANIES,
  BRANCH_FEATURES,
  COMPANIES_BRANCH,
  CREATE_BRANCH_ADMIN,
  PROJECT_ADMIN,
  ROLES,
  SOFT_DELETE,
  SWITCH_BRANCH,
  TYPES,
  UPDATE_FEATURES,
  USERS,
  USER_TYPE,
} from "./admin.endpoints";
import { http } from "utils/client";

/** Asynchronously creates a new company by making an HTTP POST request to the ADMIN_COMPANIES endpoint.
 * @param {FormData} body - The form data containing the details of the new company.
 * @returns {Promise<ICreateBranchResponse>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const createCompany = async (
  body: FormData,
): Promise<ICreateBranchResponse> => {
  try {
    const response = await http.post(ADMIN_COMPANIES, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/** Asynchronously fetches company data from the ADMIN_COMPANIES endpoint using an HTTP GET request.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const fetchCompanies = async () => {
  try {
    const response = await http.get(ADMIN_COMPANIES);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Asynchronously fetches branches for companies by making an HTTP GET request to the COMPANIES_BRANCH endpoint.
 * @param {object} params - The parameters for the request.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const fetchCompaniesBranches = async (params: object) => {
  try {
    const response = await http.get(COMPANIES_BRANCH, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Creates new branches for companies by making an HTTP POST request to the COMPANIES_BRANCH endpoint.
 * @param {object} params - The parameters for the request.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const createCompaniesBranches = async (params: object) => {
  try {
    const response = await http.post(COMPANIES_BRANCH, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Asynchronously fetches roles by making an HTTP GET request to the ROLES endpoint.
 * @param {object} params - The parameters for the request.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */

export const fetchRoles = async (params: object) => {
  try {
    const response = await http.get(ROLES, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Asynchronously fetches organization types by making an HTTP GET request to the TYPES endpoint.
 * @returns {Promise<any>} A promise that resolves to the response data from the server.
 * @throws {Error} If an error occurs during the HTTP request.
 */

export const fetchOrganizationTypes = async () => {
  try {
    const response = await http.get(TYPES);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Creates a new branch for an admin.
 * @param {object} body - The request body containing information for creating the branch.
 * @returns {Promise<any>} - A Promise that resolves with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const createBranchAdmin = async (body: object) => {
  try {
    const response = await http.post(CREATE_BRANCH_ADMIN, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Fetches features associated with a branch.
 * @param {any} params - The parameters for the request, typically containing information about the branch.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing branch features.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchFeatures = async (params: any) => {
  try {
    const response = await http.get(BRANCH_FEATURES, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Updates features associated with a specific feature ID.
 * @param {any} featureId - The ID of the feature to update.
 * @param {any} body - The request body containing information for updating the feature.
 * @returns {Promise<any>} - A Promise that resolves with the response data after updating the feature.
 * @throws {Error} - Throws an error if the request fails.
 */
export const updateFeatures = async (featureId: string, body: object) => {
  try {
    const url = UPDATE_FEATURES.replace("{featureId}", featureId);
    const response = await http.patch(url, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/** Fetches projects associated with an admin.
 * @param {object} params - The parameters for the request, typically containing information about the admin.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing admin projects.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchAdminProjects = async (params: object) => {
  try {
    const response = await http.get(PROJECT_ADMIN, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Fetches users along with their associated branches.
 * @param {object} params - The parameters for the request, typically containing information about the users.
 * @returns {Promise<any>} - A Promise that resolves with the response data containing users and their branches.
 * @throws {Error} - Throws an error if the request fails.
 */
export const fetchUsersWithBranches = async (params: object) => {
  try {
    const response = await http.get(USERS, params);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

//
/** Edits a branch, typically used for switching branches.
 * @param {object} body - The request body containing information for editing the branch.
 * @returns {Promise<any>} - A Promise that resolves with the response data after editing the branch.
 * @throws {Error} - Throws an error if the request fails.
 */
export const editBranch = async (body: object) => {
  try {
    const response = await http.post(SWITCH_BRANCH, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/** Defines a function to update the user type for a specific user.
 * Parameters:
 * - userId: The identifier of the user whose type needs to be updated.
 * - body: The object containing the data to be updated (e.g., the new user type).
 */
export const updateUserType = async (userId: string, body: object) => {
  try {
    const url = USER_TYPE.replace("{userId}", userId);
    const response = await http.patch(url, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/** Soft deletes a project by making a DELETE request to the server.
 * @param {string} projectId - The ID of the project to be soft deleted.
 * @returns {Promise<any>} - A Promise that resolves with the data returned from the server.
 * @throws {Error} - If an error occurs during the request.
 */
export const softDeleteProject = async (projectId: string) => {
  try {
    const url = SOFT_DELETE.replace("{projectId}", projectId);
    const response = await http.delete(url);
    return response.data;
  } catch (error) {
    throw error;
  }
};
