import React from "react";
import { Tooltip } from "antd";
import { IAsset } from "redux/components/assets";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import TextToLink from "components/shared/text-to-link";
import AssetDetail from "components/shared/asset-detail/AssetDetail";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useNavigate } from "react-router-dom";
import { truncate } from "lodash";

const PackageAssetsColumns = (props: any) => {
  const navigate = useNavigate();
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.SUB_ASSET_NAME,
      dataIndex: "tag_name",
      width: 180,
      searchable: true,
      render: (text, asset: IAsset) => (
        <Tooltip placement="topLeft" title={text}>
          <TextToLink
            className="text-to-link-options"
            text={text}
            onClick={() =>
              DrawerServiceInstance.open(AlectifyDrawer, {
                width: 420,
                title: truncate(asset.name, {
                  length: 30,
                  omission: "...",
                }),
                name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                onClose: () =>
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                  }),
                children: (
                  <AssetDetail
                    predictionData={props.predictionData}
                    subProjectId={asset?.subproject_id}
                    assetId={asset?.tag_id}
                    navigate={navigate}
                  />
                ),
              })
            }
          />
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      width: 100,
      searchable: true,
      ellipsis: true,
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
    },
  ];
  return React.useMemo(() => columns, []);
};

export default PackageAssetsColumns;
