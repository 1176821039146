interface ICameraIconProps {
  fill?: string | null;
}
const CameraIcon: React.FC<ICameraIconProps> = (props: ICameraIconProps) => {
  return (
    <svg
      width="27"
      height="22"
      viewBox="0 0 27 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.7083 12.4566C16.7083 14.3926 15.141 15.9599 13.205 15.9599C11.269 15.9599 9.70174 14.3926 9.70174 12.4566C9.70174 10.5206 11.269 8.95337 13.205 8.95337C15.141 8.95337 16.7083 10.5206 16.7083 12.4566ZM23.8775 3.67679C25.2766 3.67679 26.41 4.8102 26.41 6.20934V12.8471V18.4762C26.41 19.9675 25.2007 21.1768 23.7093 21.1768H2.70065C1.20933 21.1768 0 19.9675 0 18.4762V12.8417V6.20934C0 4.8102 1.13341 3.67679 2.53254 3.67679H7.59762L7.83623 2.66812C8.19957 1.10629 9.59328 0 11.1985 0H15.2169C16.8221 0 18.2159 1.10629 18.5792 2.66812L18.8124 3.67679H23.8775ZM5.282 7.3319C5.282 6.63233 4.71259 6.06291 4.01302 6.06291C3.30803 6.06291 2.73861 6.63233 2.73861 7.3319C2.73861 8.03146 3.30803 8.60088 4.0076 8.60088C4.71259 8.6063 5.282 8.03689 5.282 7.3319ZM19.4523 12.4566C19.4523 9.0076 16.654 6.20934 13.205 6.20934C9.75597 6.20934 6.95771 9.0076 6.95771 12.4566C6.95771 15.9057 9.75597 18.7039 13.205 18.7039C16.654 18.7039 19.4523 15.9057 19.4523 12.4566Z"
        fill={props.fill || "#1F2D56"}
      />
    </svg>
  );
};
export default CameraIcon;
