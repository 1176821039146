import { IReduxActionResponse } from "redux/interfaces";
import { IUsersResponse, IUsersState } from "../users.interface";
import { usersInitialState } from "redux/components/users/users.initialState";

export const getProjectUsers = (
  state: IUsersState,
  action: IReduxActionResponse<IUsersResponse>,
): IUsersState => {
  const { payload } = action;
  return {
    ...state,
    projectTeamMembers: {
      data: payload?.data ?? [],
      message: payload?.message ?? "",
      success: payload?.success ?? true,
    },
  };
};

export const resetUsersState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...usersInitialState,
  };
};
