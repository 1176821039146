import { Space, Tag } from "antd";

const StatusClosed: React.FC<{ text?: string; color?: string }> = ({
  text,
  color,
}) => {
  return (
    <Tag color={color || "#D92323"} className="round-tag">
      <Space size={3}>{text}</Space>
    </Tag>
  );
};

export default StatusClosed;
