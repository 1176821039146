import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName, IAuthActions } from "redux/components/Auth";
import { authInitialState } from "redux/components/Auth/auth.initialState";
import * as AuthEffects from "./affects";

export * from "./Auth.constants";
export * from "./Auth.interface";

const { actions, reducer } = createActionAndReducers<IAuthActions>(
  reducerName,
  authInitialState,
  AuthEffects,
);

export { actions, authInitialState, reducer };
