export const reducerName = "auth";

export const FEATURE_FLAGS = {
  MANAGE_PROJECT: "manage_project",
  NOTIFICATION: "notification",
  MY_CALENDAR: "my_calendar",
  SPAREPART: "sparepart",
  DASHBOARD: "dashboard",
  IMPORT_PROCEDURE: "import_procedure",
  PROCEDURE_LIBRARY: "procedure_library",
  ML_BREAKDOWN_PREDICTION: "ml_breakdown_prediction",
  WORK_ORDER_DUE_TODAY: "work_order_due_today",
  CONTACT: "contact",
  TIMELINE: "timeline",
  COMPANYWIDE: "companywide",
  TASK: "task",
  PM_INTERNAL: "pm_internal",
  PM_EXTERNAL: "pm_external",
  WORKORDER: "workorder",
  ALL_WORKORDER: "all_workorder",
  IOT_DEVICE: "iot_device",
  VIEW_PROJECT: "view_project",
  DOCUMENTS: "documents",
  OPERATOR_ROUND: "operator_round",
  LLM_DOC_SEARCH: "llm_docs_search",
};
