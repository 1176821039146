import { memo } from "react";

interface isGenericIconProps {
  fill?: string;
  className?: string;
}
const GenericIcon: React.FC<isGenericIconProps> = (
  props: isGenericIconProps,
) => {
  return (
    <svg width="15" height="15" viewBox="0 0 15 15" fill="none" {...props}>
      <circle
        id="Ellipse 384"
        cx="7.5"
        cy="7.5"
        r="6.5"
        stroke="#4e4e4e"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="3 3"
      />
    </svg>
  );
};

export default memo(GenericIcon);
