import React from "react";
import { Dropdown } from "antd";
import type { MenuProps } from "antd";
import { useNavigate } from "react-router-dom";
import AlectifyButton from "components/shared/button";
import { actionItems as PMEActions } from "components/shared/tasks-table/TaskTable.rules";
import { IWorkOrderPMEActionsProps } from "./WorkOrderPMDetails.interface";
import { DownOutlined } from "@ant-design/icons";
import { enumToTile } from "utils/helpers";
import { PM_STATUS } from "enums";

const WorkOrderPMEDetailsActionItems: React.FC<IWorkOrderPMEActionsProps> = (
  props: IWorkOrderPMEActionsProps,
) => {
  const navigate = useNavigate();

  const items: MenuProps["items"] = [
    ...(PMEActions({
      task: (props.pmExternal || null) as any,
      currentUser: props.currentUser,
      projectId: props.projectId || "",
      subProjectId: props.subProjectId || "",
      navigate,
      onEdit: props.onEdit,
      updateStatus: props.updateStatus,
      openSubmitForReviewModal: props.openSubmitForReviewModal as any,
      onUpload: props.openAttachmentsDrawer,
      pmDetailPageActive: props.pmDetailPageActive,
      openProcedureCheckListDrawer: props.openProcedureCheckListDrawer,
      removeTask: props.removePMExternal,
      taskStatusStepIcon: props.taskStatusStepIcon,
      openWarningmodal: props.openWarningModal,
      openSpareParts: props.openSpareParts,
      downloadPDF: props.downloadPDF,
    }) as any),
  ];

  return (
    <Dropdown
      className="alectify-work-order-task-edit-btn"
      menu={{ items }}
      trigger={["click"]}
      disabled={!items?.length}
    >
      <AlectifyButton
        name="pme-detail-action-btn"
        text={
          enumToTile(
            props.pmExternal?.status === PM_STATUS.PENDING
              ? "Scheduled"
              : props.pmExternal?.status === PM_STATUS.COMPLETED
              ? "Closed"
              : props.pmExternal?.status || "",
          ) || "Action"
        }
        type="primary"
        rightIcon={<DownOutlined />}
      />
    </Dropdown>
  );
};

export default WorkOrderPMEDetailsActionItems;
