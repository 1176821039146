import React, { useEffect, useRef } from "react";
import { ISearchAddress } from "./SearchAddress.interface";

const SearchAddress: React.FC<ISearchAddress> = ({
  map,
  mapApi,
  addplace,
  ...restProps
}) => {
  const searchInput = useRef<HTMLInputElement>(null);
  const autoComplete = useRef<any>();

  useEffect(() => {
    const searchData = searchInput.current;

    const options = {
      types: ["address"],
    };

    if (mapApi) {
      autoComplete.current = new mapApi.places.Autocomplete(
        searchData,
        options,
      );
      autoComplete.current.addListener("place_changed", onPlaceChanged);
      autoComplete.current.bindTo("bounds", map);

      return () => {
        mapApi.event.clearInstanceListeners(searchData);
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapApi]);

  const onPlaceChanged = () => {
    const place = autoComplete.current.getPlace();

    if (!place.geometry) return;
    if (place.geometry.viewport) {
      map.fitBounds(place.geometry.viewport);
    } else {
      map.setCenter(place.geometry.location);
      map.setZoom(17);
    }

    if (addplace) {
      addplace(place);
    }

    searchInput.current?.blur();
  };

  const clearSearchBox = () => {
    if (searchInput.current) {
      searchInput.current.value = "";
    }
  };

  return (
    <>
      <div className="search-address-wrapper">
        <input
          ref={searchInput}
          type="search"
          autoComplete="off"
          onFocus={clearSearchBox}
          placeholder="Search Address"
          onKeyDown={(e) => (e.keyCode === 13 ? e.preventDefault() : "")}
          {...restProps}
        />
      </div>
    </>
  );
};

export default SearchAddress;
