export const actionItems = (action: any) => {
  const items = [];

  const divider = {
    type: "divider",
  };
  items.push({
    label: "Edit User Role",
    key: "skip",
    onClick: () => action.onEditPermission(action.record),
  });
  items.push(divider, {
    label: "Edit Branch",
    key: "delete",
    onClick: () => action.onEditBranch(action.record),
  });

  return items;
};
