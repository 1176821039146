import { memo } from "react";

const DangerIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 15 14"
      fill="none"
    >
      <path
        d="M14.689 10.2006L9.39016 1.6114C9.19762 1.29503 8.92164 1.03251 8.5898 0.850036C8.25795 0.667564 7.88185 0.571533 7.49906 0.571533C7.11626 0.571533 6.74016 0.667564 6.40832 0.850036C6.07647 1.03251 5.8005 1.29503 5.60795 1.6114L0.310351 10.2006C0.110154 10.5262 0.00314077 10.8966 6.80879e-05 11.2743C-0.0030046 11.6521 0.0979713 12.024 0.292846 12.3526C0.480229 12.6798 0.756549 12.9525 1.09261 13.1419C1.42867 13.3313 1.812 13.4304 2.20208 13.4287H12.7985C13.1886 13.4304 13.5719 13.3313 13.908 13.1419C14.2441 12.9525 14.5204 12.6798 14.7078 12.3526C14.9025 12.0239 15.0032 11.6519 14.9999 11.2742C14.9966 10.8964 14.8894 10.5261 14.689 10.2006Z"
        fill="white"
      />
      <path
        d="M2.20083 12.5702C1.96693 12.5729 1.73671 12.5144 1.53507 12.401C1.33342 12.2877 1.16804 12.1237 1.05679 11.927C0.938444 11.729 0.877336 11.5045 0.879753 11.2766C0.882171 11.0486 0.948025 10.8253 1.07055 10.6297L6.3644 2.04656C6.4801 1.85623 6.64602 1.69827 6.84558 1.58848C7.04513 1.47868 7.27133 1.4209 7.50156 1.4209C7.73179 1.4209 7.95799 1.47868 8.15754 1.58848C8.3571 1.69827 8.52302 1.85623 8.63873 2.04656L13.9326 10.6321C14.0536 10.8273 14.1183 11.0496 14.1201 11.2765C14.1218 11.5033 14.0606 11.7265 13.9426 11.9234C13.8313 12.1201 13.6659 12.2841 13.4643 12.3974C13.2627 12.5108 13.0324 12.5693 12.7985 12.5666H2.20083V12.5702Z"
        fill="#E46767"
      />
      <path
        d="M8.37868 4.04764V7.45628C8.37868 7.67935 8.286 7.89329 8.12104 8.05103C7.95608 8.20877 7.73235 8.29738 7.49907 8.29738C7.26578 8.29738 7.04205 8.20877 6.8771 8.05103C6.71214 7.89329 6.61946 7.67935 6.61946 7.45628V4.04764C6.61946 3.82457 6.71214 3.61063 6.8771 3.45289C7.04205 3.29515 7.26578 3.20654 7.49907 3.20654C7.73235 3.20654 7.95608 3.29515 8.12104 3.45289C8.286 3.61063 8.37868 3.82457 8.37868 4.04764Z"
        fill="#E1E4FB"
      />
      <path
        d="M7.49844 9.16149C7.23337 9.15817 6.97325 9.23029 6.75116 9.36869C6.52907 9.5071 6.35503 9.70553 6.25117 9.93876C6.14732 10.172 6.11834 10.4295 6.16792 10.6785C6.21749 10.9275 6.34339 11.1568 6.5296 11.3372C6.71581 11.5176 6.95394 11.641 7.21368 11.6917C7.47342 11.7424 7.74304 11.7181 7.9883 11.6219C8.23355 11.5257 8.44334 11.3619 8.591 11.1513C8.73865 10.9408 8.81749 10.693 8.81752 10.4395C8.81883 10.2728 8.78575 10.1074 8.72014 9.95285C8.65453 9.7983 8.55769 9.65762 8.43517 9.5389C8.31265 9.42019 8.16685 9.32575 8.00611 9.26099C7.84538 9.19622 7.67286 9.16243 7.49844 9.16149Z"
        fill="#E1E4FB"
      />
    </svg>
  );
};

export default memo(DangerIcon);
