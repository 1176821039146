import { memo, useRef, useState } from "react";
import AlectifyTable from "../table";
import { Col, Row, Spin } from "antd";
import MaintenancePredictionDetails from "./MaintenancePredictionDetails";
import { fetchPredictionData } from "services/prediction/prediction.service";
import { DateRange, IPredictionState } from "./MaintenancePrediction.interface";
import usePredictionColumn from "./usePredictionColumn";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import AlectifyEmptyState from "../empty/AlectifyEmptyState";
import { MESSAGES } from "constants/messages";
import "./MaintenancePrediction.scss";

const MaintenancePrediction: React.FC = () => {
  const [showMaintenanceDetail, setShowMaintenanceDetail] = useState(false);
  const [predictionState, setPredictionState] = useState<IPredictionState>({
    data: [],
    fetching: false,
    meta: {
      next: "",
      previous: "",
      total_pages: 0,
      total_count: 0,
    },
  });
  const [graphRecords, setGraphRecords] = useState({});
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const dateRangeRef = useRef<DateRange | undefined>();
  const openGraphStats = (record: any) => {
    setSelectedRecord(record);
    setShowMaintenanceDetail(true);
    setGraphRecords(record);
    if (selectedRecord?.id === record?.id) {
      setShowMaintenanceDetail(!showMaintenanceDetail);
    }
  };

  const getDateRangeFromChild = (dateRange: DateRange) => {
    dateRangeRef.current = dateRange;
  };

  const columns = usePredictionColumn({
    showMaintenanceDetail,
    setShowMaintenanceDetail,
    openGraphStats,
  });

  const getPredictions = async (options?: IPagination) => {
    try {
      setPredictionState((prevState) => ({
        ...prevState,
        fetching: true,
      }));

      const params = {
        ...options,
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...dateRangeRef.current,
      };

      const response = await fetchPredictionData(params);
      setPredictionState((prevState: any) => ({
        ...prevState,
        data: response.data,
        meta: response.meta,
        fetching: false,
      }));
    } catch (error) {
      setPredictionState((prevState) => ({
        ...prevState,
        fetching: false,
      }));
      console.log(error);
    }
  };
  const customLocale = {
    emptyText: (
      <div>
        <AlectifyEmptyState description={MESSAGES.PREDICTiON.AI_NOT_FOUND} />
      </div>
    ),
  };
  return (
    <Spin spinning={predictionState.fetching}>
      <Row gutter={16}>
        <Col span={showMaintenanceDetail ? 13 : 24}>
          <AlectifyTable
            aIBotHeader
            noIcontainsSearch
            columns={columns}
            scroll={{ y: "calc(100vh - 280px)" }}
            dataSource={predictionState?.data}
            total={predictionState?.meta?.total_count}
            className="alectify-table-card-columns"
            onDataFetch={getPredictions}
            customSearchKey="search"
            getDateRangeFromChild={getDateRangeFromChild}
            setShowMaintenanceDetail={setShowMaintenanceDetail}
            locale={customLocale}
          />
        </Col>
        {showMaintenanceDetail && (
          <Col span={11}>
            <MaintenancePredictionDetails
              record={graphRecords}
              getPredictions={getPredictions}
              getDateRangeFromChild={getDateRangeFromChild}
              setShowMaintenanceDetail={setShowMaintenanceDetail}
            />
          </Col>
        )}
      </Row>
    </Spin>
  );
};

export default memo(MaintenancePrediction);
