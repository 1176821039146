import { Space, Tag, Tooltip } from "antd";
import { CHIP_COLORS } from "../phone-number/constants";
import AlectifyText from "static/texts.json";
import GenericIcon from "components/icons/GenericIcon";

interface GenericTagProps {
  count?: number;
  text?: string;
  showGenericName?: boolean;
}

const GenericTag: React.FC<GenericTagProps> = ({
  count,
  text,
  showGenericName,
}) => {
  return (
    <Tag color={CHIP_COLORS.GENERIC} className="generic-tag round-tag">
      <Space size={3}>
        <Tooltip title={AlectifyText.GENERIC}>
          <GenericIcon />
        </Tooltip>
        {showGenericName && (text || AlectifyText.NO_ASSET)}
      </Space>
    </Tag>
  );
};

GenericTag.defaultProps = {
  text: AlectifyText.NO_ASSET,
  showGenericName: true,
};

export default GenericTag;
