import { memo } from "react";

const DoubleCheckIcon: React.FC<{
  className?: string;
  onClick?: () => void;
  color?: string;
}> = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="12"
      height="14"
      viewBox="0 0 12 14"
      fill="none"
      className={props.className}
    >
      <path
        d="M10.1388 5.29821L12 7L4.34436 14L0 10.0276L1.86102 8.32576L4.34436 10.5964L10.1388 5.29821ZM12 1.70179L10.1388 0L4.34436 5.29821L1.86102 3.02755L0 4.72934L4.34436 8.70179L12 1.70179Z"
        fill={props.color || "#fff"}
      />
    </svg>
  );
};

export default memo(DoubleCheckIcon);
