const ReplacementIcon: React.FC = () => {
  return (
    <svg
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 27993">
        <path
          id="Vector"
          d="M11.6245 7.79612L10.684 7.65852C10.6 7.4548 10.5774 7.4006 10.4928 7.19688L11.061 6.43561C11.1736 6.2849 11.1581 6.07463 11.0253 5.9412L10.2999 5.21567C10.167 5.08283 9.9568 5.06734 9.80612 5.17993L9.04435 5.74879C8.84066 5.66421 8.78646 5.64217 8.58277 5.55758L8.44638 4.61701C8.41957 4.43057 8.25996 4.29297 8.07175 4.29297H7.04673C6.85853 4.29297 6.69891 4.43116 6.67211 4.61701L6.53572 5.55758C6.33203 5.64217 6.27783 5.66421 6.07414 5.74879L5.31297 5.18052C5.16228 5.06794 4.95145 5.08283 4.81863 5.21626L4.09379 5.9406C3.96097 6.07344 3.94549 6.28371 4.05806 6.43501L4.62625 7.19688C4.54228 7.4006 4.51964 7.4548 4.43506 7.65852L3.49463 7.79612C3.30821 7.82352 3.17062 7.98316 3.17062 8.1714V9.19536C3.17062 9.38359 3.3088 9.54323 3.49522 9.57004L4.43566 9.70645C4.52024 9.91017 4.54287 9.96497 4.62684 10.1687L4.05865 10.93C3.94609 11.0807 3.96098 11.2915 4.09439 11.425L4.81923 12.1499C4.95204 12.2827 5.16288 12.2982 5.31356 12.1856L6.07533 11.6174C6.27902 11.702 6.33322 11.7246 6.53751 11.8086L6.6739 12.7491C6.7007 12.9356 6.86032 13.0732 7.04852 13.0732H8.07354C8.26175 13.0732 8.42136 12.935 8.44816 12.7485L8.58456 11.808C8.78825 11.7234 8.84245 11.7008 9.04674 11.6168L9.80791 12.185C9.95859 12.2976 10.1694 12.2821 10.3022 12.1493L11.0271 11.4244C11.1599 11.2909 11.1754 11.0807 11.0628 10.9294L10.4934 10.1663C10.5774 9.96318 10.6 9.90898 10.684 9.70585L11.6262 9.56885C11.8127 9.54204 11.9508 9.3824 11.9508 9.19417V8.17021C11.9508 7.98197 11.8127 7.82233 11.6262 7.79493L11.6245 7.79612ZM8.10749 10.2122C6.7948 10.6446 5.59766 9.44792 6.03006 8.13506C6.18134 7.6758 6.55061 7.30588 7.00981 7.15399C8.32309 6.71974 9.52142 7.91943 9.08723 9.23289C8.93536 9.69215 8.56609 10.0615 8.10689 10.2128L8.10749 10.2122Z"
          fill="white"
        />
        <g id="Group">
          <path
            id="Vector_2"
            d="M1.25093 8.58654C1.25093 5.15377 4.04366 2.36045 7.47643 2.36045H9.14969V3.00881C9.14969 3.17775 9.24189 3.333 9.39 3.41389C9.459 3.45137 9.53514 3.4704 9.61127 3.4704C9.69812 3.4704 9.78497 3.44601 9.86051 3.39724L11.8461 2.12371C11.9787 2.03864 12.0584 1.89351 12.0584 1.73588C12.0584 1.57765 11.9793 1.43251 11.8467 1.34686L9.86111 0.073323C9.71894 -0.0176862 9.53871 -0.0248241 9.39059 0.0566677C9.24248 0.137565 9.15028 0.292816 9.15028 0.461748V1.11011H7.47702C3.35425 1.11011 0 4.46377 0 8.58654C0 8.93155 0.280761 9.21171 0.625763 9.21171C0.970765 9.21171 1.25093 8.93155 1.25093 8.58654Z"
            fill="white"
          />
          <path
            id="Vector_3"
            d="M14.7994 7.06683C14.7387 6.77179 14.477 6.56836 14.1855 6.56836C14.1439 6.56836 14.1017 6.57252 14.0594 6.58145C13.7222 6.65104 13.5039 6.98296 13.574 7.32023C13.6288 7.58493 13.6662 7.85557 13.6865 8.12444C13.7103 8.44981 13.9839 8.7044 14.3093 8.7044L14.3562 8.70202C14.7001 8.67703 14.9594 8.37605 14.9338 8.03224C14.91 7.71043 14.8648 7.38566 14.7988 7.06623L14.7994 7.06683Z"
            fill="white"
          />
          <path
            id="Vector_4"
            d="M12.777 5.31977C12.9518 5.6041 13.3504 5.70046 13.6377 5.5238C13.7798 5.43636 13.8798 5.29836 13.9178 5.13597C13.9565 4.97298 13.9291 4.80524 13.8411 4.66308C13.671 4.38708 13.4812 4.12 13.2766 3.86898C13.1713 3.7393 13.0214 3.65841 12.8555 3.64175C12.8335 3.63937 12.8121 3.63818 12.79 3.63818C12.6473 3.63818 12.5093 3.68696 12.3963 3.77856C12.2666 3.88385 12.1857 4.03375 12.1684 4.1997C12.1512 4.36626 12.2 4.52865 12.3053 4.65832C12.4748 4.86651 12.633 5.08898 12.7752 5.31977H12.777Z"
            fill="white"
          />
          <path
            id="Vector_5"
            d="M14.2414 9.8351C14.1861 9.82023 14.1302 9.8125 14.0749 9.8125C13.8007 9.8125 13.5479 9.99392 13.4711 10.2705C13.3997 10.5281 13.3093 10.785 13.2029 11.0343C13.0678 11.3513 13.2153 11.7195 13.533 11.8551C13.8453 11.9884 14.2218 11.8343 14.3539 11.525C14.4817 11.2252 14.59 10.9159 14.6768 10.6048C14.7215 10.4442 14.7012 10.2753 14.6186 10.1295C14.5365 9.98381 14.402 9.87912 14.2414 9.83451V9.8351Z"
            fill="white"
          />
          <path
            id="Vector_6"
            d="M12.4831 12.6392C12.476 12.6392 12.4682 12.6392 12.4611 12.6392C12.2946 12.6451 12.1399 12.7153 12.0263 12.8372C11.8425 13.0341 11.6432 13.2209 11.4344 13.3928C11.1679 13.6117 11.1299 14.0067 11.3488 14.2732C11.5593 14.5289 11.9722 14.57 12.2297 14.3588C12.4801 14.1524 12.7193 13.9282 12.9399 13.692C13.1755 13.4398 13.1624 13.0436 12.9102 12.8081C12.7936 12.6986 12.6425 12.6392 12.4831 12.6392Z"
            fill="white"
          />
          <path
            id="Vector_7"
            d="M6.62583 14.7543C6.35578 14.7169 6.0881 14.6621 5.83054 14.5919C5.77582 14.5771 5.7199 14.5693 5.66458 14.5693C5.55751 14.5693 5.45104 14.5973 5.35527 14.652C5.21073 14.7347 5.10663 14.8691 5.06261 15.0309C4.9716 15.3634 5.16909 15.7084 5.5016 15.7994C5.8115 15.8839 6.13271 15.9493 6.45511 15.994C6.79833 16.0421 7.11359 15.7959 7.15999 15.4598C7.18318 15.2944 7.13976 15.1297 7.03923 14.9964C6.93871 14.8632 6.79179 14.7775 6.62583 14.7543Z"
            fill="white"
          />
          <path
            id="Vector_8"
            d="M3.88367 13.6736C3.66298 13.5172 3.45122 13.3453 3.25374 13.1626C3.13715 13.055 2.98726 12.9961 2.82903 12.9961C2.8207 12.9961 2.81237 12.9961 2.80405 12.9961C2.6369 13.0026 2.48284 13.074 2.36982 13.1966C2.25621 13.3191 2.19732 13.4785 2.20386 13.6456C2.2104 13.8128 2.28119 13.9675 2.40432 14.0805C2.64166 14.3006 2.89625 14.507 3.16095 14.6943C3.43338 14.8871 3.84024 14.8169 4.03297 14.545C4.23224 14.2637 4.16562 13.8723 3.88367 13.673V13.6736Z"
            fill="white"
          />
          <path
            id="Vector_9"
            d="M1.62093 10.7054C1.56382 10.5478 1.44962 10.4229 1.29794 10.3515C1.21347 10.3116 1.12365 10.292 1.03323 10.292C0.96126 10.292 0.889288 10.3045 0.819692 10.3301C0.49551 10.4478 0.327169 10.8071 0.444351 11.1313C0.554394 11.4347 0.685853 11.7351 0.83575 12.0241C0.989812 12.3204 1.37883 12.4471 1.67922 12.2906C1.98496 12.1318 2.10512 11.7535 1.94571 11.4472C1.82258 11.2098 1.71313 10.96 1.61974 10.7042L1.62093 10.7054Z"
            fill="white"
          />
          <path
            id="Vector_10"
            d="M10.0372 14.4801C9.95211 14.4397 9.86051 14.4194 9.7689 14.4194C9.69812 14.4194 9.62793 14.4319 9.55952 14.4557C9.30315 14.5467 9.04083 14.6211 8.7791 14.677C8.61553 14.7121 8.47514 14.8079 8.38473 14.9482C8.29372 15.0886 8.26279 15.2558 8.29789 15.4187C8.36808 15.7495 8.7107 15.9719 9.04024 15.9006C9.35728 15.8333 9.67314 15.7435 9.97948 15.6341C10.1371 15.5776 10.2632 15.464 10.3346 15.3129C10.406 15.1624 10.4149 14.9923 10.359 14.8352C10.3031 14.6776 10.1889 14.5521 10.0378 14.4801H10.0372Z"
            fill="white"
          />
        </g>
      </g>
    </svg>
  );
};

export default ReplacementIcon;
