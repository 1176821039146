import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

interface IDrawIconProps {
  fill?: string | null;
}
const DrawIcon: React.FC<IDrawIconProps> = (props: IDrawIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M17.2897 12.5519H5.34121L5.94114 11.2686L15.9093 11.2496C16.2463 11.2496 16.5353 10.9967 16.5955 10.6469L17.9759 2.53149C18.012 2.31864 17.9579 2.09948 17.8254 1.933C17.76 1.85107 17.6784 1.78507 17.5862 1.73958C17.494 1.69409 17.3935 1.67021 17.2917 1.66958L4.61086 1.62533L4.50251 1.09006C4.43429 0.748667 4.14134 0.5 3.80827 0.5H0.708282C0.520434 0.5 0.34028 0.578374 0.207451 0.717882C0.0746224 0.857389 0 1.0466 0 1.24389C0 1.44119 0.0746224 1.6304 0.207451 1.76991C0.34028 1.90942 0.520434 1.98779 0.708282 1.98779H3.23442L3.70795 4.35224L4.8737 10.2802L3.37287 12.8533C3.29493 12.9638 3.24798 13.095 3.23734 13.232C3.22671 13.3691 3.2528 13.5065 3.31267 13.6288C3.43306 13.8796 3.67584 14.0376 3.94471 14.0376H5.20477C4.93614 14.4124 4.79105 14.8688 4.79144 15.3379C4.79144 16.5306 5.71441 17.5 6.85007 17.5C7.98573 17.5 8.90871 16.5306 8.90871 15.3379C8.90871 14.8679 8.76023 14.4106 8.49537 14.0376H11.7278C11.4592 14.4124 11.3141 14.8688 11.3145 15.3379C11.3145 16.5306 12.2374 17.5 13.3731 17.5C14.5088 17.5 15.4317 16.5306 15.4317 15.3379C15.4317 14.8679 15.2832 14.4106 15.0184 14.0376H17.2917C17.681 14.0376 18 13.7047 18 13.2937C17.9988 13.0966 17.9235 12.908 17.7904 12.7691C17.6574 12.6301 17.4774 12.552 17.2897 12.5519ZM4.90581 3.09204L16.459 3.13208L15.3274 9.7871L6.25215 9.80395L4.90581 3.09204ZM6.85007 16.0038C6.50095 16.0038 6.21603 15.7045 6.21603 15.3379C6.21603 14.9712 6.50095 14.6719 6.85007 14.6719C7.1992 14.6719 7.48412 14.9712 7.48412 15.3379C7.48412 15.5145 7.41732 15.6839 7.29841 15.8087C7.1795 15.9336 7.01823 16.0038 6.85007 16.0038ZM13.3731 16.0038C13.024 16.0038 12.739 15.7045 12.739 15.3379C12.739 14.9712 13.024 14.6719 13.3731 14.6719C13.7222 14.6719 14.0071 14.9712 14.0071 15.3379C14.0071 15.5145 13.9403 15.6839 13.8214 15.8087C13.7025 15.9336 13.5413 16.0038 13.3731 16.0038Z"
        stroke={props.fill || PROJECT_THEME_COLOR}
      />
    </svg>
  );
};

export default DrawIcon;
