import { AxiosResponse } from "axios";
import { http, MULTIPART_HEADERS } from "utils/client";
import {
  FETCH_CONTACTS,
  UPDATE_CONTACT,
  VERIFY_EMAIL,
} from "./contacts.endpoints";
import { buildExpressToNormalResponse } from "./contacts.normalizer";
import {
  IFetchContactsParams,
  IContactsResponse,
  IContactExpressResponse,
} from "./contacts.interface";

/**
 * Fetches contacts
 *
 * @param {IFetchContactsParams} params - params to fetch contacts
 * @returns A Promise that gets the contacts data with the help of normalizer {buildExpressToNormalResponse}.
 * @throws If an error occurs during the request.
 */
export const fetchContacts = async (
  params: IFetchContactsParams,
): Promise<IContactsResponse> => {
  try {
    const response: AxiosResponse<IContactExpressResponse> = await http.get(
      FETCH_CONTACTS,
      params,
    );
    return buildExpressToNormalResponse(response.data);
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

// /user/validate-email/
/**
 * Cheak the email already Exist
 *
 * @returns A Promise that create the new contact.
 * @throws If an error occurs during the request.
 */
export const verifyEmailExist = async (body: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.post(VERIFY_EMAIL, body);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * create contacts
 *
 * @returns A Promise that create the new contact.
 * @throws If an error occurs during the request.
 */
export const CreateContacts = async (body: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.post(
      FETCH_CONTACTS,
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Asynchronous function for updating a contact data via an HTTP PATCH request.
 * @param {any} body - The data to be sent in the request body.
 * @returns {Promise<any>} A Promise that resolves to the updated user's profile data.
 * @throws {Error} If there's an error during the HTTP request.
 */
export const updateContact = async (
  body: any,
  selectedContactId: string,
): Promise<any> => {
  try {
    const url = UPDATE_CONTACT.replace("{id}", selectedContactId);
    const response = await http.patch(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * delete contacts
 * @throws If an error occurs during the request.
 */
export const DeleteContact = async (body: any): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.delete(
      FETCH_CONTACTS,
      body,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
