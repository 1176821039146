import Calendar from "pages/calendar";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { setShowCalendarMyWorkOrders } from "redux/components/common/sources";

const CompanyWideCalendar: React.FC = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setShowCalendarMyWorkOrders(false));
  }, []);

  return <Calendar />;
};

export default CompanyWideCalendar;
