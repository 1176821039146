import { ChangeEvent, useState } from "react";
import { Input, InputNumber, Space, Select } from "antd";
import { IAlectifyHoursPickerProps } from "./AlectifyHoursPicker.interface";

import "./AlectifyHoursPicker.scss";

const { Option } = Select;

const AlectifyHoursPicker: React.FC<IAlectifyHoursPickerProps> = (
  props: IAlectifyHoursPickerProps,
) => {
  const [time, setTime] = useState<{
    days: string;
    hours: string;
    minutes: string;
  }>({
    days: "00",
    hours: "00",
    minutes: "00",
  });

  const handleTimeChange = (
    value: number | null,
    type: "days" | "hours" | "minutes",
  ) => {
    const formattedValue =
      value !== null ? value.toString().padStart(2, "0") : "00";
    const newTime = { ...time, [type]: formattedValue };
    setTime(newTime);
    props.onChange(`${newTime.days}:${newTime.hours}:${newTime.minutes}`);
  };

  const handleSelectChange = (value: string, type: "hours" | "minutes") => {
    const newTime = { ...time, [type]: value };
    setTime(newTime);
    props.onChange(`${newTime.days}:${newTime.hours}:${newTime.minutes}`);
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let inputValue = e.target.value;
    inputValue = inputValue.replace(/[^\d]/g, "");

    const hours = inputValue.slice(0, 2);
    let minutes = inputValue.slice(2, 4);

    if (parseInt(minutes, 10) > 59) {
      minutes = "59";
    }
    const formattedValue = `${hours}${minutes ? `:${minutes}` : ""}`;
    props.onChange(formattedValue);
  };

  const generateOptions = (range: number) => {
    return Array.from({ length: range }, (_, i) => (
      <Option key={i} value={i.toString().padStart(2, "0")}>
        {i.toString().padStart(2, "0")}
      </Option>
    ));
  };

  return (
    <>
      {!props.isdayTimeField ? (
        <Input
          {...props}
          onChange={handleChange}
          className={`${props.className} "alectify-hours-input"`}
          placeholder="HH:MM"
        />
      ) : (
        <Space className="alectify-hours-picker">
          <InputNumber
            min={0}
            max={100}
            value={parseInt(time.days)}
            onChange={(value) => handleTimeChange(value, "days")}
            placeholder="Day"
            className="alectify-hours-input"
          />
          <span>{parseInt(time.days) > 1 ? "Days" : "Day"}</span>
          <Select
            value={time.hours}
            onChange={(value) => handleSelectChange(value, "hours")}
            placeholder="Hour"
            className="alectify-hours-input"
            dropdownClassName="alectify-dropdown"
          >
            {generateOptions(24)}
          </Select>
          <span>{parseInt(time.hours) > 1 ? "Hours" : "Hour"}</span>
          <Select
            value={time.minutes}
            onChange={(value) => handleSelectChange(value, "minutes")}
            placeholder="Minute"
            className="alectify-hours-input"
            dropdownClassName="alectify-dropdown"
          >
            {generateOptions(60)}
          </Select>
          <span>{parseInt(time.minutes) > 1 ? "Minutes" : "Minute"}</span>
        </Space>
      )}
    </>
  );
};

export default AlectifyHoursPicker;
