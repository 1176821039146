import { Breadcrumb, Col, Row, Typography } from "antd";
import AlectifyText from "static/texts.json";
import AllTimelineTable from "./all-timeline-table/AllTimelineTable";

const AllTimeline: React.FC = () => {
  return (
    <Row
      justify={"start"}
      align={"middle"}
      className="alectify-procedure-container"
    >
      <Col span={24}>
        <Breadcrumb
          className=""
          items={[
            {
              title: "Home",
              href: `#`,
            },
            {
              title: AlectifyText.TIMELINE,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <Typography.Title level={4} className="mt-10">
          {AlectifyText.TIMELINE}
        </Typography.Title>
      </Col>
      <AllTimelineTable />
    </Row>
  );
};

export default AllTimeline;
