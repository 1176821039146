export const PM_DIRECTORIES = {
  IMAGES: "Images",
  VIDEOS: "Videos",
  PM_REPORTS: "PM Reports",
  PM_INSTRUCTIONS: "PM Instructions",
  COMMERCIAL_DOCS: "Commercial Docs",
};

export const TASK_DIRECTORIES = {
  PO: "PO",
  OTHERS: "Others",
  QUOTATION: "Quotation",
  SERVICE_REQUIRED: "Service Required",
};
