export const documentInitialState = {
  fetching: false,
  projectDocs: {
    fetching: false,
    data: [],
    message: "",
    meta: {
      total_count: 0,
      total_pages: 0,
      next: "",
      previous: "null",
    },
  },
  assetDocs: {
    fetching: false,
    data: [],
    message: "",
    meta: {
      total_count: 0,
      total_pages: 0,
      next: "",
      previous: "null",
    },
  },
  assetPackageDocs: {
    fetching: false,
    data: [],
    message: "",
    meta: {
      total_count: 0,
      total_pages: 0,
      next: "",
      previous: "null",
    },
  },
  uploadedDocuments: [],
  documentsCount: {
    data: {
      asset_docs_count: 0,
      project_docs_count: 0,
      package_docs_count: 0,
    },
    success: false,
    message: "",
  },
};
