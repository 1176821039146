import React, { memo } from "react";
import { Radio, Space } from "antd";
import type { RadioChangeEvent } from "antd";
import "../style.scss";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { setActiveToggleButton } from "redux/components/procedures/sources";

interface IFilterOption {
  value: string;
  label: string;
}

interface IFilterAssetsProps {
  options: IFilterOption[];
  label?: string;
  className?: string;
  onChange?: (value: string) => void;
}

const FilterAssets: React.FC<IFilterAssetsProps> = ({
  options,
  label,
  className,
  onChange,
}) => {
  const dispatch = useDispatch();
  const { activeToggleButton } = useSelector(
    ({ procedures }: IRootState) => procedures,
  );
  const handleChange = ({ target: { value } }: RadioChangeEvent) => {
    dispatch(setActiveToggleButton(value));
    if (onChange) onChange(value);
  };

  return (
    <div className={`toggle-button-container ${className}`}>
      <Space>
        {label}
        <Radio.Group value={activeToggleButton} onChange={handleChange}>
          {options.map((option) => (
            <Radio.Button
              key={option.value}
              value={option.value}
              className={activeToggleButton === option.value ? "active" : ""}
            >
              {option.label}
            </Radio.Button>
          ))}
        </Radio.Group>
      </Space>
    </div>
  );
};

export default memo(FilterAssets);
