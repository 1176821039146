import React from "react";

const UploadIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="39"
      height="37"
      viewBox="0 0 39 37"
      fill="none"
    >
      <path
        d="M7.23414 10.9887C3.70162 11.3142 0.949951 14.5524 0.949951 18.4459C0.949951 22.5514 4.01087 25.93 7.81911 25.93H10.1552C10.5287 25.93 10.7946 25.6061 10.7946 25.2544C10.7946 24.9028 10.5287 24.5789 10.1552 24.5789H7.81911C4.75736 24.5789 2.22871 21.8486 2.22871 18.4459C2.22871 15.0432 4.75736 12.313 7.81911 12.313H7.97513C8.40305 12.313 8.67501 11.8982 8.60252 11.5071L8.60251 11.507C8.17058 9.18155 8.71039 6.79912 10.0779 4.97077C13.31 0.649681 19.5394 1.30525 21.9346 6.29007L21.9347 6.29016C22.1099 6.65438 22.5648 6.78441 22.8919 6.5217L22.892 6.52165C23.7224 5.85429 24.703 5.50415 25.7299 5.50415C28.3623 5.50415 30.5416 7.85265 30.5416 10.7861C30.5416 11.0342 30.5248 11.2896 30.4914 11.5441C30.4668 11.731 30.5172 11.923 30.6346 12.0712L30.8305 11.9159L30.6346 12.0712C30.7531 12.2206 30.931 12.3127 31.1248 12.3127H31.181C34.2427 12.3127 36.7714 15.0429 36.7714 18.4456C36.7714 21.8483 34.2427 24.5786 31.181 24.5786H28.8448C28.4713 24.5786 28.2055 24.9024 28.2055 25.2541C28.2055 25.6058 28.4713 25.9296 28.8448 25.9296H31.1808V25.9296C34.989 25.9296 38.0499 22.5514 38.0499 18.4456C38.0499 14.5707 35.3252 11.3456 31.817 10.9938C31.9274 7.3031 29.201 4.15342 25.7298 4.15342C24.6627 4.15342 23.6362 4.45019 22.7243 5.01615C19.6624 -0.264701 12.7188 -0.739803 9.07454 4.13269L9.07454 4.13271C7.62516 6.0708 6.97342 8.53213 7.23414 10.9887Z"
        fill="#0954F1"
        stroke="#0954F1"
        strokeWidth="0.5"
      />
      <path
        d="M9.46338 25.0134C9.46338 30.9961 13.9216 35.9018 19.4475 35.9018C24.9735 35.9018 29.4316 30.9961 29.4316 25.0134C29.4316 19.0306 24.9734 14.125 19.4475 14.125C13.9216 14.125 9.46338 19.0307 9.46338 25.0134ZM10.7423 25.0134C10.7423 19.7336 14.6681 15.4763 19.4475 15.4763C24.2268 15.4763 28.1527 19.7335 28.1527 25.0134C28.1527 30.2932 24.2268 34.5505 19.4475 34.5505C14.6682 34.5505 10.7423 30.2933 10.7423 25.0134Z"
        fill="#0954F1"
        stroke="#0954F1"
        strokeWidth="0.5"
      />
      <path
        d="M19.1911 29.2963C19.1911 29.5384 19.3736 29.7579 19.6261 29.7579C19.8786 29.7579 20.0612 29.5387 20.0612 29.2963V21.1963C20.0612 20.9543 19.8786 20.7348 19.6261 20.7348C19.3736 20.7348 19.1911 20.9543 19.1911 21.1963V29.2963Z"
        fill="#0954F1"
        stroke="#0954F1"
        strokeWidth="0.3"
      />
      <path
        d="M22.1081 23.9089L22.2189 24.01C22.135 24.102 22.0218 24.1502 21.9065 24.1502L22.1081 23.9089ZM22.1081 23.9089C22.0525 23.9699 21.9794 24.0002 21.9065 24.0002L22.1081 23.9089ZM22.1081 23.9089L22.2187 24.0102C22.3826 23.8311 22.3826 23.5461 22.2187 23.3671L19.9383 20.8748C19.7676 20.6882 19.4846 20.688 19.3138 20.8748C19.3138 20.8748 19.3138 20.8748 19.3138 20.8748C19.3138 20.8748 19.3138 20.8749 19.3137 20.8749L17.0334 23.3671C16.8696 23.5461 16.8696 23.8311 17.0334 24.0102C17.2042 24.1968 17.4872 24.197 17.6579 24.0102C17.6579 24.0102 17.658 24.0101 17.658 24.0101L19.6261 21.8591M22.1081 23.9089L19.6261 21.8591M19.6261 21.8591L21.5943 24.0102C21.678 24.1016 21.791 24.1502 21.9065 24.1502L19.6261 21.8591Z"
        fill="#0954F1"
        stroke="#0954F1"
        strokeWidth="0.3"
      />
    </svg>
  );
};

export default UploadIcon;
