export const USER_TYPES: { [key: number]: string } = {
  1: "Primary",
  2: "Internal",
  3: "External",
};

export const USER_TYPES_KEY = {
  ADMIN: 1,
  INTERNAL: 2,
  EXTERNAL: 3,
};

export const KEYS_TO_EXCLUDE: (string | number)[] = [
  "image_url",
  "contact_number",
  "contact_number_prefix",
  "business_phone_number",
  "business_phone_number_prefix",
  "personal_contact_type",
  "business_contact_type",
  "access_expiry_date",
];
