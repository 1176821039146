import { memo } from "react";

const CreatorIcon: React.FC = () => {
  return (
    <svg
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 28003">
        <g id="Group 27926">
          <g id="Group 27925">
            <g id="Group 27927">
              <path
                id="Vector 151"
                d="M6.17647 1V12"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                id="Vector 152"
                d="M11.353 6.49976L1.00003 6.49976"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default memo(CreatorIcon);
