import React from "react";

const PdfIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="23"
      viewBox="0 0 20 23"
      fill="none"
    >
      <path
        d="M1.42856 0H13.5664L20 6.40861V21.4286C20 22.2179 19.36 22.8572 18.5714 22.8572H1.42856C0.639966 22.8572 0 22.2179 0 21.4286V1.42856C0 0.639301 0.64004 0 1.42856 0Z"
        fill="#E2574C"
      />
      <path
        d="M19.9801 6.42602H15.0008C14.2122 6.42602 13.5723 5.78605 13.5723 4.99746V0.0117188L19.9801 6.42602Z"
        fill="#B53629"
      />
      <path
        d="M16.5005 11.1531C16.8358 11.1531 17 10.8606 17 10.5771C17 10.2835 16.8288 10 16.5005 10H14.5907C14.2174 10 14.0092 10.3095 14.0092 10.6512V15.3488C14.0092 15.7676 14.2474 16 14.5697 16C14.89 16 15.1293 15.7676 15.1293 15.3488V14.0595H16.2844C16.6427 14.0595 16.8219 13.7659 16.8219 13.4744C16.8219 13.1889 16.6427 12.9053 16.2844 12.9053H15.1293V11.1531H16.5005ZM10.0455 10H8.6482C8.26884 10 7.99956 10.2605 7.99956 10.6472V15.3528C7.99956 15.8327 8.3869 15.983 8.66415 15.983H10.1305C11.8661 15.983 13.0122 14.8399 13.0122 13.0756C13.0112 11.2102 11.9323 10 10.0455 10ZM10.1126 14.8229H9.26079V11.1602H10.0285C11.1906 11.1602 11.6961 11.9406 11.6961 13.0176C11.6961 14.0255 11.1996 14.8229 10.1126 14.8229ZM4.99386 10H3.60959C3.21821 10 3 10.2584 3 10.6512V15.3488C3 15.7676 3.25022 16 3.5865 16C3.92278 16 4.17299 15.7676 4.17299 15.3488V13.9773H5.04077C6.11175 13.9773 6.99559 13.2178 6.99559 11.9966C6.99569 10.8015 6.14293 10 4.99386 10ZM4.97087 12.8753H4.1731V11.1031H4.97087C5.46333 11.1031 5.77661 11.4877 5.77661 11.9897C5.77558 12.4907 5.46333 12.8753 4.97087 12.8753Z"
        fill="white"
      />
    </svg>
  );
};

export default PdfIcon;
