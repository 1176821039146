//libs
import { useEffect } from "react";
import { isEmpty } from "lodash";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router-dom";
//internal
import TasksTable from "components/shared/tasks-table";
import { getProjectUsers } from "redux/components/users/sources";
import { PM_TYPES } from "redux/components/pm-external";
// import { fetchTaskDetails } from "services/tasks/tasks.service";

const Tasks = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { masterProjectId, subProjectId } = useParams();
  const taskId = new URLSearchParams(location.search).get("taskId");

  const openTaskDetailDrawer = async (id: string) => {
    // const response = await fetchTaskDetails({
    //   id,
    // });
    // if (response?.id) {
    //   DrawerServiceInstance.open(AlectifyDrawer, {
    //     width: 420,
    //     title: response.name || "-",
    //     name: DRAWER_CONSTANTS.DRAWER_TASK_DETAIL,
    //     onClose: () =>
    //       DrawerServiceInstance.close(AlectifyDrawer, {
    //         name: DRAWER_CONSTANTS.DRAWER_TASK_DETAIL,
    //       }),
    //     children: <TaskDetail taskId={response.id} />,
    //   });
    // }
  };

  useEffect(() => {
    dispatch(getProjectUsers(subProjectId));
    if (!isEmpty(taskId)) {
      openTaskDetailDrawer(taskId || "");
    }
  }, [masterProjectId, subProjectId]);

  return (
    <TasksTable
      subProjectId={subProjectId}
      masterProjectId={masterProjectId}
      allowCreation
      scroll={{ y: "calc(100vh - 375px)" }}
      myWorkdOrder={false}
      type={PM_TYPES.TASK}
    />
  );
};

export default Tasks;
