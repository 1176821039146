import React from "react";
import { getGreetingMessage } from "utils/helpers";

const WelcomeMessage: React.FC = () => {
  const greeting = getGreetingMessage();

  return <>{greeting}</>;
};

export default WelcomeMessage;
