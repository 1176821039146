//libs
import { Col, Row, Space, Spin, Typography, message } from "antd";
import { useCallback, useEffect, useState } from "react";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";
import { MESSAGES } from "constants/messages";
import { PlusOutlined } from "@ant-design/icons";

//components
import { IProcedureCheckListProps } from "pages/procedures/procedure-creation/check-list/ProcedureCheckList.interface";
import { Card } from "pages/procedures/procedure-creation/check-list/CheckListCard";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";

//services & helpers
import { IProcedureCheckList } from "services/procedures/procedures.interface";
import {
  createProcedureSteps,
  deleteProcedureSteps,
  fetchProcedureSteps,
  updateOrder,
} from "services/procedures/procedures.service";

//styles
import "./ProcedureCheckList.scss";
import TextToLink from "components/shared/text-to-link";
import AlectifyButton from "components/shared/button";

const ProcedureCheckList: React.FC<IProcedureCheckListProps> = (
  props: IProcedureCheckListProps,
) => {
  const [checkList, setCheckList] = useState<IProcedureCheckList[]>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setCheckList((prevCards: IProcedureCheckList[]) => {
      const newCards = [...prevCards]; // Create a shallow copy of the array

      // Remove the card at dragIndex
      const draggedCard = newCards.splice(dragIndex, 1)[0];

      // Insert the dragged card at hoverIndex
      newCards.splice(hoverIndex, 0, draggedCard);
      updateOrder(
        props.procedureId || "",
        newCards.map((card: IProcedureCheckList, index: number) => {
          return {
            order: index + 1,
            id: card.id,
          };
        }),
      );
      return newCards;
    });
  }, []);

  const renderCard = useCallback(
    (
      card: {
        id: string;
        name: string;
        order: string;
        imageUrl?: string;
        draft: boolean;
      },
      index: number,
    ) => {
      return (
        <Card
          procedureId={props.procedureId}
          key={card.id}
          index={index}
          id={card.id}
          draft={card.draft}
          text={card.name}
          imageUrl={card.imageUrl}
          order={card.order}
          moveCard={moveCard}
          removeCard={handleRemoveCard}
          addNew={handleAddNewCard}
          getSteps={getSteps}
        />
      );
    },
    [checkList],
  );

  const getSteps = async () => {
    try {
      setLoader(true);
      const response = await fetchProcedureSteps(props.procedureId || "");
      if (response.status) {
        if (response.data.length > 0) {
          setCheckList(
            response.data.map((steps: IProcedureCheckList, index) => {
              return {
                ...steps,
                draft: false,
              };
            }),
          );
        }
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const handleAddNewCard = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("name", "");
      formData.append("order", String(checkList.length + 1));
      formData.append("procedureLibraryId", props.procedureId || "");
      const response = await createProcedureSteps(formData);
      if (response.status) {
        setLoader(false);
        setCheckList((prevCheckList) => [
          ...prevCheckList,
          { ...(response.data as any), draft: false },
        ]);
      }
    } catch (ex) {
      setLoader(false);
      console.log("error", ex);
    }
  };

  const onDeleteProcedureStep = async (id: string) => {
    try {
      setLoader(true);
      const response = await deleteProcedureSteps(id);
      if (response.status) {
        message.success("Step deleted successfully.");
        setCheckList((prevCheckList) =>
          prevCheckList.filter((card) => card.id !== id),
        );
        getSteps();
        setLoader(false);
      }
    } catch (ex) {
      setLoader(false);
    }
  };

  const handleRemoveCard = (id: string, draft: boolean) => {
    if (checkList.length < 1) {
      message.error(MESSAGES.PROCEDURE_MESSAGES.CANNOT_DELETE_CHECKLIST);
      return;
    }
    if (!draft) {
      onDeleteProcedureStep(id);
    } else {
      setCheckList((prevCheckList) =>
        prevCheckList.filter((card, i) => card.id !== id),
      );
    }
  };

  useEffect(() => {
    if (props.procedureId) {
      getSteps();
    }
  }, [props.procedureId]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <Typography.Title level={5} className="mt-0">
            Checklist
          </Typography.Title>
        </Col>
        <Col span={12} className="text-align-right">
          <AlectifyButton
            type="primary"
            text="Add New"
            icon={<PlusOutlined />}
            onClick={handleAddNewCard}
            className="light-blue-button"
          />
        </Col>
        {checkList.length > 0 ? (
          <>
            <Col span={24}>
              <Row justify={"start"} align={"middle"}>
                <Col span={4}>
                  <strong>
                    Serial No#{" "}
                    <span className="alectify-procedure-create-edit-required">
                      *
                    </span>
                  </strong>
                </Col>
                <Col span={20}>
                  <strong>
                    Job Description{" "}
                    <span className="alectify-procedure-create-edit-required">
                      *
                    </span>
                  </strong>
                </Col>
                <Col span={24}>
                  <Spin spinning={loader}>
                    <div className="alectify-procedure-checklist-dnd-container">
                      <DndProvider backend={HTML5Backend}>
                        <div className="alectify-procedure-checklist-container">
                          {checkList.map((card, i) =>
                            renderCard(card as any, i),
                          )}
                        </div>
                      </DndProvider>
                    </div>
                  </Spin>
                </Col>
              </Row>
            </Col>
          </>
        ) : (
          <div className="alecitfy-procedure-steps-empty">
            {!loader && (
              <Space direction="vertical">
                <AlectifyEmptyState />
                <span>
                  Looks like you don't have any Steps.{" "}
                  <TextToLink
                    text="Click on Add new button"
                    onClick={handleAddNewCard}
                    className="add-new-text"
                    underline
                  />{" "}
                  to add a new step.
                </span>
              </Space>
            )}
          </div>
        )}
      </Row>
    </>
  );
};

export default ProcedureCheckList;
