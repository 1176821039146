import React, { useMemo } from "react";
import "./CreateEditSubProject.scss";
import AlectifyText from "static/texts.json";
import { IStepNavigationProps } from "./CreateEditSubProject.interface";

const StepNavigation: React.FC<IStepNavigationProps> = ({
  currentActiveTab,
}) => {
  const steps = useMemo(
    () => [
      { number: 1, label: AlectifyText.DETAIL },
      { number: 2, label: AlectifyText.MANAGE_ASSET },
    ],
    [],
  );

  return (
    <div className="step-navigation">
      {steps.map((step, index) => (
        <React.Fragment key={step.number}>
          <div
            className={`step ${
              currentActiveTab >= step.number ? "active" : ""
            }`}
          >
            <span
              className={`step-number ${
                currentActiveTab >= step.number
                  ? "step-header-active"
                  : "step-not-active"
              }`}
            >
              {currentActiveTab > step.number ? "✔" : step.number}
            </span>
            <span
              className={`text ${
                currentActiveTab >= step.number ? "text-active" : ""
              }`}
            >
              {step.label}
            </span>
          </div>
          {index < steps.length - 1 && (
            <div
              className={`line ${
                currentActiveTab >= step.number + 1 ? "filled" : ""
              }`}
            ></div>
          )}
        </React.Fragment>
      ))}
    </div>
  );
};

export default React.memo(StepNavigation);
