import { Avatar, Badge, Dropdown, Row, Space, Tooltip, message } from "antd";
import { ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import {
  EllipsisOutlined,
  PaperClipOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import AvatarGroup from "components/shared/avatar-group";
import "../PmInternalTable.scss";
import {
  capitalizeFirstLetter,
  displayDateTime,
  getFullUserName,
} from "utils/helpers";
import {
  IPmInternal,
  IPmUser,
} from "redux/components/pm-internal/pm-internal.interface";
import { IPMExternalHookProps } from "../PmInternalTable.interface";
import TextToLink from "components/shared/text-to-link";
import useMyWorkOrdersColumns from "pages/my-work-orders/effects/useMyWorkOrdersColumns";
import {
  AssetPackageTag,
  AssetTag,
  AssigneeTag,
  GenericTag,
  NonRecurringTag,
  RecurringTag,
  ScheduledTag,
  SkippedTag,
  WaitingForReviewTag,
} from "components/shared/tags";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { CommentReferenceEnum, PM_STATUS } from "enums";
import Comments from "components/shared/comments";
import { isEmpty, truncate } from "lodash";
import AssetDetail from "components/shared/asset-detail";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { ISubProject } from "redux/components/sub-project";
import PreventiveDocumentsAttachment from "components/shared/preventive-documents/PreventiveDocumentsAttachment";
import { actionItems } from "../PmInternalTable.rules";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import PmDetail from "components/shared/pm-detail";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { ColumnGroupType } from "antd/es/table";
import { isDelayed } from "components/shared/pm-external-table/PmExternal.helpers";
import DelayedTag from "components/shared/tags/DelayedTag";
import OnTimeTag from "components/shared/tags/OnTimeTag";
import { PM_TYPES } from "redux/components/pm-external";
import { useNavigate } from "react-router-dom";
import ClosedTag from "components/shared/tags/ClosedTag";
import ProcedureDetail from "components/shared/procedure-detail";
import { countCheckedSteps } from "pages/procedures/Procedures.helpers";
import { IProcedure } from "services/procedures/procedures.interface";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "components/shared/phone-number/constants";
import { CalendarDateIcon, UserIcon, WarningIcon } from "components/icons";
import AlectifyButton from "components/shared/button";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { undoTaskStatus } from "services/tasks/tasks.service";
import { MESSAGES } from "constants/messages";
import { FrequencyTypeEnum } from "components/shared/pm-create-edit-form/PMCreateEditForm.interace";
import ReopenedIcon from "components/icons/ReopenedIcon";
import InProgressTag from "components/shared/tags/InProgressTag";

export const PM_STATUS_COMPONENTS = {
  [PM_STATUS.COMPLETED]: <ClosedTag />,
  [PM_STATUS.PENDING]: <ScheduledTag />,
  [PM_STATUS.SKIPPED]: <SkippedTag />,
  [PM_STATUS.WAITING_FOR_REVIEW]: <WaitingForReviewTag />,
  [PM_STATUS.INPROGRESS]: <InProgressTag />,
};

const PmInternalColumns = (props: IPMExternalHookProps) => {
  const navigate = useNavigate();
  const myItemsColumns = useMyWorkOrdersColumns();
  const currentUser = useSelector((state: IRootState) => state.auth.user);
  const { showMyWorkOrders } = useSelector((state: IRootState) => state.common);
  const { projectTeamMembers } = useSelector(({ users }: IRootState) => users);

  const getTaskStatusIcon = (procedure: IProcedure): JSX.Element => {
    const isAllChecked =
      procedure.procedureStepCheckedTotalCount ===
      procedure.procedureStepTotalCount;
    return isAllChecked ? (
      <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
    ) : (
      <StampCheckIcon />
    );
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.CREATED,
      key: "created",
      dataIndex: "created_at",
      visible: !props.myItemsColumns,
      sorter: true,
      sortingColumn: "created_at",
      ellipsis: true,
      width: 110,
      render: (target: any, record: any) => (
        <Space direction="vertical" size={8}>
          <div className="creator-container">
            <CalendarDateIcon />
            <span className="creator-name-date">
              {`${displayDateTime(
                record?.created_at || record?.createdAt,
                true,
                false,
              )}`}
            </span>
          </div>

          <div className="creator-container">
            {isEmpty(
              record?.createdBy?.image_url || record?.created_by?.image_url,
            ) ? (
              <UserIcon />
            ) : (
              <Avatar
                src={
                  record?.createdBy?.image_url || record?.created_by?.image_url
                }
                size="small"
              />
            )}
            <span className="creator-name-date">
              {`${
                record?.created_by && !isEmpty(record?.created_by)
                  ? getFullUserName(record?.created_by[0])
                  : record?.createdBy && !isEmpty(record?.createdBy)
                  ? getFullUserName(record?.createdBy)
                  : "-"
              }`}
            </span>
          </div>
        </Space>
      ),
    },
    {
      width: 120,
      dataIndex: "workTitle",
      title: AlectifyText.WORKID_AND_TITLE,
      visible: true,
      render: (value: string, pmInternal: IPmInternal) => {
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.getPmInternalData(props.optionsPersist as any);
            },
            showFooter: true,
            destroyOnClose: true,
            readOnly: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmInternal.procedure as any}
                taskId={pmInternal.id}
                isDisabled={
                  pmInternal.status === PM_STATUS.COMPLETED ||
                  pmInternal.status === PM_STATUS.SKIPPED
                }
              />
            ),
          });
        };
        return (
          <Space direction="vertical" size={15}>
            <Space direction="horizontal" size={10}>
              <TextToLink
                className="text-to-link-options"
                text={truncate(value)}
                onClick={() => {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: 420,
                    title: value,
                    name: DRAWER_CONSTANTS.PM_INTERNAL_DETAIL_DRAWER,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.PM_INTERNAL_DETAIL_DRAWER,
                      }),
                    children: (
                      <PmDetail
                        pmInternalRecord={pmInternal}
                        pmId={pmInternal.id}
                        pmType={PM_TYPES.PM_INTERNAL}
                      />
                    ),
                  });
                }}
              />
              {pmInternal.isReopened && (
                <Tooltip title={AlectifyText.REOPENED}>
                  <span>
                    <ReopenedIcon />
                  </span>
                </Tooltip>
              )}
            </Space>
            {(!isEmpty(pmInternal.procedure) && (
              <Tooltip
                title={`${AlectifyText.PROCEDURE_CHECKLIST} ${countCheckedSteps(
                  pmInternal.procedure as any,
                )}`}
              >
                <span
                  className="cursor-pointer"
                  onClick={openProcedureCheckListDrawer}
                >
                  {pmInternal.workId}{" "}
                  {!isEmpty(pmInternal.procedure) &&
                    getTaskStatusIcon(pmInternal.procedure as any)}
                </span>
              </Tooltip>
            )) || <span>{pmInternal.workId}</span>}
          </Space>
        );
      },
    },
    {
      key: "name",
      width: 120,
      dataIndex: "name",
      visible: true,
      searchable: true,
      title: AlectifyText.PARENT_OR_SUB_ASSET,
      render: (name, record: IPmInternal) => {
        const assetName = record.asset?.name;
        const areaName = record.area?.name;
        const subProject: ISubProject = record.subProject;
        const isGeneric = record?.isGeneric;
        return (
          <Space direction="vertical" size={15}>
            <TextToLink
              className="text-to-link-options"
              text={
                assetName
                  ? truncate(assetName)
                  : areaName
                  ? truncate(areaName)
                  : "-"
              }
              underline={!isGeneric}
              onClick={() => {
                if (!isGeneric) {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: record.asset?.name ? 420 : 900,
                    title: record.asset?.name || areaName || "",
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    closable: false,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      }),
                    children: !isEmpty(record.asset) ? (
                      <AssetDetail
                        pmInternalRecord={record}
                        subProjectId={subProject.id}
                        assetId={record.asset?.id}
                        navigate={navigate}
                        isDetailButton
                      />
                    ) : (
                      <PackageDetailAssets
                        pmInternalRecord={record}
                        subProjectId={subProject?.id || ""}
                        assetPackageId={record.area?.id || ""}
                        navigate={navigate}
                        isDetailsbutton
                      />
                    ),
                  });
                }
              }}
            />
            {assetName ? (
              <AssetTag />
            ) : areaName ? (
              <AssetPackageTag />
            ) : (
              <GenericTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.FREQUENCY,
      dataIndex: "isRecurring",
      key: "isRecurring",
      visible: true,
      width: 100,
      render: (isRecurring, record) => (
        <div className="pminternalcolumn-cell-tag">
          <Space direction="vertical" size={15}>
            <span>
              {record?.frequency && isRecurring
                ? record.frequencyType === FrequencyTypeEnum.MONTHLY
                  ? `${record?.frequency} Month(s)`
                  : "-"
                : (record.frequencyType &&
                    capitalizeFirstLetter(record.frequencyType)) ||
                  "-"}
            </span>
            {isRecurring ? <RecurringTag /> : <NonRecurringTag />}
          </Space>
        </div>
      ),
    },
    {
      title: AlectifyText.STATUS,
      dataIndex: "dueDate",
      key: "dueDate",
      visible: true,
      sorter: true,

      width: 80,
      render: (date, record: IPmInternal) => (
        <Space direction="vertical" size={15}>
          {`Due: ${displayDateTime(date)}`}
          {PM_STATUS_COMPONENTS[record.status]}
        </Space>
      ),
    },
    {
      title: AlectifyText.DATE_CLOSED,
      dataIndex: "closedAt",
      key: "closedAt",
      width: 100,
      visible: props.filter === PM_STATUS.COMPLETED,
      render: (value: Date, pmInternal: IPmInternal) => {
        return (
          <Space direction="vertical" size={15}>
            {`On: ${displayDateTime(value)}`}
            {isDelayed(value, pmInternal.dueDate) ? (
              <DelayedTag />
            ) : (
              <OnTimeTag />
            )}
          </Space>
        );
      },
    },
    {
      title: AlectifyText.ACTION_REQUIRED,
      dataIndex: "assignees",
      key: "assignees",
      visible:
        props.filter !== PM_STATUS.COMPLETED &&
        props.filter !== PM_STATUS.SKIPPED,
      width: 80,
      filters: projectTeamMembers?.data.map((user) => ({
        text: getFullUserName(user),
        value: user.id,
      })),
      render: (assignees: IPmUser[]) => {
        if (assignees?.length) {
          return (
            <Space direction="vertical" size={5}>
              <AvatarGroup
                maxCount={2}
                users={assignees.map(({ user }) => user)}
              />
              <Row>
                <AssigneeTag />
              </Row>
            </Space>
          );
        }
        return <></>;
      },
    },
    {
      width: 60,
      dataIndex: "name",
      visible: true,
      title: AlectifyText.MESSAGES,
      render: (_, record) => {
        const subProject = record.subProject || null;
        const openCommentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 420,
            title: AlectifyText.MESSAGING_CENTER,
            name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.DRAWER_COMMENTS,
              });
              props.getPmInternalData(props.optionsPersist as any);
            },
            children: (
              <Comments
                details={record && record}
                referenceId={record.id}
                subProjectId={subProject?.id}
                reference_type={CommentReferenceEnum.PM_INTERNAL}
              />
            ),
            className: "alectify-drawer-task-comments",
          });
        };

        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: record?.workTitle,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              });
              props.getPmInternalData(props.optionsPersist as any);
            },
            children: <PreventiveDocumentsAttachment details={record} />,
          });
        };

        return (
          <Space
            direction="vertical"
            size={18}
            className="alectify-task-table-collaboration-column"
          >
            <Tooltip placement="topLeft" title={AlectifyText.MESSAGING_CENTER}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <MessageOutlined
                    className="icon-font-size-18"
                    onClick={openCommentsDrawer}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openCommentsDrawer}
                  >
                    {record?.comments}
                  </span>
                </Space>
              </Badge>
            </Tooltip>

            <Tooltip placement="topLeft" title={AlectifyText.ATTACHMENTS}>
              <Badge
                offset={[2, 0]}
                overflowCount={9}
                className="cursor-pointer"
                size="small"
              >
                <Space direction="horizontal">
                  <PaperClipOutlined
                    className="icon-font-size-18"
                    onClick={openAttachmentsDrawer}
                  />
                  <span
                    className="alectify-task-table-collaboration-count-text"
                    onClick={openAttachmentsDrawer}
                  >
                    {" "}
                    {record?.documents}
                  </span>
                </Space>
              </Badge>
            </Tooltip>
          </Space>
        );
      },
    },
    {
      width: 90,
      title: AlectifyText.ACTIONS,
      align: "center",
      visible: true,
      dataIndex: "",
      render: (_, pmInternal: IPmInternal) => {
        const openAttachmentsDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 860,
            title: pmInternal?.asset?.name || pmInternal?.area?.name,
            name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
            onClose: () =>
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.ATTACHMENTS_DRAWER,
              }),
            children: <PreventiveDocumentsAttachment details={pmInternal} />,
          });
        };
        const openProcedureCheckListDrawer = () => {
          DrawerServiceInstance.open(AlectifyDrawer, {
            width: 680,
            title: "Procedure",
            name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
            onClose: () => {
              DrawerServiceInstance.close(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
              });
              props.getPmInternalData(props.optionsPersist as any);
            },
            showFooter: true,
            readOnly: true,
            destroyOnClose: true,
            cancelText: AlectifyText.CLOSE,
            children: (
              <ProcedureDetail
                procedure={pmInternal.procedure as any}
                taskId={pmInternal.id}
                isDisabled={
                  pmInternal.status === PM_STATUS.COMPLETED ||
                  pmInternal.status === PM_STATUS.SKIPPED
                }
              />
            ),
          });
        };

        const handleUndoConfirm = async () => {
          try {
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.UNDO_WARNING_MODAL,
            });
            const resp = await undoTaskStatus(pmInternal.id);
            if (resp?.status) {
              message.success(
                `${pmInternal?.workTitle} has changed the state from ${pmInternal?.status} to ${resp?.data?.status}`,
              );
              props.getPmInternalData(props.optionsPersist as any);
            }
          } catch (error) {
            message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
          }
        };
        const openWarningmodal = () => {
          ModalServiceInstance.open(AlectifyModal, {
            name: MODAL_NAMES.UNDO_WARNING_MODAL,
            title: AlectifyText.CONFIRMATION,
            footer: null,
            onCancel: () => {
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.UNDO_WARNING_MODAL,
              });
            },
            children: (
              <ConfirmationModal
                icon={WarningIcon}
                message={`You are about to UNDO the State from ${pmInternal?.status} to ${
                  pmInternal.completedAt
                    ? AlectifyText.WAITING_FOR_REVIEW
                    : AlectifyText.PENDING
                }`}
                note={`Are you sure?`}
                onConfirm={handleUndoConfirm}
                onCancel={() =>
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.UNDO_WARNING_MODAL,
                  })
                }
              />
            ),
          });
        };

        const dropdownItems = actionItems({
          pmInternal,
          currentUser,
          showMyWorkOrders,
          projectId: props.masterProjectId || "",
          subProjectId: props.subProjectId || "",
          myItemsColumns: props.myItemsColumns,
          onEdit: props.onEdit,
          updateStatus: props.updateStatus,
          onUpload: openAttachmentsDrawer,
          navigate,
          openProcedureCheckListDrawer,
          removePMInternal: props.removePMInternal,
        });

        const renderDropdown: any =
          dropdownItems && dropdownItems.length > 0 ? dropdownItems : [];

        return (
          <>
            {[PM_STATUS.PENDING, PM_STATUS.WAITING_FOR_REVIEW].includes(
              pmInternal.status,
            ) ? (
              <Dropdown
                menu={{
                  items: renderDropdown,
                }}
                trigger={["click"]}
              >
                <EllipsisOutlined
                  rotate={90}
                  className="alectify-action-icon"
                />
              </Dropdown>
            ) : (
              <AlectifyButton
                text={AlectifyText.REOPEN}
                type="primary"
                onClick={openWarningmodal}
              />
            )}
          </>
        );
      },
    },
  ];

  return !props.myItemsColumns
    ? columns.filter((cols) => cols.visible)
    : [...myItemsColumns, ...columns.filter((cols) => cols.visible)];
};
export default PmInternalColumns;
