import { memo, useMemo, useState, useCallback } from "react";
import AlectifyTable from "components/shared/table";
import AlectifyText from "static/texts.json";
import PreviewFile from "components/shared/preview-file";
import { displayDateTime } from "utils/helpers";
import { Space, Tooltip } from "antd";
import AlectifyDownloadDocument from "components/shared/download-document";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { IIncidentReportsDocumentsProps } from "./IncidentReport.interface";
import DeleteIcon from "components/icons/DeleteIcon";

const IncidentReportsDocuments: React.FC<IIncidentReportsDocumentsProps> = (
  props,
) => {
  const { details: initialDetails, onDeleteDocuments } = props;

  const [state, setState] = useState({
    details: initialDetails,
    fetching: false,
  });

  const incidentColumns = useMemo<ColumnProps<any>[] | ColumnGroupType<any>[]>(
    () => [
      {
        title: AlectifyText.FILE_NAME,
        dataIndex: "fileName",
        searchable: true,
        width: 100,
        ellipsis: {
          showTitle: false,
        },
        render: (_: any, record: any) => (
          <PreviewFile
            fileName={record?.fileName}
            fileUrl={record?.filePath}
            isActivevalue={record?.isActive}
          />
        ),
      },
      {
        title: AlectifyText.UPLOADED_AT,
        dataIndex: "createdAt",
        key: "createdAt",
        searchable: true,
        width: 150,
        ellipsis: {
          showTitle: false,
        },
        render: (created_at: any) => displayDateTime(created_at, true),
      },
      {
        title: AlectifyText.ACTIONS,
        align: "center",
        dataIndex: "",
        ellipsis: {
          showTitle: false,
        },
        width: 80,
        render: (_: any, record: any) => {
          return record.isActive ? (
            <Space>
              <AlectifyDownloadDocument
                file_name={record.fileName}
                file_path={record.filePath}
              />
              <Tooltip title={AlectifyText.DELETE} className="cursor-pointer">
                <div onClick={() => handleDeleteDocument(record?.id)}>
                  <DeleteIcon />
                </div>
              </Tooltip>
            </Space>
          ) : (
            AlectifyText.DELETED
          );
        },
      },
    ],
    [],
  );

  const handleDeleteDocument = useCallback(
    async (id: string) => {
      try {
        setState((prevState) => ({ ...prevState, fetching: true }));
        if (onDeleteDocuments) {
          await onDeleteDocuments(id);
          setState((prevState) => ({
            details: prevState.details.filter((doc) => doc.id !== id),
            fetching: false,
          }));
        }
      } catch (error) {
        console.error(error);
        setState((prevState) => ({ ...prevState, fetching: false }));
      }
    },
    [onDeleteDocuments],
  );

  return (
    <>
      <AlectifyTable
        loading={state.fetching}
        columns={incidentColumns}
        dataSource={state.details}
        total={state.details.length ?? 0}
        onDataFetch={() => {}}
        scroll={{ y: "calc(100vh - 375px)" }}
      />
    </>
  );
};

export default memo(IncidentReportsDocuments);
