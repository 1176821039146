const InprogressIcon: React.FC = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="in-progress_icon">
        <path
          id="Path 46683"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8 0C6.41775 0 4.87103 0.469192 3.55544 1.34824C2.23985 2.22729 1.21447 3.47672 0.608967 4.93853C0.00346629 6.40034 -0.15496 8.00887 0.153721 9.56072C0.462403 11.1126 1.22433 12.538 2.34315 13.6568C3.46197 14.7757 4.88743 15.5376 6.43928 15.8463C7.99113 16.155 9.59966 15.9965 11.0615 15.391C12.5233 14.7855 13.7727 13.7602 14.6518 12.4446C15.5308 11.129 16 9.58225 16 8C16 6.94942 15.7931 5.90914 15.391 4.93853C14.989 3.96793 14.3997 3.08601 13.6569 2.34315C12.914 1.60028 12.0321 1.011 11.0615 0.608964C10.0909 0.206926 9.05058 0 8 0ZM8.61486 3.07703C8.61486 2.91378 8.55001 2.75721 8.43457 2.64177C8.31913 2.52634 8.16257 2.46149 7.99931 2.46149C7.83606 2.46149 7.6795 2.52634 7.56406 2.64177C7.44862 2.75721 7.38377 2.91378 7.38377 3.07703V8C7.38413 8.16296 7.44903 8.31914 7.56426 8.43437C7.67949 8.54959 7.83567 8.61449 7.99863 8.61485H11.6905C11.8538 8.61485 12.0103 8.55 12.1258 8.43456C12.2412 8.31913 12.3061 8.16256 12.3061 7.99931C12.3061 7.83606 12.2412 7.67949 12.1258 7.56406C12.0103 7.44862 11.8538 7.38377 11.6905 7.38377H8.61623L8.61486 3.07703Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default InprogressIcon;
