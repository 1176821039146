import { Space, Tag } from "antd";
import InternalIcon from "components/icons/InternalIcon";
import AlectifyText from "static/texts.json";

const InternalTag: React.FC<{ count?: number }> = ({ count }) => {
  return (
    <Tag color={"#43BD18"} className="user-type-tag  round-tag">
      <Space size={2}>
        <InternalIcon /> {AlectifyText.INTERNAL}
      </Space>
    </Tag>
  );
};

export default InternalTag;
