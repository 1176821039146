import { MONTHS_2023 } from "constants/dashboard.constants";
import {
  IChartDataItem,
  IDashboardDetails,
  IOpenItemsDetails,
  IPmState,
} from "services/dashboard/dashboard.interface";
import {
  // IPMExternalCounts,
  IPmCounts,
  // IWorkOrderSummary,
  IWorkOrderSummaryResponse,
} from "services/pm-internal/pm-internal.interface";
import { ISparePartDashboardMonthlyCost } from "services/spare-parts/spare-parts.interface";
import AlectifyText from "static/texts.json";
import { renderAmount } from "utils/helpers";

export const pieChartOptions = () => {
  return {
    chart: {
      width: 380,
      type: "pie",
    },
    labels: ["PM", "CM", "Task"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const openWorkOrderOptions = (title: string): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: "donut",
    },
    labels: ["PM", "CM", "Task"],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              offsetY: -10,
              formatter: () => "",
            },
            total: {
              show: true,
              showAlways: true,
              label: "This Week",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              // formatter: () => "70",
            },
          },
        },
      },
    },
    colors: ["#4339F2", "#34B53A", "#FFB200", "#6699ff"], // Example colors
    stroke: {
      show: true,
      width: 1,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: true,
      horizontalAlign: "center",
      position: "top",
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const closedWorkOrderOptions = (
  title: string,
): ApexCharts.ApexOptions => {
  return {
    chart: {
      type: "donut",
    },
    labels: ["PM", "CM", "Task"],
    plotOptions: {
      pie: {
        donut: {
          size: "60%",
          labels: {
            show: true,
            name: {
              show: false,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              offsetY: -10,
              formatter: () => title,
            },
            total: {
              show: true,
              showAlways: true,
              label: "This Week",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              // formatter: () => "70",
            },
          },
        },
      },
    },
    colors: ["#4339F2", "#34B53A", "#FFB200", "#6699ff"], // Example colors
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      show: false,
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const recurringChartOptions = (title: string) => {
  return {
    chart: {
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    labels: ["Recurring", "Non-Recurring"],
    plotOptions: {
      bar: {
        borderRadius: 10,
        borderRadiusApplication: "end",
      },
      /* pie: {
        donut: {
          size: "50%",
          labels: {
            show: true,
            name: {
              show: true,
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              offsetY: -10,
              formatter: () => title,
            },
            total: {
              show: true,
              showAlways: true,
              label: "This Week",
              fontSize: "22px",
              fontFamily: "Helvetica, Arial, sans-serif",
              fontWeight: 600,
              color: "#263238",
              // formatter: () => "70",
            },
          },
        },
      }, */
    },
    colors: ["#4339F2", "#43BD18", "#FFC53F", "#6699ff"], // Example colors
    stroke: {
      show: true,
      width: 2,
      colors: ["#fff"],
    },
    dataLabels: {
      enabled: true,
    },
    legend: {
      position: "top",
      fontSize: "14px",
    },
    xaxis: {
      // categories: ["PM", "CM", "Task"],
      title: {
        // text: "# Open Items",
        style: {
          fontSize: "14px",
          // color: "#333",
          fontWeight: 400,
        },
      },
      style: {
        fontSize: "14px",
      },

      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const openWorkOrdersStatisticsOptions: any = (
  callback?: (type: string) => void,
) => {
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          // const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          // const seriesName = config.w.config.series[seriesIndex].name;
          const category = config.w.config.xaxis.categories[dataPointIndex];

          /*   console.log(
            `Clicked on series: ${seriesName}, category: ${category}`,
          ); */
          if (callback) {
            callback(category);
          }
          // Handle click event here
          // For example, you could redirect the user or show more details
        },
      },
    },

    legend: {
      position: "top",
      fontSize: "14px",
    },
    plotOptions: {
      bar: {
        horizontal: true,
      },
    },

    xaxis: {
      categories: ["Scheduled", "Waiting For Review"],
      title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          // color: "#333",
          fontWeight: 400,
        },
      },
      style: {
        fontSize: "14px",
      },

      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        lines: {
          show: false,
        },
      },
    },
    colors: ["#00337C", "#03C988", "#D5CEA3"],
  };
};

export const openWorkOrdersStatisticsOptionsV2: any = (
  callback?: (type: string, category: string) => void,
) => {
  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: function (
          event: any,
          chartContext: any,
          config: any,
        ) {
          const seriesIndex = config.seriesIndex;
          const dataPointIndex = config.dataPointIndex;
          const category = config.w.config.xaxis.categories[dataPointIndex];
          const seriesName = config.w.config.series[seriesIndex].name;

          const status =
            seriesName === "Scheduled" ? "PENDING" : "WAITING FOR REVIEW";

          if (callback) {
            callback(status, category);
          }
        },
      },
    },

    legend: {
      position: "top",
      fontSize: "14px",
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 10,
      },
    },

    xaxis: {
      categories: ["PM", "CM", "Task"],
      title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          fontWeight: 400,
        },
      },
      style: {
        fontSize: "14px",
      },

      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },

    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        labels: {
          style: {
            fontSize: "14px",
          },
        },
        lines: {
          show: false,
        },
      },
    },
    colors: ["#4339F2", "#FFB200", "#27A0FD"],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: "bottom",
          },
        },
      },
    ],
  };
};

export const overDueWorkOrdersOptions: any = (xLabels: any) => {
  const categories = Array.isArray(xLabels?.openItems)
    ? xLabels.openItems.map(
        (item: any) => `${item?.first_name} ${item?.last_name}`,
      )
    : [];

  return {
    chart: {
      stacked: true,
      toolbar: {
        show: false,
      },
    },
    legend: {
      position: "top",
      fontSize: "14px",
    },
    plotOptions: {
      bar: {
        horizontal: false,
        download: false,
        columnWidth: "50%",
        borderRadius: 5,
      },
    },
    xaxis: {
      categories: categories,
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      title: {
        text: "# Open Items",
        style: {
          fontSize: "14px",
          //   color: "#333",
          fontWeight: 400,
        },
      },
      labels: {
        style: {
          fontSize: "14px",
        },
      },
    },
    grid: {
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: false,
        },
      },
    },
    colors: ["#0D8D12", "#faa307"],
  };
};

export const barOverDueWorkChartOptions: any = {
  chart: {
    type: "bar",
    horizontal: false,
    toolbar: {
      show: false,
    },
  },

  legend: {
    position: "top",
    fontSize: "14px",
  },

  plotOptions: {
    bar: {
      distributed: true,
      horizontal: false,
      columnWidth: "50%",
      borderRadius: 10,
      borderRadiusApplication: "end",
    },
  },
  xaxis: {
    categories: ["On Time", "<7 Days", "7 to 14 Days", "14+ Days"],
    labels: {
      style: {
        fontSize: "14px",
        // color: "#333",
      },
    },
    style: {
      fontSize: "14px",
    },
    title: {
      style: {
        fontSize: "14px",
        // color: "#333",
        fontWeight: 400,
      },
    },
  },

  yaxis: {
    title: {
      text: "Number of Days",
      style: {
        fontSize: "14px",
        fontWeight: 400,
        // color: "#000000",
      },
    },
    tickAmount: 5,
    min: 0,
  },

  colors: ["#34B53A", "#FFC53F", "#FF5C00", "#FF3A29"],
  responsive: [
    {
      breakpoint: 480,
      options: {
        chart: {
          width: 200,
        },
        legend: {
          position: "bottom",
        },
      },
    },
  ],
};

export const annualBarChartOptions: any = {
  chart: {
    type: "bar",
    horizontal: false,
    toolbar: {
      show: false,
    },
  },

  legend: {
    show: false,
  },

  plotOptions: {
    bar: {
      distributed: true,
      horizontal: false,
      columnWidth: "50%",
    },
  },
  xaxis: {
    categories: ["Spare Parts", "Work Orders", "Total"],
    labels: {
      style: {
        fontSize: "14px",
        // color: "#333",
      },
    },
  },
  yaxis: {
    tickAmount: 4,
    title: {
      text: "Cost (in thousands)",
      style: {
        fontSize: "14px",
        fontWeight: 400,
        // color: "#000000",
      },
    },
  },
  colors: ["#0D8D12", "#00337C", "#faa307"],
};

export const annualMaintenanceChartOptions = {
  chart: {
    type: "line",
    toolbar: {
      show: false,
    },
  },

  markers: {
    size: 6,
  },

  stroke: {
    curve: "smooth",
    width: 2,
  },

  legend: {
    position: "top",
    fontSize: "14px",
  },
  xaxis: {
    categories: MONTHS_2023,

    grid: {
      show: true,
    },
  },
  yaxis: {
    tickAmount: 4,
    min: 0,
    max: 1000,
    labels: {
      formatter: function (value: any) {
        return "$" + value.toFixed(2); // Format y-axis labels as currency with two decimal places
      },
    },
    title: {
      text: "Cost (in thousands)",
      style: {
        fontSize: "14px",
        fontWeight: 400,
        //   color: "#000000",
      },
    },
    grid: {
      show: true,
    },
  },
  colors: ["#0D8D12", "#00337C"],
  grid: {
    show: true,
  },
};

export const overDueWorkOrdersSeries = (
  openItems: IOpenItemsDetails[] | undefined,
) => {
  return [
    {
      name: AlectifyText.ON_TIME,
      data:
        openItems?.map(
          (data: IOpenItemsDetails) =>
            (data?.onTimePmCounts || 0) + (data?.onTimeTaskCounts || 0),
        ) || [],
    },
    {
      name: AlectifyText.OVERDUE,
      data:
        openItems?.map(
          (data: IOpenItemsDetails) =>
            (data?.overDuePmCounts || 0) + (data?.overDueTaskCounts || 0),
        ) || [],
    },
  ];
};

export const openWorkOrdersStatisticsSeriesV2 = (
  chart?: IWorkOrderSummaryResponse,
) => {
  return [
    {
      name: AlectifyText.SCHEDULED,
      data: [
        chart?.data?.pm?.pending || 0,
        chart?.data?.cm?.pending || 0,
        chart?.data?.task?.pending || 0,
      ],
    },
    /* {
      name: AlectifyText.IN_PROGRESS,
      data: [
        chart?.data?.pm?.inProgress || 0,
        chart?.data?.cm?.inProgress || 0,
        chart?.data?.task?.inProgress || 0,
      ],
    }, */
    {
      name: AlectifyText.WAITING_FOR_REVIEW,
      data: [
        chart?.data?.pm?.waitingForReview || 0,
        chart?.data?.cm?.waitingForReview || 0,
        chart?.data?.task?.waitingForReview || 0,
      ],
    },
    // {
    //   name: AlectifyText.PM_INTERNAL,
    //   data: [pmCounts?.pmInternal.pending || 0, 0],
    // },
  ];
};

export const openWorkOrdersStatisticsSeries = (
  pmCounts: IPmCounts | undefined | null,
) => {
  return [
    {
      name: AlectifyText.PM_EXTERNAL,
      data: [
        pmCounts?.pmExternal.pending || 0,
        pmCounts?.pmExternal?.waitingForReview || 0,
      ],
    },
    {
      name: AlectifyText.TASK,
      data: [pmCounts?.task.pending || 0, pmCounts?.task.waitingForReview || 0],
    },
    // {
    //   name: AlectifyText.PM_INTERNAL,
    //   data: [pmCounts?.pmInternal.pending || 0, 0],
    // },
  ];
};

export const openWorkOrdersStatisticsSeriesExternal = (pmState: IPmState) => {
  return [
    {
      name: AlectifyText.PM_EXTERNAL,
      data: [
        pmState.pmCounts?.pmExternal?.pending || 0,
        pmState.pmCounts?.pmExternal?.waitingForReview || 0,
      ],
    },
    {
      name: AlectifyText.TASK,
      data: [
        pmState.pmCounts?.task?.pending || 0,
        pmState.pmCounts?.task?.waitingForReview || 0,
      ],
    },
  ];
};

export const overDueWorkChartSeries = (data: IDashboardDetails | undefined) => {
  return [
    {
      name: "Orders",
      data: [
        data?.onTimeCounts || 0,
        data?.sevenDaysDueCounts || 0,
        data?.sevenToFourteenDaysDueCounts || 0,
        data?.plusFourteenDaysDueCounts || 0,
      ],
    },
  ];
};

export const annualBarChartData: IChartDataItem[] = [
  {
    name: AlectifyText.COST,
    data: [121.3, 452.2, 573.5],
  },
];

export const annualSeriesData = [
  {
    name: AlectifyText.SPARE_PARTS,
    data: [0, 1000, 80, 900, 80, 900, 80, 800, 100, 720, 100, 300],
  },
  {
    name: AlectifyText.WORK_ORDERS,
    data: [200, 280, 650, 210, 550, 300, 400, 800, 250, 100, 200, 300],
  },
];

export const getSparePartsUtilizationChartOptions = (
  sparePartsDashboardMonthlyCost: ISparePartDashboardMonthlyCost[],
) => {
  const options = {
    chart: {
      id: "realtime",
      height: 350,
      type: "line",
      animations: {
        enabled: false,
        easing: "linear",
        // dynamicAnimation: {
        //   speed: 1000,
        // },
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [4, 4],
      curve: "smooth",
    },
    markers: {
      size: [5, 5], // Set the size of the markers (dots)
      colors: ["#0500FF", "#FF3A29"], // Set the colors for each series
      strokeWidth: 2, // Set the stroke width around the markers
      shape: "circle", // Can be 'circle', 'square', etc.
    },
    // dataLabels: {
    //   enabled: true,
    //   // formatter: (val: any) => `$${val}`,
    // },
    xaxis: {
      categories:
        sparePartsDashboardMonthlyCost &&
        sparePartsDashboardMonthlyCost.map((d) => d.month),
    },
    yaxis: {
      title: {
        text: "CAD",
      },
      labels: {
        formatter: (val: any) => `${renderAmount(val)}`,
      },
    },
    legend: {
      position: "top",
    },
    colors: ["#0500FF", "#FF3A29"],
  };
  return options;
};

export const getSparePartsUtilizationChartSeries = (
  sparePartsDashboardMonthlyCost: ISparePartDashboardMonthlyCost[],
) => {
  const series = [
    {
      name: "Spare Parts Added (Received)",
      type: "line",
      data: sparePartsDashboardMonthlyCost.map((i: any) => i.restockTotal),
    },
    {
      name: "Spare Parts Withdrawn (Draw)",
      type: "line",
      data: sparePartsDashboardMonthlyCost.map((i: any) => i.drawTotal),
    },
  ];
  return series;
};
