import { Space, Tag } from "antd";
import AdminIcon from "components/icons/AdminIcon";
import AlectifyText from "static/texts.json";
const AdminTag: React.FC = () => {
  return (
    <Tag color={"#27A0FD"} className="user-type-tag round-tag">
      <Space size={2}>
        <AdminIcon /> {AlectifyText.ADMIN}
      </Space>
    </Tag>
  );
};

export default AdminTag;
