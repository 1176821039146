import { IReduxActionResponse } from "redux/interfaces";
import { chatbotInitialState } from "../Chatbot.initialState";

export const setChatbotAction = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    toggle: payload.toggle,
    fetching: payload.fetching,
    chatbotItems: payload.chatbotItems,
  };
};

export const resetChatbotState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return chatbotInitialState;
};

export const resetAiProcedureState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...state,
    aiProcedureItems: {
      ...chatbotInitialState.aiProcedureItems,
    },
  };
};

export const setAiProcedureAction = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  const { payload } = action;
  return {
    ...state,
    aiProcedureItems: {
      data: payload.aiProcedureItems.data,
      toggle: payload.aiProcedureItems.toggle,
      fetching: payload.aiProcedureItems.fetching,
    },
  };
};

export const setBase64Url = (state: any, action: IReduxActionResponse<any>) => {
  const { payload } = action;
  return {
    ...state,
    base64Url: payload,
  };
};
