import { memo, ReactElement } from "react";
import { Typography } from "antd";
import "./AlectifyDetailCardText.scss";

interface IAlectifyDetailCardTextProps {
  label: string;
  value: string | number | undefined | ReactElement;
  heading?: string;
  className?: string;
  linkValue?: boolean;
  onClick?: () => void;
}

const AlectifyDetailCardText: React.FC<IAlectifyDetailCardTextProps> = (
  props: IAlectifyDetailCardTextProps,
) => {
  return (
    <>
      {props.heading ? (
        <Typography.Title
          level={5}
          className="alectify-bordered-row-text-title"
        >
          {props.heading}
        </Typography.Title>
      ) : (
        <></>
      )}
      <div className={`alectify-bordered-row-text ${props.className}`}>
        <Typography.Title level={5}>{props.label}:</Typography.Title>
        <Typography.Paragraph
          onClick={props?.onClick}
          underline={props.linkValue}
          className={`value ${
            props.linkValue ? "cursor-pointer text-to-link-options" : ""
          }`}
        >
          {props.value || "-"}
        </Typography.Paragraph>
      </div>
    </>
  );
};

AlectifyDetailCardText.defaultProps = {
  label: "",
  value: "",
  className: "",
};

export default memo(AlectifyDetailCardText);
