import { Space, Tag } from "antd";
import LowInventoryIcon from "components/icons/LowInventoryIcon";
import AlectifyText from "static/texts.json";

const LowInventory: React.FC = () => {
  return (
    <Tag color={"#FC912F"} className="round-tag">
      <Space size={3}>
        <LowInventoryIcon /> {AlectifyText.LOW_INVENTORY}
      </Space>
    </Tag>
  );
};

export default LowInventory;
