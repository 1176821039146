import { AxiosResponse } from "axios";
import { IParams } from "redux/interfaces";
import { pmHttp } from "utils/client";
import { GET_WORK_ORDERS } from "./work-order.endponts";
import { IWorkOrdersResponse } from "./work-order.interface";

/**
 * Fetches work orders for a given master project, sub project ID, pm type.
 *
 * @param masterProjectId - The ID of the master project to fetch pm internal
 * @param subProjectId - The ID of the sub project to fetch pm internal for.
 * @param pmType - The PM type.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project work orders data.
 * @throws If an error occurs during the request.
 */
export const fetchWorkOrders = async (
  masterProjectId: string,
  subProjectId: string,
  pmType: string,
  params?: IParams, // Make params optional
): Promise<IWorkOrdersResponse> => {
  try {
    const url = GET_WORK_ORDERS.replace("{masterProjectId}", masterProjectId)
      .replace("{subProjectId}", subProjectId)
      .replace("{pmType}", pmType);
    const response: AxiosResponse<IWorkOrdersResponse> = await pmHttp.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
