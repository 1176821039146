import { memo } from "react";

const MoreHorizontalIcon: React.FC<{
  className?: string;
  onClick?: () => void;
  fill?: string | null;
}> = (props) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="28"
      height="28"
      fill="none"
    >
      <path
        color="grey"
        d="M21 12C21 11.1716 20.3284 10.5 19.5 10.5C18.6716 10.5 18 11.1716 18 12C18 12.8284 18.6716 13.5 19.5 13.5C20.3284 13.5 21 12.8284 21 12Z"
        stroke={props.fill || "#91908c"}
        fill={props.fill || "#91908c"}
        strokeWidth="1.5"
      />
      <path
        d="M13.5 12C13.5 11.1716 12.8284 10.5 12 10.5C11.1716 10.5 10.5 11.1716 10.5 12C10.5 12.8284 11.1716 13.5 12 13.5C12.8284 13.5 13.5 12.8284 13.5 12Z"
        stroke={props.fill || "#91908c"}
        strokeWidth="1.5"
        fill={props.fill || "#91908c"}
      />
      <path
        d="M6 12C6 11.1716 5.32843 10.5 4.5 10.5C3.67157 10.5 3 11.1716 3 12C3 12.8284 3.67157 13.5 4.5 13.5C5.32843 13.5 6 12.8284 6 12Z"
        stroke={props.fill || "#91908c"}
        strokeWidth="1.5"
        fill={props.fill || "#91908c"}
      />
    </svg>
  );
};

export default memo(MoreHorizontalIcon);
