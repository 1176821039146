import React, { useEffect, useState } from "react";
import { Calendar, Spin } from "antd";
import {
  IMonthlyCalendarProps,
  IStartDateObj,
} from "./MonthlyCalendar.interface";
import dayjs, { Dayjs } from "dayjs";
import { useLocation, useNavigate } from "react-router-dom";
import "./style.scss";

const MiniMonthlyCalendar: React.FC<IMonthlyCalendarProps> = (
  props: IMonthlyCalendarProps,
) => {
  const navigate = useNavigate();
  const location = useLocation();

  const startDateQ = dayjs(new URLSearchParams(location.search).get("start"));
  const endDateQ = dayjs(new URLSearchParams(location.search).get("end"));
  const calendarType = new URLSearchParams(location.search).get("calendarType");
  const [calendarValue, setCalendarValue] = useState<Dayjs>(dayjs());

  useEffect(() => {
    const { month, year } = props.miniMonthlyCalendarMonthAndYear.current;
    setCalendarValue(dayjs().year(year).month(month));
  }, [props.miniMonthlyCalendarMonthAndYear.current]);

  const disabledDate = (date: Dayjs) => {
    return date.year() < 2019 || date.year() > 2028;
  };

  const handleSelect = (date: Dayjs) => {
    if (calendarType === "month") {
      if (!date.isSame(calendarValue, "month")) {
        setCalendarValue(date);
        props.miniMonthlyCalendarMonthAndYear.current = {
          month: date.month(),
          year: date.year(),
        };
      }

      const firstVisibleDay = date.clone().startOf("month").startOf("week");
      const lastVisibleDay = date.clone().endOf("month").endOf("week");

      const params = new URLSearchParams(location.search);
      params.set("start", firstVisibleDay.format("YYYY-MM-DD"));
      params.set("end", lastVisibleDay.format("YYYY-MM-DD"));

      navigate(`?${params.toString()}`, { replace: true });

      return;
    }

    if (!date.isSame(calendarValue, "month")) {
      setCalendarValue(date);
      props.miniMonthlyCalendarMonthAndYear.current = {
        month: date.month(),
        year: date.year(),
      };
    }

    if (!date.isSame(props.miniMonthlyCalendarDate, "day")) {
      props.setMiniMonthlyCalendarDate(dayjs(date.toDate()));

      props?.setCalendarState &&
        props.setCalendarState((prevState: any) => ({
          ...prevState,
          isSelectActive: true,
        }));

      const selectedMonth = date.month();
      const selectedYear = date.year();

      props.miniMonthlyCalendarMonthAndYear.current = {
        month: selectedMonth,
        year: selectedYear,
      };

      const startOfWeek = date.clone().startOf("week").format("YYYY-MM-DD");
      const endOfWeek = date.clone().endOf("week").format("YYYY-MM-DD");

      const searchParams = new URLSearchParams(location.search);
      searchParams.set("start", startOfWeek);
      searchParams.set("end", endOfWeek);
      navigate({ search: searchParams.toString() });
    }
  };
  const customDateCellRender = (date: dayjs.Dayjs, info: any) => {
    const startDates = props.calendarEvents?.data || [];
    const startDate = dayjs(startDateQ).local();
    const endDate = dayjs(endDateQ).local();
    const monthStart = dayjs(calendarValue).startOf("month").local();
    const calendarStart = monthStart.startOf("week");
    const monthEnd = dayjs(calendarValue).endOf("month").local();
    const calendarEnd = monthEnd.endOf("week");

    const isWithinCalendarRange =
      (date.isSame(calendarStart, "day") ||
        date.isAfter(calendarStart, "day")) &&
      (date.isSame(calendarEnd, "day") || date.isBefore(calendarEnd, "day"));

    if (!isWithinCalendarRange) {
      return null;
    }

    const isInCurrentMonth = date.isSame(calendarValue, "month");
    const isBetweenDates =
      date.isSame(startDate, "day") ||
      (date.isAfter(startDate, "day") && date.isBefore(endDate, "day")) ||
      date.isSame(endDate, "day");

    const shouldHighlightMonthDates =
      calendarType === "month" && isInCurrentMonth;

    // Check if there's an event for the current date
    const hasRecordExistEvent = startDates.some(
      (startDateObj: IStartDateObj) => {
        const eventDate = dayjs(startDateObj.start).local().startOf("day"); // Convert event start date to local time
        const calendarDate = date.startOf("day"); // Compare the rendered date in local time

        return (
          eventDate.isSame(calendarDate, "day") && startDateObj.isRecordExist
        );
      },
    );

    return (
      <div
        className={`${
          shouldHighlightMonthDates || isBetweenDates ? "highlight-day" : ""
        } ${
          dayjs().isSame(date, "day")
            ? "active-date-highlight"
            : "hide-active-date-highlight"
        }`}
      >
        {info.originNode}
        {hasRecordExistEvent && <div className="calendar-dot" />}
      </div>
    );
  };

  const shortMonths = Array.from({ length: 12 }, (_, index) =>
    dayjs().month(index).format("MMM"),
  );

  return (
    <Spin spinning={props.calendarEvents.fetching}>
      <Calendar
        value={calendarValue}
        fullCellRender={customDateCellRender}
        onSelect={handleSelect as any}
        disabledDate={disabledDate}
        fullscreen={false}
        className="alectify-weekly-selectable-calendar"
        headerRender={({ value, type, onChange }) => {
          const { year, month } = props.miniMonthlyCalendarMonthAndYear.current;
          return (
            <div className="alectify-weekly-calendar-item">
              <div>
                <select
                  className="my-month-select"
                  value={String(year)}
                  onChange={(e) => {
                    const selectedYear = parseInt(e.target.value, 10);
                    if (!isNaN(selectedYear)) {
                      const newDate = dayjs()
                        .year(selectedYear)
                        .month(
                          props.miniMonthlyCalendarMonthAndYear.current.month,
                        );

                      onChange(newDate);
                      setCalendarValue(newDate);
                      props.miniMonthlyCalendarMonthAndYear.current.year =
                        selectedYear;
                    }
                  }}
                >
                  {Array.from(
                    { length: 10 },
                    (_, i) => i + new Date().getFullYear() - 5,
                  )
                    .filter((y) => !isNaN(y))
                    .map((y) => (
                      <option key={y} value={y}>
                        {y}
                      </option>
                    ))}
                </select>
              </div>
              <select
                className="my-month-select"
                value={String(month + 1)}
                onChange={(e) => {
                  const selectedMonth = parseInt(e.target.value, 10) - 1;
                  if (!isNaN(selectedMonth)) {
                    const newDate = dayjs()
                      .year(props.miniMonthlyCalendarMonthAndYear.current.year)
                      .month(selectedMonth);

                    onChange(newDate);
                    setCalendarValue(newDate);
                    props.miniMonthlyCalendarMonthAndYear.current.month =
                      selectedMonth;
                  }
                }}
              >
                {shortMonths.map((shortMonth, index) => (
                  <option
                    className="month-item"
                    key={`${shortMonth}`}
                    value={index + 1}
                  >
                    {shortMonth}
                  </option>
                ))}
              </select>
            </div>
          );
        }}
      />
    </Spin>
  );
};

export default MiniMonthlyCalendar;
