import { AxiosResponse } from "axios";
import { MULTIPART_HEADERS, http } from "utils/client";
import { CONTACTUS_URL } from "./contactUs.endpoints";

/**
 * Send contact support
 *
 * @returns A Promise that create the new contact.
 * @throws If an error occurs during the request.
 */
export const contactSupport = async (body: FormData): Promise<any> => {
  try {
    const response: AxiosResponse<any> = await http.post(
      CONTACTUS_URL,
      body,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};
