import React from "react";
import { Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { AllTimelineText } from "pages/all-timeline/AllTimeline.constant";
import { getFullUserName } from "utils/helpers";

const AlltimelineColumn = () => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AllTimelineText.REFERENCETYPE,
      dataIndex: "referenceType",
      key: "referenceType",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (referenceType) => (
        <Tooltip placement="topLeft" title={referenceType}>
          {referenceType}
        </Tooltip>
      ),
    },
    {
      title: AllTimelineText.WORK_TITLE,
      dataIndex: "workTitle",
      key: "workTitle",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (_, user) => (
        <Tooltip placement="topLeft" title={user.pm.workTitle}>
          {user.pm.workTitle}
        </Tooltip>
      ),
    },
    {
      title: AllTimelineText.MESSAGE,
      dataIndex: "text",
      key: "text",
      width: 180,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (text) => (
        <Tooltip placement="topLeft" title={text}>
          {text}
        </Tooltip>
      ),
    },
    {
      title: AllTimelineText.SENT_BY,
      dataIndex: "first_name",
      key: "first_name",
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (_, user) => (
        <Tooltip
          placement="topLeft"
          title={user && getFullUserName(user?.sentBy)}
        >
          {user && getFullUserName(user?.sentBy)}
        </Tooltip>
      ),
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default AlltimelineColumn;
