import { IAlectifyChipProps } from "./Chips.interface";
import "./AlectifyChip.scss";

const AlectifyChip: React.FC<IAlectifyChipProps> = (
  props: IAlectifyChipProps,
) => {
  if (!props.isRound) {
    return (
      <div
        className="alectify-chip"
        style={{
          background: props.backgroundColor,
          borderColor: props.borderColor,
        }}
      >
        <span
          style={{
            color: props.textColor,
          }}
        >
          {props.text}
        </span>
      </div>
    );
  } else {
    return (
      <div
        className="alectify-round-chip"
        style={{
          background: props.backgroundColor,
          borderColor: props.borderColor,
          borderRadius: props.borderRadius || 30,
          boxShadow: props.boxShadow
            ? " 0px 2px 7px 0px rgba(0, 0, 0, 0.27)"
            : "none",
          fontSize: `${props.fontSize} !important` || 12,
        }}
      >
        <span
          style={{
            color: props.textColor,
          }}
        >
          {props.text}
        </span>
      </div>
    );
  }
};

AlectifyChip.defaultProps = {
  isRound: false,
  boxShadow: false,
};
export default AlectifyChip;
