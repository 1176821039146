export const PROJECT_THEME_COLOR = "#141B34";
export const PROJECT_THEME_COLOR_SECONDARY = "#0954f1";
export const DISABLED_ICON_COLOR = "#B9BBC7";
export const LIGHT_BLUE_COLOR = "#DAE5F3";
export const DONE_TASK_GREEN_COLOR = "#43BD18";
export const PROJECT_WHITE_COLOR = "#fff";

export const CHIP_COLORS = {
  ASSET: "#DFF2FB",
  ASSET_PACKAGE: "#0D76A3;",
  TASK: "#D85F02",
  PM_INTERNAL: "#7570B3",
  PM_EXTERNAL: "#FF5656",
  TEXT: "#fff",
  GENERIC: "#0954f1",
  SPARE_PART: "#FBE7FF",
};

export const CONTACT_NUMBER_VALIDATION_MESSAGE =
  "Contact number must contain only numbers";
export const CONTACT_NUMBER_LENGTH_MESSAGE =
  "Contact number must be exactly 10 digits";
export const PLEASE_ENTER_CONTACT_NUMBER_MESSAGE =
  "Please enter contact number";
export const PLEASE_SELECT_COUNTRY_CODE_MESSAGE =
  "Please select a country code";

export const COUNTRY_CODES = [
  {
    label: "Canada",
    code: "+1",
    iso: "CA",
  },
  {
    label: "Afghanistan",
    code: "+93",
    iso: "AF",
  },
  {
    label: "Albania",
    code: "+355",
    iso: "AL",
  },
  {
    label: "Algeria",
    code: "+213",
    iso: "DZ",
  },
  {
    label: "Andorra",
    code: "+376",
    iso: "AD",
  },
  {
    label: "Angola",
    code: "+244",
    iso: "AO",
  },
  {
    label: "Antarctica",
    code: "+672",
    iso: "AQ",
  },
  {
    label: "Argentina",
    code: "+54",
    iso: "AR",
  },
  {
    label: "Armenia",
    code: "+374",
    iso: "AM",
  },
  {
    label: "Aruba",
    code: "+297",
    iso: "AW",
  },
  {
    label: "Australia",
    code: "+61",
    iso: "AU",
  },
  {
    label: "Austria",
    code: "+43",
    iso: "AT",
  },
  {
    label: "Azerbaijan",
    code: "+994",
    iso: "AZ",
  },
  {
    label: "Bahrain",
    code: "+973",
    iso: "BH",
  },
  {
    label: "Bangladesh",
    code: "+880",
    iso: "BD",
  },
  {
    label: "Belarus",
    code: "+375",
    iso: "BY",
  },
  {
    label: "Belgium",
    code: "+32",
    iso: "BE",
  },
  {
    label: "Belize",
    code: "+501",
    iso: "BZ",
  },
  {
    label: "Benin",
    code: "+229",
    iso: "BJ",
  },
  {
    label: "Bhutan",
    code: "+975",
    iso: "BT",
  },
  {
    label: "Bolivia",
    code: "+591",
    iso: "BO",
  },
  {
    label: "Bosnia and Herzegovina",
    code: "+387",
    iso: "BA",
  },
  {
    label: "Botswana",
    code: "+267",
    iso: "BW",
  },
  {
    label: "Brazil",
    code: "+55",
    iso: "BR",
  },
  {
    label: "British Indian Ocean Territory",
    code: "+246",
    iso: "IO",
  },
  {
    label: "Brunei",
    code: "+673",
    iso: "BN",
  },
  {
    label: "Bulgaria",
    code: "+359",
    iso: "BG",
  },
  {
    label: "Burkina Faso",
    code: "+226",
    iso: "BF",
  },
  {
    label: "Burundi",
    code: "+257",
    iso: "BI",
  },
  {
    label: "Cambodia",
    code: "+855",
    iso: "KH",
  },
  {
    label: "Cameroon",
    code: "+237",
    iso: "CM",
  },
  {
    label: "Cape Verde",
    code: "+238",
    iso: "CV",
  },
  {
    label: "Central African Republic",
    code: "+236",
    iso: "CF",
  },
  {
    label: "Chad",
    code: "+235",
    iso: "TD",
  },
  {
    label: "Chile",
    code: "+56",
    iso: "CL",
  },
  {
    label: "China",
    code: "+86",
    iso: "CN",
  },
  {
    label: "Christmas Island",
    code: "+61",
    iso: "CX",
  },
  {
    label: "Cocos Islands",
    code: "+61",
    iso: "CC",
  },
  {
    label: "Colombia",
    code: "+57",
    iso: "CO",
  },
  {
    label: "Comoros",
    code: "+269",
    iso: "KM",
  },
  {
    label: "Cook Islands",
    code: "+682",
    iso: "CK",
  },
  {
    label: "Costa Rica",
    code: "+506",
    iso: "CR",
  },
  {
    label: "Croatia",
    code: "+385",
    iso: "HR",
  },
  {
    label: "Cuba",
    code: "+53",
    iso: "CU",
  },
  {
    label: "Curacao",
    code: "+599",
    iso: "CW",
  },
  {
    label: "Cyprus",
    code: "+357",
    iso: "CY",
  },
  {
    label: "Czech Republic",
    code: "+420",
    iso: "CZ",
  },
  {
    label: "Democratic Republic of the Congo",
    code: "+243",
    iso: "CD",
  },
  {
    label: "Denmark",
    code: "+45",
    iso: "DK",
  },
  {
    label: "Djibouti",
    code: "+253",
    iso: "DJ",
  },
  {
    label: "East Timor",
    code: "+670",
    iso: "TL",
  },
  {
    label: "Ecuador",
    code: "+593",
    iso: "EC",
  },
  {
    label: "Egypt",
    code: "+20",
    iso: "EG",
  },
  {
    label: "El Salvador",
    code: "+503",
    iso: "SV",
  },
  {
    label: "Equatorial Guinea",
    code: "+240",
    iso: "GQ",
  },
  {
    label: "Eritrea",
    code: "+291",
    iso: "ER",
  },
  {
    label: "Estonia",
    code: "+372",
    iso: "EE",
  },
  {
    label: "Ethiopia",
    code: "+251",
    iso: "ET",
  },
  {
    label: "Falkland Islands",
    code: "+500",
    iso: "FK",
  },
  {
    label: "Faroe Islands",
    code: "+298",
    iso: "FO",
  },
  {
    label: "Fiji",
    code: "+679",
    iso: "FJ",
  },
  {
    label: "Finland",
    code: "+358",
    iso: "FI",
  },
  {
    label: "France",
    code: "+33",
    iso: "FR",
  },
  {
    label: "French Polynesia",
    code: "+689",
    iso: "PF",
  },
  {
    label: "Gabon",
    code: "+241",
    iso: "GA",
  },
  {
    label: "Gambia",
    code: "+220",
    iso: "GM",
  },
  {
    label: "Georgia",
    code: "+995",
    iso: "GE",
  },
  {
    label: "Germany",
    code: "+49",
    iso: "DE",
  },
  {
    label: "Ghana",
    code: "+233",
    iso: "GH",
  },
  {
    label: "Gibraltar",
    code: "+350",
    iso: "GI",
  },
  {
    label: "Greece",
    code: "+30",
    iso: "GR",
  },
  {
    label: "Greenland",
    code: "+299",
    iso: "GL",
  },
  {
    label: "Guatemala",
    code: "+502",
    iso: "GT",
  },
  {
    label: "Guyana",
    code: "+592",
    iso: "GY",
  },
  {
    label: "Haiti",
    code: "+509",
    iso: "HT",
  },
  {
    label: "Honduras",
    code: "+504",
    iso: "HN",
  },
  {
    label: "Hong Kong",
    code: "+852",
    iso: "HK",
  },
  {
    label: "Hungary",
    code: "+36",
    iso: "HU",
  },
  {
    label: "Iceland",
    code: "+354",
    iso: "IS",
  },
  {
    label: "India",
    code: "+91",
    iso: "IN",
  },
  {
    label: "Indonesia",
    code: "+62",
    iso: "ID",
  },
  {
    label: "Iran",
    code: "+98",
    iso: "IR",
  },
  {
    label: "Iraq",
    code: "+964",
    iso: "IQ",
  },
  {
    label: "Ireland",
    code: "+353",
    iso: "IE",
  },
  {
    label: "Israel",
    code: "+972",
    iso: "IL",
  },
  {
    label: "Italy",
    code: "+39",
    iso: "IT",
  },
  {
    label: "Ivory Coast",
    code: "+225",
    iso: "CI",
  },
  {
    label: "Japan",
    code: "+81",
    iso: "JP",
  },
  {
    label: "Jordan",
    code: "+962",
    iso: "JO",
  },
  {
    label: "Kazakhstan",
    code: "+7",
    iso: "KZ",
  },
  {
    label: "Kenya",
    code: "+254",
    iso: "KE",
  },
  {
    label: "Kiribati",
    code: "+686",
    iso: "KI",
  },
  {
    label: "Kosovo",
    code: "+383",
    iso: "XK",
  },
  {
    label: "Kuwait",
    code: "+965",
    iso: "KW",
  },
  {
    label: "Kyrgyzstan",
    code: "+996",
    iso: "KG",
  },
  {
    label: "Laos",
    code: "+856",
    iso: "LA",
  },
  {
    label: "Latvia",
    code: "+371",
    iso: "LV",
  },
  {
    label: "Lebanon",
    code: "+961",
    iso: "LB",
  },
  {
    label: "Lesotho",
    code: "+266",
    iso: "LS",
  },
  {
    label: "Liberia",
    code: "+231",
    iso: "LR",
  },
  {
    label: "Libya",
    code: "+218",
    iso: "LY",
  },
  {
    label: "Liechtenstein",
    code: "+423",
    iso: "LI",
  },
  {
    label: "Lithuania",
    code: "+370",
    iso: "LT",
  },
  {
    label: "Luxembourg",
    code: "+352",
    iso: "LU",
  },
  {
    label: "Macao",
    code: "+853",
    iso: "MO",
  },
  {
    label: "Macedonia",
    code: "+389",
    iso: "MK",
  },
  {
    label: "Madagascar",
    code: "+261",
    iso: "MG",
  },
  {
    label: "Malawi",
    code: "+265",
    iso: "MW",
  },
  {
    label: "Malaysia",
    code: "+60",
    iso: "MY",
  },
  {
    label: "Maldives",
    code: "+960",
    iso: "MV",
  },
  {
    label: "Mali",
    code: "+223",
    iso: "ML",
  },
  {
    label: "Malta",
    code: "+356",
    iso: "MT",
  },
  {
    label: "Marshall Islands",
    code: "+692",
    iso: "MH",
  },
  {
    label: "Mauritania",
    code: "+222",
    iso: "MR",
  },
  {
    label: "Mauritius",
    code: "+230",
    iso: "MU",
  },
  {
    label: "Mayotte",
    code: "+262",
    iso: "YT",
  },
  {
    label: "Mexico",
    code: "+52",
    iso: "MX",
  },
  {
    label: "Micronesia",
    code: "+691",
    iso: "FM",
  },
  {
    label: "Moldova",
    code: "+373",
    iso: "MD",
  },
  {
    label: "Monaco",
    code: "+377",
    iso: "MC",
  },
  {
    label: "Mongolia",
    code: "+976",
    iso: "MN",
  },
  {
    label: "Montenegro",
    code: "+382",
    iso: "ME",
  },
  {
    label: "Morocco",
    code: "+212",
    iso: "MA",
  },
  {
    label: "Mozambique",
    code: "+258",
    iso: "MZ",
  },
  {
    label: "Myanmar",
    code: "+95",
    iso: "MM",
  },
  {
    label: "Namibia",
    code: "+264",
    iso: "NA",
  },
  {
    label: "Nauru",
    code: "+674",
    iso: "NR",
  },
  {
    label: "Nepal",
    code: "+977",
    iso: "NP",
  },
  {
    label: "Netherlands",
    code: "+31",
    iso: "NL",
  },
  {
    label: "Netherlands Antilles",
    code: "+599",
    iso: "AN",
  },
  {
    label: "New Caledonia",
    code: "+687",
    iso: "NC",
  },
  {
    label: "New Zealand",
    code: "+64",
    iso: "NZ",
  },
  {
    label: "Nicaragua",
    code: "+505",
    iso: "NI",
  },
  {
    label: "Niger",
    code: "+227",
    iso: "NE",
  },
  {
    label: "Nigeria",
    code: "+234",
    iso: "NG",
  },
  {
    label: "Niue",
    code: "+683",
    iso: "NU",
  },
  {
    label: "North Korea",
    code: "+850",
    iso: "KP",
  },
  {
    label: "Norway",
    code: "+47",
    iso: "NO",
  },
  {
    label: "Oman",
    code: "+968",
    iso: "OM",
  },
  {
    label: "Pakistan",
    code: "+92",
    iso: "PK",
  },
  {
    label: "Palau",
    code: "+680",
    iso: "PW",
  },
  {
    label: "Palestine",
    code: "+970",
    iso: "PS",
  },
  {
    label: "Panama",
    code: "+507",
    iso: "PA",
  },
  {
    label: "Papua New Guinea",
    code: "+675",
    iso: "PG",
  },
  {
    label: "Paraguay",
    code: "+595",
    iso: "PY",
  },
  {
    label: "Peru",
    code: "+51",
    iso: "PE",
  },
  {
    label: "Philippines",
    code: "+63",
    iso: "PH",
  },
  {
    label: "Pitcairn",
    code: "+64",
    iso: "PN",
  },
  {
    label: "Poland",
    code: "+48",
    iso: "PL",
  },
  {
    label: "Portugal",
    code: "+351",
    iso: "PT",
  },
  {
    label: "Qatar",
    code: "+974",
    iso: "QA",
  },
  {
    label: "Republic of the Congo",
    code: "+242",
    iso: "CG",
  },
  {
    label: "Reunion",
    code: "+262",
    iso: "RE",
  },
  {
    label: "Romania",
    code: "+40",
    iso: "RO",
  },
  {
    label: "Russia",
    code: "+7",
    iso: "RU",
  },
  {
    label: "Rwanda",
    code: "+250",
    iso: "RW",
  },
  {
    label: "Saint Barthelemy",
    code: "+590",
    iso: "BL",
  },
  {
    label: "Saint Helena",
    code: "+290",
    iso: "SH",
  },
  {
    label: "Saint Martin",
    code: "+590",
    iso: "MF",
  },
  {
    label: "Saint Pierre and Miquelon",
    code: "+508",
    iso: "PM",
  },
  {
    label: "Samoa",
    code: "+685",
    iso: "WS",
  },
  {
    label: "San Marino",
    code: "+378",
    iso: "SM",
  },
  {
    label: "Sao Tome and Principe",
    code: "+239",
    iso: "ST",
  },
  {
    label: "Saudi Arabia",
    code: "+966",
    iso: "SA",
  },
  {
    label: "Senegal",
    code: "+221",
    iso: "SN",
  },
  {
    label: "Serbia",
    code: "+381",
    iso: "RS",
  },
  {
    label: "Seychelles",
    code: "+248",
    iso: "SC",
  },
  {
    label: "Sierra Leone",
    code: "+232",
    iso: "SL",
  },
  {
    label: "Singapore",
    code: "+65",
    iso: "SG",
  },
  {
    label: "Slovakia",
    code: "+421",
    iso: "SK",
  },
  {
    label: "Slovenia",
    code: "+386",
    iso: "SI",
  },
  {
    label: "Solomon Islands",
    code: "+677",
    iso: "SB",
  },
  {
    label: "Somalia",
    code: "+252",
    iso: "SO",
  },
  {
    label: "South Africa",
    code: "+27",
    iso: "ZA",
  },
  {
    label: "South Korea",
    code: "+82",
    iso: "KR",
  },
  {
    label: "South Sudan",
    code: "+211",
    iso: "SS",
  },
  {
    label: "Spain",
    code: "+34",
    iso: "ES",
  },
  {
    label: "Sri Lanka",
    code: "+94",
    iso: "LK",
  },
  {
    label: "Sudan",
    code: "+249",
    iso: "SD",
  },
  {
    label: "Suriname",
    code: "+597",
    iso: "SR",
  },
  {
    label: "Svalbard and Jan Mayen",
    code: "+47",
    iso: "SJ",
  },
  {
    label: "Swaziland",
    code: "+268",
    iso: "SZ",
  },
  {
    label: "Sweden",
    code: "+46",
    iso: "SE",
  },
  {
    label: "Switzerland",
    code: "+41",
    iso: "CH",
  },
  {
    label: "Syria",
    code: "+963",
    iso: "SY",
  },
  {
    label: "Taiwan",
    code: "+886",
    iso: "TW",
  },
  {
    label: "Tajikistan",
    code: "+992",
    iso: "TJ",
  },
  {
    label: "Tanzania",
    code: "+255",
    iso: "TZ",
  },
  {
    label: "Thailand",
    code: "+66",
    iso: "TH",
  },
  {
    label: "Togo",
    code: "+228",
    iso: "TG",
  },
  {
    label: "Tokelau",
    code: "+690",
    iso: "TK",
  },
  {
    label: "Tonga",
    code: "+676",
    iso: "TO",
  },
  {
    label: "Tunisia",
    code: "+216",
    iso: "TN",
  },
  {
    label: "Turkey",
    code: "+90",
    iso: "TR",
  },
  {
    label: "Turkmenistan",
    code: "+993",
    iso: "TM",
  },
  {
    label: "Tuvalu",
    code: "+688",
    iso: "TV",
  },
  {
    label: "Uganda",
    code: "+256",
    iso: "UG",
  },
  {
    label: "Ukraine",
    code: "+380",
    iso: "UA",
  },
  {
    label: "United Arab Emirates",
    code: "+971",
    iso: "AE",
  },
  {
    label: "United Kingdom",
    code: "+44",
    iso: "GB",
  },

  {
    label: "Uruguay",
    code: "+598",
    iso: "UY",
  },
  {
    label: "Uzbekistan",
    code: "+998",
    iso: "UZ",
  },
  {
    label: "Vanuatu",
    code: "+678",
    iso: "VU",
  },
  {
    label: "Vatican",
    code: "+379",
    iso: "VA",
  },
  {
    label: "Venezuela",
    code: "+58",
    iso: "VE",
  },
  {
    label: "Vietnam",
    code: "+84",
    iso: "VN",
  },
  {
    label: "Wallis and Futuna",
    code: "+681",
    iso: "WF",
  },
  {
    label: "Western Sahara",
    code: "+212",
    iso: "EH",
  },
  {
    label: "Yemen",
    code: "+967",
    iso: "YE",
  },
  {
    label: "Zambia",
    code: "+260",
    iso: "ZM",
  },
  {
    label: "Zimbabwe",
    code: "+263",
    iso: "ZW",
  },
];
