import { Col, Row, Typography } from "antd";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";

import AlectifyButton from "components/shared/button";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { getMasterProject } from "redux/components/master-project/sources";
import { IRootState } from "redux/rootReducer";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { setActiveMasterProject } from "redux/components/common/sources";
import { IMasterProject } from "redux/components/master-project";
import WelcomeMessage from "components/shared/welcome-greeting-message";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import MasterProjectCreationForm from "./MasterProjectCreationForm";
import { useForm } from "antd/es/form/Form";
import AlectifyMap from "components/shared/map";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import AlectifyText from "static/texts.json";
import "./index.scss";
import { isEmpty } from "lodash";

const { Title, Paragraph } = Typography;

const MasterProject: React.FC<{ dataRound?: boolean }> = (props: {
  dataRound?: boolean;
}) => {
  const masterProjects = useSelector(
    (state: IRootState) => state.MasterProject,
  );
  const activeMasterProject = useSelector(
    (state: IRootState) => state.common.activeMasterProject,
  );
  const auth = useSelector((state: IRootState) => state.auth);
  const [FormInstance] = useForm();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    dispatch(getMasterProject({ version: 2 }));
    if (!isEmpty(activeMasterProject)) {
      if (location.pathname.includes(ROUTES.DATA_ROUND)) {
        navigate(`${ROUTES.DATA_ROUND}/${activeMasterProject.id}`);
      } else {
        navigate(`${ROUTES.PROJECT}/${activeMasterProject.id}`);
      }
    }
  }, [activeMasterProject]);

  const onProjectClickHandler = (project: IMasterProject) => {
    dispatch(setActiveMasterProject(project));
    navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${project.id}`);
  };

  return (
    <div className="alectify-master-project-container">
      <Row
        justify="space-between"
        className="alectify-master-project-header-container"
        align="top"
      >
        <Col>
          <Title level={5} className="mt-0">
            <WelcomeMessage />
            {auth.user && ` ${auth.user.first_name} ${auth.user.last_name}`}
          </Title>
          <Paragraph>
            You have total{" "}
            <span className="text-blue-bold">
              {masterProjects?.data.length || "0"}
            </span>{" "}
            {AlectifyText.SITES}
          </Paragraph>
        </Col>
        {isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) && (
          <Col>
            <AlectifyButton
              text={`${AlectifyText.CREATE_SITE}`}
              type="primary"
              shape="default"
              icon={<PlusOutlined />}
              onClick={() =>
                DrawerServiceInstance.open(AlectifyDrawer, {
                  title: AlectifyText.NEW_SITE,
                  name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                  showFooter: true,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () => {
                    FormInstance.resetFields();
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                    });
                  },

                  onSubmit: FormInstance.submit,
                  width: 600,
                  children: (
                    <MasterProjectCreationForm
                      onProjectClickHandler={onProjectClickHandler}
                      FormInstance={FormInstance}
                    />
                  ),
                })
              }
            />
          </Col>
        )}
      </Row>
      <AlectifyMap
        projects={masterProjects?.data}
        onClick={onProjectClickHandler}
      />
    </div>
  );
};

export default MasterProject;
