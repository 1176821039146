import { ISparePartsInitialState } from "./spare-parts.interface";

const sparePartInitialState: ISparePartsInitialState = {
  fetching: false,
  data: [],
  message: "",
  success: false,
  meta: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 100,
  },
  recentlyAddedSpareParts: [],
  vendors: [],
  categories: [],
};

export default sparePartInitialState;
