import { Col, Row, Tabs, TabsProps } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import AlectifyText from "static/texts.json";
import DocumentsIcon from "components/icons/DocumentsIcon";
import AssetTimelineDocuments from "./documents";
import TasksIcon from "components/icons/TasksIcon";
import AssetTimeline from "./timeline/AssetTimeline";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { ITimelineResponse } from "redux/components/common";
import WorkOrderDocuments from "./documents/WorkOrderDocuments";
import { useEffect, useState, memo } from "react";
import AttachmentIcon from "components/icons/AttachmentIcon";
import { fetchWorkOrderDocumentsCount } from "services/documents/documents.service";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import "./timeline/AssetTimeline.scss";
import { fetchEquipmentTimeline } from "services/assets/assets.service";

interface ITimelineTabsProps {
  timelinePage?: number;
  setTimelinePage?: any;
  timelineDataFromAssetTimeline: (value: ITimelineResponse) => void;
  equipmentId: string;
  timelineCount: number;
}

const TimelineTabs: React.FC<ITimelineTabsProps> = ({
  timelinePage,
  setTimelinePage,
  timelineDataFromAssetTimeline,
  equipmentId,
  timelineCount,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>(
    new URLSearchParams(location.search).get("page") || "timeline",
  );
  const [dateRange, setDateRange] = useState<[string, string] | null>(null);
  const [initialLoad, setInitialLoad] = useState(true);

  const [timeline, setTimeline] = useState<ITimelineResponse>({
    data: [],
    fetching: false,
    meta: undefined,
  });

  const getEquipmentTimeline = async () => {
    try {
      const params = {
        page: timelinePage,
        limit: 10,
        startDate: dateRange ? dateRange[0] : undefined,
        endDate: dateRange ? dateRange[1] : undefined,
      };
      setTimeline({ ...timeline, fetching: true });
      const response = await fetchEquipmentTimeline(equipmentId || "", params);
      setTimeline((prevTimeline) => {
        return {
          data: [...prevTimeline.data, ...response.data],
          fetching: false,
          meta: response?.meta,
        };
      });
      setInitialLoad(false);
      timelineDataFromAssetTimeline(response);
    } catch (error) {
      console.log(error);
      setTimeline({ ...timeline, fetching: false });
    }
  };

  useEffect(() => {
    getEquipmentTimeline();
  }, [equipmentId, dateRange, timelinePage]);

  useEffect(() => {
    navigate({ search: `page=${activeTab}` });
  }, [activeTab, navigate]);

  const [state, setState] = useState({
    documentsCount: 0,
    workOrderCounts: 0,
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        await Promise.all([fetchDocumentsCounts(), fetchWordOrderCounts()]);
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, []);

  const fetchDocumentsCounts = async (options?: IPagination | null) => {
    try {
      const activeStatus = options?.isActive ?? "true";

      if (options?.isActive === "all") options.isActive = undefined;
      const params = {
        types: ["ASSET", "ASSET_PACKAGE"],
        isActive: activeStatus,
        ...options,
      };

      const documentsResponse = await fetchWorkOrderDocumentsCount(
        equipmentId,
        params,
      );

      const documentsCount = documentsResponse?.data?.count || 0;

      setState((prevState) => ({
        ...prevState,
        documentsCount: documentsCount,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const fetchWordOrderCounts = async (options?: IPagination | null) => {
    try {
      const activeStatus = options?.isActive ?? "true";

      if (options?.isActive === "all") options.isActive = undefined;

      const paramsWorkOrder = {
        types: ["PM_EXTERNAL", "TASK"],

        isActive: activeStatus,
        ...options,
      };

      const workOrderResponse = await fetchWorkOrderDocumentsCount(
        equipmentId,
        paramsWorkOrder,
      );

      setState((prevState) => ({
        ...prevState,
        workOrderCounts: workOrderResponse?.data?.count || 0,
      }));
    } catch (err) {
      console.error(err);
    }
  };

  const handleTabChange = (key: string) => {
    setActiveTab(key);
  };

  const items: TabsProps["items"] | any = [
    {
      key: "timeline",
      label: (
        <>
          <TasksIcon /> {AlectifyText.TIMELINE} ({timelineCount})
        </>
      ),
      children: activeTab === "timeline" && (
        <Row gutter={30} justify={"center"}>
          <Col span={18}>
            <AssetTimeline
              timeline={timeline}
              initialLoad={initialLoad}
              setDateRange={setDateRange}
              setTimeline={setTimeline}
              setTimelinePage={setTimelinePage}
              timelinePage={timelinePage}
              dateRange={dateRange}
            />
          </Col>
        </Row>
      ),
      visible: isFeatureEnabled(FEATURE_FLAGS.TIMELINE),
    },
    {
      key: "documents",
      label: (
        <>
          <DocumentsIcon /> {AlectifyText.DOCUMENTS} ({state.documentsCount})
        </>
      ),
      children: activeTab === "documents" && (
        <Row>
          <Col span={24}>
            <AssetTimelineDocuments
              activeTab={activeTab}
              fetchAllCounts={fetchDocumentsCounts}
            />
          </Col>
        </Row>
      ),
      visible: true,
    },
    {
      key: "workOrderAttachments",
      label: (
        <>
          <AttachmentIcon /> {AlectifyText.WORK_ORDER_ATTACHMENTS} (
          {state.workOrderCounts})
        </>
      ),
      children: activeTab === "workOrderAttachments" && (
        <Row>
          <Col span={24}>
            <WorkOrderDocuments
              activeTab={activeTab}
              fetchAllCounts={fetchWordOrderCounts}
            />
          </Col>
        </Row>
      ),
      visible: true,
    },
  ];

  return (
    <div className="timeline-tabs-container">
      <Tabs
        activeKey={activeTab}
        onChange={handleTabChange}
        items={items.filter((tab: any) => tab.visible)}
      />
    </div>
  );
};

export default memo(TimelineTabs);
