import React from "react";

const OpenEnvelopIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 -5 64 64"
      className="size-6"
    >
      <rect x="8" y="18" width="48" height="32" fill="#0954f1" />

      {/* Envelope Body Sides and Bottom */}
      <line x1="8" y1="18" x2="8" y2="50" stroke="#FFFFFF" strokeWidth="3" />
      <line x1="56" y1="18" x2="56" y2="50" stroke="#FFFFFF" strokeWidth="3" />
      <line x1="8" y1="50" x2="56" y2="50" stroke="#FFFFFF" strokeWidth="3" />

      {/* Envelope Flap */}
      <path
        d="M8 18 L32 4 L56 18"
        fill="#0954f1"
        stroke="#FFFFFF"
        strokeWidth="3"
      />

      {/* Envelope Inner Diagonal Lines */}
      <path
        d="M8 18 L32 36 M32 36 L56 18"
        fill="none"
        stroke="#FFFFFF"
        strokeWidth="3"
      />
    </svg>
  );
};

export default OpenEnvelopIcon;
