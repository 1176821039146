import { memo, useEffect, useRef, useState } from "react";
import AlectifyTable from "../table";
import { IGroupCheckpointsTableProps } from "./GroupCheckpointsTable.interface";
import useGroupCheckpointsColumn from "./effects/useGroupCheckpointsColumn";
import AlectifyButton from "../button";
import AlectifyText from "static/texts.json";
import {
  CloudDownloadOutlined,
  CloudUploadOutlined,
  DownOutlined,
  DownloadOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import {
  bulkUploadCheckpointsAndGroups,
  deleteCheckpoint,
  deleteGroup,
  getGroupAndCheckpointListing,
  resetCheckpointAndGroupOrder,
} from "services/data-round/data-round.service";
import { IGroupCheckpoint } from "services/data-round/data-round.interface";
import { Dropdown, Space, Upload, message } from "antd";
import { MESSAGES } from "constants/messages";
import { MenuProps } from "antd/lib";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AddCheckpointManualForm from "../manage-groups/AddCheckpointManualForm";
import { downloadCSV } from "utils/helpers";
import { dataRoundCheckpointsGroupCSV } from "services/data-round/data-round.constants";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { IMeta } from "redux/interfaces";

const GroupCheckpointsTable: React.FC<IGroupCheckpointsTableProps> = (
  props: IGroupCheckpointsTableProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [data, setData] = useState<{
    data: IGroupCheckpoint[];
    meta: IMeta;
  } | null>(null);
  const fileInputRef = useRef<any>(null);

  const onDelete = async (record: IGroupCheckpoint) => {
    setLoader(true);
    try {
      let response: any;
      if (record.type === "Area") {
        response = await deleteGroup(record.dr_project_id, record.area_id);
      } else {
        response = await deleteCheckpoint(
          record.dr_project_id,
          record.checkpoint_id,
        );
      }
      setLoader(false);
      if (response.success) {
        message.success(
          record.type === "Area"
            ? MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES.DELETE_GROUP
            : MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES.DELETE_CHECKPOINT,
        );
        fetchGroupsAndCheckpoints();
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  const resetOrder = async () => {
    try {
      setLoader(true);
      const response = await resetCheckpointAndGroupOrder(props.projectId);
      setLoader(false);
      if (response.success) {
        fetchGroupsAndCheckpoints();
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  const fetchGroupsAndCheckpoints = async (params?: IPagination) => {
    try {
      setLoader(true);
      const response = await getGroupAndCheckpointListing(
        props.projectId,
        params,
      );
      setLoader(false);
      if (response.success) {
        setData((prev) => ({
          data: response.data,
          meta: response.meta,
        }));
      }
    } catch (ex) {
      setLoader(false);
    }
  };

  const rowClassName = (record: any) =>
    record.type === "Area" ? "sea-green-background" : "white-background";

  useEffect(() => {
    if (props.isEditing) {
      const params = {
        page: PAGINATION.DEFAULT_START_PAGE,
        per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      };
      fetchGroupsAndCheckpoints(params);
    }
  }, [props.projectId]);

  const items: MenuProps["items"] = [
    {
      label: AlectifyText.UPLOAD_CHECKPOINT_CSV,
      key: "1",
      icon: <CloudUploadOutlined />,
    },
    {
      label: AlectifyText.DOWNLOAD_SAMPLE_CSV,
      key: "2",
      icon: <DownloadOutlined />,
    },
  ].filter(Boolean) as MenuProps["items"];

  const handleCsvDownload = () => {
    downloadCSV(dataRoundCheckpointsGroupCSV);
  };
  const handleCsvFileUpload = async (file: any) => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("checkpoint_csv", file);
      await bulkUploadCheckpointsAndGroups(props.projectId, formData);
      setLoader(false);
      message.success(
        MESSAGES.DATA_ROUND.GROUP_CHECKPOINT_MESSAGES.CHECKPOINT_LIST_UPLOADED,
      );
    } catch (error) {
      setLoader(false);
      console.log(error);
    } finally {
      setLoader(false);
      const params = {
        page: PAGINATION.DEFAULT_START_PAGE,
        per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      };
      fetchGroupsAndCheckpoints(params);
    }
  };

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    switch (e.key) {
      case "1":
        if (fileInputRef.current) {
          fileInputRef.current.click();
        }
        break;
      case "2":
        handleCsvDownload();
        break;
      default:
        message.info("Click on menu item.");
    }
  };
  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onClose = () => {
    props.form.resetFields();
    fetchGroupsAndCheckpoints();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.CREATE_NEW_GROUP_CHECKPOINT,
    });
  };
  const columns = useGroupCheckpointsColumn({
    ...props,
    onDelete,
    onResetOrder: resetOrder,
    onClose,
  });
  return (
    <>
      <div className="upload-btn-hide">
        <Upload
          accept=".csv"
          fileList={[]}
          maxCount={1}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={(info) => {
            const { file } = info;
            handleCsvFileUpload(file as any);
          }}
        >
          <div ref={fileInputRef}></div>
        </Upload>
      </div>

      <AlectifyTable
        size="small"
        columns={columns}
        loading={loader}
        dataSource={data?.data || []}
        showSearch
        total={data?.meta?.total_count || 0}
        customSearchKey="search"
        noIcontainsSearch
        scroll={{ y: "calc(100vh - 375px)" }}
        displayRowClass={rowClassName}
        extraButton={
          props.showExtraButton ? (
            <Space>
              {props.showAddButton && (
                <AlectifyButton
                  text="Add New"
                  type="primary"
                  icon={<PlusOutlined className="font-size-15" />}
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 500,
                      title: AlectifyText.ADD_CHECKPOINT_GROUP,
                      name: DRAWER_CONSTANTS.CREATE_NEW_GROUP_CHECKPOINT,
                      showFooter: true,
                      closeIcon: null,
                      closable: false,
                      children: (
                        <AddCheckpointManualForm
                          form={props.form}
                          isEditing={false}
                          projectId={props.projectId}
                          onClose={onClose}
                        />
                      ),
                      onSubmit: props.form.submit,
                      onClose,
                    });
                  }}
                />
              )}
              <AlectifyButton
                onClick={resetOrder}
                text={AlectifyText.RESET_ORDER}
                type="text"
                className="light-blue-button-hover"
                icon={<SyncOutlined className="icon-font-size-18" />}
              />

              <Dropdown menu={menuProps}>
                <AlectifyButton
                  className="transparent-dropdown-btn"
                  text={AlectifyText.BULK_UPLOADS}
                  icon={<DownOutlined className="icon-font-size-18" />}
                />
              </Dropdown>
              {props.showDownloadButton && (
                <AlectifyButton
                  text={AlectifyText.DOWNLOAD_CSV}
                  type="primary"
                  icon={<CloudDownloadOutlined className="icon-font-size-18" />}
                  onClick={() => {
                    //   getDownloadAssetsFile(subProjectId);
                  }}
                />
              )}
            </Space>
          ) : (
            <></>
          )
        }
        onDataFetch={fetchGroupsAndCheckpoints}
      />
    </>
  );
};

export default memo(GroupCheckpointsTable);
