import { Space, Tag } from "antd";
import { CircleCheckIcon } from "components/icons";

const OnTimeTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#3CAC15"} className="round-tag">
      <Space size={3}>
        <CircleCheckIcon /> On Time
      </Space>
    </Tag>
  );
};

export default OnTimeTag;
