import { memo } from "react";

const RightCircleIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="16"
      viewBox="0 0 15 16"
      fill="none"
    >
      <circle cx="7.5" cy="8" r="7.5" fill="#8C66F5" />
      <path
        d="M4.28572 7.5C4.00958 7.5 3.78572 7.72386 3.78572 8C3.78572 8.27614 4.00958 8.5 4.28572 8.5V7.5ZM12.1393 8.35355C12.3345 8.15829 12.3345 7.84171 12.1393 7.64645L8.95729 4.46447C8.76203 4.2692 8.44545 4.2692 8.25019 4.46447C8.05493 4.65973 8.05493 4.97631 8.25019 5.17157L11.0786 8L8.25019 10.8284C8.05493 11.0237 8.05493 11.3403 8.25019 11.5355C8.44545 11.7308 8.76203 11.7308 8.95729 11.5355L12.1393 8.35355ZM4.28572 8.5H11.7857V7.5H4.28572V8.5Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(RightCircleIcon);
