import { CloseOutlined, PauseCircleOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
import { WaitingIcon } from "components/icons";
import SuccessTickIcon from "components/icons/SuccessTickIcon";
import { ReactNode } from "react";
import { RoundStatusEnum } from "services/data-round/data-round.interface";

const DataRoundStatusTag: React.FC<{ status: RoundStatusEnum }> = ({
  status,
}) => {
  const statusTagMap: {
    [key in RoundStatusEnum]: { label: string; color: string; icon: ReactNode };
  } = {
    [RoundStatusEnum.NOT_STARTED]: {
      label: "Not Started",
      color: "#ff1010",
      icon: <CloseOutlined />,
    },
    [RoundStatusEnum.ON_GOING]: {
      label: "In Progress",
      color: "#25a0fd",
      icon: <WaitingIcon />,
    },
    [RoundStatusEnum.ENDED]: {
      label: "Completed",
      color: "#42be19",
      icon: <SuccessTickIcon />,
    },
    [RoundStatusEnum.PAUSED]: {
      label: "On Hold",
      color: "#ffb310",
      icon: <PauseCircleOutlined />,
    },
  };

  return (
    <Tag color={statusTagMap[status].color} className="round-tag m-0">
      <Space size={5}>
        <span>{statusTagMap[status].icon}</span>
        <span>{statusTagMap[status].label}</span>
      </Space>
    </Tag>
  );
};

export default DataRoundStatusTag;
