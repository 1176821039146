//libs
import { Col, Row, Space, Upload } from "antd";
import { CloudUploadOutlined } from "@ant-design/icons";
import { isEmpty } from "lodash";

//internal
import AlectifyTable from "components/shared/table";
import { IAlectifyUploadProps } from "./AlectifyUpload.interface";
import AlectifyButton from "../button";
import useFileListColumns from "./effects/useFileListColumns";
import { useDispatch, useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import {
  deleteUploadedDocument,
  setUploadDocuments,
} from "redux/components/common/sources";
import { IRootState } from "redux/rootReducer";
import AttachmentIcon from "components/icons/AttachmentIcon";
import "./style.scss";

const AlectifyUpload: React.FC<IAlectifyUploadProps> = (
  props: IAlectifyUploadProps,
) => {
  const dispatch = useDispatch();
  const { uploadDocuments } = useSelector((state: IRootState) => state.common);

  const columns = useFileListColumns({
    onFileDelete: (uid: string) => {
      dispatch(deleteUploadedDocument(uid));
    },
    onDirectoryChange: props.onDirectoryChange,
    documentType: props?.documentType,
  });

  const uploadDocument = (item: any) => {
    dispatch(setUploadDocuments(item));
  };

  return (
    <Row justify={"center"} align={"middle"}>
      <Col span={24}>
        <Upload
          {...props}
          beforeUpload={() => false}
          onChange={(ev) => {
            uploadDocument(ev.fileList);
          }}
          fileList={uploadDocuments || []}
          showUploadList={false}
          className="width-100"
        >
          {!props.attachmentText ? (
            <AlectifyButton
              text="Upload"
              type="primary"
              icon={<CloudUploadOutlined />}
              className="dark-blue-button width-100"
            />
          ) : (
            <div className="attachment-upload-container">
              <div className="mt-5 cursor-pointer">
                <AttachmentIcon />
              </div>
              <span>{AlectifyText.ATTACH_FILE}</span>
            </div>
          )}
        </Upload>
      </Col>
      <Col span={24}>
        {!isEmpty(uploadDocuments) && (
          <AlectifyTable
            columns={columns}
            dataSource={uploadDocuments || []}
            onDataFetch={() => {}}
            total={0}
            showPagination={false}
          />
        )}
      </Col>
      {props?.uploadDocument ? (
        <div className="documents-upload-bottom">
          <div className="doc-upload-bottom ">
            <div>
              <AlectifyButton
                text="Cancel"
                type="default"
                className="light-blue-button"
                onClick={props.onCloseModal}
              />
            </div>

            <div>
              <AlectifyButton
                text="submit"
                type="primary"
                className="dark-blue-button"
                onClick={() => {
                  props.uploadDocument && props.uploadDocument(uploadDocuments);
                }}
              />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </Row>
  );
};

export default AlectifyUpload;
