interface IAdminIconProps {
  fill?: string | null;
}
const AdminIcon: React.FC<IAdminIconProps> = (props: IAdminIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="13"
      viewBox="0 0 13 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.82143 2.40744e-08C8.21208 -6.53821e-05 7.61008 0.133143 7.05764 0.390288C6.5052 0.647432 6.01569 1.02229 5.62341 1.48859C5.23114 1.95488 4.94559 2.50133 4.78679 3.08963C4.62799 3.67793 4.59979 4.29384 4.70414 4.89419C4.74562 5.13562 4.68433 5.33805 4.56733 5.45505L0.543524 9.47824C0.195445 9.82654 -5.8996e-05 10.2988 1.33543e-08 10.7912V12.5357C1.33543e-08 12.792 0.208 13 0.464286 13H2.78571C2.90885 13 3.02694 12.9511 3.11401 12.864C3.20108 12.7769 3.25 12.6589 3.25 12.5357V11.6071H4.17857C4.30171 11.6071 4.4198 11.5582 4.50687 11.4712C4.59394 11.3841 4.64286 11.266 4.64286 11.1429V10.2143H5.57143C5.69452 10.2142 5.81254 10.1652 5.89952 10.0781L7.54495 8.43267C7.66257 8.31567 7.865 8.25438 8.10581 8.29648C8.6759 8.39469 9.26016 8.37346 9.82162 8.23414C10.3831 8.09481 10.9095 7.84043 11.3675 7.48708C11.8256 7.13374 12.2052 6.68915 12.4825 6.18145C12.7598 5.67375 12.9286 5.114 12.9783 4.53766C13.0281 3.96132 12.9576 3.38093 12.7713 2.83324C12.5851 2.28556 12.2871 1.78252 11.8964 1.35596C11.5056 0.92941 11.0305 0.58864 10.5012 0.35523C9.97192 0.121821 9.39991 0.000857737 8.82143 2.40744e-08ZM8.82143 1.85714C8.69829 1.85714 8.5802 1.90606 8.49313 1.99313C8.40606 2.0802 8.35714 2.19829 8.35714 2.32143C8.35714 2.44456 8.40606 2.56266 8.49313 2.64973C8.5802 2.7368 8.69829 2.78571 8.82143 2.78571C9.19084 2.78571 9.54512 2.93246 9.80633 3.19367C10.0675 3.45488 10.2143 3.80916 10.2143 4.17857C10.2143 4.30171 10.2632 4.4198 10.3503 4.50687C10.4373 4.59394 10.5554 4.64286 10.6786 4.64286C10.8017 4.64286 10.9198 4.59394 11.0069 4.50687C11.0939 4.4198 11.1429 4.30171 11.1429 4.17857C11.1429 3.56289 10.8983 2.97243 10.4629 2.53707C10.0276 2.10172 9.43711 1.85714 8.82143 1.85714Z"
        fill={props.fill || "white"}
      />
    </svg>
  );
};
export default AdminIcon;
