export const GET_PROJECT_ASSET_PACKAGES_URL =
  "/projects/{subProjectId}/v2/packages/";
export const GET_PACKAGES_DETAIL_ASSETS_URL =
  "/projects/{subProjectId}/packages/{assetPackageId}/tags/";
export const GET_ASSET_PACKAGE_BY_SEARCH =
  "/projects/{subProjectId}/packages/search/";
export const GET_ALL_PACKAGES =
  "/projects/{subProjectId}/packages/?no-team=1&is_individual=true";
export const CREATE_PACKAGE = "/projects/{subProjectId}/packages/";
export const UPDATE_PACKAGE = "/projects/{subProjectId}/packages/{packageId}/";
export const DELETE_PACKAGE = "/projects/{subProjectId}/packages/{packageId}/";
export const RESET_ORDER = "/projects/{subProjectId}/packages/reset-order/";
export const GET_ASSET_PACKAGE_DETAIL =
  "/projects/{subProjectId}/v2/packages/{assetPackageId}/";
export const UPLOAD_ASSET_PACKAGE_IMAGE =
  "/projects/{projectid}/packages/{tagid}/upload-profile-image/";
