import { INotificationsInitialState } from "./notifications.interface";

const notificationsInitialState: INotificationsInitialState = {
  fetching: false,
  data: [],
  message: "",
  meta: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 0,
    itemCount: 0,
    itemsPerPage: 0,
  },
  unreadCounts: 0,
  unReadSystemGenerated: 0,
  isDropdownOpen: false,
  isSystemDropdownOpen: false,
};

export default notificationsInitialState;
