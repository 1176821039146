import React from "react";
import LoaderService from "../LoaderService";
import { ILoaderParams } from "../LoaderService.interface";
import { Spin } from "antd";

export interface ILoaderRootState {
  loader: ILoaderParams | null;
  defaultLoader?: React.FunctionComponent<any>;
}

export interface ILoaderRootProps {
  loaderComponent?: React.FunctionComponent<any>;
}

class LoaderRoot extends React.Component<ILoaderRootProps, ILoaderRootState> {
  state: ILoaderRootState = {
    loader: null,
    defaultLoader: this.props.loaderComponent,
  };

  componentDidMount() {
    LoaderService.on("showLoader", (params: ILoaderParams) => {
      this.setState({
        loader: params,
      });
    });

    LoaderService.on("hideLoader", (params: ILoaderParams) => {
      this.setState({
        loader: null,
      });
    });
  }

  render() {
    const { loader, defaultLoader } = this.state;
    const LoaderComponent = loader ? loader.component || defaultLoader : null;
    const child = LoaderComponent
      ? React.createElement(Spin, { size: "large" })
      : null;
    return LoaderComponent
      ? React.createElement("section", { className: "loader-wrapper" }, child)
      : null;
  }
}

export default LoaderRoot;
