import { Dispatch } from "react";
import { actions } from "..";
import { IParams, SourceActionType } from "redux/interfaces";
import {
  fetchNotifications,
  fetchUnreadNotificationsCount,
  // markNotificationsAsRead,
} from "services/notifications/notifications.service";

export const getNotifications: SourceActionType = (
  params: IParams,
  append = false,
) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setNotificationsLoader(true));
    try {
      const response = await fetchNotifications(params);
      if (append) {
        dispatch(actions.appendNotifications(response));
      } else {
        dispatch(actions.setNotifications(response));
      }
      dispatch(actions.setNotificationsLoader(false));
    } catch (ex: any) {
      dispatch(actions.setNotificationsLoader(false));
    }
  };
};

export const getUnreadNotificationsCount: SourceActionType = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response = await fetchUnreadNotificationsCount();
      dispatch(actions.setUnreadNotificationsCount(response));
    } catch (ex: any) {}
  };
};

export const confirmNotificationsRead: SourceActionType = (ids: string[]) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.markNotificationsAsRead(ids));
      // await markNotificationsAsRead({ ids });
      dispatch(getUnreadNotificationsCount());
    } catch (ex: any) {}
  };
};
