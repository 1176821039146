import { Space, Tag, Tooltip } from "antd";
import AssetsIcon from "components/icons/AssetsIcon";
import AlectifyText from "static/texts.json";
import { CHIP_COLORS } from "../phone-number/constants";

interface AssetTagProps {
  count?: number;
  showSubAssetName?: boolean;
}

const AssetTag: React.FC<AssetTagProps> = ({ count, showSubAssetName }) => {
  return (
    <Tag color={CHIP_COLORS.ASSET} className="asset-tag round-tag">
      <Space size={2}>
        <Tooltip title={AlectifyText.SUB_ASSET_NAME}>
          <AssetsIcon />
        </Tooltip>
        {showSubAssetName &&
          `${AlectifyText.SUB_ASSET_NAME}${count ? `: ${count}` : ""}`}
      </Space>
    </Tag>
  );
};

AssetTag.defaultProps = {
  showSubAssetName: true,
};

export default AssetTag;
