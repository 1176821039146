interface IAlectifyMaximizeIconProps {
  fill?: string | null;
}
const AlectifyMaximizeIcon: React.FC<IAlectifyMaximizeIconProps> = (
  props: IAlectifyMaximizeIconProps,
) => {
  return (
    <svg
      {...props}
      width="14"
      height="14"
      viewBox="0 0 16 16"
      fill={props.fill || "none"}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="Icon"
        d="M10.5 1.4696H15M15 1.4696V5.9696M15 1.4696L9.75 6.7196M6 14.9696H1.5M1.5 14.9696V10.4696M1.5 14.9696L6.75 9.7196"
        stroke="#0954F1"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default AlectifyMaximizeIcon;
