import { memo } from "react";

const PictureIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="21"
      height="18"
      viewBox="0 0 21 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 3C0 2.40326 0.237053 1.83097 0.65901 1.40901C1.08097 0.987053 1.65326 0.75 2.25 0.75H18.75C19.3467 0.75 19.919 0.987053 20.341 1.40901C20.7629 1.83097 21 2.40326 21 3V15C21 15.5967 20.7629 16.169 20.341 16.591C19.919 17.0129 19.3467 17.25 18.75 17.25H2.25C1.65326 17.25 1.08097 17.0129 0.65901 16.591C0.237053 16.169 0 15.5967 0 15V3ZM1.5 13.06V15C1.5 15.414 1.836 15.75 2.25 15.75H18.75C18.9489 15.75 19.1397 15.671 19.2803 15.5303C19.421 15.3897 19.5 15.1989 19.5 15V13.06L16.81 10.371C16.5287 10.0901 16.1475 9.93232 15.75 9.93232C15.3525 9.93232 14.9713 10.0901 14.69 10.371L13.81 11.25L14.78 12.22C14.8537 12.2887 14.9128 12.3715 14.9538 12.4635C14.9948 12.5555 15.0168 12.6548 15.0186 12.7555C15.0204 12.8562 15.0018 12.9562 14.9641 13.0496C14.9264 13.143 14.8703 13.2278 14.799 13.299C14.7278 13.3703 14.643 13.4264 14.5496 13.4641C14.4562 13.5018 14.3562 13.5204 14.2555 13.5186C14.1548 13.5168 14.0555 13.4948 13.9635 13.4538C13.8715 13.4128 13.7887 13.3537 13.72 13.28L8.56 8.121C8.27875 7.8401 7.8975 7.68232 7.5 7.68232C7.1025 7.68232 6.72125 7.8401 6.44 8.121L1.5 13.061V13.06ZM11.625 5.25C11.625 4.95163 11.7435 4.66548 11.9545 4.4545C12.1655 4.24353 12.4516 4.125 12.75 4.125C13.0484 4.125 13.3345 4.24353 13.5455 4.4545C13.7565 4.66548 13.875 4.95163 13.875 5.25C13.875 5.54837 13.7565 5.83452 13.5455 6.0455C13.3345 6.25647 13.0484 6.375 12.75 6.375C12.4516 6.375 12.1655 6.25647 11.9545 6.0455C11.7435 5.83452 11.625 5.54837 11.625 5.25Z"
        fill="#666666"
      />
    </svg>
  );
};

export default memo(PictureIcon);
