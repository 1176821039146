import { Dispatch } from "react";
import { IAssetPackageResponse } from "redux/components/asset-packages/asset-packages.interface";
import { IParams, SourceActionType } from "redux/interfaces";
import { fetchAssetPackageDetail } from "services/asset-packages/asset-packages.service";
import { actions } from "..";
import { fetchAssetAndAssetPackages } from "services/assets/assets.service";

export const getProjectAssetPackages: SourceActionType = (
  subProjectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setAssetPackageLoader(true));
      const response = await fetchAssetAndAssetPackages(subProjectId, params);
      dispatch(actions.getProjectAssetPackages(response));
      dispatch(actions.setAssetPackageLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setAssetPackageLoader(false));
    }
  };
};

export const getAssetPackageDetail: SourceActionType = (
  subProjectId: string,
  assetPackageId: string,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setAssetPackageDetailLoader(true));
      const response: IAssetPackageResponse = await fetchAssetPackageDetail(
        subProjectId,
        assetPackageId,
      );
      dispatch(actions.setAssetPackageDetail(response.data[0]));
      dispatch(actions.setAssetPackageDetailLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setAssetPackageDetailLoader(false));
    }
  };
};
