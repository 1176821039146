import React, { memo } from "react";
import "./Typing.scss";

interface TypingIndicatorProps {
  text: string;
}

const TypingIndicator: React.FC<TypingIndicatorProps> = ({ text }) => {
  return (
    <div className="loading">
      {text}
      <span className="dot" />
      <span className="dot" />
      <span className="dot" />
    </div>
  );
};

export default memo(TypingIndicator);
