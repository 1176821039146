import { AxiosResponse } from "axios";
import { pmHttp } from "utils/client";
import { GET_PM_CALENDAR_EVENTS } from "./calendar.endpoints";
import { IPmCalendarEventResponse } from "./calendar.interface";

/**
 * Fetches calendar events for preventive maintenance tasks within a specified date range
 * for a given master and subproject.
 *
 * @param {string} masterProjectId - The ID of the master project.
 * @param {string} subProjectId - The ID of the subproject.
 * @param {string} startDate - The start date of the date range in the format 'YYYY-MM-DD'.
 * @param {string} endDate - The end date of the date range in the format 'YYYY-MM-DD'.
 * @returns {Promise<{ data: ICalendarEvent[] }>} A promise that resolves to an object containing pm calendar events.
 * @throws {Error} If an error occurs during the HTTP request.
 */
export const fetchPmCalendarEvents = async (
  masterProjectId: string,
  subProjectId: string,
  startDate: string,
  endDate: string,
  params?: any,
): Promise<IPmCalendarEventResponse> => {
  try {
    // Construct the URL with the provided parameters
    const url = GET_PM_CALENDAR_EVENTS.replace(
      "{masterProjectId}",
      masterProjectId,
    )
      .replace("{subProjectId}", subProjectId)
      .replace("{startDate}", startDate)
      .replace("{endDate}", endDate);

    // Make an HTTP GET request to retrieve preventive maintenance task calendar events
    const response: AxiosResponse<IPmCalendarEventResponse> = await pmHttp.get(
      url,
      params,
    );

    // Extract and return the list of calendar events from the response
    return response.data;
  } catch (error) {
    // Re-throw the error for better error handling elsewhere
    throw error;
  }
};
