export interface IAlectifyDescriptionViewProps {
  detail: string;
  height: DescriptionViewHeight | string;
}

export enum DescriptionViewHeight {
  extraSmall = 60,
  small = 100,
  medium = 250,
  large = 400,
  contain = "100%",
}
