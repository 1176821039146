import { memo, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { getDownloadAssetsFile } from "services/assets/assets.service";
import { getProjectAssets } from "redux/components/assets/sources";
import { PAGINATION } from "constants/index";
import AlectifyText from "static/texts.json";
import { IPagination } from "../table/AlectifyTable.interface";
import AlectifyTable from "../table";
import useAssetsColumns from "./effects/useAssetsColumns";
import AlectifyButton from "../button";
import { IAssetsTableProps } from "./AssetsTable.interface";
import { assetsFilterOptions, BUTTON_TYPES } from "utils/constants";

const AssetsTable: React.FC<IAssetsTableProps> = (props: IAssetsTableProps) => {
  const dispatch = useDispatch();
  const columns = useAssetsColumns({ ...props, showAssetPackage: true });
  const { assets } = useSelector((state: IRootState) => state.assets);
  const { activeSubProject } = useSelector(({ common }: IRootState) => common);
  const { activeToggleButton } = useSelector(
    ({ procedures }: IRootState) => procedures,
  );
  const subProjectId = props.subProjectId || activeSubProject?.id || "-";
  const getAssets = useCallback(
    (options?: IPagination) => {
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...options,
        ...(activeToggleButton === BUTTON_TYPES.PARENT_ASSET
          ? { type: "PackageRoom" }
          : activeToggleButton === BUTTON_TYPES.SUB_ASSET
          ? { type: "Tag" }
          : {}),
      };
      dispatch(getProjectAssets(subProjectId, params));
    },
    [activeToggleButton, subProjectId, dispatch],
  );
  useEffect(() => {
    getAssets();
  }, [getAssets, subProjectId]);

  const rowClassName = (record: any) => {
    return record.type !== "Tag" ? "sea-green-background" : "white-background";
  };
  return (
    <>
      <AlectifyTable
        toggleOptions={assetsFilterOptions}
        searchDataRef={props.searchDataRef}
        size="small"
        columns={columns}
        loading={assets.fetching}
        dataSource={
          assets.data.map((asset) => ({
            ...asset,
            id: asset.type === "PackageRoom" ? asset.package_id : asset.tag_id,
          })) || []
        }
        total={assets.meta.total_count}
        onDataFetch={getAssets}
        showSearch={props.showSearch}
        scroll={props.scroll}
        displayRowClass={rowClassName}
        fetchSelectedRowKeys={props.fetchSelectedRowKeys}
        rowSelection={props.rowSelection as any}
        selectedRowKeys={props.selectedRowKeys}
        onRowSelectionAction={props.onRowSelectionAction}
        getCheckboxProps={props.getCheckboxProps}
        extraButton={
          props.showExtraButton ? (
            <AlectifyButton
              text={AlectifyText.DOWNLOAD_ASSETS}
              type="primary"
              icon={<CloudDownloadOutlined className="icon-font-size-18" />}
              onClick={() => {
                getDownloadAssetsFile(subProjectId);
              }}
            />
          ) : (
            props.extra
          )
        }
        customSearchKey="search"
      />
    </>
  );
};

export default memo(AssetsTable);
