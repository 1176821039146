import { memo } from "react";
import AlectifyTable from "components/shared/table";
import useListProjectsColumn from "./effects/useListProjectsColumn";
import { IListProjectTable } from "./Admin.interface";

const ListProjectTable: React.FC<IListProjectTable> = (props) => {
  const columns = useListProjectsColumn(props);

  return (
    <AlectifyTable
      showSearch
      loading={props?.projects?.fetching}
      noIcontainsSearch
      columns={columns}
      dataSource={props.projects?.data}
      total={props.projects.meta?.total_count || 0}
      onDataFetch={props.getAdminProjects}
      customSearchKey="search"
      scroll={{ y: "calc(100vh - 375px)" }}
    />
  );
};

export default memo(ListProjectTable);
