import dayjs from "dayjs";
import AlectifyText from "static/texts.json";
import { formatTitleText } from "utils/helpers";

export const mlOptions: any = (dateRangeRef: any) => {
  const fromDate = dayjs(dateRangeRef.current?.from_date);
  const toDate = dayjs(dateRangeRef.current?.to_date);

  return {
    chart: {
      type: "area",
      toolbar: false,
    },
    xaxis: {
      type: "datetime",
      labels: {
        formatter: function (value: any) {
          return dayjs(value).format("MMM, DD");
        },
        style: {
          fontSize: "14px",
        },
      },
      min: fromDate.valueOf(),
      max: toDate.valueOf(),
      tickAmount: 15,
      title: {
        text: formatTitleText(fromDate, toDate),
        style: {
          fontSize: "14px",
        },
      },
    },
    yaxis: {
      min: 0,
      max: 1,
      title: {
        text: AlectifyText.BREAK_DOWN_PROBABILITY,
        style: {
          fontSize: "14px",
        },
      },
      labels: {
        formatter: function (val: any) {
          return val.toFixed(0);
        },
        style: {
          fontSize: "14px",
        },
      },
      tickAmount: 1,
    },
    colors: ["#008FFB", "#00E396"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    legend: {
      position: "top",
      fontSize: "14px",
      horizontalAlign: "center",
      show: true,
    },
    annotations: {
      xaxis: [
        {
          x: dayjs().valueOf(),
          borderColor: "#3a86ff",
          label: {
            borderColor: "#3a86ff",
            style: {
              color: "#fff",
              background: "#3a86ff",
              fontSize: "12px",
            },
            text: dayjs().format("MMM DD, YYYY"),
          },
        },
      ],
    },
  };
};

export const predictionSeries = (data: any) => {
  return [
    {
      name: AlectifyText.ACTUAL,
      data: data?.Actual?.map((point: any) => ({
        x: dayjs(point?.Date)?.format("YYYY-MM-DD"),
        y: point?.Value,
      })),
    },
    {
      name: AlectifyText.PREDICTION,
      data: data?.Prediction?.map((point: any) => ({
        x: dayjs(point?.Date)?.format("YYYY-MM-DD"),
        y: point?.Value,
      })),
    },
  ];
};
