interface ICrossRedIconProps {
  fill?: string | null;
}
const CrossRedIcon: React.FC<ICrossRedIconProps> = (
  props: ICrossRedIconProps,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <g clip-path="url(#clip0_10086_85612)">
        <path
          d="M4.81792 4.25739C4.52502 3.9645 4.05015 3.9645 3.75726 4.25739C3.46436 4.55029 3.46436 5.02516 3.75726 5.31805L6.93923 8.50003L3.75729 11.682C3.46439 11.9749 3.46439 12.4497 3.75729 12.7426C4.05018 13.0355 4.52505 13.0355 4.81795 12.7426L7.99989 9.56069L11.1819 12.7427C11.4748 13.0356 11.9496 13.0356 12.2425 12.7427C12.5354 12.4498 12.5354 11.9749 12.2425 11.682L9.06056 8.50003L12.2426 5.31802C12.5355 5.02512 12.5355 4.55025 12.2426 4.25736C11.9497 3.96446 11.4748 3.96446 11.1819 4.25736L7.99989 7.43937L4.81792 4.25739Z"
          fill={props.fill || "#F94C4C"}
        />
      </g>
      <defs>
        <clipPath id="clip0_10086_85612">
          <rect
            width="16"
            height="16"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CrossRedIcon;
