import { createActionAndReducers } from "redux/utils/reducer.helper";
import * as GlobalSearchEffects from "./affects";
import { reducerName } from "./GlobalSearch.constants";
import { IGlobalSearchActions } from "./GlobalSearch.interface";
import { globalSearchInitialState } from "./GlobalSearch.initialState";
export * from "./GlobalSearch.constants";
export * from "./GlobalSearch.interface";

const { actions, reducer } = createActionAndReducers<IGlobalSearchActions>(
  reducerName,
  globalSearchInitialState,
  GlobalSearchEffects,
);

export { actions, globalSearchInitialState, reducer };
