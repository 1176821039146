import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./users.constants";
import { usersInitialState } from "./users.initialState";
import { IUsersActions } from "./users.interface";
import * as UsersAffects from "./affects/index";

export * from "./users.constants";
export * from "./users.interface";

const { actions, reducer } = createActionAndReducers<IUsersActions>(
  reducerName,
  usersInitialState,
  UsersAffects,
);

export { actions, usersInitialState, reducer };
