import GoogleMapReact, { ClickEventValue } from "google-map-react";
import { Marker } from "components/shared/marker";
import { IProjectMapProps } from "pages/master-project/listing/ProjectMap.interface";
import { IMasterProject } from "redux/components/master-project";
import { config } from "utils/configs";
import "./AlectifyMap.scss";
import { isEmpty } from "lodash";

const AlectifyMap: React.FC<IProjectMapProps> = (props: IProjectMapProps) => {
  const {
    projects,
    onClick,
    updateLocation,
    masterprojectItem,
    onChange,
    handleApiLoaded,
  } = props;
  const defaultProps = {
    center: {
      lat: 47.73247288415846,
      lng: -110.3151785794922,
    },
    zoom: 4,
  };
  const handleOnClick = (project: IMasterProject) => {
    if (onClick) {
      onClick(project);
    }
  };

  const handleLocationOnClick = (event: ClickEventValue) => {
    if (updateLocation) {
      updateLocation(event);
    }
  };

  return (
    <div className="alectify-google-map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.REACT_APP_GOOGLE_MAP_KEY as string | "",
          libraries: ["places", "geometry"],
        }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
        onClick={handleLocationOnClick}
        center={masterprojectItem?.center}
        onChange={onChange}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={
          handleApiLoaded
            ? ({ map, maps }) => handleApiLoaded(map, maps)
            : () => {}
        }
      >
        {!isEmpty(projects) &&
          projects?.map((project: IMasterProject) => (
            <Marker
              key={project.id}
              lat={project.latitude}
              lng={project.longitude}
              onClick={() => handleOnClick(project)}
              project={project}
            />
          ))}
        {!projects && masterprojectItem && (
          <Marker
            onClick={() => {}}
            lat={masterprojectItem?.latitude}
            lng={masterprojectItem?.longitude}
            title={masterprojectItem?.address}
          />
        )}
      </GoogleMapReact>
    </div>
  );
};
export default AlectifyMap;
