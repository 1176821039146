import {
  WORK_ORDER_EDITING_TYPES,
  WORK_ORDER_FORM_FIELD_TYPES,
} from "./WorkOrderCreateEdit.constants";
import {
  IWorkOrderCreateEditProps,
  IWorkOrderInitialState,
} from "./WorkOrderCreateEdit.interface";
import DayJs from "dayjs";
import AlectifyText from "static/texts.json";
import { ITask } from "redux/components/tasks";
import { isEmpty } from "lodash";
import { PM_TYPES } from "redux/components/pm-external";
import { MaintenanceCategoriesEnum } from "enums";
import { convertArrayToStrings } from "utils/helpers";

export const createWorkOrderPayload = (
  state: IWorkOrderInitialState,
  values: any,
  props: IWorkOrderCreateEditProps,
) => {
  const teamInfo =
    state?.selectedMembertype === "users" && state.selectedTeamMembers
      ? {
          teamMembers: state?.selectedTeamMembers?.map(
            (member: any) => member.id,
          ),
        }
      : state?.selectedMembertype === "teams" && state.selectedTeamMembers
      ? { teamId: state?.selectedTeamId }
      : undefined;
  const payload = {
    ...values,
    ...teamInfo,
    projectId: state.selectedMasterProject?.id,
    subProjectId: state.selectedSubProject?.id,
    priority: values.priority ? "CRITICAL" : "NORMAL",
    dueDate: DayJs(values["dueDate"]).format(),
    frequency: values.frequency || values.frequency_day || undefined,
    isRecurring: state.isRecurring,
    frequencyType: values.frequencyType?.toUpperCase(),
    areaIds: state.selectedAssetPackage,
    assetIds: state.selectedAssets,
    procedureLibraryId:
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      null,
    assets: null,
    assetLevel:
      isEmpty(state.selectedAssets) && isEmpty(state.selectedAssetPackage)
        ? "generic"
        : "asset",
    taskCategory: state.taskCategory,
  };
  if (!props.editing || props.copying) {
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
    payload.subProjectId = state.selectedSubProject?.id;
  }
  if (props.editing && !props.copying) {
    delete payload.assignees;
    delete payload.approvers;
    delete payload.subProject;
    delete payload.masterProject;
    payload.changeAllFuturePM =
      props.editType === WORK_ORDER_EDITING_TYPES.ALL_EVENTS;
    payload.procedureLibraryId =
      state.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibraryId ||
      state.selectedProcedureLibrary?.procedureLibrary?.id ||
      state.selectedProcedureLibrary?.id ||
      null;
    delete payload.assetIds;
    delete payload.areaIds;
  }
  // Remove keys with undefined values
  const filteredPayload = Object.entries(payload).reduce(
    (acc: any, [key, value]) => {
      if (value !== undefined) {
        acc[key] = value;
      }
      return acc;
    },
    {},
  );
  if (props.copying) {
    filteredPayload[WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES] =
      convertArrayToStrings(payload[WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES]);
    filteredPayload[WORK_ORDER_FORM_FIELD_TYPES.APPROVERS] =
      convertArrayToStrings(payload[WORK_ORDER_FORM_FIELD_TYPES.APPROVERS]);
  }
  return filteredPayload;
};

export const initializeWorkOrderForm = (
  props: IWorkOrderCreateEditProps,
  taskDetails: ITask | null,
) => {
  if (taskDetails) {
    const pmAssigneesToMap = taskDetails?.assignees;
    const assigneesValue =
      pmAssigneesToMap &&
      pmAssigneesToMap.map((assignee: any) => ({
        ...assignee,
        label: `${assignee.user.first_name} ${assignee.user.last_name}`,
        name: assignee.user.first_name,
        value: assignee.user.id,
      }));
    const pmTeamMember = isEmpty(taskDetails?.teamMembers)
      ? taskDetails?.team?.projectTeamMembers
      : taskDetails?.teamMembers;
    const teamSValue = pmTeamMember?.map((member: any) => member?.user?.id);

    const pmApproversToMap = taskDetails?.approvers;
    const approversValue =
      pmApproversToMap &&
      pmApproversToMap.map((approvers: any) => ({
        ...approvers,
        label: `${approvers.user.first_name} ${approvers.user.last_name}`,
        name: approvers.user.first_name,
        value: approvers.user.id,
      }));
    props.FormInstance.setFields([
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.MASTER_PROJECT,
        value: taskDetails?.project.name,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.SUB_PROJECT,
        value: taskDetails?.subProject.name,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.WORK_TITLE,
        value: taskDetails.workTitle || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TASK_PRIORITY,
        value: taskDetails.priority === "CRITICAL",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DETAILS,
        value: taskDetails.detail,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TASK_CATEGORY,
        value:
          taskDetails.taskCategory ===
            MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE ||
          taskDetails.taskCategory ===
            MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
            ? taskDetails.taskCategory
            : "TASK",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.ASSIGNEES,
        value: assigneesValue,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.APPROVERS,
        value: approversValue,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DUE_DATE,
        value: DayJs(taskDetails.dueDate),
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DATE,
        value: taskDetails?.date || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.DAY,
        value: taskDetails?.day || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.WEEK,
        value: taskDetails?.week || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.FREQUENCY,
        value: taskDetails?.frequency || null,
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.ASSETS,
        value: "value",
      },
      {
        name: WORK_ORDER_FORM_FIELD_TYPES.TEAM_MEMBERS_LIST,
        value: teamSValue,
      },
    ]);
  }
};
export const getSelectedAssetsText = (
  selectedAssets: any[] | null,
  selectedAssetPackage: any[] | null,
) => {
  // Combine selected asset names and package names
  const selectedNames = [
    ...(selectedAssets?.map(
      (asset) => asset.tag_name || asset.name || asset.asset?.name || "-",
    ) || []),
    ...(selectedAssetPackage?.map(
      (pkg) => pkg.package_name || pkg.name || pkg.area?.name || "-",
    ) || []),
  ];

  // If there are more than 2 items, show the count
  if (selectedNames.length > 1) {
    return `${selectedNames.slice(0, 1).join(", ")} (+${
      selectedNames.length - 1
    } Assets)`;
  }

  // If there are 2 or fewer, just join them with commas
  return selectedNames.join(", ") || AlectifyText.ADD_ASSETS;
};
