import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./pm-internal.constants";
import { pmInternalInitialState } from "./pm-internal.initialState";
import { IPmInternalActions } from "./pm-internal.interface";
import * as PmInternalAffects from "./affects";

export * from "./pm-internal.interface";
export * from "./pm-internal.constants";

const { actions, reducer } = createActionAndReducers<IPmInternalActions>(
  reducerName,
  pmInternalInitialState,
  PmInternalAffects,
);

export { actions, pmInternalInitialState, reducer };
