import { Col, Row } from "antd";
import { IProcedureImportProps } from "./ProcedureImport.interface";
import AlectifyButton from "components/shared/button";

import "./ProcedureImport.scss";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ProcedureTable from "../procedure-table/ProcedureTable";
import { IProcedure } from "services/procedures/procedures.interface";
import { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import ProcedureDetail from "../procedure-detail";
import AlectifyText from "static/texts.json";

const ProcedureImport: React.FC<IProcedureImportProps> = (
  props: IProcedureImportProps,
) => {
  const [selectedProcedure, setSelectedProcedure] = useState<IProcedure | null>(
    null,
  );
  const onSelectProcedure = (procedure: IProcedure | null) => {
    setSelectedProcedure(procedure);
    props.onSelectProcedure(procedure);
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.PROCEDURE_IMPORT_MODAL,
    });
  };

  const openImportModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1400,
      title: "Import Procedure",
      name: MODAL_NAMES.PROCEDURE_IMPORT_MODAL,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.PROCEDURE_IMPORT_MODAL,
        });
      },
      children: (
        <ProcedureTable
          onRowSelection={onSelectProcedure}
          projectId={props.projectId}
        />
      ),
    });
  };

  useEffect(() => {
    // if (!isEmpty(props.procedure)) {
    setSelectedProcedure(props.procedure);
    // }
  }, [props.procedure, props.projectId]);

  return (
    <Row justify={"start"} align={"top"}>
      <Col
        span={24}
        className={
          (!isEmpty(selectedProcedure) && "procedure-imported-list") || ""
        }
      >
        {isEmpty(selectedProcedure) ? (
          /*  <div className="procedure-import-main-container">
            <div className="procedure-import-main-content">
              <div className="procedure-import-empty-icon-and-text">
                <EmptyIcon />
                <Typography.Paragraph className="procedure-import-empty-text mt-10">
                  NO PROCEDURE SELETECTED YET
                </Typography.Paragraph>
              </div>
              <AlectifyButton
                name="import-procedure"
                text="Import Procedure"
                type="primary"
                className="full-width"
                onClick={openImportModal}
              />
            </div>
          </div> */
          <div className="import-procedure-button">
            <AlectifyButton
              name="import-procedure"
              text={AlectifyText.IMPORT_PROCEDURE}
              type="primary"
              className="full-width"
              onClick={openImportModal}
            />
          </div>
        ) : (
          <ProcedureDetail
            procedure={selectedProcedure}
            onRemove={onSelectProcedure}
            isDisabled={false}
            showRemoveButton
            readonly
          />
        )}
      </Col>
    </Row>
  );
};
export default ProcedureImport;
