import { Space, Tooltip, Typography } from "antd";
import AlectifyButton from "components/shared/button";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import "./SubProjectDetailHeader.scss";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import { useNavigate, useLocation } from "react-router-dom";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import InformationIcon from "components/icons/InformationIcon";
import AlectifyText from "static/texts.json";
import ProcedureViewSwitcher from "pages/procedures/procedures-listing/ProcedureViewSwitcher";
import { setWorkOrderCardSwitcher } from "redux/components/pm-external/sources";

const SubProjectDetailHeader: React.FC = () => {
  const { activeSubProject } = useSelector(({ common }: IRootState) => common);
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const { pmExternal } = useSelector((state: IRootState) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSwitchView = (value: boolean) => {
    dispatch(setWorkOrderCardSwitcher(value));
  };

  const showProcedureViewSwitcher =
    location.search.includes("page=pm-external") ||
    location.search.includes("page=tasks");

  return (
    <div className="sub-project-detail-header">
      <Space size={10}>
        <Typography.Title>{activeSubProject?.name}</Typography.Title>
        <Tooltip title={activeSubProject?.description}>
          <div>
            <InformationIcon />
          </div>
        </Tooltip>
        <AlectifyButton
          text={`${AlectifyText.CHANGE_ASSET_CATEGORY}`}
          type="primary"
          className="light-blue-button"
          onClick={() => {
            DrawerServiceInstance.open(AlectifyDrawer, {
              width: 420,
              name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
              title: `${activeMasterProject?.name}: Sub Project List`,
              onClose: () =>
                DrawerServiceInstance.close(AlectifyDrawer, {
                  name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                }),
              children: (
                <SwitchSubProjectDrawer
                  masterProjectId={activeMasterProject?.id || ""}
                  navigate={navigate}
                />
              ),
            });
          }}
        />
        {showProcedureViewSwitcher && (
          <ProcedureViewSwitcher
            onChange={handleSwitchView}
            tableView={pmExternal.activeWorkOrderCardSwitcher}
          />
        )}
      </Space>
    </div>
  );
};

export default SubProjectDetailHeader;
