import React from "react";

const ClosedEnvelopIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24" // Width of the SVG
      height="28" // Height of the SVG
      viewBox="0 -1 64 64"
      fill="none"
      className="size-6"
    >
      {/* Envelope Body */}
      <rect
        x="8"
        y="20"
        width="48"
        height="35"
        rx="2"
        ry="2"
        fill="#E3E4E8" // Background color
        stroke="#0954f1" // Outline color
        strokeWidth="3"
      />

      {/* Inner Lines of the Envelope */}
      <path
        d="M8 20 L32 36 L56 20" // Inner line path
        fill="none"
        stroke="#0954f1" // Color for inner lines
        strokeWidth="3"
      />
    </svg>
  );
};

export default ClosedEnvelopIcon;
