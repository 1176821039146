import { FC, useState } from "react";
import { AutoComplete, Form, Spin } from "antd";
import { debounce } from "lodash";
import { fetchOrganizations } from "services/organization/organization.service";
import { IOrganization } from "redux/components/Auth";
import { OrganizationsAutoCompleteProps } from "./autocomplete.interface";

const OrganizationsAutoComplete: FC<OrganizationsAutoCompleteProps> = ({
  name,
  label,
  required,
  placeholder,
  disabled,
  onBlur,
  onChange,
  onSelect,
}) => {
  const [organizations, setOrganizations] = useState({
    data: [],
    value: "",
    fetching: false,
  });
  const onSearchVendor = debounce(async (value: string) => {
    try {
      if (!value) {
        throw new Error();
      }
      const params = {
        search: value,
      };
      setOrganizations({ ...organizations, fetching: true });
      const response = await fetchOrganizations(params);
      setOrganizations({
        ...organizations,
        fetching: false,
        data: response?.data,
        value,
      });
    } catch (error) {
      setOrganizations({ ...organizations, fetching: false, data: [] });
    }
  }, 500);

  const requiredRule = [
    {
      required: required,
      message: `Please enter ${label}`,
    },
  ];

  return (
    <Form.Item
      label={label}
      name={name}
      rules={requiredRule}
      className="alectify-organization-autocomplete"
    >
      <AutoComplete
        showSearch
        placeholder={placeholder}
        options={organizations?.data?.map((org: IOrganization) => ({
          ...org,
          name: org?.name,
          value: org?.name,
        }))}
        onSearch={onSearchVendor}
        notFoundContent={
          organizations.fetching ? (
            <div className="text-center">
              <Spin />
            </div>
          ) : null
        }
        disabled={disabled}
        onBlur={onBlur && onBlur}
        onSelect={onSelect && onSelect}
        onChange={onChange && onChange}
      />
    </Form.Item>
  );
};

export default OrganizationsAutoComplete;
