import { memo } from "react";
import { Col, Row } from "antd";
import { IAddAssetPackage } from "./AddAssetPackage.interface";
import AddAssetPackageForm from "./AddAssetPackageForm";
import AvailableAndSelectedAssets from "./AvailableAndSelectedAssets";

const AddAssetPackage: React.FC<IAddAssetPackage> = ({
  subProjectId,
  form,
  record,
  packageEdit,
}) => {
  return (
    <Row gutter={30} className="full-height">
      <Col span={6}>
        <AddAssetPackageForm form={form} record={record} />
      </Col>
      <Col span={18} className="full-height border-light-grey">
        <AvailableAndSelectedAssets
          form={form}
          subProjectId={subProjectId}
          record={record}
          packageEdit={packageEdit}
        />
      </Col>
    </Row>
  );
};

export default memo(AddAssetPackage);
