import { memo } from "react";
interface IClockIconProps {
  fill?: string;
}

const ClockIcon: React.FC<IClockIconProps> = (props: IClockIconProps) => {
  return (
    <svg
      width="19"
      height="18"
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Frame">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.1875 1.6875C5.32135 1.6875 2.1875 4.82135 2.1875 8.6875C2.1875 12.5537 5.32135 15.6875 9.1875 15.6875C13.0537 15.6875 16.1875 12.5537 16.1875 8.6875C16.1875 4.82135 13.0537 1.6875 9.1875 1.6875ZM9.72596 4.37981C9.72596 4.237 9.66923 4.10004 9.56825 3.99906C9.46727 3.89808 9.33031 3.84135 9.1875 3.84135C9.04469 3.84135 8.90773 3.89808 8.80675 3.99906C8.70577 4.10004 8.64904 4.237 8.64904 4.37981V8.6875C8.64904 8.98473 8.89027 9.22596 9.1875 9.22596H12.4183C12.5611 9.22596 12.698 9.16923 12.799 9.06825C12.9 8.96727 12.9567 8.83031 12.9567 8.6875C12.9567 8.54469 12.9 8.40773 12.799 8.30675C12.698 8.20577 12.5611 8.14904 12.4183 8.14904H9.72596V4.37981Z"
          fill={props.fill || "#0954F1"}
        />
      </g>
    </svg>
  );
};

export default memo(ClockIcon);
