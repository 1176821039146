export enum GroupCheckpointTypes {
  GROUP = "Area",
  CHECKPOINT = "Checkpoint",
}

export const SHIFTS_PER_DAY = [
  {
    key: "1",
    label: "1",
    value: 1,
  },
  {
    key: "2",
    label: "2",
    value: 2,
  },
  {
    key: "3",
    label: "3",
    value: 3,
  },
  {
    key: "4",
    label: "4",
    value: 4,
  },
  {
    key: "5",
    label: "5",
    value: 5,
  },
  {
    key: "6",
    label: "6",
    value: 6,
  },
  {
    key: "7",
    label: "7",
    value: 7,
  },
  {
    key: "8",
    label: "8",
    value: 8,
  },
  {
    key: "9",
    label: "9",
    value: 9,
  },
  {
    key: "10",
    label: "10",
    value: 10,
  },
  {
    key: "11",
    label: "11",
    value: 11,
  },
  {
    key: "12",
    label: "12",
    value: 12,
  },
  {
    key: "13",
    label: "13",
    value: 13,
  },
  {
    key: "14",
    label: "14",
    value: 14,
  },
  {
    key: "15",
    label: "15",
    value: 15,
  },
  {
    key: "16",
    label: "16",
    value: 16,
  },
  {
    key: "17",
    label: "17",
    value: 17,
  },
  {
    key: "18",
    label: "18",
    value: 18,
  },
  {
    key: "19",
    label: "19",
    value: 19,
  },
  {
    key: "20",
    label: "20",
    value: 20,
  },
  {
    key: "21",
    label: "21",
    value: 21,
  },
  {
    key: "22",
    label: "22",
    value: 22,
  },
  {
    key: "23",
    label: "23",
    value: 23,
  },
  {
    key: "24",
    label: "24",
    value: 24,
  },
];

export const dataRoundCheckpointsGroupCSV = `Item #,Area,Checkpoint,Description,Reading Required,On Off Feature,Sub Asset,Parent Asset
1,SPRINKLER ROOM (118),,,,
1.1,SPRINKLER ROOM (118),Drain water from the compressor,Drained,0,0,,
1.2,SPRINKLER ROOM (118),Main & Zones Valves (SV-1 SV-2 SV-3 SV-4 SV-5),Secure/Open,1,0,2110-NRG-009-12,
1.3,SPRINKLER ROOM (118),Zone Water Supply Pressure,80-120 PSI,0,0,,
1.4,SPRINKLER ROOM (118),Zone System Air Pressure,20-25 PSI,0,1,,
1.5,SPRINKLER ROOM (118),Zone Control Latch Water Pressure,80-120 PSI,0,0,,
1.6,SPRINKLER ROOM (118),Valves SV-6 SV-7,Secure/Open,0,0,,
2,Domestic Water System,,,
2.1,Domestic Water System,Water Meter Reading (Mondays only) 2-M.1,81-120 PSI,1,0,,
3,ROOM 1,,,
3.1,ROOM 1,C1,83-120 PSI,,,,
3.2,ROOM 1,C2,84-120 PSI,,,,
3.3,ROOM 1,C3,85-120 PSI,,0,,
3.4,ROOM 1,C4,86-120 PSI,,1,,
3.5,ROOM 1,C5,87-120 PSI,,0,,
3.6,ROOM 1,C6,88-120 PSI,,0,,
3.7,ROOM 1,C7,89-120 PSI,,0,,
3.8,ROOM 1,C8,90-120 PSI,,0,,
3.9,ROOM 1,C9,91-120 PSI,,,,
3.10,ROOM 1,C10,92-120 PSI,0,1,,
3.11,ROOM 1,C11,93-120 PSI,1,0,,
4,ROOM 2,,,
4.1,ROOM 2,C13,95-120 PSI,0,0,,
4.2,ROOM 2,C14,96-120 PSI,0,0,,Package 1
5,ROOM 6,,,
5.1,ROOM 6,C16,98-120 PSI,,0,,
5.2,ROOM 6,C17,99-120 PSI,1,,,
5.3,ROOM 6,C18,100-120 PSI,0,1,,
5.4,ROOM 6,C19,101-120 PSI,1,0,,
5.5,ROOM 6,C20,102-120 PSI,0,1,,
5.6,ROOM 6,C21,103-120 PSI,0,0,,
5.7,ROOM 6,C22,104-120 PSI,0,0,,
5.8,ROOM 6,C23,105-120 PSI,0,0,,
5.9,ROOM 6,C24,106-120 PSI,,0,,
5.10,ROOM 6,C25,107-120 PSI,1,,,
5.11,ROOM 6,C26,108-120 PSI,0,1,,
5.12,ROOM 6,C27,109-120 PSI,1,0,,
5.13,ROOM 6,C28,110-120 PSI,0,1,,
5.14,ROOM 6,C29,111-120 PSI,0,0,,
5.15,ROOM 6,C30,112-120 PSI,0,,Asset 3,
6,GARRAGE – ONE,,113-120 PSI,0,,,
`;
