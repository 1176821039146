import { Space, Tag, Tooltip } from "antd";
import AlectifyText from "static/texts.json";
import NoAssetTagIcon from "components/icons/NoAssetTagIcon";

interface NoAssetTagProps {
  count?: number;
  text?: string;
  showGenericName?: boolean;
}

const NoAssetTag: React.FC<NoAssetTagProps> = ({
  count,
  text,
  showGenericName,
}) => {
  return (
    <Tag color={"#d7d8d9"} className="no-asset-tag round-tag">
      <Space size={3}>
        <Tooltip title={"No Asset"}>
          <NoAssetTagIcon />
        </Tooltip>
        <span className="text-color">No Asset</span>
      </Space>
    </Tag>
  );
};

NoAssetTag.defaultProps = {
  text: AlectifyText.GENERIC,
  showGenericName: true,
};

export default NoAssetTag;
