import { memo, useEffect, useRef, useState } from "react";
import { Col, Row, Spin, Typography } from "antd";
import "./MaintenancePredictionCards";
import AlectifyBarChart from "../chart";
import AlectifyText from "static/texts.json";
import MaintenancePredictionCards from "./MaintenancePredictionCards";
import {
  DateRange,
  IMaintenancePredictionProps,
} from "./MaintenancePrediction.interface";
import dayjs from "dayjs";
import { mlOptions, predictionSeries } from "./sources";
import { fetchPredictionGraphData } from "services/prediction/prediction.service";
import { IPredictionGraphState } from "services/prediction/prediction.interface";
import { formatDate } from "utils/helpers";
import { DATE_FORMAT_FULL_MONTH_NAME } from "constants/index";
import MaintenancePredictionDatePicker from "./MaintenancePredictionDatePicker";
import "./MaintenancePrediction.scss";

const MaintenancePredictionDetails: React.FC<IMaintenancePredictionProps> = ({
  record,
  setShowMaintenanceDetail,
}) => {
  const dateRangeRef = useRef<DateRange | undefined>();
  const [predictionGraphState, setPredictionGraphState] =
    useState<IPredictionGraphState>({
      fetching: false,
      Actual: [],
      Prediction: [],
    });
  const getDateRangeFromChild = (dateRange: DateRange) => {
    dateRangeRef.current = dateRange;
  };
  const getPredictionGraphData = async () => {
    try {
      setPredictionGraphState({ ...predictionGraphState, fetching: true });
      const params = {
        ...dateRangeRef.current,
      };
      const response = await fetchPredictionGraphData(
        record?.mainproject_id,
        record?.object_type === "Tag" ? record?.tag_id : record?.package_id,
        record?.object_type === "Tag" ? "tags" : "area",
        params,
      );
      const { data } = response;
      const actualData = data.filter(
        (item) => item.Category === AlectifyText.ACTUAL,
      );
      const predictionData = data.filter(
        (item) => item.Category === AlectifyText.PREDICTION,
      );

      setPredictionGraphState({
        fetching: false,
        Actual: actualData,
        Prediction: predictionData,
      });
    } catch (err) {
      setPredictionGraphState({ ...predictionGraphState, fetching: false });
      console.log(err);
    }
  };
  useEffect(() => {
    getPredictionGraphData();
  }, [record]);

  return (
    <div className="task-heading">
      <Typography.Title level={5}>
        {AlectifyText.CORRECTIVE_MAINTENANCE_PREDICTION}: {record?.object_name}
      </Typography.Title>
      <Row gutter={16}>
        <Col span={8}>
          <MaintenancePredictionCards
            className="maintenance-card1 maintenance-card-container"
            value={
              record?.mtbf_hours
                ? `${record?.mtbf_hours?.toFixed(1)} Hours`
                : "N/A"
            }
            text="Mean Time Between Failure"
          />
        </Col>
        <Col span={8}>
          <MaintenancePredictionCards
            value={
              record?.mttr_hours
                ? `${record?.mttr_hours?.toFixed(1)} Hours`
                : "N/A"
            }
            className="maintenance-card2 maintenance-card-container"
            text="Mean Time To Repair"
          />
        </Col>
        <Col span={8}>
          <MaintenancePredictionCards
            value={formatDate(
              dayjs(record?.next_breakdown_date),
              DATE_FORMAT_FULL_MONTH_NAME,
            )}
            className="maintenance-card3 maintenance-card-container"
            text="Next Breakdown"
          />
        </Col>
      </Row>
      <div className="mt-20">
        <div className="display-dates-container">
          <MaintenancePredictionDatePicker
            disableDateDisplay
            getPredictions={getPredictionGraphData}
            getDateRangeFromChild={getDateRangeFromChild}
            setShowMaintenanceDetail={setShowMaintenanceDetail}
          />
        </div>
        <Spin spinning={predictionGraphState.fetching}>
          <AlectifyBarChart
            options={mlOptions(dateRangeRef)}
            series={predictionSeries(predictionGraphState)}
            type="area"
            height={330}
            className="chart"
          />
        </Spin>
      </div>
    </div>
  );
};

export default memo(MaintenancePredictionDetails);
