import { Space, Tag, Tooltip } from "antd";
import AssetPackagesIcon from "components/icons/AssetPackagesIcon";
import AlectifyText from "static/texts.json";
import { CHIP_COLORS } from "../phone-number/constants";

interface AssetPackageTagProps {
  count?: number;
  showParentAssetName?: boolean;
}

const AssetPackageTag: React.FC<AssetPackageTagProps> = ({
  count,
  showParentAssetName,
}) => {
  return (
    <Tag
      color={CHIP_COLORS.ASSET_PACKAGE}
      className="asset-package-tag round-tag"
    >
      <Space size={2}>
        <Tooltip title={AlectifyText.PARENT_ASSET_NAME}>
          <AssetPackagesIcon fill="#fff" />
        </Tooltip>
        {showParentAssetName &&
          `${AlectifyText.PARENT_ASSET_NAME}${count ? `: ${count}` : ""}`}
      </Space>
    </Tag>
  );
};

AssetPackageTag.defaultProps = {
  showParentAssetName: true,
};

export default AssetPackageTag;
