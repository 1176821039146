import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

interface IPMInternalIcon {
  fill?: string;
}
const PmInternalIcon: React.FC<IPMInternalIcon> = (props: IPMInternalIcon) => {
  return (
    <svg
      width="30"
      height="23"
      viewBox="0 0 30 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 28001">
        <path
          id="Vector"
          d="M3.10193 11.4838C3.09368 11.4077 3.02939 11.35 2.9528 11.35H2.13135C2.08875 11.35 2.04817 11.3681 2.01972 11.3998C1.99127 11.4315 1.97763 11.4738 1.98222 11.5161C2.21339 13.6515 3.04854 15.6792 4.26602 17.1761C5.4831 18.6725 7.09326 19.65 8.87209 19.65C10.6537 19.65 12.145 18.6693 13.242 17.1701C14.3382 15.6719 15.0501 13.6455 15.2805 11.5164C15.2851 11.4741 15.2714 11.4318 15.243 11.4001C15.2145 11.3684 15.1739 11.3503 15.1313 11.3503H14.6499C14.5733 11.3503 14.509 11.408 14.5007 11.4841C14.3025 13.312 13.6219 15.1533 12.622 16.534C11.6207 17.9166 10.3163 18.8167 8.87209 18.8167C7.42675 18.8167 6.08655 17.9153 5.04986 16.5319C4.01482 15.1507 3.29974 13.3098 3.10193 11.4838Z"
          fill="#141B34"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="0.3"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M1.18195 11.5H16.0804C16.6613 11.5 17.1313 10.9741 17.1313 10.3255C17.1313 9.67644 16.6613 9.15058 16.0804 9.15058H15.7944C15.7944 6.02975 14.1954 3.32794 11.8656 2.00636L11.2719 5.7687C11.225 6.06662 10.9938 6.27613 10.7318 6.27613C10.7003 6.27613 10.6681 6.2732 10.6358 6.26733C10.3371 6.20825 10.1381 5.89022 10.1906 5.55626L10.8686 1.25758C10.6141 0.802948 10.1628 0.5 9.64746 0.5H7.61448C7.09986 0.5 6.64896 0.802948 6.39409 1.25758L7.07213 5.55626C7.12498 5.89022 6.92558 6.20867 6.62685 6.26733C6.59462 6.27362 6.56238 6.27613 6.5309 6.27613C6.2689 6.27613 6.0369 6.06704 5.99079 5.7687L5.39709 2.00678C3.06613 3.32752 1.4683 6.02975 1.4683 9.15058H1.18195C0.601362 9.15058 0.131348 9.67644 0.131348 10.3255C0.131348 10.9741 0.601362 11.5 1.18195 11.5Z"
          fill="#141B34"
        />
        <path
          id="Vector 156"
          d="M2.13135 21.5C4.03258 19.9908 9.49431 17.8778 16.1313 21.5"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <g id="Group 25904">
          <path
            id="Vector 3"
            d="M23.4166 20.853L18.2626 15.8036L23.1538 10.4993"
            stroke="#141B34"
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            id="Vector 4"
            d="M20.1309 15.5L29.0078 15.5"
            stroke={props.fill || PROJECT_THEME_COLOR}
            strokeWidth="1.7"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </g>
      </g>
    </svg>
  );
};

export default PmInternalIcon;
