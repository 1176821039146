import AssetDocumentsTable from "components/shared/asset-documents-table";
import AssetPackageDocumentsTable from "components/shared/asset-package-documents-table";
import { useParams } from "react-router-dom";

interface IAssetTimelineDocuments {
  activeTab: string;
  fetchAllCounts?: () => void;
}

const AssetTimelineDocuments: React.FC<IAssetTimelineDocuments> = ({
  activeTab,
  fetchAllCounts,
}) => {
  const { equipmentId, subProjectId, equipmentType } = useParams();
  return (
    <>
      {equipmentType === "asset" ? (
        <AssetDocumentsTable
          activeTab={activeTab}
          timelineDocuments
          assetId={equipmentId}
          subProjectId={subProjectId}
          scroll={{ x: 1200 }}
          rowSelection
          downloadable
          fetchAllCounts={fetchAllCounts}
        />
      ) : (
        <AssetPackageDocumentsTable
          activeTab={activeTab}
          timelineDocuments
          subProjectId={subProjectId}
          assetPackageId={equipmentId}
          scroll={{ x: 1200 }}
          rowSelection
          downloadable
          fetchAllCounts={fetchAllCounts}
        />
      )}
    </>
  );
};

export default AssetTimelineDocuments;
