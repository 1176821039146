import { IAssetPackageState } from "./asset-packages.interface";

export const assetPackagesInitialState: IAssetPackageState = {
  fetching: false,
  data: [],
  message: "",
  meta: {
    total_count: 0,
    total_pages: 0,
    next: "",
    previous: "",
  },
  assetPackageDetail: {
    fetching: false,
    data: null,
  },
};
