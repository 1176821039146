import { RefObject, memo, useState } from "react";
import { Space, Row, Upload, message, Spin, Typography, Dropdown } from "antd";
import AlectifyButton from "../button";
import {
  CloudUploadOutlined,
  DownOutlined,
  DownloadOutlined,
  PlusOutlined,
  SyncOutlined,
} from "@ant-design/icons";
import AddAssetManualForm from "./AddAssetManualForm";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { useForm } from "antd/es/form/Form";
import AlectifyText from "static/texts.json";
import { isEmpty } from "lodash";
import { useRef } from "react";
import { PAGINATION } from "constants/index";
import {
  clearOldTags,
  createProjectAssets,
  deleteAsset,
  resetAssetsOrder,
} from "services/assets/assets.service";
import { IUserAccessProps } from "../user-access/IUserAccess.interface";
import { useDispatch, useSelector } from "react-redux";
import AssetsTable from "../assets-table";
import { IPagination } from "../table/AlectifyTable.interface";
import { getProjectAssets } from "redux/components/assets/sources";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { MESSAGES } from "constants/messages";
import { DataRef } from "../assets-table/AssetsTable.interface";
import { getSubProjects } from "redux/components/sub-project/sources";
import { IAsset, actions } from "redux/components/assets";
import { actions as assetPackageActions } from "redux/components/asset-packages";
import { csvContent, downloadCSV } from "utils/helpers";
import type { MenuProps } from "antd";
import "./ManageAssets.scss";

const ManageAssets: React.FC<IUserAccessProps> = ({
  subProjectId,
  isEdit,
  assetCategoryDetail,
}) => {
  const [FormInstance] = useForm();
  const dispatch = useDispatch();
  const fileInputRef = useRef<any>(null);
  const { assets } = useSelector((state: IRootState) => state.assets);
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const [state, setState] = useState({
    loader: false,
    formatError: "",
    selectedRowKeys: "",
  });
  const searchDataRef: RefObject<DataRef> = useRef<DataRef>({
    searchText: null,
  });
  const getAssets = (options?: IPagination) => {
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      search: searchDataRef.current && searchDataRef.current.searchText,
      ...options,
    };
    dispatch(getProjectAssets(subProjectId, params));
  };

  const fetchSubprojects = () => {
    dispatch(
      getSubProjects(activeMasterProject?.id, {
        page: PAGINATION.DEFAULT_START_PAGE,
        per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      }),
    );
  };

  const resetOrder = async () => {
    try {
      setState((prevState) => ({
        ...prevState,
        loader: true,
      }));
      await resetAssetsOrder(subProjectId);
      getAssets();
      setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
    } catch (err) {
      setState((prevState) => ({
        ...prevState,
        loader: false,
      }));
      console.log(err);
    }
  };

  // const deleteCheckedRow = async (assetId: string) => {
  //   try {
  //     setState((prevState) => ({
  //       ...prevState,
  //       loader: true,
  //     }));
  //     await deleteAsset(subProjectId, assetId);
  //     getAssets();
  //     fetchSubprojects();
  //     message.success(AlectifyText.REMOVED_SUCCESSFULLY);
  //     setState((prevState) => ({
  //       ...prevState,
  //       selectedRowKeys: "",
  //     }));
  //     setState((prevState) => ({
  //       ...prevState,
  //       loader: false,
  //     }));
  //   } catch (error) {
  //     console.log(error);
  //     setState((prevState) => ({
  //       ...prevState,
  //       loader: false,
  //     }));
  //   }
  // };

  const fetchSelectedRowKeys = async (tagId: string) => {
    setState((prevState) => ({
      ...prevState,
      selectedRowKeys: tagId,
    }));
  };

  const onClose = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.ADD_ASSET_MANUAL,
    });
  };

  const onCloseAndFetchAssets = () => {
    FormInstance.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.ADD_ASSET_MANUAL,
    });
    getAssets();
    fetchSubprojects();
  };

  const handleFileInputClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleConfirmation = async (selectedOption: number) => {
    try {
      let response;
      if (selectedOption === 1) {
        response = await clearOldTags(subProjectId);
      } else if (selectedOption === 2) {
        handleFileInputClick();
      }
      if (response) {
        getAssets();
        handleFileInputClick();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleUploadButtonClick = () => {
    const onCancel = () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.UPLOAD_ASSETS_CSV_WARNING_MODAL,
      });
    };

    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.UPLOAD_ASSETS_CSV_WARNING_MODAL,
      title: AlectifyText.CONFIRMATION,
      footer: false,
      onCancel,
      children: (
        <ConfirmationModal
          assetCategoryDetail={assetCategoryDetail}
          icon={WarningIcon}
          options
          onConfirm={(value) => {
            handleConfirmation(value);
            onCancel();
          }}
          onCancel={() => {
            onCancel();
          }}
        />
      ),
    });
  };

  const handleCsvFileUpload = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append("asset_csv", file);
      await createProjectAssets(subProjectId, formData);
      message.success(MESSAGES.ASSET.ASSETS_UPLOADED);
    } catch (error) {
      console.log(error);
    } finally {
      const params = {
        page: PAGINATION.DEFAULT_START_PAGE,
        per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      };
      dispatch(getProjectAssets(subProjectId, params));
    }
  };

  const handleCsvDownload = () => {
    downloadCSV(csvContent);
  };

  const handleMenuClick: MenuProps["onClick"] = async (e) => {
    switch (e.key) {
      case "1":
        if (isEmpty(assets?.data)) {
          handleFileInputClick();
        } else {
          handleUploadButtonClick();
        }
        break;
      case "2":
        handleCsvDownload();
        break;
      default:
        message.info("Click on menu item.");
    }
  };

  const items: MenuProps["items"] = [
    {
      label: AlectifyText.UPLOAD_CSV,
      key: "1",
      icon: <CloudUploadOutlined />,
    },
    {
      label: AlectifyText.DOWNLOAD_SAMPLE_CSV,
      key: "2",
      icon: <DownloadOutlined />,
    },
  ].filter(Boolean) as MenuProps["items"];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  const onDeleteAsset = async (asset: IAsset) => {
    try {
      const payload = {
        [asset.type === "Tag" ? "tag_ids" : "package_ids"]:
          asset.type === "Tag" ? [asset?.tag_id] : [asset?.package_id],
      };

      const response = await deleteAsset(subProjectId, payload);
      if (response.success) {
        message.success(response.message);
      }
    } catch (err) {
      console.log(err);
    } finally {
      getAssets();
    }
  };
  return (
    <Spin spinning={state.loader}>
      <div className="manageAssets-container">
        {/* <h3>{AlectifyText.MANAGE_ASSETS}</h3> */}
        <div className="upload-btn-hide">
          <Upload
            accept=".csv"
            fileList={[]}
            maxCount={1}
            showUploadList={false}
            beforeUpload={() => false}
            onChange={(info) => {
              const { file } = info;
              handleCsvFileUpload(file as any);
            }}
          >
            <div ref={fileInputRef}></div>
          </Upload>
        </div>
        <Row align="middle" className="mb-10">
          {state.formatError && (
            <div className="mt-15">
              <Typography.Text type="danger">
                {state.formatError}
              </Typography.Text>
            </div>
          )}
        </Row>

        <AssetsTable
          form={FormInstance}
          searchDataRef={searchDataRef}
          subProjectId={subProjectId}
          fetchSelectedRowKeys={fetchSelectedRowKeys}
          onCloseAndFetchAssets={onCloseAndFetchAssets}
          scroll={{ y: "calc(100vh - 375px)" }}
          displayActionButton
          onClose={onClose}
          onDelete={onDeleteAsset}
          showSearch
          extra={
            <Space wrap>
              {/* {!isEmpty(state.selectedRowKeys) && (
                <AlectifyButton
                  onClick={() => deleteCheckedRow(state.selectedRowKeys)}
                  danger
                  text={`Delete (${state.selectedRowKeys.length})`}
                  type="text"
                  className="danger-button"
                  icon={<DeleteOutlined className="icon-font-size-18" />}
                />
              )} */}
              <AlectifyButton
                text="Add Asset"
                type="primary"
                icon={<PlusOutlined className="font-size-15" />}
                onClick={() => {
                  dispatch(actions.setAssetDetail([] as any));
                  dispatch(
                    assetPackageActions.setAssetPackageDetail([] as any),
                  );
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: "70vw",
                    title: AlectifyText.ADD_MANUAL_ASSET,
                    name: DRAWER_CONSTANTS.ADD_ASSET_MANUAL,
                    showFooter: true,
                    closeIcon: null,
                    closable: false,
                    children: (
                      <AddAssetManualForm
                        form={FormInstance}
                        subProjectId={subProjectId}
                        onCloseAndFetchAssets={onCloseAndFetchAssets}
                      />
                    ),
                    onSubmit: FormInstance.submit,
                    onClose: onClose,
                  });
                }}
              />

              <AlectifyButton
                onClick={resetOrder}
                text={AlectifyText.RESET_ORDER}
                type="text"
                className="light-blue-button-hover"
                icon={<SyncOutlined className="icon-font-size-18" />}
              />

              <Dropdown menu={menuProps}>
                <AlectifyButton
                  className="transparent-dropdown-btn"
                  text={AlectifyText.BULK_UPLOADS}
                  icon={<DownOutlined className="icon-font-size-18" />}
                />
              </Dropdown>
            </Space>
          }
        />
      </div>
    </Spin>
  );
};

export default memo(ManageAssets);
