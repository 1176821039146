import { useEffect, useRef } from "react";
import { ChatBotIcon } from "components/icons";
import { Col, Row, Tooltip } from "antd";
import PdfIcon from "components/icons/PdfIcon";
import { IAutoCreateProcedureResponseProps } from "./AutoCreateProcedure.interface";
import { IChatBotItems } from "components/chatbot-ui/Chatbot.interface";
import AlectifyText from "static/texts.json";
import TypingIndicator from "components/typing-indicator";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { extractFileName } from "utils/helpers";
import { MODAL_NAMES } from "constants/modal.constants";
import PreviewPdf from "components/shared/preview-pdf";
import DocIcon from "components/icons/DocIcon";
import { truncate } from "lodash";
import CrossIcon from "components/icons/CrossIcon";
import "./AutoCreateProcedure.style.scss";

const AutoCreateProcedureResponse: React.FC<
  IAutoCreateProcedureResponseProps
> = (props) => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [props.state?.aiProcedureItems?.data]);

  return (
    <div className="ai-response-body">
      <div ref={chatContainerRef} className="ai-response-answer-body">
        {props.state?.aiProcedureItems?.data?.map(
          (message: IChatBotItems, index) => {
            const isUser = message?.role === "user";

            return (
              <div className="ai-response-generator" key={index}>
                <div className={isUser ? "user-question" : "received-message"}>
                  <div className="mt-5">{!isUser && <ChatBotIcon />}</div>
                  <div className={isUser ? "user-text" : "assistant-text"}>
                    {!message?.temporary ? (
                      <>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              message?.content || AlectifyText.DATABASE_ERROR,
                          }}
                        />
                        <Row gutter={[12, 12]}>
                          {message?.sources?.map((source: any, sourceIndex) => (
                            <Col span={6} key={sourceIndex}>
                              <div className="read-page-container">
                                <h3>
                                  Read Page No:
                                  <span>{source?.page_number || "-"}</span>
                                </h3>
                                <Tooltip title={source?.file_name || "-"}>
                                  <div
                                    className="pdf-container"
                                    onClick={() => {
                                      ModalServiceInstance.open(AlectifyModal, {
                                        title: `${extractFileName(
                                          source?.file_name,
                                        )}- Preview`,
                                        name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                                        width: 1300,
                                        style: { top: 20, right: 250 },
                                        footer: null,
                                        className:
                                          "alectify-pdf-viewer-container",
                                        onCancel: () => {
                                          ModalServiceInstance.close(
                                            AlectifyModal,
                                            {
                                              name: MODAL_NAMES.PREVIEW_PDF_MODAL,
                                            },
                                          );
                                        },
                                        children: (
                                          <PreviewPdf
                                            documentUrl={source?.file_name}
                                            pageNumber={source?.page_number}
                                            zoom={80}
                                          />
                                        ),
                                      });
                                    }}
                                  >
                                    <div className="icon-font-size-16 mr-5">
                                      {source?.file_name
                                        .toLowerCase()
                                        .endsWith(".pdf") ? (
                                        <PdfIcon />
                                      ) : (
                                        <DocIcon />
                                      )}
                                    </div>
                                    <p>{extractFileName(source?.file_name)}</p>
                                  </div>
                                </Tooltip>
                              </div>
                            </Col>
                          ))}
                        </Row>
                      </>
                    ) : (
                      <TypingIndicator text={"Typing"} />
                    )}
                  </div>
                </div>
              </div>
            );
          },
        )}
      </div>
      <div className="ai-footer-content">
        <div className="equipment-info-container">
          <span>Equipment Information:</span>
          <p className="bold-text">
            {truncate(props.formValues?.description, {
              length: 70,
              omission: "...",
            }) || "-"}
          </p>
        </div>
        <div className="bottom-detail">
          {props.fileRef.current && (
            <div className="d-flex align-items-center width-100 p-5">
              <span className="attachment-text">
                {AlectifyText.ATTACHED_DOCS}
              </span>
              <div className="pdf-container-bottom">
                <PdfIcon />
                <span>
                  {truncate(props.fileRef.current.name, {
                    length: 50,
                    omission: "...",
                  })}
                </span>
                <div
                  className="cursor-pointer mt-5"
                  onClick={props.removeUploadedFile}
                >
                  {!props.state.aiProcedureItems.fetching ? (
                    <CrossIcon fill={"#868FA0"} />
                  ) : null}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AutoCreateProcedureResponse;
