import { memo, useEffect, useRef, useState } from "react";
import GlobalSearchCard from "./GlobalSearchCard";
import { useDispatch, useSelector } from "react-redux";
import InfiniteScroll from "react-infinite-scroll-component";
import { Divider, Skeleton, Spin } from "antd";
import {
  getAssetAndPackage,
  setGlobalSearchPage,
} from "redux/components/global-search/sources";
import { IEquipment, IGlobalSearchProps } from "./GlobalSearch.interface";
import AlectifyEmptyState from "../empty/AlectifyEmptyState";
import { isEmpty } from "lodash";
import { cancelApiRequests } from "utils/client";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import "./GlobalSearch.scss";

const GlobalSearch: React.FC<IGlobalSearchProps> = ({
  globalSearchedValue,
  setGlobalSearchVisible,
  globalSearchIsVisible,
}) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();
  const [isMounted, setIsMounted] = useState(false);
  const { globalSearchItems, meta, globalSearchLoader, page } = useSelector(
    ({ globalSearch }) => globalSearch,
  );
  // Event listener to handle clicks outside of the component
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        containerRef.current &&
        !containerRef.current.contains(event.target as Node)
      ) {
        setGlobalSearchVisible(false);
      }
    };

    // Attach the event listener when the component mounts
    document.addEventListener("click", handleClickOutside);
    setIsMounted(true);

    // Detach the event listener when the component unmounts
    return () => {
      document.removeEventListener("click", handleClickOutside);
      setIsMounted(false);
      if (!globalSearchedValue) {
        cancelApiRequests(SERVICE_UNIQUE_ID.UNIQUE_FETCH_GLOBAL_EQUIPMENTS);
      }
    };
  }, []);

  useEffect(() => {
    if (isMounted && page > 1) {
      const params = {
        page: page,
        per_page: 10,
        search: globalSearchedValue,
      };
      dispatch(getAssetAndPackage(params));
    }
  }, [page]);

  return (
    <div ref={containerRef} className="global-search-results-container">
      {globalSearchIsVisible && (
        <div id="scrollableDiv">
          <InfiniteScroll
            key="global-search"
            dataLength={globalSearchItems?.length}
            next={() => dispatch(setGlobalSearchPage(page + 1))}
            hasMore={globalSearchItems?.length < meta?.total_count}
            loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
            endMessage={
              globalSearchItems.length > 0 ? (
                <Divider plain>It is all, nothing more.</Divider>
              ) : null
            }
            scrollableTarget="scrollableDiv"
            height={700}
          >
            <Spin spinning={globalSearchLoader}>
              {isEmpty(globalSearchItems) ? (
                <div className="empty-container">
                  <AlectifyEmptyState
                    description="No data available"
                    height={200}
                    width={200}
                  />
                </div>
              ) : (
                globalSearchItems?.map(
                  (globalSearchItem: IEquipment, index: number) => (
                    <>
                      <GlobalSearchCard
                        key={index}
                        globalSearchItem={globalSearchItem}
                        setGlobalSearchVisible={setGlobalSearchVisible}
                      />
                    </>
                  ),
                )
              )}
            </Spin>
          </InfiniteScroll>
        </div>
      )}
      {meta?.total_pages && meta.total_pages > 1 && (
        <div className="page-container">
          <span>Page{` `}</span>
          {page}-10 of <span>{meta?.total_pages}</span>
        </div>
      )}
    </div>
  );
};

export default memo(GlobalSearch);
