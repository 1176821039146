//libs
import { PAGINATION } from "constants/index";
import { IRootState } from "redux/rootReducer";
import AlectifyTable from "components/shared/table";
import { useDispatch, useSelector } from "react-redux";
import { Breadcrumb, Col, Row, Typography } from "antd";
import useProcedureColumn from "./effects/useProcedureColumn";
import {
  getProcedures,
  setActiveTableView,
} from "redux/components/procedures/sources";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useEffect, useState } from "react";
import { useForm } from "antd/lib/form/Form";
import { IProcedure } from "services/procedures/procedures.interface";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";

//components
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { CloseOutlined, PlusOutlined } from "@ant-design/icons";
import ProceduresListing from "./procedures-listing";
import AlectifyButton from "components/shared/button";
import ProcedureCreateEdit from "./procedure-creation";
import AlectifyDrawer from "components/drawer";

//interfaces
import { IProceduresProps } from "./Procedures.interface";

//constants
import AlectifyText from "static/texts.json";

//styles
import "./Procedures.scss";
import ProcedureViewSwitcher from "./procedures-listing/ProcedureViewSwitcher";

const Procedures: React.FC<IProceduresProps> = () => {
  const { activeMasterProject } = useSelector((state: IRootState) => ({
    activeMasterProject: state.common.activeMasterProject,
  }));
  const [pagination, setPagination] = useState<{ [key: string]: number }>({
    page: PAGINATION.DEFAULT_START_PAGE,
    limit: PAGINATION.DEFAULT_PAGE_SIZE,
  });

  useEffect(() => {
    fetchProcedures({
      page: pagination.page,
      limit: pagination.limit,
    } as any);
  }, [activeMasterProject?.id]);

  const dispatch = useDispatch();
  const [FormInstance] = useForm();
  const { tableView } = useSelector(({ procedures }: IRootState) => procedures);

  const { procedures, fetching, meta } = useSelector(
    (state: IRootState) => state.procedures,
  );

  const fetchProcedures = (options?: IPagination) => {
    const { per_page, ...restOptions } = options || {
      projectIds:
        (activeMasterProject?.id && [activeMasterProject?.id]) || null,
    };

    let orderField, orderBy;
    if (typeof options?.orderBy === "string") {
      const [field, direction] = options.orderBy.split(" ");
      orderField = field;
      orderBy = direction;
    }
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      ...{
        projectIds:
          (activeMasterProject?.id && [activeMasterProject?.id]) || null,
      },
      ...restOptions,
      orderField,
      orderBy,
    };
    dispatch(getProcedures(params));
  };

  const columns = useProcedureColumn({ FormInstance });

  const openCreationDrawer = (
    isEdit: boolean = false,
    procedure?: IProcedure,
  ) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
      width: 1471,
      title: isEdit
        ? AlectifyText.UPDATE_PROCEDURE
        : AlectifyText.ADD_NEW_PROCEDURE,
      showFooter: false,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () => {
        fetchProcedures();
        FormInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAWER_PROCEDURE_CREATION,
        });
      },
      children: (
        <ProcedureCreateEdit
          FormInstance={FormInstance}
          isEdit={isEdit}
          procedure={procedure || null}
        />
      ),
    });
  };

  const handleSwitchView = (value: boolean) => {
    dispatch(setActiveTableView(value));
  };

  return (
    <Row
      justify={"start"}
      align={"middle"}
      className="alectify-procedure-container"
    >
      <Col span={24}>
        <Breadcrumb
          className=""
          items={[
            {
              title: "Home",
              href: `#`,
            },
            {
              title: AlectifyText.PROCEDURES_LIBRARY,
            },
          ]}
        />
      </Col>
      <Col span={24}>
        <div className="d-flex align-items-center">
          <Typography.Title level={4} className="mt-10">
            {AlectifyText.PROCEDURES_LIBRARY} {`(${meta.totalItems || 0})`}
          </Typography.Title>
          <ProcedureViewSwitcher
            onChange={handleSwitchView}
            tableView={tableView}
          />
        </div>
      </Col>
      <Col span={24} className="alectify-procedure-toolbar mt-10">
        {tableView ? (
          <AlectifyTable
            columns={columns}
            loading={fetching}
            dataSource={procedures}
            total={meta.totalItems || 0}
            showSearch
            customSearchKey="search"
            columnFilterAsString
            onDataFetch={fetchProcedures}
            scroll={{ x: 1320, y: "calc(100vh - 320px)" }}
            extraButton={
              <AlectifyButton
                name="procedure-create-new-btn"
                type="primary"
                text={AlectifyText.CREATE_PROCEDURE}
                onClick={() => openCreationDrawer(false)}
                icon={<PlusOutlined />}
              />
            }
          />
        ) : (
          <ProceduresListing
            FormInstance={FormInstance}
            procedures={procedures}
            meta={meta}
            fetching={fetching}
          />
        )}
      </Col>
    </Row>
  );
};
export default Procedures;
