import { memo, useState } from "react";
import UploadDocumentForm from "./UploadDocumentForm";
import "./AddDocument.scss";

interface ISelectedDocumentsProps {
  selectedDocuments: any[];
  setSelectedDocuments: (documents: any[]) => void;
  markDocumentsAsUploaded: (documents: any[]) => void;
  getDocuments?: () => void;
  documentForm: any;
  handleFilesUpload: (value: any) => void;
  removeSingleDocument: (value: string) => void;
}

const SelectedDocuments: React.FC<ISelectedDocumentsProps> = (
  props: ISelectedDocumentsProps,
) => {
  const [submitting, setSubmitting] = useState<boolean>(false);
  const { selectedDocuments, setSelectedDocuments, markDocumentsAsUploaded } =
    props;

  return (
    <>
      <div className="alectify-document-list">
        <div className="p-10">
          <UploadDocumentForm
            removeSingleDocument={props.removeSingleDocument}
            form={props.documentForm}
            submitting={submitting}
            setSubmitting={setSubmitting}
            documentsToUpload={selectedDocuments}
            markDocumentsAsUploaded={markDocumentsAsUploaded}
            getDocuments={props.getDocuments}
            selectedDocuments={selectedDocuments}
            setSelectedDocuments={setSelectedDocuments}
            handleFilesUpload={props.handleFilesUpload}
          />
        </div>
      </div>
    </>
  );
};

export default memo(SelectedDocuments);
