//libs
import { Col, Row, Space, Tooltip, Typography } from "antd";

//components
import { AlectifyDetailCardImage } from "components/shared/detail-card";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import ProcedureDetail from "components/shared/procedure-detail";
import StepsTag from "components/shared/tags/StepsTag";
import EditIcon from "components/icons/EditIcon";
import AlectifyDrawer from "components/drawer";

//interface
import { IProcedureCardProps } from "./ProceduresCard.interface";

//helpers
import { getProceduresTag } from "pages/procedures/Procedures.helpers";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyText from "static/texts.json";

//styles
import "./ProceduresCard.scss";
import { CloseOutlined } from "@ant-design/icons";
import { truncate } from "lodash";
import { displayDateTime, getFullUserName } from "utils/helpers";

const ProcedureCard: React.FC<IProcedureCardProps> = (
  props: IProcedureCardProps,
) => {
  const TAG = getProceduresTag(props.procedure.jobType);

  const openDetailDrawer = () => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
      title: truncate(props.procedure.name, {
        length: 20,
        omission: "...",
      }),
      width: 677,
      showFooter: true,
      cancelText: AlectifyText.CLOSE,
      readOnly: true,
      destroyOnClose: true,
      closeIcon: <CloseOutlined />,
      closable: true,
      onClose: () => {
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.PROCEDURE_DETAIL_DRAWER,
        });
      },
      children: (
        <ProcedureDetail
          procedure={props.procedure}
          readonly
          isDisabled={false}
        />
      ),
    });
  };

  return (
    <div className="procedure-card">
      <div className="header">
        <Row justify="space-between" gutter={4}>
          <Col span={22} className="cursor-pointer">
            <Space>
              <AlectifyDetailCardImage
                src={props?.procedure?.imageUrl || NO_IMAGE_AVATAR}
                className="procedure-card-img"
                showImage
              />
              <div onClick={openDetailDrawer}>
                <Typography.Title ellipsis={{ tooltip: true, rows: 1 }}>
                  <Tooltip title={props.procedure.name || ""}>
                    {truncate(props.procedure.name, {
                      omission: "...",
                    })}
                  </Tooltip>
                </Typography.Title>
                <Typography.Paragraph className="mt-5">
                  Ref #: <strong>{props.procedure.reference}</strong>
                </Typography.Paragraph>
              </div>
            </Space>
          </Col>
          <Col span={2}>
            <div className="draft-edit-icons">
              <div
                className="cursor-pointer"
                onClick={() => props.onEdit(true, props.procedure)}
              >
                <EditIcon />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="body cursor-pointer alectify-procedure-card-description"
        onClick={openDetailDrawer}
      >
        <Typography.Paragraph>
          {props.procedure.description}
        </Typography.Paragraph>
      </div>
      <div className="footer cursor-pointer" onClick={openDetailDrawer}>
        <TAG />
        <StepsTag count={props.procedure?.procedureSteps?.length} />
      </div>
      {props?.procedure?.createdBy ? (
        <div className="creator-info">
          <span>{getFullUserName(props?.procedure?.createdBy)}</span>
          <span>{displayDateTime(props.procedure.createdAt)}</span>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ProcedureCard;
