import { fetchRefreshToken } from "services/auth/auth.service";
import { getFromLocal, removeFromLocal, saveToLocal } from "./cache";
import { isTokenExpired } from "./helpers";
import { resetAllState } from "redux/rootReducer";
import { actions } from "redux/components/Auth";
import store from "redux/store/store";
import { message, notification } from "antd";

class TokenService {
  private static instance: TokenService;
  private isRefreshing = false;
  private refreshTokenPromise: Promise<string> | null = null;
  private pendingRequests: Array<(token: string) => void> = [];

  private constructor() {}

  static getInstance(): TokenService {
    if (!TokenService.instance) {
      TokenService.instance = new TokenService();
    }
    return TokenService.instance;
  }

  async refreshToken(): Promise<string> {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      const refreshToken = getFromLocal("refresh_token");

      if (!refreshToken || isTokenExpired(refreshToken)) {
        this.handleTokenRefreshFailure();
        return Promise.reject(new Error("No valid refresh token available"));
      }

      this.refreshTokenPromise = fetchRefreshToken({
        refresh_token: refreshToken,
      })
        .then((response) => {
          const newAccessToken = response.data?.access;
          const newRefreshToken = response.data?.refresh;

          if (newAccessToken && newRefreshToken) {
            saveToLocal(newAccessToken, "token");
            saveToLocal(newRefreshToken, "refresh_token");
            this.pendingRequests.forEach((callback) =>
              callback(newAccessToken),
            );
            this.pendingRequests = [];
            return newAccessToken;
          } else {
            throw new Error("Failed to refresh token");
          }
        })
        .catch((error) => {
          this.handleTokenRefreshFailure();
          return Promise.reject(error);
        })
        .finally(() => {
          this.isRefreshing = false;
          this.refreshTokenPromise = null;
        });

      return this.refreshTokenPromise;
    }

    return this.refreshTokenPromise as Promise<string>;
  }

  queueRequestForNewToken(callback: (token: string) => void) {
    this.pendingRequests.push(callback);
  }

  private handleTokenRefreshFailure() {
    removeFromLocal("token");
    removeFromLocal("refresh_token");
    store.dispatch(resetAllState() as any);
    store.dispatch(actions.logout());
    notification.info({
      message: "Session expired",
      description: "Your session has expired, please log in again.",
    });
    window.location.href = "/login";
    message.destroy();
  }
}

export const tokenService = TokenService.getInstance();
