import { memo } from "react";
interface ICheckMarkGroupIconProps {
  fill?: string | null;
}
const CheckMarkGroupIcon: React.FC<ICheckMarkGroupIconProps> = (
  props: ICheckMarkGroupIconProps,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="14"
      height="10"
      viewBox="0 0 14 10"
      fill="none"
    >
      <path
        d="M1 6.50839L2.73003 8.46785L9.40299 0.909912"
        stroke="#0D922A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5.4624 8.04799L6.32725 9.08992L13.0002 1.53198"
        stroke="#0D922A"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default memo(CheckMarkGroupIcon);
