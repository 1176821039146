import ReactApexChart from "react-apexcharts";
import { IDataRoundAnalyticsProps } from "./DataRoundAnalytics.interface";
import { useEffect, useState } from "react";
import {
  ICheckpoint,
  IDataRoundAnalytics,
  IDataRoundProject,
  IShiftSchedule,
} from "services/data-round/data-round.interface";
import {
  getAllCheckpoints,
  getDataRoundAnalytics,
  getDRProjectDetails,
} from "services/data-round/data-round.service";
import { useParams } from "react-router-dom";
import { Col, Row, Space, Spin, Typography, DatePicker } from "antd";
import AlectifySelect from "components/shared/select";
import { isEmpty } from "lodash";
import "./DataRoundAnalytics.scss";
import { RangePickerProps } from "antd/es/date-picker";

const { RangePicker } = DatePicker;
const DataRoundAnalytics: React.FC<IDataRoundAnalyticsProps> = (
  props: IDataRoundAnalyticsProps,
) => {
  const [loader, setLoading] = useState<boolean>(false);
  const [checkpoints, setCheckpoints] = useState<ICheckpoint[] | null>(null);
  const [analytics, setAnalytics] = useState<IDataRoundAnalytics[] | null>(
    null,
  );
  const [selectedCheckpoint, setSelectedCheckpoint] =
    useState<ICheckpoint | null>(null);
  const [dateRange, setDateRange] = useState<{
    start_date: Date | null | string;
    end_date: Date | null | string;
  }>();
  const [project, setProject] = useState<IDataRoundProject | null>(null);
  const [selectedShift, setSelectedShift] = useState<IShiftSchedule | null>(
    null,
  );

  const { projectId } = useParams();

  const chartOptions: any = {
    chart: {
      id: "realtime",
      height: 350,
      type: "line",
      animations: {
        enabled: false,
        easing: "linear",
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: [4, 4],
      curve: "smooth",
    },
    markers: {
      size: [5, 5],
      colors: ["#0500FF", "#FF3A29"],
      strokeWidth: 2,
      shape: "circle",
    },
    xaxis: {
      categories: analytics ? analytics.map((item) => item.shift_date) : [],
      title: {
        text: "Shift Dates",
      },
    },
    yaxis: {
      title: {
        text: "Readings",
      },
      labels: {
        formatter: (val: any) => `${val}`,
      },
    },
    legend: {
      position: "top",
    },
    colors: ["#0500FF", "#FF3A29"],
  };

  const chartSeries = [
    {
      name: "Readings",
      data: analytics
        ? analytics.map((item) => parseInt(item.reading_key, 10))
        : [],
    },
  ];

  const fetchAllCheckpoints = async () => {
    setLoading(true);
    try {
      const response = await getAllCheckpoints(projectId || "", {
        is_reading_required: true,
      });
      setLoading(false);
      if (response.success) {
        setCheckpoints(response.data);
      }
    } catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  };

  const fetchAnalytics = async () => {
    setLoading(true);
    try {
      const params: any = {
        checkpoint_ids:
          (selectedCheckpoint && selectedCheckpoint.checkpoint_id) || null,
        start_date: dateRange?.start_date || null,
        end_date: dateRange?.end_date || null,
        shift: selectedShift?.id || null,
      };
      const response = await getDataRoundAnalytics(projectId || "", params);
      setLoading(false);
      if (response.success) {
        setAnalytics(response.data);
      }
    } catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  };

  const getProjectDetails = async () => {
    setLoading(true);
    try {
      const response = await getDRProjectDetails(projectId || "");
      setLoading(false);
      if (response.success) {
        setProject(response.data);
      }
    } catch (ex) {
      setLoading(false);
      console.log(ex);
    }
  };

  const onCheckpointSelection = (value: string) => {
    if (!isEmpty(checkpoints)) {
      const checkpoint = checkpoints?.find(
        (cp: ICheckpoint) => cp.checkpoint_id === value,
      );
      if (!isEmpty(checkpoint)) {
        setSelectedCheckpoint(checkpoint);
      }
    }
  };

  const onShiftSelection = (value: string) => {
    if (!isEmpty(project?.shift_schedules)) {
      const shift = project?.shift_schedules?.find(
        (cp: IShiftSchedule) => cp.id === value,
      );
      if (!isEmpty(shift)) {
        setSelectedShift(shift);
      }
    }
  };
  const onDateChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    setDateRange({
      start_date: dateStrings[0],
      end_date: dateStrings[1],
    });
  };

  useEffect(() => {
    if (projectId) {
      Promise.all([
        fetchAllCheckpoints(),
        // fetchAnalytics(),
        getProjectDetails(),
      ]);
    }
  }, [projectId]);

  useEffect(() => {
    if (
      !isEmpty(selectedShift) ||
      !isEmpty(selectedCheckpoint) ||
      !isEmpty(dateRange)
    ) {
      fetchAnalytics();
    }
  }, [selectedShift, selectedCheckpoint, dateRange]);

  return (
    <Spin spinning={loader}>
      <Row
        justify={"start"}
        align={"top"}
        className="data-round-analytics-filter-container"
      >
        <Col span={24}>
          <Space size={40}>
            <Space align="start" direction="vertical">
              <Typography.Text>Checkpoint:</Typography.Text>
              <AlectifySelect
                placeholder="Select Checkpoint"
                name="checkpoint"
                className="data-round-checkpoint-selection"
                options={
                  (checkpoints &&
                    checkpoints.map((checkpoint: ICheckpoint) => ({
                      label: checkpoint.checkpoint_name,
                      key: checkpoint.checkpoint_id,
                      value: checkpoint.checkpoint_id,
                    }))) ||
                  []
                }
                onChange={(value: string) => onCheckpointSelection(value)}
              />
            </Space>
            <Space align="start" direction="vertical">
              <Typography.Text>Shift:</Typography.Text>
              <AlectifySelect
                className="data-round-shift-selection"
                placeholder="Select Shift"
                name="shift"
                options={
                  (project &&
                    project?.shift_schedules?.map((shift: IShiftSchedule) => ({
                      label: shift.name,
                      key: shift?.id,
                      value: shift.id,
                    }))) ||
                  []
                }
                onChange={(value: string) => onShiftSelection(value)}
              />
            </Space>
            <Space align="start" direction="vertical">
              <Typography.Text>Range:</Typography.Text>
              <RangePicker
                className="data-round-datepicker"
                onChange={onDateChange}
              />
            </Space>
          </Space>
        </Col>
      </Row>
      <Row justify={"center"} align={"middle"}>
        <Col span={24}>
          <Typography.Title level={4}>
            {selectedCheckpoint?.checkpoint_name || ""}
          </Typography.Title>
          <ReactApexChart
            options={chartOptions}
            series={chartSeries}
            type="line"
            height={350}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default DataRoundAnalytics;
