import React from "react";
import "./AlectifyEmptyState.scss";
interface IAlectifyEmptyState {
  description?: string;
  width?: number;
  height?: number;
}
const AlectifyEmptyState: React.FC<IAlectifyEmptyState> = ({
  description,
  width,
  height,
}) => {
  return (
    <div className="d-flex justify-content-center width-100 empty-state-container">
      <embed
        type="video/webm"
        src="https://lottie.host/embed/f0f5e925-83ce-47b7-b572-3772e9ab7a2a/KhpW3EVj9R.json"
        width={width || "400"}
        height={height || "300"}
      />
      {description && <p>{description}</p>}
    </div>
  );
};
export default AlectifyEmptyState;
