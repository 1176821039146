import React from "react";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import {
  IUseLicenseAndPermissionsProps,
  IUserBranchInfo,
} from "../Admin.interface";
import { EllipsisOutlined } from "@ant-design/icons";
import { Dropdown } from "antd";
import { actionItems } from "../CompanyBranchTable.rules";
import { UserTypeEnum } from "enums";
import AdminTag from "components/shared/tags/AdminTag";
import InternalTag from "components/shared/tags/InternalTag";
import ExternalTag from "components/shared/tags/ExternalTag";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import UpdateUserTypeModal from "../UpdateUserTypeModal";

const useLicenseAndPermissions = (props: IUseLicenseAndPermissionsProps) => {
  const closeUpdateUserTypeModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.USER_TYPE_MODAL,
    });
  };

  const openUpdateUserTypeModal = (record: IUserBranchInfo) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.USER_TYPE_MODAL,
      width: 800,
      footer: null,
      onCancel: closeUpdateUserTypeModal,
      children: (
        <UpdateUserTypeModal record={record} getUsers={props.getUsers} />
      ),
    });
  };

  const onEditPermission = (record: IUserBranchInfo) => {
    openUpdateUserTypeModal(record);
  };

  const onEditBranch = (record: IUserBranchInfo) => {
    props.openCreateBranchModal && props.openCreateBranchModal(record);
  };

  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.USER_NAME,
          dataIndex: "first_name",
          width: 100,
          visible: true,
          searchable: true,
          ellipsis: {
            showTitle: false,
          },
          render: (_: any, record: any) =>
            record.first_name + " " + record.last_name || "-",
        },
        {
          title: "Email",
          visible: true,
          width: 130,
          dataIndex: "email",
        },
        {
          title: AlectifyText.COMPANY_NAME,
          dataIndex: "branch",
          width: 120,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (branch: any) => branch?.company?.name || "-",
        },
        {
          title: AlectifyText.BRANCH,
          dataIndex: "branch",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (branch: any) => branch?.name || "-",
        },
        {
          title: AlectifyText.USER_ROLE,
          dataIndex: "user_type",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (user: number) => (
            <>
              {user === UserTypeEnum.ADMIN ? (
                <AdminTag />
              ) : user === UserTypeEnum.INTERNAL ? (
                <InternalTag />
              ) : (
                <ExternalTag />
              )}
            </>
          ),
        },
        {
          title: AlectifyText.ACTIONS,
          dataIndex: "",
          width: 70,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (_: any, record: any) => {
            return (
              <Dropdown
                menu={{
                  items: [
                    ...(actionItems({
                      onEditPermission,
                      onEditBranch,
                      record,
                    }) as any),
                  ],
                }}
              >
                <EllipsisOutlined
                  rotate={90}
                  className="alectify-action-icon"
                />
              </Dropdown>
            );
          },
        },
      ].filter((column) => column.visible),
    [],
  );
};

export default useLicenseAndPermissions;
