import { FormInstance } from "antd";
import { DocumentTypes } from "redux/components/documents";

export interface IEditDocumentProps {
  form: FormInstance;
  fileName: string;
  comment: string;
  version: string;
  type: DocumentTypes;
  documentId: string;
  subProjectId: string;
  editType?: DocumentEditTypes;
  successCb: () => void;
}
export enum DocumentEditTypes {
  FILENAME = "FILENAME",
  COMMENT = "COMMENT",
  VERSION = "VERSION",
}
