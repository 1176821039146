import React, { useState, useEffect, useCallback } from "react";
import TimelineBreadcrumbs from "pages/timeline/TimelineBreadcrumbs";
import { useNavigate, useParams } from "react-router-dom";
import { truncate } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import TimelineTabs from "pages/timeline/tabs/TimelineTabs";
import { ITimelineResponse } from "redux/components/common";
import AssetDetailHeader from "pages/asset-detail/AssetDetailHeader";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import { Space } from "antd";
import "../asset-detail/assetDetail.style.scss";
import { getAssetPackageDetail } from "redux/components/asset-packages/sources";
import { getAssetDetail } from "redux/components/assets/sources";
import BackArrowIcon from "components/icons/BackArrowIcon";

const Timeline: React.FC = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { equipmentId, subProjectId, equipmentType } = useParams();
  const { assetDetail } = useSelector(({ assets }: IRootState) => assets);
  const { assetPackageDetail } = useSelector(
    ({ assetPackage }: IRootState) => assetPackage,
  );
  const [timeline, setTimeline] = useState<ITimelineResponse>({
    data: [],
    fetching: false,
    meta: undefined,
  });
  const [timelinePage, setTimelinePage] = useState(1);

  const timelineDataFromAssetTimeline = (record: ITimelineResponse) => {
    setTimeline((prevTimeline) => {
      return {
        data: [...prevTimeline.data, ...record.data],
        fetching: false,
        meta: record?.meta,
      };
    });
  };

  const handleScroll = useCallback(() => {
    const container = document.querySelector(".asset-detail-main");

    if (container) {
      const { scrollTop, scrollHeight, clientHeight } = container;

      if (
        scrollTop + clientHeight >= scrollHeight - 2 &&
        timeline.meta?.totalItems !== undefined &&
        timeline.data.length < timeline.meta.totalItems &&
        !timeline.fetching
      ) {
        setTimelinePage((prevPage) => prevPage + 1);
      }
    }
  }, [timeline]);

  useEffect(() => {
    const container = document.querySelector(".asset-detail-main");
    if (container) {
      container.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (container) {
        container.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    if (equipmentType === "asset-package" && !assetPackageDetail.data) {
      dispatch(getAssetPackageDetail(subProjectId, equipmentId));
    } else if (equipmentType === "asset" && !assetDetail.data) {
      dispatch(getAssetDetail(equipmentId, subProjectId));
    }
  }, [equipmentId, subProjectId]);

  const getTitle = () => {
    if (equipmentType === "asset") {
      return truncate(assetDetail?.data?.name, { length: 50, omission: "..." });
    }
    if (equipmentType === "asset-package") {
      return truncate(assetPackageDetail?.data?.name, {
        length: 50,
        omission: "...",
      });
    }
    return null;
  };

  return (
    <div className="asset-detail-main">
      <div className="breadcrumbs-sticky">
        <TimelineBreadcrumbs
          equipmentId={equipmentId}
          subProjectId={subProjectId}
          equipmentType={equipmentType}
        />
      </div>
      <Space className="asset-heading header-sticky">
        <div className="cursor-pointer" onClick={() => navigate(-2)}>
          <BackArrowIcon />
        </div>
        <span className="asset-title">{getTitle()}</span>
        {equipmentType === "asset" ? <AssetTag /> : <AssetPackageTag />}
      </Space>
      <div className="detail">
        <AssetDetailHeader />
      </div>
      <TimelineTabs
        timelineCount={timeline.meta?.totalItems || 0}
        equipmentId={equipmentId || "-"}
        timelinePage={timelinePage}
        setTimelinePage={setTimelinePage}
        timelineDataFromAssetTimeline={timelineDataFromAssetTimeline}
      />
    </div>
  );
};

export default Timeline;
