import { Form } from "antd";
import { MESSAGES } from "constants/messages";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AlectifyInput from "../input";
import { IAddTeamsFormProps } from "./AddTeamsForm.interface";
import TeamMembersDropdown from "../team-members-dropdown";

const AddTeamsForm: React.FC<IAddTeamsFormProps> = (
  props: IAddTeamsFormProps,
) => {
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );

  return (
    <Form name="add-user-form" layout="vertical" form={props.form}>
      <AlectifyInput
        name="name"
        type="text"
        placeholder="Enter Team Name"
        rules={[
          {
            required: true,
            message: MESSAGES.FIELD_RULES.REQUIRED.replace(
              "{fieldName}",
              "name",
            ),
          },
        ]}
        label="Team Name"
      />
      <TeamMembersDropdown
        projectId={activeMasterProject?.id || ""}
        isRequired
      />

      <AlectifyInput
        name="description"
        type="text"
        placeholder="Enter description"
        rules={[
          {
            required: false,
            message: MESSAGES.FIELD_RULES.REQUIRED.replace(
              "{fieldName}",
              "description",
            ),
          },
        ]}
        label="Description"
        max={255}
      />
    </Form>
  );
};

export default AddTeamsForm;
