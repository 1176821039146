import { IReduxActionResponse } from "redux/interfaces";
import { ISubProjectResponse } from "../sub-project.interface";
import { subProjectInitialState } from "redux/components/sub-project/sub-project.initialState";

export const getSubProjects = (
  state: any,
  action: IReduxActionResponse<ISubProjectResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    data: payload?.data,
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const setSubProjectLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
  };
};

export const resetSubProjectState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...subProjectInitialState,
  };
};
