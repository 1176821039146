import { Space, Tag } from "antd";
import AlectifyText from "static/texts.json";
import NotRecurringIcon from "components/icons/NotRecurringIcon";

const NonRecurringTag: React.FC = () => {
  return (
    <Tag color={"#FEA982"} className="round-tag">
      <Space size={3} align="center">
        <NotRecurringIcon /> {AlectifyText.NOT_RECURRING}
      </Space>
    </Tag>
  );
};

export default NonRecurringTag;
