import { http, pmHttp } from "utils/client";
import { FILE_SHARE, SEND_INCIDENT_REPORT } from "./email-share.endpoints";
import {
  IAlectifyEmailComposerBody,
  IShareSubProjectBody,
} from "./email.interface";

/**
 * It takes two parameters:
 * @subProjectId An identifier for a specific project.
 * @body Data containing the files or information to be shared
 * @returns message documents shared is successful.
 * @throws An error if an error occurs during the request.
 */
export const shareSubProjectDocuments = async (
  subProjectId: string,
  body: IShareSubProjectBody,
) => {
  try {
    const url = FILE_SHARE.replace("{projectID}", subProjectId);
    const response = await http.post(url, body);
    return response?.data;
  } catch (error) {
    throw error;
  }
};

/**
 * @param {IAlectifyEmailComposerBody} body - body of an email to send.
 * @returns {Promise<any>} - Promise resolves to the response.
 * @throws An error if an error occurs during the request.
 */

export const sendIncidentReportEmail = async (
  body: IAlectifyEmailComposerBody,
): Promise<any> => {
  try {
    const response = await pmHttp.post(SEND_INCIDENT_REPORT, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
