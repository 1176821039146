import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./tasks.constants";
import { tasksInitialState } from "./tasks.initialState";
import { ITasksActions } from "./tasks.interface";
import * as TasksAffects from "./affects";
import * as TasksInterfaces from "services/tasks/tasks.interface";

export * from "./tasks.constants";
export * from "./tasks.interface";

const { actions, reducer } = createActionAndReducers<ITasksActions>(
  reducerName,
  tasksInitialState,
  TasksAffects,
);

export { actions, tasksInitialState, reducer, TasksInterfaces };
