import { memo, useEffect, useState } from "react";
import { IPreviewPdfProps } from "./PreviewPdf.interface";
import { getSignedUrl } from "services/documents/documents.service";
import { Spin, message } from "antd";
import { MESSAGES } from "constants/messages";

const PreviewPdf: React.FC<IPreviewPdfProps> = ({
  documentUrl,
  pageNumber = 1,
  zoom = 88,
}) => {
  const [signedUrl, setSignedUrl] = useState<string>();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const getUrl = async () => {
    try {
      if (documentUrl.includes("https")) {
        setSignedUrl(documentUrl);
      } else {
        setIsLoad(true);
        const response = await getSignedUrl(documentUrl);
        if (response.success) {
          const modifiedUrl = `${response.data}#page=${pageNumber}&zoom=${zoom}`;
          setSignedUrl(modifiedUrl);
          setIsLoad(false);
        } else {
          setIsLoad(false);
          message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
        }
      }
    } catch (error) {
      console.error("Error from signed URL:", error);
      setIsLoad(false);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    getUrl();
  }, [documentUrl, pageNumber]);

  return (
    <Spin spinning={isLoad}>
      <div style={{ height: "calc(100vh - 140px)" }}>
        <iframe
          src={signedUrl}
          height="100%"
          width="100%"
          title="PDF Document Preview"
        />
      </div>
    </Spin>
  );
};

export default memo(PreviewPdf);
