export const GET_DATA_ROUND_PROJECT =
  "/operator-rounds/masterprojects/{masterProjectId}/dr-projects/";
export const CREATE_DR_PROJECT = "/operator-rounds/dr-projects/";
export const UPDATE_DR_PROJECT = "/operator-rounds/dr-projects/{projectId}/";
export const GET_DR_PROJECT_DETAILS =
  "/operator-rounds/dr-projects/{projectId}/";
export const GET_ALL_GROUPS_OF_DR_PROJECT =
  "/operator-rounds/dr-projects/{projectId}/area/";
export const CREATE_NEW_GROUP =
  "/operator-rounds/dr-projects/{projectId}/area/";
export const UPDATE_GROUP =
  "/operator-rounds/dr-projects/{projectId}/area/{areaId}/";
export const GET_GROUP_AND_CHECKPOINT_LISTING =
  "/operator-rounds/dr-projects/{projectId}/area-checkpoint/";
export const CREATE_NEW_CHECKPOINT =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/";
export const UPDATE_CHECKPOINT =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/{checkpointId}/";
export const RESET_CHECKPOINT_GROUP_ORDER =
  "/operator-rounds/dr-projects/{projectId}/area-checkpoint/reset-order/";
export const DATA_ROUND_INSIGHTS =
  "/operator-rounds/dr-projects/{projectId}/round-insights/datewise/";
export const GET_ROUND_DETAILS =
  "/operator-rounds/dr-projects/{projectId}/round/{roundId}/insights/";
export const GET_DATA_ROUND_ANALYTICS =
  "/operator-rounds/dr-projects/{projectId}/round/checkpoint/reading-analytics/";
export const GET_ALL_CHECKPOINTS =
  "/operator-rounds/dr-projects/{projectId}/checkpoint/";
