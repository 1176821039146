import { createActionAndReducers } from "redux/utils/reducer.helper";
import * as ChatbotEffects from "./affects";
import { chatbotInitialState } from "./Chatbot.initialState";
import { reducerName } from "./Chatbot.constants";
import { IChatbotActions } from "./Chatbot.interface";

export * from "./Chatbot.constants";
export * from "./Chatbot.interface";

const { actions, reducer } = createActionAndReducers<IChatbotActions>(
  reducerName,
  chatbotInitialState,
  ChatbotEffects,
);

export { actions, chatbotInitialState, reducer };
