import { get } from "lodash";
import type { RcFile } from "antd/es/upload";

import { KEYS_TO_EXCLUDE, USER_TYPES } from "./profile.constants";
import { extractPhoneNumber } from "../phone-number/PhoneNumber.helper";

export const prepareFormData = (values: any, fileList: any) => {
  const formData = new FormData();
  const contactNumber = `${values.contact_number_prefix}${values.contact_number}`;
  const businessContactNumber = `${values.business_phone_number_prefix}${values.business_phone_number}`;
  if (values.contact_number) {
    formData.append("contact_number", contactNumber);
  }
  if (values.business_phone_number) {
    formData.append("business_phone_number", businessContactNumber);
  }
  if (fileList) {
    formData.append("image_url", fileList[0]?.originFileObj as Blob);
  }

  Object.entries(values).forEach(([key, value]) => {
    if (value && !KEYS_TO_EXCLUDE.includes(key)) {
      formData.append(key, value as Blob);
    }
  });

  return formData;
};

export function generateFormInitialValues(user: any | null) {
  if (!user) {
    return {};
  }

  return {
    first_name: get(user, "first_name", ""),
    last_name: get(user, "last_name", ""),
    organization_name: get(user, "organization.name", ""),
    personal_contact_type: get(USER_TYPES, user.user_type, ""),
    contact_number: get(user, "contact_number")
      ? extractPhoneNumber(get(user, "contact_number")).number
      : "",
    contact_number_prefix: get(user, "contact_number")
      ? extractPhoneNumber(get(user, "contact_number")).code
      : "",
    business_contact_type: get(USER_TYPES, user.user_type, ""),
    business_phone_number: get(user, "business_phone_number")
      ? extractPhoneNumber(get(user, "business_phone_number")).number
      : "",
    business_phone_number_prefix: get(user, "business_phone_number")
      ? extractPhoneNumber(get(user, "business_phone_number")).code
      : "",
    address: get(user, "address", ""),
    business_address: get(user, "business_address", ""),
  };
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
