import React, { useEffect, useState } from "react";
import { Button, Dropdown, Space } from "antd";
import { DatePicker } from "antd";
import dayjs from "dayjs";
import {
  DateRange,
  IMaintenancePredictionDatePickerProps,
} from "./MaintenancePrediction.interface";
import AlectifyText from "static/texts.json";
import type { MenuProps } from "antd";
import { CalendarOutlined } from "@ant-design/icons";
import { RangePickerProps } from "antd/es/date-picker";
import { SERVER_DATE_FORMAT } from "constants/index";
import { formatDate } from "utils/helpers";
import "./MaintenancePrediction.scss";

const MaintenancePredictionDatePicker: React.FC<
  IMaintenancePredictionDatePickerProps
> = ({
  getPredictions,
  getDateRangeFromChild,
  setShowMaintenanceDetail,
  disableDateDisplay = false,
  disableDatePicker = false,
}) => {
  const getDefaultDateRange = (disableDateDisplay: boolean): DateRange => {
    const currentDate = dayjs();
    const fromDate = disableDateDisplay
      ? currentDate.subtract(30, "day")
      : currentDate;
    const toDate = currentDate.add(30, "day");

    return {
      from_date: formatDate(fromDate, SERVER_DATE_FORMAT),
      to_date: formatDate(toDate, SERVER_DATE_FORMAT),
    };
  };

  const [dateRange, setDateRange] = useState<DateRange>(
    getDefaultDateRange(disableDateDisplay),
  );

  const [type, setType] = useState<any>(AlectifyText.NEXT_30);
  const [rangePickerVisible, setRangePickerVisible] = useState(false);

  const { RangePicker } = DatePicker;

  useEffect(() => {
    getDateRangeFromChild && getDateRangeFromChild(dateRange);
    const params = {
      ...dateRange,
    };
    getPredictions(params);
  }, [dateRange]);

  const handleMenuClick: MenuProps["onClick"] = ({ key }) => {
    setType(key);
    const currentDate = new Date();
    switch (key) {
      case AlectifyText.NEXT_30:
        const fromDate = formatDate(
          dayjs(currentDate).subtract(30, "day"),
          SERVER_DATE_FORMAT,
        );
        const toDate = formatDate(
          dayjs(currentDate).add(30, "day"),
          SERVER_DATE_FORMAT,
        );

        setDateRange({
          from_date: fromDate,
          to_date: toDate,
        });

        if (disableDateDisplay) {
          setShowMaintenanceDetail(true);
          setRangePickerVisible(false);
        } else {
          setShowMaintenanceDetail(false);
          setRangePickerVisible(false);
        }
        break;

      case AlectifyText.LAST_MONTH:
        const lastMonthStartDate = formatDate(
          dayjs(
            new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1),
          ),
          SERVER_DATE_FORMAT,
        );
        setDateRange({
          from_date: lastMonthStartDate,
          to_date: formatDate(
            dayjs(
              new Date(currentDate.getFullYear(), currentDate.getMonth(), 0),
            ),
            SERVER_DATE_FORMAT,
          ),
        });
        if (disableDateDisplay) {
          setShowMaintenanceDetail(true);
          setRangePickerVisible(false);
        } else {
          setShowMaintenanceDetail(false);
          setRangePickerVisible(false);
        }

        break;

      case AlectifyText.CUSTOM_RANGE:
        if (disableDateDisplay) {
          setShowMaintenanceDetail(true);
        } else {
          setShowMaintenanceDetail(false);
        }
        break;
      default:
        setDateRange({
          from_date: formatDate(
            dayjs(
              new Date(currentDate.getFullYear(), currentDate.getMonth(), 1),
            ),
            SERVER_DATE_FORMAT,
          ),
          to_date: formatDate(
            dayjs(
              new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
              ),
            ),
            SERVER_DATE_FORMAT,
          ),
        });
    }
  };

  const onDateChange: RangePickerProps["onChange"] = (dates, dateStrings) => {
    setDateRange({
      from_date: dateStrings[0],
      to_date: dateStrings[1],
    });
    setRangePickerVisible(false);
  };

  const items: MenuProps["items"] = [
    {
      label: AlectifyText.NEXT_30,
      key: AlectifyText.NEXT_30,
    },

    {
      label: AlectifyText.LAST_MONTH,
      key: AlectifyText.LAST_MONTH,
    },
    {
      label: (
        <div className="date-range-container">
          <RangePicker
            value={null}
            className="range-picker-container"
            onChange={onDateChange}
            placeholder={["Custom", "Range"]}
          />
        </div>
      ),
      key: AlectifyText.CUSTOM_RANGE,
    },
  ];

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="date-ranger-maintenance-picker">
      <div className="date-display-text">
        <>
          {!disableDateDisplay && (
            <>
              <span>From </span>
              {formatDate(dayjs(dateRange?.from_date))}
              <span> To </span>
              {formatDate(dayjs(dateRange?.to_date))}
            </>
          )}
        </>
      </div>

      {!disableDatePicker ? (
        <Dropdown
          menu={menuProps}
          open={rangePickerVisible}
          onOpenChange={(visible) =>
            setRangePickerVisible(visible || rangePickerVisible)
          }
        >
          <Button>
            <Space>
              {type}
              <CalendarOutlined />
            </Space>
          </Button>
        </Dropdown>
      ) : null}
    </div>
  );
};

export default MaintenancePredictionDatePicker;
