//libs
import { ColumnGroupType } from "antd/es/table";
import { ColumnProps } from "antd/lib/table";

//components
import CheckIcon from "components/icons/CheckIcon";
import CrossIcon from "components/icons/CrossIcon";
import AlectifyButton from "components/shared/button";
import StepsTag from "components/shared/tags/StepsTag";
import { getProceduresTag } from "pages/procedures/Procedures.helpers";
import { IProcedureTableProps } from "../ProcedureTable.interface";

//constants
import AlectifyText from "static/texts.json";

const ProcedureColumns = (props: IProcedureTableProps) => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      width: 100,
      dataIndex: "reference",
      title: AlectifyText.REFERENCE_NO,
      ellipsis: true,
    },
    {
      width: 200,
      dataIndex: "name",
      title: "Name",
      ellipsis: true,
      searchable: true,
    },
    {
      width: 200,
      dataIndex: "description",
      title: AlectifyText.DESCRIPTION,
      ellipsis: true,
    },
    {
      width: 150,
      dataIndex: "procedureCategory",
      title: AlectifyText.CATEGORY,
      render: (value) => {
        return value.name;
      },
    },
    {
      width: 250,
      dataIndex: "jobType",
      title: AlectifyText.JOB_TYPE,
      render: (value) => {
        const Tag = getProceduresTag(value);
        return <Tag />;
      },
    },
    {
      width: 120,
      dataIndex: "procedureSteps",
      title: AlectifyText.STEPS,
      render: (value) => {
        return <StepsTag count={value.length} />;
      },
    },
    {
      width: 100,
      dataIndex: "comments",
      title: AlectifyText.COMMENTS,
      render: (value) => {
        return value ? <CheckIcon /> : <CrossIcon />;
      },
    },
    {
      width: 100,
      dataIndex: "fileUpload",
      title: AlectifyText.FILE,
      render: (value) => {
        return value ? <CheckIcon /> : <CrossIcon />;
      },
    },
    {
      width: 100,
      dataIndex: "",
      title: AlectifyText.ACTIONS,
      render: (_, record) => {
        return (
          <AlectifyButton
            name="import"
            text={AlectifyText.IMPORT}
            type="primary"
            onClick={() => props.onRowSelection && props.onRowSelection(record)}
            ghost
          />
        );
      },
    },
  ];
  return columns;
};

export default ProcedureColumns;
