const CleanupIcon: React.FC = () => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 27992">
        <path
          id="Vector"
          d="M5.79999 2.6C5.79999 1.71634 6.51631 1 7.39999 1H8.19999C9.08367 1 9.79999 1.71634 9.79999 2.6V4.64269C9.79999 5.69282 10.4827 6.62101 11.4852 6.93377L12.1148 7.13022C13.1173 7.44296 13.8 8.3712 13.8 9.42128V10.6C13.8 11.0418 13.4418 11.4 13 11.4H2.59999C2.15816 11.4 1.79999 11.0418 1.79999 10.6V9.42128C1.79999 8.3712 2.4827 7.44296 3.48517 7.13022L4.1148 6.93377C5.11727 6.62101 5.79999 5.69282 5.79999 4.64269V2.6Z"
          stroke="white"
        />
        <path
          id="Vector_2"
          d="M2.60174 11.3999C2.72638 12.4465 2.19656 15.0105 1 16.8942C1 16.8942 9.23392 17.8474 10.3481 13.7546V15.2969C10.3481 16.0499 10.3481 16.4264 10.5826 16.6603C11.0337 17.1101 13.1513 17.1224 13.603 16.6416C13.8403 16.3889 13.8166 16.0254 13.7689 15.2984C13.6903 14.0996 13.4746 12.5645 12.8818 11.3999"
          stroke="white"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default CleanupIcon;
