import { memo } from "react";
import { Col, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import { ISubProject } from "redux/components/sub-project";
import { setActiveSubProject } from "redux/components/common/sources";
import SubProjectCard from "components/shared/custom-cards/sub-project-card/SubProjectCard";
import { ISubProjectListingProps } from "./SubProjectListing.interface";
import "./SubProjectListing.scss";
import { isEmpty } from "lodash";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";

const SubProjectListing: React.FC<ISubProjectListingProps> = (
  props: ISubProjectListingProps,
) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const { fetching } = useSelector(({ subProject }: IRootState) => subProject);

  const onClickSubProject = (subProject: ISubProject) => {
    dispatch(setActiveSubProject(subProject));
    navigate(
      `${ROUTES.MASTER_PROJECT_DETAILS}/${activeMasterProject?.id}${ROUTES.SUB_PROJECT}/${subProject.id}`,
    );
  };

  return (
    <Spin spinning={fetching}>
      {!isEmpty(props?.subProjects) ? (
        <Row gutter={20}>
          {props.subProjects.map((subProject) => (
            <Col span={6} key={subProject.id}>
              <SubProjectCard
                subProject={subProject}
                onClick={() => onClickSubProject(subProject)}
                showEditButton
              />
            </Col>
          ))}
        </Row>
      ) : (
        <AlectifyEmptyState />
      )}
    </Spin>
  );
};

SubProjectListing.defaultProps = {
  subProjects: [],
};

export default memo(SubProjectListing);
