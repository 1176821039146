//libs
import { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
//internal
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import PmDetail from "components/shared/pm-detail";
// import PmExternalTable from "components/shared/pm-external-table";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { fetchExternalPMDetails } from "services/pm-external/pm-external.service";
import TasksTable from "components/shared/tasks-table";
import { PM_TYPES } from "redux/components/pm-external";

const PmExternal: React.FC = () => {
  const location = useLocation();
  const pmId = new URLSearchParams(location.search).get("pmId");

  const { masterProjectId, subProjectId } = useParams();

  const openDetailDrawer = async (id: string) => {
    const response = await fetchExternalPMDetails(id);
    if (response.status) {
      DrawerServiceInstance.open(AlectifyDrawer, {
        width: 420,
        title: response?.data?.workTitle,
        name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
        onClose: () =>
          DrawerServiceInstance.close(AlectifyDrawer, {
            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
          }),
        children: <PmDetail prevMaintenanceDetail={response.data} />,
      });
    }
  };

  useEffect(() => {
    if (pmId) {
      openDetailDrawer(pmId);
    }
  }, []);

  return (
    <TasksTable
      allowCreation
      subProjectId={subProjectId}
      masterProjectId={masterProjectId}
      scroll={{ x: 1320, y: "calc(100vh - 350px)" }}
      type={PM_TYPES.PM_EXTERNAL}
      // myWorkOrder={false}
    />
  );
};

export default PmExternal;
