import { useEffect } from "react";
import AlectifyTable from "../table";
import useProcedureColumns from "./effects/useProcedureColumns";
import { useDispatch, useSelector } from "react-redux";
import { getProcedures } from "redux/components/procedures/sources";
import { IRootState } from "redux/rootReducer";
import "./ProcedureTable.scss";
import { IProcedureTableProps } from "./ProcedureTable.interface";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";

const ProcedureTable: React.FC<IProcedureTableProps> = (
  props: IProcedureTableProps,
) => {
  const { procedures, fetching, meta } = useSelector(
    (state: IRootState) => state.procedures,
  );
  const dispatch = useDispatch();
  const columns = useProcedureColumns({
    onRowSelection: props.onRowSelection,
    projectId: props.projectId,
  });

  const getAllProcedures = (options?: IPagination) => {
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      projectIds: (props.projectId && [props.projectId]) || null,
      ...options,
    };

    dispatch(getProcedures(params));
  };

  useEffect(() => {
    getAllProcedures();
  }, []);

  return (
    <AlectifyTable
      size="small"
      loading={fetching}
      dataSource={procedures}
      scroll={{ x: 400 }}
      columns={columns}
      total={meta?.totalItems}
      onDataFetch={getAllProcedures}
      showSearch
      noIcontainsSearch
      customSearchKey="search"
    />
  );
};

export default ProcedureTable;
