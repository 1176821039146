import { Space, Tag } from "antd";
import { DangerIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const DangerTag: React.FC<{ text?: string; color?: string }> = ({
  text,
  color,
}) => {
  return (
    <Tag color={color || "#D92323"} className="round-tag">
      <Space size={3}>
        <DangerIcon /> {text || AlectifyText.CRITICAL}
      </Space>
    </Tag>
  );
};

export default DangerTag;
