import { isArray } from "lodash";
import { Component } from "react";
import DrawerService from "../DrawerService";
import { IDrawerParams } from "../DrawerService.interface";

type DrawerRootState = {
  drawers: IDrawerParams[];
  loading: boolean;
};

class DrawerRoot extends Component<any, DrawerRootState> {
  state: DrawerRootState = {
    drawers: [],
    loading: false,
  };

  componentDidMount() {
    DrawerService.on("openDrawer", (params: IDrawerParams) => {
      if (params) {
        this.updateDrawers(params);
      }
    });

    DrawerService.on("closeDrawer", (params: IDrawerParams) => {
      this.setState({
        drawers: this.state.drawers.filter((drawer: IDrawerParams) => {
          if (isArray(params.props.name)) {
            return !params.props.name.includes(drawer.props.name);
          }
          return drawer.props.name !== params.props.name;
        }),
      });
    });

    DrawerService.on("forceCloseAll", (params: IDrawerParams) => {
      this.setState({
        drawers: [],
      });
    });

    DrawerService.on("showLoader", (params: IDrawerParams) => {
      // const { component } = params;
      this.setState({
        ...this.state,
        loading: true,
      });
    });

    DrawerService.on("hideLoader", (params: IDrawerParams) => {
      // const { component } = params;
      this.setState({
        ...this.state,
        loading: false,
      });
    });

    document.addEventListener("updateDrawerProps", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { name, props } = customEvent.detail;
      this.setState((prevState: any) => ({
        drawers: prevState.drawers.map((drawer: any) =>
          drawer.props.name === name ? { ...drawer, props } : drawer,
        ),
      }));
    });
  }

  drawerClose = function (drawer: IDrawerParams) {
    return function () {
      if (drawer.props && drawer.props.onClose) {
        drawer.props.onClose.apply(null, arguments);
      }
      DrawerService.close(drawer.component);
    };
  };

  updateDrawers = (params: IDrawerParams) => {
    const { component, props } = params;
    const name = `${component.name}`;
    this.setState({
      drawers: [
        ...this.state.drawers,
        {
          name,
          component,
          props,
        },
      ],
    });
  };

  render() {
    const drawersToShow = this.state.drawers;

    return (
      <>
        {drawersToShow.length ? (
          <section>
            {drawersToShow.map((draw: IDrawerParams) => {
              const DrawerComponent = draw.component;
              return (
                <DrawerComponent
                  key={draw.name}
                  {...draw.props}
                  open={true}
                  closeDrawerHandler={this.drawerClose(draw)}
                  loading={this.state.loading}
                />
              );
            })}
          </section>
        ) : null}
      </>
    );
  }
}

export default DrawerRoot;
