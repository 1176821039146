import React from "react";
import { Typography } from "antd";
import { WarningOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import StampCheckIcon from "components/icons/StampCheckIcon";
import { DONE_TASK_GREEN_COLOR } from "../phone-number/constants";
import "./ProcedureDetail.scss";

interface ChecklistStatusProps {
  completedSteps: number;
  totalSteps: number;
}

const ChecklistStatus: React.FC<ChecklistStatusProps> = ({
  completedSteps,
  totalSteps,
}) => {
  return (
    <div className="checklist-container">
      <Typography.Text>{AlectifyText.PROCEDURE_CHECKLIST}</Typography.Text>
      <div
        className={`checklist-status-container ${
          completedSteps < totalSteps ? "warning-review" : "completed-review"
        }`}
      >
        <Typography.Text
          className={`checklist-status-text ${
            completedSteps < totalSteps
              ? "warning-review-text"
              : "completed-review-text"
          }`}
        >
          {completedSteps} / {totalSteps} steps completed
        </Typography.Text>
        {completedSteps < totalSteps ? (
          <div className="checklist-warning-icon">
            <WarningOutlined />
          </div>
        ) : (
          <div className="d-flex">
            <StampCheckIcon fill={DONE_TASK_GREEN_COLOR} />
          </div>
        )}
      </div>
    </div>
  );
};

export default ChecklistStatus;
