const NotRecurringIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M12.7098 16.7348C12.4847 16.7706 12.2492 16.6534 12.1507 16.43C12.032 16.1676 12.151 15.8604 12.4187 15.7405C15.0716 14.5662 16.6007 11.7355 16.1425 8.85543C15.5861 5.35818 12.3024 2.96275 8.82329 3.51623C5.86949 3.98615 4.20812 6.02927 3.46774 7.22279L5.47333 6.90372C5.75302 6.85923 6.02205 7.05548 6.06678 7.33663C6.11151 7.61778 5.92457 7.8936 5.63806 7.93919L2.61604 8.41995C2.58193 8.42538 2.51944 8.42829 2.46951 8.42217C2.40484 8.41137 2.3459 8.39262 2.2927 8.36593C2.22475 8.33455 2.16827 8.28729 2.12435 8.231C2.08042 8.17471 2.04113 8.10362 2.02231 8.02927C2.01904 8.0087 2.01686 7.99499 2.01358 7.97442L1.5183 4.86117C1.47358 4.58002 1.66842 4.30997 1.94811 4.26548C2.2278 4.22098 2.49683 4.41723 2.54156 4.69838L2.80229 6.33729C3.72332 4.96036 5.55686 2.98827 8.66074 2.49448C12.706 1.85093 16.5199 4.63308 17.1668 8.6995C17.6992 12.0459 15.9218 15.3379 12.8372 16.7005C12.7974 16.7138 12.7507 16.7283 12.7098 16.7348Z"
        fill="white"
      />
      <path
        d="M2.32739 2.32739L17.3274 17.3274"
        stroke="white"
        strokeLinecap="round"
      />
      <path
        d="M10.4843 17.2422C10.4707 17.2443 10.456 17.2397 10.4492 17.2408C9.70768 17.3101 8.96137 17.262 8.23967 17.1058C8.03071 17.0626 7.86351 16.8876 7.83687 16.6764C7.82716 16.6154 7.83105 16.5522 7.84281 16.4947C7.90378 16.2209 8.18913 16.0434 8.45569 16.0983C9.08365 16.2348 9.72369 16.2719 10.3565 16.2199C10.6237 16.1913 10.8711 16.3883 10.9142 16.6594L10.9117 16.6876C10.9402 16.9542 10.7494 17.2 10.4843 17.2422ZM6.5076 16.3805C6.39206 16.3988 6.27685 16.3755 6.17091 16.3228C5.52667 15.9527 4.93854 15.4971 4.43585 14.9654C4.36066 14.887 4.31158 14.7975 4.2954 14.6959C4.26844 14.5264 4.32519 14.3575 4.45282 14.2399C4.6523 14.0483 4.98823 14.0574 5.17906 14.2495C5.18014 14.2562 5.18014 14.2562 5.18014 14.2562C5.18802 14.2619 5.19697 14.2744 5.20484 14.2801C5.63914 14.7323 6.13958 15.1184 6.68252 15.4212C6.81315 15.4978 6.91056 15.6282 6.93537 15.7842C6.9537 15.8994 6.93805 16.02 6.87839 16.1268C6.79586 16.2651 6.66392 16.3556 6.5076 16.3805ZM3.7113 13.4334C3.48702 13.4691 3.26727 13.3581 3.17267 13.1577C2.8441 12.494 2.61997 11.786 2.50349 11.0538L2.50241 11.0471C2.46499 10.768 2.65369 10.5086 2.93234 10.4643C3.21099 10.42 3.47874 10.6137 3.52296 10.8917C3.62433 11.5289 3.8187 12.1374 4.09603 12.698C4.11825 12.7501 4.1326 12.7964 4.14123 12.8507C4.17574 13.0676 4.06429 13.2869 3.85759 13.3893C3.81217 13.4104 3.76567 13.4248 3.7113 13.4334Z"
        fill="white"
      />
    </svg>
  );
};

export default NotRecurringIcon;
