import { memo } from "react";

interface ICalendarDateIcon {
  fill?: string;
}
const CalendarDateIcon: React.FC<ICalendarDateIcon> = ({ fill }) => {
  return (
    <svg
      className="CalendarDateIcon"
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        d="M5.94643 10.125H4.33929C4.07411 10.125 3.85714 9.93516 3.85714 9.70312V8.29688C3.85714 8.06484 4.07411 7.875 4.33929 7.875H5.94643C6.21161 7.875 6.42857 8.06484 6.42857 8.29688V9.70312C6.42857 9.93516 6.21161 10.125 5.94643 10.125ZM10.2857 9.70312V8.29688C10.2857 8.06484 10.0687 7.875 9.80357 7.875H8.19643C7.93125 7.875 7.71429 8.06484 7.71429 8.29688V9.70312C7.71429 9.93516 7.93125 10.125 8.19643 10.125H9.80357C10.0687 10.125 10.2857 9.93516 10.2857 9.70312ZM14.1429 9.70312V8.29688C14.1429 8.06484 13.9259 7.875 13.6607 7.875H12.0536C11.7884 7.875 11.5714 8.06484 11.5714 8.29688V9.70312C11.5714 9.93516 11.7884 10.125 12.0536 10.125H13.6607C13.9259 10.125 14.1429 9.93516 14.1429 9.70312ZM10.2857 13.0781V11.6719C10.2857 11.4398 10.0687 11.25 9.80357 11.25H8.19643C7.93125 11.25 7.71429 11.4398 7.71429 11.6719V13.0781C7.71429 13.3102 7.93125 13.5 8.19643 13.5H9.80357C10.0687 13.5 10.2857 13.3102 10.2857 13.0781ZM6.42857 13.0781V11.6719C6.42857 11.4398 6.21161 11.25 5.94643 11.25H4.33929C4.07411 11.25 3.85714 11.4398 3.85714 11.6719V13.0781C3.85714 13.3102 4.07411 13.5 4.33929 13.5H5.94643C6.21161 13.5 6.42857 13.3102 6.42857 13.0781ZM14.1429 13.0781V11.6719C14.1429 11.4398 13.9259 11.25 13.6607 11.25H12.0536C11.7884 11.25 11.5714 11.4398 11.5714 11.6719V13.0781C11.5714 13.3102 11.7884 13.5 12.0536 13.5H13.6607C13.9259 13.5 14.1429 13.3102 14.1429 13.0781ZM18 3.9375V16.3125C18 17.2441 17.1362 18 16.0714 18H1.92857C0.863839 18 0 17.2441 0 16.3125V3.9375C0 3.00586 0.863839 2.25 1.92857 2.25H3.85714V0.421875C3.85714 0.189844 4.07411 0 4.33929 0H5.94643C6.21161 0 6.42857 0.189844 6.42857 0.421875V2.25H11.5714V0.421875C11.5714 0.189844 11.7884 0 12.0536 0H13.6607C13.9259 0 14.1429 0.189844 14.1429 0.421875V2.25H16.0714C17.1362 2.25 18 3.00586 18 3.9375ZM16.0714 16.1016V5.625H1.92857V16.1016C1.92857 16.2176 2.03705 16.3125 2.16964 16.3125H15.8304C15.9629 16.3125 16.0714 16.2176 16.0714 16.1016Z"
        fill={fill || "#84919A"}
      />
    </svg>
  );
};

export default memo(CalendarDateIcon);
