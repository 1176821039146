import React, { memo } from "react";
import { IMaintenancePredictionCardsProps } from "./MaintenancePrediction.interface";
import "./MaintenancePrediction.scss";

const MaintenancePredictionCards: React.FC<IMaintenancePredictionCardsProps> = (
  props: IMaintenancePredictionCardsProps,
) => {
  return (
    <>
      <div className={props.className}>
        <p className="text-style">{props.text}</p>
        <p className="text-value-style">{props.value}</p>
      </div>
    </>
  );
};

export default memo(MaintenancePredictionCards);
