import { memo } from "react";
import { Typography } from "antd";
import { IProjectSparePart } from "redux/components/spare-parts";
import AlectifyTable from "../table";
import useSimilarPartsColumns from "./effects/useSimilarPartsColumns";
import "./SimilarSpareParts.scss";
import { isEmpty } from "lodash";

interface ISimilarSparePartsProps {
  similarParts: IProjectSparePart[];
  masterProjectId: string;
  loading?: boolean;
}

const SimilarSpareParts: React.FC<ISimilarSparePartsProps> = (
  props: ISimilarSparePartsProps,
) => {
  const columns = useSimilarPartsColumns({
    masterProjectId: props.masterProjectId,
  });

  return (
    <>
      {!isEmpty(props.similarParts) && (
        <Typography.Text className="similar-parts-heading">
          This part is also available in below Master Projects.
        </Typography.Text>
      )}
      <AlectifyTable
        columns={columns}
        dataSource={props.similarParts}
        loading={props.loading}
        onDataFetch={() => {}}
        total={props.similarParts.length}
      />
    </>
  );
};

export default memo(SimilarSpareParts);
