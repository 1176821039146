import { memo } from "react";
import { Avatar } from "antd";
import "./AlectifyDetailCardImage.scss";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import ViewImage from "../view-image/ViewImage";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyText from "static/texts.json";
interface IAlectifyDetailCardImageProps {
  src: string;
  className?: string;
  showImage?: boolean;
}

const AlectifyDetailCardImage: React.FC<IAlectifyDetailCardImageProps> = (
  props: IAlectifyDetailCardImageProps,
) => {
  const handleClick = (event: any) => {
    event.stopPropagation();
    if (props.showImage) {
      ModalServiceInstance.open(AlectifyModal, {
        name: MODAL_NAMES.VIEW_IMAGE_MODAL,
        title: AlectifyText.VIEW_IMAGE,
        children: <ViewImage imageUrl={props.src} />,
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.VIEW_IMAGE_MODAL,
          });
        },
        footer: null,
      });
    }
  };
  return (
    <div className={`alectify-detail-card-image ${props.className}`}>
      <Avatar
        src={props.src}
        className={props.showImage ? "cursor-pointer" : ""}
        onClick={handleClick}
      />
    </div>
  );
};

AlectifyDetailCardImage.defaultProps = {
  src: "",
  className: "",
  showImage: false,
};

export default memo(AlectifyDetailCardImage);
