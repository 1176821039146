export const chatbotInitialState: any = {
  toggle: false,
  fetching: false,
  chatbotItems: [],
  aiProcedureItems: {
    toggle: false,
    fetching: false,
    data: [],
  },
};
