import { Space, Tag } from "antd";
import { CircleCheckIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const CompletedTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#3CAC15"} className="round-tag">
      <Space size={3}>
        <CircleCheckIcon /> {text || AlectifyText.COMPLETED}
      </Space>
    </Tag>
  );
};

export default CompletedTag;
