import { http } from "utils/client";
import { IParams } from "redux/interfaces";
import { AxiosResponse } from "axios";
import {
  CREATE_SUB_PROJECT,
  GET_SUB_PROJECTS,
  SUB_PROJECT_DETAIL_TYPE,
} from "./sub-project.endpoints";
import { ISubProjectResponse } from "redux/components/sub-project/sub-project.interface";
import {
  ICreateNewSubProjectBody,
  IDraftPayload,
} from "./subProject.interface";
import { IAssetCategoryDetailResponse } from "components/shared/user-access/IUserAccess.interface";

/**
 * Fetches subprojects for a given master project ID.
 *
 * @param masterProjectId - The ID of the master project to fetch subprojects for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the subprojects data.
 * @throws If an error occurs during the request.
 */
export const fetchSubProjects = async (
  masterProjectId: string,
  params?: IParams, // Make params optional
): Promise<ISubProjectResponse> => {
  try {
    const url = GET_SUB_PROJECTS.replace("{masterProjectId}", masterProjectId);
    const response: AxiosResponse<ISubProjectResponse> = await http.get(url, {
      ...params,
      version: 2,
    });
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Creates a new sub-project.
 *
 * @param {ICreateNewSubProjectBody} body - The request body containing data for the new sub-project.
 * @returns {Promise<any>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */

export const createNewSubProject = async (body: ICreateNewSubProjectBody) => {
  try {
    const response = await http.post(CREATE_SUB_PROJECT, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Update a sub-project for a given project.
 *
 * @param {string} projectId - The ID of the project.
 * @param {ICreateNewSubProjectBody} body - The request body for updating the sub-project.
 * @param {string} type - The type of the sub-project is string.
 * @returns {Promise<any>} - A Promise that resolves with the updated sub-project data.
 * @throws {Error} - Throws an error if the update fails.
 */
export const updateSubProject = async (
  projectId: string,
  body: ICreateNewSubProjectBody,
  type: string,
) => {
  try {
    const response = await http.patch(
      SUB_PROJECT_DETAIL_TYPE.replace("{projectId}", projectId).replace(
        "{api_type}",
        type,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetch details of a sub-project for a given project.
 *
 * @param {string} projectId and type - The ID and type of the project for which sub-project details are fetched.
 * @returns {Promise<any>} - A Promise that resolves with the details of the sub-project.
 * @throws {Error} - Throws an error if the fetching fails.
 */
export const fetchSubProjectDetail = async (
  projectId: string,
  type: string,
): Promise<IAssetCategoryDetailResponse> => {
  try {
    const response = await http.get(
      SUB_PROJECT_DETAIL_TYPE.replace("{projectId}", projectId).replace(
        "{api_type}",
        type,
      ),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Updates the draft completion status for a subProject.
 *
 * @param {string} type - The type of subProject.
 * @param {string} projectId - The ID of the project.
 * @param {IDraftPayload} payload - The payload containing draft information.
 * @returns {Promise<any>} - A promise that resolves with the response data.
 * @throws {Error} - Throws an error if the request fails.
 */
export const subProjectDraftCompleted = async (
  type: string,
  projectId: string,
  payload: IDraftPayload,
) => {
  try {
    const response = await http.patch(
      SUB_PROJECT_DETAIL_TYPE.replace("{projectId}", projectId).replace(
        "{api_type}",
        type,
      ),
      payload,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
