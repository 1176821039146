import { LoaderService } from "components/shared/CaaS/loader";
import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { IMasterProjectResponse } from "../MasterProject.interface";
import {
  fetchDropdownMasterProjects,
  fetchMasterProjects,
} from "services/master-project/masterProject.service";
import { actions } from "..";

export const getMasterProject: SourceActionType = (params: IParams) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      LoaderService.show();
      const response: IMasterProjectResponse =
        await fetchMasterProjects(params);
      dispatch(actions.getMasterProjects(response));
      LoaderService.hide();
    } catch (ex) {
      console.log(ex);
      LoaderService.hide();
    }
  };
};

export const getDropdownMasterProjects: SourceActionType = (
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      LoaderService.show();
      const response = await fetchDropdownMasterProjects(params);
      dispatch(actions.setDropdownMasterProjects(response.data));
      LoaderService.hide();
    } catch (ex) {
      console.log(ex);
      LoaderService.hide();
    }
  };
};
