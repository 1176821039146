import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";
interface IAssetPackagesIcon {
  fill?: string | null;
  stroke?: string;
}
const AssetPackagesIcon: React.FC<IAssetPackagesIcon> = (
  props: IAssetPackagesIcon,
) => {
  return (
    <svg
      {...props}
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 27990">
        <path
          id="Vector"
          d="M7.74072 5.4C7.74072 3.32582 7.74072 2.28873 8.38509 1.64437C9.02945 1 10.0666 1 12.1407 1C14.2149 1 15.252 1 15.8963 1.64437C16.5407 2.28873 16.5407 3.32582 16.5407 5.4C16.5407 7.47418 16.5407 8.51127 15.8963 9.15563C15.252 9.8 14.2149 9.8 12.1407 9.8C10.0666 9.8 9.02945 9.8 8.38509 9.15563C7.74072 8.51127 7.74072 7.47418 7.74072 5.4Z"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
        />
        <path
          id="Vector_2"
          d="M1.14062 19.7004C1.14062 18.1448 1.14063 17.367 1.6239 16.8836C2.10717 16.4004 2.88499 16.4004 4.44063 16.4004C5.99626 16.4004 6.77408 16.4004 7.25735 16.8836C7.74063 17.367 7.74063 18.1448 7.74063 19.7004C7.74063 21.256 7.74063 22.0338 7.25735 22.5172C6.77408 23.0004 5.99626 23.0004 4.44063 23.0004C2.88499 23.0004 2.10717 23.0004 1.6239 22.5172C1.14063 22.0338 1.14062 21.256 1.14062 19.7004Z"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
        />
        <path
          id="Vector_3"
          d="M16.5405 19.7004C16.5405 18.1448 16.5405 17.367 17.0238 16.8836C17.5071 16.4004 18.2849 16.4004 19.8405 16.4004C21.3961 16.4004 22.174 16.4004 22.6573 16.8836C23.1405 17.367 23.1405 18.1448 23.1405 19.7004C23.1405 21.256 23.1405 22.0338 22.6573 22.5172C22.174 23.0004 21.3961 23.0004 19.8405 23.0004C18.2849 23.0004 17.5071 23.0004 17.0238 22.5172C16.5405 22.0338 16.5405 21.256 16.5405 19.7004Z"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
        />
        <path
          id="Vector_4"
          d="M19.8404 16.3996C19.6394 13.7013 18.517 13.0996 14.7224 13.0996H12.1404H9.55849C5.76389 13.0996 4.64151 13.7013 4.44043 16.3996"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AssetPackagesIcon;
