interface IAssetIcon {
  fill?: string;
  className?: string | null;
}
const AssetsIcon: React.FC<IAssetIcon> = (props: IAssetIcon) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 23 22"
      className={`${props.className}`}
    >
      <path
        d="M10.6718 12.3346C10.5389 12.3346 10.4059 12.3039 10.2832 12.2323L1.25197 7.0059C0.883769 6.79112 0.761015 6.32061 0.975799 5.95241C1.19058 5.58421 1.65083 5.46145 2.02926 5.67624L10.6718 10.6777L19.2631 5.70695C19.6313 5.49217 20.1017 5.62515 20.3165 5.98312C20.5313 6.35132 20.3984 6.82177 20.0405 7.03656L11.0707 12.2323C10.9377 12.2937 10.8048 12.3346 10.6718 12.3346Z"
        fill={props.fill || "#141B34"}
      />
      <path
        d="M10.6721 21.6011C10.2528 21.6011 9.90503 21.2533 9.90503 20.834V11.5574C9.90503 11.138 10.2528 10.7903 10.6721 10.7903C11.0915 10.7903 11.4392 11.138 11.4392 11.5574V20.834C11.4392 21.2533 11.0915 21.6011 10.6721 21.6011Z"
        fill={props.fill || "#141B34"}
      />
      <path
        d="M10.6712 22C9.74043 22 8.85061 21.7852 8.17557 21.4068L2.71393 18.3794C1.2309 17.5611 0.0649414 15.5872 0.0649414 13.8894V8.10039C0.0649414 6.40258 1.2309 4.43888 2.71393 3.61043L8.17557 0.582984C9.56655 -0.194328 11.7553 -0.194328 13.1565 0.582984L18.6182 3.61043C20.1012 4.42866 21.2672 6.40258 21.2672 8.10039V11.8438C21.2672 12.2631 20.9194 12.6109 20.5001 12.6109C20.0807 12.6109 19.733 12.2631 19.733 11.8438V8.10039C19.733 6.97534 18.8636 5.50258 17.8818 4.96051L12.4201 1.93306C11.4894 1.41145 9.86316 1.41145 8.93243 1.93306L3.47078 4.96051C2.47868 5.51281 1.61956 6.97534 1.61956 8.10039V13.8894C1.61956 15.0144 2.48891 16.4872 3.47078 17.0293L8.93243 20.0567C9.83248 20.5579 11.5303 20.5579 12.4201 20.0567C12.7883 19.8522 13.2588 19.9851 13.4633 20.3533C13.6679 20.7215 13.5349 21.192 13.1667 21.3966C12.4917 21.7852 11.6019 22 10.6712 22Z"
        fill={props.fill || "#141B34"}
      />
      <path
        d="M18.0363 21.3864C15.8067 21.3864 13.9963 19.576 13.9963 17.3464C13.9963 15.1167 15.8067 13.3064 18.0363 13.3064C20.266 13.3064 22.0763 15.1167 22.0763 17.3464C22.0763 19.576 20.266 21.3864 18.0363 21.3864ZM18.0363 14.8406C16.6556 14.8406 15.5305 15.9656 15.5305 17.3464C15.5305 18.7271 16.6556 19.8522 18.0363 19.8522C19.4171 19.8522 20.5421 18.7271 20.5421 17.3464C20.5421 15.9656 19.4171 14.8406 18.0363 14.8406Z"
        fill={props.fill || "#141B34"}
      />
      <path
        d="M21.9223 22.0001C21.728 22.0001 21.5337 21.9285 21.3802 21.775L20.3575 20.7523C20.0609 20.4557 20.0609 19.9646 20.3575 19.668C20.6541 19.3714 21.145 19.3714 21.4416 19.668L22.4644 20.6908C22.761 20.9874 22.761 21.4784 22.4644 21.775C22.311 21.9285 22.1166 22.0001 21.9223 22.0001Z"
        fill={props.fill || "#141B34"}
      />
    </svg>
  );
};

export default AssetsIcon;
