import { Breadcrumb } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { ROUTES } from "routes/Routes.constants";
import SubProjectDetailHeader from "./SubProjectDetailHeader";
import SubProjectDetailTabs from "./tabs";
import AlectifyText from "static/texts.json";

const SubProjectDetail: React.FC = () => {
  const { activeMasterProject, activeSubProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const navigate = useNavigate();
  return (
    <>
      <Breadcrumb
        items={[
          {
            title: AlectifyText.ASSET_CATEGORY,
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: activeMasterProject?.name,
            href: `#`,
            onClick: () =>
              navigate(`${ROUTES.PROJECT}/${activeMasterProject?.id}`),
          },
          { title: activeSubProject?.name },
        ]}
      />
      <SubProjectDetailHeader />
      <SubProjectDetailTabs />
    </>
  );
};

export default SubProjectDetail;
