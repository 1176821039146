const PmExternalColoredIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      viewBox="0 0 22 19"
      fill="none"
    >
      <path
        d="M2.56752 9.41256C2.55927 9.33641 2.49499 9.27871 2.4184 9.27871H1.71429C1.6717 9.27871 1.63112 9.29682 1.60267 9.32852C1.57422 9.36021 1.56058 9.40251 1.56517 9.44486C1.76372 11.2789 2.48104 13.021 3.52739 14.3075C4.57334 15.5934 5.95906 16.4359 7.49207 16.4359C9.02788 16.4359 10.3119 15.5902 11.255 14.3014C12.1973 13.0137 12.8085 11.273 13.0063 9.44511C13.0109 9.40277 12.9972 9.36047 12.9688 9.32878C12.9403 9.29708 12.8997 9.27898 12.8572 9.27898H12.4445C12.3679 9.27898 12.3036 9.33667 12.2953 9.41281C12.1258 10.9762 11.5436 12.5509 10.6889 13.731C9.83286 14.9131 8.72046 15.6787 7.49207 15.6787C6.26255 15.6787 5.11943 14.9118 4.23302 13.7289C3.34827 12.5483 2.73669 10.974 2.56752 9.41256Z"
        fill="#fff"
        stroke="#fff"
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        d="M0.900514 9.42857H13.6706C14.1686 9.42857 14.5714 8.97783 14.5714 8.42185C14.5714 7.86552 14.1686 7.41478 13.6706 7.41478H13.4255C13.4255 4.73979 12.0549 2.42395 10.0579 1.29117L9.54905 4.51603C9.50889 4.77139 9.31067 4.95097 9.0861 4.95097C9.05911 4.95097 9.03148 4.94845 9.00385 4.94343C8.7478 4.89279 8.57721 4.62019 8.62219 4.33394L9.20336 0.649355C8.98522 0.25967 8.59841 0 8.15667 0H6.41411C5.97301 0 5.58653 0.25967 5.36806 0.649355L5.94924 4.33394C5.99454 4.62019 5.82362 4.89314 5.56757 4.94343C5.53994 4.94881 5.51231 4.95097 5.48533 4.95097C5.26076 4.95097 5.0619 4.77175 5.02238 4.51603L4.51349 1.29153C2.51553 2.42359 1.14596 4.73979 1.14596 7.41478H0.900514C0.40287 7.41478 0 7.86552 0 8.42185C0 8.97783 0.40287 9.42857 0.900514 9.42857Z"
        fill="#fff"
      />
      <path
        d="M1.71429 18.0002C3.34392 16.7065 8.02541 14.8954 13.7143 18.0002"
        stroke="#fff"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M17.9952 11.1506L20.5715 13.7114L18.0107 16.2877"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.7221 13.7166L13.7221 13.7165"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default PmExternalColoredIcon;
