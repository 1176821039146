import React from "react";
import { Card, Typography } from "antd";
import { IAlectifyCardProps } from "./AlectifyCard.interface";

const AlectifyCard: React.FC<IAlectifyCardProps> = (
  props: IAlectifyCardProps,
) => {
  return (
    <Card
      extra={props.extra}
      className={props.className}
      title={
        <>
          <Typography.Title level={5} className="m-0 mb-0">
            {props.title}
          </Typography.Title>
          {props?.description && (
            <Typography.Paragraph
              className={`alectify-card-description ${props?.descriptionclassname}`}
            >
              {props?.description}
            </Typography.Paragraph>
          )}
        </>
      }
    >
      {props.children}
    </Card>
  );
};

AlectifyCard.defaultProps = {
  extra: <></>,
  children: <></>,
  className: "",
};

export default AlectifyCard;
