import { IParams, SourceActionType } from "redux/interfaces";
import { Dispatch } from "react";
import { IProcedureListResponse } from "services/procedures/procedures.interface";
import { fetchProcedures } from "services/procedures/procedures.service";
import { actions } from "..";

/**
 * Source mapper function to dispatch the actions after services return response.
 * @params { IGetProceduresParams } params - params for pagination and searching purposes.
 * @returns void.
 **/
export const getProcedures: SourceActionType = (params: IParams) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.showProcedureLoader(true));
      const response: IProcedureListResponse = await fetchProcedures(params);
      dispatch(actions.getProcedures(response));
      dispatch(actions.showProcedureLoader(false));
    } catch (ex) {
      dispatch(actions.showProcedureLoader(false));
      console.log(ex);
    }
  };
};

export const setActiveTableView: SourceActionType = (payload: boolean) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setActiveTableView(payload));
  };
};

export const setActiveToggleButton: SourceActionType = (
  payload: string | null,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setActiveToggleButton(payload));
  };
};
