import React, { memo, useState } from "react";
import { Form, message, Space } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { MESSAGES } from "constants/messages";
import { PAGINATION } from "constants/index";
import { IRootState } from "redux/rootReducer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { IProjectSparePart } from "redux/components/spare-parts";
import { deleteSpareParts } from "services/spare-parts/spare-parts.service";
import {
  clearRecentlyAddedSpareParts,
  deleteRecentlyAddedSpareParts,
  getSpareParts,
} from "redux/components/spare-parts/sources";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import AlectifyText from "static/texts.json";
import AlectifyTable from "components/shared/table";
import AlectifyDrawer from "components/drawer";
import AlectifyButton from "components/shared/button";
import CreateEditSparePart from "components/shared/create-edit-spare-part";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import useRecentlyAddedSparePartsColumns from "./effects/useRecentlyAddedSparePartsColumns";
import { CloseOutlined } from "@ant-design/icons";

const AddSparePart: React.FC = () => {
  const dispatch = useDispatch();
  const [formInstance] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const { recentlyAddedSpareParts } = useSelector(
    ({ spareParts }: IRootState) => spareParts,
  );

  const fetchSpareParts = (options?: IPagination) => {
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      ...options,
    };
    dispatch(getSpareParts(params));
  };

  const openSparePartForm = (sparePart?: IProjectSparePart) => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 800,
      title: sparePart
        ? AlectifyText.UPDATE_INVENTORY
        : AlectifyText.ADD_NEW_INVENTORY,
      name: DRAWER_CONSTANTS.ADD_NEW_SPARE_PART_DRAWER,
      showFooter: true,
      submitText: sparePart ? "Update" : "Submit",
      destroyOnClose: true,
      closeIcon: <CloseOutlined />,
      closable: true,
      children: (
        <CreateEditSparePart
          formInstance={formInstance}
          existingSparePart={sparePart}
          fetchSpareParts={fetchSpareParts}
        />
      ),
      onSubmit: formInstance.submit,
      onClose: () => {
        formInstance.resetFields();
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.ADD_NEW_SPARE_PART_DRAWER,
        });
      },
    });
  };

  const onDeleteSpareParts = async (ids: string[]) => {
    try {
      setLoading(true);
      await deleteSpareParts(ids);
      setLoading(false);
      dispatch(deleteRecentlyAddedSpareParts(ids));
      setSelectedIds([]);
      message.success(MESSAGES.SPARE_PART_MESSAGES.DELETED);
    } catch (error) {
      setLoading(false);
    }
  };

  const columns = useRecentlyAddedSparePartsColumns({
    openSparePartForm,
    onDeleteSpareParts,
  });

  return (
    <>
      <AlectifyTable
        rowSelection
        loading={loading}
        columns={columns}
        scroll={{ x: 1500, y: "calc(100vh - 350px)" }}
        onDataFetch={() => {}}
        dataSource={recentlyAddedSpareParts}
        total={recentlyAddedSpareParts.length}
        onRowSelectionAction={(_, ids: React.Key[]) =>
          setSelectedIds(ids as string[])
        }
        extraButton={
          <Space>
            {recentlyAddedSpareParts.length ? (
              <AlectifyButton
                type="default"
                text={AlectifyText.CLEAR_ALL}
                className="alectify-default-button"
                onClick={() => dispatch(clearRecentlyAddedSpareParts())}
              />
            ) : null}
            {selectedIds.length ? (
              <AlectifyButton
                danger
                type="text"
                className="danger-button"
                loading={loading}
                text={`Delete (${selectedIds.length})`}
                onClick={() => onDeleteSpareParts(selectedIds)}
              />
            ) : null}
            <AlectifyButton
              type="primary"
              text={AlectifyText.ADD_NEW_INVENTORY}
              onClick={() => openSparePartForm()}
            />
          </Space>
        }
      />
      <div className="text-align-right"></div>
    </>
  );
};

export default memo(AddSparePart);
