import React from "react";
import { Space, Tooltip, Popconfirm, Avatar } from "antd";
import { IAssetPackage } from "redux/components/asset-packages";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import TextToLink from "components/shared/text-to-link";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { useNavigate, useParams } from "react-router-dom";
import { IAssetPackageTableProps } from "../AssetPackages.interface";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyButton from "components/shared/button";
import AddAssetPackage from "components/shared/manage-asset-package/AddAssetPackage";
import { EditIconFilledIcon } from "components/icons";
import DeleteIcon from "components/icons/DeleteIcon";
import { CloseOutlined } from "@ant-design/icons";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";

const AssetPackageColumns = (props: IAssetPackageTableProps) => {
  const navigate = useNavigate();
  const { subProjectId } = useParams();

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.ITEM_NUMBER,
      dataIndex: "serial_number",
      // sorter: (a, b) => a.serial_number - b.serial_number,
      align: "center",
      width: 50,
      visible: true,
      ellipsis: true,
      render: (serial_number) => (
        <Tooltip placement="topLeft" title={serial_number}>
          {serial_number}
        </Tooltip>
      ),
    },
    {
      title: AlectifyText.PARENT_ASSET_NAME,
      dataIndex: "package_name",
      searchable: true,
      visible: true,
      width: 100,
      ellipsis: true,
      render: (name: string, assetPackage: IAssetPackage) => (
        <Space align="center">
          <div className="avatar-size">
            <Avatar
              src={assetPackage?.image ? assetPackage?.image : NO_IMAGE_AVATAR}
            />
          </div>

          <Tooltip placement="topLeft" title={name}>
            <TextToLink
              className="text-to-link-options"
              text={name}
              onClick={() =>
                DrawerServiceInstance.open(AlectifyDrawer, {
                  width: 940,
                  title: `${name} (${assetPackage?.tags_count})`,
                  name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                  closable: true,
                  closeIcon: <CloseOutlined />,
                  onClose: () =>
                    DrawerServiceInstance.close(AlectifyDrawer, {
                      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    }),
                  children: (
                    <PackageDetailAssets
                      subProjectId={subProjectId || props.subProjectId || ""}
                      assetPackageId={assetPackage.id}
                      navigate={navigate}
                      isDetailsbutton={props?.isDetailsbutton}
                      isUploadbutton
                    />
                  ),
                })
              }
            />
          </Tooltip>
        </Space>
      ),
      // sorter: (a, b) => a.name - b.name,
    },
    {
      title: AlectifyText.DESCRIPTION,
      dataIndex: "description",
      key: "description",
      ellipsis: true,
      visible: true,
      searchable: true,
      width: 150,
      render: (description) => (
        <Tooltip placement="topLeft" title={description}>
          {description}
        </Tooltip>
      ),
      // onCell: onAssetClick,
    },
    // {
    //   title: AlectifyText.TOTAL_ASSETS,
    //   dataIndex: "tags_count",
    //   visible: true,
    //   width: 100,
    //   render: (tags_count) => (
    //     <Tooltip placement="topLeft" title={tags_count}>
    //       {tags_count}
    //     </Tooltip>
    //   ),
    //   // sorter: (a, b) => a.tags_count - b.tags_count,
    // },
    {
      title: AlectifyText.ACTIONS,
      align: "center",
      visible: props.showAction,
      dataIndex: "",
      width: 80,
      render: (_, assetPackage: IAssetPackage) =>
        props.selectRowOption ? (
          <AlectifyButton
            text="Select"
            type="primary"
            onClick={() => props.onRowSelect?.(assetPackage)}
          />
        ) : (
          <></>
        ),
    },

    {
      title: AlectifyText.ACTIONS,
      visible: props.displayActionButton,
      width: 100,
      render: (value: string, asset: IAssetPackage) =>
        props.displayActionButton ? (
          <>
            <Space>
              <div>
                <Tooltip title="Delete Package">
                  <Popconfirm
                    onConfirm={() =>
                      props?.onDeletePackage && props.onDeletePackage(asset)
                    }
                    title="Are your want to delete this package?"
                  >
                    <div className="cursor-pointer">
                      <DeleteIcon />
                    </div>
                  </Popconfirm>
                </Tooltip>
              </div>

              <Tooltip title="Edit">
                <div
                  className="cursor-pointer"
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: "70vw",
                      title: AlectifyText.ADD_ASSET_PACKAGE,
                      name: DRAWER_CONSTANTS.ADD_ASSET_PACKAGE,
                      showFooter: true,
                      onClose: props?.onClose,
                      closable: true,
                      closeIcon: <CloseOutlined />,
                      submitText: "Update",
                      onSubmit: props?.form?.submit,
                      children: (
                        <AddAssetPackage
                          packageEdit
                          record={asset}
                          subProjectId={props?.subProjectId}
                          form={props.form}
                        />
                      ),
                    });
                  }}
                >
                  <EditIconFilledIcon />
                </div>
              </Tooltip>
            </Space>
          </>
        ) : (
          <></>
        ),
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default AssetPackageColumns;
