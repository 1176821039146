import React, { memo, useEffect } from "react";
import { Form, Space, message } from "antd";
import { MESSAGES } from "constants/messages";
import AlectifyButton from "components/shared/button";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { IUpdateUserTypeModalProps } from "./Admin.interface";
import AlectifySelect from "components/shared/select";
import { USER_ACCESS_TYPES } from "enums";
import { updateUserType } from "services/admin/admin.service";

const UpdateUserTypeModal: React.FC<IUpdateUserTypeModalProps> = ({
  record,
  getUsers,
}) => {
  const [form] = Form.useForm();

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.USER_TYPE_MODAL,
    });
  };

  const onSubmit = async (values: any) => {
    const { user_type } = values;
    try {
      await updateUserType(record.id, {
        user_type: parseInt(user_type),
      });
    } catch (ex) {
      console.log(ex);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    } finally {
      getUsers();
      onCloseModal();
    }
  };

  useEffect(() => {
    form.setFieldsValue({ user_type: record?.user_type.toString() });
  }, []);
  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <AlectifySelect
        label="User Type"
        name="user_type"
        options={Object.keys(USER_ACCESS_TYPES).map((key: any) => ({
          value: key,
          label: USER_ACCESS_TYPES[key],
        }))}
        rules={[
          {
            required: true,
            message: MESSAGES.ADMIN_MESSAGES.USER_TYPE__REQUIRED,
          },
        ]}
      />
      <Space className="d-flex justify-flex-end">
        <AlectifyButton text="Cancel" type="primary" onClick={onCloseModal} />
        <AlectifyButton text="Update" type="primary" htmlType="submit" />
      </Space>
    </Form>
  );
};
export default memo(UpdateUserTypeModal);
