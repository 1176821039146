import { getAllGroups } from "services/data-round/data-round.service";
import AlectifyTable from "../table";
import { IGroupTableProps } from "./GroupTable.interface";
import { useEffect, useState } from "react";
import { IDrGroup } from "services/data-round/data-round.interface";
import useGroupTableColumns from "./effects/useGroupTableColumns";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { IMeta } from "redux/interfaces";

const GroupTable: React.FC<IGroupTableProps> = (props: IGroupTableProps) => {
  const [groups, setGroups] = useState<IDrGroup[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [meta, setMeta] = useState<IMeta>();

  const getGroups = async (params?: IPagination) => {
    setLoader(false);
    try {
      setLoader(true);
      const response = await getAllGroups(props.projectId, params);
      setLoader(false);
      if (response.success) {
        setGroups(response.data);
        setMeta(response.meta);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };
  const columns = useGroupTableColumns(props);

  useEffect(() => {
    const params = {
      page: PAGINATION.DEFAULT_START_PAGE,
      per_page: PAGINATION.DEFAULT_PAGE_SIZE,
    };
    getGroups(params);
  }, [props.projectId]);

  return (
    <AlectifyTable
      scroll={props.scroll}
      showSearch={props.showSearch}
      size="small"
      columns={columns}
      loading={loader}
      dataSource={groups || []}
      total={meta?.total_count || 0}
      onDataFetch={getGroups}
      customSearchKey="search"
      extraButton={props.extra}
      rowSelection={props.rowSelection}
      selectedRowKeys={props.selectedRowKeys}
      onRowSelectionAction={props.onRowSelectionAction}
    />
  );
};

export default GroupTable;
