import React, { ReactNode } from "react";
import { Col, Divider, Row, Skeleton, Tag, Timeline } from "antd";
import dayjs from "dayjs";
import { IDataRoundShiftTimelineProps } from "./DataRoundShiftTimeline.interface";
import "./DataRoundTimeline.scss";
import { CircleDotIcon } from "components/icons";
import ShiftCards from "./ShiftCards";
import { isEmpty } from "lodash";

const DataRoundShiftTimeline: React.FC<IDataRoundShiftTimelineProps> = (
  props: IDataRoundShiftTimelineProps,
) => {
  const generateShiftCards = (date: string): ReactNode => {
    if (!isEmpty(props.project)) {
      const cards = [];
      for (let i = 0; i < (props.project?.shift_schedules.length ?? 0); i++) {
        cards.push(
          <>
            <ShiftCards
              key={i}
              insights={props.insights}
              shiftSchedule={props.project.shift_schedules[i]}
              shiftDate={date}
            />
            <Divider />
          </>,
        );
      }
      return <>{cards}</>;
    }
    return null; // Return null or some other fallback if props.project is empty
  };

  const today = dayjs();
  const lastWeekDay = today.subtract(6, "day"); // Calculate the date 6 days before today

  const timelineItems = [];

  // Loop from today to 6 days back (inclusive)
  for (
    let currentDate = today;
    currentDate.isAfter(lastWeekDay) || currentDate.isSame(lastWeekDay);
    currentDate = currentDate.subtract(1, "day")
  ) {
    timelineItems.push({
      label: (
        <Tag color={"#fff"} className="round-tag alectify-timeline-date-tag">
          {currentDate.format("YYYY-MM-DD")}
        </Tag>
      ),
      key: currentDate.format("YYYY-MM-DD"),
      dot: <CircleDotIcon />,
      children: generateShiftCards(currentDate.format("YYYY-MM-DD")),
    });
  }

  return (
    <Row justify={"start"} align={"middle"}>
      {props.loader ? (
        <Skeleton />
      ) : (
        <Col span={24} className="round-timeline-container">
          <Timeline
            mode={"left"}
            items={timelineItems}
            className="round-timeline"
          />
        </Col>
      )}
    </Row>
  );
};

export default DataRoundShiftTimeline;
