import React from "react";
import { Typography } from "antd";
import "./Footer.scss";
import { currentYear } from "utils/helpers";

const { Text } = Typography;

const MainFooter: React.FC = () => {
  return (
    <div className="footer-container">
      <Text className="footer-text">
        © Copyrights Alectify {currentYear}. All Rights Reserved.
      </Text>
    </div>
  );
};

export default MainFooter;
