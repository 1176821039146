const AssetPackagesColoredIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
    >
      <path
        d="M5.49994 3.99994C5.49994 2.58575 5.49994 1.87866 5.93927 1.43933C6.3786 1 7.08571 1 8.49988 1C9.91405 1 10.6211 1 11.0605 1.43933C11.4998 1.87866 11.4998 2.58575 11.4998 3.99994C11.4998 5.41412 11.4998 6.12121 11.0605 6.56054C10.6211 6.99987 9.91405 6.99987 8.49988 6.99987C7.08571 6.99987 6.3786 6.99987 5.93927 6.56054C5.49994 6.12121 5.49994 5.41412 5.49994 3.99994Z"
        stroke="#fff"
        strokeWidth="1.5"
      />
      <path
        d="M1 13.75C1 12.6893 1 12.159 1.3295 11.8295C1.659 11.5 2.18932 11.5 3.24995 11.5C4.31059 11.5 4.84091 11.5 5.17041 11.8295C5.49991 12.159 5.49991 12.6893 5.49991 13.75C5.49991 14.8106 5.49991 15.3409 5.17041 15.6704C4.84091 15.9999 4.31059 15.9999 3.24995 15.9999C2.18932 15.9999 1.659 15.9999 1.3295 15.6704C1 15.3409 1 14.8106 1 13.75Z"
        stroke="#fff"
        strokeWidth="1.5"
      />
      <path
        d="M11.4998 13.75C11.4998 12.6893 11.4998 12.159 11.8292 11.8295C12.1588 11.5 12.6891 11.5 13.7497 11.5C14.8103 11.5 15.3406 11.5 15.6702 11.8295C15.9997 12.159 15.9997 12.6893 15.9997 13.75C15.9997 14.8106 15.9997 15.3409 15.6702 15.6704C15.3406 15.9999 14.8103 15.9999 13.7497 15.9999C12.6891 15.9999 12.1588 15.9999 11.8292 15.6704C11.4998 15.3409 11.4998 14.8106 11.4998 13.75Z"
        stroke="#fff"
        strokeWidth="1.5"
      />
      <path
        d="M13.7497 11.4995C13.6126 9.65975 12.8474 9.24951 10.2602 9.24951H8.4998H6.73942C4.15225 9.24951 3.38701 9.65975 3.24991 11.4995"
        stroke="#fff"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AssetPackagesColoredIcon;
