import { memo, useEffect } from "react";
import { Image, Typography, Upload, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { displayDateTime } from "utils/helpers";
import { IAssetDetailProps } from "./AssetDetail.interface";
import { AlectifyDetailCard, AlectifyDetailCardText } from "../detail-card";
import { NO_IMAGE_AVATAR } from "../assets-table/AssetsTable.constants";
import {
  getAssetDetail,
  getProjectAssets,
} from "redux/components/assets/sources";
import { IRootState } from "redux/rootReducer";
import AlectifyText from "static/texts.json";
import { ROUTES } from "routes/Routes.constants";
import AlectifyButton from "../button";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { actions } from "redux/components/notifications";
import { truncate } from "lodash";
import { CameraOutlined } from "@ant-design/icons";
import "./AssetDetailSidebar.scss";
import { UploadAssetImage } from "services/assets/assets.service";
import { MESSAGES } from "constants/messages";
import { IPagination } from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { getAssetDocuments } from "redux/components/documents/sources";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyModal from "../modal";
import ViewImage from "../view-image/ViewImage";

const AssetDetail: React.FC<IAssetDetailProps> = (props: IAssetDetailProps) => {
  const {
    assetId,
    subProjectId,
    pmInternalRecord,
    pmExternalRecord,
    predictionData,
    isUploadbutton,
    taskRecord,
  } = props;

  const { assetDetail } = useSelector((state: IRootState) => state.assets);
  const asset = props?.asset || assetDetail?.data;
  const { activeSubProject, activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  const dispatch = useDispatch();
  useEffect(() => {
    if (assetId && subProjectId) {
      dispatch(getAssetDetail(assetId, subProjectId));
    }
  }, [assetId, subProjectId]);

  const getDocuments = (options?: IPagination) => {
    const projectId = subProjectId || activeSubProject?.id;
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      searchText: options?.searchText || null,
      ...options,
    } as any;
    if (props.assetId) params.tag = props.assetId;
    dispatch(getAssetDocuments(projectId, params));
    dispatch(getProjectAssets(props.subProjectId, params));
  };

  const goToAssetDetail = () => {
    const url = ROUTES.TIMELINE.replace(":subProjectId", asset?.project || "")
      .replace(":equipmentType", "asset")
      .replace(":equipmentId", asset?.id || assetId || "");
    if (props.navigate) {
      dispatch(actions.toggleNotificationDropdown(false));
      props.navigate(url);
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: [
          DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
          DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
        ],
      });
    }
  };

  const handleImageUpload = async (file: any) => {
    const projectId = subProjectId || activeSubProject?.id || "";
    const tagId = asset?.id || assetId || "";
    message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.IMAGE_UPLOADING);
    try {
      const response = await UploadAssetImage(projectId, tagId, {
        image: file,
      });
      if (response) {
        dispatch(getAssetDetail(tagId, projectId));
        getDocuments();
        message.success(MESSAGES.FILE_UPLOAD_MESSAGES.ASSET_IMAGE_UPLOADED);
      }
    } catch (error: any) {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error("Error uploading image:", error);
    }
  };

  const PreviewImage = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.VIEW_IMAGE_MODAL,
      title: AlectifyText.VIEW_IMAGE,
      children: <ViewImage imageUrl={asset?.image || ""} />,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.VIEW_IMAGE_MODAL,
        });
      },
      footer: null,
    });
  };

  return (
    <AlectifyDetailCard
      loading={assetDetail.fetching}
      className="asset-detail-card"
    >
      <>
        <div className="profile-image-container">
          <div className="image-wrapper">
            <Image
              preview={false}
              src={assetDetail?.data?.image || asset?.image || NO_IMAGE_AVATAR}
              className="contact-profile-image"
              onClick={() =>
                (assetDetail?.data?.image || asset?.image) && PreviewImage
              }
            />
            {isUploadbutton && (
              <Upload
                maxCount={1}
                accept="image/*"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={(info) => {
                  const { fileList } = info;
                  handleImageUpload(fileList[0].originFileObj);
                }}
              >
                <CameraOutlined className="camera-icon" />
              </Upload>
            )}
          </div>
        </div>
        <Typography.Paragraph className="text-align-center mt-5">
          {asset?.description}
        </Typography.Paragraph>
        <AlectifyDetailCardText
          heading={AlectifyText.ITEM_DETAIL}
          label={AlectifyText.ITEM_NUMBER}
          value={asset?.serial_number}
        />
        <AlectifyDetailCardText
          label={AlectifyText.SITE}
          value={
            pmInternalRecord?.project?.name ||
            pmExternalRecord?.project?.name ||
            predictionData?.mainproject_name ||
            predictionData?.master_project_name ||
            activeMasterProject?.name ||
            taskRecord?.project?.name ||
            "-"
          }
        />
        <AlectifyDetailCardText
          label={AlectifyText.ASSET_CATEGORY}
          value={truncate(
            pmInternalRecord?.subProject?.name ||
              pmExternalRecord?.subProject?.name ||
              predictionData?.project_name ||
              predictionData?.subproject_name ||
              activeSubProject?.name ||
              taskRecord?.subProject?.name ||
              "-",
            {
              length: 20,
              omission: "...",
            },
          )}
        />
        <AlectifyDetailCardText
          label={AlectifyText.PARENT_ASSET_NAME}
          value={truncate(assetDetail?.data?.packageroom?.name, {
            length: 20,
            omission: "...",
          })}
        />
        <AlectifyDetailCardText
          label={AlectifyText.SUB_ASSET_NAME}
          value={truncate(assetDetail?.data?.name, {
            length: 20,
            omission: "...",
          })}
        />
        {/* <AlectifyDetailCardText
          label={AlectifyText.TEAM_MEMBERS}
          value={
            <AvatarGroup
              users={asset?.team_members || []}
              maxCount={5}
              showImage
            />
          }
        /> */}
        <AlectifyDetailCardText
          heading={AlectifyText.MANUFACTUROR_INFORMATION}
          label={AlectifyText.MANUFACTURER}
          value={assetDetail?.data?.manufacture?.name}
        />
        <AlectifyDetailCardText
          label={AlectifyText.WARRANTY_DATE}
          value={displayDateTime(assetDetail?.data?.warranty_date)}
        />
        <AlectifyDetailCardText
          label={AlectifyText.INSTALLATION_DATE}
          value={displayDateTime(assetDetail?.data?.installation_date) || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.MODEL}
          value={assetDetail?.data?.model || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.SERIAL_NUMBER}
          value={assetDetail?.data?.model_serial_number || "-"}
        />
        <AlectifyDetailCardText
          heading={AlectifyText.OTHER_INFORMATION}
          label={AlectifyText.ASSET_TYPE}
          value={assetDetail?.data?.asset_type || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.ASSET_STATUS}
          value={assetDetail?.data?.status || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.GROUP}
          value={assetDetail?.data?.group || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.LOCATION}
          value={assetDetail?.data?.location || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.PREFERRED_SUPPLIER}
          value={assetDetail?.data?.preferred_supplier?.name || "-"}
        />
        <AlectifyDetailCardText
          label={AlectifyText.PARENT_ASSET_NAME}
          value={
            truncate(assetDetail?.data?.packageroom?.name, {
              length: 20,
              omission: "...",
            }) || "-"
          }
        />
        {props?.navigate && props?.isDetailButton && (
          <div className="alectify-sticky-buttons-container">
            <AlectifyButton
              block
              type="primary"
              text="Go to Asset Detail"
              onClick={goToAssetDetail}
              className="mb-5"
            />
          </div>
        )}
      </>
    </AlectifyDetailCard>
  );
};

AssetDetail.defaultProps = {
  subProjectId: "",
  assetId: "",
};

export default memo(AssetDetail);
