import React from "react";

interface IAlertIconProps {
  stroke?: string | null;
}
const AlertIcon: React.FC<IAlertIconProps> = ({ stroke }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M5.32171 10.2249C7.73539 5.95398 8.94222 3.8185 10.5983 3.2688C11.5093 2.96639 12.4907 2.96639 13.4017 3.2688C15.0578 3.8185 16.2646 5.95398 18.6783 10.2249C21.092 14.4958 22.2988 16.6313 21.9368 18.3713C21.7376 19.3286 21.2469 20.1968 20.535 20.8517C19.241 22.042 16.8274 22.042 12 22.042C7.17265 22.042 4.75897 22.042 3.46496 20.8517C2.75308 20.1968 2.26239 19.3286 2.06322 18.3713C1.70119 16.6313 2.90803 14.4958 5.32171 10.2249Z"
        stroke={stroke || "#141B34"}
        strokeWidth="1.5"
      />
      <path
        d="M12.2422 17.542V13.542C12.2422 13.0706 12.2422 12.8349 12.0957 12.6884C11.9493 12.542 11.7136 12.542 11.2422 12.542"
        stroke={stroke || "#141B34"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.992 9.54199H12.001"
        stroke={stroke || "#141B34"}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AlertIcon;
