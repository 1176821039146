interface IMyWorkOrderIconProps {
  fill?: string | null;
}
const MyWorkOrderIcon: React.FC<IMyWorkOrderIconProps> = (
  props: IMyWorkOrderIconProps,
) => {
  return (
    <svg
      width="21"
      height="23"
      viewBox="0 0 21 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        id="spare-parts"
        d="M15.9813 3.15144L15.9924 3.14591H15.9979L10.5 0L5.00486 3.14591H4.99931L5.01872 3.15421L0.5 5.75V17.25L10.5 23L20.5 17.25V5.75L15.9813 3.15144ZM9.68724 20.6668L5.71775 18.3834V14.5243L2.12275 12.4897V7.65469L9.68724 12.0031V20.6668ZM2.90499 6.23377L6.64147 4.08305L10.4945 6.2863L14.353 4.07752L18.1033 6.23377L10.5056 10.6016L2.90499 6.23377ZM18.8773 12.5035L15.3044 14.5243V18.3696L11.3128 20.6641V12.0087L18.8773 7.66022V12.5035Z"
        fill={props.fill || "#141B34"}
      />
    </svg>
  );
};

export default MyWorkOrderIcon;
