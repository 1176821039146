import {
  IProcedureCheckList,
  ProcedureJobTypeEnum,
} from "services/procedures/procedures.interface";
import JobTypeTag from "components/shared/tags/JobTypeTag";
import MOPTag from "components/shared/tags/MOPTag";
import SOPTag from "components/shared/tags/SopTag";

const TagComponentMap = {
  [ProcedureJobTypeEnum.JOB_PLAN]: JobTypeTag,
  [ProcedureJobTypeEnum.SOP]: SOPTag,
  [ProcedureJobTypeEnum.MOP]: MOPTag,
  "Maintenance Operating Procedure": MOPTag,
};

/**
 * Mapper for showing tags according to job type.
 * @param { ProcedureJobTypeEnum } jobType - Enum for job type of procedures.
 * @returns
 */
export const getProceduresTag = (jobType: ProcedureJobTypeEnum) => {
  return TagComponentMap[jobType];
};

/**
 * Count the number of checked steps in a procedure.
 * @param { IProcedureCheckList[] } procedureSteps - Array of procedure steps.
 * @returns { string } - The number of steps that are checked (isChecked is true).
 */
export const countCheckedSteps = (
  procedureSteps: IProcedureCheckList[],
): string => {
  const totalSteps = procedureSteps.length;
  const checkedSteps = procedureSteps.reduce(
    (count, step) => (step.isChecked ? count + 1 : count),
    0,
  );

  return `${checkedSteps}/${totalSteps}`;
};

/**
 * Check if all steps in a procedure are completed.
 * @param { IProcedureCheckList[] } procedureSteps - Array of procedure steps.
 * @returns { boolean } - True if all steps are completed, false otherwise.
 */
export const areAllStepsCompleted = (
  procedureSteps: IProcedureCheckList[],
): boolean => {
  return procedureSteps.every((step) => step.isChecked);
};
