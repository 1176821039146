import { Space, Tag } from "antd";
import AlectifyText from "static/texts.json";
import RecurringIcon from "components/icons/RecurringIcon";

const RecurringTag: React.FC = () => {
  return (
    <Tag color={"#6FD2C0"} className="round-tag">
      <Space size={3} align="center">
        <RecurringIcon /> {AlectifyText.RECURRING}
      </Space>
    </Tag>
  );
};

export default RecurringTag;
