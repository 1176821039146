import { memo } from "react";

const CircleDotIcon: React.FC = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="10"
        cy="10"
        r="7.5"
        fill="white"
        stroke="#0954F1"
        strokeWidth="5"
      />
    </svg>
  );
};

export default memo(CircleDotIcon);
