import { InputNumber } from "antd";
import { IAlectifyInputNumberProps } from "./AlectifyInputNumber.interface";

import "./AlectifyInputNumber.scss";

const AlectifyInputNumberPicker: React.FC<IAlectifyInputNumberProps> = (
  props: IAlectifyInputNumberProps,
) => {
  return (
    <>
      <InputNumber
        {...props}
        className="alectify-input-number"
        defaultValue={props.defaultValue}
      />
      {props.extra}
    </>
  );
};

export default AlectifyInputNumberPicker;
