import { Tooltip } from "antd";
import AlectifyText from "static/texts.json";

const TodayTasksEmbedIcon: React.FC<{ onClick?: () => void }> = ({
  onClick,
}) => {
  return (
    <Tooltip title={AlectifyText.ALL_WORK_ORDERS_DUE_TODAY}>
      <div className="today-task-icon" onClick={onClick}>
        <embed
          type="video/webm"
          src="https://lottie.host/embed/e2cdd3a5-1183-48e3-bd68-632da0fb1ced/yHFANxWlfM.json"
        />
      </div>
    </Tooltip>
  );
};

export default TodayTasksEmbedIcon;
