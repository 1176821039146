import DayJs from "dayjs";
import { memo, useEffect, useState } from "react";
import { Form, Row, Col, Radio, RadioChangeEvent, Spin } from "antd";
import AlectifyInput from "../input";
import AlectifySelect from "../select";
import AlectifyDatePicker from "../datepicker";
import { IAddAssetFormProps } from "./AddAssetManaul.interface";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import { AssetStatusEnum } from "enums/asset-type.enum";
import { IAsset, actions } from "redux/components/assets";
import {
  createSingleAsset,
  updateAssetDetail,
} from "services/assets/assets.service";
import { SERVER_DATE_FORMAT } from "constants/index";
import { message } from "antd/lib";
import { isEmpty, truncate } from "lodash";
import OrganizationsAutoComplete from "../autocomplete/OrganizationsAutoComplete";
import AlectifyButton from "../button";
import AssetPackagesTable from "../asset-packages-table";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import {
  IAssetPackage,
  actions as assetPackageActions,
} from "redux/components/asset-packages";
import {
  createPackage,
  updatePackage,
} from "services/asset-packages/asset-packages.service";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { getAssetDetail } from "redux/components/assets/sources";
import { getAssetPackageDetail } from "redux/components/asset-packages/sources";
import { validateSerialNumber } from "utils/helpers";
import "./ManageAssets.scss";

const AddAssetManualForm: React.FC<IAddAssetFormProps> = ({
  form,
  subProjectId,
  asset,
  isEditing,
  onCloseAndFetchAssets,
}) => {
  const [parentFormActive, setParentFormActive] = useState<string | null>(null);
  const [selectedAsset, setSelectedAsset] = useState<IAssetPackage>();
  const { assetDetail } = useSelector((state: IRootState) => state.assets);
  const { assetPackageDetail } = useSelector(
    ({ assetPackage }: IRootState) => assetPackage,
  );
  const dispatch = useDispatch();
  const [state, setState] = useState<any>({
    data: [],
    fetching: false,
  });
  const onSubmit = async (values: IAsset) => {
    try {
      const payload: any = {
        ...values,
        installation_date: !isEmpty(values["installation_date"])
          ? DayJs(values["installation_date"]).format(SERVER_DATE_FORMAT)
          : null,
        warranty_date: !isEmpty(values["warranty_date"])
          ? DayJs(values["warranty_date"]).format(SERVER_DATE_FORMAT)
          : null,
      };

      if (!isEditing) {
        parentFormActive !== "Tag"
          ? await createPackage(subProjectId, payload)
          : await createSingleAsset(subProjectId, payload);
        message.success(MESSAGES.ASSET.ASSET_CREATED);
      } else if (asset?.tag_id) {
        await updateAssetDetail(subProjectId, asset.tag_id, payload);
        message.success(MESSAGES.ASSET.ASSET_UPDATED);
      } else if (asset?.package_id) {
        await updatePackage(subProjectId, asset.package_id, payload);
        message.success(MESSAGES.ASSET.ASSET_PACKAGE_UPDATED);
      }

      onCloseAndFetchAssets();
    } catch {
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  useEffect(() => {
    setState({
      data: !isEmpty(assetDetail.data)
        ? assetDetail.data
        : assetPackageDetail.data,
      fetching: assetPackageDetail.fetching || assetDetail.fetching,
    });
    return () => {
      form?.resetFields();
    };
  }, [assetDetail, assetPackageDetail]);

  useEffect(() => {
    form?.setFieldsValue({
      ...state?.data,
      type: asset?.type !== "Tag" ? "PackageRoom" : "Tag",

      installation_date: !isEmpty(state?.data?.installation_date)
        ? DayJs(state?.data?.installation_date)
        : null,
      warranty_date: !isEmpty(state.data?.warranty_date)
        ? DayJs(state?.data?.warranty_date)
        : null,
      manufacture: state?.data?.manufacture?.name,
      preferred_supplier: state?.data?.preferred_supplier?.name || null,
      packageroom: state?.data?.packageroom?.id,
    });
  }, [state]);

  useEffect(() => {
    if (asset?.type === "Tag") {
      dispatch(assetPackageActions.setAssetPackageDetail([] as any));
    } else {
      dispatch(actions.setAssetDetail([] as any));
    }

    if (isEditing) {
      if (asset?.type === "Tag") {
        setParentFormActive("Tag");
      } else {
        setParentFormActive("PackageRoom");
      }
    }
    if (asset && form) {
      if (asset.type === "Tag") {
        dispatch(getAssetDetail(asset?.tag_id, subProjectId));
      } else {
        dispatch(getAssetPackageDetail(asset.subproject_id, asset.package_id));
      }
    }
  }, [asset]);

  const equipmentModalHandler = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      name: "select-asset-modal",
      title: AlectifyText.SELECT_ASSET_PACKAGE,
      children: (
        <AssetPackagesTable
          showSearch
          selectRowOption
          subProjectId={subProjectId}
          showAction
          is_individual={false}
          parentFormActive={parentFormActive}
          onRowSelect={(record: IAssetPackage) => {
            form && form.setFieldValue("packageroom", record.package_id);
            setSelectedAsset(record);
            ModalServiceInstance.close(AlectifyModal, {
              name: "select-asset-modal",
            });
          }}
        />
      ),
      footer: null,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: "select-asset-modal",
        });
      },
    });
  };

  const onChangeParentAsset = (e: RadioChangeEvent) => {
    if (isEditing) {
      return;
    }
    setParentFormActive(e.target.value);
  };

  return (
    <>
      <Spin spinning={state.fetching}>
        <Form
          form={form}
          layout="vertical"
          onFinish={onSubmit}
          className="alectify-manage-asset-form-container"
        >
          <Row justify="start" gutter={20}>
            <Col span={24}>
              <h4 className="background-color-light-grey p-5 mb-15 mt-0">
                {AlectifyText.ASSET_DETAIL}
              </h4>
              <Form.Item
                name="type"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.ASSET_CATEGORY.PLEASE_SELECT_ASSET,
                  },
                ]}
              >
                <Radio.Group
                  onChange={onChangeParentAsset}
                  value={parentFormActive}
                  disabled={isEditing}
                  className="alectify-manage-asset-form-radio-buttons"
                >
                  <Radio value={"PackageRoom"}>{AlectifyText.ADD_PARENT}</Radio>
                  <Radio value={"Tag"}> {AlectifyText.ADD_SUB_ASSET} </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
            <Col span={8}>
              {parentFormActive === "Tag" ? (
                <Form.Item
                  name="packageroom"
                  label={AlectifyText.SELECT_PARENT_ASSET}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ASSET_CATEGORY.PLEASE_SELECT_ASSET,
                    },
                  ]}
                >
                  <AlectifyButton
                    text={
                      (!isEmpty(selectedAsset) &&
                        truncate(selectedAsset?.package_name, {
                          length: 20,
                          omission: "...",
                        })) ||
                      state?.data?.packageroom?.name ||
                      AlectifyText.PARENT_ASSET_NAME
                    }
                    type="primary"
                    htmlType="button"
                    className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                    onClick={equipmentModalHandler}
                    block
                  />
                </Form.Item>
              ) : null}
            </Col>
          </Row>
          <Col span={24} className="mb-10">
            <Row justify={"start"} align={"middle"} gutter={20}>
              <Col span={8}>
                <Form.Item
                  className="mb-0"
                  name="serial_number"
                  label={AlectifyText.ITEM_NUMBER_ASSET}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.ITEM_NUMBER_ASSET,
                      ),
                    },
                    {
                      message: "Max limit reached",
                      validator: validateSerialNumber,
                    },
                  ]}
                >
                  <AlectifyInput
                    name="serial_number"
                    type="number"
                    placeholder="Enter Item Number"
                  />
                </Form.Item>
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="name"
                  label={
                    parentFormActive === "Tag"
                      ? AlectifyText.SUB_ASSET_NAME
                      : AlectifyText.PARENT_ASSET_NAME
                  }
                  type="text"
                  placeholder={`Enter ${
                    parentFormActive === "Tag"
                      ? AlectifyText.SUB_ASSET_NAME
                      : AlectifyText.PARENT_ASSET_NAME
                  }  Name`}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.SUB_ASSET_NAME,
                      ),
                    },
                  ]}
                />
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="description"
                  label={AlectifyText.DESCRIPTION}
                  type="textarea"
                  placeholder="Enter Description"
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                        "{fieldName}",
                        AlectifyText.DESCRIPTION,
                      ),
                    },
                  ]}
                  max={255}
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <h4 className="background-color-light-grey p-5 m-0">
              Manufacturer information
            </h4>
          </Col>
          <Col span={24}>
            <Row
              justify={"start"}
              align={"middle"}
              gutter={20}
              className="mt-15"
            >
              <Col span={8}>
                <Form.Item
                  name="installation_date"
                  label={AlectifyText.INSTALLATION_DATE}
                >
                  <AlectifyDatePicker className="full-width-picker alectify-input" />
                </Form.Item>
              </Col>

              <Col span={8}>
                <Form.Item
                  name="warranty_date"
                  label={AlectifyText.WARRANTY_END_DATE}
                >
                  <AlectifyDatePicker className="full-width-picker alectify-input" />
                </Form.Item>
              </Col>
              <Col span={8}>
                <OrganizationsAutoComplete
                  name="manufacture"
                  label={AlectifyText.MANUFACTURER}
                  placeholder="Enter Manufacturer"
                  required
                />
              </Col>
              <Col span={8}>
                <OrganizationsAutoComplete
                  name="preferred_supplier"
                  label={AlectifyText.PREFERRED_SUPPLIER}
                  placeholder="Enter Preferred Supplier"
                />
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="model"
                  label={AlectifyText.MODEL}
                  type="text"
                  placeholder="Enter Model"
                />
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="model_serial_number"
                  label={AlectifyText.SERIAL_NUMBER}
                  type="text"
                  placeholder="Enter Serial Number"
                />
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <h4 className="background-color-light-grey p-5 mb-15 mt-10">
              Other information
            </h4>
          </Col>

          <Col span={24}>
            <Row justify={"start"} align={"middle"} gutter={20}>
              <Col span={8}>
                <AlectifyInput
                  name="asset_type"
                  label={AlectifyText.ASSET_TYPE}
                  type="text"
                  placeholder="Select Asset Type"
                />
              </Col>

              <Col span={8}>
                <AlectifySelect
                  name="status"
                  label={AlectifyText.ASSET_STATUS}
                  placeholder={`Enter ${AlectifyText.ASSET_STATUS}`}
                  options={[
                    {
                      label: "Active",
                      value: AssetStatusEnum.Active,
                    },
                    {
                      label: "Spare",
                      value: AssetStatusEnum.Spare,
                    },
                    {
                      label: "Inactive",
                      value: AssetStatusEnum.Inactive,
                    },
                  ]}
                />
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="group"
                  label={AlectifyText.GROUP}
                  type="text"
                  placeholder="Enter Group"
                />
              </Col>

              <Col span={8}>
                <AlectifyInput
                  name="location"
                  label={AlectifyText.LOCATION}
                  type="text"
                  placeholder="Enter Location"
                />
              </Col>
            </Row>
          </Col>
        </Form>
      </Spin>
    </>
  );
};

export default memo(AddAssetManualForm);
