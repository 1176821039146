import { Form } from "antd";
import { PAGINATION } from "constants/index";
import { MODAL_NAMES } from "constants/modal.constants";
import { useEffect, useState } from "react";
import { IPmExternal } from "redux/components/pm-external";
import { ISparePartDraw } from "redux/components/spare-parts";
import { ITask } from "redux/components/tasks";
import { fetchWorkOrderSparePartsHisotry } from "services/spare-parts/spare-parts.service";
import AlectifyButton from "../button";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import AlectifyTable from "../table";
import { IPagination } from "../table/AlectifyTable.interface";
import DrawWorkOrderSparePart from "./DrawWorkOrderSparePart";
import useColumns from "./effects/useWorkOrderSparePartsHistoryColumns";

interface Props {
  record: ITask | IPmExternal;
  showDrawSparePartsButton?: boolean;
  callback?: () => void;
  workOrderSParePartsHistory?: {
    data: ISparePartDraw[];
    total: number;
    fetching: boolean;
  };
  workOrderGetHistory?: (value?: any) => void;
  workOrderDetailPage?: boolean;
}

const WorkOrderSpareParts: React.FC<Props> = (props: Props) => {
  const { record, showDrawSparePartsButton } = props;
  const [FormInstance] = Form.useForm();

  const [history, setHistory] = useState<{
    data: ISparePartDraw[];
    total: number;
    fetching: boolean;
  }>({ fetching: false, data: [], total: 0 });

  const getHistory = async (options?: IPagination) => {
    try {
      setHistory({ ...history, fetching: true });
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };
      const response = await fetchWorkOrderSparePartsHisotry(record.id, params);
      setHistory({
        fetching: false,
        data: response.data,
        total: response.meta?.totalItems || 0,
      });
    } catch (error) {
      console.log("error: ", error);
      setHistory({ ...history, fetching: false });
    }
  };

  const openDrawForm = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 500,
      name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
      title: "Draw Spare Part",
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.WORK_ORDER_DRAW_FORM_MODAL,
        });
      },
      onOk: FormInstance.submit,
      okText: "Submit",
      children: (
        <DrawWorkOrderSparePart
          getHistory={getHistory}
          workOrder={record}
          FormInstance={FormInstance}
          callback={props.callback}
        />
      ),
    });
  };

  const columns = useColumns();

  useEffect(() => {
    if (props.workOrderDetailPage && props.workOrderGetHistory) {
      props.workOrderGetHistory();
    } else {
      getHistory();
    }
  }, [record.id]);

  return (
    <AlectifyTable
      columns={columns}
      dataSource={
        props.workOrderDetailPage && props?.workOrderSParePartsHistory
          ? props?.workOrderSParePartsHistory?.data
          : history.data
      }
      onDataFetch={
        props.workOrderDetailPage && props.workOrderGetHistory
          ? props.workOrderGetHistory
          : getHistory
      }
      total={
        props.workOrderDetailPage && props.workOrderSParePartsHistory
          ? props?.workOrderSParePartsHistory?.total
          : history?.total
      }
      loading={
        props.workOrderDetailPage && props.workOrderSParePartsHistory
          ? props?.workOrderSParePartsHistory?.fetching
          : history.fetching
      }
      extraButton={
        (showDrawSparePartsButton && (
          <AlectifyButton
            text="Draw Spare Part"
            type="primary"
            onClick={() => openDrawForm()}
          />
        )) || <></>
      }
    />
  );
};

WorkOrderSpareParts.defaultProps = {
  record: {} as any,
  showDrawSparePartsButton: false,
  workOrderDetailPage: false,
};
export default WorkOrderSpareParts;
