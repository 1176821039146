import { IReduxActionResponse } from "redux/interfaces";
import { IProcedureListResponse } from "services/procedures/procedures.interface";
import { ProcedureInitialState } from "../procedures.initialState";
import {
  IProceduresInitialState,
  IProcedureView,
} from "../procedures.interface";

export const getProcedures = (
  state: any,
  action: IReduxActionResponse<IProcedureListResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    procedures: payload?.data,
    meta: payload?.meta,
  };
};

export const showProcedureLoader = (
  state: any,
  action: IReduxActionResponse<IProceduresInitialState>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
  };
};

export const resetProcedureState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...ProcedureInitialState,
  };
};

export const setActiveTableView = (
  state: any,
  action: IReduxActionResponse<IProcedureView>,
) => {
  const { payload } = action;
  return {
    ...state,
    tableView: payload,
  };
};

export const setActiveToggleButton = (
  state: any,
  action: IReduxActionResponse<IProcedureView>,
) => {
  const { payload } = action;
  return {
    ...state,
    activeToggleButton: payload,
  };
};
