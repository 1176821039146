import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";
import { memo } from "react";

interface ICalendarIconProps {
  fill?: string | null;
  className?: string | null;
}
const CalendarIcon: React.FC<ICalendarIconProps> = (
  props: ICalendarIconProps,
) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.className} "Alectify-siderbar-menu-icon"`}
    >
      <g id="Group 27987">
        <path
          id="Vector"
          d="M18.6313 1V3M5.36816 1V3"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M9.78983 15.998L9.78982 12.3452C9.78982 12.1535 9.63869 11.998 9.45226 11.998H8.68457M13.8016 15.998L15.2987 12.3472C15.3686 12.1765 15.2198 11.998 15.0074 11.998H13.1056"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector_3"
          d="M1.5 11.2432C1.5 6.88594 1.5 4.70728 2.88392 3.35364C4.26784 2 6.49523 2 10.95 2H13.05C17.5048 2 19.7322 2 21.1161 3.35364C22.5 4.70728 22.5 6.88594 22.5 11.2432V11.7568C22.5 16.1141 22.5 18.2927 21.1161 19.6464C19.7322 21 17.5048 21 13.05 21H10.95C6.49523 21 4.26784 21 2.88392 19.6464C1.5 18.2927 1.5 16.1141 1.5 11.7568V11.2432Z"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M5.36816 7.00195H18.6313"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default memo(CalendarIcon);
