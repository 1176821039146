import React, { useState } from "react";
import { get, truncate } from "lodash";
import { Space, Tooltip } from "antd";
import { formatDate, isExternalUser } from "utils/helpers";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import TextToLink from "components/shared/text-to-link";
import AssetDetail from "components/shared/asset-detail";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import PackageDetailAssets from "components/shared/package-detail-assets";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { useNavigate } from "react-router-dom";
import RightArrowIcon from "components/icons/RightArrowIcon";
import { IPredictionTableColumnHookProps } from "./MaintenancePrediction.interface";
import AlectifyBulletIcon from "components/icons/BulletIcon";
import { IPredictionStateData } from "services/prediction/prediction.interface";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import {
  setActiveMasterProject,
  setActiveSubProject,
} from "redux/components/common/sources";
import { ROUTES } from "routes/Routes.constants";
import dayjs from "dayjs";
import { LeftArrowIcon } from "components/icons";
import { DATE_FORMAT_FULL_MONTH_NAME } from "constants/index";
import SwitchSubProjectDrawer from "pages/sub-project/swtich-sub-project-drawer";
import AlectifyButton from "../button";

const usePredictionColumn = (props: IPredictionTableColumnHookProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedRecord, setSelectedRecord] = useState<any>(null);
  const { user } = useSelector(({ auth }: IRootState) => auth);

  const onMasterProjectClick = (masterProject: any) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveMasterProject(masterProject));
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}`);
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: [
          DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
          DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
        ],
      });
    }
  };

  const onSubProjectClick = (masterProject: any, subProject: any) => {
    if (!isExternalUser(user)) {
      dispatch(setActiveSubProject(subProject));
      dispatch(setActiveMasterProject(masterProject));
      navigate(
        `${ROUTES.MASTER_PROJECT_DETAILS}/${masterProject?.id}${ROUTES.SUB_PROJECT}/${subProject?.id}`,
      );
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: [
          DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
          DRAWER_CONSTANTS.MAINTENANCE_PREDICTION_DRAWER,
        ],
      });
    }
  };

  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      visible: true,
      key: "master-project",
      dataIndex: "project",
      title: AlectifyText.SITE_AND_ASSET_CATEGORY,
      width: 100,
      ellipsis: {
        showTitle: false,
      },
      render: (_: any, record: IPredictionStateData) => {
        const masterProject = {
          id: record?.mainproject_id,
          name: record?.mainproject_name,
        };
        const subProject = {
          id: record?.subproject_id,
          name: record?.subproject_name,
        };

        return (
          <Space direction="vertical" size={15}>
            {masterProject?.name ? (
              <Tooltip placement="topLeft" title={masterProject?.name}>
                {masterProject?.name
                  ? truncate(masterProject?.name, {
                      length: 20,
                      omission: "...",
                    })
                  : "-"}
              </Tooltip>
            ) : (
              "-"
            )}
            <Tooltip placement="topLeft" title={subProject?.name}>
              <Space direction="horizontal" size={8}>
                <AlectifyBulletIcon />
                <TextToLink
                  className="text-to-link-options"
                  text={truncate(subProject?.name)}
                  underline={!isExternalUser(user)}
                  onClick={() => {
                    DrawerServiceInstance.open(AlectifyDrawer, {
                      width: 420,
                      name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                      title: masterProject?.name,
                      onClose: () =>
                        DrawerServiceInstance.close(AlectifyDrawer, {
                          name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                        }),
                      children: (
                        <SwitchSubProjectDrawer
                          masterProjectId={masterProject.id}
                          navigate={navigate}
                        />
                      ),
                      customFooter: (
                        <Space>
                          <AlectifyButton
                            text={AlectifyText.VISIT_ASSET_CATEGORY}
                            type="default"
                            className="light-blue-button"
                            onClick={() =>
                              onSubProjectClick(masterProject, subProject)
                            }
                          />
                          <AlectifyButton
                            text={AlectifyText.VISIT_SITE}
                            className="ant-btn-primary"
                            onClick={() => onMasterProjectClick(masterProject)}
                          />
                        </Space>
                      ),
                    });
                  }}
                />{" "}
              </Space>
            </Tooltip>
          </Space>
        );
      },
    },

    {
      width: 120,
      visible: true,
      dataIndex: "object_type",
      searchable: true,
      title: AlectifyText.PARENT_OR_SUB_ASSET,
      ellipsis: true,
      render: (_, data: IPredictionStateData) => {
        const subProject = get(data, "subproject_id", "-");
        return (
          <Space direction="vertical" size={15} className="alectify-trim-text">
            <Tooltip
              placement="topLeft"
              title={
                data.object_type === "Tag" ? data?.tag_name : data?.package_name
              }
            >
              <TextToLink
                className="text-to-link-options"
                text={truncate(
                  data.object_type === "Tag"
                    ? data?.tag_name
                    : data?.package_name,
                )}
                onClick={() => {
                  DrawerServiceInstance.open(AlectifyDrawer, {
                    width: 800,
                    title: data?.object_name || "-",
                    name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                    onClose: () =>
                      DrawerServiceInstance.close(AlectifyDrawer, {
                        name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
                      }),
                    children:
                      data?.object_type === "Tag" ? (
                        // Asset detail drawer
                        <AssetDetail
                          predictionData={data}
                          assetId={data?.tag_id}
                          subProjectId={subProject}
                          navigate={navigate}
                        />
                      ) : (
                        // Package detail drawer
                        <PackageDetailAssets
                          predictionData={data}
                          subProjectId={subProject || ""}
                          assetPackageId={data.package_id}
                          navigate={navigate}
                        />
                      ),
                  });
                }}
              />
            </Tooltip>
            {data?.object_type === "Tag" ? <AssetTag /> : <AssetPackageTag />}
          </Space>
        );
      },
    },
    {
      width: 200,
      dataIndex: "next_breakdown_date",
      title: "Next Break Down",
      visible: true,
      render: (value: any, record: IPredictionStateData) => (
        <div className="d-flex justify-content-space-between">
          <div className="next-break-down">
            {formatDate(dayjs(value), DATE_FORMAT_FULL_MONTH_NAME)}
          </div>
          <div
            className={`rightArrowIcon cursor-pointer ${
              selectedRecord?.id === record?.id
                ? "rightArrowIcon-active"
                : "rightArrowIcon-not-active "
            }`}
            onClick={() => {
              props.openGraphStats(record);
              setSelectedRecord(record);
            }}
          >
            {selectedRecord?.id === record?.id &&
            props.showMaintenanceDetail ? (
              <LeftArrowIcon />
            ) : (
              <RightArrowIcon />
            )}
          </div>
        </div>
      ),
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), [columns]);
};

export default usePredictionColumn;
