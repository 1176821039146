import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName } from "./asset-package.constants";
import { assetPackagesInitialState } from "./asset-package.initialState";
import { IAssetPackageActions } from "./asset-packages.interface";
import * as AssetPackagesAffects from "./affects";

export * from "./asset-package.constants";
export * from "./asset-packages.interface";

const { actions, reducer } = createActionAndReducers<IAssetPackageActions>(
  reducerName,
  assetPackagesInitialState,
  AssetPackagesAffects,
);

export { actions, assetPackagesInitialState, reducer };
