import React from "react";

interface SendIconProps {
  fill?: string;
}

const SendIcon: React.FC<SendIconProps> = (props: SendIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="25"
      viewBox="0 0 34 31"
      fill="none"
    >
      <path
        d="M0.916637 6.05629C0.722193 6.12615 0.55214 6.24873 0.424505 6.41105C0.296871 6.57336 0.216411 6.76936 0.191657 6.97827C0.166902 7.18717 0.198774 7.40119 0.283895 7.59765C0.369016 7.7941 0.504213 7.96566 0.675154 8.09414L11.1192 15.9493L21.5213 8.22296C21.7643 8.0425 22.072 7.97 22.3768 8.02141C22.6816 8.07283 22.9585 8.24395 23.1465 8.49712C23.3346 8.7503 23.4184 9.0648 23.3796 9.37144C23.3407 9.67807 23.1824 9.95172 22.9394 10.1322L12.5372 17.8585L17.0399 30.1266C17.1138 30.3271 17.239 30.5057 17.4025 30.6437C17.566 30.7817 17.7615 30.8738 17.9685 30.9103C18.1755 30.9468 18.3861 30.9263 18.5782 30.8511C18.7703 30.7759 18.9367 30.6487 19.0597 30.4829C25.3212 22.0328 30.0677 12.5053 33.0947 2.31079C33.1471 2.13471 33.1568 1.94689 33.123 1.76284C33.0892 1.5788 33.0129 1.40381 32.9004 1.25234C32.7879 1.10087 32.6424 0.977269 32.476 0.891745C32.3096 0.806221 32.1269 0.761233 31.9432 0.760497C21.3087 0.712499 10.8157 2.5035 0.916637 6.05629Z"
        fill={props.fill || "#0954F1"}
      />
    </svg>
  );
};

export default SendIcon;
