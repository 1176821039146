import { Col, Row, Space, Tag, Typography } from "antd";
import { IShiftCardsProps } from "./DataRoundShiftTimeline.interface";
import { IDataRoundInsights } from "services/data-round/data-round.interface";
import { isEmpty } from "lodash";
import AvatarGroup from "components/shared/avatar-group";
import AlectifyButton from "components/shared/button";
import DataRoundStatusTag from "components/shared/tags/DataRoundStatusTag";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import RoundsDetailTable from "components/shared/rounds-detail-table/RoundsDetailTable";
import { useParams } from "react-router-dom";

const ShiftCards: React.FC<IShiftCardsProps> = (props: IShiftCardsProps) => {
  const { projectId } = useParams();
  const shift = (): IDataRoundInsights | undefined => {
    return props.insights?.find(
      (insight: IDataRoundInsights) =>
        insight.shift?.id === props.shiftSchedule?.id &&
        insight.shift_date === props.shiftDate,
    );
  };
  const formatTime = (timeString: string) => {
    // Split the time string by ':' and '.' to get hours, minutes, and seconds
    const [hours, minutes] = timeString.split(/[:.]/);

    // Return the formatted time
    return `${hours}:${minutes}`;
  };
  const currentShift = shift();

  const openRoundDetails = (roundId: string) => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.ROUND_DETAIL_MODAL,
      title: "Round Detail",
      width: 1200,
      footer: null,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ROUND_DETAIL_MODAL,
        });
      },
      children: (
        <RoundsDetailTable projectId={projectId || ""} roundId={roundId} />
      ),
    });
  };
  return (
    <div className="shift-card mb-10">
      <Row justify={"space-between"} align={"middle"} className="mb-20">
        <Col span={12}>
          <Typography.Title level={5} className="m-0">
            {props.shiftSchedule.name || "-"}
          </Typography.Title>
        </Col>
        <Col span={12} className="text-align-right">
          <DataRoundStatusTag
            status={(currentShift && currentShift?.status) || 0}
          />
        </Col>
      </Row>
      <Row justify={"space-between"} align={"middle"} className="mt-5">
        {!isEmpty(currentShift) ? (
          <>
            {currentShift?.user && (
              <Col span={6}>
                <Space>
                  <AvatarGroup
                    users={[currentShift.user]}
                    showImage
                    size="small"
                  />
                  {`${currentShift.user.first_name || ""} ${
                    currentShift.user.last_name || ""
                  }`.trim()}
                </Space>
              </Col>
            )}
            <Col span={5}>
              <Space>
                Issues Reported:
                <Tag color={"#F3F3F3"} className="steps-tag round-tag">
                  <Space size={2}>{currentShift?.issues_count || 0}</Space>
                </Tag>
              </Space>
            </Col>
            <Col span={9}>
              <Space>
                <span>
                  Start Time:{" "}
                  {(currentShift?.start_time &&
                    formatTime(currentShift?.start_time)) ||
                    "-"}
                </span>
                <span>
                  Completed at:{" "}
                  {(currentShift?.end_time &&
                    formatTime(currentShift?.end_time)) ||
                    "-"}
                </span>
              </Space>
            </Col>
          </>
        ) : (
          "Not Started"
        )}
        <Col span={4} className="text-align-right">
          <AlectifyButton
            text="View Detail"
            className="alectify-primary-button"
            type="primary"
            disabled={isEmpty(currentShift)}
            onClick={() => openRoundDetails(currentShift?.id || "")}
          />
        </Col>
      </Row>
    </div>
  );
};

export default ShiftCards;
