import { useState } from "react";
import { useDispatch } from "react-redux";
import { setUploadedDocuments } from "redux/components/documents/sources";
import SelectedDocuments from "./SelectedDocuments";
import { IAddDocumentProps } from "./documents-table/DocumentsTable.interface";
import { debounce } from "lodash";

const AddDocument: React.FC<IAddDocumentProps> = (props) => {
  const dispatch = useDispatch();
  const [selectedDocuments, setSelectedDocuments] = useState<any[]>([]);

  const markDocumentsAsUploaded = (documents: any[]) => {
    const updatedSelectedDocuments = selectedDocuments.filter(
      (document) =>
        !documents.some(
          (uploadedDocument) => document.uid === uploadedDocument.uid,
        ),
    );

    setSelectedDocuments(updatedSelectedDocuments);
    dispatch(setUploadedDocuments(documents));
  };

  const handleFilesUpload = debounce(({ fileList }: any) => {
    const newDocuments = fileList.map((file: any) => ({
      ...file,
      isSelected: false,
      isUploaded: false,
    }));
    setSelectedDocuments((prevDocuments: any) => [
      ...prevDocuments,
      ...newDocuments,
    ]);
  }, 300);

  const removeSingleDocument = (uid: string) => {
    setSelectedDocuments((prevSelectedDocuments) => {
      return prevSelectedDocuments.filter((document) => document.uid !== uid);
    });
  };

  return (
    <>
      <SelectedDocuments
        handleFilesUpload={handleFilesUpload}
        documentForm={props.documentForm}
        selectedDocuments={selectedDocuments}
        setSelectedDocuments={setSelectedDocuments}
        markDocumentsAsUploaded={markDocumentsAsUploaded}
        getDocuments={props.getDocuments && props.getDocuments}
        removeSingleDocument={removeSingleDocument}
      />
    </>
  );
};

export default AddDocument;
