import React from "react";

const EditIconFilledIcon: React.FC<any> = (props: any = {}) => {
  return (
    <svg
      {...props}
      width="25"
      height="27"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.12 23.91H4.88C4.39325 23.91 4 24.3032 4 24.79V25.78C4 25.901 4.099 26 4.22 26H25.78C25.901 26 26 25.901 26 25.78V24.79C26 24.3032 25.6068 23.91 25.12 23.91ZM8.00675 21.6C8.06175 21.6 8.11675 21.5945 8.17175 21.5863L12.7972 20.775C12.8523 20.764 12.9045 20.7393 12.943 20.698L24.6002 9.04075C24.6257 9.01531 24.646 8.98509 24.6598 8.95182C24.6736 8.91855 24.6807 8.88289 24.6807 8.84688C24.6807 8.81086 24.6736 8.7752 24.6598 8.74193C24.646 8.70866 24.6257 8.67844 24.6002 8.653L20.0298 4.07975C19.9775 4.0275 19.9087 4 19.8345 4C19.7602 4 19.6915 4.0275 19.6392 4.07975L7.982 15.737C7.94075 15.7782 7.916 15.8277 7.905 15.8827L7.09375 20.5082C7.067 20.6556 7.07656 20.8072 7.1216 20.95C7.16664 21.0928 7.24581 21.2224 7.35225 21.3277C7.53375 21.5037 7.762 21.6 8.00675 21.6Z"
        fill="#0954F1"
      />
    </svg>
  );
};

export default EditIconFilledIcon;
