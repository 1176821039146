import { PROJECT_THEME_COLOR_SECONDARY } from "components/shared/phone-number/constants";
import { memo } from "react";

const BellIcon: React.FC<{
  fill?: string;
  className?: string;
  onClick?: () => void;
}> = ({
  className,
  ...props // Spread the remaining props
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill={props.fill || "none"}
      width="26"
      height="26"
      viewBox="-4 -5 30 26"
      strokeWidth="1.5"
      stroke={PROJECT_THEME_COLOR_SECONDARY}
      className={`size-6 ${className}`} // Combine the className prop with default classes
      {...props} // Spread other props
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0"
      />
    </svg>
  );
};

export default memo(BellIcon);
// #0954f1
