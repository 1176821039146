import { config } from "utils/configs";
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";

// Firebase Config
export const firebaseConfig = {
  appId: config.REACT_APP_FIREBASE_APP_ID,
  apiKey: config.REACT_APP_FIREBASE_API_KEY,
  projectId: config.REACT_APP_FIREBASE_PROJECT_ID,
  authDomain: config.REACT_APP_FIREBASE_AUTH_DOMAIN,
  storageBucket: config.REACT_APP_FIREBASE_STORAGE_BUCKET,
  measurementId: config.REACT_APP_FIREBASE_MEASUREMENT_ID,
  messagingSenderId: config.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Messaging service
export const messaging = getMessaging(app);
