import { createActionAndReducers } from "redux/utils/reducer.helper";

import { INotificationActions } from "./notifications.interface";

import { reducerName } from "./notifications.constants";

import notificationInitialState from "./notifications.initialState";

import * as notificationEffects from "./affects";

export * from "./notifications.interface";

export * from "./notifications.constants";

const { actions, reducer } = createActionAndReducers<INotificationActions>(
  reducerName,
  notificationInitialState,
  notificationEffects,
);

export { actions, notificationInitialState, reducer };
