export const DATE_FORMAT = "DD-MM-YYYY";
export const DISPLAY_DATE_FORMAT = "MMM DD, YYYY";
export const DISPLAY_DATETIME_FORMAT = "MMM DD, YYYY  h:mm a";
export const CSV_DATE_FORMAT = "MM/DD/YYYY";
export const INPUT_DATE_FORMAT = "MM-DD-YYYY";
export const SERVER_DATE_FORMAT = "YYYY-MM-DD";
export const DISPLAY_DATE_TIME_FORMAT_24H = "MMM DD, YYYY, HH:mm:ss";
export const DISPLAY_DATE_TIME_FORMAT_12H = "MMM DD, YYYY, h:mm:ss A";
export const DATE_FORMAT_FULL_MONTH_NAME = "MMMM D, YYYY";

export const monthMap: Record<string, string> = {
  Jan: "January",
  Feb: "February",
  Mar: "March",
  Apr: "April",
  May: "May",
  Jun: "June",
  Jul: "July",
  Aug: "August",
  Sep: "September",
  Oct: "October",
  Nov: "November",
  Dec: "December",
};
