import { MULTIPART_HEADERS, http } from "utils/client";
import { PROJECT_USERS, USER, VALIDATE_EMAIL } from "./user.endpoints";
import { IUserValidationResponse } from "redux/components/users";
import { IUserId } from "redux/components/Auth";

/**
 * Asynchronous function for updating a user's profile data via an HTTP PATCH request.
 * @param {any} body - The data to be sent in the request body.
 * @returns {Promise<any>} A Promise that resolves to the updated user's profile data.
 * @throws {Error} If there's an error during the HTTP request.
 */
export const updateUserProfile = async (body: any): Promise<any> => {
  try {
    const response = await http.patch(USER, body, MULTIPART_HEADERS);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Asynchronous function for fetching a user's profile data from a remote HTTP endpoint.
 * @returns {Promise<any>} A Promise that resolves to the user's profile data.
 * @throws {Error} If there's an error during the HTTP request.
 */
export const fetchUserProfile = async (): Promise<any> => {
  try {
    const response = await http.get(USER);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Asynchronous function for fetching a user's profile data from a remote HTTP endpoint.
 * @param {email} body - Email to be sent to check if the user already exist.
 * @returns {Promise<IUserValidationResponse>} A Promise that resolves to the user's profile data.
 * @throws {Error} If there's an error during the HTTP request.
 */
export const validateEmail = async (body: {
  email: string;
}): Promise<IUserValidationResponse> => {
  try {
    const response = await http.post(VALIDATE_EMAIL, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch details of users associated with a specific project.
 *
 * @param {string} projectId - The ID of the project for which user details are fetched.
 * @param {string} type - The type of users to fetch is string type.
 * @returns {Promise<any>} - A Promise that resolves with the details of the project users.
 * @throws {Error} - Throws an error if the fetching fails.
 */

export const fetchProjectUsersDetail = async (
  projectId: string,
  type: string,
) => {
  try {
    const response = await http.get(
      PROJECT_USERS.replace("{projectId}", projectId).replace("{type}", type),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Remove a user from a specific project.
 *
 * @param {string} projectId - The ID of the project from which the user should be removed.
 * @param {string} type - The type of user to remove is string type.
 * @param {any} body - The request body containing information about the user to be removed.
 * @returns {Promise<any>} - A Promise that resolves with the result of the removal operation.
 * @throws {Error} - Throws an error if the removal fails.
 */
export const removeUserFromProject = async (
  projectId: string,
  type: string,
  body: IUserId,
) => {
  try {
    const response = await http.delete(
      PROJECT_USERS.replace("{projectId}", projectId).replace("{type}", type),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
