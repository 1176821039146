import React from "react";
import { Space, Tag, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { IMasterProject } from "redux/components/master-project";
import AlectifyText from "static/texts.json";
import TextToLink from "components/shared/text-to-link";
import { truncate } from "lodash";

interface Props {
  masterProjectId: string;
}

const useSimilarPartsColumns = (props: Props) => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: AlectifyText.SITE,
      dataIndex: "project",
      visible: true,
      ellipsis: true,
      width: 150,
      render: (masterProject: IMasterProject) => (
        <Tooltip placement={"topLeft"} title={masterProject.name}>
          <Space direction="vertical">
            <TextToLink
              underline={false}
              text={truncate(masterProject.name, {
                length: 20,
                omission: "...",
              })}
            />
            {masterProject.id === props.masterProjectId && (
              <Tag color="#F14E4E">Same Site</Tag>
            )}
          </Space>
        </Tooltip>
      ),
    },
    {
      width: 100,
      dataIndex: "remainingQuantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.IN_HAND_QTY,
    },
    {
      width: 100,
      dataIndex: "minimumQuantity",
      visible: true,
      ellipsis: true,
      title: AlectifyText.MIN_QTY,
    },
  ];
  return React.useMemo(() => columns, []);
};

export default useSimilarPartsColumns;
