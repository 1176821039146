export const subProjectInitialState = {
  data: [],
  message: "",
  meta: {
    next: "",
    total_count: 0,
    total_pages: 0,
    previous: null,
  },
  fetching: false,
};
