import React, { memo, useState } from "react";
import { Form, Space, Spin, message } from "antd";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import { createCompany } from "services/admin/admin.service";
import { ICreateCompanyModal } from "./Admin.interface";

const CreateCompanyModal: React.FC<ICreateCompanyModal> = ({
  getCompanies,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState<boolean>(false);

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_COMPANY,
    });
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      await createCompany(values);
      setLoading(false);
      onCloseModal();
      getCompanies();
    } catch (ex) {
      console.log(ex);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      setLoading(false);
    }
  };
  return (
    <Spin spinning={loading}>
      <Form form={form} name="basic" layout="vertical" onFinish={onSubmit}>
        <AlectifyInput
          label="Company name"
          name="name"
          type="text"
          rules={[
            {
              required: true,
              message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
            },
          ]}
          placeholder="Company name"
        />
        <Space className="d-flex justify-flex-end">
          <AlectifyButton text="Cancel" type="primary" onClick={onCloseModal} />
          <AlectifyButton text="Submit" type="primary" htmlType="submit" />
        </Space>
      </Form>
    </Spin>
  );
};
export default memo(CreateCompanyModal);
