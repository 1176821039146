import { memo } from "react";

interface ICrossIconProps {
  fill?: string;
}
const CrossIcon: React.FC<ICrossIconProps> = (props: ICrossIconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M18.3755 15.6308C17.1814 14.502 16.0882 13.4677 15.0953 12.528C15.0756 12.5094 15.0756 12.4908 15.0953 12.4722C16.1328 11.49 17.2047 10.4759 18.3107 9.42939C18.5422 9.21011 18.7031 8.98021 18.7938 8.73969C19.0928 7.94699 18.822 7.07625 18.1095 6.61114C17.4097 6.15506 16.4444 6.25594 15.8896 6.84104C14.842 7.94699 13.7966 9.05242 12.7538 10.1573C12.7538 10.1573 12.7533 10.1578 12.7528 10.1584C12.7459 10.1653 12.7347 10.1647 12.7278 10.1573C11.7557 9.13259 10.723 8.0415 9.62976 6.88457C9.32765 6.56442 8.96024 6.38124 8.52858 6.33505C7.08495 6.18107 6.082 7.69904 6.79452 8.97862C6.87947 9.131 7.06106 9.33753 7.3398 9.59929C8.40806 10.6022 9.42375 11.5617 10.3864 12.4775C10.3864 12.4775 10.3869 12.4781 10.3874 12.4786C10.3996 12.4913 10.3991 12.5115 10.3864 12.5237L7.71889 15.0452L7.48156 15.2698C7.26122 15.4795 7.12158 15.6133 7.06371 15.6722C6.69577 16.0433 6.52958 16.518 6.56515 17.0951C6.66603 17.779 7.03929 18.2691 7.68438 18.5659L8.04383 18.654C8.61672 18.7512 9.19279 18.5547 9.59525 18.1507C9.72214 18.0232 9.85329 17.8804 9.98868 17.7222L10.3832 17.2905L10.637 17.0495L12.7183 14.8482C12.7305 14.8355 12.7421 14.8355 12.7544 14.8477C13.7 15.84 14.6493 16.8456 15.6034 17.865C16.0367 18.3275 16.3807 18.6211 16.9849 18.6689C17.5918 18.7166 18.0967 18.5085 18.5002 18.0445C19.1002 17.3548 19.0402 16.2589 18.3755 15.6308Z"
        fill={props.fill || "#EE4445"}
      />
    </svg>
  );
};

export default memo(CrossIcon);
