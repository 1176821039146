import { http, pmHttp } from "utils/client";
import {
  IDashboardOverviewResponse,
  IDashboardPlatformSummaryResponse,
  IDashboardResponse,
} from "./dashboard.interface";
import { AxiosResponse } from "axios";
import {
  GET_DASHBOARD_GRAPH_OVERDUE_WORK_ORDERS_DETAILS,
  GET_DASHBOARD_OVERVIEW_DETAILS,
  GET_DASHBOARD_PLATFORM_SUMMARY,
} from "./dashboard.endpoints";
import { IParams } from "redux/interfaces";

/**
 * Fetches dashboard details from the server.
 * @returns A Promise that resolves to an IDashboardResponse.
 * @throws Throws an error if the request fails.
 */
export const fetchDashboardOverDueWorkOrdersDetails = async (
  params?: any, // Make params optional
): Promise<IDashboardResponse> => {
  try {
    const response: AxiosResponse<IDashboardResponse> = await pmHttp.get(
      GET_DASHBOARD_GRAPH_OVERDUE_WORK_ORDERS_DETAILS,
      params,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetch dashboard overview details using an HTTP GET request.
 * @returns A promise that resolves to the dashboard overview response.
 * @throws An error if the request fails.
 */
export const fetchDashboardOverviewDetails = async (
  params?: IParams,
): Promise<IDashboardOverviewResponse> => {
  try {
    const response: AxiosResponse<IDashboardOverviewResponse> = await http.get(
      GET_DASHBOARD_OVERVIEW_DETAILS,
      params,
    );

    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchPlatformSummary = async (
  params?: IParams,
): Promise<IDashboardPlatformSummaryResponse> => {
  try {
    const response: AxiosResponse<IDashboardPlatformSummaryResponse> =
      await pmHttp.get(GET_DASHBOARD_PLATFORM_SUMMARY, params);

    return response.data;
  } catch (error) {
    throw error;
  }
};
