import { AxiosResponse } from "axios";
import { http } from "utils/client";
import { GET_USERS_LIST, NEW_PROJECT_USERS } from "./users.endpoints";
import { IUsersResponse, IusersParams } from "redux/components/users";
import { IUser } from "redux/components/Auth";
import { IParams } from "redux/interfaces";

/**
 * Fetches projects users for a given sub project ID.
 *
 * @param projectId - The ID of the sub project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const fetchProjectUsers = async (
  projectId: string,
  params?: IusersParams, // Make params optional
): Promise<IUsersResponse> => {
  try {
    const url = NEW_PROJECT_USERS.replace("{projectId}", projectId);
    const response: AxiosResponse<IUsersResponse> = await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Fetches Master project users list.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const fetchAllUsers = async (
  projectId: string,
  params?: IParams, // Make params optional
): Promise<IUsersResponse> => {
  try {
    const url = GET_USERS_LIST.replace("{projectId}", projectId);
    const response: AxiosResponse<IUsersResponse> = await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Creates new user for the master project.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const createNewUser = async (
  projectId: string,
  body: IUser,
): Promise<IUsersResponse> => {
  try {
    const url = GET_USERS_LIST.replace("{projectId}", projectId);
    const response: AxiosResponse<IUsersResponse> = await http.post(url, body);
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Deletes user for the master project.
 *
 * @param projectId - The ID of the master project to fetch project users for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the project users data.
 * @throws If an error occurs during the request.
 */
export const deleteUser = async (
  projectId: string,
  body: { user_id: string },
): Promise<IUsersResponse> => {
  try {
    const url = GET_USERS_LIST.replace("{projectId}", projectId);
    const response: AxiosResponse<IUsersResponse> = await http.delete(
      url,
      body,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};
