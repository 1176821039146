import { useEffect, useState } from "react";
import { PAGINATION } from "constants/index";
import {
  IProjectSparePart,
  ISparePartDrawResponse,
} from "redux/components/spare-parts";
import { IPagination } from "../table/AlectifyTable.interface";
import { fetchSparePartsDrawHistory } from "services/spare-parts/spare-parts.service";
import AlectifyTable from "../table";
import useDrawHistoryColumns from "./effects/useDrawHistoryColumns";

interface IDrawHistoryProps {
  sparePart: IProjectSparePart;
}

const DrawHistory: React.FC<IDrawHistoryProps> = (props: IDrawHistoryProps) => {
  const { sparePart } = props;
  const columns = useDrawHistoryColumns();
  const [drawHistory, setDrawHistory] = useState<ISparePartDrawResponse>({
    data: [],
    fetching: false,
  });

  const getDrawHistory = async (options?: IPagination) => {
    try {
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        search: options?.search,
      };
      setDrawHistory({ ...drawHistory, fetching: true });
      const response = await fetchSparePartsDrawHistory(sparePart.id, params);
      setDrawHistory({
        data: response.data,
        meta: response.meta,
        fetching: false,
      });
    } catch (error) {
      console.log("error: ", error);
      setDrawHistory({ ...drawHistory, fetching: false });
    }
  };

  useEffect(() => {
    getDrawHistory();
  }, [sparePart.id]);

  return (
    <AlectifyTable
      showSearch
      size="small"
      columns={columns}
      loading={drawHistory.fetching}
      dataSource={drawHistory.data}
      customSearchKey="search"
      searchPlaceholder="Search by Activity, PO #"
      onDataFetch={getDrawHistory}
      total={drawHistory.meta?.totalItems || 0}
    />
  );
};

export default DrawHistory;
