import { memo, useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Typography, Form } from "antd";
import { actions } from "redux/components/chat-bot";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { ChatBotIcon } from "components/icons";
import SendIcon from "components/icons/SendIcon";
import AlectifyInput from "components/shared/input";
import WelcomeMessage from "components/shared/welcome-greeting-message";
import { fetchTopFiveAnswers } from "services/chat-bot/chatBot.service";
import { DEFAULT_QUESTIONS, TITLES } from "./Chatbot.constant";
import { IChatStateData } from "./Chatbot.interface";
import { setChatbotResponse } from "redux/components/chat-bot/sources";
import AlectifyButton from "components/shared/button";
import AlectifyText from "static/texts.json";
import ChatBotSource from "./ChatBotSource";
import { processSources } from "./Chatbot.helper";
import "./Chatbot.scss";
import { IChatBotPayload } from "services/chat-bot/chatBot.interface";

const ChatBot: React.FC = () => {
  const { Title } = Typography;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const auth = useSelector((state: IRootState) => state.auth);
  const chatbotState = useSelector((state: any) => state.chatbot);
  const [state, setState] = useState<IChatStateData>(chatbotState);
  const user = useSelector((state: IRootState) => state.auth.user);
  const getQuestionResponse = async (values: { question: string }) => {
    try {
      if (isEmpty(values.question)) {
        return;
      }
      form.setFieldValue("question", null);
      setState((prevState: IChatStateData) => {
        const newState: IChatStateData = {
          ...prevState,
          toggle: true,
          fetching: true,
          chatbotItems: [
            ...prevState.chatbotItems,
            {
              role: "user",
              content: values?.question,
            },
            {
              role: "assistant",
              temporary: true,
            },
          ],
        };
        dispatch(setChatbotResponse(newState));
        return newState;
      });

      getQueryResponse(values);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    setState(chatbotState);
  }, [chatbotState]);

  const getQueryResponse = async (payload: any) => {
    try {
      const chatBotMetaData: IChatBotPayload = {
        messages: [],
        metadata: {
          initialize: isEmpty(chatbotState.chatbotItems),
        },
        companyId: user?.company?.id,
      };
      if (!isEmpty(chatbotState.chatbotItems)) {
        chatbotState.chatbotItems.map((item: any) => {
          chatBotMetaData.messages.push({
            role: item.role,
            content: item.content,
          });
        });
      }
      chatBotMetaData.messages.push({
        role: "user",
        content: payload.question,
      });
      const response = await fetchTopFiveAnswers(chatBotMetaData);

      setState((prevState: IChatStateData) => {
        const { chatbotItems } = prevState;
        const updatedItems = [
          ...chatbotItems?.slice(0, -1),
          {
            role: "assistant",
            content: response?.answer,
            sources: processSources(response),
          },
        ];

        const newState: IChatStateData = {
          ...prevState,
          chatbotItems: updatedItems,
          fetching: false,
        };
        dispatch(setChatbotResponse(newState));
        return newState;
      });
    } catch (err) {
      console.log(err);
    }
  };

  const onClickDefaultQuestion = (value: string) => {
    getQuestionResponse({ question: value });
  };

  const handleSendClick = () => {
    const payload = form.getFieldValue("question");
    if (!isEmpty(payload)) {
      getQuestionResponse({ question: payload });
    }
  };

  const onClearMessage = () => {
    setState((prevState) => ({
      ...prevState,
      toggle: false,
      fetching: false,
      chatbotItems: [],
    }));
    dispatch(actions.resetChatbotState());
  };

  return (
    <div className="chatbot">
      {/* header */}
      <div className="chatbot-header-container">
        <div className="chatbot-text-container">
          <ChatBotIcon />
          <div className="text-status">
            <h3>{TITLES.AI_BASED_SEARCH}</h3>
          </div>
        </div>
        <AlectifyButton
          danger
          disabled={state?.fetching}
          type="text"
          text={AlectifyText.CLEAR_ALL}
          className={`${state?.fetching ? "disable-button" : "danger-button"}`}
          onClick={onClearMessage}
        />
      </div>

      {/* conversation body */}
      {!state?.toggle ? (
        <div className="chatbot-initial-message-container">
          <ChatBotIcon />
          <Title level={5} className="greeting-text">
            <WelcomeMessage />
            {auth?.user &&
              ` ${auth?.user?.first_name} ${auth?.user?.last_name}`}
          </Title>
          <div className="help-text">
            <p>{TITLES.HOW_CAN_HELP}</p>
          </div>
          {DEFAULT_QUESTIONS.map((data, index) => {
            return (
              <div
                key={index}
                className="prompt-messages cursor-pointer"
                onClick={() => onClickDefaultQuestion(data.question)}
              >
                <p>{data?.question}</p>
              </div>
            );
          })}
        </div>
      ) : (
        <div className="chatbot-conversion-container">
          <ChatBotSource data={state} />
        </div>
      )}
      {/* reply body */}
      <Form form={form} onFinish={getQuestionResponse}>
        <div
          className={`chatbot-reply-container ${
            state?.fetching ? "disable-input" : ""
          }`}
        >
          <div className="reply-body">
            <AlectifyInput
              name="question"
              type="textarea"
              placeholder="Please ask your question..."
              disabled={state?.fetching}
            />
          </div>
          <div className="mr-10 mt-10 cursor-pointer" onClick={handleSendClick}>
            <SendIcon fill={state?.fetching ? "#666666" : "#0954F1"} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default memo(ChatBot);
