import ReactPlayer from "react-player";
import { IAlectifyVideoPlayerProps } from "./AlectifyVideoPlayer.interface";
import { useEffect, useState } from "react";
import {
  downloadSingleFile,
  getSignedUrl,
} from "services/documents/documents.service";
import { Col, Row, Space, Spin, message } from "antd";
import { MESSAGES } from "constants/messages";
import { isEmpty } from "lodash";
import "./AlectifyVideoPlayer.scss";
import AlectifyButton from "../button";
import { CloudDownloadOutlined } from "@ant-design/icons";
import audioFileImage from "../../../assets/images/audio-file-image.png";
import { isAudioFileType } from "utils/helpers";
const AlectifyVideoPlayer: React.FC<IAlectifyVideoPlayerProps> = (
  props: IAlectifyVideoPlayerProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [signedUrl, setSignedUrl] = useState<string>("");

  const getUrl = async () => {
    setLoader(true);
    try {
      const response = await getSignedUrl(props.filePath);
      if (response.success) {
        const modifiedUrl = `${response.data}`;
        setSignedUrl(modifiedUrl);
        setLoader(false);
      } else {
        setLoader(false);
        message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("Error from signed URL:", error);
      setLoader(false);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  const handleDownload = async () => {
    try {
      setLoader(true);
      await downloadSingleFile(props.filePath, props.fileName);
      setLoader(false);
    } catch (ex) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getUrl();
  }, []);

  return (
    <Spin spinning={loader}>
      <Row justify={"center"} align={"middle"}>
        <Col
          span={24}
          className="text-align-center alectify-video-player-container"
        >
          {(!isEmpty(signedUrl) && (
            <Space direction="vertical" align="center">
              {isAudioFileType(props.fileName) && (
                <img
                  src={audioFileImage}
                  alt="Audio file"
                  style={{ width: 400, height: 400, marginBottom: 16 }}
                />
              )}
              <ReactPlayer
                url={props?.isSigned ? props?.filePath : signedUrl}
                controls
                width={isAudioFileType(props.fileName) ? 400 : 620}
                height={isAudioFileType(props.fileName) ? 50 : 400}
              />
              <AlectifyButton
                text={"Download File"}
                icon={<CloudDownloadOutlined className="downlod-icon" />}
                type="link"
                className="alectify-link-button"
                onClick={handleDownload}
              />
            </Space>
          )) || <div>Video not found.</div>}
        </Col>
      </Row>
    </Spin>
  );
};

export default AlectifyVideoPlayer;
