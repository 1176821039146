import React from "react";
import { Form, Select, Input, Row, Col } from "antd";
import {
  CONTACT_NUMBER_LENGTH_MESSAGE,
  CONTACT_NUMBER_VALIDATION_MESSAGE,
  COUNTRY_CODES,
  PLEASE_ENTER_CONTACT_NUMBER_MESSAGE,
  PLEASE_SELECT_COUNTRY_CODE_MESSAGE,
} from "./constants";
import { IPhoneNumberInputProps } from "./AlectifyPhoneNumber.interface";
import "./PhoneNumber.scss";
import { isEmpty } from "lodash";

const PhoneNumberInput: React.FC<IPhoneNumberInputProps> = (
  props: IPhoneNumberInputProps,
) => {
  const { Option } = Select;

  // Custom validator for the contact number
  const validateContactNumber = (
    _: any,
    value: string,
    callback: (message?: string) => void,
  ) => {
    const contactValue = props.form.getFieldValue(props.name);
    if (isEmpty(contactValue)) {
      callback();
    } else if (props.required && isEmpty(contactValue)) {
      callback(PLEASE_ENTER_CONTACT_NUMBER_MESSAGE);
    } else if (!/^\d*$/.test(contactValue)) {
      callback(CONTACT_NUMBER_VALIDATION_MESSAGE);
    } else if (contactValue.length !== 10) {
      callback(CONTACT_NUMBER_LENGTH_MESSAGE);
    } else {
      callback();
    }
  };

  // Custom validator for the country code
  const validateCountryCode = (
    _: any,
    value: string,
    callback: (message?: string) => void,
  ) => {
    const codeValue = props.form.getFieldValue(props.prefixName);
    const contactValue = props.form.getFieldValue(props.name);

    if (!isEmpty(codeValue)) {
      callback();
    } else if (props.required || !isEmpty(contactValue)) {
      callback(PLEASE_SELECT_COUNTRY_CODE_MESSAGE);
    } else {
      callback();
    }
  };

  return (
    <Form.Item
      label={props.label}
      className={
        props?.isContactUs
          ? `alectify-contact-phone-number`
          : `alectify-phone-number`
      }
      required={props.required}
    >
      <Row>
        {/* Country Code Select */}
        <Col span={8} className="alectify-select">
          <Form.Item
            name={props.prefixName}
            rules={[
              {
                required: props.required,
                validator: validateCountryCode,
              },
            ]}
          >
            <Select
              showSearch
              optionFilterProp="children"
              placeholder="Country code"
              disabled={props.disabled}
            >
              {COUNTRY_CODES.map((country) => (
                <Option key={country.code} value={country.code}>
                  {`${country.label} (${country.code})`}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        {/* Contact Number Input */}
        <Col span={16}>
          <Form.Item
            name={props.name}
            rules={[
              {
                required: props.required,
                validator: validateContactNumber,
              },
            ]}
          >
            <Input placeholder={props.label} disabled={props.disabled} />
          </Form.Item>
        </Col>
      </Row>
    </Form.Item>
  );
};

export default PhoneNumberInput;
