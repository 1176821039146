import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { memo, useEffect, useRef, useState } from "react";
import { Col, Empty, Form, message, Row, Spin, Typography } from "antd";
import { IParams } from "redux/interfaces";
import { PAGINATION } from "constants/index";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { MinusIcon, PlusIcon } from "components/icons";
import { getProjectAssetPackages } from "redux/components/asset-packages/sources";
import { assetsInitialState, IAsset } from "redux/components/assets";
import {
  createPackage,
  fetchPackageDetailAssets,
  updatePackage,
} from "services/asset-packages/asset-packages.service";
import { IAvailableAssets } from "./ManageAssetPackage.interface";
import { fetchProjectAssets } from "services/assets/assets.service";
import AlectifyText from "static/texts.json";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import "./AvailableSelectedAssets.scss";
import { IRootState } from "redux/rootReducer";

const AvailableAndSelectedAssets: React.FC<IAvailableAssets> = ({
  form,
  subProjectId,
  record,
}) => {
  const dispatch = useDispatch();
  const paginationRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>("");
  // package assets or selected assets for the package
  const [selectedAssets, setSelectedAssets] = useState<any[]>([]);
  // all project assets
  const [allAssets, setAllAssets] = useState(assetsInitialState.assets);
  const [page, setPage] = useState<number>(PAGINATION.DEFAULT_START_PAGE);
  const onSelectAsset = (asset: IAsset) => {
    setSelectedAssets([...selectedAssets, asset]);
  };
  const { searchedText } = useSelector(({ common }: IRootState) => common);
  const onRemoveAsset = (id?: string) => {
    const updatedSelectedAssets = selectedAssets.filter(
      (asset) => asset.id !== id,
    );
    const removedAsset = selectedAssets.find((asset) => asset.id === id);
    if (
      removedAsset &&
      !allAssets.data.some((asset) => asset.id === removedAsset.id)
    ) {
      // Append the removed asset back to available assets
      setAllAssets((prevState) => ({
        ...prevState,
        data: [...prevState.data, removedAsset],
      }));
    }

    setSelectedAssets(updatedSelectedAssets);
  };

  const onClose = () => {
    form.resetFields();
    DrawerServiceInstance.close(AlectifyDrawer, {
      name: DRAWER_CONSTANTS.ADD_ASSET_PACKAGE,
    });
  };

  const getAssetPackages = () => {
    const params = {
      page: PAGINATION.DEFAULT_START_PAGE,
      per_page: PAGINATION.DEFAULT_PAGE_SIZE,
      search: searchedText || null,
      is_individual: false,
    };
    dispatch(getProjectAssetPackages(subProjectId, params));
  };

  const onSubmitHandle = async (values: any) => {
    try {
      let succesMsg: string = "";
      const payload = {
        ...values,
        tags: selectedAssets.map((asset) => asset.id),
      };
      if (selectedAssets.length === 0) {
        setErrorMessage(AlectifyText.ASSETS_ERROR_MEG);
        return;
      }
      setLoading(true);
      if (!isEmpty(record)) {
        await updatePackage(subProjectId, record.id, payload);
        succesMsg = AlectifyText.PACKAGE_UPDATED_SUCCESS;
      } else {
        await createPackage(subProjectId, payload);
        succesMsg = AlectifyText.PACKAGE_CREATED_SUCCESS;
      }
      message.success(succesMsg);
      onClose();
      getAssetPackages();
    } catch (err) {
      console.log(err);
    } finally {
      setLoading(false);
    }
  };

  const getCurrentPackageAssets = async () => {
    try {
      const response = await fetchPackageDetailAssets(
        subProjectId,
        record?.id || "",
      );
      setSelectedAssets(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getAssets = async (options?: IParams) => {
    setAllAssets({ ...allAssets, fetching: true });
    const params = {
      page: page,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      packageroom_is_individual: "True",
    } as any;
    const response = await fetchProjectAssets(subProjectId, params);
    setAllAssets({
      ...response,
      fetching: false,
      data: [...allAssets.data, ...response.data],
    });
    setPage(params.page);
  };

  useEffect(() => {
    if (!isEmpty(record)) {
      getCurrentPackageAssets();
    }
  }, []);

  useEffect(() => {
    getAssets();
  }, [page]);

  const availableAssets = allAssets.data.filter((asset: IAsset) => {
    return !selectedAssets.find(
      (selectedAsset: IAsset) => selectedAsset.id === asset.id,
    );
  });

  const handleIntersection = (entries: any) => {
    const target = entries[0];
    if (target.isIntersecting && !loading) {
      // Load more data when the user reaches the end
      if (page < allAssets.meta.total_pages) {
        setPage((prevPage) => prevPage + 1);
      }
    }
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 1.0,
    };
    const observer = new IntersectionObserver(handleIntersection, options);
    const target = paginationRef.current;
    if (target) {
      observer.observe(target);
    }
    return () => observer.disconnect();
  }, [allAssets.data]);

  return (
    <Spin spinning={loading}>
      <Row gutter={30} className="available-selected-container">
        <Col span={12} className="full-height overflow-auto">
          <Spin spinning={allAssets.fetching}>
            <div className="package-assets-wrapper">
              <div className="d-flex justify-content-space-between align-items-center">
                <h4>Available Assets ({`${availableAssets.length || 0}`})</h4>
              </div>
              <ul>
                {!isEmpty(availableAssets) ? (
                  availableAssets.map((asset: IAsset) => {
                    return (
                      <li key={asset.id} onClick={() => onSelectAsset(asset)}>
                        <div className="d-flex justify-content-space-between p-3">
                          <Typography.Text>{asset.name}</Typography.Text>
                          <PlusIcon />
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <Empty />
                )}
                <li
                  className="no-style"
                  style={{ height: 10 }}
                  ref={paginationRef}
                ></li>
              </ul>
            </div>
          </Spin>
        </Col>
        <Col span={12} className="full-height border-light-grey overflow-auto">
          <div className="d-flex justify-content-space-between align-items-center">
            <h4>Selected ({selectedAssets.length})</h4>
          </div>
          <Form form={form} onFinish={onSubmitHandle}>
            <Form.Item name="tags">
              <ul>
                {!isEmpty(selectedAssets) ? (
                  selectedAssets.map((asset: IAsset) => {
                    return (
                      <li
                        key={asset.id}
                        onClick={() => onRemoveAsset(asset.id)}
                      >
                        <div className="d-flex justify-content-space-between p-3">
                          <Typography.Text>{asset.name}</Typography.Text>
                          <MinusIcon />
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <Empty />
                )}
              </ul>
            </Form.Item>
          </Form>
          {errorMessage && (
            <div className="mt-15">
              <Typography.Text type="danger">{errorMessage}</Typography.Text>
            </div>
          )}
        </Col>
      </Row>
    </Spin>
  );
};

export default memo(AvailableAndSelectedAssets);
