import { Space, Tag } from "antd";
import { DangerIcon } from "components/icons";

const DelayedTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#D92323"} className="round-tag">
      <Space size={3}>
        <DangerIcon /> Delayed
      </Space>
    </Tag>
  );
};

export default DelayedTag;
