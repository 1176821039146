import { Space, Spin, message } from "antd";
import "./ViewImage.scss"; // Import your CSS file for styling
import { useEffect, useState } from "react";
import {
  downloadSingleFile,
  getSignedUrl,
} from "services/documents/documents.service";
import { MESSAGES } from "constants/messages";
import { CloudDownloadOutlined } from "@ant-design/icons";
import AlectifyButton from "../button";
import AlectifyText from "static/texts.json";
import { saveAs } from "file-saver";
import { getFileNameFromS3Url } from "utils/helpers";

interface IImageViewerProps {
  imageUrl: string;
  isUnSigned?: boolean;
  isDownloadable?: boolean;
}

const ImageViewer: React.FC<IImageViewerProps> = ({
  imageUrl,
  isUnSigned,
  isDownloadable,
}) => {
  const [signedUrl, setSignedUrl] = useState<string>();
  const [isLoad, setIsLoad] = useState<boolean>(false);

  const getUrl = async () => {
    setIsLoad(true);
    try {
      const response = await getSignedUrl(imageUrl);
      if (response.success) {
        const modifiedUrl = `${response.data}`;
        setSignedUrl(modifiedUrl);
        setIsLoad(false);
      } else {
        setIsLoad(false);
        message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      }
    } catch (error) {
      console.error("Error from signed URL:", error);
      setIsLoad(false);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
    }
  };

  const handleDownload = async () => {
    if (isUnSigned) {
      await downloadSingleFile(imageUrl, getFileNameFromS3Url(imageUrl));
    } else {
      saveAs(imageUrl, getFileNameFromS3Url(imageUrl));
    }
  };

  useEffect(() => {
    if (isUnSigned) getUrl();
  }, [imageUrl]);

  return (
    <div className="image-container">
      <Spin spinning={isLoad}>
        <img
          src={isUnSigned ? signedUrl : imageUrl}
          alt="Preview"
          style={{ height: "100%", width: "100%", objectFit: "contain" }}
        />
        {isDownloadable && (
          <Space align="center" className="view-image-bottom">
            <AlectifyButton
              text={AlectifyText?.DOWNLOAD_IMAGE}
              icon={<CloudDownloadOutlined className="downlod-icon" />}
              type="link"
              className="alectify-link-button"
              onClick={handleDownload}
            />
          </Space>
        )}
      </Spin>
    </div>
  );
};
ImageViewer.defaultProps = {
  imageUrl: "",
  isUnSigned: false,
  isDownloadable: true,
};
export default ImageViewer;
