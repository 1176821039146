import { memo, useEffect } from "react";
import { ISelectSubProjectProps } from "./SelectSubProject.interface";
import AlectifySelect from "components/shared/select";
import AlectifyText from "static/texts.json";
import { isEmpty } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { getSubProjects } from "redux/components/sub-project/sources";
import { IRootState } from "redux/rootReducer";

const SelectSubProject: React.FC<ISelectSubProjectProps> = (
  props: ISelectSubProjectProps,
) => {
  const subProjects = useSelector((state: IRootState) => state.subProject);

  const dispatch = useDispatch();

  const getOptions = () => {
    let options: any[] = [];
    if (props.addAllFilter) {
      options = [
        {
          label: "All",
          value: "all",
        },
      ];
    }
    if (props.masterProjectId) {
      options = [
        ...options,
        ...subProjects.data?.map((project) => ({
          label: project.name,
          value: project.id,
          ...project,
        })),
      ];
    }
    return options;
  };

  useEffect(() => {
    if (!isEmpty(props.masterProjectId) && props.masterProjectId !== "all") {
      dispatch(getSubProjects(props.masterProjectId));
    }
  }, [props.masterProjectId]);

  return (
    <AlectifySelect
      placeholder={AlectifyText.SELECT_ASSET_CATEGORY}
      name="subProject"
      label={`${AlectifyText.ASSET_CATEGORY}`}
      loading={subProjects.fetching}
      disabled={props.disabled}
      rules={props.rules}
      filterOption={(input: string, option) => {
        return String(option?.label ?? "").includes(input);
      }}
      onSelect={props.onSelect}
      options={getOptions()}
    />
  );
};

export default memo(SelectSubProject);
