import { Path, Rect, Svg } from "@react-pdf/renderer";

const EventCompletedIconPdf = () => {
  return (
    <Svg
      width="13"
      height="13"
      viewBox="0 0 17 17"
      fill="none"
      //   xmlns="http://www.w3.org/2000/Svg"
    >
      <Rect
        width="14.12"
        height="13.903"
        rx="3"
        fill="#43BD18"
        y="2.789"
        x="0.313"
      />
      <Path
        d="M 7.346 15 C 10.299 15 12.693 12.656 12.693 9.765 C 12.693 6.874 10.299 4.53 7.346 4.53 C 4.394 4.53 2 6.874 2 9.765 C 2 12.656 4.394 15 7.346 15 Z"
        fill="white"
      />
      <Path
        d="M 7.319 5.467 C 6.438 5.467 5.577 5.721 4.845 6.197 C 4.113 6.673 3.543 7.35 3.206 8.142 C 2.869 8.934 2.78 9.805 2.952 10.646 C 3.124 11.486 3.548 12.259 4.171 12.865 C 4.793 13.471 5.586 13.884 6.45 14.051 C 7.314 14.218 8.209 14.132 9.022 13.804 C 9.836 13.476 10.531 12.921 11.02 12.208 C 11.509 11.495 11.77 10.658 11.77 9.8 C 11.77 9.231 11.655 8.668 11.432 8.142 C 11.208 7.616 10.88 7.138 10.466 6.736 C 10.053 6.334 9.562 6.014 9.022 5.797 C 8.482 5.579 7.903 5.467 7.319 5.467 Z M 6.428 11.967 L 4.202 9.8 L 4.83 9.19 L 6.428 10.74 L 9.807 7.452 L 10.435 8.067 L 6.428 11.967 Z"
        fill="#43BD18"
      />
    </Svg>
  );
};

export default EventCompletedIconPdf;
