//libs
import React from "react";
import { Avatar, Dropdown, MenuProps, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";

//local imports
import AlectifyText from "static/texts.json";
import { displayDateTime, getUserImage } from "utils/helpers";
import { DocumentTypes, IViewDocument } from "redux/components/documents";
import { IUser } from "redux/components/Auth";
import { isEmpty } from "lodash";
import PreviewFile from "components/shared/preview-file";
import { MoreOutlined } from "@ant-design/icons";
import { downloadSingleFile } from "services/documents/documents.service";
import { DOCUMENT_TYPE_ICONS } from "components/shared/asset-documents-table/effects/useDocumentsColumn";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import ViewImage from "components/shared/view-image/ViewImage";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { DocumentEditTypes } from "components/shared/edit-document/EditDocument.interface";
import { IPagination } from "components/shared/table/AlectifyTable.interface";

interface Props {
  assetPackageId?: string;
  optionsPersisted?: IPagination | null;
  onDelete?: (record: IViewDocument) => void;
  onEdit?: (record: IViewDocument, editType: DocumentEditTypes) => void;
  onRestore?: (record: IViewDocument) => void;
  workOrderAttachments?: boolean;
  timelineDocuments?: boolean;
}

const getItems = (document: IViewDocument, props: Props) => {
  const items: MenuProps["items"] = [
    {
      key: "download",
      label: "Download",
      disabled: !document.isActive,
      onClick: async (ev) => {
        ev.domEvent.stopPropagation();
        await downloadSingleFile(document.filePath, document.fileName);
      },
    },
    {
      key: "editFilename",
      label: "Edit File Name",
      disabled: !document.isActive,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onEdit) props.onEdit(document, DocumentEditTypes.FILENAME);
      },
    },
    {
      key: "editComment",
      label: "Edit Comment",
      disabled: !document.isActive,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onEdit) props.onEdit(document, DocumentEditTypes.COMMENT);
      },
    },
    {
      key: "delete",
      label: "Delete",
      disabled: !document.isActive,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onDelete) props.onDelete(document);
      },
    },
    {
      key: "restore",
      label: "Restore",
      disabled: document.isActive,
      onClick: (ev) => {
        ev.domEvent.stopPropagation();
        if (props?.onRestore) props.onRestore(document);
      },
    },
  ];

  return items;
};

const useColumnHook = (props: Props) => {
  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.UPLOADED_AT,
          dataIndex: "createdAt",
          key: "createdAt",
          searchable: true,
          width: 70,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (created_at: Date) => (
            <Tooltip title={displayDateTime(created_at, true)}>
              {displayDateTime(created_at, true)}
            </Tooltip>
          ),
        },
        {
          title: AlectifyText.FILE_NAME,
          dataIndex: "fileName",
          searchable: true,
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          render: (_: any, document: IViewDocument) => (
            <PreviewFile
              fileName={document?.fileName}
              fileUrl={document?.filePath}
              isActivevalue={document?.isActive}
            />
          ),
          // sorter: (a, b) => a.name - b.name,
        },
        {
          title: AlectifyText.FILE_TYPE,
          dataIndex: "fileType",
          width: 100,
          visible:
            props.timelineDocuments || props.workOrderAttachments
              ? false
              : true,
        },
        {
          title: AlectifyText.DOCUMENT_TYPE,
          dataIndex: "type",
          width: 70,
          visible: props.workOrderAttachments,

          render: (value: DocumentTypes) => DOCUMENT_TYPE_ICONS[value],
        },
        {
          title: AlectifyText.UPLOADED_BY,
          dataIndex: "created_by",
          width: 50,
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          render: (created_by: IUser, record: any) => (
            <Tooltip
              title={
                record &&
                `${record.createdByFirstName} ${record.createdByLastName}`
              }
            >
              <Avatar
                size="small"
                src={record.createdByImageUrl || getUserImage(created_by)}
                className="cursor-pointer"
                onClick={() => {
                  ModalServiceInstance.open(AlectifyModal, {
                    title: "Preview",
                    footer: null,
                    name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: MODAL_NAMES.VIEW_IMAGE_MODAL,
                      });
                    },
                    children: (
                      <ViewImage
                        imageUrl={record.createdByImageUrl || NO_IMAGE_AVATAR}
                        isDownloadable={!isEmpty(record.createdByImageUrl)}
                      />
                    ),
                  });
                }}
              />
            </Tooltip>
          ),
        },
        {
          title: AlectifyText.COMMENTS,
          dataIndex: "comment",
          width: 100,
          ellipsis: {
            showTitle: false,
          },
          visible: true,
        },
        {
          title: AlectifyText.ACTIONS,
          align: "center",
          dataIndex: "",
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          width: 50,
          render: (_: any, document: IViewDocument) => {
            return (
              <Dropdown menu={{ items: getItems(document, props) }}>
                <MoreOutlined style={{ fontSize: 20 }} />
              </Dropdown>
            );
          },
          // document.isActive ? (
          //   <Space>
          //     <AlectifyDownloadDocument
          //       file_name={document.fileName}
          //       file_path={document.filePath}
          //     />

          //     <Tooltip title={"Delete"} className="cursor-pointer">
          //       <div onClick={() => props.onDelete && props.onDelete(document)}>
          //         <DeleteIcon />
          //       </div>
          //     </Tooltip>
          //   </Space>
          // ) : (
          //   AlectifyText.DELETED
          // ),
        },
      ].filter((column) => column.visible),
    [props.optionsPersisted],
  );
};

export default useColumnHook;
