import { memo, useEffect } from "react";
import { Form, Row, Col } from "antd";
import AlectifyInput from "../input";
import AlectifyText from "static/texts.json";
import { MESSAGES } from "constants/messages";
import { FormInstance } from "antd/lib";
import { IAssetPackage } from "redux/components/asset-packages";

interface IAddAssetPackageFormProps {
  form: FormInstance;
  record?: IAssetPackage;
}

const AddAssetPackageForm: React.FC<IAddAssetPackageFormProps> = ({
  form,
  record,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      description: record?.description,
      serial_number: record?.serial_number,
      name: record?.name,
    });
  }, [record]);

  return (
    <div className="add-asset-package-detail-container">
      <h4>Asset Package Detail</h4>
      <Form form={form} layout="vertical">
        <Row>
          <Col span={24}>
            <AlectifyInput
              name="serial_number"
              label={AlectifyText.ITEM_NUMBER_ASSET}
              type="number"
              placeholder={AlectifyText.PLEASE_ENTER_ITEM}
            />
          </Col>

          <Col span={24}>
            <AlectifyInput
              name="name"
              label={AlectifyText.PARENT_ASSET_NAME}
              type="text"
              placeholder={AlectifyText.PLEASE_ENTER_ASSET_PACKAGE}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.ASSET_NAME,
                  ),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <AlectifyInput
              name="description"
              label={AlectifyText.DESCRIPTION}
              type="textarea"
              rows={4}
              placeholder={AlectifyText.PLEASE_ENTER_DESCRIPTION}
              max={255}
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(AddAssetPackageForm);
