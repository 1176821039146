import {
  Checkbox,
  Col,
  Form,
  Image,
  Row,
  Space,
  Spin,
  Typography,
  Upload,
} from "antd";
import AlectifyInput from "components/shared/input";
import AlectifySelect from "components/shared/select";
import { JobTypeOptions } from "../ProcedureCreation.configs";
import { MESSAGES } from "constants/messages";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import { debounce } from "lodash";
import { fetchProcedureCategories } from "services/procedures/procedures.service";
import { useEffect, useState } from "react";
import { IProcedureCategory } from "services/procedures/procedures.interface";
import { IProcedureBasicInfoProps } from "./ProcedureBasicInfo.interface";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import AlectifyAutoComplete from "components/shared/autocomplete";
import { CameraOutlined } from "@ant-design/icons";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import SelectMasterProject from "components/shared/master-project-select";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";

const ProcedureBasicInformation: React.FC<IProcedureBasicInfoProps> = (
  props: IProcedureBasicInfoProps,
) => {
  const [categories, setCategories] = useState<IProcedureCategory[]>([]);
  const [categoryLoader, setCategoryLoader] = useState<boolean>(false);
  const [catagoryopen, setcatagoryOpen] = useState<boolean>(false);
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );
  const onCategorySearch = debounce(async (search: string) => {
    setCategoryLoader(true);
    try {
      const response = await fetchProcedureCategories({
        search,
      });
      setCategories(response.data);
      setCategoryLoader(false);
    } catch (ex) {
      setCategoryLoader(false);
      console.log(ex);
    }
  }, 300);

  useEffect(() => {
    onCategorySearch("");
  }, []);

  return (
    <>
      <Typography.Title level={5} className="mt-0">
        Basic Information
      </Typography.Title>
      <Row justify={"center"} align={"middle"} gutter={30}>
        <Col span={24} className="text-align-center">
          <div className="profile-image-container">
            <div className="image-wrapper">
              <Image
                preview={false}
                src={props.previewImage || NO_IMAGE_AVATAR}
                className="contact-profile-image"
              />
              <Upload
                maxCount={1}
                accept="image/*"
                showUploadList={false}
                beforeUpload={() => false}
                onChange={props.handleImageUpload}
              >
                <CameraOutlined className="camera-icon" />
              </Upload>
            </div>
          </div>
        </Col>
        <Col span={12}>
          <AlectifySelect
            options={JobTypeOptions}
            name="jobType"
            placeholder="e.g Job Plan"
            label="Job Type:"
            rules={[
              {
                required: true,
                message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                  "{fieldName}",
                  "Job Type",
                ),
              },
            ]}
          />
        </Col>
        <Col span={12}>
          <SelectMasterProject
            onSelect={(id: string) => console.log(id)}
            onChange={(projectId: string) => console.log(projectId)}
            defaultValue={
              (activeMasterProject && activeMasterProject?.id) || undefined
            }
            rules={[
              {
                required: true,
                message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                  "{fieldName}",
                  "Site",
                ),
              },
            ]}
          />
        </Col>
        <Col span={24}>
          <AlectifyInput
            name="title"
            label="Title:"
            placeholder="e.g Jhon"
            type="text"
            rules={BASIC_REQUIRED_VALIDATION_RULES}
          />
        </Col>
        <Col span={24}>
          <Space direction="horizontal" size={100}>
            <Form.Item name="comments" valuePropName="checked">
              <Checkbox name="comment-required">Comment Required</Checkbox>
            </Form.Item>

            <Form.Item name="fileUpload" valuePropName="checked">
              <Checkbox name="file-attachment">File Attachment</Checkbox>
            </Form.Item>
          </Space>
        </Col>
        <Col span={12}>
          <AlectifyInput
            name="reference"
            label="Reference #:"
            placeholder="e.g 12345"
            type="text"
          />
        </Col>
        <Col span={12}>
          <Form.Item
            name="category"
            label="Category"
            rules={BASIC_REQUIRED_VALIDATION_RULES}
          >
            <AlectifyAutoComplete
              showSearch
              placeholder="Search or enter category"
              onSearch={onCategorySearch}
              onSelect={(val, record) => props.onCategorySelect(record as any)}
              notFoundContent={
                categoryLoader ? (
                  <div className="text-align-center">
                    <Spin size="small" spinning={categoryLoader} />
                  </div>
                ) : (
                  <AlectifyEmpty description="Sorry! This category doesn't Exist. You can still add as 'New'" />
                )
              }
              open={catagoryopen}
              onDropdownVisibleChange={(open) => setcatagoryOpen(open)}
              options={categories.map((category: IProcedureCategory) => ({
                value: category.name,
                label: category.name,
                key: category.id,
              }))}
              filterOption={(inputValue, option: any) =>
                option.label.toUpperCase().indexOf(inputValue.toUpperCase()) !==
                -1
              }
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <AlectifyInput
            name="description"
            label="Description:"
            type="textarea"
            max={255}
          />
        </Col>
        {/*  <Col span={24}>
          {isEmpty(props?.procedure?.imageUrl) || props.isremove ? (
            <Form.Item label="Upload Image:">
              <Form.Item
                name="dragger"
                valuePropName="fileList"
                getValueFromEvent={normFile}
                noStyle
              >
                <Upload.Dragger
                  name="files"
                  // action="/upload.do"
                  accept=".jpeg,.png,jpg"
                  className="alectify-procedure-upload-dnd"
                  beforeUpload={() => false}
                  multiple={false}
                >
                  <p className="ant-upload-drag-icon">
                    <DnDUploadIcon />
                  </p>
                  <p className="ant-upload-text">
                    Supported formates: JPEG, JPG, PNG
                  </p>
                </Upload.Dragger>
              </Form.Item>
            </Form.Item>
          ) : (
            <div className="alectify-procedure-img-container">
              <Row justify={"center"} align={"middle"}>
                <Col span={24} className="text-align-center">
                  <img
                    src={props.procedure?.imageUrl}
                    alt="Procedure"
                    className={`alectify-procedure-image ${
                      props.procedure?.imageUrl && "cursor-pointer"
                    }`}
                    onClick={() =>
                      props.procedure?.imageUrl &&
                      openImagePreviewModal(props.procedure?.imageUrl)
                    }
                  />
                </Col>
                <Col span={24}>
                  <AlectifyButton
                    danger
                    type="text"
                    text={"Delete Image"}
                    className="danger-button full-width"
                    onClick={openConfirmationModal}
                  />
                </Col>
              </Row>
            </div>
          )}
        </Col> */}
      </Row>
    </>
  );
};

export default ProcedureBasicInformation;
