interface IDownloadIconProps {
  fill?: string | null;
}
const DownloadIcon: React.FC<IDownloadIconProps> = (
  props: IDownloadIconProps,
) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
    >
      <path
        d="M13.75 13.4133H11.9306V7.46401C11.9306 7.35597 11.8422 7.26758 11.7342 7.26758H10.261C10.1529 7.26758 10.0646 7.35597 10.0646 7.46401V13.4133H8.25005C8.08554 13.4133 7.99469 13.6024 8.09536 13.7301L10.8454 17.2093C10.8637 17.2328 10.8872 17.2518 10.914 17.2648C10.9408 17.2779 10.9702 17.2847 11 17.2847C11.0299 17.2847 11.0593 17.2779 11.0861 17.2648C11.1129 17.2518 11.1364 17.2328 11.1547 17.2093L13.9047 13.7301C14.0054 13.6024 13.9146 13.4133 13.75 13.4133Z"
        fill={props.fill || "#0954F1"}
      />
      <path
        d="M18.3513 5.07522C17.2268 2.10915 14.3614 0 11.0049 0C7.64844 0 4.78304 2.1067 3.65848 5.07277C1.55424 5.62522 0 7.54286 0 9.82143C0 12.5346 2.19754 14.7321 4.90826 14.7321H5.89286C6.00089 14.7321 6.08929 14.6438 6.08929 14.5357V13.0625C6.08929 12.9545 6.00089 12.8661 5.89286 12.8661H4.90826C4.0808 12.8661 3.30246 12.5371 2.72299 11.9404C2.14598 11.3462 1.83906 10.5458 1.86607 9.71585C1.88817 9.06764 2.10915 8.45871 2.50937 7.94554C2.91942 7.42255 3.49397 7.04197 4.13237 6.87255L5.06295 6.62947L5.40424 5.7308C5.6154 5.17098 5.91004 4.64799 6.2808 4.17411C6.64683 3.70442 7.0804 3.29153 7.56741 2.94888C8.57656 2.23929 9.76496 1.86362 11.0049 1.86362C12.2449 1.86362 13.4333 2.23929 14.4424 2.94888C14.931 3.29263 15.3632 3.70513 15.729 4.17411C16.0998 4.64799 16.3944 5.17344 16.6056 5.7308L16.9444 6.62701L17.8725 6.87255C19.2033 7.23103 20.1339 8.44152 20.1339 9.82143C20.1339 10.6342 19.8172 11.4002 19.2426 11.9748C18.9609 12.2582 18.6257 12.4829 18.2565 12.6359C17.8873 12.7889 17.4914 12.8671 17.0917 12.8661H16.1071C15.9991 12.8661 15.9107 12.9545 15.9107 13.0625V14.5357C15.9107 14.6438 15.9991 14.7321 16.1071 14.7321H17.0917C19.8025 14.7321 22 12.5346 22 9.82143C22 7.54531 20.4507 5.63014 18.3513 5.07522Z"
        fill={props.fill || "#0954F1"}
      />
    </svg>
  );
};
export default DownloadIcon;
