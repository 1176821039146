import { createActionAndReducers } from "redux/utils/reducer.helper";

import { ISparePartsActions } from "./spare-parts.interface";

import { reducerName } from "./spare-parts.constants";

import sparePartInitialState from "./spare-parts.initialState";

import * as sparePartsEffects from "./affects";

export * from "./spare-parts.interface";

export * from "./spare-parts.constants";

const { actions, reducer } = createActionAndReducers<ISparePartsActions>(
  reducerName,
  sparePartInitialState,
  sparePartsEffects,
);

export { actions, sparePartInitialState, reducer };
