import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import { Card, Col, Form, Row, Typography, message } from "antd";
import { useSelector } from "react-redux";
import { isEmpty } from "lodash";
//path imports
import AlectifyButton from "components/shared/button";
import AlectifyInput from "components/shared/input";
import SVGToComponent from "components/shared/icons/icons";
import EmailIcon from "assets/images/alectify-email-icon.svg";
import {
  IForgetPasswordPayload,
  IForgetPasswordProps,
} from "./ForgetPassword.interface";
import "./ForgetPassword.scss";
import { ROUTES } from "routes/Routes.constants";
import { ForgetpasswordRequest } from "services/auth/auth.service";
import { MESSAGES } from "constants/messages";
import { currentYear } from "utils/helpers";

const { Text, Title } = Typography;

const Forgetpassword: React.FC<IForgetPasswordProps> = () => {
  const auth = useSelector((state: any) => state.auth);
  const [loading, setLoading] = useState<boolean>(false);

  const navigate = useNavigate();

  const onSubmit = async (payload: IForgetPasswordPayload) => {
    setLoading(true);
    try {
      const response = await ForgetpasswordRequest(payload);
      if (response.message) {
        message.success(response.message);
      }
      setLoading(false);
    } catch (ex: any) {
      message.error(
        ex?.response?.data?.message ||
          MESSAGES.AUTHENTICATION_MESSAGES.USER_DOESNOT_EXIST,
      );
      setLoading(false);
      console.error("Error:", ex);
    }
  };

  useEffect(() => {
    if (!isEmpty(auth.user)) {
      navigate(ROUTES.ROOT);
    }
  }, [auth, navigate]);

  return (
    <Row
      className="auth-container height-100vh"
      align="middle"
      justify="center"
    >
      <Col xs={24} sm={20} md={18} lg={12} xl={10} xxl={10}>
        <Card className="auth-form-container">
          <div className="auth-form-header">
            <div className="auth-form-logo-container" />
            <Title className="auth-form-title">
              Welcome to <span className="company-name">Alectify!</span>
            </Title>
            <p>Reset your account password</p>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            validateTrigger="onSubmit"
          >
            <AlectifyInput
              label="Email Address"
              name="email"
              type="email"
              prefix={<SVGToComponent icon={EmailIcon} />}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
              placeholder="Enter your email address here"
            />

            <Form.Item className="mt-50">
              <AlectifyButton
                type="primary"
                className="alectify-btn-block"
                text="submit"
                htmlType="submit"
                loading={loading}
              />
            </Form.Item>
          </Form>
          <div className="mt-50">
            <p style={{ fontSize: 15 }}>
              Back to login?{" "}
              <Link
                className="btn btn-outline-primary"
                to="/login"
                onClick={(ev) => {
                  navigate(ROUTES.LOGIN);
                }}
              >
                Click
              </Link>
            </p>
          </div>
        </Card>
        <Text className="auth-form-copyright-text">
          © Copyrights Alectify {currentYear}. All Rights Reserved.
        </Text>
      </Col>
    </Row>
  );
};

export default Forgetpassword;
