import dayjs from "dayjs";
import InfiniteScroll from "react-infinite-scroll-component";
import { Skeleton } from "antd";
import React, { memo } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { ICommentListProps } from "./Comments.interface";
import relativeTime from "dayjs/plugin/relativeTime";
import TimelineCommentItem from "./TimelineCommentItem";
import "dayjs/locale/en";
import "./Comment.scss";

dayjs.extend(relativeTime);

const TimelistCommentList: React.FC<ICommentListProps> = ({
  getParams,
  params,
}) => {
  const state = useSelector((state: IRootState) => state.common);

  return (
    <div
      className="comment-list"
      id="scrollableDiv"
      style={{
        height: 400,
        display: "flex",
        overflow: "auto",
        flexDirection: "column-reverse",
      }}
    >
      <InfiniteScroll
        dataLength={state.comments.data.length}
        next={() => {
          getParams && getParams(params?.page + 1);
        }}
        style={{ display: "flex", flexDirection: "column-reverse" }}
        inverse={true}
        hasMore={
          state.comments.data?.length < (state.comments.meta?.total_count || 0)
        }
        loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
        scrollableTarget="scrollableDiv"
        height={400}
      >
        {state?.comments?.data?.map((comment) => (
          <TimelineCommentItem comment={comment} />
        ))}
      </InfiniteScroll>
    </div>
  );
};

export default memo(TimelistCommentList);
