import { Space, Tag } from "antd";
import AlectifyText from "static/texts.json";

const StepsTag: React.FC<{ count?: number }> = ({ count }) => {
  return (
    <Tag color={"#F3F3F3"} className="steps-tag round-tag">
      <Space size={2}>{`${count || 0} ${AlectifyText.STEPS}`}</Space>
    </Tag>
  );
};

export default StepsTag;
