interface IAnalyticsIconProps {
  fill?: string | null;
}
const AnalyticsIcon: React.FC<IAnalyticsIconProps> = (
  props: IAnalyticsIconProps,
) => {
  return (
    <svg
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="Alectify-siderbar-menu-icon"
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M6.5 18V15M11.5 18V9M16.5 18V14"
          stroke={props.fill || "#141B35"}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector_2"
          d="M21.5 6C21.5 7.65685 20.1569 9 18.5 9C16.8431 9 15.5 7.65685 15.5 6C15.5 4.34315 16.8431 3 18.5 3C20.1569 3 21.5 4.34315 21.5 6Z"
          stroke={props.fill || "#141B35"}
          strokeWidth="1.5"
        />
        <path
          id="Vector_3"
          d="M21.4955 11.5C21.4955 11.5 21.5 11.8395 21.5 12.5C21.5 16.9784 21.5 19.2175 20.1088 20.6088C18.7175 22 16.4783 22 12 22C7.52166 22 5.28249 22 3.89124 20.6088C2.5 19.2175 2.5 16.9784 2.5 12.5C2.5 8.02169 2.5 5.78252 3.89124 4.39127C5.28249 3.00003 7.52166 3.00003 12 3.00003L13 3"
          stroke={props.fill || "#141B35"}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default AnalyticsIcon;
