import { ICommentItem } from "components/shared/comments/Comments.interface";
import { PM_STATUS } from "enums";
import { IMeta, IMetaNest, IReduxAction } from "redux/interfaces";
import { IMasterProject } from "../master-project";
import { ISubProject } from "../sub-project";

export interface ICommonState {
  activeSubProject: ISubProject | null;
  activeMasterProject: IMasterProject | null;
  previousActiveMasterProject: IMasterProject | null;
  masterProjectSidebar: boolean;
  showMyWorkOrders: boolean;
  showTeamInventory: boolean;
  uploadDocuments: any[];
  showCalendarMyWorkOrders: boolean;
  comments: ICommentsState;
  searchedText: string | null;
  assetDetailSidebar: boolean;
  activeCalendarType: number;
}

export interface ICommentsState {
  data: ICommentItem[];
  meta: IMeta | null;
  fetching: boolean;
}

export interface ICommonActions {
  setActiveSubProject: IReduxAction<ISubProject>;
  setActiveMasterProject: IReduxAction<IMasterProject>;
  setActiveCalendarType: IReduxAction<any>;
  logout: IReduxAction<any>;
  setMasterProjectSidebar: IReduxAction<boolean>;
  setAssetDetailSidebar: IReduxAction<boolean>;
  setShowMyWorkOrders: IReduxAction<boolean>;
  setShowTeamInventory: IReduxAction<boolean>;
  setShowCalendarMyWorkOrders: IReduxAction<boolean>;
  setUploadDocuments: IReduxAction<any>;
  deleteUploadedDocument: IReduxAction<any>;
  setComments: IReduxAction<ICommentsState>;
  appendComments: IReduxAction<ICommentsState>;
  pushNewComment: IReduxAction<ICommentItem>;
  setCommentsLoader: IReduxAction<boolean>;
  resetCommonState: IReduxAction<any>;
  setSearchedText: IReduxAction<any>;
}

export interface ITimeline {
  assetId: string;
  createdAt: Date;
  detail: string;
  id: string;
  name: string;
  quantity: number;
  status: PM_STATUS;
  type: TimelineTypeEnum;
  updatedAt: Date;
  userEmail: string;
  userFirstName: string;
  userId: string;
  userLastName: string;
  commentsCount: string;
  dueDate?: Date;
}

export enum TimelineTypeEnum {
  TASK = "TASK",
  SPARE_PART = "SPARE_PART",
  PM_EXTERNAL = "PM_EXTERNAL",
  PM_INTERNAL = "PM_INTERNAL",
}

export interface ITimelineResponse {
  data: ITimeline[];
  meta?: IMetaNest;
  message?: string;
  success?: boolean;
  fetching?: boolean;
}
