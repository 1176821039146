import React, { memo } from "react";
import { Space, Tooltip } from "antd";
import CardViewIcon from "components/icons/CardViewIcon";
import "../Procedures.scss";
import ListIcon from "components/icons/ListIcon";

interface IProcedureViewSwitcherProps {
  onChange: (value: boolean) => void;
  tableView?: boolean;
}

const ProcedureViewSwitcher: React.FC<IProcedureViewSwitcherProps> = ({
  onChange,
  tableView,
}) => {
  const handleChange = (value: boolean) => {
    onChange(value);
  };
  return (
    <div className={`toggle-button-container`}>
      <Space>
        <Tooltip title="Table View">
          <span onClick={() => handleChange(true)}>
            <ListIcon
              className={"cursor-pointer mt-3"}
              fill={tableView ? "#0954f1" : null}
            />
          </span>
        </Tooltip>
        <Tooltip title="Card View">
          <span onClick={() => handleChange(false)}>
            <CardViewIcon
              className={"cursor-pointer mt-3"}
              fill={!tableView ? "#0954f1" : null}
            />
          </span>
        </Tooltip>
      </Space>
    </div>
  );
};

export default memo(ProcedureViewSwitcher);
