import React, { memo, useEffect, useState } from "react";
import { Col, Form, Row, Space, Spin, message } from "antd";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifySelect from "components/shared/select";
import OrganizationsAutoComplete from "components/shared/autocomplete/OrganizationsAutoComplete";
import { ICreateBranchAdminModal } from "./Admin.interface";
import {
  createBranchAdmin,
  fetchCompaniesBranches,
  fetchOrganizationTypes,
  fetchRoles,
} from "services/admin/admin.service";
import "./Admin.scss";

const CreateBranchAdminModal: React.FC<ICreateBranchAdminModal> = ({
  companies,
  getUsers,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [roles, setRoles] = useState([]);
  const [organizationTypes, setOrganizationTypes] = useState([]);

  const [form] = Form.useForm();
  const [branches, setBranches] = useState([]);
  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH,
    });
  };

  const getBranches = async (companyId: string) => {
    try {
      form.setFieldsValue({ branch: null });
      setLoading(true);
      const params = {
        company: companyId,
      };
      const response = await fetchCompaniesBranches(params);
      setBranches(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const onTypeChange = async (value: string) => {
    try {
      form.setFieldsValue({ role: null });
      const params = {
        type: value,
      };
      const result = await fetchRoles(params);
      setRoles(result.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getOrganizationTypes = async () => {
    try {
      const response = await fetchOrganizationTypes();
      setOrganizationTypes(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  const checkPasswordMatch = () => {
    const password = form.getFieldValue("password");
    const confirmPassword = form.getFieldValue("confirmPassword");
    if (password !== confirmPassword) {
      throw new Error(MESSAGES.AUTHENTICATION_MESSAGES.PASSWORD_DOESNOT_MATCH);
    }
  };

  const onSubmit = async (values: any) => {
    try {
      setLoading(true);
      values.is_branch_admin = true;
      checkPasswordMatch();
      await createBranchAdmin(values);
      message.success(MESSAGES.ADMIN_MESSAGES.BRANCH_CREATED);
      setLoading(false);
      getUsers();
      onCloseModal();
    } catch (error) {
      setLoading(false);
      if (
        error instanceof Error &&
        error.message ===
          MESSAGES.AUTHENTICATION_MESSAGES.PASSWORD_DOESNOT_MATCH
      ) {
        form.setFields([
          {
            name: "confirmPassword",
            errors: [error.message],
          },
        ]);
      }
    }
  };

  useEffect(() => {
    getOrganizationTypes();
  }, []);
  return (
    <Spin spinning={loading}>
      <Form form={form} name="basic" layout="vertical" onFinish={onSubmit}>
        <Row gutter={[12, 12]}>
          <Col span={12}>
            <AlectifySelect
              label="Company Name"
              name="company"
              onChange={getBranches}
              options={companies?.map((company) => ({
                value: company.id,
                label: company.name,
              }))}
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <AlectifySelect
              label="Branch Name"
              name="branch"
              onChange={() => {}}
              options={branches?.map((branch: any) => ({
                value: branch?.id,
                label: branch?.name,
              }))}
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.BRANCH_NAME_REQUIRED,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AlectifyInput
              name="first_name"
              type="text"
              placeholder="eg: John."
              label="First Name"
              rules={[
                {
                  required: true,
                  message:
                    MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_FIRST_NAME,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AlectifyInput
              name="last_name"
              type="text"
              placeholder="eg: John."
              label="Last Name"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_LAST_NAME,
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AlectifyInput
              name="email"
              type="email"
              placeholder="Email Address"
              rules={[
                {
                  required: true,
                  message: MESSAGES.FORM_FIELD_REQUIRED_MESSAGE.ENTER_EMAIL,
                },
              ]}
              label="Email Address"
            />
          </Col>
          <Col span={12}>
            <OrganizationsAutoComplete
              required
              name="organization_name"
              label="Organization Name"
              placeholder="Organization Name"
            />
          </Col>
          {/* <Col span={12}>
            <AlectifySelect
              label="Type"
              name="type"
              onChange={onTypeChange}
              options={organizationTypes?.map((org: any) => ({
                value: org?.id,
                label: org?.name,
              }))}
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
                },
              ]}
            />
          </Col>

          <Col span={12}>
            <AlectifySelect
              label="Role"
              name="role"
              options={roles?.map((role: any) => ({
                value: role?.id,
                label: role?.name,
              }))}
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
                },
              ]}
            />
          </Col> */}

          <Col span={12}>
            <AlectifyInput
              className="override-input-affix-wrapper"
              label="New Password"
              name="password"
              type="password"
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.PASSWORD_REQUIRED,
                },
                {
                  min: 6,
                  message: MESSAGES.ADMIN_MESSAGES.PASSWORD_CONTAIN_6_CHAR,
                },
              ]}
              placeholder={MESSAGES.ADMIN_MESSAGES.PASSWORD_REQUIRED}
            />
          </Col>
          <Col span={12}>
            <AlectifyInput
              label="Confirm Password"
              className="override-input-affix-wrapper"
              name="confirmPassword"
              type="password"
              rules={[
                {
                  required: true,
                  message: MESSAGES.ADMIN_MESSAGES.CONFIRM_PASSWORD,
                },
                {
                  min: 6,
                  message: MESSAGES.ADMIN_MESSAGES.PASSWORD_CONTAIN_6_CHAR,
                },
              ]}
              placeholder={MESSAGES.ADMIN_MESSAGES.CONFIRM_PASSWORD_REQUIRED}
            />
          </Col>
        </Row>
        <Space className="d-flex justify-flex-end">
          <AlectifyButton text="Cancel" type="primary" onClick={onCloseModal} />
          <AlectifyButton text="Submit" type="primary" htmlType="submit" />
        </Space>
      </Form>
    </Spin>
  );
};
export default memo(CreateBranchAdminModal);
