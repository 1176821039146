import { memo } from "react";

const InternalUserIcon: React.FC<{
  className?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="#EEEDFF" />
      <path
        d="M11.5773 21.5034C11.5691 21.4272 11.5048 21.3695 11.4282 21.3695H10.5752C10.5326 21.3695 10.492 21.3876 10.4636 21.4193C10.4351 21.451 10.4215 21.4933 10.4261 21.5357C10.666 23.752 11.5328 25.8565 12.7963 27.41C14.0594 28.9629 15.7299 29.9767 17.5748 29.9767C19.4224 29.9767 20.9694 28.9597 22.1078 27.4039C23.2454 25.8492 23.9844 23.746 24.2235 21.536C24.2281 21.4936 24.2144 21.4513 24.186 21.4197C24.1575 21.388 24.117 21.3699 24.0744 21.3699H23.5744C23.4978 21.3699 23.4335 21.4275 23.4253 21.5037C23.2194 23.4027 22.5122 25.3156 21.4733 26.7502C20.433 28.1867 19.077 29.1229 17.5748 29.1229C16.0714 29.1229 14.6782 28.1854 13.6012 26.7481C12.5257 25.313 11.7828 23.4004 11.5773 21.5034Z"
        fill="#7570B3"
        stroke="#7570B3"
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        d="M9.58996 21.5191H25.0604C25.6637 21.5191 26.1518 20.973 26.1518 20.2995C26.1518 19.6255 25.6637 19.0794 25.0604 19.0794H24.7635C24.7635 15.8388 23.1031 13.0332 20.6838 11.6609L20.0673 15.5677C20.0187 15.877 19.7786 16.0946 19.5065 16.0946C19.4738 16.0946 19.4403 16.0916 19.4069 16.0855C19.0967 16.0241 18.89 15.6939 18.9445 15.3471L19.6486 10.8833C19.3843 10.4113 18.9157 10.0967 18.3805 10.0967H16.2695C15.7351 10.0967 15.2669 10.4113 15.0022 10.8833L15.7063 15.3471C15.7612 15.6939 15.5541 16.0245 15.2439 16.0855C15.2105 16.092 15.177 16.0946 15.1443 16.0946C14.8722 16.0946 14.6313 15.8775 14.5835 15.5677L13.967 11.6613C11.5465 13.0328 9.88732 15.8388 9.88732 19.0794H9.58996C8.98709 19.0794 8.49902 19.6255 8.49902 20.2995C8.49902 20.973 8.98709 21.5191 9.58996 21.5191Z"
        fill="#7570B3"
      />
      <path
        d="M10.5752 31.903C12.5494 30.3358 18.2209 28.1417 25.1128 31.903"
        stroke="#7570B3"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M28.3139 29.8566L25.1241 26.8248L28.1559 23.6351"
        stroke="#7570B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M26.1533 26.7959L33.4203 26.6333"
        stroke="#7570B3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(InternalUserIcon);
