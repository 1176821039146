import { Drawer, Spin } from "antd";
import { IAlectifyDrawerProps } from "./AlectifyDrawer.interface";
import "./AlectifyDrawer.scss";
import DrawerFooter from "./DrawerFooter";

const AlectifyDrawer: React.FC<IAlectifyDrawerProps> = (
  props: IAlectifyDrawerProps,
) => {
  return (
    <Drawer
      maskClosable
      open={props.open}
      width={props.width}
      title={props.title}
      onClose={props.onClose}
      rootClassName={`alectify-drawer ${props.className}`}
      closable={props.closeIcon ? true : false} // we are not using close button to close drawer, should be false everywhere.
      closeIcon={props.closeIcon}
      footerStyle={{ textAlign: "right" }}
      footer={
        props.showFooter ? (
          <DrawerFooter
            submitText={props.submitText}
            cancelText={props.cancelText}
            readOnly={props.readOnly}
            onCancel={props.onClose}
            onSubmit={props.onSubmit || console.log} // TODO: onSubmit will be required type.
          />
        ) : props?.customFooter ? (
          props?.customFooter
        ) : null
      }
    >
      <Spin spinning={props.loading} wrapperClassName="full-height">
        {props.children}
      </Spin>
    </Drawer>
  );
};

AlectifyDrawer.defaultProps = {
  className: "",
};

export default AlectifyDrawer;
