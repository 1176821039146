import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

interface IProjectIconProps {
  fill?: string | null;
  className?: string | null;
}
const ProjectIcon: React.FC<IProjectIconProps> = (props: IProjectIconProps) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`${props.className} "Alectify-siderbar-menu-icon"`}
    >
      <g id="Frame">
        <path
          id="Vector"
          d="M16 10.5L18.1494 11.1448C19.5226 11.5568 20.2092 11.7628 20.6046 12.2942C21 12.8256 21 13.5425 21 14.9761V22.5"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          id="Vector_2"
          d="M8 9.5H11M8 13.5H11"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          id="Vector_3"
          d="M12 22.5V19.5C12 18.5572 12 18.0858 11.7071 17.7929C11.4142 17.5 10.9428 17.5 10 17.5H9C8.05719 17.5 7.58579 17.5 7.29289 17.7929C7 18.0858 7 18.5572 7 19.5V22.5"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinejoin="round"
        />
        <path
          id="Vector_4"
          d="M2 22.5H22"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
        />
        <path
          id="Vector_5"
          d="M3 22.5V7.21724C3 4.70649 3 3.45111 3.79118 2.82824C4.58237 2.20537 5.74742 2.54355 8.07752 3.2199L13.0775 4.67122C14.4836 5.07937 15.1867 5.28344 15.5933 5.83965C16 6.39587 16 7.15344 16 8.66857V22.5"
          stroke={props.fill || PROJECT_THEME_COLOR}
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
};

export default ProjectIcon;
