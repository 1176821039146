import { IUserManagementTableProps } from "./UserManagementTable.interface";
import useUserManagementColumn from "./effects/useUsersManegmentColumn";
import AlectifyText from "static/texts.json";
import { useEffect, useRef, useState } from "react";
import {
  createNewUser,
  deleteUser,
  fetchAllUsers,
} from "services/users/users.service";
import { FEATURE_FLAGS, IOrganization, IUser } from "redux/components/Auth";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyModal from "../modal";
import AddUserForm from "../add-user-form";
import { useForm } from "antd/lib/form/Form";
import AlectifyTable from "../table";
import AlectifyButton from "../button";
import { PlusOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import { updateContact } from "services/contacts/contacts.service";
import { MESSAGES } from "constants/messages";
import { PAGINATION } from "constants/index";
import { IPagination } from "../table/AlectifyTable.interface";
import { isFeatureEnabled } from "utils/helpers";

export const UsersManagementTable: React.FC<any> = (
  props: IUserManagementTableProps,
) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [users, setUsers] = useState<IUser[]>([]);
  const [pagination, setPagination] = useState<IPagination>();
  const existingUserRef = useRef<IUser | undefined>();
  const isEditingRef = useRef<boolean>(false);
  const organizationRef = useRef<IOrganization | undefined>();
  const [FormInstance] = useForm();
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );

  const onAddOrUpdateUser = async () => {
    try {
      await FormInstance.validateFields();
      setLoader(true);
      const values = {
        ...FormInstance.getFieldsValue(),
        id: existingUserRef.current?.id,
        new: isEditingRef.current,
      };

      if (existingUserRef.current && isEditingRef.current) {
        await updateContact(values, existingUserRef?.current?.id);
        message.success(
          `${values?.first_name} ${MESSAGES.GENERAL_MESSAGES.UPDATED_MESSAGE}`,
        );
        isEditingRef.current = false;
      } else {
        await createNewUser(activeMasterProject?.id || "", values);
        message.success(
          `${values?.first_name}  ${MESSAGES.GENERAL_MESSAGES.ADDED_MESSAGE}`,
        );
      }
      getUsers();
      FormInstance.resetFields();
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    } finally {
      setLoader(false);
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.ADD_NEW_USER_MODAL,
      });
    }
  };

  const ModalConfigs = {
    name: MODAL_NAMES.ADD_NEW_USER_MODAL,
    title: isEditingRef.current
      ? AlectifyText.UPDATE_USER
      : AlectifyText.ADD_NEW_USER,
    footer: (
      <>
        <AlectifyButton
          text="Cancel"
          type="default"
          htmlType="submit"
          className="alectify-default-button"
          onClick={() => {
            FormInstance.resetFields();
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.ADD_NEW_USER_MODAL,
            });
          }}
        />
        <AlectifyButton
          text="Submit"
          type="primary"
          onClick={onAddOrUpdateUser}
        />
      </>
    ),
    onCancel: () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.ADD_NEW_USER_MODAL,
      });
    },
    children: (
      <Spin spinning={loader}>
        <AddUserForm
          form={FormInstance}
          existingUserRef={existingUserRef}
          organizationRef={organizationRef}
        />
      </Spin>
    ),
  };

  const openAddNewUserModal = () => {
    isEditingRef.current = false;
    FormInstance.resetFields();
    existingUserRef.current = undefined;
    ModalServiceInstance.open(AlectifyModal, ModalConfigs);
  };

  const getUsers = async (options?: IPagination) => {
    try {
      setLoader(true);
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        // limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...options,
      } as any;
      const response = await fetchAllUsers(
        activeMasterProject?.id || "",
        params,
      );
      if (response.success) {
        setUsers(response.data);
        setPagination(response.meta as any);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);

      console.log(ex);
    }
  };

  const onDeleteUser = async (id: string) => {
    try {
      setLoader(true);
      const response = await deleteUser(activeMasterProject?.id || "", {
        user_id: id,
      });
      message.success(response.message);
      getUsers();
      setLoader(false);
    } catch (ex) {
      console.log(ex);
      setLoader(false);
    }
  };
  const onEditUser = async (values: IUser) => {
    isEditingRef.current = true;
    FormInstance.setFieldsValue({
      ...values,
      organization_name: values?.organization?.name,
    });
    existingUserRef.current = values;
    ModalServiceInstance.open(AlectifyModal, {
      ...ModalConfigs,
      title: AlectifyText.UPDATE_TEAM,
    });
  };
  const column = useUserManagementColumn({
    onDelete: onDeleteUser,
    onEdit: onEditUser,
  });

  useEffect(() => {
    getUsers();
  }, [activeMasterProject?.id]);

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.ADD_NEW_USER_MODAL,
      ModalConfigs,
    );
  }, [loader]);

  return (
    <div className="mt-10">
      <AlectifyTable
        showSearch
        dataSource={users}
        columns={column}
        customSearchKey="search"
        loading={loader}
        onDataFetch={getUsers}
        size="small"
        total={pagination?.total_count as any}
        extraButton={
          isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) && (
            <AlectifyButton
              text={AlectifyText.ADD_NEW}
              type="primary"
              icon={<PlusOutlined />}
              onClick={openAddNewUserModal}
            />
          )
        }
      />
    </div>
  );
};

export default UsersManagementTable;
