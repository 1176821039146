import { Dispatch } from "react";
import { SourceActionType } from "redux/interfaces";
import { IChatbotInitialState, actions } from "..";

export const setChatbotResponse: SourceActionType = (
  payload: IChatbotInitialState,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setChatbotAction(payload));
  };
};

export const setAiProcedureResponse: SourceActionType = (
  payload: IChatbotInitialState,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setAiProcedureAction(payload));
  };
};

export const setBase64UrlAction: SourceActionType = (
  payload: IChatbotInitialState,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setBase64Url(payload));
  };
};
