import { useEffect, useState } from "react";
import { Col, Form, Row, Spin, notification } from "antd";
import {
  IDrawSparePartProps,
  IDrawSparePartStates,
} from "./DrawSparePart.interface";
import { IMasterProject } from "redux/components/master-project";
import { ISubProject } from "redux/components/sub-project";
import AlectifyText from "static/texts.json";
import SelectMasterProject from "../master-project-select";
import SelectSubProject from "../sub-project-select/SelectSubProject";
import SparePartDetail from "../spare-part-detail";
import AlectifyButton from "../button";
import AssetsTable from "../assets-table";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { isEmpty, truncate } from "lodash";
import { IAsset } from "redux/components/assets";
import { drawSparePart } from "services/spare-parts/spare-parts.service";
import AlectifyQuantityInput from "../quantity-input";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import AlectifyInput from "../input";
import DrawerServiceInstance from "../CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import { ISparePartDrawPayload } from "services/spare-parts/spare-parts.interface";
import { MESSAGES } from "constants/messages";
import { getSparePartStatus } from "../spare-parts-table/SparePartsTable.helpers";
import { SPARE_PART_STATUS_COMPONENTS } from "../spare-parts-table/effects/useSparePartsColumns";
import { SparePartStatusEnum } from "enums";

const initialState: IDrawSparePartStates = {
  loading: false,
  selectedMasterProject: null,
  selectSubProject: null,
  assetDetail: null,
};
const DrawSparePartForm: React.FC<IDrawSparePartProps> = (
  props: IDrawSparePartProps,
) => {
  const [state, setState] = useState<IDrawSparePartStates>({
    ...initialState,
  });
  const status = getSparePartStatus(props?.sparePart);

  useEffect(() => {
    if (props.sparePart.project) {
      onSelectMasterProject("", props.sparePart.project);
    }
  }, []);

  const onMasterProjectChange = () => {
    props.FormInstance.setFieldValue("subProject", "");
    setState({
      ...state,
      selectSubProject: null,
    });
  };
  const onSelectMasterProject = (
    value: string,
    masterProject: IMasterProject,
  ) => {
    props.FormInstance.setFieldValue("masterProject", masterProject.id);
    props.FormInstance.setFieldValue("subProject", "");
    setState({
      ...state,
      selectedMasterProject: masterProject,
      selectSubProject: null,
      assetDetail: null,
    });
  };

  const onSelectSubProject = (value: string, subProject: ISubProject) => {
    setState({
      ...state,
      selectSubProject: subProject,
      assetDetail: null,
    });
  };
  const equipmentModalHandler = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      name: "select-asset-modal",
      title: AlectifyText.SELECT_ASSET,
      children: (
        <AssetsTable
          selectRowOption
          subProjectId={state.selectSubProject?.id}
          showAction
          showSearch
          onRowSelect={(record: IAsset) => {
            props.FormInstance.setFieldValue("asset", record);
            setState({
              ...state,
              assetDetail: record,
            });
            ModalServiceInstance.close(AlectifyModal, {
              name: "select-asset-modal",
            });
          }}
        />
      ),
      footer: null,
      onCancel: () => {
        ModalServiceInstance.close(AlectifyModal, {
          name: "select-asset-modal",
        });
      },
    });
  };

  const onSubmit = async (values: any) => {
    try {
      const drawQty = parseInt(values.quantity);

      if (drawQty === 0) {
        props.FormInstance.setFields([
          { name: "quantity", errors: ["Quantity must be greater than Zero"] },
        ]);
        return;
      } else if (drawQty > props.sparePart.remainingQuantity) {
        props.FormInstance.setFields([
          {
            name: "quantity",
            errors: [MESSAGES.SPARE_PART_MESSAGES.OVER_IN_HAND_QTY],
          },
        ]);
        return;
      }
      await props.FormInstance.validateFields();
      setState({
        ...state,
        loading: true,
      });

      const payload: ISparePartDrawPayload = {
        projectId: values.masterProject,
        subProjectId: values.subProject,
        comments: values.comments,
        quantity: drawQty,
        assetId:
          state.assetDetail?.type === "Tag"
            ? state.assetDetail?.tag_id
            : undefined,
        areaId:
          state.assetDetail?.type === "PackageRoom"
            ? state.assetDetail?.package_id
            : undefined,
      };

      await drawSparePart(props.sparePart.id, payload);
      props.FormInstance.resetFields();
      setState({
        ...state,
        loading: false,
      });
      DrawerServiceInstance.close(AlectifyDrawer, {
        name: DRAWER_CONSTANTS.DRAWER_DRAW_SPARE_PART,
      });
      props.fetchSpareParts();
    } catch (ex: any) {
      setState({
        ...state,
        loading: false,
      });
      if (ex?.errorFields && ex?.errorFields.length > 0) return;
      notification.error({
        message: "Cannot draw spare parts",
      });
      props.FormInstance.resetFields();
      console.log(ex);
    }
  };

  return (
    <Spin spinning={state.loading}>
      <Row justify="start" gutter={24}>
        <Col span={8}>
          <Form
            name="drawSparePart"
            layout="vertical"
            form={props.FormInstance}
            onFinishFailed={(e) => console.log(e)}
            onFinish={onSubmit}
          >
            <Col span={24}>
              <SelectMasterProject
                onSelect={onSelectMasterProject}
                onChange={onMasterProjectChange}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                disabled
              />
            </Col>
            <Col span={24}>
              <SelectSubProject
                masterProjectId={
                  (state.selectedMasterProject &&
                    state.selectedMasterProject.id) ||
                  ""
                }
                onSelect={onSelectSubProject}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={24}>
              <Form.Item
                label={AlectifyText.SELECT_ASSET}
                name={"asset"}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              >
                <AlectifyButton
                  text={
                    isEmpty(state.assetDetail)
                      ? AlectifyText.SELECT_ASSET
                      : truncate(
                          state.assetDetail?.type === "Tag"
                            ? state.assetDetail?.tag_name
                            : state.assetDetail?.package_name,
                          {
                            length: 20,
                            omission: "...",
                          },
                        )
                  }
                  type="primary"
                  htmlType="button"
                  className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                  onClick={equipmentModalHandler}
                  block
                  disabled={
                    isEmpty(state.selectedMasterProject) ||
                    isEmpty(state.selectSubProject)
                  }
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <AlectifyQuantityInput
                name="quantity"
                label={AlectifyText.QUANTITY}
                placeholder=""
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                form={props.FormInstance}
                max={props.sparePart?.remainingQuantity}
                disabled={status === SparePartStatusEnum.OUT_OF_STOCK}
                maxQtyErrorMsg={MESSAGES.SPARE_PART_MESSAGES.OVER_IN_HAND_QTY}
              />
            </Col>
            <Col span={24}>
              <AlectifyInput
                name="comments"
                label={AlectifyText.COMMENTS}
                type="textarea"
                placeholder=""
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
          </Form>
        </Col>
        <Col span={16}>
          <SparePartDetail sparePart={props.sparePart} showSimilarSparePart />
        </Col>
      </Row>
    </Spin>
  );
};

export default DrawSparePartForm;
