import UseTeamsColumn from "./effects/useTeamsColumns";
import AlectifyText from "static/texts.json";
import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifyModal from "../modal";
import { useForm } from "antd/lib/form/Form";
import AlectifyTable from "../table";
import AlectifyButton from "../button";
import { PlusOutlined } from "@ant-design/icons";
import { Spin, message } from "antd";
import {
  createTeams,
  deleteTeam,
  fetchTeamsList,
  updateTeams,
} from "services/teams/teams.service";
import { ITeams, ITeamsState } from "services/teams/teams.interface";
import AddTeamsForm from "../add-teams-form";
import ConfirmationModal from "../confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { MESSAGES } from "constants/messages";
import {
  IPagination,
  ITableFilterType,
} from "../table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";

export const TeamsTable: React.FC<any> = (props: any) => {
  const [loader, setLoader] = useState<boolean>(false);
  const [teams, setTeams] = useState<ITeamsState>({
    data: [],
    meta: null,
  });
  const [formInstance] = useForm();
  const { activeMasterProject } = useSelector(
    (state: IRootState) => state.common,
  );
  const existingTeamRef = useRef<ITeams | undefined>();
  const filterRef = useRef(true);

  useEffect(() => {
    getTeamsList();
  }, [activeMasterProject?.id]);

  useEffect(() => {
    ModalServiceInstance.updateModalProps(
      MODAL_NAMES.ADD_NEW_TEAMS_MODAL,
      ModalConfigs,
    );
  }, [loader]);

  const getTeamsList = async (options?: IPagination | null) => {
    const params = {
      ...options,
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      is_active: options?.is_active || filterRef.current,
    };
    try {
      setLoader(true);
      const response = await fetchTeamsList(
        activeMasterProject?.id || "",
        params as IPagination,
      );
      if (response.success) {
        setTeams({ ...teams, data: response?.data, meta: response?.meta });
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const openAddNewTeamModal = () => {
    formInstance.resetFields();
    existingTeamRef.current = undefined;
    ModalServiceInstance.open(AlectifyModal, ModalConfigs);
  };

  const onCreateOrUpdateTeam = async () => {
    try {
      await formInstance.validateFields();
      setLoader(true);
      const values = formInstance.getFieldsValue();
      const payload = {
        team_member_ids: values.teamMembers,
        name: values.name,
        description: values.description,
      };

      if (existingTeamRef.current) {
        await updateTeams(
          activeMasterProject?.id || "",
          existingTeamRef.current.id,
          payload,
        );
        message.success(`${values?.name} was updated successfully`);
        setLoader(false);
      } else {
        await createTeams(activeMasterProject?.id || "", payload);
        message.success(`${values?.name} was created successfully`);
      }
      setLoader(false);
      formInstance.resetFields();
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.ADD_NEW_TEAMS_MODAL,
      });
      getTeamsList();
    } catch (ex: any) {
      setLoader(false);
      if (!ex?.response?.data?.success) {
        message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      }
      console.log(ex);
    }
  };

  const onEditTeam = (record: ITeams) => {
    filterRef.current = true;
    formInstance.setFieldsValue({
      name: record.name,
      teamMembers: record?.team_members?.map((member) => member?.id),
      description: record.description,
    });
    existingTeamRef.current = record;
    ModalServiceInstance.open(AlectifyModal, {
      ...ModalConfigs,
      title: AlectifyText.UPDATE_TEAM,
    });
  };

  const openWarningModal = (id: string) => {
    const onCancel = () =>
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      });

    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.PM_EXTERNAL_REMOVE_WARNING_MODAL,
      title: AlectifyText.CONFIRMATION,
      footer: null,
      onCancel,
      children: (
        <ConfirmationModal
          icon={WarningIcon}
          message={MESSAGES.TEAMS_MESSAGES.TEAMS_DELETE}
          onConfirm={() => {
            onDeleteTeams(id);
            onCancel();
          }}
          onCancel={onCancel}
        />
      ),
    });
  };

  const onDeleteTeams = async (id: string) => {
    try {
      filterRef.current = true;
      setLoader(true);
      const response = await deleteTeam(activeMasterProject?.id || "", id);
      if (response.success) {
        message.success(response.message);
        getTeamsList();
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const onRestoreTeams = async (id: string) => {
    try {
      filterRef.current = false;
      setLoader(true);
      const payload = {
        is_active: true,
      };
      const response = await updateTeams(
        activeMasterProject?.id || "",
        id,
        payload,
      );
      if (response.success) {
        message.success(MESSAGES.TEAMS_MESSAGES.TEAM_RESTORE);
        getTeamsList();
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const ModalConfigs = {
    name: MODAL_NAMES.ADD_NEW_TEAMS_MODAL,
    title: existingTeamRef.current
      ? AlectifyText.UPDATE_TEAM
      : AlectifyText.CREATE_NEW_TEAM,
    footer: (
      <>
        <AlectifyButton
          text="Cancel"
          type="default"
          className="alectify-default-button"
          onClick={() => {
            formInstance.resetFields();
            ModalServiceInstance.close(AlectifyModal, {
              name: MODAL_NAMES.ADD_NEW_TEAMS_MODAL,
            });
          }}
        />
        <AlectifyButton
          text="Submit"
          type="primary"
          onClick={onCreateOrUpdateTeam}
        />
      </>
    ),
    onCancel: () => {
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.ADD_NEW_TEAMS_MODAL,
      });
    },
    children: (
      <Spin spinning={loader}>
        <AddTeamsForm form={formInstance} existingUserRef={existingTeamRef} />
      </Spin>
    ),
  };

  const displayRowClass = (record: any) => {
    return !record.is_active ? "disable-row-background " : "";
  };

  const columns = UseTeamsColumn({
    onDelete: openWarningModal,
    onEdit: onEditTeam,
    onRestore: onRestoreTeams,
  });
  return (
    <div className="mt-20">
      <AlectifyTable
        dataSource={teams?.data}
        columns={columns}
        loading={loader}
        onDataFetch={getTeamsList}
        displayRowClass={displayRowClass}
        size="small"
        total={teams?.meta?.total_count || 0}
        scroll={{ y: "calc(100vh - 348px)" }}
        filters={
          {
            key: "is_active",
            defaultValue: "true",
            options: [
              { label: "Active", value: "true" },
              { label: "Inactive", value: "false" },
            ],
          } as any
        }
        filterType={ITableFilterType.DROPDOWN}
        extraButton={
          isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) && (
            <AlectifyButton
              text={AlectifyText.ADD_NEW}
              type="primary"
              icon={<PlusOutlined />}
              onClick={openAddNewTeamModal}
            />
          )
        }
      />
    </div>
  );
};

export default TeamsTable;
