import SparePartsTable from "components/shared/spare-parts-table";
import { memo } from "react";

const RefillSpareParts: React.FC = () => {
  return (
    <SparePartsTable
      scroll={{ y: "calc(100vh - 350px)" }}
      refill
      allowOrderCreation
    />
  );
};

export default memo(RefillSpareParts);
