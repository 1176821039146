import { Dispatch } from "react";
import { IParams, SourceActionType } from "redux/interfaces";
import { fetchSubProjects } from "services/sub-project/sub-project.service";
import { ISubProjectResponse } from "../sub-project.interface";
import { actions } from "..";
import { actions as documentActions } from "../../documents";
import { setActiveSubProject } from "redux/components/common/sources";
import { get, isEmpty } from "lodash";

export const getSubProjects: SourceActionType = (
  masterProejectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setSubProjectLoader(true));
      const response: ISubProjectResponse = await fetchSubProjects(
        masterProejectId,
        params,
      );
      dispatch(actions.getSubProjects(response));
      dispatch(documentActions.clearUploadedDocuments());
      dispatch(actions.setSubProjectLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setSubProjectLoader(false));
    }
  };
};

export const getSubProjectDetails: SourceActionType = (
  masterProejectId: string,
  subProjectId: string,
) => {
  return async (dispatch: Dispatch<any>) => {
    const params = {
      id: subProjectId,
    };
    try {
      const response = await fetchSubProjects(masterProejectId, params);
      if (!isEmpty(response?.data)) {
        dispatch(setActiveSubProject(get(response, "data[0]", null)));
      }
    } catch (error) {
      console.log(error);
    }
  };
};
