import { memo } from "react";

interface IWorkOrderDueIcon {
  onClick?: () => void;
}
const WorkOrderDueIcon: React.FC<IWorkOrderDueIcon> = (
  props: IWorkOrderDueIcon,
) => {
  return (
    <svg
      {...props}
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx="20" cy="20" r="20" fill="#E8F1FD" />
      <path d="M28.2339 7V8.44275H8.03516V7H28.2339Z" fill="#1F2D56" />
      <path d="M28.2273 7.0293V32.9991H26.792V7.0293H28.2273Z" fill="#1F2D56" />
      <path
        d="M28.2339 31.5449V32.9877H8.03516V31.5449H28.2339Z"
        fill="#1F2D56"
      />
      <path d="M9.43531 7.0166V32.9864H8V7.0166H9.43531Z" fill="#1F2D56" />
      <path
        d="M16.6588 9.94043V11.3832H10.8877V9.94043H16.6588Z"
        fill="#1F2D56"
      />
      <path
        d="M19.5784 12.8438V14.2865H10.9258V12.8438H19.5784Z"
        fill="#1F2D56"
      />
      <path
        d="M15.1504 15.6953V17.1381H10.875V15.6953H15.1504Z"
        fill="#1F2D56"
      />
      <path
        d="M19.5784 18.5967V20.0394H10.9258V18.5967H19.5784Z"
        fill="#1F2D56"
      />
      <path
        d="M13.8259 21.3945V22.8373H10.9756V21.3945H13.8259Z"
        fill="#1F2D56"
      />
      <path
        d="M19.6799 24.3975V25.8402H11.0273V24.3975H19.6799Z"
        fill="#1F2D56"
      />
      <path
        d="M16.7476 27.2998V28.7425H10.9453V27.2998H16.7476Z"
        fill="#1F2D56"
      />
      <path
        d="M21.0674 12.7341H22.5373V17.0605H21.0674V12.7341Z"
        fill="#1F2D56"
      />
      <path
        d="M23.8926 12.7341H25.3625V17.0605H23.8926V12.7341Z"
        fill="#1F2D56"
      />
      <path
        d="M25.385 12.7568V14.2268H21.0586V12.7568H25.385Z"
        fill="#1F2D56"
      />
      <path
        d="M25.3909 15.6416V17.1115H21.0645V15.6416H25.3909Z"
        fill="#1F2D56"
      />
      <path
        d="M21.0674 18.4959H22.5373V22.8223H21.0674V18.4959Z"
        fill="#1F2D56"
      />
      <path
        d="M23.8926 18.4959H25.3625V22.8223H23.8926V18.4959Z"
        fill="#1F2D56"
      />
      <path
        d="M25.385 18.5186V19.9885H21.0586V18.5186H25.385Z"
        fill="#1F2D56"
      />
      <path
        d="M25.3909 21.4033V22.8732H21.0645V21.4033H25.3909Z"
        fill="#1F2D56"
      />
      <path
        d="M21.0664 24.2459H22.5363V28.5723H21.0664V24.2459Z"
        fill="#1F2D56"
      />
      <path
        d="M23.8916 24.2459H25.3615V28.5723H23.8916V24.2459Z"
        fill="#1F2D56"
      />
      <path
        d="M25.385 24.2686V25.7385H21.0586V24.2686H25.385Z"
        fill="#1F2D56"
      />
      <path
        d="M25.3909 27.1533V28.6232H21.0645V27.1533H25.3909Z"
        fill="#1F2D56"
      />
      <path
        d="M22.4802 15.7478L25.315 12.913L26.3299 13.9279L23.4951 16.7627L22.4802 15.7478Z"
        fill="#38C6F3"
      />
      <path
        d="M22.2268 14.0202L24.2567 16.0501L23.2418 17.065L21.2119 15.0352L22.2268 14.0202Z"
        fill="#38C6F3"
      />
      <path
        d="M22.7859 21.5739L25.6207 18.7391L26.6356 19.7541L23.8008 22.5889L22.7859 21.5739Z"
        fill="#38C6F3"
      />
      <path
        d="M22.5325 19.8464L24.5623 21.8762L23.5474 22.8912L21.5176 20.8613L22.5325 19.8464Z"
        fill="#38C6F3"
      />
      <path
        d="M22.7849 27.2517L25.6197 24.4169L26.6346 25.4318L23.7998 28.2666L22.7849 27.2517Z"
        fill="#38C6F3"
      />
      <path
        d="M22.5315 25.5232L24.5614 27.553L23.5464 28.5679L21.5166 26.5381L22.5315 25.5232Z"
        fill="#38C6F3"
      />
      <path
        d="M37.0264 10.1163L36.0115 11.1312L32.9509 8.07058L33.9658 7.05566L37.0264 10.1163Z"
        fill="#38C6F3"
      />
      <path
        d="M36.0049 11.1875L34.99 12.2025L31.9294 9.14187L32.9443 8.12695L36.0049 11.1875Z"
        fill="#202D56"
      />
      <path
        d="M36.0032 11.1858L17.6398 29.5493L16.6248 28.5343L34.9883 10.1709L36.0032 11.1858Z"
        fill="#1F2D56"
      />
      <path
        d="M13.5284 30.5958L14.6464 26.4233L17.7009 29.4778L13.5284 30.5958Z"
        fill="#1F2D56"
      />
      <path
        d="M33.972 9.13015L15.6085 27.4936L14.5936 26.4787L32.957 8.11523L33.972 9.13015Z"
        fill="#1F2D56"
      />
      <path
        d="M34.0755 11.0725L16.7079 28.4401L15.693 27.4252L33.0605 10.0576L34.0755 11.0725Z"
        fill="#38C6F3"
      />
      <path
        d="M15.3207 28.81L15.7086 27.3624L16.7683 28.4221L15.3207 28.81Z"
        fill="#38C6F3"
      />
    </svg>
  );
};

export default memo(WorkOrderDueIcon);
