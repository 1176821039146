import { createActionAndReducers } from "redux/utils/reducer.helper";
import { reducerName, ICommonActions } from "./";
import { commonInitialState } from "./common.initialState";
import * as CommonEffects from "./affects";

export * from "./common.constants";
export * from "./common.interface";

const { actions, reducer } = createActionAndReducers<ICommonActions>(
  reducerName,
  commonInitialState,
  CommonEffects,
);

export { actions, commonInitialState, reducer };
