import { memo } from "react";

const EventCompletedPastDueDateIcon: React.FC = () => {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="17" height="17" rx="3" fill="#D90808" />
      <path
        d="M8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15Z"
        fill="white"
      />
      <path
        d="M8.50004 2.86743C7.38587 2.86743 6.29672 3.19782 5.37032 3.81682C4.44393 4.43582 3.72189 5.31562 3.29551 6.34498C2.86914 7.37434 2.75758 8.50701 2.97494 9.59977C3.19231 10.6925 3.72883 11.6963 4.51667 12.4841C5.3045 13.272 6.30827 13.8085 7.40103 14.0259C8.49379 14.2432 9.62646 14.1317 10.6558 13.7053C11.6852 13.2789 12.565 12.5569 13.184 11.6305C13.803 10.7041 14.1334 9.61493 14.1334 8.50076C14.1334 7.76099 13.9877 7.02845 13.7046 6.34498C13.4215 5.66151 13.0065 5.0405 12.4834 4.5174C11.9603 3.99429 11.3393 3.57934 10.6558 3.29624C9.97235 3.01314 9.23981 2.86743 8.50004 2.86743ZM7.37337 11.3174L4.5567 8.50076L5.35057 7.7069L7.37337 9.72276L11.6495 5.4475L12.4434 6.24743L7.37337 11.3174Z"
        fill="#D90808"
      />
    </svg>
  );
};

export default memo(EventCompletedPastDueDateIcon);
