import { memo, useEffect } from "react";
import { IProjectBasicInformationProps } from "./ProjectBasicInformation.interface";
import AlectifyInput from "../input";
import AlectifyText from "static/texts.json";
import { Form, Row, Col } from "antd";
import OrganizationsAutoComplete from "../autocomplete/OrganizationsAutoComplete";
import { fetchMasterProjectDetails } from "services/master-project/masterProject.service";
import { MESSAGES } from "constants/messages";
import "./ProjectBasicInformation.scss";

const ProjectBasicInformation: React.FC<IProjectBasicInformationProps> = ({
  FormInstance,
  onSubmitProfile,
  masterProjectId,
}) => {
  const getProjectDetails = async () => {
    if (masterProjectId) {
      const body = { master_project_id: masterProjectId };
      const response = await fetchMasterProjectDetails(body);
      FormInstance.setFieldsValue({ owner: response?.data[0]?.owner });
    }
  };

  useEffect(() => {
    getProjectDetails();
  }, []);

  return (
    <div className="project-basic-info-container">
      {/* <h3>{AlectifyText.ASSET_CATEGORY_INFORMATION}</h3> */}
      <Form
        name="project-info-form"
        layout="vertical"
        form={FormInstance}
        onFinish={onSubmitProfile}
      >
        <Row justify="start" align="middle" gutter={24}>
          <Col span={24}>
            <AlectifyInput
              name="name"
              label={AlectifyText.ASSET_CATEGORY_NAME}
              type="text"
              placeholder={AlectifyText.ASSET_CATEGORY_NAME}
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                    "{fieldName}",
                    AlectifyText.ASSET_CATEGORY_NAME,
                  ),
                },
              ]}
            />
          </Col>
          <Col span={24}>
            <AlectifyInput
              label={AlectifyText.WORK_ID_PREFIX}
              name="work_id_prefix"
              type="text"
              placeholder={AlectifyText.WORK_ID_PREFIX}
            />
          </Col>
          {/*  <Col span={12}>
            <AlectifyInput
              label="Color"
              name="color"
              type="color"
              placeholder="Enter Color"
              className="master-color-input"
            />
          </Col> */}
          <Col span={24}>
            <AlectifyInput
              label={AlectifyText.DESCRIPTION}
              type="textarea"
              name="description"
              rows={6}
              max={255}
            />
          </Col>
          <Col span={24}>
            <OrganizationsAutoComplete
              name="owner"
              placeholder={AlectifyText.OWNER_ORGANIZATION}
              label={AlectifyText.OWNER_ORGANIZATION}
              disabled={true}
              required
            />
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default memo(ProjectBasicInformation);
