import React from "react";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import { Checkbox } from "antd";
import { IFeaturesTable } from "../Admin.interface";
import { FEATURE_FLAGS } from "redux/components/Auth";

const useLicenseFeatureColumn = (props: IFeaturesTable) => {
  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.COMPANY_NAME,
          dataIndex: "branch",
          width: 100,
          searchable: true,
          visible: true,
          fixed: "left",
          ellipsis: {
            showTitle: false,
          },
          render: (branch: any) => branch?.company?.name || "-",
        },
        {
          title: AlectifyText.BRANCH,
          dataIndex: "branch",
          width: 100,
          searchable: true,
          fixed: "left",
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (branch: any) => branch?.name || "-",
        },
        {
          title: AlectifyText.ALL_WORK_ORDER,
          dataIndex: "all_workorder",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "all_workorder",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.TASK,
          dataIndex: "task",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange("task", ev.target.checked, record?.id)
              }
            />
          ),
        },
        {
          title: AlectifyText.PM_EXTERNAL,
          dataIndex: "pm_external",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "pm_external",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.PM_INTERNAL,
          dataIndex: "pm_internal",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "pm_internal",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.COMPANY_WIDE,
          dataIndex: "companywide",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "companywide",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },

        {
          title: AlectifyText.CONTACTS,
          dataIndex: "contact",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange("contact", ev.target.checked, record?.id)
              }
            />
          ),
        },
        {
          title: AlectifyText.DASHBOARD,
          dataIndex: "dashboard",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "dashboard",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.IMPORT_PROCEDURE,
          dataIndex: "import_procedure",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "import_procedure",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.MANAGE_PROJECT,
          dataIndex: "manage_project",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "manage_project",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.ML_BREAKDOWN_PREDICTION_,
          dataIndex: "ml_breakdown_prediction",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "ml_breakdown_prediction",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.CALENDAR,
          dataIndex: "my_calendar",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "my_calendar",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.NOTIFICATIONS,
          dataIndex: "notification",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "notification",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.PROCEDURE_LIBRARY,
          dataIndex: "procedure_library",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "procedure_library",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.SPARE_PART,
          dataIndex: "sparepart",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "sparepart",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.TIMELINE,
          dataIndex: "timeline",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange("timeline", ev.target.checked, record?.id)
              }
            />
          ),
        },

        {
          title: AlectifyText.WORK_ORDER_DUE_TODAY,
          dataIndex: "work_order_due_today",
          width: 130,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "work_order_due_today",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        /*  {
          title: AlectifyText.WORK_ORDER,
          dataIndex: "workorder",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "workorder",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        }, */
        {
          title: AlectifyText.LLM_FEATURE,
          dataIndex: "llm_docs_search",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "llm_docs_search",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.VIEW_PROJECT,
          dataIndex: "view_project",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "view_project",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        {
          title: AlectifyText.DATA_ROUND,
          dataIndex: "operator_round",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "operator_round",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
        /*    {
          title: AlectifyText.AI_BASED_SEARCHING,
          dataIndex: FEATURE_FLAGS.LLM_DOC_SEARCH,
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  FEATURE_FLAGS.LLM_DOC_SEARCH,
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        }, */
        {
          title: AlectifyText.IOT_DEVICE,
          dataIndex: "iot_device",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
          render: (value: any, record: any) => (
            <Checkbox
              checked={value}
              onChange={(ev) =>
                props.onFeatureChange(
                  "iot_device",
                  ev.target.checked,
                  record?.id,
                )
              }
            />
          ),
        },
      ].filter((column) => column.visible),
    [],
  );
};

export default useLicenseFeatureColumn;
