import { Spin } from "antd";
import { memo } from "react";
import { IAlectifyDetailCardProps } from "./AlectifyDetailCard.interface";

const AlectifyDetailCard: React.FC<IAlectifyDetailCardProps> = (
  props: IAlectifyDetailCardProps,
) => {
  return (
    <Spin spinning={props.loading}>
      <div className={`alectify-detail-card ${props.className}`}>
        {props.children}
      </div>
    </Spin>
  );
};

AlectifyDetailCard.defaultProps = {
  loading: false,
};

export default memo(AlectifyDetailCard);
