import { SparePartStatusEnum } from "enums";
import { IProjectSparePart } from "redux/components/spare-parts";

/**
 * Calculate spare part status value based on pending items
 *
 * @param {IProjectSparePart} sparePart - spare part
 * @returns A string for spare part status
 */
export const getSparePartStatus = (
  sparePart: IProjectSparePart,
): SparePartStatusEnum | null => {
  let status = SparePartStatusEnum.NORMAL;
  if (sparePart.remainingQuantity <= sparePart.minimumQuantity) {
    status = SparePartStatusEnum.LOW_INVENTORY;
  }

  if (sparePart.remainingQuantity <= 0) {
    status = SparePartStatusEnum.OUT_OF_STOCK;
  }
  return status;
};
