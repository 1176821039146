import { memo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import AlectifyTable from "components/shared/table";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";

import useAssetPackagesColumns from "./effects/useAssetPackagesColumns";
import { IAssetPackageTableProps } from "./AssetPackages.interface";
import { getProjectAssetPackages } from "redux/components/asset-packages/sources";

const AssetPackagesTable: React.FC<IAssetPackageTableProps> = (
  props: IAssetPackageTableProps,
) => {
  const dispatch = useDispatch();
  const assetsPackages = useSelector((state: IRootState) => state.assetPackage);
  const columns = useAssetPackagesColumns(props);
  const getAssetPackages = (options?: IPagination) => {
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      per_page: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      type:
        props.parentFormActive === "PackageRoom"
          ? "Tag"
          : "PackageRoom" || null,
      ...options,
    };
    dispatch(getProjectAssetPackages(props.subProjectId, params));
  };

  useEffect(() => {
    getAssetPackages();
  }, [props.subProjectId]);

  return (
    <AlectifyTable
      scroll={props.scroll}
      showSearch={props.showSearch}
      size="small"
      columns={columns}
      loading={assetsPackages.fetching}
      dataSource={assetsPackages.data}
      total={assetsPackages.meta.total_count}
      onDataFetch={getAssetPackages}
      customSearchKey="search"
      extraButton={props.extra}
      rowSelection={props.rowSelection}
      selectedRowKeys={props.selectedRowKeys}
      onRowSelectionAction={props.onRowSelectionAction}
    />
  );
};

export default memo(AssetPackagesTable);
