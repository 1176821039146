// TimelineBreadcrumbs component
import { memo } from "react";
import { Breadcrumb } from "antd";
import { useNavigate } from "react-router-dom";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import { ROUTES } from "routes/Routes.constants";
import { isExternalUser } from "utils/helpers";
import { truncate } from "lodash";
// import { getAssetDetail } from "redux/components/assets/sources";
// import { getAssetPackageDetail } from "redux/components/asset-packages/sources";

interface ITimelineBreadcrumbsProps {
  equipmentId?: string;
  subProjectId?: string;
  equipmentType?: string;
}

const TimelineBreadcrumbs: React.FC<ITimelineBreadcrumbsProps> = ({
  equipmentId,
  subProjectId,
  equipmentType,
}: ITimelineBreadcrumbsProps) => {
  const navigate = useNavigate();
  const { assetDetail } = useSelector(({ assets }: IRootState) => assets);
  const { assetPackageDetail } = useSelector(
    ({ assetPackage }: IRootState) => assetPackage,
  );
  const { user } = useSelector((state: IRootState) => state.auth);
  const { activeSubProject, activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  // useEffect(() => {
  //   if (equipmentType === "asset-package") {
  //     dispatch(getAssetPackageDetail(subProjectId, equipmentId));
  //   } else if (equipmentType === "asset") {
  //     dispatch(getAssetDetail(equipmentId, subProjectId));
  //   }
  // }, [equipmentId, subProjectId]);

  const getAssetPackageRoutes = (tab: "assets" | "asset-packages") => {
    const baseRoute = `${ROUTES.PROJECT}/${activeMasterProject?.id}${ROUTES.SUB_PROJECT}/${subProjectId}?page=${tab}`;

    return baseRoute;
  };

  const getAssetPackageDetailsRoutes = () => {
    const baseRoute = `${ROUTES.SUB_PROJECT}/${activeSubProject?.id}/timeline/asset-package`;

    const assetPackageId =
      assetPackageDetail?.data?.id || assetDetail?.data?.packageroom?.id || "";

    return `${baseRoute}/${assetPackageId}`;
  };

  const getBreadcrumbItems = () => {
    const items = [
      {
        title: truncate(AlectifyText.PARENT_ASSET_NAME, {
          length: 20,
          omission: "...",
        }),
        href: "#",
        onClick: () => {
          if (activeSubProject?.id) {
            if (!isExternalUser(user)) {
              navigate(getAssetPackageRoutes("asset-packages"));
            }
          }
        },
      },
      {
        title:
          equipmentType === "asset-package"
            ? truncate(assetPackageDetail?.data?.name ?? "-", {
                length: 20,
                omission: "...",
              })
            : truncate(assetDetail?.data?.packageroom?.name ?? "-", {
                length: 20,
                omission: "...",
              }),
        ...(equipmentType === "asset" ? { href: "#" } : {}),
        onClick: () => {
          if (activeSubProject?.id) {
            if (equipmentType === "asset" && !isExternalUser(user)) {
              navigate(getAssetPackageDetailsRoutes());
            }
          }
        },
      },
      ...(equipmentType === "asset"
        ? [
            {
              title: AlectifyText.ASSET,
              href: "#",
              onClick: () => {
                if (activeSubProject?.id) {
                  if (!isExternalUser(user)) {
                    navigate(getAssetPackageRoutes("assets"));
                  }
                }
              },
            },
            {
              title: truncate(assetDetail?.data?.name ?? "-", {
                length: 20,
                omission: "...",
              }),
            },
          ]
        : []),
    ];
    return items;
  };

  return <Breadcrumb className="mt-15 mb-15" items={getBreadcrumbItems()} />;
};

export default memo(TimelineBreadcrumbs);
