import { Dispatch } from "react";
import { IGlobalSearchInitialState, actions } from "..";
import { IParams, SourceActionType } from "redux/interfaces";
import { fetchGlobalEquipments } from "services/assets/assets.service";

export const setGlobalSearchResponse: SourceActionType = (
  payload: IGlobalSearchInitialState,
) => {
  return (dispatch: Dispatch<any>) => {
    dispatch(actions.setGlobalSearchAction(payload));
  };
};

export const getAssetAndPackage: SourceActionType = (params: IParams) => {
  return async (dispatch: Dispatch<any>) => {
    dispatch(actions.setGlobalSearchLoader(true));
    try {
      const response = await fetchGlobalEquipments(params);
      dispatch(actions.setGlobalSearchAction(response));
      dispatch(actions.setGlobalSearchLoader(false));
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const resetGetAssetAndPkg: SourceActionType = () => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.resetGlobalSearchState());
    } catch (ex) {
      console.log(ex);
    }
  };
};

export const setGlobalSearchPage: SourceActionType = (payload: number) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setGlobalSearchPageState(payload));
    } catch (ex) {
      console.log(ex);
    }
  };
};
