export const extractPhoneNumber = (
  phoneNumber: string,
): { number: string; code: string } => {
  const phoneNumberString = phoneNumber.toString();
  const phoneNumberLength = phoneNumberString.length;
  const rightmostDigits = phoneNumberString.slice(-10);
  const countryCode = phoneNumberString.slice(0, phoneNumberLength - 10);

  return {
    number: rightmostDigits,
    code: countryCode,
  };
};
