import { get } from "lodash";
import { useSelector } from "react-redux";
import { Space, Typography } from "antd";
import { IRootState } from "redux/rootReducer";
import { useForm } from "antd/es/form/Form";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import AlectifyLogo from "assets/images/alectify-logo.svg";
import LocationMarkerGreen from "assets/images/location-marker-green.svg";
import "./MasterProjectDetailCard.scss";
import EditIcon from "components/icons/EditIcon";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import AlectifyDrawer from "components/drawer";
import MasterProjectCreationForm from "pages/master-project/listing/MasterProjectCreationForm";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import { CloseOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";

const MasterProjectDetailCard: React.FC<any> = () => {
  const [FormInstance] = useForm();
  const project = useSelector(
    ({ common }: IRootState) => common.activeMasterProject,
  );
  const location = useLocation();

  return (
    <div className="master-project-detail-card">
      <div className="header">
        <Typography.Title>{get(project, "name", "")}</Typography.Title>
        {isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) && (
          <div
            className="cursor-pointer"
            onClick={() =>
              DrawerServiceInstance.open(AlectifyDrawer, {
                name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                title: "Update Master Project",
                showFooter: true,
                closeIcon: <CloseOutlined />,
                closable: true,
                onClose: () => {
                  FormInstance.resetFields();
                  DrawerServiceInstance.close(AlectifyDrawer, {
                    name: DRAWER_CONSTANTS.CHANGE_SUB_PROJECT_DRAWER,
                  });
                },

                onSubmit: FormInstance.submit,
                width: 600,
                children: (
                  <MasterProjectCreationForm
                    FormInstance={FormInstance}
                    existingMasterProject={project}
                  />
                ),
              })
            }
          >
            <EditIcon />
          </div>
        )}
      </div>
      <div className="body">
        <Typography.Paragraph>
          {get(project, "description", "")}
        </Typography.Paragraph>
        <div className="details">
          {!location.pathname.includes(ROUTES.DATA_ROUND) && (
            <>
              <AssetPackageTag count={get(project, "pkg_count", 0)} />
              <AssetTag count={get(project, "tags_count", 0)} />
            </>
          )}
          <div className="organization">
            <Space>
              <img src={AlectifyLogo} alt="" />
              {get(project, "owner", "")}
            </Space>
          </div>
          <div className="location">
            <Space>
              <img src={LocationMarkerGreen} alt="" />
              {get(project, "address", "")}
            </Space>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MasterProjectDetailCard;
