import { Tooltip } from "antd";
import AlectifyText from "static/texts.json";
import BulbIcon from "./BulbIcon";

const FortuneGlass: React.FC<{ onClick?: () => void; isActive?: boolean }> = ({
  onClick,
  isActive,
}) => {
  return isActive ? (
    <Tooltip title={AlectifyText.BREAKDOWN_PREDICTION}>
      <div className="fortune-glass" onClick={onClick}>
        <embed
          type="video/webm"
          src="https://lottie.host/embed/8ef8da17-a4d5-4774-b372-297f16c05f75/P5ZTwbZKiV.json"
        />
      </div>
    </Tooltip>
  ) : (
    <Tooltip title={AlectifyText.BREAKDOWN_PREDICTION}>
      <BulbIcon className="bulb-icon" />
    </Tooltip>
  );
};

export default FortuneGlass;
