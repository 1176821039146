import { MULTIPART_HEADERS, pmHttp } from "utils/client";
import {
  DELETE_DOCUMENT_ID,
  INCIDENT_REPORT_ID,
  INCIDENT_REPORT_STATUS,
  INCIDENT_REPORTS,
} from "./incident-reports.endpoints";
import { IParams } from "redux/interfaces";

export const getAllIncidentReports = async (params?: IParams) => {
  try {
    const response = await pmHttp.get(INCIDENT_REPORTS, params);
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const createIncidentReport = async (payload?: FormData) => {
  try {
    const response = await pmHttp.post(
      INCIDENT_REPORTS,
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateIncidentReports = async (id: string, payload: FormData) => {
  try {
    const response = await pmHttp.patch(
      INCIDENT_REPORT_ID.replace("{id}", id),
      payload,
      MULTIPART_HEADERS,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteIncidentReports = async (id: string) => {
  try {
    const response = await pmHttp.delete(
      INCIDENT_REPORT_ID.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const deleteIncidentDocument = async (id: string) => {
  try {
    const response = await pmHttp.delete(
      DELETE_DOCUMENT_ID.replace("{id}", id),
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateIncidentReportStatus = async (
  id: string,
  payload: {
    status: string;
  },
) => {
  try {
    const response = await pmHttp.patch(
      INCIDENT_REPORT_STATUS.replace("{id}", id),
      payload,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
