import { memo } from "react";

interface INoAssetTagIconProps {
  fill?: string;
  className?: string;
}

const NoAssetTagIcon: React.FC<INoAssetTagIconProps> = ({
  fill = "#4e4e4e",
  className,
}) => {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 4 120 100"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {/* Outer circle */}
      <circle
        cx="50"
        cy="50"
        r="45"
        stroke={fill}
        strokeWidth="10"
        fill="none"
      />
      {/* Diagonal line */}
      <line x1="20" y1="20" x2="80" y2="80" stroke={fill} strokeWidth="10" />
    </svg>
  );
};

export default memo(NoAssetTagIcon);
