import { useEffect, useState } from "react";
import {
  Avatar,
  Col,
  Collapse,
  DatePicker,
  Divider,
  Row,
  Skeleton,
  Space,
  Spin,
  Tag,
  Timeline,
  Tooltip,
  Typography,
} from "antd";
import { useParams } from "react-router-dom";
import { CircleDotIcon } from "components/icons";
import { ITimelineResponse, TimelineTypeEnum } from "redux/components/common";
import { AssetTimelineLabel } from "components/shared/asset-timeline";
import { fetchEquipmentTimeline } from "services/assets/assets.service";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import Comments from "components/shared/comments";
import TextToLink from "components/shared/text-to-link";
import { PM_STATUS } from "enums";
import { PM_STATUS_COMPONENTS } from "components/shared/pm-internal-table/effects/usePmInternalColumns";
import PmDetail from "components/shared/pm-detail";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import DrawHistory from "components/shared/draw-history";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";
import dayjs from "dayjs";
import {
  CloseOutlined,
  DownOutlined,
  MessageOutlined,
} from "@ant-design/icons";
import InfiniteScroll from "react-infinite-scroll-component";
import "./AssetTimeline.scss";

const { Panel } = Collapse;

const { RangePicker } = DatePicker;

interface IAssetTimelineProps {
  setTimelinePage?: any;
  timelinePage?: number;
  timeline: ITimelineResponse;
  initialLoad: boolean;
  dateRange: [string, string] | null;
  setTimeline: (value: any) => void;
  setDateRange: (value: any) => void;
}

const AssetTimeline: React.FC<IAssetTimelineProps> = ({
  timelinePage,
  setTimelinePage,
  timeline,
  initialLoad,
  dateRange,
  setTimeline,
  setDateRange,
}) => {
  const { subProjectId } = useParams();
  const [activeTimeline, setActiveTimeline] = useState<any>();

  if (timeline.fetching && initialLoad) return <Skeleton active round />;

  const customPagination = (
    <div>
      {timeline?.meta?.totalItems && timeline?.meta?.totalItems > 0 ? (
        <Typography.Text>
          {`Showing ${Math.min(
            timelinePage && timeline?.meta?.itemsPerPage
              ? timelinePage * timeline?.meta?.itemsPerPage
              : 0,
            timeline?.meta?.totalItems,
          )} out of ${timeline?.meta?.totalItems}`}
        </Typography.Text>
      ) : null}
    </div>
  );
  return (
    <Spin spinning={timeline.fetching}>
      <div className="asset-timeline-wrapper">
        <div className="timeline-date-search">
          <div className="ml-10">{customPagination}</div>
          <Space align="center">
            <Typography.Text>Date Search:</Typography.Text>
            <RangePicker
              className=""
              value={
                dateRange ? [dayjs(dateRange[0]), dayjs(dateRange[1])] : null
              }
              onChange={(dates) => {
                setTimelinePage(1);
                setTimeline({
                  ...timeline,
                  data: [],
                });
                if (dates && dates.length === 2 && dates[0] && dates[1]) {
                  setDateRange([
                    dates[0].format("YYYY-MM-DD"),
                    dates[1].format("YYYY-MM-DD"),
                  ]);
                } else {
                  setDateRange(null);
                }
              }}
            />
          </Space>
        </div>
        <div id="scrollableDiv" className="timeline-box">
          {!timeline.data.length ? (
            <AlectifyEmpty description="Records Not Found" />
          ) : (
            <InfiniteScroll
              key="activity-sidebar"
              dataLength={timeline.data?.length}
              scrollThreshold={0.9}
              next={() => {
                setTimelinePage(timelinePage && timelinePage + 1);
              }}
              hasMore={
                timeline?.meta?.totalItems
                  ? timeline?.data?.length < timeline?.meta?.totalItems
                  : true
              }
              loader={
                <div className="timeline-skeleton-loader">
                  <Skeleton avatar paragraph={{ rows: 1 }} active />
                </div>
              }
              endMessage={
                !timeline?.fetching && <Divider plain>Nothing more.</Divider>
              }
              scrollableTarget="scrollableDiv"
            >
              <Timeline
                mode={"left"}
                className="assets-timeline"
                items={timeline.data.map((item, idx: number) => ({
                  key: `${item?.id}-${idx}-${item.type}` || idx.toString(),
                  dot: <CircleDotIcon />,
                  label: <AssetTimelineLabel timeline={item} />,
                  children: (
                    <Collapse
                      accordion
                      destroyInactivePanel
                      onChange={(ev) => setActiveTimeline(ev)}
                      activeKey={activeTimeline as any}
                      className="timeline-collapse"
                      expandIcon={({ isActive }) => (
                        <DownOutlined
                          rotate={isActive ? 180 : 0}
                          className="timeline-arrow-icon"
                        />
                      )}
                    >
                      <Panel
                        header={
                          <Row justify={"start"} align={"top"} gutter={12}>
                            <Col span={2}>
                              <Avatar size={"large"} src={NO_IMAGE_AVATAR} />
                            </Col>
                            <Col span={14}>
                              <TextToLink
                                text={item.name || "-"}
                                className="at-item-title"
                                onClick={(ev) => {
                                  const detailsProps: any = {
                                    pmId: item.id,
                                    pmType: item.type,
                                  };
                                  if (
                                    item.type === TimelineTypeEnum.PM_EXTERNAL
                                  ) {
                                    detailsProps["pmExternalRecord"] = item;
                                  }
                                  // else {
                                  //   detailsProps["pmInternalRecord"] = item;
                                  // }
                                  ev.stopPropagation();
                                  if (
                                    item.type === TimelineTypeEnum.SPARE_PART
                                  ) {
                                    DrawerServiceInstance.open(AlectifyDrawer, {
                                      width: 900,
                                      title: `${item.name} History`,
                                      name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
                                      showFooter: false,
                                      children: (
                                        <DrawHistory
                                          sparePart={{ id: item.id } as any}
                                        />
                                      ),
                                      closeIcon: <CloseOutlined />,
                                      closable: true,
                                      onClose: () =>
                                        DrawerServiceInstance.close(
                                          AlectifyDrawer,
                                          {
                                            name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
                                          },
                                        ),
                                    });
                                  } else {
                                    DrawerServiceInstance.open(AlectifyDrawer, {
                                      width: 480,
                                      title: item.name,
                                      name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                                      closeIcon: <CloseOutlined />,
                                      closable: true,
                                      onClose: () =>
                                        DrawerServiceInstance.close(
                                          AlectifyDrawer,
                                          {
                                            name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                                          },
                                        ),
                                      children: <PmDetail {...detailsProps} />,
                                    });
                                  }
                                }}
                              />
                              <Typography.Title level={4}></Typography.Title>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: item.detail || "",
                                }}
                              />
                              {/* <Typography.Paragraph className="desc" dangerouslySetInnerHTML={{ __html: item.detail || ""}} /> */}
                            </Col>
                            <Col span={8} className="text-align-right">
                              {PM_STATUS_COMPONENTS[item.status as PM_STATUS]}

                              <Tooltip placement="topLeft" title={"History"}>
                                <Tag
                                  color="#fff"
                                  className="round-tag"
                                  style={{
                                    color: "#555",
                                    border: "1px solid #E8F1FD",
                                  }}
                                >
                                  <Space size={2}>
                                    <MessageOutlined className="icon-font-size-16" />
                                    {item.type === TimelineTypeEnum.SPARE_PART
                                      ? "1"
                                      : item.commentsCount || 0}
                                  </Space>
                                </Tag>
                              </Tooltip>
                            </Col>
                          </Row>
                          // <Space size={1}>
                          //   <Avatar size={"large"} src={NO_IMAGE_AVATAR} />
                          //   <Space direction="vertical" size={0}>
                          //     <TextToLink
                          //       text={item.name || "-"}
                          //       className="at-item-title"
                          //       onClick={(ev) => {
                          //         const detailsProps: any = {
                          //           pmId: item.id,
                          //           pmType: item.type,
                          //         };
                          //         if (
                          //           item.type === TimelineTypeEnum.PM_EXTERNAL
                          //         ) {
                          //           detailsProps["pmExternalRecord"] = item;
                          //         }
                          //         // else {
                          //         //   detailsProps["pmInternalRecord"] = item;
                          //         // }
                          //         ev.stopPropagation();
                          //         if (
                          //           item.type === TimelineTypeEnum.SPARE_PART
                          //         ) {
                          //           DrawerServiceInstance.open(AlectifyDrawer, {
                          //             width: 900,
                          //             title: `${item.name} History`,
                          //             name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
                          //             showFooter: false,
                          //             children: (
                          //               <DrawHistory
                          //                 sparePart={{ id: item.id } as any}
                          //               />
                          //             ),
                          //             closeIcon: <CloseOutlined />,
                          //             closable: true,
                          //             onClose: () =>
                          //               DrawerServiceInstance.close(
                          //                 AlectifyDrawer,
                          //                 {
                          //                   name: DRAWER_CONSTANTS.DRAW_HISTORY_DRAWER,
                          //                 },
                          //               ),
                          //           });
                          //         } else {
                          //           DrawerServiceInstance.open(AlectifyDrawer, {
                          //             width: 480,
                          //             title: item.name,
                          //             name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                          //             closeIcon: <CloseOutlined />,
                          //             closable: true,
                          //             onClose: () =>
                          //               DrawerServiceInstance.close(
                          //                 AlectifyDrawer,
                          //                 {
                          //                   name: DRAWER_CONSTANTS.PM_EXTERNAL_DETAIL_DRAWER,
                          //                 },
                          //               ),
                          //             children: <PmDetail {...detailsProps} />,
                          //           });
                          //         }
                          //       }}
                          //     />
                          //     <Typography.Title level={4}></Typography.Title>
                          //     <Typography.Paragraph className="desc">
                          //       {item.detail}
                          //     </Typography.Paragraph>
                          //   </Space>
                          // </Space>
                        }
                        key={`${item?.id}-${idx}-${item.type}`}
                        showArrow
                        // extra={
                        //   <>
                        //     {PM_STATUS_COMPONENTS[item.status as PM_STATUS]}

                        //     <Tooltip placement="topLeft" title={"History"}>
                        //       <Tag
                        //         color="#fff"
                        //         className="round-tag"
                        //         style={{
                        //           color: "#555",
                        //           border: "1px solid #E8F1FD",
                        //         }}
                        //       >
                        //         <Space size={2}>
                        //           <MessageOutlined className="icon-font-size-16" />
                        //           {item.type === TimelineTypeEnum.SPARE_PART
                        //             ? "1"
                        //             : item.commentsCount || 0}
                        //         </Space>
                        //       </Tag>
                        //     </Tooltip>
                        //   </>
                        // }
                      >
                        <Comments
                          details={item}
                          referenceId={item?.id}
                          reference_type={item?.type}
                          subProjectId={subProjectId || ""}
                          hideEditor
                          noScrollToBottom
                          isTimeline
                        />
                      </Panel>
                    </Collapse>
                  ),
                }))}
              />
            </InfiniteScroll>
          )}
        </div>
      </div>
    </Spin>
  );
};

export default AssetTimeline;
