import { AutoComplete } from "antd";
import { IAlectifyAutoCompleteProps } from "./autocomplete.interface";

import "./autocomplete.scss";

const AlectifyAutoComplete: React.FC<IAlectifyAutoCompleteProps> = (
  props: IAlectifyAutoCompleteProps,
) => {
  return <AutoComplete className="alectify-autocomplete" {...props} />;
};

export default AlectifyAutoComplete;
