interface ICardViewIconProps {
  fill?: string | null;
  className?: string | null;
}
const CardViewIcon: React.FC<ICardViewIconProps> = (
  props: ICardViewIconProps,
) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={props.className || ""}
    >
      <g clip-path="url(#clip0_21602_298805)">
        <rect
          x="0.5"
          y="0.5"
          width="23"
          height="23"
          rx="3.5"
          stroke={props.fill || "#787879"}
        />
        <mask
          id="mask0_21602_298805"
          maskUnits="userSpaceOnUse"
          x="4"
          y="4"
          width="16"
          height="16"
        >
          <path
            d="M19.4078 4.60742H4.58984V19.3914H19.4078V4.60742Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_21602_298805)">
          <path
            d="M7.70984 10.9031C7.20384 10.9031 6.69784 10.9031 6.19184 10.9031C5.98788 10.908 5.78496 10.8726 5.59475 10.7988C5.40454 10.725 5.23079 10.6143 5.0835 10.4732C4.9362 10.332 4.81826 10.1631 4.73646 9.97622C4.65466 9.78932 4.61061 9.58809 4.60684 9.38411C4.58418 8.30144 4.58418 7.21844 4.60684 6.13511C4.60471 5.73965 4.75956 5.35951 5.03741 5.0781C5.31525 4.79669 5.6934 4.63701 6.08884 4.63411C7.18884 4.59711 8.30084 4.59811 9.40484 4.64011C9.6097 4.63909 9.81259 4.68004 10.001 4.76044C10.1894 4.84083 10.3594 4.95898 10.5004 5.10758C10.6414 5.25618 10.7505 5.43208 10.8209 5.62446C10.8913 5.81683 10.9216 6.02159 10.9098 6.22611C10.9312 7.25011 10.9312 8.27444 10.9098 9.29911C10.9175 9.5155 10.8798 9.7311 10.7992 9.93205C10.7185 10.133 10.5968 10.3149 10.4416 10.4659C10.2865 10.617 10.1015 10.7339 9.89849 10.8092C9.69548 10.8845 9.47896 10.9165 9.26284 10.9031C8.74484 10.9031 8.22784 10.9031 7.70984 10.9031Z"
            fill={props.fill || "#787879"}
            stroke={props.fill || "#787879"}
          />
          <path
            d="M16.2272 10.907C15.7092 10.907 15.1922 10.907 14.6742 10.907C14.4673 10.9115 14.2616 10.8746 14.0691 10.7986C13.8766 10.7225 13.7013 10.6088 13.5533 10.464C13.4054 10.3193 13.2879 10.1464 13.2077 9.95561C13.1275 9.76482 13.0862 9.55993 13.0862 9.35296C13.0702 8.29363 13.0702 7.23396 13.0862 6.17396C13.081 5.9754 13.1153 5.77778 13.1872 5.59257C13.259 5.40737 13.3668 5.23828 13.5045 5.0951C13.6422 4.95193 13.807 4.83753 13.9892 4.75854C14.1715 4.67955 14.3676 4.63754 14.5662 4.63496C15.6662 4.59696 16.7782 4.59796 17.8832 4.63996C18.0882 4.63881 18.2912 4.67966 18.4797 4.76C18.6682 4.84033 18.8383 4.95845 18.9794 5.10707C19.1205 5.25568 19.2297 5.43164 19.3002 5.62408C19.3707 5.81652 19.401 6.02136 19.3892 6.22596C19.4105 7.24996 19.4105 8.2743 19.3892 9.29896C19.3973 9.51538 19.36 9.73108 19.2797 9.93222C19.1995 10.1334 19.078 10.3155 18.9232 10.4669C18.7684 10.6183 18.5836 10.7357 18.3807 10.8115C18.1779 10.8873 17.9614 10.9198 17.7452 10.907C17.2392 10.907 16.7332 10.907 16.2272 10.907Z"
            fill={props.fill || "#787879"}
            stroke={props.fill || "#787879"}
          />
          <path
            d="M7.74434 19.3919C7.22634 19.3919 6.70934 19.3919 6.19134 19.3919C5.98443 19.3965 5.77866 19.3598 5.58613 19.2839C5.3936 19.2079 5.21818 19.0943 5.07016 18.9496C4.92215 18.805 4.80452 18.6322 4.72418 18.4415C4.64385 18.2507 4.60242 18.0458 4.60234 17.8389C4.58568 16.7795 4.58568 15.7199 4.60234 14.6599C4.5973 14.4611 4.6318 14.2634 4.70386 14.0781C4.77591 13.8928 4.88408 13.7237 5.02207 13.5806C5.16006 13.4375 5.32512 13.3233 5.50765 13.2445C5.69019 13.1658 5.88656 13.1241 6.08534 13.1219C7.18534 13.0839 8.29734 13.0849 9.40234 13.1219C9.60728 13.1206 9.8103 13.1613 9.99888 13.2415C10.1875 13.3217 10.3576 13.4398 10.4988 13.5883C10.64 13.7368 10.7493 13.9127 10.8199 14.1051C10.8905 14.2975 10.921 14.5023 10.9093 14.7069C10.9307 15.7309 10.9307 16.7552 10.9093 17.7799C10.9171 17.9962 10.8796 18.2118 10.7991 18.4128C10.7187 18.6137 10.5971 18.7957 10.4422 18.9469C10.2873 19.0981 10.1025 19.2153 9.89969 19.2909C9.69684 19.3665 9.48043 19.3988 9.26434 19.3859C8.75834 19.3859 8.25234 19.3859 7.74634 19.3859"
            fill={props.fill || "#787879"}
          />
          <path
            d="M7.74434 19.3919C7.22634 19.3919 6.70934 19.3919 6.19134 19.3919C5.98443 19.3965 5.77866 19.3598 5.58613 19.2839C5.3936 19.2079 5.21818 19.0943 5.07016 18.9496C4.92215 18.805 4.80452 18.6322 4.72418 18.4415C4.64385 18.2507 4.60242 18.0458 4.60234 17.8389C4.58568 16.7795 4.58568 15.7199 4.60234 14.6599C4.5973 14.4611 4.6318 14.2634 4.70386 14.0781C4.77591 13.8928 4.88408 13.7237 5.02207 13.5806C5.16006 13.4375 5.32512 13.3233 5.50765 13.2445C5.69019 13.1658 5.88656 13.1241 6.08534 13.1219C7.18534 13.0839 8.29734 13.0849 9.40234 13.1219C9.60728 13.1206 9.8103 13.1613 9.99888 13.2415C10.1875 13.3217 10.3576 13.4398 10.4988 13.5883C10.64 13.7368 10.7493 13.9127 10.8199 14.1051C10.8905 14.2975 10.921 14.5023 10.9093 14.7069C10.9307 15.7309 10.9307 16.7552 10.9093 17.7799C10.9171 17.9962 10.8796 18.2118 10.7991 18.4128C10.7187 18.6137 10.5971 18.7957 10.4422 18.9469C10.2873 19.0981 10.1025 19.2153 9.89969 19.2909C9.69684 19.3665 9.48043 19.3988 9.26434 19.3859C8.75834 19.3859 8.25234 19.3859 7.74634 19.3859"
            stroke={props.fill || "#787879"}
          />
          <path
            d="M16.2398 19.3893C15.7218 19.3893 15.2048 19.3893 14.6868 19.3893C14.4771 19.395 14.2684 19.3583 14.0732 19.2813C13.878 19.2044 13.7004 19.0888 13.551 18.9415C13.4016 18.7942 13.2834 18.6183 13.2037 18.4243C13.1239 18.2302 13.0842 18.0221 13.0868 17.8123C13.0738 16.7763 13.0768 15.7403 13.0868 14.7043C13.0768 14.503 13.1074 14.3017 13.1766 14.1124C13.2458 13.923 13.3522 13.7495 13.4896 13.602C13.6271 13.4545 13.7927 13.3361 13.9766 13.2537C14.1606 13.1713 14.3592 13.1266 14.5608 13.1223C15.6768 13.0823 16.7968 13.0843 17.9128 13.1283C18.1177 13.13 18.32 13.1736 18.5074 13.2565C18.6948 13.3394 18.8632 13.4598 19.0022 13.6103C19.1413 13.7608 19.248 13.9381 19.3159 14.1315C19.3837 14.3248 19.4113 14.5299 19.3968 14.7343C19.4128 15.7463 19.4158 16.7593 19.3968 17.7713C19.406 17.9907 19.3687 18.2095 19.2873 18.4134C19.2059 18.6173 19.0823 18.8017 18.9245 18.9545C18.7668 19.1072 18.5785 19.2248 18.3721 19.2996C18.1657 19.3744 17.9457 19.4046 17.7268 19.3883C17.2328 19.3883 16.7388 19.3883 16.2438 19.3883"
            fill={props.fill || "#787879"}
          />
          <path
            d="M16.2398 19.3893C15.7218 19.3893 15.2048 19.3893 14.6868 19.3893C14.4771 19.395 14.2684 19.3583 14.0732 19.2813C13.878 19.2044 13.7004 19.0888 13.551 18.9415C13.4016 18.7942 13.2834 18.6183 13.2037 18.4243C13.1239 18.2302 13.0842 18.0221 13.0868 17.8123C13.0738 16.7763 13.0768 15.7403 13.0868 14.7043C13.0768 14.503 13.1074 14.3017 13.1766 14.1124C13.2458 13.923 13.3522 13.7495 13.4896 13.602C13.6271 13.4545 13.7927 13.3361 13.9766 13.2537C14.1606 13.1713 14.3592 13.1266 14.5608 13.1223C15.6768 13.0823 16.7968 13.0843 17.9128 13.1283C18.1177 13.13 18.32 13.1736 18.5074 13.2565C18.6948 13.3394 18.8632 13.4598 19.0022 13.6103C19.1413 13.7608 19.248 13.9381 19.3159 14.1315C19.3837 14.3248 19.4113 14.5299 19.3968 14.7343C19.4128 15.7463 19.4158 16.7593 19.3968 17.7713C19.406 17.9907 19.3687 18.2095 19.2873 18.4134C19.2059 18.6173 19.0823 18.8017 18.9245 18.9545C18.7668 19.1072 18.5785 19.2248 18.3721 19.2996C18.1657 19.3744 17.9457 19.4046 17.7268 19.3883C17.2328 19.3883 16.7388 19.3883 16.2438 19.3883"
            stroke={props.fill || "#787879"}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_21602_298805">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
export default CardViewIcon;
