import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { PAGINATION } from "constants/index";
import { IWorkOrder } from "services/work-orders/work-order.interface";
import {
  IPagination,
  ITableFilterType,
} from "../table/AlectifyTable.interface";
import { fetchWorkOrders } from "services/work-orders/work-order.service";
import { IDashboardWorkOrdersProps } from "./DashboardWorkOrders.interface";
import useDashboardWorkOrdersColumns from "./effects/useDashboardOrdersColumns";
import AlectifyTable from "../table";
import { MaintenanceCategoriesEnum } from "enums";
import { PM_TYPES } from "redux/components/pm-external";

interface IWorkOrderState {
  data: IWorkOrder[];
  total: number;
  fetching: boolean;
}

const DashboardWorkOrders: React.FC<IDashboardWorkOrdersProps> = (
  props: IDashboardWorkOrdersProps,
) => {
  const columns = useDashboardWorkOrdersColumns({
    overDueTag: ["dueDate", "assignees"].includes(props.filters?.key),
  });
  const [workOrders, setWorkOrders] = useState<IWorkOrderState>({
    data: [],
    total: 0,
    fetching: false,
  });

  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const { user } = useSelector(({ auth }: IRootState) => auth);

  const getDashboardWorkOrders = async (options?: IPagination) => {
    try {
      let pmType =
        (options?.pmType as string) || props?.defaultParams?.pmType || "all";
      const params = {
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
        ...(props.defaultParams ?? {}),
        ...options,
        projectIds: activeMasterProject?.id
          ? [activeMasterProject?.id]
          : undefined,
        "teamMembers[]": user.id,
        createdById: user.id,
        pmType: pmType || undefined,
      };
      if (
        pmType === MaintenanceCategoriesEnum.CORRECTIVE_MAINTENANCE ||
        pmType === MaintenanceCategoriesEnum.PREVENTIVE_MAINTENANCE
      ) {
        params.taskCategory = pmType;
        pmType = "all";
        delete params.pmType;
      }
      if (props.type === "BY_ASSIGNEES") {
        delete params["teamMembers[]"];
        delete params["createdById"];
        params.assignees = options?.assignees || [user.id];
      }
      delete params?.per_page;
      setWorkOrders({ ...workOrders, fetching: true });
      const response = await fetchWorkOrders("all", "all", pmType, params);
      setWorkOrders({
        fetching: false,
        data: response.data,
        total: response.meta.totalItems,
      });
    } catch (error) {
      setWorkOrders({ ...workOrders, fetching: false });
    }
  };

  useEffect(() => {
    getDashboardWorkOrders(
      props.defaultParams ? { ...props.defaultParams } : {},
    );
  }, []);

  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      columnFilterAsString
      scroll={{ x: 500 }}
      size="small"
      columns={columns}
      loading={workOrders.fetching}
      dataSource={workOrders.data}
      total={workOrders.total}
      className="alectify-table-card-columns"
      onDataFetch={getDashboardWorkOrders}
      filters={props.filters}
      defaultFilterValue={props.defaultParams?.pmType}
      filterType={ITableFilterType.DROPDOWN}
      customSearchKey="search"
    />
  );
};

export default DashboardWorkOrders;
