import { ClockCircleOutlined } from "@ant-design/icons";
import { Space, Tag } from "antd";
// import { WaitingIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const InProgressTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#27A0FD"} className="round-tag">
      <Space size={3}>
        <ClockCircleOutlined /> {text || AlectifyText.IN_PROGRESS}
      </Space>
    </Tag>
  );
};

export default InProgressTag;
