import { Modal } from "antd";
import { IAlectifyModalProps } from "./AlectifyModal.interface";
import "./AlectifyModal.scss";

const AlectifyModal: React.FC<IAlectifyModalProps> = (
  props: IAlectifyModalProps,
) => {
  return (
    <Modal
      onCancel={props.onCancel}
      {...props}
      className={`alectify-modal ${props.className}`}
    >
      {props.children}
    </Modal>
  );
};

AlectifyModal.defaultProps = {
  className: "",
};

export default AlectifyModal;
