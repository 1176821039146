import { Col, Radio, Row } from "antd";
import { isEmpty } from "lodash";
import { useSelector } from "react-redux";
import AlectifyText from "static/texts.json";
import { IMasterProject } from "redux/components/master-project";
import { IRootState } from "redux/rootReducer";
import { IProjectFilterModalProps } from "./ProjectFilter.interface";
import "./ProjectFilterModal.scss";
import { useState } from "react";
import AlectifyInput from "../input";
import SearchIcon from "components/icons/SearchIcon";
import AlectifyEmpty from "../empty/AlectifyEmpty";

const ProjectFilterModal: React.FC<IProjectFilterModalProps> = (
  props: IProjectFilterModalProps,
) => {
  const masterProjects = useSelector(
    ({ MasterProject }: IRootState) => MasterProject,
  );
  const [filtered, setFiltered] = useState<IMasterProject[]>(
    masterProjects.data,
  );
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const handleCheckboxChange = (projectId: string) => {
    props.setSelectedProjects(projectId);
  };

  const searchProject = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value.toLowerCase();

    if (masterProjects.data) {
      const matchingObjects = masterProjects.data.filter(
        (item: IMasterProject) => {
          // Remove extra white spaces and convert to lowercase for case-insensitive search
          const cleanedValue = item.name.replace(/\s+/g, " ").toLowerCase();
          if (cleanedValue.includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        },
      );
      setFiltered(matchingObjects);
    }
  };

  return (
    <>
      <AlectifyInput
        type="text"
        prefix={<SearchIcon />}
        name="searchProject"
        placeholder={AlectifyText.SEARCH_A_SITE}
        onChange={searchProject}
        className="alectify-project-filter-search-input mb-5"
        allowClear
        autoComplete="off"
      />
      <Col className="dashboard-filter-container">
        {!isEmpty(filtered) ? (
          <Radio.Group
            className="full-width"
            defaultValue={
              isEmpty(activeMasterProject) ? "all" : activeMasterProject?.id
            }
          >
            <Row className="masterproject-filter-row border-none">
              <Radio
                className="d-flex"
                onChange={(e) => handleCheckboxChange("all")}
                value={"all"}
              >
                {AlectifyText.ALL_SITES} ({masterProjects?.data?.length})
              </Radio>
            </Row>
            {filtered?.map((item: IMasterProject) => {
              return (
                <Row
                  className="masterproject-filter-row"
                  key={item?.id}
                  justify={"start"}
                  align={"middle"}
                >
                  <Radio
                    className="d-flex"
                    onChange={(e) => handleCheckboxChange(item?.id)}
                    value={item?.id}
                    checked={activeMasterProject?.id === item.id}
                  >
                    <div className="project-filter-name-and-color">
                      <div
                        className="masterproject-color"
                        style={{ background: item?.color, marginRight: 10 }}
                      ></div>
                      <div>{item?.name}</div>
                    </div>
                  </Radio>
                </Row>
              );
            })}
          </Radio.Group>
        ) : (
          <AlectifyEmpty description="No Results Found" />
        )}
      </Col>
    </>
  );
};

export default ProjectFilterModal;
