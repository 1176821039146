import React from "react";
import AlectifyEmptyState from "components/shared/empty/AlectifyEmptyState";
const IotDevices: React.FC = () => {
  return (
    <>
      <AlectifyEmptyState description="Auto Work Order creation from connected IoT devices" />
    </>
  );
};

export default IotDevices;
