import { IMeta, IMetaNest, IReduxAction } from "redux/interfaces";

// Add your actions here with interface
export interface IDocumentsActions {
  getAssetDocuments: IReduxAction<IAssetDocumentResponse>;
  getDocumentsCount: IReduxAction<IDocumentsCountResponse>;
  getProjectDocuments: IReduxAction<IProjectDocumentResponse>;
  getAssetPackageDocuments: IReduxAction<IAssetPackageDocumentResponse>;
  setDocumentsLoader: IReduxAction<boolean>;
  setUploadedDocuments: IReduxAction<any[]>;
  clearUploadedDocuments: IReduxAction<any>;
  resetDocumentState: IReduxAction<any>;
}

export interface IDocuments {
  id: string;
  file_name: string;
  file_path: string;
  file_type: string;
  dir_key: string;
  created_by: ICreatedBy;
  created_at: Date;
  soft_deleted_at?: string;
  updated_at: string;
  project_name: string;
  tag_name: string;
  is_active: boolean;
  document_type: string;
  project: string;
  document_id: string;
  comment: string;
  package_name: string;
  tag: string;
  package: string;
  master_project_name: string;
  version_number: string;
}

export enum DocumentTypes {
  ASSET = "ASSET",
  TASK = "TASK",
  PM_INTERNAL = "PM_INTERNAL",
  PM_EXTERNAL = "PM_EXTERNAL",
  ASSET_PACKAGE = "ASSET_PACKAGE",
  PROJECT_DOCUMENT = "ProjectDocuments",
}

export interface IViewDocument {
  areaId: string;
  assetId: string;
  createdAt: string;
  createdByEmail: string;
  createdByFirstName: string;
  createdById: string;
  createdByLastName: string;
  deletedByEmail: string;
  deletedByFirstName: string;
  deletedById: string;
  deletedByLastName: string;
  fileName: string;
  filePath: string;
  fileType: string;
  folder: string;
  id: string;
  isActive: boolean;
  projectId: string;
  softDeletedAt: string;
  subProjectId: string;
  type: DocumentTypes;
  updatedAt: string;
  comment: string;
}

export interface IDocumentRecord {
  comment: string | null;
  created_at: string;
  id: string;
  created_by: {
    id: string;
    first_name: string;
    last_name: string;
    image: string;
    email: string;
  };
  deleted_by: null;
  dir_key: string;
  document_id: string;
  document_type: string;
  file_name: string;
  file_path: string;
  file_type: string;
  is_active: boolean;
  key: number;
  master_project: string;
  master_project_name: string;
  project: string;
  project_name: string;
  soft_deleted_at: boolean | null;
}
export interface IDocumentState {
  fetching: boolean;
  assetDocs: IAssetDocumentResponse;
  projectDocs: IProjectDocumentResponse;
  assetPackageDocs: IAssetPackageDocumentResponse;
  uploadedDocuments: any[];
}

export interface IProjectDocumentResponse {
  data: IDocuments[];
  message: string;
  success: boolean;
  meta: IMeta;
  fetching: boolean;
}
export interface IAssetDocumentResponse {
  data: IViewDocument[];
  message: string;
  success: boolean;
  meta: IMetaNest;
  fetching: boolean;
}
export interface IAssetPackageDocumentResponse {
  data: IViewDocument[];
  message: string;
  success: boolean;
  meta: IMetaNest;
  fetching: boolean;
}

export interface ICreatedBy {
  id: string;
  first_name: string;
  last_name: string;
  image: string;
  email: string;
}

export interface IDocumentsCountResponse {
  data: IDocumentsCounts;
  success: boolean;
  message: "";
}

export interface IDocumentsCounts {
  asset_docs_count: number;
  project_docs_count: number;
  package_docs_count: number;
}
