export const GET_SPARE_PARTS = "/spare-parts";
export const GET_SPARE_PARTS_DETAIL = "/spare-parts/{projectSparePartId}";
export const GET_GLOBAL_SPARE_PARTS = "/spare-parts/global";
export const CREATE_SPARE_PART = "/spare-parts";
export const DELETE_SPARE_PART = "/spare-parts";
export const UPDATE_SPARE_PART = "/spare-parts/{sparePartId}";
export const GET_SPARE_PARTS_PROJECT_CATEGORIES =
  "/spare-parts/categories/{masterProjectId}";
export const DRAW_SPARE_PARTS = "/manage-orders/borrow/{sparePartId}";
export const REFILL_SPARE_PART = "/manage-orders/restock/v2/{sparePartId}";
export const FIND_SPARE_PARTS = "/spare-parts/find-parts/{partNumber}";
export const DRAW_SPARE_PARTS_HISTORY =
  "/manage-orders/draw-history/{sparePartId}";
export const GET_SPARE_PART_VENDORS = "/spare-parts/preferred-suppliers";
export const GET_SPARE_PART_CATEGORIES = "/spare-parts/categories";
export const GET_SPARE_PART_DASHBOARD_STATS = "/spare-parts/dashboard/stats";
export const GET_SPARE_PART_MONTHLY_COST_HISTORY =
  "/spare-parts/dashboard/monthly-cost-history";
export const GET_SPARE_PART_MONTHLY_COUNTS_HISTORY =
  "/spare-parts/dashboard/monthly-counts-history";
export const DOWNLOAD_SPARE_PARTS_AS_CSV = "/spare-parts/download";
export const WORK_ORDER_SPARE_PARTS_HISTORY =
  "/manage-orders/work-order-draw-history/{workOrderId}";
export const CREATE_SPARE_PART_ORDER = "/manage-orders/{projectSparePartId}";
export const DELETE_SPARE_PART_ORDER = "/manage-orders/{orderId}";
