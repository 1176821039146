interface IDragIconProps {
  fill?: string | null;
}
const DragIcon: React.FC<IDragIconProps> = (props: IDragIconProps) => {
  return (
    <svg
      width="12"
      height="21"
      viewBox="0 0 12 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="2.5" cy="2.5" r="2.5" fill={props.fill || "#636466"} />
      <circle cx="9.5" cy="2.5" r="2.5" fill={props.fill || "#636466"} />
      <circle cx="2.5" cy="10.5" r="2.5" fill={props.fill || "#636466"} />
      <circle cx="9.5" cy="10.5" r="2.5" fill={props.fill || "#636466"} />
      <circle cx="2.5" cy="18.5" r="2.5" fill={props.fill || "#636466"} />
      <circle cx="9.5" cy="18.5" r="2.5" fill={props.fill || "#636466"} />
    </svg>
  );
};
export default DragIcon;
