export const DOCUMENT_CATEGORIES = {
  ASSET_CATEGORY_DOCUMENTS: "project_documents",
  ASSET_DOCUMENTS: "asset_documents",
};

export interface AssetStatuses {
  1: string;
  2: string;
  3: string;
  [key: string]: string;
}

export const PROJECT_SUB_FOLDERS = {
  ELECTRICAL_PACKAGES: "Electrical Packages",
  ADDENDUMS: "Addendums",
  POWER_STUDIES: "Power Studies",
  OTHERS: "Others",
  // PRIVATE: "Private",
};

export const ASSET_SUB_FOLDERS = {
  IMAGES: "Images",
  VIDEOS: "Videos",
  FINAL_DRAWINGS: "Final Drawings",
  INSTRUCTION_MANUAL: "Instruction Manual",
  TEST_REPORTS: "Test Reports",
  SERVICE_REPORTS: "Service Reports",
  OTHERS: "Others",
};

export const DOCUMENTS_SUB_FOLDERS = {
  IMAGES: "Images",
  VIDEOS: "Videos",
  FINAL_DRAWINGS: "Final Drawings",
  INSTRUCTION_MANUAL: "Instruction Manual",
  TEST_REPORTS: "Test Reports",
  SERVICE_REPORTS: "Service Reports",
  ELECTRICAL_PACKAGES: "Electrical Packages",
  ADDENDUMS: "Addendums",
  POWER_STUDIES: "Power Studies",
  OTHERS: "Others",
};

export const TAGS_OPERATON_CSV_HEADERS = [
  "Item #",
  "Asset",
  "Description",
  "Manufacturer",
];

export const EXPECTED_HEADERS = [
  ...TAGS_OPERATON_CSV_HEADERS,
  "Model",
  "Serial Number",
  "Preferred Supplier",
  "Installation Date",
  "Warranty End Date",
  "Asset Type",
  "Asset Status",
  "Group",
  "Area",
  "Location",
];

export const ASSET_STATUSES: AssetStatuses = {
  1: "Active",
  2: "Spare",
  3: "Inactive",
};

export const DATES_KEYS = [
  "order_date",
  "approval_date",
  "release_date",
  "installation_date",
  "ship_date",
  "test_date",
  "pm_start_internal",
  "pm_start_external",
  "pm_end_external",
  "warranty_date",
];

export const TABLE_HEADER_VALUES = {
  ASSETS_NAME: "Asset Name",
  ASSETS: "Assets",
  ASSETS_PACKAGE: "Asset Package",
};

export const PROJECT_API_ENUM = {
  BASIC_INFO: "basic_info",
  USERS: "users",
  TAGS: "tags",
  PACKAGES: "packages",
  DOCUMENT: "documents",
  SERVICE_HISTORY: "service_history",
};
Object.freeze(PROJECT_API_ENUM);

export const DOCUMENT_TYPE = {
  ASSETS: "asset_documents",
  SITE: "project_documents",
};
