import { memo } from "react";

const SmallCalendarIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="16"
      viewBox="0 0 13 16"
      fill="none"
    >
      <path
        d="M4.29464 8.9375H3.13393C2.94241 8.9375 2.78571 8.7793 2.78571 8.58594V7.41406C2.78571 7.2207 2.94241 7.0625 3.13393 7.0625H4.29464C4.48616 7.0625 4.64286 7.2207 4.64286 7.41406V8.58594C4.64286 8.7793 4.48616 8.9375 4.29464 8.9375ZM7.42857 8.58594V7.41406C7.42857 7.2207 7.27187 7.0625 7.08036 7.0625H5.91964C5.72812 7.0625 5.57143 7.2207 5.57143 7.41406V8.58594C5.57143 8.7793 5.72812 8.9375 5.91964 8.9375H7.08036C7.27187 8.9375 7.42857 8.7793 7.42857 8.58594ZM10.2143 8.58594V7.41406C10.2143 7.2207 10.0576 7.0625 9.86607 7.0625H8.70536C8.51384 7.0625 8.35714 7.2207 8.35714 7.41406V8.58594C8.35714 8.7793 8.51384 8.9375 8.70536 8.9375H9.86607C10.0576 8.9375 10.2143 8.7793 10.2143 8.58594ZM7.42857 11.3984V10.2266C7.42857 10.0332 7.27187 9.875 7.08036 9.875H5.91964C5.72812 9.875 5.57143 10.0332 5.57143 10.2266V11.3984C5.57143 11.5918 5.72812 11.75 5.91964 11.75H7.08036C7.27187 11.75 7.42857 11.5918 7.42857 11.3984ZM4.64286 11.3984V10.2266C4.64286 10.0332 4.48616 9.875 4.29464 9.875H3.13393C2.94241 9.875 2.78571 10.0332 2.78571 10.2266V11.3984C2.78571 11.5918 2.94241 11.75 3.13393 11.75H4.29464C4.48616 11.75 4.64286 11.5918 4.64286 11.3984ZM10.2143 11.3984V10.2266C10.2143 10.0332 10.0576 9.875 9.86607 9.875H8.70536C8.51384 9.875 8.35714 10.0332 8.35714 10.2266V11.3984C8.35714 11.5918 8.51384 11.75 8.70536 11.75H9.86607C10.0576 11.75 10.2143 11.5918 10.2143 11.3984ZM13 3.78125V14.0938C13 14.8701 12.3761 15.5 11.6071 15.5H1.39286C0.623884 15.5 0 14.8701 0 14.0938V3.78125C0 3.00488 0.623884 2.375 1.39286 2.375H2.78571V0.851562C2.78571 0.658203 2.94241 0.5 3.13393 0.5H4.29464C4.48616 0.5 4.64286 0.658203 4.64286 0.851562V2.375H8.35714V0.851562C8.35714 0.658203 8.51384 0.5 8.70536 0.5H9.86607C10.0576 0.5 10.2143 0.658203 10.2143 0.851562V2.375H11.6071C12.3761 2.375 13 3.00488 13 3.78125ZM11.6071 13.918V5.1875H1.39286V13.918C1.39286 14.0146 1.47121 14.0938 1.56696 14.0938H11.433C11.5288 14.0938 11.6071 14.0146 11.6071 13.918Z"
        fill="white"
      />
    </svg>
  );
};

export default memo(SmallCalendarIcon);
