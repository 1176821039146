import { Space } from "antd";
import { IDrawerFooterProps } from "./AlectifyDrawer.interface";
import AlectifyButton from "components/shared/button";

const DrawerFooter: React.FC<IDrawerFooterProps> = (
  props: IDrawerFooterProps,
) => {
  return (
    <Space>
      <AlectifyButton
        text={props.cancelText ? props.cancelText : "Cancel"}
        type="default"
        htmlType="submit"
        className="alectify-default-button"
        onClick={props.onCancel}
      />
      {!props.readOnly && (
        <AlectifyButton
          text={props.submitText ? props.submitText : "Submit"}
          type="primary"
          onClick={props.onSubmit}
        />
      )}
    </Space>
  );
};
export default DrawerFooter;
