import { Col, Row, Tag, Tooltip, Typography } from "antd";
import TextToLink from "components/shared/text-to-link";
import { get } from "lodash";
import { IDataRoundProjectCardProps } from "./DataRoundProject.interface";
import { isFeatureEnabled } from "utils/helpers";
import { FEATURE_FLAGS } from "redux/components/Auth";
import EditIcon from "components/icons/EditIcon";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { IRootState } from "redux/rootReducer";
import { useSelector } from "react-redux";
import AssetPackagesColoredIcon from "components/icons/AssetPackagesColoredIcon";
import AssetsColoredIcon from "components/icons/AssetsColoredIcon";

const DataRoundProjectCard: React.FC<IDataRoundProjectCardProps> = (
  props: IDataRoundProjectCardProps,
) => {
  const navigate = useNavigate();
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );
  const onRoute = () => {
    navigate(
      ROUTES.DATA_ROUND_DETAIL.replace(
        ":masterProjectId",
        activeMasterProject?.id || "",
      ).replace(":projectId", props.project.id),
    );
  };
  return (
    <div className="sub-project-card">
      <div className="header" onClick={onRoute}>
        <Row justify="space-between" gutter={16}>
          <Col span={20} className="cursor-pointer">
            <TextToLink
              text={get(props?.project, "name", "-")}
              onClick={onRoute}
            />
          </Col>
          <Col span={4}>
            <div className="draft-edit-icons">
              {isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT) &&
                props.showEditButton && (
                  <div
                    className="cursor-pointer"
                    onClick={(ev) => {
                      ev.stopPropagation();
                      props.openCreationDrawer(
                        props.project.id,
                        get(props?.project, "name", "-"),
                        true,
                        props.project,
                      );
                    }}
                  >
                    <EditIcon />
                  </div>
                )}
              {props?.project.is_draft && (
                <div className="draft-container ml-5">Draft</div>
              )}
            </div>
          </Col>
        </Row>
      </div>
      <div
        className="body cursor-pointer alectify-sub-project-card-description mb-0"
        onClick={onRoute}
      >
        {/* <Typography.Paragraph>
          {get(props?.project, "description", "") || "-"}
        </Typography.Paragraph> */}
        {/* <AvatarGroup users={props?.subProject.accounts || 0} /> */}
        <div className="footer mt-30" onClick={onRoute}>
          <Tooltip placement="top" title={"Groups"}>
            <Tag color={"#0d76a3"}>
              <AssetPackagesColoredIcon />
              <span>{props?.project.total_areas_count || 0}</span>
            </Tag>
          </Tooltip>
          <Tooltip placement="top" title={"Checkpoints"}>
            <Tag color="#DFF2FB" style={{ color: "#0d76a3" }}>
              <AssetsColoredIcon fill={"#0d76a3"} />
              <span>{props?.project?.total_checkpoints_count || 0}</span>
            </Tag>
          </Tooltip>
        </div>
      </div>
    </div>
  );
};
export default DataRoundProjectCard;
