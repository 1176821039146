import React from "react";
import { Tag } from "antd";
import { ClockCircleFilled, CheckCircleOutlined } from "@ant-design/icons";

import "../analytics-cards/Analytics.scss";

const CustomTitleComponent: React.FC<{ status: string }> = ({ status }) => (
  <Tag
    icon={
      status === "Completed" ? (
        <CheckCircleOutlined className="icon-size-14" />
      ) : (
        <ClockCircleFilled className="icon-size-14" />
      )
    }
    color={status === "Completed" ? "#3CAC15" : "#FFB900"}
  >
    {status}
  </Tag>
);

export default CustomTitleComponent;
