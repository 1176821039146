import { Typography } from "antd";
import { memo, ReactElement, ReactNode } from "react";
import "./AlectifyDetailCardTitle.scss";

interface IAlectifyDetailCardTitleProps {
  title?: string | ReactNode | undefined;
  description?: string | ReactElement;
  className?: string;
  extra?: string | number | undefined | ReactElement;
  onClick?: () => void;
}

const AlectifyDetailCardTitle: React.FC<IAlectifyDetailCardTitleProps> = (
  props: IAlectifyDetailCardTitleProps,
) => {
  return (
    <div
      className={`alectify-detail-card-title ${props.className}`}
      onClick={props.onClick}
    >
      <Typography.Title level={5}>{props.title}</Typography.Title>
      {props.description ? (
        <Typography.Paragraph>{props.description}</Typography.Paragraph>
      ) : (
        <></>
      )}
      {props.extra}
    </div>
  );
};

AlectifyDetailCardTitle.defaultProps = {
  title: "",
  className: "",
  description: "",
};

export default memo(AlectifyDetailCardTitle);
