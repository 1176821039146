import { Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { memo } from "react";

const eyeIconStyle: React.CSSProperties = {
  color: "#0954f1",
  fontSize: "1.3rem",
};

interface IEyeIconProps {
  title?: string;
  className?: string;
  onClick?: (ev: any) => void;
  styles?: React.CSSProperties;
}

const EyeIcon: React.FC<IEyeIconProps> = (props: IEyeIconProps) => {
  return (
    <Tooltip title={props.title}>
      <EyeOutlined
        className={props.className}
        onClick={props.onClick}
        style={{ ...eyeIconStyle, ...props.styles }}
      />
    </Tooltip>
  );
};

EyeIcon.defaultProps = {
  title: "View Details",
  styles: {},
  className: "",
};

export default memo(EyeIcon);
