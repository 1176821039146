import { memo, FC, SVGProps } from "react";

const DeleteIcon: FC<SVGProps<SVGSVGElement>> = (props) => {
  return (
    <svg
      {...props}
      width="28"
      height="28"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0417 6.4585H10.8333C10.9479 6.4585 11.0417 6.36475 11.0417 6.25016V6.4585H18.9583V6.25016C18.9583 6.36475 19.0521 6.4585 19.1667 6.4585H18.9583V8.3335H20.8333V6.25016C20.8333 5.33089 20.0859 4.5835 19.1667 4.5835H10.8333C9.91406 4.5835 9.16667 5.33089 9.16667 6.25016V8.3335H11.0417V6.4585ZM24.1667 8.3335H5.83333C5.3724 8.3335 5 8.70589 5 9.16683V10.0002C5 10.1147 5.09375 10.2085 5.20833 10.2085H6.78125L7.42448 23.8283C7.46615 24.7163 8.20052 25.4168 9.08854 25.4168H20.9115C21.8021 25.4168 22.5339 24.7189 22.5755 23.8283L23.2187 10.2085H24.7917C24.9062 10.2085 25 10.1147 25 10.0002V9.16683C25 8.70589 24.6276 8.3335 24.1667 8.3335ZM20.7109 23.5418H9.28906L8.65885 10.2085H21.3411L20.7109 23.5418Z"
        fill="#F94C4C"
      />
    </svg>
  );
};

export default memo(DeleteIcon);
