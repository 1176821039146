import { useState, useEffect } from "react";
import { Col, Drawer, Row, Tooltip, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { IMasterProject } from "redux/components/master-project";
import AlectifyText from "static/texts.json";
import AlectifyInput from "components/shared/input";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import {
  setActiveMasterProject,
  setMasterProjectSidebar,
} from "redux/components/common/sources";
import SearchIcon from "components/icons/SearchIcon";
import { isEmpty, truncate } from "lodash";
import "./index.scss";
import AlectifyEmpty from "components/shared/empty/AlectifyEmpty";

const MasterProjectSidebarListing: React.FC<{ collapsed: boolean }> = ({
  collapsed,
}) => {
  const state = useSelector((state: IRootState) => state);
  const { common, MasterProject } = state;
  const [filtered, setFiltered] = useState<IMasterProject[]>(
    MasterProject.data,
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const searchObjects = (event: React.ChangeEvent<HTMLInputElement>) => {
    const searchString = event.target.value.toLowerCase();

    if (state.MasterProject.data) {
      const matchingObjects = state.MasterProject.data.filter(
        (item: IMasterProject) => {
          // Remove extra white spaces and convert to lowercase for case-insensitive search
          const cleanedValue = item.name.replace(/\s+/g, " ").toLowerCase();
          if (cleanedValue.includes(searchString.toLowerCase())) {
            return true;
          }
          return false;
        },
      );
      setFiltered(matchingObjects);
    }
  };

  const onProjectClickHandler = (project: IMasterProject) => {
    dispatch(setMasterProjectSidebar(false));
    dispatch(setActiveMasterProject(project));
    if (location.pathname.includes(ROUTES.DATA_ROUND)) {
      navigate(`${ROUTES.DATA_ROUND}/${project.id}`);
      setFiltered(MasterProject.data);
    } else {
      navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${project.id}`);
      setFiltered(MasterProject.data);
    }
  };

  const onCloseHandler = () => {
    dispatch(setMasterProjectSidebar(false));
    setFiltered(MasterProject.data);
  };

  useEffect(() => {
    setFiltered(MasterProject.data);
  }, [MasterProject.data]);

  return (
    <Drawer
      open={common.masterProjectSidebar}
      placement="left"
      rootClassName={`alectify-master-project-sidebar-container ${
        collapsed ? "sidebar-drawer-closed" : "sidebar-drawer-expand"
      }`}
      zIndex={0}
      closable={true}
      headerStyle={{ display: "none" }}
      destroyOnClose={true}
      width={282}
      onClose={onCloseHandler}
      motion={undefined}
    >
      <Typography.Title level={5} className="mt-0 mb-5">
        {AlectifyText.SITES}
      </Typography.Title>

      <AlectifyInput
        type="text"
        prefix={<SearchIcon />}
        name="searchProject"
        placeholder={AlectifyText.SEARCH_A_SITE}
        onChange={searchObjects}
        className="alectify-master-project-sidebar-search mb-5"
        allowClear
        autoComplete="off"
      />
      <Row
        justify="center"
        align={"middle"}
        className="alectify-master-project-list-container"
      >
        {isEmpty(filtered) ? (
          <>
            <AlectifyEmpty description="No Results" />
          </>
        ) : (
          filtered.map((project: IMasterProject) => {
            return (
              <Col
                span={24}
                key={project.id}
                className={`alectify-master-project-list ${
                  common.activeMasterProject &&
                  common.activeMasterProject.id === project.id
                    ? "active-project"
                    : ""
                }`}
                onClick={() => onProjectClickHandler(project)}
              >
                <Tooltip title={project.name}>
                  <Typography.Paragraph>
                    {truncate(project.name)}
                  </Typography.Paragraph>
                </Tooltip>
              </Col>
            );
          })
        )}
      </Row>
    </Drawer>
  );
};

export default MasterProjectSidebarListing;
