import { memo } from "react";

const MinusIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M11 1C5.47768 1 1 5.47768 1 11C1 16.5223 5.47768 21 11 21C16.5223 21 21 16.5223 21 11C21 5.47768 16.5223 1 11 1ZM15.2857 11.5357C15.2857 11.6339 15.2054 11.7143 15.1071 11.7143H6.89286C6.79464 11.7143 6.71429 11.6339 6.71429 11.5357V10.4643C6.71429 10.3661 6.79464 10.2857 6.89286 10.2857H15.1071C15.2054 10.2857 15.2857 10.3661 15.2857 10.4643V11.5357Z"
        fill="#E8F1FD"
      />
      <path
        d="M7 11L15 11"
        stroke="#0954F1"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default memo(MinusIcon);
