import { IReduxActionResponse } from "redux/interfaces";
import {
  IAssetDocumentResponse,
  IAssetPackageDocumentResponse,
  IDocumentState,
  IDocumentsCountResponse,
  IProjectDocumentResponse,
} from "redux/components/documents/documents.interface";
import { documentInitialState } from "redux/components/documents/documents.initialState";

export const getProjectDocuments = (
  state: any,
  action: IReduxActionResponse<IProjectDocumentResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      projectDocs: {
        data: payload.data,
        meta: payload.meta,
        message: payload.message,
      },
    };
  }
  return state;
};

export const getAssetDocuments = (
  state: any,
  action: IReduxActionResponse<IAssetDocumentResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      assetDocs: {
        data: payload.data,
        meta: payload.meta,
        message: payload.message,
      },
    };
  }
  return state;
};

export const getAssetPackageDocuments = (
  state: any,
  action: IReduxActionResponse<IAssetPackageDocumentResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      assetPackageDocs: {
        data: payload.data,
        meta: payload.meta,
        message: payload.message,
      },
    };
  }
  return state;
};

export const setDocumentsLoader = (
  state: any,
  action: IReduxActionResponse<boolean>,
) => {
  const { payload } = action;
  return {
    ...state,
    fetching: payload,
  };
};

export const getDocumentsCount = (
  state: IDocumentState,
  action: IReduxActionResponse<IDocumentsCountResponse>,
) => {
  const { payload } = action;
  if (payload) {
    return {
      ...state,
      documentsCount: {
        data: payload.data,
        message: payload.message,
        succuss: payload.success,
      },
    };
  }
  return state;
};

// Upload Documents Affects

export const setUploadedDocuments = (
  state: IDocumentState,
  action: IReduxActionResponse<any[]>,
): IDocumentState => {
  const { payload } = action;
  return {
    ...state,
    uploadedDocuments: [...(state.uploadedDocuments ?? []), ...(payload ?? [])],
  };
};

export const clearUploadedDocuments = (
  state: IDocumentState,
  action: IReduxActionResponse<any>,
): IDocumentState => {
  return {
    ...state,
    uploadedDocuments: [],
  };
};

export const resetDocumentState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...documentInitialState,
  };
};
