const OutOfStockIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="13"
      viewBox="0 0 15 13"
      fill="none"
    >
      <path
        d="M14.689 9.70031L9.39016 1.11115C9.19762 0.794788 8.92164 0.532263 8.5898 0.349792C8.25795 0.16732 7.88185 0.0712891 7.49906 0.0712891C7.11626 0.0712891 6.74016 0.16732 6.40832 0.349792C6.07647 0.532263 5.8005 0.794788 5.60795 1.11115L0.310351 9.70031C0.110154 10.026 0.00314077 10.3963 6.80879e-05 10.7741C-0.0030046 11.1519 0.0979713 11.5238 0.292846 11.8524C0.480229 12.1795 0.756549 12.4522 1.09261 12.6416C1.42867 12.831 1.812 12.9301 2.20208 12.9284H12.7985C13.1886 12.9301 13.5719 12.831 13.908 12.6416C14.2441 12.4522 14.5204 12.1795 14.7078 11.8524C14.9025 11.5236 15.0032 11.1517 14.9999 10.7739C14.9966 10.3962 14.8894 10.0259 14.689 9.70031Z"
        fill="white"
      />
      <path
        d="M2.20085 12.0702C1.96695 12.0729 1.73673 12.0144 1.53508 11.901C1.33344 11.7877 1.16805 11.6237 1.05681 11.427C0.938459 11.229 0.877352 11.0045 0.879769 10.7766C0.882186 10.5486 0.94804 10.3253 1.07056 10.1297L6.36441 1.54656C6.48012 1.35623 6.64604 1.19827 6.84559 1.08848C7.04515 0.978682 7.27135 0.920898 7.50158 0.920898C7.73181 0.920898 7.958 0.978682 8.15756 1.08848C8.35711 1.19827 8.52304 1.35623 8.63874 1.54656L13.9326 10.1321C14.0536 10.3273 14.1183 10.5496 14.1201 10.7765C14.1218 11.0033 14.0606 11.2265 13.9426 11.4234C13.8313 11.6201 13.666 11.7841 13.4643 11.8974C13.2627 12.0108 13.0324 12.0693 12.7985 12.0666H2.20085V12.0702Z"
        fill="#D92323"
      />
      <path
        d="M8.37866 3.54715V6.95579C8.37866 7.17886 8.28598 7.3928 8.12103 7.55054C7.95607 7.70828 7.73234 7.79689 7.49905 7.79689C7.26577 7.79689 7.04204 7.70828 6.87708 7.55054C6.71212 7.3928 6.61945 7.17886 6.61945 6.95579V3.54715C6.61945 3.32408 6.71212 3.11014 6.87708 2.9524C7.04204 2.79467 7.26577 2.70605 7.49905 2.70605C7.73234 2.70605 7.95607 2.79467 8.12103 2.9524C8.28598 3.11014 8.37866 3.32408 8.37866 3.54715Z"
        fill="#E1E4FB"
      />
      <path
        d="M7.49846 8.66124C7.23338 8.65792 6.97327 8.73005 6.75118 8.86845C6.52908 9.00686 6.35504 9.20529 6.25119 9.43852C6.14734 9.67175 6.11835 9.92923 6.16793 10.1782C6.21751 10.4273 6.34341 10.6566 6.52962 10.837C6.71583 11.0174 6.95395 11.1408 7.21369 11.1915C7.47343 11.2422 7.74306 11.2179 7.98831 11.1216C8.23356 11.0254 8.44336 10.8616 8.59101 10.6511C8.73867 10.4405 8.81751 10.1928 8.81753 9.9393C8.81885 9.77251 8.78576 9.60714 8.72016 9.4526C8.65455 9.29806 8.5577 9.15737 8.43518 9.03866C8.31266 8.91995 8.16687 8.82551 8.00613 8.76074C7.84539 8.69597 7.67288 8.66218 7.49846 8.66124Z"
        fill="#E1E4FB"
      />
    </svg>
  );
};

export default OutOfStockIcon;
