import { Avatar, Col, Radio, Row, Select, Space } from "antd";
import AlectifySelect from "components/shared/select";
import { memo, useEffect, useState } from "react";
import AlectifyText from "static/texts.json";
import { IWOCreateTeamSelection } from "../WorkOrderCreateEdit.interface";
import { createAssigneeListForPM } from "components/shared/pm-create-edit-form/PMCreateEditForm.helpers";
import { IUser } from "redux/components/Auth";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import { isExternalUser } from "utils/helpers";
import { ITeams } from "services/teams/teams.interface";
import AvatarGroup from "components/shared/avatar-group";
import { RadioChangeEvent } from "antd/lib";
import { isEmpty } from "lodash";
import { MESSAGES } from "constants/messages";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
const { Option, OptGroup } = Select;

const TeamMemberSelection: React.FC<IWOCreateTeamSelection> = (
  props: IWOCreateTeamSelection,
) => {
  const [selectedRadio, setSelectedRadio] = useState<string>("users");
  const [selectedOptions, setSelectedOptions] = useState<string[]>([]);
  const { user } = useSelector((state: IRootState) => state.auth);

  const handleRadioChange = (e: RadioChangeEvent) => {
    setSelectedOptions([]);
    props.FormInstance.setFieldValue("assignees", undefined);
    props.FormInstance.setFieldValue("approvers", undefined);
    const value = e.target.value as string;
    setSelectedRadio(value);
    props.onSelectMemberType(value);
  };

  const handleSelectChange = (value: string[]) => {
    setSelectedOptions(value);
  };

  useEffect(() => {
    if (props.taskDetails) {
      if (!isEmpty(props.taskDetails.team)) {
        setSelectedOptions([props.taskDetails.team.id]);
        setSelectedRadio("teams");
      } else if (!isEmpty(props.taskDetails.teamMembers)) {
        setSelectedRadio("users");
        setSelectedOptions(
          props.taskDetails.teamMembers?.map((d: any) => d.user.id) || [],
        );
      }
    }
  }, [props.taskDetails]);

  return (
    <>
      <Row justify={"space-between"} align={"middle"}>
        <Col span={12}>
          <span className="alectify-work-order-section-card-heading">
            2. Who will work on this work order?
          </span>
        </Col>
        <Col span={12} className="text-align-right">
          <span className="alectify-work-order-section-card-counter">2/4</span>
        </Col>
      </Row>
      <div className="alectify-work-order-section-card">
        <Row justify={"center"} align={"middle"}>
          <Col span={24}>
            <Row gutter={16}>
              <>
                {/* Team Radio */}
                <Col span={8}>
                  <Radio.Group
                    onChange={handleRadioChange}
                    disabled={props.editing}
                    value={selectedRadio}
                    className=" wo-priority-box"
                  >
                    <Radio value="teams">Pre-defined teams</Radio>
                  </Radio.Group>
                </Col>
                {/* Users Radio */}
                <Col span={4}>
                  <Radio.Group
                    onChange={handleRadioChange}
                    value={selectedRadio}
                    disabled={props.editing}
                    className="wo-priority-box"
                  >
                    <Radio value="users">Users</Radio>
                  </Radio.Group>
                </Col>
              </>
              <Col span={12}>
                <Select
                  mode={selectedRadio === "users" ? "multiple" : undefined}
                  style={{ width: "100%" }}
                  placeholder={`Select ${
                    selectedRadio === "teams" ? "One team" : "multiple Users"
                  }`}
                  // loading={loader}
                  optionFilterProp="children"
                  onChange={handleSelectChange}
                  value={selectedOptions}
                  defaultValue={props.selectedTeamMembers as any}
                  onDeselect={props.onDeselectTeamMember}
                  onSelect={(e) => props.onSelectTeamMember}
                  disabled={props.editing}
                  maxTagCount={2}
                  {...props}
                  className="wo-team-selection-dropdown"
                >
                  {selectedRadio === "teams" ? (
                    <>
                      {props.projectTeams?.map((team: ITeams) => (
                        <Option key={team.id} value={team.id} label={team.name}>
                          <Space
                            direction="horizontal"
                            className="d-flex width-100 justify-content-space-between team-selected"
                          >
                            <span>{team?.name}</span>
                            <AvatarGroup
                              users={team?.team_members}
                              size={"small"}
                              maxCount={4}
                            />
                          </Space>
                        </Option>
                      ))}
                    </>
                  ) : (
                    <OptGroup label="Select Multiple Users">
                      {props.projectTeamMembers?.map((user: IUser) => (
                        <Option
                          key={user.id}
                          value={user.id}
                          label={`${user.first_name} ${user.last_name}`}
                        >
                          <Space direction="horizontal">
                            <Avatar
                              size={"small"}
                              src={user.image || NO_IMAGE_AVATAR}
                            />
                            <span>{user.first_name}</span>
                            <span>
                              {user.last_name}{" "}
                              {isExternalUser(user) ? "(External)" : <></>}
                            </span>
                          </Space>
                        </Option>
                      ))}
                    </OptGroup>
                  )}
                </Select>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row
          justify={"center"}
          align={"middle"}
          gutter={16}
          className="mt-10 alectify-work-order-teammember-selection"
        >
          <Col span={12}>
            <AlectifySelect
              name="assignees"
              className="m-0 alectify-wo-assignees-selection"
              options={createAssigneeListForPM(
                props.selectedTeamMembers as any,
                user.id,
              )}
              label={AlectifyText.ASSIGNEES}
              mode="multiple"
              maxTagCount={3}
              // defaultValue={selectedRadio === "users" ? user.id : undefined}
              onSelect={
                props.editing && !props.copying
                  ? (_, record) => {
                      props.onCreateAssignee(record as any, "assignees");
                    }
                  : () => {}
              }
              onDeselect={
                props.editing && !props.copying
                  ? (e) => {
                      props.onRemoveAssignee(e, "assignees");
                    }
                  : () => {}
              }
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.SELECT.replace(
                    "{fieldName}",
                    AlectifyText.ASSIGNEES,
                  ),
                },
              ]}
            />
          </Col>
          <Col span={12}>
            <AlectifySelect
              name="approvers"
              className="m-0 alectify-wo-assignees-selection"
              options={createAssigneeListForPM(
                props.selectedTeamMembers as any,
                user.id,
              )}
              label={AlectifyText.APPROVERS}
              mode="multiple"
              maxTagCount={3}
              // defaultValue={selectedRadio === "users" ? user.id : undefined}
              onSelect={
                props.editing && !props.copying
                  ? (_, record) => {
                      props.onCreateAssignee(record as any, "approvers");
                    }
                  : () => {}
              }
              onDeselect={
                props.editing && !props.copying
                  ? (e) => {
                      props.onRemoveAssignee(e, "approvers");
                    }
                  : () => {}
              }
              rules={[
                {
                  required: true,
                  message: MESSAGES.FIELD_RULES.SELECT.replace(
                    "{fieldName}",
                    AlectifyText.APPROVERS,
                  ),
                },
              ]}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default memo(TeamMemberSelection);
