export enum TaskPriorityEnum {
  NORMAL = "NORMAL",
  CRITICAL = "CRITICAL",
}

export enum StatusEnum {
  OPEN = "OPEN",
  CLOSED = "CLOSED",
}

export enum TaskStatusEnum {
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  COMPLETED = "COMPLETED",
  SCHEDULED = "SCHEDULED",
  REVIEW_DECLINED = "REVIEW DECLINED",
  WAITING_FOR_REVIEW = "WAITING FOR REVIEW",
  WAITING_FOR_APPROVAL = "WAITING FOR APPROVAL",
}

export enum TaskRequestTypesStatus {
  APPROVED = "APPROVED",
  QUOTATION_REQUEST = "QUOTATION_REQUEST",
  PO_REQUEST = "PO_REQUEST",
  COMPLETED = "COMPLETED",
}

export enum TaskActionRequireEnum {
  ASSIGNEE = "Assignee",
  APPROVER = "Approver",
  CREATOR = "Creator",
}

export enum TaskCategoriesEnum {
  CLEANUP = "CLEANUP",
  CORRECTIVE_MAINTENANCE = "CORRECTIVE_MAINTENANCE",
  DAMAGE = "DAMAGE",
  INSPECTION = "INSPECTION",
  REPLACEMENT = "REPLACEMENT",
  SAFETY = "SAFETY",
  OTHERS = "OTHERS",
}

export enum MaintenanceCategoriesEnum {
  CORRECTIVE_MAINTENANCE = "CORRECTIVE_MAINTENANCE",
  PREVENTIVE_MAINTENANCE = "PREVENTIVE_MAINTENANCE",
}

export const TASK_PRIORITY_COLORS = {
  [TaskPriorityEnum.NORMAL]: "#43BD18",
  [TaskPriorityEnum.CRITICAL]: "#D92323",
};

export const TASK_STATUS_COLORS = {
  [TaskStatusEnum.APPROVED]: "#94d059",
  [TaskStatusEnum.SCHEDULED]: "#3E74DD",
  [TaskStatusEnum.COMPLETED]: "#43BD18",
  [TaskStatusEnum.REVIEW_DECLINED]: "red",
  [TaskStatusEnum.WAITING_FOR_REVIEW]: "#c2c576",
  [TaskStatusEnum.WAITING_FOR_APPROVAL]: "#e5b567",
};

export const TASK_ACTION_REQUIRE_COLORS = {
  [TaskActionRequireEnum.ASSIGNEE]: "#7C9EB7",
  [TaskActionRequireEnum.APPROVER]: "#7EC4CF",
  [TaskActionRequireEnum.CREATOR]: "#957DAD",
};

export const TASK_CATEGORIES_COLORS = {
  [TaskCategoriesEnum.OTHERS]: "#A4A0A0",
  [TaskCategoriesEnum.CLEANUP]: "#32B0DB",
  [TaskCategoriesEnum.REPLACEMENT]: "#FF7E07",
  [TaskCategoriesEnum.CORRECTIVE_MAINTENANCE]: "#FF5449",
  [TaskCategoriesEnum.DAMAGE]: "#FF5449",
  [TaskCategoriesEnum.INSPECTION]: "#FF5449",
  [TaskCategoriesEnum.SAFETY]: "#FF5449",
};

export enum TASK_DOCUMENTS {
  PO = "PO",
  QUOTATION = "Quotation",
  SERVICE_REQUIRED = "Service Required",
  OTHERS = "Others",
}
