import { IAssetStates } from "./AssetDetail.interface";
import { isEmpty } from "lodash";
import AlectifyModal from "../modal";
import AlectifyButton from "../button";
import AlectifySelect from "../select";
import { useDispatch, useSelector } from "react-redux";
import {
  setActiveMasterProject,
  setActiveSubProject,
  setAssetDetailSidebar,
} from "redux/components/common/sources";
import { Col, Drawer, Form, Row } from "antd";
import { useState } from "react";
import AlectifyText from "static/texts.json";
import AssetsTable from "../assets-table";
import { MESSAGES } from "constants/messages";
import { truncateString } from "utils/helpers";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import { IAsset } from "redux/components/assets";
import AssetPackagesTable from "../asset-packages-table";
import SelectMasterProject from "../master-project-select";
import { ISubProject } from "redux/components/sub-project";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import { IAssetPackage } from "redux/components/asset-packages";
import { IMasterProject } from "redux/components/master-project";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import SelectSubProject from "../sub-project-select/SelectSubProject";
import { IRootState } from "redux/rootReducer";
import "./AssetDetailSidebar.scss";

const AssetDetailSidebarListing: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [AssetFormInstance] = Form.useForm();
  const [state, setState] = useState<IAssetStates>({
    selectedMasterProject: null,
    selectSubProject: null,
    assetLevel: "asset",
    selectedAsset: null,
    selectedAssetPackage: null,
  });
  const { assetDetailSidebar } = useSelector(
    ({ common }: IRootState) => common,
  );
  const onSelectMasterProject = (
    value: string,
    masterProject: IMasterProject,
  ) => {
    AssetFormInstance.setFieldValue("masterProject", masterProject.id);
    AssetFormInstance.setFieldValue("subProject", "");
    AssetFormInstance.setFieldValue("assetId", "");
    setState({
      ...state,
      selectedMasterProject: masterProject,
      selectSubProject: null,
    });
  };

  const onSelectSubProject = (value: string, subProject: ISubProject) => {
    AssetFormInstance.setFieldValue("assetId", "");
    setState({
      ...state,
      selectSubProject: subProject,
      selectedAsset: null,
      selectedAssetPackage: null,
    });
  };

  const onMasterProjectChange = () => {
    setState({
      ...state,
      selectSubProject: null,
      selectedAsset: null,
      selectedAssetPackage: null,
    });
  };

  const onAssetLevelChange = (value: string) => {
    setState({
      ...state,
      assetLevel: value,
      selectedAsset: null,
      selectedAssetPackage: null,
    });
  };

  const getAssetDetails = async (_: any, record: any) => {
    try {
      setState({
        ...state,
        selectedAsset: record,
        assetLevel: "asset",
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  const getAssetPackageDetails = async (_: any, record: any) => {
    try {
      setState({
        ...state,
        selectedAssetPackage: record,
        assetLevel: "area",
      });
    } catch (ex) {
      console.log(ex);
    }
  };

  const equipmentModalHandler = () =>
    ModalServiceInstance.open(AlectifyModal, {
      width: 1200,
      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      title:
        state.assetLevel === "area"
          ? AlectifyText.SELECT_ASSET_PACKAGE
          : AlectifyText.SELECT_ASSET,
      children:
        state.assetLevel !== "area" ? (
          <AssetsTable
            selectRowOption
            subProjectId={state.selectSubProject?.id || ""}
            showAction
            onRowSelect={(record: IAsset) => {
              getAssetDetails(null, record);
              ModalServiceInstance.close(AlectifyModal, {
                name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
              });
            }}
            showSearch
          />
        ) : (
          <AssetPackagesTable
            showSearch
            selectRowOption
            subProjectId={state.selectSubProject?.id || ""}
            showAction
            is_individual={false}
            onRowSelect={(record: IAssetPackage) => {
              getAssetPackageDetails(null, record);
              ModalServiceInstance.close(AlectifyModal, {
                name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
              });
            }}
          />
        ),
      footer: null,
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
        }),
    });

  const goToAssetDetail = () => {
    const url = ROUTES.ASSETS_DETAIL.replace(
      ":subProjectId",
      state.selectSubProject?.id || "",
    )
      .replace(":equipmentType", "asset")
      .replace(":equipmentId", state?.selectedAsset?.id || "");
    onCloseHandler();
    dispatch(setActiveMasterProject(state.selectedMasterProject));
    dispatch(setActiveSubProject(state.selectSubProject));

    navigate(url);
  };

  const goToAssetPackageDetail = () => {
    const url = ROUTES.TIMELINE.replace(
      ":subProjectId",
      state.selectSubProject?.id || "",
    )
      .replace(":equipmentType", "asset-package")
      .replace(":equipmentId", state?.selectedAssetPackage?.id || "-");
    onCloseHandler();
    dispatch(setActiveMasterProject(state.selectedMasterProject));
    dispatch(setActiveSubProject(state.selectSubProject));

    navigate(url);
  };

  const onCloseHandler = () => {
    dispatch(setAssetDetailSidebar(false));
  };

  return (
    <Drawer
      open={assetDetailSidebar}
      placement="left"
      rootClassName="alectify-master-project-sidebar-container asset-detail-drawer"
      zIndex={0}
      closable={true}
      headerStyle={{ display: "none" }}
      destroyOnClose={true}
      width={282}
      onClose={onCloseHandler}
      motion={undefined}
    >
      <Row justify="start" gutter={24}>
        <Col span={24}>
          <Form
            name="goto-asset-detail"
            layout="vertical"
            form={AssetFormInstance}
            initialValues={{
              Tracking: "asset",
            }}
          >
            <Col span={24}>
              <SelectMasterProject
                onSelect={onSelectMasterProject}
                onChange={onMasterProjectChange}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>
            <Col span={24}>
              <SelectSubProject
                masterProjectId={
                  (state.selectedMasterProject &&
                    state.selectedMasterProject.id) ||
                  ""
                }
                onSelect={onSelectSubProject}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Col>

            <Col span={24}>
              <Form.Item>
                <AlectifySelect
                  name={AlectifyText.PM_TRACKING}
                  label={`${AlectifyText.PM_TRACKING}`}
                  rules={[
                    {
                      required: true,
                      message: MESSAGES.ASSET_CATEGORY.PLEASE_SELECT_ASSET,
                    },
                  ]}
                  filterOption={(input: string, option) => {
                    return String(option?.label ?? "").includes(input);
                  }}
                  onSelect={onAssetLevelChange}
                  options={[
                    {
                      label: AlectifyText.ASSET_LEVEL,
                      value: "asset",
                    },
                    {
                      label: AlectifyText.PARENT_ASSET_NAME,
                      value: "area",
                    },
                  ]}
                />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Form.Item
                name="assetId"
                rules={[
                  {
                    required: true,
                    message: MESSAGES.ASSET_CATEGORY.PLEASE_SELECT_ASSET,
                  },
                ]}
              >
                <AlectifyButton
                  disabled={!AssetFormInstance.getFieldValue("subProject")}
                  text={
                    state.assetLevel === "asset"
                      ? truncateString(
                          (state.selectSubProject &&
                            state?.selectedAsset?.name) ||
                            AlectifyText.SELECT_ASSET,
                          20,
                        )
                      : truncateString(
                          (state.selectSubProject &&
                            state?.selectedAssetPackage?.name) ||
                            AlectifyText.SELECT_ASSET_PACKAGE,
                          20,
                        )
                  }
                  type="primary"
                  htmlType="button"
                  className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                  onClick={equipmentModalHandler}
                  block
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item>
                <AlectifyButton
                  disabled={
                    state.selectSubProject
                      ? isEmpty(
                          state?.selectedAssetPackage || state?.selectedAsset,
                        )
                      : true
                  }
                  text={
                    state.assetLevel === "asset"
                      ? AlectifyText.GO_TO_ASSET_DETAIL
                      : AlectifyText.GO_TO_ASSET_PACKAGE_DETAIL
                  }
                  type="primary"
                  htmlType="button"
                  className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                  onClick={
                    state.assetLevel === "asset"
                      ? goToAssetDetail
                      : goToAssetPackageDetail
                  }
                  block
                />
              </Form.Item>
            </Col>
          </Form>
        </Col>
      </Row>
    </Drawer>
  );
};

export default AssetDetailSidebarListing;
