import React from "react";

const UserIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.62495 4.5C5.62495 3.60489 5.98053 2.74645 6.61346 2.11351C7.2464 1.48058 8.10484 1.125 8.99995 1.125C9.89506 1.125 10.7535 1.48058 11.3864 2.11351C12.0194 2.74645 12.375 3.60489 12.375 4.5C12.375 5.39511 12.0194 6.25355 11.3864 6.88649C10.7535 7.51942 9.89506 7.875 8.99995 7.875C8.10484 7.875 7.2464 7.51942 6.61346 6.88649C5.98053 6.25355 5.62495 5.39511 5.62495 4.5ZM2.8132 15.0787C2.83849 13.4546 3.50143 11.9055 4.65892 10.7659C5.81641 9.62633 7.37561 8.98759 8.99995 8.98759C10.6243 8.98759 12.1835 9.62633 13.341 10.7659C14.4985 11.9055 15.1614 13.4546 15.1867 15.0787C15.1887 15.1881 15.1587 15.2956 15.1005 15.3882C15.0423 15.4808 14.9583 15.5544 14.8589 15.6C13.0208 16.4428 11.0221 16.8777 8.99995 16.875C6.91045 16.875 4.9252 16.419 3.14095 15.6C3.04156 15.5544 2.95763 15.4808 2.89942 15.3882C2.84121 15.2956 2.81125 15.1881 2.8132 15.0787Z"
        fill="#0954F1"
      />
    </svg>
  );
};

export default UserIcon;
