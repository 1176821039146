const RecurringIcon: React.FC = () => {
  return (
    <div
      style={{
        borderRadius: 100,
        background: "#6FD2C0",
        width: 20,
        height: 20,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="15"
        height="14"
        viewBox="0 0 17 17"
        fill="none"
      >
        <path
          d="M11.3824 15.4069C11.1573 15.4427 10.9218 15.3255 10.8233 15.1021C10.7046 14.8398 10.8236 14.5326 11.0913 14.4126C13.7442 13.2383 15.2733 10.4076 14.8151 7.52755C14.2587 4.0303 10.975 1.63487 7.4959 2.18835C4.54209 2.65827 2.88073 4.70139 2.14035 5.89491L4.14594 5.57584C4.42563 5.53135 4.69466 5.7276 4.73939 6.00875C4.78411 6.2899 4.59718 6.56572 4.31067 6.6113L1.28864 7.09207C1.25454 7.0975 1.19205 7.10041 1.14212 7.09429C1.07745 7.08349 1.01851 7.06474 0.965303 7.03805C0.897362 7.00667 0.840882 6.95941 0.796955 6.90312C0.753028 6.84683 0.713741 6.77574 0.694919 6.70139C0.691647 6.68082 0.689465 6.66711 0.686192 6.64653L0.190912 3.53329C0.146184 3.25214 0.341031 2.98209 0.620722 2.93759C0.900412 2.8931 1.16944 3.08935 1.21417 3.3705L1.4749 5.00941C2.39593 3.63248 4.22947 1.66039 7.33335 1.1666C11.3786 0.523047 15.1925 3.3052 15.8394 7.37162C16.3718 10.718 14.5944 14.01 11.5098 15.3726C11.47 15.3859 11.4233 15.4004 11.3824 15.4069Z"
          fill="white"
        />
        <path
          d="M9.15691 15.9145C9.14332 15.9167 9.12865 15.9121 9.12185 15.9132C8.38028 15.9825 7.63398 15.9344 6.91228 15.7782C6.70332 15.7349 6.53611 15.56 6.50948 15.3487C6.49977 15.2877 6.50366 15.2246 6.51542 15.1671C6.57638 14.8933 6.86173 14.7158 7.1283 14.7707C7.75626 14.9071 8.3963 14.9443 9.02912 14.8923C9.29634 14.8637 9.5437 15.0606 9.58684 15.3318L9.58435 15.36C9.61282 15.6266 9.42197 15.8724 9.15691 15.9145ZM5.18021 15.0528C5.06467 15.0712 4.94945 15.0478 4.84352 14.9952C4.19928 14.625 3.61115 14.1695 3.10846 13.6378C3.03327 13.5594 2.98419 13.4699 2.96801 13.3682C2.94105 13.1987 2.9978 13.0299 3.12543 12.9122C3.32491 12.7206 3.66084 12.7298 3.85167 12.9218C3.85275 12.9286 3.85275 12.9286 3.85275 12.9286C3.86062 12.9343 3.86958 12.9468 3.87745 12.9525C4.31175 13.4047 4.81218 13.7907 5.35512 14.0936C5.48576 14.1701 5.58317 14.3006 5.60798 14.4565C5.62631 14.5718 5.61066 14.6924 5.551 14.7992C5.46846 14.9375 5.33652 15.0279 5.18021 15.0528ZM2.38391 12.1058C2.15962 12.1415 1.93988 12.0305 1.84528 11.83C1.51671 11.1664 1.29257 10.4583 1.1761 9.7262L1.17502 9.71942C1.1376 9.4404 1.32629 9.18101 1.60495 9.13668C1.8836 9.09235 2.15135 9.28608 2.19556 9.56402C2.29694 10.2012 2.49131 10.8098 2.76864 11.3704C2.79086 11.4224 2.80521 11.4688 2.81383 11.523C2.84834 11.74 2.73689 11.9593 2.53019 12.0617C2.48477 12.0828 2.43828 12.0971 2.38391 12.1058Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default RecurringIcon;
