import { Col, Row } from "antd";
import { memo } from "react";
import NotificationsMain from "components/shared/notifications";
import { useLocation } from "react-router-dom";
import { NotificationTypes } from "redux/components/notifications";
// import NotificationsFilters from "components/shared/notifications/NotificationsFilters";

const Notifications: React.FC = () => {
  const location = useLocation();
  return (
    <div>
      <Row gutter={16}>
        {/* <Col span={4}>
          <NotificationsFilters />
        </Col> */}
        <Col span={24}>
          <NotificationsMain
            fullScreenActive
            type={
              location.search.includes(NotificationTypes.GLOBAL)
                ? NotificationTypes.GLOBAL
                : NotificationTypes.SYSTEM
            }
          />
        </Col>
      </Row>
    </div>
  );
};

export default memo(Notifications);
