import React from "react";
import { Card, Typography } from "antd";
import CustomTitleComponent from "../CustomTitleComponent";
import { ICountInColCardProps } from "./lCardProps.interface";
import "../custom-cards.styles.scss";

const CountInColCard: React.FC<ICountInColCardProps> = (
  props: ICountInColCardProps,
) => (
  <Card
    className={props?.className ? props.className : "card-body-col"}
    title={<span className="custom-card-title">{props.title}</span>}
    extra={props.status && <CustomTitleComponent status={props.status} />}
    onClick={props.onClick}
  >
    <div className="flex-direction-column ">
      <Typography.Text className="count">{props.count}</Typography.Text>
    </div>
  </Card>
);

export default CountInColCard;
