const TaskCircleIcon: React.FC = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="#FFE8D6" />
      <path
        d="M20.4004 27.2129H29.0882"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4004 21.9727H29.0882"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20.4004 16.7344H29.0882"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4502 16.7338L14.319 17.6569L16.9253 14.8877"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4502 21.9741L14.319 22.8972L16.9253 20.1279"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.4502 27.2133L14.319 28.1364L16.9253 25.3672"
        stroke="#D85F02"
        strokeWidth="1.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="10"
        y="10"
        width="22"
        height="22"
        rx="3"
        stroke="#D85F02"
        strokeWidth="1.7"
      />
    </svg>
  );
};

export default TaskCircleIcon;
