import React from "react";
import { Tooltip } from "antd";
import { ColumnProps } from "antd/es/table";
import { ColumnGroupType } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import { IListProjectState, IListProjectTable } from "../Admin.interface";
import DeleteIcon from "components/icons/DeleteIcon";

const useListProjectsColumn = (props: IListProjectTable) => {
  return React.useMemo<ColumnProps<any>[] | ColumnGroupType<any>[] | any[]>(
    () =>
      [
        {
          title: AlectifyText.SITE,
          dataIndex: "master_project_name",
          width: 100,
          visible: true,
          searchable: true,
          ellipsis: {
            showTitle: false,
          },
        },
        {
          title: AlectifyText.ASSET_CATEGORY,
          dataIndex: "name",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
        },
        {
          title: AlectifyText.COMPANY_NAME,
          dataIndex: "company_name",
          width: 100,
          visible: true,
          ellipsis: {
            showTitle: false,
          },
        },

        {
          title: AlectifyText.BRANCH,
          align: "center",
          dataIndex: "branch_name",
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          width: 80,
        },
        {
          title: AlectifyText.ACTIONS,
          align: "center",
          dataIndex: "",
          ellipsis: {
            showTitle: false,
          },
          visible: true,
          width: 80,
          render: (_: string, record: IListProjectState) => {
            return (
              <Tooltip title="Delete">
                <div
                  className="cursor-pointer"
                  onClick={() => props.onSoftDelete(record)}
                >
                  <DeleteIcon />
                </div>
              </Tooltip>
            );
          },
        },
      ].filter((column) => column.visible),
    [],
  );
};

export default useListProjectsColumn;
