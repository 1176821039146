import { Dispatch } from "react";
import { SourceActionType } from "redux/interfaces";
import { fetchProjectUsers } from "services/users/users.service";
import { IUsersResponse, IusersParams } from "..";
import { actions } from "..";

export const getProjectUsers: SourceActionType = (
  subProjectId: string,
  params: IusersParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      const response: IUsersResponse = await fetchProjectUsers(
        subProjectId,
        params,
      );
      dispatch(actions.getProjectUsers(response));
    } catch (ex) {
      console.log(ex);
    }
  };
};
