import { memo } from "react";
import AlectifyTable from "../table";
import { IDocumentsItemsProps } from "./DocumentsAttachment.interface";
import useDocumentsPmItemsColumns from "./effects/useDocumentsPmItemsColumns";
import { IPagination } from "../table/AlectifyTable.interface";
import { useDispatch } from "react-redux";
// import { getTaskAttachments } from "redux/components/tasks/sources";
import { getPmInternalAttachments } from "redux/components/pm-internal/sources";
import { IParams } from "redux/interfaces";
import AlectifyButton from "../button";
import { CloudUploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { debounce, isEmpty } from "lodash";
import { UploadChangeParam } from "antd/es/upload";

const DocumentsAttachmentTable: React.FC<IDocumentsItemsProps> = (
  props: IDocumentsItemsProps,
) => {
  const dispatch = useDispatch();
  const documentType = props.documentType;

  // const columnTask = useDocumentsTaskItemsColumns();
  const columnPm = useDocumentsPmItemsColumns({
    deleteDocument: props.deleteDocuments,
  });

  const fetchTaskAttachments = (options?: IPagination) => {
    /*  const params = {
      id: props?.id,
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page,
      search: options?.search,
    }; */
    // dispatch(getTaskAttachments(params));
  };

  const fetchPmAttachments = (params?: IPagination) => {
    if (!isEmpty(params)) {
      params.limit = params.per_page;
    }
    dispatch(getPmInternalAttachments(props?.id, params as IParams));
  };

  const selectedFunction =
    documentType === "TASK" ? fetchTaskAttachments : fetchPmAttachments;

  const displayRowClass = (record: any) => {
    return !record.isActive ? "disable-row" : "";
  };

  const handleUploadChange = debounce((info: UploadChangeParam) => {
    props.uploadDocuments && props.uploadDocuments(info.fileList);
  }, 300); // Adjust debounce time as needed

  return (
    <>
      <AlectifyTable
        showSearch
        customSearchKey="search"
        columns={columnPm}
        dataSource={props?.data}
        total={props?.count ?? 0}
        onDataFetch={selectedFunction}
        loading={props?.loading}
        scroll={{ y: "calc(100vh - 500px)" }}
        displayRowClass={displayRowClass}
        extraButton={
          props.uploadDocuments && (
            <Upload
              onChange={handleUploadChange}
              beforeUpload={() => false}
              showUploadList={false}
              className="width-100"
              multiple
              fileList={[]}
            >
              <AlectifyButton
                text="Upload"
                icon={<CloudUploadOutlined />}
                type="primary"
              />
            </Upload>
          )
        }
      />
    </>
  );
};

export default memo(DocumentsAttachmentTable);
