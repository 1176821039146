import { Component } from "react";
import ModalService from "../ModalService";
import { IModalParams } from "../ModalService.interface";

type ModalRootState = {
  modals: IModalParams[];
};

class ModalRoot extends Component<any, ModalRootState> {
  state: ModalRootState = {
    modals: [],
  };

  componentDidMount() {
    ModalService.on("openModal", (params: IModalParams) => {
      if (params) {
        this.updateModals(params);
      }
    });

    ModalService.on("forceCloseAll", (params: IModalParams) => {
      this.setState({
        modals: [],
      });
    });

    ModalService.on("closeModal", (params: IModalParams) => {
      // @todo: temp fix, need a proper solution for closing the modal
      this.setState({
        modals: this.state.modals.filter(
          (modal: IModalParams) => modal.props.name !== params.props.name,
        ),
      });
    });

    document.addEventListener("updateModalProps", (event: Event) => {
      const customEvent = event as CustomEvent;
      const { name, props } = customEvent.detail;
      this.setState((prevState: any) => ({
        modals: prevState.modals.map((modal: any) =>
          modal.props.name === name ? { ...modal, props } : modal,
        ),
      }));
    });
  }

  modalClose = function (modal: IModalParams) {
    return function () {
      if (modal.props && modal.props.onClose) {
        modal.props.onClose.apply(null, arguments);
      }
      ModalService.close(modal.component);
    };
  };

  updateModals = (params: IModalParams) => {
    const { component, props } = params;
    const name = `${component.name}`;
    this.setState({
      modals: [
        ...this.state.modals,
        {
          name,
          component,
          props,
        },
      ],
    });
  };

  render() {
    const modalsToShow = this.state.modals;
    return (
      <>
        {modalsToShow.length ? (
          <section className={"modal-wrapper"}>
            {modalsToShow.map((modal: IModalParams, idx: number) => {
              const ModalComponent = modal.component;
              return (
                <ModalComponent
                  key={idx.toString()}
                  {...modal.props}
                  open={true}
                  closeModalHandler={this.modalClose(modal)}
                />
              );
            })}
          </section>
        ) : null}
      </>
    );
  }
}

export default ModalRoot;
