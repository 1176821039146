import { memo } from "react";
import { Col, Row, Typography } from "antd";
import { ShareAltOutlined } from "@ant-design/icons";
import AlectifyText from "static/texts.json";
import AlectifyButton from "../button";
import { IDocumentsAttachmentProps } from "./DocumentsAttachment.interface";
import DocumentsAttachmentTable from "./DocumentsAttachmentTable";

const DocumentsAttachment: React.FC<IDocumentsAttachmentProps> = (
  props: IDocumentsAttachmentProps,
) => {
  return (
    <div>
      <Row justify={"center"} align={"middle"}>
        <Col span={12}>
          <Typography.Title level={5} className="text-align-left m-0">
            {AlectifyText.ATTACHMENTS} ({props?.count})
          </Typography.Title>
        </Col>
        <Col span={12} className="text-align-right mb-10 ">
          {props.shareable && (
            <AlectifyButton
              text="Share"
              icon={<ShareAltOutlined />}
              type="primary"
              className="task-share-btn"
            />
          )}
        </Col>
      </Row>
      <div className="mt-10">
        <DocumentsAttachmentTable
          data={props?.items}
          loading={props?.loading}
          documentType={props?.documentType}
          id={props?.id}
          count={props?.count}
          uploadDocuments={props.uploadDocuments}
          deleteDocuments={props.deleteDocument}
        />
      </div>
    </div>
  );
};
export default memo(DocumentsAttachment);
