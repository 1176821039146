import { memo } from "react";

const CreatorApproverIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M0.790038 7.68488H11.9935C12.4303 7.68488 12.7838 7.33116 12.7838 6.89485C12.7838 6.45825 12.4303 6.10453 11.9935 6.10453H11.7784C11.7784 4.00528 10.576 2.18788 8.82401 1.29891L8.37756 3.82968C8.34232 4.03008 8.16842 4.17101 7.9714 4.17101C7.94773 4.17101 7.92349 4.16903 7.89925 4.16509C7.67461 4.12535 7.52495 3.91142 7.56441 3.68678L8.07428 0.795237C7.8829 0.489425 7.54355 0.285645 7.156 0.285645H5.62722C5.24024 0.285645 4.90116 0.489425 4.7095 0.795237L5.21938 3.68678C5.25912 3.91142 5.10917 4.12563 4.88454 4.16509C4.8603 4.16932 4.83606 4.17101 4.81238 4.17101C4.61536 4.17101 4.4409 4.03036 4.40623 3.82968L3.95977 1.29919C2.20692 2.1876 1.00537 4.00528 1.00537 6.10453H0.790038C0.353445 6.10453 0 6.45825 0 6.89485C0 7.33116 0.353445 7.68488 0.790038 7.68488Z"
        fill="white"
      />
      <path
        d="M6.5 8.85718V12.7143"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.42857 10.7856L4.57143 10.7856"
        stroke="white"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(CreatorApproverIcon);
