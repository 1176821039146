import React, { useEffect } from "react";
import AlectifyText from "static/texts.json";
import { Col, Form, Row, Space, Upload, message } from "antd";
import UploadIcon from "components/icons/UploadIcon";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import { useSelector } from "react-redux";
import { MESSAGES } from "constants/messages";
import { IAutoCreateProcedureFormProps } from "./AutoCreateProcedure.interface";
import "./AutoCreateProcedure.style.scss";
import { IRootState } from "redux/rootReducer";
import { uploadAutoCreateFile } from "services/procedures/procedures.service";
import { truncate } from "lodash";

const AutoCreateProcedureForm: React.FC<IAutoCreateProcedureFormProps> = (
  props,
) => {
  const user = useSelector((state: IRootState) => state.auth.user);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter") {
        event.preventDefault();
      }
    };

    document.addEventListener("keydown", handleKeyDown);

    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e?.fileList;
  };

  const handleFileUpload = async (file: any) => {
    try {
      props.form.setFieldValue("description", null);
      props.setIsLoading(true);
      props.getUploadedFile(file);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("userId", user?.id);
      const response = await uploadAutoCreateFile(formData);
      if (response.success) {
        message.success(
          MESSAGES.GENERAL_MESSAGES.UPLOADED_SUCCESSFULLY_MESSAGE,
        );
        props.fileRef.current = file;
        props.form.setFieldsValue({ attachFile: [file] });
      } else {
        message.error(response?.error);
      }
      props.setIsLoading(false);
    } catch (error: any) {
      props.setIsLoading(false);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      console.error(error);
    }
  };

  const handleQuestionSelect = (question: string) => {
    props.form.setFieldsValue({ question });
    props.form.submit();
  };
  const showUploadedFileName = () => {
    if (props.fileRef.current) {
      return (
        <div className="p-10">
          {truncate(props.fileRef.current.name, {
            length: 30,
            omission: "...",
          })}
        </div>
      );
    } else {
      return (
        <>
          <UploadIcon />
          <p className="support-text-format ml-5">
            ({AlectifyText.SUPPORT_PDF})
          </p>
        </>
      );
    }
  };

  useEffect(() => {
    showUploadedFileName();
  }, [props.form, props.fileRef.current]);

  return (
    <div className="ai-procedure-form-container">
      <Form
        form={props.form}
        onFinish={(values) => {
          props.onSubmit(values);
        }}
        layout="vertical"
        disabled={props.state?.aiProcedureItems?.fetching}
      >
        <Row justify={"start"} align={"top"}>
          <Col span={24}>
            <Form.Item
              name="attachFile"
              valuePropName="fileList"
              getValueFromEvent={normFile}
              label={
                <div className="form-label">
                  <div className="blue-round">1</div>
                  <span>{AlectifyText.ATTACH_FILE}</span>
                </div>
              }
              rules={BASIC_REQUIRED_VALIDATION_RULES}
            >
              <Upload.Dragger
                name="files"
                accept=".pdf"
                className="alectify-procedure-bot-upload-dnd"
                beforeUpload={() => false}
                showUploadList={false}
                fileList={[]}
                maxCount={1}
                multiple={false}
                onChange={(info) => {
                  const { fileList } = info;
                  handleFileUpload(fileList[0].originFileObj);
                }}
              >
                <div className="upload-center-title">
                  {showUploadedFileName()}
                </div>
              </Upload.Dragger>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="description">
              <AlectifyInput
                extra={
                  <span className="input-eg-msg">
                    {MESSAGES.AI_FORM_PROCEDURE.EQUIPMENT_TEXT}
                  </span>
                }
                className="procedure-input"
                name="description"
                label={
                  <div className="form-label">
                    <div className="blue-round">2</div>
                    <span>{MESSAGES.AI_FORM_PROCEDURE.DESCRIPTION}</span>
                  </div>
                }
                placeholder="Enter here"
                type="text"
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="question"
              label={
                <div className="form-label">
                  <div className="blue-round">3</div>
                  <span>{MESSAGES.AI_FORM_PROCEDURE.QUESTIONS}</span>
                </div>
              }
            >
              <Space direction="vertical" className="width-100">
                <AlectifyButton
                  text={MESSAGES.AI_FORM_PROCEDURE.Q1}
                  type="default"
                  onClick={() =>
                    handleQuestionSelect(MESSAGES.AI_FORM_PROCEDURE.Q1)
                  }
                  htmlType="button"
                  className="question-btn"
                />

                <AlectifyButton
                  text={MESSAGES.AI_FORM_PROCEDURE.Q2}
                  type="default"
                  className="question-btn"
                  htmlType="button"
                  onClick={() =>
                    handleQuestionSelect(MESSAGES.AI_FORM_PROCEDURE.Q2)
                  }
                />

                <AlectifyButton
                  text={MESSAGES.AI_FORM_PROCEDURE.Q3}
                  type="default"
                  className="question-btn"
                  htmlType="button"
                  onClick={() =>
                    handleQuestionSelect(MESSAGES.AI_FORM_PROCEDURE.Q3)
                  }
                />

                <AlectifyButton
                  text={MESSAGES.AI_FORM_PROCEDURE.Q4}
                  type="default"
                  className="question-btn"
                  htmlType="button"
                  onClick={() =>
                    handleQuestionSelect(MESSAGES.AI_FORM_PROCEDURE.Q4)
                  }
                />
              </Space>
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <div className="ai-procedure-clear-all">
        <AlectifyButton
          danger
          type="text"
          disabled={props.state?.aiProcedureItems?.fetching}
          onClick={props.onClearAll}
          text={AlectifyText.CLEAR_ALL}
          className="danger-button ai-clear-all"
        />
      </div>
    </div>
  );
};

export default AutoCreateProcedureForm;
