import { IContactsResponse } from "./contacts.interface";
/**
 * Converts an Express-style response object to a normal response object.
 * @param {any} response - The Express-style response object to be transformed.
 * @returns {IContactsResponse} A normal response object with the desired structure.
 */
export const buildExpressToNormalResponse = (
  response: any,
): IContactsResponse => {
  // Extract the relevant data from the Express-style response
  const data = response?.data;

  const normalResponse: IContactsResponse = {
    data,
    meta: {
      next: "",
      previous: "",
      total_pages: Number(response.meta.total_pages),
      total_count: Number(response.meta.total_count),
    },
    message: response.message,
  };

  return normalResponse;
};
