import { Col, Row, Space } from "antd";
import { IStatusCardProps } from "./StatusCard.interface";
import AvatarGroup from "../avatar-group";
import TextToLink from "../text-to-link";
import "./StatusCard.scss";

const StatusCard: React.FC<IStatusCardProps> = (props: IStatusCardProps) => {
  return (
    <div className="alectify-status-card">
      <Row justify={"space-between"} align={"middle"}>
        <Col span={18} className="text-align-left">
          <Space>
            <span className="status-card-text">
              <Space>
                {props.prefix && (
                  <span className="status-card-icon">{props.prefix}</span>
                )}
                {props.onClick ? (
                  <TextToLink text={props.text} onClick={props.onClick} />
                ) : (
                  props.text
                )}
              </Space>
            </span>
            {props.helperText && (
              <span className="status-card-chip">{props.helperText}</span>
            )}
          </Space>
        </Col>
        <Col span={6} className="text-align-right">
          {props.count ? (
            <span className="status-card-count">{props.count}</span>
          ) : (
            <AvatarGroup users={props.users || []} showImage maxCount={1} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default StatusCard;
