import { Space, Tag } from "antd";
import { WaitingIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const WaitingForReviewTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#FFB310"} className="round-tag">
      <Space size={3}>
        <WaitingIcon /> {text || AlectifyText.WAITING_FOR_REVIEW}
      </Space>
    </Tag>
  );
};

export default WaitingForReviewTag;
