import { MULTIPART_HEADERS, http, pmHttp } from "utils/client";
import {
  CREATE_NEW_COMMENT,
  FETCH_COMMENTS,
  UPLOAD_COMMENT_ATTACHMENT,
} from "./comment.endpoints";

import { AxiosResponse } from "axios";
import {
  ICommentsBody,
  UploadCommentAttachmentResponse,
} from "./comments.interface";
import { ICommentItem } from "components/shared/comments/Comments.interface";

/**
 * Creates a new comment for a subproject task.
 *
 * @param {string} subProjectId - ID of the subProject.
 * @param {string} referenceId - ID of the task.
 * @param {ICommentsBody} body - The comment data to be created.
 * @returns A Promise that resolves to the response containing the newly created comment.
 * @throws If an error occurs during the request.
 */
export const createNewComment = async (
  subProjectId: string,
  referenceId: string,
  body: ICommentsBody,
): Promise<{ data: ICommentItem }> => {
  try {
    const url = CREATE_NEW_COMMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{referenceId}", referenceId);
    const response = await pmHttp.post(url, body);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Fetches comments for a subProject task.
 *
 * @param {string} subProjectId - ID of the subProject.
 * @param {string} referenceId - ID of the task.
 * @returns A Promise that resolves to the comments associated with the task.
 * @throws If an error occurs during the request.
 */
export const fetchComments = async (
  subProjectId: string,
  referenceId: string,
  params: any,
) => {
  try {
    const url = FETCH_COMMENTS.replace("{subProjectId}", subProjectId).replace(
      "{referenceId}",
      referenceId,
    );
    const response = await http.get(url, params);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Uploads an attachment for a comment in a subProject task.
 *
 * @param {string} subProjectId - ID of the subProject.
 * @param {string} taskId - ID of the task.
 * @param {FormData} body - The attachment data to be uploaded.
 * @returns A Promise that resolves to the response containing the uploaded attachment details.
 * @throws If an error occurs during the request.
 */
export const uploadCommentAttachment = async (
  subProjectId: string,
  taskId: string,
  body: FormData,
): Promise<UploadCommentAttachmentResponse> => {
  try {
    const url = UPLOAD_COMMENT_ATTACHMENT.replace(
      "{subProjectId}",
      subProjectId,
    ).replace("{taskId}", taskId);

    const response: AxiosResponse<UploadCommentAttachmentResponse> =
      await pmHttp.post(url, body, MULTIPART_HEADERS);
    return response.data;
  } catch (error) {
    throw error;
  }
};
