import { get, isEmpty } from "lodash";
import type { RcFile } from "antd/es/upload";
import DayJs from "dayjs";

import { KEYS_TO_EXCLUDE } from "components/shared/profile/profile.constants";
import { extractPhoneNumber } from "components/shared/phone-number/PhoneNumber.helper";
import { USER_TYPES } from "components/shared/profile/profile.constants";
// import { getUserImage } from "utils/helpers";
import { SERVER_DATE_FORMAT } from "constants/index";
import { IUser } from "redux/components/Auth";

export const prepareFormData = (
  values: any,
  fileList: any,
  existingUser?: IUser | null,
) => {
  const formData = new FormData();
  if (!isEmpty(existingUser)) {
    formData.append("id", existingUser.id);
    return formData;
  }
  const contactNumber = `${values.contact_number_prefix}${values.contact_number}`;
  const businessContactNumber = `${values.business_phone_number_prefix}${values.business_phone_number}`;
  if (values.contact_number) {
    formData.append("contact_number", contactNumber);
  }
  if (values.access_expiry_date) {
    formData.append(
      "access_expiry_date",
      DayJs(values.access_expiry_date).format(SERVER_DATE_FORMAT),
    );
  }

  if (values.business_phone_number) {
    formData.append("business_phone_number", businessContactNumber);
  }
  if (!isEmpty(fileList)) {
    formData.append("image_url", fileList[0]?.originFileObj as Blob);
  } else if (values.image) {
    formData.append("image_url", values.image);
  }
  Object.entries(values).forEach(([key, value]) => {
    if (value && !KEYS_TO_EXCLUDE.includes(key)) {
      formData.append(key, value as Blob);
    }
  });

  return formData;
};

export function generateFormInitialValues(user: any | null) {
  if (!isEmpty(user)) {
    return {
      first_name: get(user, "first_name", ""),
      last_name: get(user, "last_name", ""),
      email: get(user, "email", ""),
      organization_name: get(user, "organization.name", ""),
      personal_contact_type: get(USER_TYPES, user.user_type, ""),
      access_expiry_date:
        get(user, "access_expiry_date") !== null
          ? DayJs(get(user, "access_expiry_date"))
          : null,
      contact_number: get(user, "contact_number", "")
        ? extractPhoneNumber(get(user, "contact_number")).number
        : "",
      contact_number_prefix: get(user, "contact_number")
        ? extractPhoneNumber(get(user, "contact_number")).code
        : "",
      business_contact_type: get(USER_TYPES, user.user_type, ""),
      business_phone_number: get(user, "business_phone_number")
        ? extractPhoneNumber(get(user, "business_phone_number")).number
        : "",
      business_phone_number_prefix: get(user, "business_phone_number")
        ? extractPhoneNumber(get(user, "business_phone_number")).code
        : "",
      address: get(user, "address", ""),
      business_address: get(user, "business_address", ""),
    };
  } else {
    return {};
  }
}

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
