import { Col, Row, Spin, Typography } from "antd";
import { IDataRoundInsightsProps } from "../../DataRoundProjectDetails.interface";
import { CountInColCard } from "components/shared/custom-cards/count-card";
import AlectifyText from "static/texts.json";
import { useEffect, useState } from "react";
import {
  getDRProjectDetails,
  getDataRoundProjectInsights,
} from "services/data-round/data-round.service";
import {
  IDataRoundInsights,
  IDataRoundProject,
} from "services/data-round/data-round.interface";
import { useParams } from "react-router-dom";
import dayjs from "dayjs";
import DataRoundShiftTimeline from "./DataRoundShiftTimeline/DataRoundShiftTimeline";

const DataRoundInsights: React.FC<IDataRoundInsightsProps> = (
  props: IDataRoundInsightsProps,
) => {
  const [insights, setInsights] = useState<IDataRoundInsights[] | null>(null);
  const [loader, setLoader] = useState<boolean>(false);
  const [project, setProject] = useState<IDataRoundProject | null>(null);
  const { projectId } = useParams();

  const getInsights = async () => {
    setLoader(true);

    const today = dayjs();
    const start_date = today.subtract(6, "day").format("YYYY-MM-DD"); // 6 days before today

    // Calculate the end date (7 days before today)
    const end_date = today.format("YYYY-MM-DD"); // Today's date

    try {
      const response = await getDataRoundProjectInsights(props.projectId, {
        start_date: start_date,
        end_date: end_date,
      });
      if (response.success) {
        setInsights(response.data);
      }
      setLoader(false);
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  const getProjectDetails = async () => {
    setLoader(true);
    try {
      const response = await getDRProjectDetails(
        props.projectId || projectId || "",
      );
      setLoader(false);
      if (response.success) {
        setProject(response.data);
      }
    } catch (ex) {
      setLoader(false);
      console.log(ex);
    }
  };

  useEffect(() => {
    if (props.projectId) {
      Promise.all([getProjectDetails(), getInsights()]);
    }
  }, [props.projectId]);

  return (
    <Spin spinning={loader}>
      <Row
        justify={"start"}
        align={"middle"}
        className="data-round-timeline-container"
      >
        <Col span={24}>
          <Typography.Title level={4} className="mb-5 mt-5">
            Assets & Lifecycle Summary
          </Typography.Title>
          <Row
            gutter={16}
            className="mt-20 mb-20"
            justify={"center"}
            align={"middle"}
          >
            <Col span={24}>
              <div className="cards-body">
                <Row gutter={[16, 16]} className="m-10">
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.CHECKPOINTS}
                      count={project?.total_checkpoints_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.GROUPS}
                      count={project?.total_areas_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.COMMENTS}
                      count={project?.total_comments_count || 0}
                    />
                  </Col>
                  <Col span={5}>
                    <CountInColCard
                      title={AlectifyText.DOCUMENTS}
                      count={project?.total_documents_count || 0}
                    />
                  </Col>
                  <Col span={4}>
                    <CountInColCard
                      title={AlectifyText.ISSUES_REPORTED}
                      count={project?.total_issues_count || 0}
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Typography.Title level={4} className="mb-5 mt-5">
            Last Week's Summary
          </Typography.Title>
        </Col>
        <Col span={24}>
          <DataRoundShiftTimeline
            insights={insights}
            project={project}
            loader={loader}
          />
        </Col>
      </Row>
    </Spin>
  );
};

export default DataRoundInsights;
