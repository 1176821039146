import { Form, message, Spin } from "antd";
import { MESSAGES } from "constants/messages";
import { memo, useEffect, useState } from "react";
import { DocumentTypes } from "redux/components/documents";
import {
  updateAssetDocument,
  updateAssetPackageDocument,
  updateProjectDocument,
} from "services/documents/documents.service";
import { updateAndRestorePmDocuments } from "services/pm-internal/pm-internal.service";
import { getFileNameAndExtention } from "utils/helpers";
import AlectifyInput from "../input";
import {
  DocumentEditTypes,
  IEditDocumentProps,
} from "./EditDocument.interface";

const EditDocument = (props: IEditDocumentProps) => {
  const [submitting, setSubmitting] = useState(false);
  const { fileName, extension } = getFileNameAndExtention(props.fileName);

  useEffect(() => {
    props.form.setFieldsValue({
      fileName,
      comment: props.comment,
    });
  }, []);

  const onSubmit = async (values: {
    fileName: string;
    comment: string;
    version_number: string;
  }) => {
    try {
      setSubmitting(true);

      // If edit type is FILENAME, use comment from props
      if (props.editType === DocumentEditTypes.FILENAME) {
        values.comment = props.comment;
      }

      // If edit type is not VERSION, use filename from props and append the extension
      if (props.editType !== DocumentEditTypes.VERSION) {
        values.fileName = `${values.fileName || fileName}.${extension}`;
      }

      // If the edit type is VERSION, use version from values
      if (props.editType === DocumentEditTypes.VERSION) {
        values.version_number = values.version_number || ""; // Assume version is from user input in values
      }

      // Handle different document types and call the appropriate update function
      if (
        [
          DocumentTypes.PM_EXTERNAL,
          DocumentTypes.PM_INTERNAL,
          DocumentTypes.TASK,
        ].includes(props.type)
      ) {
        await updateAndRestorePmDocuments(props.documentId, values);
      } else if (
        props.type === DocumentTypes.ASSET ||
        (props.type as any) === "TagDocument"
      ) {
        await updateAssetDocument(props.subProjectId, props.documentId, values);
      } else if (
        props.type === DocumentTypes.ASSET_PACKAGE ||
        (props.type as any) === "PackageRoomDocument"
      ) {
        await updateAssetPackageDocument(
          props.subProjectId,
          props.documentId,
          values,
        );
      } else if (props.type === DocumentTypes.PROJECT_DOCUMENT) {
        await updateProjectDocument(
          props.subProjectId,
          props.documentId,
          values,
        );
      }

      // Success callback and message
      props.successCb();
      setSubmitting(false);
      message.success("Updated Successfully");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <Spin spinning={submitting}>
      <Form form={props.form} layout="vertical" onFinish={onSubmit}>
        {/* File Name Edit Type */}
        {props.editType === DocumentEditTypes.FILENAME && (
          <AlectifyInput
            name="fileName"
            type="text"
            label="File Name"
            rules={[
              {
                required: true,
                message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                  "{fieldName}",
                  "File Name",
                ),
              },
            ]}
            addonAfter={<strong>.{extension}</strong>}
          />
        )}

        {/* Comment Edit Type */}
        {props.editType === DocumentEditTypes.COMMENT && (
          <AlectifyInput
            name="comment"
            type="textarea"
            label="Comment"
            rules={[
              {
                required: true,
                message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                  "{fieldName}",
                  "Comment",
                ),
              },
            ]}
          />
        )}

        {/* Version Edit Type */}
        {props.editType === DocumentEditTypes.VERSION && (
          <AlectifyInput
            name="version_number"
            type="text"
            placeholder="Enter Version number"
            label="Version"
            defaultValue={props.version || ""}
            rules={[
              {
                required: true,
                message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                  "{fieldName}",
                  "Version",
                ),
              },
              {
                pattern: /^\d+(\.\d+)*$/,
                message: "Please enter a valid version number (e.g., 1.0.0)",
              },
            ]}
          />
        )}
      </Form>
    </Spin>
  );
};

export default memo(EditDocument);
