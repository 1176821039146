import { Link, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { Card, Col, Form, message, Row, Typography } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "lodash";
//path imports
import { ILoginPayload } from "services/auth/auth.interface";
import { login } from "redux/components/Auth/sources";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import EmailIcon from "assets/images/alectify-email-icon.svg";
import PasswordIcon from "assets/images/alectify-key-icon.svg";

import { ILoginProps } from "./login.interface";
import "./login.scss";
import SVGToComponent from "components/shared/icons/icons";
import { ROUTES } from "routes/Routes.constants";
import { IRootState } from "redux/rootReducer";
import { UserTypeEnum } from "enums";
import { currentYear } from "utils/helpers";
import { getFromLocal, removeFromLocal, saveToLocal } from "utils/cache";
import { SECURITY_BANNER_FLAG } from "utils/constants";

const { Text, Title } = Typography;

const Login: React.FC<ILoginProps> = () => {
  const auth = useSelector((state: IRootState) => state.auth);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onSubmit = async (payload: ILoginPayload) => {
    try {
      saveToLocal(true, SECURITY_BANNER_FLAG, false, false);
      dispatch(login(payload));
    } catch (ex: any) {
      const errorMessage = ex?.response?.data?.message
        ? "Incorrect email or password"
        : "Something went wrong";
      message.error(errorMessage);
    }
  };

  useEffect(() => {
    if (!isEmpty(auth.user)) {
      const redirectionLink = getFromLocal("redirectTo", true, false);
      if (!isEmpty(redirectionLink?.pathname)) {
        removeFromLocal("redirectTo");
        navigate(redirectionLink?.pathname);
      } else {
        const route =
          auth.user.user_type === UserTypeEnum.EXTERNAL
            ? ROUTES.MY_ITEMS
            : auth.user?.is_superuser
            ? ROUTES.ADMIN
            : ROUTES.PROJECT;
        navigate(route);
      }
    }
  }, [auth.user, navigate]);

  return (
    <Row
      className="auth-container height-100vh"
      align="middle"
      justify="center"
    >
      <Col xs={24} sm={20} md={18} lg={12} xl={10} xxl={10}>
        <Card className="auth-form-container">
          <div className="auth-form-header">
            <div className="auth-form-logo-container" />
            <Title className="auth-form-title">
              Welcome to <span className="company-name">Alectify!</span>
            </Title>
            <p>Login to your account</p>
          </div>
          <Form
            name="basic"
            layout="vertical"
            onFinish={onSubmit}
            onFinishFailed={() => {}}
            validateTrigger="onSubmit"
          >
            <AlectifyInput
              label="Email Address"
              name="email"
              type="email"
              prefix={<SVGToComponent icon={EmailIcon} />}
              rules={[
                {
                  type: "email",
                  message: "The input is not valid E-mail!",
                },
                {
                  required: true,
                  message: "Please enter your email",
                },
              ]}
              placeholder="Enter your email address here"
            />

            <AlectifyInput
              label="Password"
              name="password"
              type="password"
              prefix={<SVGToComponent icon={PasswordIcon} />}
              rules={[
                {
                  required: true,
                  message: "Please enter valid password",
                },
                {
                  min: 6,
                  message: "Password must contain atleast 6 characters.",
                },
              ]}
              placeholder="Enter your password here"
            />

            <Form.Item noStyle>
              <Link style={{ fontSize: 15 }} to="/forgot-password">
                Forgot password?
              </Link>
            </Form.Item>

            <Form.Item className="mt-50">
              <AlectifyButton
                loading={auth.fetching}
                type="primary"
                className="alectify-btn-block"
                text="Login"
                htmlType="submit"
              />
            </Form.Item>
          </Form>
          <div className="mt-50">
            <p style={{ fontSize: 15 }}>
              Don't have an account?{" "}
              <Link
                className="btn btn-outline-primary"
                to="/login"
                onClick={(ev) => {
                  ev.stopPropagation();
                  window.open("https://alectify.ai/");
                }}
              >
                Signup
              </Link>
            </p>
          </div>
        </Card>
        <Text className="auth-form-copyright-text">
          © Copyrights Alectify {currentYear}. All Rights Reserved.
        </Text>
      </Col>
    </Row>
  );
};

export default Login;
