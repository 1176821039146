import { Space, Tag } from "antd";
import AlectifyText from "static/texts.json";

const JobTypeTag: React.FC = () => {
  return (
    <Tag color={"#26C596"} className="round-tag">
      <Space size={2}>{AlectifyText.JOB_PLAN}</Space>
    </Tag>
  );
};

export default JobTypeTag;
