interface IDeadlineIconProps {
  fill?: string | null;
}
const DeadlinesIcon: React.FC<IDeadlineIconProps> = (
  props: IDeadlineIconProps,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.25293 15.084H16.4079"
        stroke={props.fill || "#141B34"}
        strokeWidth={"1.2"}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25293 10.7971H16.4079"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.25293 6.5105H16.4079"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.52905 6.51049L4.24455 7.26573L6.39103 5"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.52905 10.7971L4.24455 11.5524L6.39103 9.28662"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.52905 15.084L4.24455 15.8392L6.39103 13.5735"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="0.687012"
        y="1"
        width="18.1184"
        height="18"
        rx="4"
        stroke={props.fill || "#141B34"}
        strokeWidth="1.2"
      />
    </svg>
  );
};

export default DeadlinesIcon;
