import React from "react";
import { Avatar, Badge, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { contactsTableConstants } from "../ContactsTable.constants";
import { getFullUserName, getUserImage, isExternalUser } from "utils/helpers";
import EditIcon from "components/icons/EditIcon";
import { USER_ACCESS_TYPES, UserTypeEnum } from "enums";
import AdminTag from "components/shared/tags/AdminTag";
import InternalTag from "components/shared/tags/InternalTag";
import ExternalTag from "components/shared/tags/ExternalTag";
import { ArrowRightOutlined } from "@ant-design/icons";

const ContactsColumn = (props: any) => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: contactsTableConstants.NAME,
      dataIndex: "first_name",
      key: "first_name",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      searchable: true,
      render: (_, user) => (
        <div>
          <Tooltip
            placement="topLeft"
            title={
              user &&
              `${getFullUserName(user)} (${
                user?.user_type && USER_ACCESS_TYPES[user?.user_type]
              })`
            }
          >
            <Badge
              count={
                isExternalUser(user) ? (
                  <ArrowRightOutlined className="external-user-arrow-icon" />
                ) : (
                  0
                )
              }
              offset={[-8, 28]}
            >
              <Avatar
                src={getUserImage(user)}
                className={
                  isExternalUser(user)
                    ? "alectify-avatar-external-user mr-5"
                    : "mr-5"
                }
              />
            </Badge>
            {user && getFullUserName(user)}
          </Tooltip>
        </div>
      ),
    },
    {
      title: contactsTableConstants.EMAIL,
      dataIndex: "email",
      key: "email",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (email) => (
        <Tooltip placement="topLeft" title={email}>
          {email}
        </Tooltip>
      ),
    },
    {
      title: contactsTableConstants.CONTACT_NUMBER,
      dataIndex: "contact_number",
      key: "contact_number",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (contact_number) => (
        <Tooltip placement="topLeft" title={contact_number}>
          {contact_number}
        </Tooltip>
      ),
    },

    {
      title: contactsTableConstants.ORGANIZATION,
      key: "organization",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (user) => (
        <Tooltip
          placement="topLeft"
          title={user && user.organization && user.organization.name}
        >
          {user && user.organization && user.organization.name}
        </Tooltip>
      ),
    },
    {
      title: contactsTableConstants.USERTYPE,
      key: "user_type",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (user) => (
        <Tooltip
          placement="topLeft"
          title={user && user.user_type && UserTypeEnum[user?.user_type]}
        >
          {user?.user_type &&
            (user.user_type === UserTypeEnum?.ADMIN ? (
              <AdminTag />
            ) : user.user_type === UserTypeEnum?.INTERNAL ? (
              <InternalTag />
            ) : (
              <ExternalTag />
            ))}
        </Tooltip>
      ),
    },
    {
      title: contactsTableConstants.ACCESS_EXPRIE_DATE,
      key: "access_expiry_date",
      width: 150,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (user) => (
        <Tooltip
          placement="topLeft"
          title={user.access_expiry_date && user.access_expiry_date}
        >
          {user.access_expiry_date || "-"}
        </Tooltip>
      ),
    },

    {
      title: contactsTableConstants.ACTIONS,
      align: "center",
      // dataIndex: "",
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      width: 80,
      render: (_, user) => (
        <div
          className="cursor-pointer"
          onClick={() => props.openEditDrawer(null, true, user)}
        >
          <EditIcon />
        </div>
      ),
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};

export default ContactsColumn;
