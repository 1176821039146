import { IUser } from "redux/components/Auth";
import { IMeta } from "redux/interfaces";

export interface IDataRoundProjectResponse {
  success: boolean;
  message: string | null;
  data: IDataRoundProject[];
  meta: IMeta;
}

export interface IDataRoundProject {
  id: string;
  name: string;
  is_draft: boolean;
  shifts_per_day?: number;
  start_time_first_shift?: any;
  shift_schedules: IShiftSchedule[];
  created_at: string;
  is_active: boolean;
  master_project: string;
  master_project_name: string;
  total_areas_count: number;
  total_checkpoints_count: number;
  total_comments_count: number;
  total_documents_count: number;
  total_issues_count: number;
  updated_at: string;
  team?: string;
}

export interface ICreateDRProjectPayload {
  name?: string;
  master_project?: string;
  shifts_per_day?: number;
  shift_schedules?: IShiftSchedule[];
  team?: string;
  start_time_first_shift?: string;
  is_draft?: boolean;
}

export interface IShiftSchedule {
  id?: string;
  name: string;
  frequency_type?: number;
  start_time: string;
  end_time: string;
}

export interface ICreateDrProjectResponse {
  success: boolean;
  message: string;
  data: IDataRoundProject;
}

export interface IDrProjectDetailsResponse {
  success: boolean;
  message: string;
  data: IDataRoundProject;
}

export interface ICreateNewGroupPayload {
  name: string;
  item_no: number;
  description: string;
}

export interface IDrGroup {
  name: string;
  item_no: number;
  description: string;
  area_id?: string;
  area_name?: string;
}

export interface IDrCheckpoint {
  name: string;
  item_no: number;
  description: string;
  is_reading_required: boolean;
  on_off_feature: boolean;
  area: string;
  tag_id: string;
  package_id: string | null;
}

export interface IGroupAndCheckpointListingResponse {
  success: boolean;
  message: string;
  data: IGroupCheckpoint[];
  meta: IMeta;
}

export interface IGroupCheckpoint {
  checkpoint_id: string;
  type: string;
  item_no: number;
  checkpoint_name: string;
  description: string;
  area_name: string;
  dr_project_name: string;
  mainproject_name: string;
  area_id: string;
  dr_project_id: string;
  mainproject_id: string;
  is_active: boolean;
  is_reading_required: boolean;
  on_off_feature: boolean;
  tag_id: string;
  package_id: string;
  total_checkpoints_count: number;
  total_comment_count: number;
  total_document_count: number;
  total_issues_count: number;
}

export interface IDrProjectGroupsResponse {
  success: boolean;
  message: string;
  data: IDrGroup[];
  meta: IMeta;
}

export interface ICreateCheckPointPayload {
  name: string;
  item_no: number;
  description: string;
  is_reading_required: boolean;
  on_off_feature: boolean;
  area: string;
  tag_id?: string;
  package_id?: string | null;
}

export interface ICreateCheckpointResponse {
  success: boolean;
  message: string;
  data: any[];
}

export interface IDataRoundInsightsResponse {
  success: boolean;
  message: string;
  data: IDataRoundInsights[];
}

export interface IDataRoundInsights {
  id: string;
  shift: IDataRoundShift;
  status: RoundStatusEnum;
  shift_date: string;
  issues_count: number;
  start_time: string;
  end_time: string;
  user: IUser;
}

export interface IDataRoundShift {
  id: string;
  name: string;
  frequency_type: number;
  start_time: string;
  end_time: string;
}

export interface IRound {
  id: string;
  status: RoundStatusEnum;
  reading_key: string;
  docs_count: number;
  comments_count: number;
  checkpoint: {
    name: string;
    description: string;
    is_reading_required: boolean;
  };
  latest_comment: {
    id: string;
    text: string;
    created_at: string;
  };
  area: string;
  checkpoint_active_status: boolean;
  known_issue: boolean;
  critical_issue: boolean;
}

export interface IRoundDetailResponse {
  success: boolean;
  message: string;
  data: IRound[];
}

export interface IDataRoundAnalyticsResponse {
  success: boolean;
  message: string | null;
  data: IDataRoundAnalytics[];
}

export interface IDataRoundAnalytics {
  reading_key: string;
  shift_date: string;
  shift: string;
  checkpoint_name: string;
}

export interface ICheckpoint {
  checkpoint_id: string;
  checkpoint_name: string;
  item_no: string;
  description: string;
  is_reading_required: boolean;
  on_off_feature: boolean;
  area_id: string;
  area_name: string;
  tag_id: string | null;
  package_id: string | null;
}

export interface ICheckpointResponse {
  success: boolean;
  message: string;
  data: ICheckpoint[];
}
/******** Enums *******/
export enum RoundStatusEnum {
  NOT_STARTED = 0,
  ON_GOING = 1,
  ENDED = 2,
  PAUSED = 3,
}
