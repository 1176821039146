import { ICommonState } from "./common.interface";

export const commonInitialState: ICommonState = {
  activeSubProject: null,
  activeCalendarType: 1,
  activeMasterProject: null,
  previousActiveMasterProject: null,
  masterProjectSidebar: false,
  assetDetailSidebar: false,
  showMyWorkOrders: true,
  showTeamInventory: true,
  showCalendarMyWorkOrders: true,
  uploadDocuments: [],
  searchedText: null,
  comments: {
    data: [],
    meta: null,
    fetching: false,
  },
};
