import { memo, useState } from "react";
import { Row, Col, Space } from "antd";
import { ICreateEditFooterProps } from "./CreateEditSubProject.interface";
import AlectifyButton from "../button";
import AlectifyText from "static/texts.json";
import "./CreateEditSubProject.scss";

const CreateEditSubProjectFooter: React.FC<ICreateEditFooterProps> = (
  props: ICreateEditFooterProps,
) => {
  const [lastClickTime, setLastClickTime] = useState<number>(0);
  const { onCancel, onSubmit, currentActiveTab, isEdit } = props;

  const getButtonText = () => {
    if (currentActiveTab === 2) {
      return isEdit ? AlectifyText.UPDATE_FINISH : AlectifyText.SAVE_FINISH;
    } else {
      return isEdit ? AlectifyText.UPDATE_NEXT : AlectifyText.SAVE_NEXT;
    }
  };

  const handleClick = () => {
    const currentTime = new Date().getTime();
    if (currentTime - lastClickTime > 300) {
      onSubmit && onSubmit();
    }
    setLastClickTime(currentTime);
  };

  return (
    <div className="text-align-right">
      <Space>
        <AlectifyButton
          text={
            currentActiveTab === 1 ? AlectifyText.CANCEL : AlectifyText.PREVIOUS
          }
          type="default"
          className="light-blue-button"
          onClick={onCancel}
        />

        <AlectifyButton
          text={getButtonText()}
          htmlType="submit"
          type="primary"
          onClick={handleClick}
        />
      </Space>
    </div>
  );
};

export default memo(CreateEditSubProjectFooter);
