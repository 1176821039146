import { Path, Rect, Svg } from "@react-pdf/renderer";
import { PROJECT_THEME_COLOR } from "components/shared/phone-number/constants";

const TaskIconPDF = () => {
  return (
    <Svg width="14" height="14" viewBox="0 0 20 20" fill="white">
      <Rect
        x="0.999"
        y="2.3"
        width="17.1184"
        height="16"
        rx="4"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
      />
      <Path
        d="M8.2466 15.084H15.4016"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.2466 10.7971H15.4016"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M8.2466 6.5105H15.4016"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.52272 6.51049L3.23822 7.26573L5.3847 5"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.52272 10.7971L3.23822 11.5524L5.3847 9.28662"
        stroke={PROJECT_THEME_COLOR}
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
      <Path
        d="M2.52272 15.084L3.23822 15.8392L5.3847 13.5735"
        stroke="#000000"
        strokeWidth="1.2"
        strokeLineCap="round"
        strokeLinejoin="round"
      />
    </Svg>
  );
};

export default TaskIconPDF;
