import React from "react";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import AlectifyText from "static/texts.json";
import {
  IDrGroup,
  IGroupCheckpoint,
} from "services/data-round/data-round.interface";
import DRGroupTag from "components/shared/tags/DrGroupTag";
import DrCheckpointTag from "components/shared/tags/DrCheckpointTag";
import { IGroupTableProps } from "../GroupTable.interface";
import AlectifyButton from "components/shared/button";

export default (props: IGroupTableProps) => {
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: "Item No#",
      dataIndex: "item_no",
      visible: true,
      key: "item_no",
      width: 80,
      ellipsis: {
        showTitle: false,
      },
    },
    {
      title: "Name",
      dataIndex: "area_name",
      key: "area_name",
      width: 180,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      searchable: true,
      render: (_, record: IDrGroup) => {
        return <span>{record.area_name || "-"}</span>;
      },
      //   sorter: (a, b) => a.name - b.name,
    },
    /* {
      title: "Description",
      dataIndex: "description",
      key: "description",
      width: 180,
      visible: true,
      ellipsis: {
        showTitle: false,
      },
      searchable: true,
      render: (_, record: IDrGroup) => {
        return <span>{record.description || "-"}</span>;
      },
    }, */
    {
      title: AlectifyText.ACTIONS,
      align: "center",
      visible: props.showAction,
      dataIndex: "",
      width: 80,
      render: (_, group: IDrGroup) =>
        props.selectRowOption ? (
          <AlectifyButton
            text="Select"
            type="primary"
            onClick={() => props.onRowSelect?.(group)}
          />
        ) : (
          <></>
        ),
    },
    {
      title: "Action",
      align: "center",
      visible: props.displayActionButton,
      dataIndex: "",
      ellipsis: {
        showTitle: false,
      },
      width: 100,
    },
  ];
  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};
