import { isEmpty } from "lodash";
import AlectifyTable from "components/shared/table";
import {
  IPagination,
  ITableFilterType,
} from "components/shared/table/AlectifyTable.interface";
import { PAGINATION } from "constants/index";
import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form } from "antd";
import { MODAL_NAMES } from "constants/modal.constants";
import { useParams } from "react-router-dom";
import { IViewDocument } from "redux/components/documents";
import {
  downloadDocumentInZipAction,
  getAssetDocuments,
  getDocumentsCount,
} from "redux/components/documents/sources";
import { IRootState } from "redux/rootReducer";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import DeleteDocument from "../delete-document";
import EditDocument from "../edit-document";
import AlectifyModal from "../modal";
import RestoreDocument from "../restore-document";
import { IDocumentsTableProps } from "./AssetDocumentsTable.interface";
import useDocumentsColumn from "./effects/useDocumentsColumn";
import { DocumentEditTypes } from "../edit-document/EditDocument.interface";

const AssetDocumentsTable: React.FC<IDocumentsTableProps> = (
  props: IDocumentsTableProps,
) => {
  const [EditFormInstance] = Form.useForm();
  const [DeleteFormInstance] = Form.useForm();
  const [RestoreFormInstance] = Form.useForm();
  const dispatch = useDispatch();

  const documents = useSelector((state: IRootState) => state.documents);
  const { subProjectId } = useParams();
  // const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [optionsPersisted, setPersistedOptions] = useState<any>({
    isActive: "true",
  });
  const prevIsActiveRef = useRef(optionsPersisted);
  useEffect(() => {
    getDocuments({ ...optionsPersisted, isActive: "true" } as any);
  }, [props.subProjectId, props.activeTab]);

  useEffect(() => {
    if (optionsPersisted?.isActive !== prevIsActiveRef.current?.isActive) {
      props.fetchAllCounts && props.fetchAllCounts(optionsPersisted);
      prevIsActiveRef.current = optionsPersisted;
    }
  }, [optionsPersisted]);

  const getDocuments = (options?: IPagination | null) => {
    if (options?.isActive === "all") options.isActive = undefined;
    const types = props.workOrderAttachments
      ? ["PM_EXTERNAL", "TASK"]
      : props.timelineDocuments
      ? ["ASSET", "ASSET_PACKAGE"]
      : undefined;
    const params = {
      page: options?.page || PAGINATION.DEFAULT_START_PAGE,
      limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      searchText: options?.searchText || null,
      types: types,
      ...options,
    } as any;
    if (!isEmpty(options)) setPersistedOptions(options);
    dispatch(getAssetDocuments(props.assetId, params));
  };

  const downloadDocumentsInZip = async (s3Keys: string[]) => {
    dispatch(downloadDocumentInZipAction(s3Keys));
  };

  const onAssetDocumentDelete = async (record: IViewDocument) => {
    try {
      DeleteFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Delete ${record?.fileName}`,
        name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: async () => {
          DeleteFormInstance.submit();
        },
        destroyOnClose: true,
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
          });
        },
        children: (
          <DeleteDocument
            comment={""}
            type={record.type}
            documentId={record.id}
            form={DeleteFormInstance}
            fileName={record.fileName}
            subProjectId={subProjectId || record.subProjectId}
            successCb={() => {
              getDocuments(optionsPersisted);
              dispatch(getDocumentsCount(subProjectId));
              props.fetchAllCounts && props.fetchAllCounts();
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.DELETE_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onAssetDocumentEdit = async (
    record: IViewDocument,
    editType: DocumentEditTypes,
  ) => {
    try {
      EditFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Edit ${record?.fileName}`,
        name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: async () => {
          EditFormInstance.submit();
        },
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
          });
        },
        destroyOnClose: true,
        children: (
          <EditDocument
            comment={record.comment}
            type={record.type}
            documentId={record.id}
            version={""}
            form={EditFormInstance}
            fileName={record.fileName}
            subProjectId={subProjectId || record.subProjectId}
            editType={editType}
            successCb={() => {
              getDocuments(optionsPersisted);
              dispatch(getDocumentsCount(subProjectId));
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.EDIT_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const onAssetDocumentRestore = async (record: IViewDocument) => {
    try {
      RestoreFormInstance.resetFields();
      ModalServiceInstance.open(AlectifyModal, {
        title: `Restore ${record?.fileName}`,
        name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
        width: 500,
        okText: "Save Changes",
        onOk: async () => {
          RestoreFormInstance.submit();
        },
        onCancel: () => {
          ModalServiceInstance.close(AlectifyModal, {
            name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
          });
        },
        destroyOnClose: true,
        children: (
          <RestoreDocument
            comment={""}
            type={record.type}
            documentId={record.id}
            form={RestoreFormInstance}
            fileName={record.fileName}
            subProjectId={subProjectId || record.subProjectId}
            successCb={() => {
              getDocuments(optionsPersisted);
              dispatch(getDocumentsCount(subProjectId));
              props.fetchAllCounts && props.fetchAllCounts();
              ModalServiceInstance.close(AlectifyModal, {
                name: MODAL_NAMES.RESTORE_DOCUMENT_MODAL,
              });
            }}
          />
        ),
      });
    } catch (error) {
      console.log(error);
    }
  };

  const columns = useDocumentsColumn({
    optionsPersisted,
    assetId: props.assetId,
    onEdit: onAssetDocumentEdit,
    onDelete: onAssetDocumentDelete,
    onRestore: onAssetDocumentRestore,
    workOrderAttachments: props.workOrderAttachments,
    timelineDocuments: props.timelineDocuments,
  });

  const displayRowClass = (record: any) => {
    return !record.isActive ? "disable-row-allow-actions" : "";
  };

  // const onRowSelectionAction = (selectedRows: any, newSelectedRowKeys: any) => {
  //   setSelectedRowKeys(newSelectedRowKeys);
  // };

  return (
    <AlectifyTable
      scroll={props.scroll}
      size="small"
      columns={columns}
      loading={documents.fetching}
      dataSource={documents.assetDocs?.data}
      total={documents.assetDocs?.meta.totalItems}
      showSearch
      rowSelection={props?.rowSelection}
      downloadable={props?.downloadable}
      onDataFetch={getDocuments}
      onDownloadZip={downloadDocumentsInZip}
      documentType={"asset"}
      customSearchKey="search"
      columnFilterAsString
      displayRowClass={displayRowClass}
      filterType={ITableFilterType.DROPDOWN}
      filters={{
        key: "isActive",
        defaultValue: "true",
        options: [
          { label: "All", value: "all" },
          { label: "Active", value: "true" },
          { label: "In Active", value: "false" },
        ],
      }}
      // getCheckboxProps
      // onRowSelectionAction={onRowSelectionAction}
      // selectedRowKeys={selectedRowKeys}
    />
  );
};

export default memo(AssetDocumentsTable);
