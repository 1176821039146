import { IReduxAction } from "redux/interfaces";
import { INotificationsResponse } from "services/notifications/notifications.interface";

export interface INotificationActions {
  setNotifications: IReduxAction<INotificationsResponse>;
  appendNotifications: IReduxAction<INotificationsResponse>;
  resetNotificatios: IReduxAction<any>;
  setNotificationsLoader: IReduxAction<boolean>;
  markNotificationsAsRead: IReduxAction<string[]>;
  setUnreadNotificationsCount: IReduxAction<INotificationCountResponse>;
  toggleNotificationDropdown: IReduxAction<boolean>;
  toggleSystemNotificationDropdown: IReduxAction<boolean>;
}

export interface INotificationsInitialState extends INotificationsResponse {
  fetching: boolean;
  unreadCounts: number;
  unReadSystemGenerated: number;
  isDropdownOpen: boolean;
  isSystemDropdownOpen: boolean;
}

export interface INotificationCountResponse {
  message: string;
  status: boolean;
  data: {
    unRead: number;
    unReadSystemGenerated: number;
  };
}

export enum NotificationTypes {
  SYSTEM = "system",
  GLOBAL = "global",
}
