import { AxiosResponse } from "axios";
import { llmHttp } from "utils/client";
import { CHAT, TOP_5 } from "./chatBot.endpoints";
import { IChatResponse } from "components/chatbot-ui/Chatbot.interface";
import { IChatBotPayload } from "./chatBot.interface";
import { SERVICE_UNIQUE_ID } from "utils/constants";
import { IParams } from "redux/interfaces";

// /**
//  * Fetch chat bot answers based on the provided payload.
//  *
//  * @param {object} payload - The payload containing the necessary data for the request.
//  * @returns {Promise<IChatResponse>} - A promise that resolves to the response data.
//  * @throws {Error} - Throws an error if the request fails.
//  */

export const fetchChatBotAnswers = async (payload: object) => {
  try {
    const response: AxiosResponse<IChatResponse> = await llmHttp.post(
      CHAT,
      payload,
    );
    return response.data;
  } catch (error) {
    console.log(error);
  }
};

// /**
//  * Fetch the top five answers based on the provided payload.
//  *
//  * @param {object} payload - The payload containing the necessary data for the request.
//  * @returns {Promise<IChatResponse>} - A promise that resolves to the response data.
//  * @throws {Error} - Throws an error if the request fails.
//  */

export const fetchTopFiveAnswers = async (
  payload: IChatBotPayload,
  params?: IParams,
) => {
  try {
    const response: AxiosResponse<IChatResponse> = await llmHttp.post(
      TOP_5,
      payload,
      undefined, // headers argument undefined
      SERVICE_UNIQUE_ID.TOP_5_SERVICE_API,
    );
    return response?.data;
  } catch (error) {
    console.log(error);
  }
};
