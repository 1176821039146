import { configureStore } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

import ReduxGlobals from "redux/globals";
import rootReducer from "redux/rootReducer";
import * as Tasks from "redux/components/tasks";
import * as Assets from "redux/components/assets";
import * as SubProject from "redux/components/sub-project";
import * as AssetPackages from "redux/components/asset-packages";
import * as PmInternal from "redux/components/pm-internal";
import * as PmExternal from "redux/components/pm-external";
import * as Procedures from "redux/components/procedures";
import * as Notifications from "redux/components/notifications";

const devTools = process.env.NODE_ENV !== "production";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    Tasks.reducerName,
    Assets.reducerName,
    SubProject.reducerName,
    AssetPackages.reducerName,
    PmExternal.reducerName,
    PmInternal.reducerName,
    PmExternal.reducerName,
    Procedures.reducerName,
    Notifications.reducerName,
  ], //list down reducer names you don't want to persist.
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  devTools,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore specific actions by type (e.g., 'persist/PERSIST')
        ignoredActions: ["persist/PERSIST"],
      },
    }),
});

ReduxGlobals.dispatch = store.dispatch;

// to apply some checks or change store after it changes everytime
store.subscribe(() => {
  console.log("store triggerred...");
  // This fires when ever we update anything in store
});

export const persistor = persistStore(store);

export default store;
