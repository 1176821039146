import React, { memo } from "react";
import { Form, Input } from "antd";
import { Rule } from "antd/lib/form";

import { IAlectifyInputProps } from "./AlectifyInput.interface";

import "./AlectifyInput.scss";

const TextFields: React.FC<IAlectifyInputProps> = (props) => {
  const {
    name,
    placeholder,
    type,
    prefix,
    onChange,
    allowClear,
    onFocus,
    rules,
    ...restProps
  } = props;

  return (
    <>
      {type === "password" ? (
        <Input.Password
          placeholder={placeholder || `Enter your ${name} here`}
          prefix={prefix ? prefix : null}
          {...props}
          {...restProps}
        />
      ) : type === "textarea" ? (
        <Input.TextArea
          {...props}
          {...(restProps as any)}
          placeholder={placeholder || `Enter your ${name} here`}
          rows={props.rows}
          style={{
            resize: props.resizeable ? "inherit" : "none",
          }}
          maxLength={props?.max}
          showCount={
            props?.max
              ? {
                  formatter: ({ count, maxLength }) =>
                    `${count} / ${maxLength}`,
                }
              : undefined
          }
        />
      ) : (
        <>
          <Input
            placeholder={placeholder || `Enter your ${name} here`}
            prefix={prefix ? prefix : null}
            onChange={onChange && onChange}
            onFocus={onFocus && onFocus}
            {...props}
            allowClear={allowClear}
            className={
              props.noFormitem ? `alectify-input ${props.className}}` : ""
            }
            {...restProps}
          />
          {props.extra}
        </>
      )}
    </>
  );
};

const AlectifyInput: React.FC<IAlectifyInputProps> = (
  props: IAlectifyInputProps,
) => {
  const { label, name, rules, className, tooltip } = props;

  const customRules: Rule[] = rules
    ? rules.map((rule: any) => ({
        ...rule,
        type: rule.type,
        message: rule.message,
        required: rule.required,
      }))
    : [];

  return !props.noFormitem ? (
    <Form.Item
      label={label && label}
      tooltip={tooltip && tooltip}
      name={name}
      rules={customRules}
      className={`alectify-input ${className}`}
    >
      <TextFields {...props} />
    </Form.Item>
  ) : (
    <TextFields {...props} />
  );
};

AlectifyInput.defaultProps = {
  noFormitem: false,
};

export default memo(AlectifyInput);
