import { Space, Tag } from "antd";
import { ToolOutlined } from "@ant-design/icons";
import { CHIP_COLORS } from "components/shared/phone-number/constants";
import AlectifyText from "static/texts.json";

const SparePartTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag
      color={CHIP_COLORS.SPARE_PART}
      style={{ color: "#A855B6" }}
      className="round-tag"
    >
      <Space size={4}>
        <ToolOutlined style={{ color: "#A855B6", fontSize: 20 }} />
        <span>{text || AlectifyText.SPARE_PART}</span>
      </Space>
    </Tag>
  );
};

export default SparePartTag;
