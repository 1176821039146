import { Breadcrumb, Col, Row } from "antd";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "routes/Routes.constants";
import AlectifyText from "static/texts.json";
import DashboardStatistics from "./DashboardStatistics";
import "./Dashboard.styles.scss";

const Dashboard: React.FC = () => {
  const navigate = useNavigate();

  return (
    <div className="dashboard-container">
      <Breadcrumb
        className=""
        items={[
          {
            title: "Home",
            href: `#`,
            onClick: () => navigate(`${ROUTES.PROJECT}`),
          },
          {
            title: AlectifyText.DASHBOARD,
          },
        ]}
      />
      <Row>
        <Col span={24}>
          <DashboardStatistics />
        </Col>
      </Row>
    </div>
  );
};

export default Dashboard;
