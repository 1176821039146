import { ChatBotIcon } from "components/icons";
import "./AutoCreateProcedure.style.scss";
import { MESSAGES } from "constants/messages";

const AutoCreateWelcome: React.FC = () => {
  return (
    <div className="ai-creator-welcome-container">
      <ChatBotIcon height={"100"} width="180" />

      <div className="ai-creator-summary">
        <h1>{MESSAGES.AI_FORM_PROCEDURE.WELCOME_MESSAGE}</h1>
      </div>
    </div>
  );
};

export default AutoCreateWelcome;
