import { CloudDownloadOutlined } from "@ant-design/icons";
import { Avatar, Tooltip, Typography } from "antd";
import AlectifyLogo from "assets/images/alectify-sidebar-logo.svg";
import { useSelector } from "react-redux";
import { IUser } from "redux/components/Auth";
import { IRootState } from "redux/rootReducer";
import { downloadSingleFile } from "services/documents/documents.service";
import {
  displayDateTime,
  getFullUserName,
  getUserImage,
  isAudioFileType,
} from "utils/helpers";
import { ICommentItem } from "./Comments.interface";
import AlectifyAudioPlayer from "../preview-file/AlectifyAudioPlayer";
import "./Comment.scss";
import { useState } from "react";

const CommentItem: React.FC<{ comment: ICommentItem }> = ({ comment }) => {
  const user = useSelector((state: IRootState) => state.auth.user);
  const [showTranscript, setShowTranscript] = useState(false);

  const toggleTranscriptVisibility = () => setShowTranscript((prev) => !prev);

  const getAuthorDisplayName = (
    author: IUser | undefined,
    isSystem: boolean,
  ) => {
    const fallbackUser: IUser = {
      id: "",
      first_name: "Loading",
      last_name: "",
      email: "",
    };

    return isSystem
      ? "SYSTEM MESSAGE"
      : `${getFullUserName(author ?? fallbackUser)} ${
          isCurrentUser(author) ? "(You)" : ""
        }`;
  };

  const isCurrentUser = (author: IUser | undefined) => author?.id === user?.id;

  const renderAuthor = (isSystem: boolean) => (
    <span className={`author-title ${isSystem ? "system-author" : ""}`}>
      {getAuthorDisplayName(comment.sent_by, isSystem)}
    </span>
  );

  const renderAvatar = () => {
    const fallbackImage = AlectifyLogo;
    const userImage = comment.sent_by
      ? getUserImage(comment.sent_by)
      : fallbackImage;
    const altText = comment.sent_by?.first_name || "Unknown User";

    return (
      <Avatar
        className={comment.is_system_generated ? "system-author-avatar" : ""}
        src={userImage}
        alt={altText}
      />
    );
  };

  const renderTranscriptToggle = () =>
    comment.speech_transcript && (
      <div
        className="text-link d-flex justify-flex-end m-5"
        onClick={toggleTranscriptVisibility}
      >
        {showTranscript ? "Hide Transcript" : "Show Transcript"}
      </div>
    );

  const renderAttachment = () =>
    isAudioFileType(comment.file_name) ? (
      <div className="audio-player-container">
        <AlectifyAudioPlayer
          fileName={comment.s3_key}
          filePath={comment.s3_url}
          isSigned
        />
        {renderTranscriptToggle()}
        {showTranscript && (
          <div className="transcript-content">{comment.speech_transcript}</div>
        )}
      </div>
    ) : (
      <Tooltip title="Download" placement="right">
        <div
          className="comment-attachment d-flex text-ellipsis"
          onClick={() => downloadSingleFile(comment.s3_key, comment.file_name)}
        >
          <CloudDownloadOutlined className="icon-size-15" />
          <span className="ml-10">{comment.file_name}</span>
        </div>
      </Tooltip>
    );

  return (
    <div
      className={`comment-item ${
        comment.is_system_generated ? "system-comment" : ""
      }`}
    >
      <div className="sender-avatar">
        <Tooltip
          title={
            comment.is_system_generated
              ? "System Message"
              : getFullUserName(
                  comment.sent_by ?? {
                    id: "",
                    first_name: "Loading",
                    last_name: "",
                    email: "",
                  },
                )
          }
        >
          {renderAvatar()}
        </Tooltip>
      </div>
      <div
        className={`comment-content ${
          isCurrentUser(comment.sent_by) ? "me" : "them"
        }`}
      >
        {renderAuthor(comment.is_system_generated)}
        <div className="comment-message">
          <Typography.Paragraph className="mb-0">
            {comment.is_system_generated && renderAuthor(false)}
            <div dangerouslySetInnerHTML={{ __html: comment.text }} />
          </Typography.Paragraph>
        </div>
        {comment.content_type === "attachment" && renderAttachment()}
        <div className="date-text text-align-right">
          {displayDateTime(comment.created_at, true)}
        </div>
      </div>
    </div>
  );
};

export default CommentItem;
