import React, { memo } from "react";
import { Col, Form, Input, Row, Tooltip, Upload } from "antd";
import {
  DeleteOutlined,
  PaperClipOutlined,
  SendOutlined,
} from "@ant-design/icons";
import AlectifyButton from "../button";
import { IEditorProps } from "./editor.interface";
import { PROJECT_THEME_COLOR } from "../phone-number/constants";
import { isEmpty } from "lodash";
import TextToLink from "../text-to-link";
import TextEditor from "components/text-editor/TextEditor";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/index";
import "../comments/Comment.scss";

const Editor: React.FC<IEditorProps> = ({
  attachment,
  onChange,
  onSubmit,
  submitting,
  value,
  onAddAttachment,
  onDeleteAttachment,
}) => {
  return (
    <div className="editor-body">
      <TextEditor
        // form={formInstance}
        name="description"
        // label="Briefly Explain Incident:"
        initialValue={value}
        onChange={onChange}
        customClass="comment-text-editor"
        rules={BASIC_REQUIRED_VALIDATION_RULES}
      />
      <Form.Item>
        <div className="attach-send-container">
          <div className="cursor-pointer ">
            <Upload
              beforeUpload={() => false}
              onChange={onAddAttachment}
              showUploadList={false}
              fileList={[]}
              multiple
            >
              <TextToLink
                text={
                  <>
                    <PaperClipOutlined
                      className="alectify-primary-color"
                      color={PROJECT_THEME_COLOR}
                    />
                    <span className="alectify-primary-color">Attach</span>
                  </>
                }
              />
            </Upload>
          </div>
          <Tooltip title="Send">
            <AlectifyButton
              htmlType="submit"
              text={"Send"}
              icon={<SendOutlined />}
              type="primary"
              onClick={onSubmit}
              loading={submitting}
              className="dark-blue-button"
              disabled={isEmpty(value) && isEmpty(attachment?.fileList)}
            />
          </Tooltip>
        </div>
      </Form.Item>
      {!isEmpty(attachment?.fileList) && (
        <div className="uploaded-file-container">
          <Row justify={"start"} align={"middle"}>
            {attachment.fileList.map((d: any, index: number) => (
              <Col span={24}>
                <Row justify={"space-between"} align={"middle"}>
                  <Col span={12} className="text-align-left">
                    <div className="ellipsis-text">{d.name}</div>
                  </Col>
                  <Col span={12} className="text-align-right">
                    <DeleteOutlined
                      className="cursor-pointer ml-20"
                      onClick={() => onDeleteAttachment(index)}
                    />
                  </Col>
                </Row>
              </Col>
            ))}
          </Row>
        </div>
      )}
    </div>
  );
};

export default memo(Editor);
