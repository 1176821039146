import React from "react";

const MarkerIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="40"
      height="51"
      viewBox="0 0 40 51"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.85823 6.08808C9.60891 2.18995 14.6959 0 20.0002 0C25.3045 0 30.3915 2.18995 34.1422 6.08808C37.8929 9.9862 40 15.2732 40 20.786C40 26.2988 37.8929 31.5858 34.1422 35.4839L20.0002 50.1818L5.85823 35.4839C4.00095 33.5538 2.52767 31.2624 1.52251 28.7405C0.517351 26.2186 0 23.5157 0 20.786C0 18.0563 0.517351 15.3533 1.52251 12.8315C2.52767 10.3096 4.00095 8.01816 5.85823 6.08808ZM20.0002 26.7245C21.5156 26.7245 22.969 26.0989 24.0406 24.9852C25.1121 23.8715 25.7141 22.361 25.7141 20.786C25.7141 19.211 25.1121 17.7005 24.0406 16.5868C22.969 15.4731 21.5156 14.8474 20.0002 14.8474C18.4848 14.8474 17.0314 15.4731 15.9599 16.5868C14.8883 17.7005 14.2863 19.211 14.2863 20.786C14.2863 22.361 14.8883 23.8715 15.9599 24.9852C17.0314 26.0989 18.4848 26.7245 20.0002 26.7245Z"
        fill="#D70714"
      />
    </svg>
  );
};

export default MarkerIcon;
