import { Dispatch } from "react";
import { IAsset, IAssetsResponse } from "../assets.interface";
import {
  fetchAssetDetail,
  fetchProjectAssets,
} from "services/assets/assets.service";
import { IParams, SourceActionType } from "redux/interfaces";
import { actions } from "..";

/**
 * Retrieves project assets for a specific subproject.
 *
 * @param subProjectId - The unique identifier of the subproject for which to fetch assets.
 * @param params - Additional parameters to customize the asset retrieval, if applicable.
 *
 * @returns A function that, when dispatched, triggers an asynchronous action to fetch project assets and updates the Redux store.
 */
export const getProjectAssets: SourceActionType = (
  subProjectId: string,
  params: IParams,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setAssetsLoader(true));
      const response: IAssetsResponse = await fetchProjectAssets(
        subProjectId,
        params,
      );
      dispatch(actions.setAssetsLoader(false));
      dispatch(actions.setProjectAssets(response));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setAssetsLoader(false));
    }
  };
};

/**
 * Retrieves asset details for a given asset and sub-project ID and updates the Redux store.
 *
 * @param {string} assetId - The ID of the asset to retrieve details for.
 * @param {string} subProjectId - The ID of the sub-project to which the asset belongs.
 *
 * @returns {Function} A function that, when dispatched, triggers an asynchronous action to fetch asset detail and updates the Redux store.
 */
export const getAssetDetail: SourceActionType = (
  assetId: string,
  subProjectId: string,
) => {
  return async (dispatch: Dispatch<any>) => {
    try {
      dispatch(actions.setAssetDetailLoader(true));
      const response: IAsset = await fetchAssetDetail(assetId, subProjectId);
      dispatch(actions.setAssetDetail(response));
      dispatch(actions.setAssetDetailLoader(false));
    } catch (ex) {
      console.log(ex);
      dispatch(actions.setAssetDetailLoader(false));
    }
  };
};
