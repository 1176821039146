import { ProcedureInitialState } from "redux/components/procedures/procedures.initialState";
import { IProceduresActions } from "redux/components/procedures/procedures.interface";
import * as ProceduresInterface from "services/procedures/procedures.interface";
import { reducerName } from "redux/components/procedures/procedures.constants";
import * as ProceduresAffects from "redux/components/procedures/affects";
import { createActionAndReducers } from "redux/utils/reducer.helper";

export * from "./procedures.constants";
export * from "./procedures.interface";

const { actions, reducer } = createActionAndReducers<IProceduresActions>(
  reducerName,
  ProcedureInitialState,
  ProceduresAffects,
);

export { actions, ProcedureInitialState, reducer, ProceduresInterface };
