interface IAssetsColoredIcon {
  fill?: string;
  stroke?: string;
}

const AssetsColoredIcon: React.FC<IAssetsColoredIcon> = ({ fill }) => {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.43972 10.0918C8.33392 10.0918 8.22812 10.0667 8.13047 10.0081L0.944461 5.73199C0.651488 5.55626 0.553814 5.1713 0.724715 4.87004C0.895616 4.56879 1.26183 4.46835 1.56294 4.64408L8.43972 8.73617L15.2757 4.66921C15.5686 4.49348 15.943 4.60228 16.1139 4.89517C16.2848 5.19643 16.1791 5.58134 15.8942 5.75707L8.7571 10.0081C8.65131 10.0583 8.54551 10.0918 8.43972 10.0918Z"
        fill={fill || "#fff"}
      />
      <path
        d="M8.43995 17.6736C8.10629 17.6736 7.82959 17.389 7.82959 17.0459V9.45598C7.82959 9.11289 8.10629 8.82837 8.43995 8.82837C8.77361 8.82837 9.05031 9.11289 9.05031 9.45598V17.0459C9.05031 17.389 8.77361 17.6736 8.43995 17.6736Z"
        fill={fill || "#fff"}
      />
      <path
        d="M8.43924 18C7.69867 18 6.99065 17.8243 6.45353 17.5146L2.10777 15.0377C0.927739 14.3682 0 12.7531 0 11.364V6.6276C0 5.23847 0.927739 3.63181 2.10777 2.95399L6.45353 0.476987C7.56032 -0.158996 9.30188 -0.158996 10.4168 0.476987L14.7626 2.95399C15.9426 3.62345 16.8703 5.23847 16.8703 6.6276V9.69038C16.8703 10.0335 16.5937 10.318 16.26 10.318C15.9263 10.318 15.6496 10.0335 15.6496 9.69038V6.6276C15.6496 5.70709 14.9579 4.50212 14.1766 4.0586L9.83086 1.5816C9.09029 1.15482 7.79633 1.15482 7.05576 1.5816L2.70998 4.0586C1.92058 4.51048 1.23699 5.70709 1.23699 6.6276V11.364C1.23699 12.2845 1.92872 13.4895 2.70998 13.9331L7.05576 16.41C7.77191 16.8201 9.12284 16.8201 9.83086 16.41C10.1238 16.2427 10.4982 16.3515 10.661 16.6527C10.8237 16.954 10.7179 17.3389 10.4249 17.5063C9.88783 17.8243 9.17981 18 8.43924 18Z"
        fill={fill || "#fff"}
      />
      <path
        d="M14.2995 17.4978C12.5254 17.4978 11.085 16.0167 11.085 14.1924C11.085 12.3681 12.5254 10.887 14.2995 10.887C16.0736 10.887 17.5141 12.3681 17.5141 14.1924C17.5141 16.0167 16.0736 17.4978 14.2995 17.4978ZM14.2995 12.1422C13.2009 12.1422 12.3057 13.0627 12.3057 14.1924C12.3057 15.3221 13.2009 16.2426 14.2995 16.2426C15.3982 16.2426 16.2934 15.3221 16.2934 14.1924C16.2934 13.0627 15.3982 12.1422 14.2995 12.1422Z"
        fill={fill || "#fff"}
      />
      <path
        d="M17.3914 18.0002C17.2368 18.0002 17.0821 17.9416 16.9601 17.8161L16.1462 16.9793C15.9102 16.7366 15.9102 16.3348 16.1462 16.0922C16.3822 15.8495 16.7729 15.8495 17.0089 16.0922L17.8227 16.929C18.0587 17.1717 18.0587 17.5734 17.8227 17.8161C17.7006 17.9416 17.546 18.0002 17.3914 18.0002Z"
        fill={fill || "#fff"}
      />
    </svg>
  );
};

export default AssetsColoredIcon;
