import { IParams } from "redux/interfaces";
import {
  ICheckpointResponse,
  ICreateCheckPointPayload,
  ICreateCheckpointResponse,
  ICreateDRProjectPayload,
  ICreateDrProjectResponse,
  ICreateNewGroupPayload,
  IDataRoundAnalyticsResponse,
  IDataRoundInsightsResponse,
  IDataRoundProjectResponse,
  IDrProjectDetailsResponse,
  IDrProjectGroupsResponse,
  IGroupAndCheckpointListingResponse,
  IRoundDetailResponse,
} from "./data-round.interface";
import {
  CREATE_DR_PROJECT,
  CREATE_NEW_CHECKPOINT,
  CREATE_NEW_GROUP,
  DATA_ROUND_INSIGHTS,
  GET_ALL_CHECKPOINTS,
  GET_DATA_ROUND_ANALYTICS,
  GET_DATA_ROUND_PROJECT,
  GET_DR_PROJECT_DETAILS,
  GET_GROUP_AND_CHECKPOINT_LISTING,
  GET_ROUND_DETAILS,
  RESET_CHECKPOINT_GROUP_ORDER,
  UPDATE_CHECKPOINT,
  UPDATE_DR_PROJECT,
  UPDATE_GROUP,
} from "./data-round.endpoints";
import { AxiosResponse } from "axios";
import { MULTIPART_HEADERS, http } from "utils/client";
import { IPagination } from "components/shared/table/AlectifyTable.interface";

/**
 * Fetches Data Round Project for a given master project ID.
 *
 * @param masterProjectId - The ID of the master project to fetch Data Round Project for.
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the Data Round Project data.
 * @throws If an error occurs during the request.
 */
export const fetchDataRoundProjects = async (
  masterProjectId: string,
  params?: IParams, // Make params optional
): Promise<IDataRoundProjectResponse> => {
  try {
    const url = GET_DATA_ROUND_PROJECT.replace(
      "{masterProjectId}",
      masterProjectId,
    );
    const response: AxiosResponse<IDataRoundProjectResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error; // Re-throw the error for better error handling elsewhere
  }
};

/**
 * Creates a new Data Round Project.
 *
 * @param {ICreateDRProjectPayload} body - The request body containing data for the new Data Round Project.
 * @returns {Promise<any>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const createDrProject = async (
  body: ICreateDRProjectPayload,
): Promise<ICreateDrProjectResponse> => {
  try {
    const response = await http.post(CREATE_DR_PROJECT, body);
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Updates a Data Round Project.
 *
 * @param {ICreateDRProjectPayload} body - The request body containing data for the Data Round Project.
 * @returns {Promise<ICreateDrProjectResponse>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const updateDrProject = async (
  projectId: string,
  body: ICreateDRProjectPayload,
): Promise<ICreateDrProjectResponse> => {
  try {
    const response = await http.patch(
      UPDATE_DR_PROJECT.replace("{projectId}", projectId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
/**
 * Get Dr Project details.
 *
 * @returns {Promise<any>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const getDRProjectDetails = async (
  projectId: string,
): Promise<IDrProjectDetailsResponse> => {
  try {
    const response = await http.get(
      GET_DR_PROJECT_DETAILS.replace("{projectId}", projectId),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Create New Group for Dr Projects
 * @param {ICreateNewGroupPayload} body - The request body containing data for the Data Round group.
 * @returns {Promise<any>}  A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 * */
export const createNewGroup = async (
  projectId: string,
  body: ICreateNewGroupPayload,
): Promise<any> => {
  try {
    const response = await http.post(
      CREATE_NEW_GROUP.replace("{projectId}", projectId),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * updates New Group for Dr Projects
 * @param {ICreateNewGroupPayload} body - The request body containing data for the Data Round group.
 * @returns {Promise<any>}  A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 * */
export const updateGroup = async (
  projectId: string,
  areaId: string,
  body: ICreateNewGroupPayload,
): Promise<any> => {
  try {
    const response = await http.patch(
      UPDATE_GROUP.replace("{projectId}", projectId).replace(
        "{areaId}",
        areaId,
      ),
      body,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * deletes Group for Dr Projects
 * @param {ICreateNewGroupPayload} body - The request body containing data for the Data Round group.
 * @returns {Promise<any>}  A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 * */
export const deleteGroup = async (
  projectId: string,
  areaId: string,
): Promise<any> => {
  try {
    const response = await http.delete(
      UPDATE_GROUP.replace("{projectId}", projectId).replace(
        "{areaId}",
        areaId,
      ),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Get Groups and Checkpoints of a Data Round Project.
 * @param {string} projectId - Id of a data round project to fetch groups and checkpoints.
 * @param {IPagination} params - optional params for search and pagination.
 * @returns {Promise<IGroupAndCheckpointListingResponse>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const getGroupAndCheckpointListing = async (
  projectId: string,
  params?: IPagination,
): Promise<IGroupAndCheckpointListingResponse> => {
  try {
    const response = await http.get(
      GET_GROUP_AND_CHECKPOINT_LISTING.replace("{projectId}", projectId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Get all of Groups/Area of a Data Round Project.
 * @param {string} projectId - Id of a data round project to fetch all groups/area.
 * @returns {Promise<IDrProjectGroupsResponse>} A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const getAllGroups = async (
  projectId: string,
  params?: IPagination,
): Promise<IDrProjectGroupsResponse> => {
  try {
    const response = await http.get(
      CREATE_NEW_GROUP.replace("{projectId}", projectId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Creates New checkpoint of a Data Round Project.
 * @param {string} projectId - Id of a data round project to create new checkpoint.
 * @param {ICreateCheckPointPayload} payload - The request body containing data for the Data Round checkpoint.
 * @returns {ICreateCheckpointResponse} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const createCheckpoint = async (
  projectId: string,
  payload: ICreateCheckPointPayload,
): Promise<ICreateCheckpointResponse> => {
  try {
    const response = await http.post(
      CREATE_NEW_CHECKPOINT.replace("{projectId}", projectId),
      payload,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Updates checkpoint of a Data Round Project.
 * @param {string} projectId - Id of a data round project to create new checkpoint.
 * @param {ICreateCheckPointPayload} payload - The request body containing data for the Data Round checkpoint.
 * @returns {ICreateCheckpointResponse} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const updateCheckpoint = async (
  projectId: string,
  checkpointId: string,
  payload: ICreateCheckPointPayload,
): Promise<ICreateCheckpointResponse> => {
  try {
    const response = await http.patch(
      UPDATE_CHECKPOINT.replace("{projectId}", projectId).replace(
        "{checkpointId}",
        checkpointId,
      ),
      payload,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Deletes checkpoint of a Data Round Project.
 * @param {string} projectId - Id of a data round project to delete checkpoint.
 * @returns {ICreateCheckpointResponse} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const deleteCheckpoint = async (
  projectId: string,
  checkpointId: string,
): Promise<ICreateCheckpointResponse> => {
  try {
    const response = await http.delete(
      UPDATE_CHECKPOINT.replace("{projectId}", projectId).replace(
        "{checkpointId}",
        checkpointId,
      ),
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Resets the order of checkpoints and groups of a Data Round Project.
 * @param {string} projectId - id of a data round project to reset the order of their checkpoints and groups.
 * @returns {Promise<any>} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const resetCheckpointAndGroupOrder = async (
  projectId: string,
): Promise<any> => {
  try {
    const response = await http.post(
      RESET_CHECKPOINT_GROUP_ORDER.replace("{projectId}", projectId),
      {},
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Bulk upload data round checkpoints and groups.
 * @param {projectId} projectId - id of a data round project upload the checkpoints and groups.
 * @param {FormData} payload - Multipart formdata/csv file containing information about checkpoints and groups.
 * @returns {Promise<any>} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */

export const bulkUploadCheckpointsAndGroups = async (
  projectId: string,
  payload: FormData,
): Promise<any> => {
  try {
    const response = await http.post(
      GET_GROUP_AND_CHECKPOINT_LISTING.replace("{projectId}", projectId),
      payload,
      MULTIPART_HEADERS,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * @param {projectId} projectId - id of a data round project to get insights of that project round.
 * @param {{[key: string]: string}}  params - Optional parameters for the request contains start_data and end_date.
 * @returns {Promise<IDataRoundInsightsResponse>} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const getDataRoundProjectInsights = async (
  projectId: string,
  params: { [key: string]: string },
): Promise<IDataRoundInsightsResponse> => {
  try {
    const response = await http.get(
      DATA_ROUND_INSIGHTS.replace("{projectId}", projectId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * @param {projectId} projectId - id of a data round project to get particular round details.
 * @param {roundId} roundId - id of a round.
 * @param {IPagination} params -  Optional parameters for the request for pagination.
 * @returns {Promise<IRoundDetailResponse>} - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */
export const getRoundDetails = async (
  projectId: string,
  roundId: string,
  params?: string,
): Promise<IRoundDetailResponse> => {
  try {
    const response = await http.get(
      GET_ROUND_DETAILS.replace("{projectId}", projectId).replace(
        "{roundId}",
        roundId,
      ),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches analytics for data round.
 * @param {string} projectId - id of a data round project to get particular round analytics.
 * @param { IParams } params -  Optional parameters for the request for pagination.
 * @returns {Promise<IDataRoundAnalyticsResponse>}  - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */

export const getDataRoundAnalytics = async (
  projectId: string,
  params?: IParams,
): Promise<IDataRoundAnalyticsResponse> => {
  try {
    const response = await http.get(
      GET_DATA_ROUND_ANALYTICS.replace("{projectId}", projectId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};

/**
 * Fetches all checkpoints of a data round.
 * @param {string} projectId - id of a data round project to get it's checkpoints.
 * @param { IParams } params -  Optional parameters for the request for pagination.
 * @returns {Promise<ICheckpointResponse>}  - A promise that resolves with the response data.
 * @throws Throws an exception if there is an error in the HTTP request.
 */

export const getAllCheckpoints = async (
  projectId: string,
  params?: IParams,
): Promise<ICheckpointResponse> => {
  try {
    const response = await http.get(
      GET_ALL_CHECKPOINTS.replace("{projectId}", projectId),
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
