import { memo } from "react";
import AlectifyTable from "components/shared/table";
import useLicenseFeatureColumn from "./effects/useLicenseFeatureColumn";
import { IFeaturesTable } from "./Admin.interface";

const FeaturesTable: React.FC<IFeaturesTable> = (props) => {
  const columns = useLicenseFeatureColumn(props);

  return (
    <AlectifyTable
      showSearch
      noIcontainsSearch
      loading={props.features.fetching}
      columns={columns}
      dataSource={props.features.data}
      total={props.features?.meta?.total_count || 0}
      onDataFetch={props.getFeatures}
      customSearchKey="search"
      scroll={{ y: "calc(100vh - 375px)" }}
    />
  );
};

export default memo(FeaturesTable);
