import React from "react";
import { Avatar, Badge, Space, Tooltip } from "antd";
import { ColumnGroupType, ColumnProps } from "antd/lib/table";
import { IUserManagementColumnProps } from "../UserManagementTable.interface";
import { FEATURE_FLAGS, IOrganization, IUser } from "redux/components/Auth";
import DeleteIcon from "components/icons/DeleteIcon";
import { NO_IMAGE_AVATAR } from "components/shared/assets-table/AssetsTable.constants";
import AlectifyModal from "components/shared/modal";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import ViewImage from "components/shared/view-image/ViewImage";
import { isEmpty } from "lodash";
import {
  getFullUserName,
  getUserImage,
  isExternalUser,
  isFeatureEnabled,
} from "utils/helpers";
import { USER_ACCESS_TYPES, UserTypeEnum } from "enums";
import { ArrowRightOutlined } from "@ant-design/icons";
import AdminTag from "components/shared/tags/AdminTag";
import InternalTag from "components/shared/tags/InternalTag";
import ExternalTag from "components/shared/tags/ExternalTag";
import { contactsTableConstants } from "pages/contacts/contacts-table/ContactsTable.constants";
import EditIcon from "components/icons/EditIcon";
import AlectifyText from "static/texts.json";
import { MODAL_NAMES } from "constants/modal.constants";
import ConfirmationModal from "components/shared/confirmation/Confirmation";
import { WarningIcon } from "components/icons";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";

const UserManagmentColumn = (props: IUserManagementColumnProps) => {
  const { activeMasterProject } = useSelector(
    ({ common }: IRootState) => common,
  );

  const onCancel = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.DELETE_USER_USER_MANAGEMENT,
    });
  };
  const loggedInUser = useSelector((state: IRootState) => state.auth.user);
  const columns: ColumnProps<any>[] | ColumnGroupType<any>[] | any[] = [
    {
      title: "Full Name",
      dataIndex: "first_name",
      align: "left",
      visible: true,
      width: 50,
      searchable: true,
      ellipsis: {
        showTitle: false,
      },
      render: (_, user) => (
        <div>
          <Tooltip
            placement="topLeft"
            title={
              user &&
              `(${user?.user_type && USER_ACCESS_TYPES[user?.user_type]})`
            }
          >
            <Badge
              count={
                isExternalUser(user) ? (
                  <ArrowRightOutlined className="external-user-arrow-icon" />
                ) : (
                  0
                )
              }
              offset={[-8, 28]}
            >
              <Avatar
                src={getUserImage(user)}
                className={`cursor-pointer ${
                  isExternalUser(user)
                    ? "alectify-avatar-external-user mr-5"
                    : "mr-5"
                }`}
                onClick={() =>
                  ModalServiceInstance.open(AlectifyModal, {
                    name: "alectify-task-image-viewer",
                    title: "Preview",
                    footer: null,
                    onCancel: () => {
                      ModalServiceInstance.close(AlectifyModal, {
                        name: "alectify-task-image-viewer",
                      });
                    },
                    children: (
                      <ViewImage
                        imageUrl={user.image || NO_IMAGE_AVATAR}
                        isDownloadable={!isEmpty(user.image)}
                      />
                    ),
                  })
                }
              />
            </Badge>
            {`${user.email === loggedInUser.email ? "(You) " : ""}`}
            {user && getFullUserName(user)}
          </Tooltip>
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      align: "left",
      searchable: true,
      visible: true,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string) => (
        <Tooltip placement="topLeft" title={value}>
          {value}
        </Tooltip>
      ),
    },
    {
      title: "Organization",
      dataIndex: "organization",
      align: "left",
      visible: true,
      searchable: true,
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: IOrganization) => (
        <Tooltip placement="topLeft" title={value?.name}>
          {value?.name}
        </Tooltip>
      ),
    },
    {
      title: contactsTableConstants.USERTYPE,
      key: "user_type",
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      visible: true,
      render: (user) => (
        <Tooltip
          placement="topLeft"
          title={user && user.user_type && UserTypeEnum[user?.user_type]}
        >
          {user?.user_type &&
            (user.user_type === UserTypeEnum?.ADMIN ? (
              <AdminTag />
            ) : user.user_type === UserTypeEnum?.INTERNAL ? (
              <InternalTag />
            ) : (
              <ExternalTag />
            ))}
        </Tooltip>
      ),
    },
    {
      title: "Actions",
      dataIndex: "",
      align: "left",
      visible: isFeatureEnabled(FEATURE_FLAGS.MANAGE_PROJECT),
      width: 50,
      ellipsis: {
        showTitle: false,
      },
      render: (value: string, record: IUser) => {
        const confirmationModal = () => {
          ModalServiceInstance.open(AlectifyModal, {
            name: MODAL_NAMES.DELETE_USER_USER_MANAGEMENT,
            title: AlectifyText.CONFIRMATION,
            footer: false,
            onCancel,
            children: (
              <ConfirmationModal
                message={`${record?.first_name} ${record?.last_name} will be removed from ${activeMasterProject?.name} and all its teams. Are you sure?`}
                icon={WarningIcon}
                onCancel={onCancel}
                onConfirm={() => {
                  props.onDelete(record.id);
                  ModalServiceInstance.close(AlectifyModal, {
                    name: MODAL_NAMES.DELETE_USER_USER_MANAGEMENT,
                  });
                }}
              />
            ),
          });
        };

        return (
          <Space>
            {record.email === loggedInUser.email ? (
              <></>
            ) : (
              <>
                <Tooltip title="Edit">
                  <span
                    className="cursor-pointer"
                    onClick={() => props.onEdit(record)}
                  >
                    <EditIcon />
                  </span>
                </Tooltip>
                <Tooltip title="Delete">
                  <span className="cursor-pointer" onClick={confirmationModal}>
                    <DeleteIcon />
                  </span>
                </Tooltip>
              </>
            )}
          </Space>
        );
      },
    },
  ];

  return React.useMemo(() => columns.filter((cols) => cols.visible), []);
};
export default UserManagmentColumn;
