import AlectifyTable from "components/shared/table";
import AlltimelineColumn from "./effects/useAlltimelineColumns";
import { fetchTimeline } from "services/timeline/timeline.service";
import { IPagination } from "components/shared/table/AlectifyTable.interface";
import { useEffect, useState } from "react";
import { PAGINATION } from "constants/index";
const AllTimelineTable: React.FC = () => {
  const [timeline, setTimeline] = useState<any>([]);
  const [loader, setLoader] = useState<boolean>(false);

  const getTimelineList = async (options?: IPagination) => {
    setLoader(true);
    try {
      const params = {
        ...options,
        page: options?.page || PAGINATION.DEFAULT_START_PAGE,
        limit: options?.per_page || PAGINATION.DEFAULT_PAGE_SIZE,
      };
      const response = await fetchTimeline(params);
      setTimeline(response);
      setLoader(false);
    } catch (ex) {
      console.log(ex);
    }
  };

  useEffect(() => {
    getTimelineList();
  }, []);

  const column = AlltimelineColumn();

  return (
    <>
      <AlectifyTable
        loading={loader}
        columns={column}
        dataSource={timeline?.data}
        total={timeline?.meta?.totalItems}
        onDataFetch={getTimelineList}
        scroll={{ y: "calc(100vh - 341px)" }}
      />
    </>
  );
};

export default AllTimelineTable;
