import { memo } from "react";
import { AssetPackageTag, AssetTag } from "components/shared/tags";
import TextToLink from "components/shared/text-to-link";
import { Tooltip } from "antd";
import { ROUTES } from "routes/Routes.constants";
import { useNavigate } from "react-router-dom";
import AlectifyText from "static/texts.json";
import { useDispatch } from "react-redux";
import {
  setActiveMasterProject,
  setActiveSubProject,
} from "redux/components/common/sources";
import { IEquipment, IGlobalSearchCardProps } from "./GlobalSearch.interface";
import "./GlobalSearch.scss";
import AlectifyButton from "../button";
import { truncateString } from "utils/helpers";

const GlobalSearchCard: React.FC<IGlobalSearchCardProps> = ({
  globalSearchItem,
  setGlobalSearchVisible,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const updateAssetAndAssetCategoryDetails = (equipment: IEquipment) => {
    const site = {
      id: equipment?.mainproject_id || "-",
      name: equipment?.mainproject_name || "-",
    };

    const assetCategory = {
      id: equipment?.subproject_id || "-",
      name: equipment?.subproject_name || "-",
    };

    dispatch(setActiveSubProject(assetCategory));
    dispatch(setActiveMasterProject(site));
  };

  const goToObjectDetailPage = (equipment: IEquipment) => {
    setGlobalSearchVisible(false);
    updateAssetAndAssetCategoryDetails(equipment);
    const url = ROUTES.TIMELINE.replace(
      ":subProjectId",
      equipment?.subproject_id || "",
    )
      .replace(
        ":equipmentType",
        equipment?.object_type === AlectifyText.TAG
          ? AlectifyText.ASSET_A
          : AlectifyText.ASSET_PACK,
      )
      .replace(":equipmentId", equipment?.id || "");
    navigate(url);
  };

  const onSiteClick = (equipment: IEquipment) => {
    setGlobalSearchVisible(false);
    updateAssetAndAssetCategoryDetails(equipment);

    navigate(`${ROUTES.MASTER_PROJECT_DETAILS}/${equipment?.mainproject_id}`);
  };

  const onClickSubProject = (equipment: IEquipment) => {
    setGlobalSearchVisible(false);
    updateAssetAndAssetCategoryDetails(equipment);
    navigate(
      `${ROUTES.MASTER_PROJECT_DETAILS}/${equipment?.mainproject_id}${ROUTES.SUB_PROJECT}/${equipment?.subproject_id}`,
    );
  };

  const goToAssetPackageDetail = (equipment: IEquipment) => {
    if (equipment?.package_id) {
      const url = ROUTES.TIMELINE.replace(
        ":subProjectId",
        equipment?.subproject_id,
      )
        .replace(":equipmentType", "asset-package")
        .replace(":equipmentId", equipment?.package_id);
      navigate(url);
      setGlobalSearchVisible(false);
      updateAssetAndAssetCategoryDetails(equipment);
    }
  };

  return (
    <div className="global-search-card-container">
      <div className="global-search-title">
        <p>
          <span className="dark-text">
            {truncateString(globalSearchItem?.name || "-", 20)}
          </span>
          <span className="faded-text">
            / {truncateString(globalSearchItem?.description || "-", 40)}
          </span>
        </p>
        <Tooltip
          title={`${
            globalSearchItem?.object_type === AlectifyText.TAG
              ? AlectifyText.GO_TO_ASSET_DETAIL
              : AlectifyText.GO_TO_ASSET_PACKAGE_DETAIL
          }`}
        >
          {/* <div
            className="cursor-pointer mr-5"
            onClick={() => goToObjectDetailPage(globalSearchItem)}
          >
            <GoToPageIcon className="goto-page-icon" />
          </div> */}
          <AlectifyButton
            className="goto-btn"
            text={AlectifyText.GO_TO_DETAIL}
            type="primary"
            onClick={() => goToObjectDetailPage(globalSearchItem)}
          />
        </Tooltip>
      </div>

      <div className="search-result-master-subProject-detail">
        <div className="tag-container">
          {globalSearchItem?.object_type === AlectifyText.TAG ? (
            <AssetTag />
          ) : (
            <AssetPackageTag />
          )}
        </div>
        <div className="d-flex align-items-center">
          <div
            className="color-dot"
            style={{
              backgroundColor: globalSearchItem?.mainproject_color || "grey",
            }}
          />
          <Tooltip title={globalSearchItem?.mainproject_name || "-"}>
            <TextToLink
              className="text-color-font"
              text={truncateString(
                globalSearchItem?.mainproject_name || "-",
                26,
              )}
              onClick={() => onSiteClick(globalSearchItem)}
            />
          </Tooltip>

          <span className="faded-text">/</span>
          <Tooltip title={globalSearchItem?.subproject_name || "-"}>
            <TextToLink
              className="text-to-link-options"
              text={truncateString(
                globalSearchItem?.subproject_name || "-",
                16,
              )}
              onClick={() => onClickSubProject(globalSearchItem)}
            />
          </Tooltip>

          {globalSearchItem?.package_name && (
            <span className="faded-text">/</span>
          )}

          {globalSearchItem?.package_name && (
            <Tooltip title={`Parent Asset: ${globalSearchItem?.package_name}`}>
              <TextToLink
                onClick={() => goToAssetPackageDetail(globalSearchItem)}
                className="text-to-link-options search-parent-asset-link"
                text={truncateString(globalSearchItem?.package_name || "-", 16)}
              />
            </Tooltip>
          )}
        </div>
      </div>
    </div>
  );
};

export default memo(GlobalSearchCard);
