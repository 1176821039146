import React from "react";

interface TasksColoredIconProps {
  isTimeline?: boolean;
}

const TasksColoredIcon: React.FC<TasksColoredIconProps> = ({ isTimeline }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M9.51001 15.084H16.6182"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51001 10.7969H16.6182"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.51001 6.5105H16.6182"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82355 6.51024L4.53436 7.26549L6.66682 4.99976"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82361 10.7976L4.53443 11.5528L6.66688 9.28711"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M3.82361 15.084L4.53443 15.8392L6.66688 13.5735"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <rect
        x="1"
        y="1"
        width="18"
        height="18"
        rx="3"
        stroke={isTimeline ? "#006FE6" : "#fff"}
        strokeWidth="1.2"
      />
    </svg>
  );
};
export default TasksColoredIcon;
