export enum AssetTypeEnum {
  Active = 1,
  Spare = 2,
  Inactive = 3,
}

export enum AssetStatusEnum {
  Active = "Active",
  Spare = "Spare",
  Inactive = "Inactive",
}
