const ExternalIcon: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="15"
      viewBox="0 0 18 13"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.32088 2.78571C2.32088 2.0469 2.61438 1.33834 3.1368 0.815916C3.65922 0.293494 4.36778 0 5.10659 0C5.84541 0 6.55397 0.293494 7.07639 0.815916C7.59881 1.33834 7.89231 2.0469 7.89231 2.78571C7.89231 3.52453 7.59881 4.23309 7.07639 4.75551C6.55397 5.27793 5.84541 5.57142 5.10659 5.57142C4.36778 5.57142 3.65922 5.27793 3.1368 4.75551C2.61438 4.23309 2.32088 3.52453 2.32088 2.78571ZM7.39163e-05 11.5174C0.0209483 10.1768 0.568137 8.89822 1.52352 7.95759C2.47891 7.01696 3.76587 6.48975 5.10659 6.48975C6.44732 6.48975 7.73428 7.01696 8.68966 7.95759C9.64505 8.89822 10.1922 10.1768 10.2131 11.5174C10.2147 11.6076 10.19 11.6964 10.1419 11.7728C10.0939 11.8492 10.0246 11.91 9.94259 11.9476C8.42541 12.6432 6.77564 13.0022 5.10659 13C3.38193 13 1.74331 12.6236 0.270598 11.9476C0.188565 11.91 0.119287 11.8492 0.0712409 11.7728C0.0231946 11.6964 -0.00153637 11.6076 7.39163e-05 11.5174Z"
        fill="white"
      />
      <path
        d="M13.564 3.00824L16.3108 5.73853L13.5805 8.48531"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.4051 5.74365L9.00812 5.74364"
        stroke="white"
        strokeWidth="1.6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
export default ExternalIcon;
