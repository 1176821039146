import { Space, Tag } from "antd";
import { DoubleCheckIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const ApprovedTag: React.FC = () => {
  return (
    <Tag color={"#27A0FD"} className="round-tag">
      <Space size={3} align="center">
        <DoubleCheckIcon /> {AlectifyText.APPROVED}
      </Space>
    </Tag>
  );
};

export default ApprovedTag;
