import { memo } from "react";

interface IAiSummaryIconProps {
  fill?: string | null;
}

const AiSummaryIcon: React.FC<IAiSummaryIconProps> = (
  props: IAiSummaryIconProps,
) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="15"
      height="14"
      viewBox="0 0 18 17"
      fill="none"
    >
      <path
        d="M11.5 4L13.4396 8.56041L18 10.5L13.4396 12.4396L11.5 17L9.56041 12.4396L5 10.5L9.56041 8.56041L11.5 4Z"
        fill="white"
      />
      <path
        d="M4 0L5.1936 2.8064L8 4L5.1936 5.1936L4 8L2.8064 5.1936L0 4L2.8064 2.8064L4 0Z"
        fill="white"
        fillOpacity="0.8"
      />
      <path
        d="M3 11L3.8952 13.1048L6 14L3.8952 14.8952L3 17L2.1048 14.8952L0 14L2.1048 13.1048L3 11Z"
        fill="white"
        fillOpacity="0.5"
      />
    </svg>
  );
};

export default memo(AiSummaryIcon);
