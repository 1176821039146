import { IParams } from "redux/interfaces";
import { http } from "utils/client";
import { GET_ORGANIZATIONS } from "./organization.endpoints";
import { IOrganization } from "redux/components/Auth";
import { AxiosResponse } from "axios";

// will move to the source folder once its redux is created.
export interface IOrganizationResponse {
  data: IOrganization[] | any;
  success: boolean;
  message: string;
}
/**
 * Fetches organizations by keyword.
 *
 * @param params - Optional parameters for the request.
 * @returns A Promise that resolves to the organization data.
 * @throws If an error occurs during the request.
 */
export const fetchOrganizations = async (
  params?: IParams,
): Promise<IOrganizationResponse> => {
  try {
    const response: AxiosResponse<IOrganizationResponse> = await http.get(
      `${GET_ORGANIZATIONS}`,
      params,
    );
    return response.data;
  } catch (ex) {
    throw ex;
  }
};
