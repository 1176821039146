import { Circle, Path, Rect, Svg } from "@react-pdf/renderer";

const EventSkippedIconPdf: React.FC = () => {
  return (
    <Svg
      width="11"
      height="11"
      viewBox="0 0 24 25"
      fill="none"
      // xmlns="http://www.w3.org/2000/Svg"
    >
      <Rect width="22" height="23" rx="4" y={1} x={1} fill="#7047E3" />
      <Circle cx="12.5" cy="12.5" r="7.5" fill="#8C66F5" />
      <Path
        d="M6.5 11.85C6.14101 11.85 5.85 12.141 5.85 12.5C5.85 12.859 6.14101 13.15 6.5 13.15V11.85ZM17.4596 12.9596C17.7135 12.7058 17.7135 12.2942 17.4596 12.0404L13.323 7.90381C13.0692 7.64997 12.6576 7.64997 12.4038 7.90381C12.15 8.15765 12.15 8.5692 12.4038 8.82304L16.0808 12.5L12.4038 16.177C12.15 16.4308 12.15 16.8424 12.4038 17.0962C12.6576 17.35 13.0692 17.35 13.323 17.0962L17.4596 12.9596ZM6.5 13.15L17 13.15V11.85L6.5 11.85V13.15Z"
        fill="white"
      />
    </Svg>
  );
};

export default EventSkippedIconPdf;
