import { ITasksState } from "./tasks.interface";

export const tasksInitialState: ITasksState = {
  fetching: false,
  data: [],
  message: "",
  success: false,
  meta: {
    totalItems: 0,
    totalPages: 0,
    currentPage: 1,
    itemCount: 0,
    itemsPerPage: 100,
  },
  pendingCounts: 0,
  skippedCounts: 0,
  completedCounts: 0,
  waitingForReviewCounts: 0,
  todayTask: [],
};
