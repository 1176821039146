import { IReduxActionResponse } from "redux/interfaces";
import {
  IDropdownMasterProject,
  IMasterProjectResponse,
} from "../MasterProject.interface";
import { masterProjectInitialState } from "../MasterProject.initialState";

export const getMasterProjects = (
  state: any,
  action: IReduxActionResponse<IMasterProjectResponse>,
) => {
  const { payload } = action;
  return {
    ...state,
    data: payload?.data,
    meta: payload?.meta,
    message: payload?.message,
  };
};

export const setDropdownMasterProjects = (
  state: any,
  action: IReduxActionResponse<IDropdownMasterProject[]>,
) => {
  const { payload } = action;
  return {
    ...state,
    dropdownMasterProjects: payload,
  };
};

export const resetMasterProjectState = (
  state: any,
  action: IReduxActionResponse<any>,
) => {
  return {
    ...masterProjectInitialState,
  };
};
