import { Space, Tag } from "antd";
import { ApproverIcon } from "components/icons";
import AlectifyText from "static/texts.json";

const ApproverTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#FFB310"} className="round-tag">
      <Space size={3} align="center">
        <ApproverIcon /> {text || AlectifyText.APPROVER}
      </Space>
    </Tag>
  );
};

export default ApproverTag;
