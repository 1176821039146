import { Tooltip } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import { IAlectifyDownloadProps } from "./AlecitfyDownloadDoucment.interface";
import { downloadSingleFile } from "services/documents/documents.service";

const AlectifyDownloadDocument: React.FC<IAlectifyDownloadProps> = (
  props: IAlectifyDownloadProps,
) => {
  const { file_name, file_path } = props;
  return (
    <Tooltip title={"Download"}>
      <CloudDownloadOutlined
        className="alectify-table-download-icon"
        onClick={async () => {
          await downloadSingleFile(file_path, file_name);
        }}
      />
    </Tooltip>
  );
};

export default AlectifyDownloadDocument;
