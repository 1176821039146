export const contactsTableConstants = {
  NAME: "Name",
  EMAIL: "Email",
  CONTACT_NUMBER: "Contact Number",
  ORGANIZATION: "Organization",
  ACCESS_EXPRIE_DATE: "Access Expiry Date",
  USERTYPE: "User Type",
  ACTIONS: "Actions",
  DEFAULT_IMAGE:
    "https://static.vecteezy.com/system/resources/previews/026/434/409/non_2x/default-avatar-profile-icon-social-media-user-photo-vector.jpg",
};
