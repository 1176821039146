import { Space, Tag } from "antd";
import AssigneeIcon from "components/icons/AssigneeIcon";
import AlectifyText from "static/texts.json";

const AssigneeTag: React.FC<{ text?: string }> = ({ text }) => {
  return (
    <Tag color={"#27A0FD"} className="round-tag">
      <Space size={3} align="center">
        <AssigneeIcon /> {text || AlectifyText.ASSIGNEE}
      </Space>
    </Tag>
  );
};

export default AssigneeTag;
