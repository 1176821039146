import { useState } from "react";
import { Form, Spin, message } from "antd";
import { UserTypeEnum } from "enums";
import { debounce, isEmpty } from "lodash";
import {
  IAddUserFormInitialState,
  IAddUserFormProps,
} from "./AddUserForm.interface";
import { IParams } from "redux/interfaces";
import { MESSAGES } from "constants/messages";
import { useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { validateEmail } from "services/user/user.services";
import { IOrganization } from "redux/components/Auth";
import { fetchOrganizations } from "services/organization/organization.service";
import AlectifyText from "static/texts.json";
import AlectifyInput from "../input";
import AlectifySelect from "../select";
import AlectifyAutoComplete from "../autocomplete";

const AddUserForm: React.FC<IAddUserFormProps> = (props: IAddUserFormProps) => {
  const auth = useSelector((state: IRootState) => state.auth);
  const [state, setState] = useState<IAddUserFormInitialState>({
    loading: false,
    user: null,
    organizations: null,
  });

  const validateUser = async (e: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const email = e.target.value;
      if (email === auth.user.email) {
        return;
      }
      setState((prevState) => ({
        ...prevState,
        loading: true,
      }));

      const user = await validateEmail({ email });
      if (!isEmpty(user.data)) {
        message.success(user.message);
        props.form.setFieldsValue({
          organization_name: user.data.organization?.name || "",
          organization: user.data.organization?.id,
          first_name: user.data.first_name || "",
          last_name: user.data.last_name || "",
          user_type: user.data.user_type || "",
        });
        props.existingUserRef.current = user.data;
        setState((prevState) => ({
          ...prevState,
          user: { ...user.data },
          loading: false,
        }));
      } else {
        props.form.resetFields();
        props.form.setFieldValue("email", email);
        props.existingUserRef.current = undefined;
        setState((prevState) => ({
          ...prevState,
          user: null,
          loading: false,
        }));
      }
    } catch (ex) {
      setState((prevState) => ({
        ...prevState,
        loading: false,
      }));
    }
  };

  const getOrganizations = debounce(async (value) => {
    setState({
      ...state,
      loading: true,
    });
    try {
      const params: IParams = {
        search: value,
      };
      const response = await fetchOrganizations(params);
      setState({
        ...state,
        loading: false,
        organizations: response.data,
      });
    } catch (error) {
      setState({
        ...state,
        loading: false,
      });
      console.log(error);
    }
  }, 500);

  const onOrganizationSelect = (org: IOrganization) => {
    if (props.organizationRef) {
      props.organizationRef.current = org;
    }
  };

  const validateOwnerEmail = (_: any, value: string) => {
    if (value === auth.user.email) {
      return Promise.reject("Can't add your own email");
    }
    return Promise.resolve();
  };

  return (
    <Spin spinning={state.loading}>
      <Form name="add-user-form" layout="vertical" form={props.form}>
        <AlectifyInput
          name="email"
          type="email"
          placeholder="Email address"
          disabled={
            !isEmpty(props.existingUserRef.current) || !isEmpty(state?.user)
          }
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                "Email",
              ),
            },
            {
              message: "Can't add your own email",
              validator: validateOwnerEmail,
            },
          ]}
          label="Email Address"
          onBlur={validateUser}
        />
        <AlectifyInput
          name="first_name"
          type="text"
          placeholder="eg: John."
          label={AlectifyText.FIRST_NAME}
          disabled={!isEmpty(state?.user)}
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                AlectifyText.FIRST_NAME,
              ),
            },
          ]}
        />
        <AlectifyInput
          name="last_name"
          type="text"
          placeholder="eg: Doe."
          label={AlectifyText.LAST_NAME}
          disabled={!isEmpty(state?.user)}
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                AlectifyText.LAST_NAME,
              ),
            },
          ]}
        />
        <Form.Item
          name="organization_name"
          label={AlectifyText.ORGANIZATION_NAME}
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                AlectifyText.ORGANIZATION_NAME,
              ),
            },
          ]}
        >
          <AlectifyAutoComplete
            placeholder="Search organization name"
            disabled={!isEmpty(state?.user)}
            onSearch={getOrganizations}
            onSelect={(event, record) => onOrganizationSelect(record as any)}
            options={
              state.organizations?.length &&
              (state.organizations.map((org: IOrganization) => {
                return {
                  ...org,
                  label: org.name,
                  name: org.name,
                  value: org.name,
                };
              }) as any)
            }
          />
        </Form.Item>
        <AlectifySelect
          name="user_type"
          label={AlectifyText.USER_TYPE}
          placeholder="Select user type"
          disabled={
            !isEmpty(props.existingUserRef.current) || !isEmpty(state?.user)
          }
          options={[
            {
              label: "Admin",
              value: UserTypeEnum.ADMIN,
            },
            {
              label: "Internal",
              value: UserTypeEnum.INTERNAL,
            },
            {
              label: "External",
              value: UserTypeEnum.EXTERNAL,
            },
          ]}
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                AlectifyText.USER_TYPE,
              ),
            },
          ]}
        />
      </Form>
    </Spin>
  );
};

export default AddUserForm;
