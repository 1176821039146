import { FormInstance } from "antd";
import {
  IProcedure,
  IProcedureCreationPayload,
} from "services/procedures/procedures.interface";

/**
 * Creates Payload for procedure creation out of form values and checklist.
 * @param { any } formValues - Ant Design form object.
 * @param { IProcedureCheckList[] } checkList - Procedure Library steps.
 * @returns { IProcedureCreationPayload }
 */
export const createProcedureRequestPayload = (
  formValues: any,
): IProcedureCreationPayload => {
  const payload: IProcedureCreationPayload = {
    jobType: formValues.jobType,
    name: formValues.title,
    reference: formValues.reference || "",
    comments: Boolean(formValues.comments),
    fileUpload: Boolean(formValues.fileUpload),
    description: formValues.description || "",
    categoryName: formValues.category || "",
    categoryId: formValues.categoryId || null,
    projectId: formValues.masterProject || null,
  };
  return payload;
};

/**
 * Initialize form fields in case of update/edit.
 * @param { IProcedure } procedure - Procedure object from response.
 * @returns
 */
export const initializeFormValues = (
  procedure: IProcedure,
  form: FormInstance,
) => {
  return {
    jobType: procedure.jobType,
    title: procedure.name,
    reference: procedure.reference,
    comments: Boolean(procedure.comments),
    fileUpload: Boolean(procedure.fileUpload),
    description: procedure.description,
    category: procedure.procedureCategory.name,
    masterProject: procedure.project?.id || undefined,
  };
};
