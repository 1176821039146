import { isEmpty } from "lodash";
import React, { memo, useEffect, useState } from "react";
import { Form, Space, Spin, message } from "antd";
import { MESSAGES } from "constants/messages";
import AlectifyInput from "components/shared/input";
import AlectifyButton from "components/shared/button";
import ModalServiceInstance from "components/shared/CaaS/modal/ModalService";
import AlectifyModal from "components/shared/modal";
import { MODAL_NAMES } from "constants/modal.constants";
import AlectifySelect from "components/shared/select";
import AlectifyText from "static/texts.json";
import { ICreateBranchModal, IStateCreateBranch } from "./Admin.interface";
import {
  createCompaniesBranches,
  editBranch,
  fetchCompaniesBranches,
} from "services/admin/admin.service";

const CreateBranchModal: React.FC<ICreateBranchModal> = ({
  companies,
  editRecord,
  getUsers,
}) => {
  const [form] = Form.useForm();

  const [state, setState] = useState<IStateCreateBranch>({
    branches: {
      data: [],
      fetching: false,
    },
    activeUser: null,
  });

  const onCloseModal = () => {
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.CREATE_BRANCH,
    });
  };

  const getBranches = async (companyId: string) => {
    try {
      const params = {
        company: companyId,
      };
      setState((prevData) => ({
        ...prevData,
        branches: {
          ...prevData.branches,
          fetching: true,
        },
      }));
      const response = await fetchCompaniesBranches(params);
      setState((prevData) => ({
        ...prevData,
        branches: {
          data: response.data,
          fetching: false,
        },
      }));
    } catch (error) {
      setState((prevData) => ({
        ...prevData,
        branches: {
          ...prevData.branches,
          fetching: false,
        },
      }));
      console.log(error);
    }
  };

  const handleCompanyChange = (companyId: string) => {
    form.setFieldValue("branch", null);
    getBranches(companyId);
  };

  const onSubmit = async (values: any) => {
    setState((prevData) => ({
      ...prevData,
      branches: {
        ...prevData.branches,
        fetching: true,
      },
    }));
    try {
      if (!isEmpty(editRecord)) {
        updateBranch(values);
      } else {
        createBranch(values);
      }
    } catch (ex) {
      console.log(ex);
      message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
      setState((prevData) => ({
        ...prevData,
        branches: {
          ...prevData.branches,
          fetching: false,
        },
      }));
    } finally {
      onCloseModal();
    }
  };

  const initialRecord = (record: any) => {
    setState((prevData) => ({
      ...prevData,
      activeUser: record,
    }));
    form.setFieldValue("branch", record.branch.id);
    form.setFieldValue("company", record?.branch?.company?.id);
  };

  const updateBranch = async (values: any) => {
    try {
      const payload = {
        branch: values?.branch,
        user: !isEmpty(state?.activeUser?.id) ? state?.activeUser?.id : null,
      };

      await editBranch(payload);
      message.success(AlectifyText.BRANCH_UPDATED);
      getUsers();
    } catch (error) {
      console.log(error);
    }
  };

  const createBranch = async (values: any) => {
    try {
      const payload = {
        company: values?.company,
        name: values?.name,
      };
      await createCompaniesBranches(payload);
      message.success(AlectifyText.BRANCH_CREATED);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (editRecord) {
      initialRecord(editRecord);
      getBranches(editRecord?.branch?.company?.id);
    }
  }, [editRecord]);

  return (
    <Spin spinning={state.branches.fetching}>
      <Form form={form} name="basic" layout="vertical" onFinish={onSubmit}>
        <AlectifySelect
          label="Company Name"
          name="company"
          defaultValue={editRecord?.branch?.company?.id}
          onChange={handleCompanyChange}
          options={companies?.map((company) => ({
            value: company?.id,
            label: company?.name,
          }))}
          rules={[
            {
              required: true,
              message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
            },
          ]}
        />

        {state?.activeUser ? (
          <AlectifySelect
            label="Branch Name"
            name="branch"
            options={state?.branches?.data?.map((branch: any) => ({
              value: branch?.id,
              label: branch?.name,
            }))}
            rules={[
              {
                required: true,
                message: MESSAGES.ADMIN_MESSAGES.COMPANY_NAME_REQUIRED,
              },
            ]}
          />
        ) : (
          <AlectifyInput
            label="Branch Name"
            name="name"
            type="text"
            rules={[
              {
                required: true,
                message: MESSAGES.ADMIN_MESSAGES.BRANCH_NAME_REQUIRED,
              },
            ]}
            placeholder="Company name"
          />
        )}
        <Space className="d-flex justify-flex-end">
          <AlectifyButton text="Cancel" type="primary" onClick={onCloseModal} />
          <AlectifyButton text="Submit" type="primary" htmlType="submit" />
        </Space>
      </Form>
    </Spin>
  );
};
export default memo(CreateBranchModal);
