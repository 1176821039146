import React, { useRef, useState, useEffect } from "react";
import { IAlectifyDescriptionViewProps } from "./AlectifyDescriptionView.interface";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyModal from "../modal";
import { MODAL_NAMES } from "constants/modal.constants";
import "./AlectifyDescriptionView.scss";
import MaximizeIcon from "components/icons/MaximizeIcon";

const AlectifyDescription: React.FC<IAlectifyDescriptionViewProps> = (
  props: IAlectifyDescriptionViewProps,
) => {
  const descriptionRef = useRef<HTMLDivElement>(null);
  const [isOverflowing, setIsOverflowing] = useState(false);

  useEffect(() => {
    const checkOverflow = () => {
      if (descriptionRef.current) {
        const hasOverflow =
          descriptionRef.current.scrollHeight >
          descriptionRef.current.clientHeight;
        setIsOverflowing(hasOverflow);
      }
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => {
      window.removeEventListener("resize", checkOverflow);
    };
  }, [props.detail, props.height]);

  const openDescriptionModal = () => {
    ModalServiceInstance.open(AlectifyModal, {
      name: MODAL_NAMES.ALECTIFY_DESCRIPTION_MODAL,
      title: "Description",
      width: 800,
      onCancel: () =>
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.ALECTIFY_DESCRIPTION_MODAL,
        }),
      footer: null,
      children: (
        <div
          className="alectify-description-modal-body"
          dangerouslySetInnerHTML={
            (props.detail && { __html: props.detail }) || { __html: "</>" }
          }
        />
      ),
    });
  };

  return (
    <div className="description-container">
      <div
        className="alectify-description-view-body"
        ref={descriptionRef}
        style={{
          height: props.height,
          maxHeight: "850px",
        }}
        dangerouslySetInnerHTML={
          (props.detail && { __html: props.detail }) || { __html: "</>" }
        }
      />

      {isOverflowing && (
        <div className="more-container" onClick={openDescriptionModal}>
          <div className="more-button">
            <MaximizeIcon />
          </div>
        </div>
      )}
    </div>
  );
};

export default AlectifyDescription;
