import { AxiosResponse } from "axios";
import { GET_PREDICTIONS, GET_PREDICTIONS_GRAPH } from "./prediction.endpoints";
import { http } from "utils/client";
import {
  IPredictionGraphResponse,
  IPredictionResponse,
} from "./prediction.interface";

/**
 * This function fetches prediction data from a specified API endpoint.
 * @param {Object} params - Optional parameters for the prediction request.
 * @returns {Promise<IPredictionResponse>} A Promise that resolves to the prediction response.
 * @throws {Error} Throws an error if there's an issue during the API request.
 */
export const fetchPredictionData = async (
  params?: object,
): Promise<IPredictionResponse> => {
  try {
    const response: AxiosResponse<IPredictionResponse> = await http.get(
      GET_PREDICTIONS,
      params,
    );
    return response?.data;
  } catch (error) {
    throw error;
  }
};
/**
 * This function fetches prediction graph data from a specified API endpoint.
 * It takes two parameters - masterProjectId and objectId - which are used to construct the API URL.
 * @param {string} masterProjectId - The identifier for the master project associated with the prediction data.
 * @param {string} objectId - The identifier for the specific object or entity for which predictions are requested.
 * @returns {Promise<IPredictionGraphResponse>} A Promise that resolves to the prediction graph response.
 * @throws {Error} Throws an error if there's an issue during the API request.
 */
export const fetchPredictionGraphData = async (
  masterProjectId: string,
  objectId: string,
  objectType: string,
  params: object,
): Promise<IPredictionGraphResponse> => {
  try {
    const url = GET_PREDICTIONS_GRAPH.replace(
      "{masterProjectId}",
      masterProjectId,
    )
      .replace("{objectId}", objectId)
      .replace("{objectType}", objectType);

    const response: AxiosResponse<IPredictionGraphResponse> = await http.get(
      url,
      params,
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};
