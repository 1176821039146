export const ADMIN_COMPANIES = `/admin/companies/`;
export const COMPANIES_BRANCH = `/admin/companies/branches/`;
export const ROLES = `/roles/`;
export const TYPES = `/organization-types/`;
export const CREATE_BRANCH_ADMIN = `/admin/companies/branches/admin/`;
export const BRANCH_FEATURES = `/admin/companies/branches/features/`;
export const UPDATE_FEATURES = `/admin/companies/branches/features/{featureId}/`;
export const PROJECT_ADMIN = `/projects/admin/`;
export const USERS = `/admin/users/`;
export const SWITCH_BRANCH = `/user/switch-branch/`;
export const USER_TYPE = `/user/{userId}/user-permission/`;
export const SOFT_DELETE = `projects/{projectId}/soft-delete/`;
