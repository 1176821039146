import { memo } from "react";

const ExternalUserIcon: React.FC<{
  className?: string;
  onClick?: () => void;
}> = (props) => {
  return (
    <svg
      {...props}
      width="42"
      height="42"
      viewBox="0 0 42 42"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="42" height="42" rx="21" fill="#FFE6E6" />
      <path
        d="M11.5865 21.5044C11.5783 21.4282 11.514 21.3705 11.4374 21.3705H10.582C10.5394 21.3705 10.4989 21.3886 10.4704 21.4203C10.442 21.452 10.4283 21.4943 10.4329 21.5367C10.6735 23.759 11.5427 25.8693 12.8096 27.427C14.0761 28.9841 15.7511 30.0007 17.601 30.0007C19.4535 30.0007 21.0047 28.9809 22.1462 27.421C23.2869 25.862 24.0279 23.7531 24.2677 21.537C24.2723 21.4946 24.2586 21.4523 24.2302 21.4206C24.2017 21.3889 24.1611 21.3708 24.1186 21.3708H23.6172C23.5406 21.3708 23.4763 21.4285 23.4681 21.5047C23.2616 23.409 22.5525 25.3273 21.5106 26.7659C20.4674 28.2065 19.1075 29.1453 17.601 29.1453C16.0933 29.1453 14.6961 28.2051 13.616 26.7638C12.5376 25.3247 11.7926 23.4067 11.5865 21.5044Z"
        fill="#FF5656"
        stroke="#FF5656"
        strokeWidth="0.3"
        strokeLinejoin="round"
      />
      <path
        d="M9.59396 21.5204H25.1073C25.7122 21.5204 26.2016 20.9728 26.2016 20.2974C26.2016 19.6216 25.7122 19.074 25.1073 19.074H24.8095C24.8095 15.8244 23.1445 13.0111 20.7185 11.6349L20.1003 15.5526C20.0516 15.8628 19.8108 16.0809 19.5379 16.0809C19.5052 16.0809 19.4716 16.0779 19.438 16.0718C19.127 16.0102 18.9197 15.6791 18.9744 15.3313L19.6804 10.8553C19.4154 10.3819 18.9455 10.0664 18.4089 10.0664H16.292C15.7561 10.0664 15.2866 10.3819 15.0212 10.8553L15.7272 15.3313C15.7823 15.6791 15.5746 16.0107 15.2636 16.0718C15.23 16.0783 15.1965 16.0809 15.1637 16.0809C14.8909 16.0809 14.6493 15.8632 14.6013 15.5526L13.9831 11.6354C11.5559 13.0106 9.89213 15.8244 9.89213 19.074H9.59396C8.98941 19.074 8.5 19.6216 8.5 20.2974C8.5 20.9728 8.98941 21.5204 9.59396 21.5204Z"
        fill="#FF5656"
      />
      <path
        d="M10.582 31.9331C12.5617 30.3616 18.2489 28.1614 25.1598 31.9331"
        stroke="#FF5656"
        strokeWidth="1.3"
        strokeLinecap="round"
      />
      <path
        d="M30.3611 23.6129L33.4908 26.7239L30.3799 29.8536"
        stroke="#FF5656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M32.459 26.7305L25.1701 26.7305"
        stroke="#FF5656"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(ExternalUserIcon);
