import { memo } from "react";

const AssigneeIcon: React.FC = () => {
  return (
    <svg
      width="9"
      height="11"
      viewBox="0 0 9 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="Group 28003">
        <path
          id="Vector"
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.96382 2.35714C1.96382 1.73199 2.21216 1.13244 2.65421 0.690391C3.09626 0.248341 3.69581 0 4.32096 0C4.94612 0 5.54566 0.248341 5.98771 0.690391C6.42976 1.13244 6.6781 1.73199 6.6781 2.35714C6.6781 2.98229 6.42976 3.58184 5.98771 4.02389C5.54566 4.46594 4.94612 4.71428 4.32096 4.71428C3.69581 4.71428 3.09626 4.46594 2.65421 4.02389C2.21216 3.58184 1.96382 2.98229 1.96382 2.35714ZM6.25446e-05 9.74547C0.0177255 8.61115 0.480731 7.52926 1.28914 6.73335C2.09754 5.93743 3.1865 5.49133 4.32096 5.49133C5.45542 5.49133 6.54439 5.93743 7.35279 6.73335C8.1612 7.52926 8.6242 8.61115 8.64186 9.74547C8.64323 9.82183 8.6223 9.89694 8.58165 9.96159C8.54099 10.0262 8.48237 10.0777 8.41296 10.1095C7.12919 10.6981 5.73324 11.0019 4.32096 11C2.86163 11 1.47511 10.6815 0.228967 10.1095C0.159555 10.0777 0.100935 10.0262 0.0602807 9.96159C0.0196262 9.89694 -0.00130001 9.82183 6.25446e-05 9.74547Z"
          fill="white"
        />
      </g>
    </svg>
  );
};

export default memo(AssigneeIcon);
