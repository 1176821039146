import moment from "moment";
import { isEmpty, isNaN } from "lodash";
import { useEffect, useState } from "react";
import { Col, Form, FormInstance, message, Row, Spin, Typography } from "antd";
import { MESSAGES } from "constants/messages";
import { MODAL_NAMES } from "constants/modal.constants";
import { renderAmount } from "utils/helpers";
import { IProjectSparePart } from "redux/components/spare-parts";
import { createSparePartOrder } from "services/spare-parts/spare-parts.service";
import { BASIC_REQUIRED_VALIDATION_RULES } from "constants/form-rules.constant";
import AlectifyText from "static/texts.json";
import AlectifyInput from "../input";
import AlectifyModal from "../modal";
import AlectifyButton from "../button";
import SparePartsTable from "../spare-parts-table";
import AlectifyDatePicker from "../datepicker";
import AlectifyInputNumber from "../input-number";
import ModalServiceInstance from "../CaaS/modal/ModalService";
import AlectifyQuantityInput from "../quantity-input";

interface Props {
  form: FormInstance;
  fetchSpareParts: () => void;
  sparePart?: IProjectSparePart;
}

const OrderSparePartForm: React.FC<Props> = (props: Props) => {
  const { form: FormInstance } = props;
  const [totalPrice, setTotalPrice] = useState<number | null>(0);
  const [submitting, setSubmitting] = useState(false);
  const [selectedSparePart, setSelectedSparePart] =
    useState<IProjectSparePart>();
  const { sparePart } = props;

  const onSubmit = async (values: any) => {
    try {
      if (submitting) return;
      if (values.quantity === "0") {
        FormInstance.setFields([
          {
            name: "quantity",
            errors: ["Order quantity should be greater than 0"],
          },
        ]);
        return;
      }
      setSubmitting(true);
      await createSparePartOrder(values.sparePartId, {
        projectId: selectedSparePart?.project.id,
        quantity: parseInt(values.quantity, 10),
        orderedDate: moment(values.orderDate).format(),
        poNumber: values.poNumber,
        comments: values.comments,
        unitPrice:
          typeof values.unitPrice === "string"
            ? parseInt(values.unitPrice, 10)
            : values.unitPrice,
      });
      props.fetchSpareParts();
      ModalServiceInstance.close(AlectifyModal, {
        name: MODAL_NAMES.SPARE_PARTS_CREATE_ORDER,
      });
      message.success("Order successfully submitted");
      setSubmitting(false);
    } catch (error) {
      setSubmitting(false);
    }
  };

  const onSelectSparePart = (sparePart: IProjectSparePart) => {
    props.fetchSpareParts();
    FormInstance.setFieldValue("sparePartId", sparePart.id);
    setSelectedSparePart(sparePart);
    ModalServiceInstance.close(AlectifyModal, {
      name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
    });
  };

  const openSparePartSelection = () => {
    ModalServiceInstance.open(AlectifyModal, {
      width: 1350,
      name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
      title: AlectifyText.SELECT_SPARE_PART,
      children: (
        <SparePartsTable
          selectRowOption
          disabledOrderedParts
          onRowSelect={onSelectSparePart}
          showSelectbutton
        />
      ),
      footer: null,
      onCancel: () => {
        props.fetchSpareParts();
        ModalServiceInstance.close(AlectifyModal, {
          name: MODAL_NAMES.SELECT_SPARE_PARTS_MODAL,
        });
      },
    });
  };

  useEffect(() => {
    if (!isEmpty(sparePart)) {
      FormInstance.setFieldValue("sparePartId", sparePart.id);
      const order = sparePart?.manageOrders[0];
      FormInstance.setFieldsValue({
        poNumber: order.poNumber,
        quantity: order.quantity.toString(),
        orderDate: moment(order.orderedDate),
        unitPrice: order.unitPrice,
        comments: order.comments,
      });
      setSelectedSparePart(sparePart);
      setTotalPrice(order.quantity * order.unitPrice);
    }
  }, [sparePart]);

  const onPriceChange = (unitPrice: number | null) => {
    if (unitPrice) {
      const quantity = parseInt(FormInstance.getFieldValue("quantity"));
      setTotalPrice(unitPrice * quantity);
    }
  };

  const onQuantityChange = (quantity: any) => {
    quantity = parseInt(quantity);
    const unitPrice = parseInt(FormInstance.getFieldValue("unitPrice"));
    if (quantity && unitPrice && !isNaN(unitPrice)) {
      setTotalPrice(quantity * unitPrice);
    }
  };

  return (
    <>
      <Spin spinning={submitting}>
        <Form
          name="orderSparePart"
          layout="vertical"
          form={FormInstance}
          onFinish={onSubmit}
          initialValues={{}}
          className="mb-30"
        >
          <Row gutter={15}>
            <Col span={24}>
              <Form.Item
                name="sparePartId"
                label={AlectifyText.PART_NUMBER}
                rules={[
                  {
                    required: true,
                    message:
                      MESSAGES.SPARE_PART_MESSAGES.PLEASE_SELECT_SPARE_PART,
                  },
                ]}
              >
                <AlectifyButton
                  text={
                    selectedSparePart?.sparePart?.partNumber ||
                    "Select Spare Part"
                  }
                  type="primary"
                  htmlType="button"
                  className="light-blue-button mt-5 alectify-task-creation-asset-selection-btn"
                  block
                  onClick={openSparePartSelection}
                  disabled={!isEmpty(sparePart)}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <AlectifyInput
                type="text"
                name="poNumber"
                label={AlectifyText["PURCHASE_ORDER#"]}
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
                placeholder="ex: 123456"
              />
            </Col>
            <Col span={12}>
              <Form.Item
                name="orderDate"
                label={AlectifyText.PO_DATE}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
              >
                <AlectifyDatePicker className="full-width alectify-datepicker-small" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <AlectifyQuantityInput
                placeholder=""
                name="quantity"
                form={FormInstance}
                label={AlectifyText.ORDER_QUANTITY}
                rules={BASIC_REQUIRED_VALIDATION_RULES}
                onChange={onQuantityChange}
              />
            </Col>
            <Col span={12}>
              <Form.Item
                name="unitPrice"
                label={
                  <>
                    {AlectifyText.UNIT_PRICE}
                    {/* <div className="form-label-extra">
                      <Typography.Text className="green-text">
                        {AlectifyText.TOTAL_PRICE_CALCULATED}:{" "}
                        {renderAmount(totalPrice)}
                      </Typography.Text>
                    </div> */}
                  </>
                }
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
              >
                <AlectifyInputNumber
                  placeholder="ex: 100"
                  prefix="$"
                  precision={2}
                  step={1}
                  onChange={onPriceChange}
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <AlectifyInput
                type="textarea"
                name="comments"
                label={AlectifyText.COMMENTS}
                // rules={BASIC_REQUIRED_VALIDATION_RULES}
                placeholder=""
                max={255}
              />
            </Col>
            <Col span={24}>
              <div className="grey-bg-wrapper order-calculated-price">
                <Typography.Title>
                  {AlectifyText.TOTAL_PRICE_CALCULATED}
                </Typography.Title>
                <Typography.Title>
                  {renderAmount(totalPrice, "standard")}
                </Typography.Title>
              </div>
            </Col>
          </Row>
        </Form>
      </Spin>
    </>
  );
};

export default OrderSparePartForm;
