import AssetsTable from "components/shared/assets-table";
import { useParams } from "react-router-dom";

const Assets = () => {
  const { subProjectId } = useParams();
  return (
    <AssetsTable
      gotoDetailPage
      subProjectId={subProjectId}
      scroll={{ y: "calc(100vh - 375px)" }}
      showExtraButton
      showSearch
      isDetailButton
    />
  );
};

export default Assets;
