import { memo } from "react";

const AiBotIcon: React.FC<any> = (props: any) => {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="25"
      viewBox="0 0 24 25"
      fill="none"
    >
      <path
        d="M4 16C2.89543 16 2 15.1046 2 14C2 12.8954 2.89543 12 4 12"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M20 16C21.1046 16 22 15.1046 22 14C22 12.8954 21.1046 12 20 12"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7 7.5V4.5"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M17 7.5V4.5"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M7 4.5C7.55228 4.5 8 4.05228 8 3.5C8 2.94772 7.55228 2.5 7 2.5C6.44772 2.5 6 2.94772 6 3.5C6 4.05228 6.44772 4.5 7 4.5Z"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M17 4.5C17.5523 4.5 18 4.05228 18 3.5C18 2.94772 17.5523 2.5 17 2.5C16.4477 2.5 16 2.94772 16 3.5C16 4.05228 16.4477 4.5 17 4.5Z"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M13.5 7.5H10.5C7.67157 7.5 6.25736 7.5 5.37868 8.40898C4.5 9.31796 4.5 10.7809 4.5 13.7069C4.5 16.6329 4.5 18.0958 5.37868 19.0048C6.25736 19.9138 7.67157 19.9138 10.5 19.9138H11.5253C12.3169 19.9138 12.5962 20.0773 13.1417 20.6713C13.745 21.3283 14.6791 22.205 15.5242 22.4091C16.7254 22.6994 16.8599 22.2979 16.5919 21.1531C16.5156 20.827 16.3252 20.3056 16.526 20.0018C16.6385 19.8316 16.8259 19.7898 17.2008 19.7061C17.7922 19.574 18.2798 19.3581 18.6213 19.0048C19.5 18.0958 19.5 16.6329 19.5 13.7069C19.5 10.7809 19.5 9.31796 18.6213 8.40898C17.7426 7.5 16.3284 7.5 13.5 7.5Z"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
      <path
        d="M9.5 15.5C10.0701 16.1072 10.9777 16.5 12 16.5C13.0223 16.5 13.9299 16.1072 14.5 15.5"
        stroke="#141B34"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.00896 11.5H9"
        stroke="#141B34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.009 11.5H15"
        stroke="#141B34"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(AiBotIcon);
