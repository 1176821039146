import { memo, useState } from "react";
import { MESSAGES } from "constants/messages";
import { WarningIcon } from "components/icons";
import { DocumentTypes } from "redux/components/documents";
import { Col, Form, FormInstance, message, Row, Spin, Typography } from "antd";
import { updateAndRestorePmDocuments } from "services/pm-internal/pm-internal.service";
import AlectifyInput from "../input";
import {
  restoreAssetDocument,
  restoreAssetPackageDocument,
  restoreProjectDocument,
} from "services/documents/documents.service";

interface IProps {
  form: FormInstance;
  fileName: string;
  comment: string;
  type: DocumentTypes;
  documentId: string;
  subProjectId: string;
  successCb: () => void;
}

const RestoreDocument = (props: IProps) => {
  const [submitting, setSubmitting] = useState(false);

  const onSubmit = async (values: { comment: string }) => {
    try {
      setSubmitting(true);
      const { comment } = values;
      const dto = { recoveredComment: comment };
      if (
        [
          DocumentTypes.PM_EXTERNAL,
          DocumentTypes.PM_INTERNAL,
          DocumentTypes.TASK,
        ].includes(props.type)
      ) {
        await updateAndRestorePmDocuments(props.documentId, dto);
      } else if (
        props.type === DocumentTypes.ASSET ||
        (props.type as any) === "TagDocument"
      ) {
        await restoreAssetDocument(props.subProjectId, props.documentId, dto);
      } else if (
        props.type === DocumentTypes.ASSET_PACKAGE ||
        (props.type as any) === "PackageRoomDocument"
      ) {
        await restoreAssetPackageDocument(
          props.subProjectId,
          props.documentId,
          dto,
        );
      } else if (props.type === DocumentTypes.PROJECT_DOCUMENT) {
        await restoreProjectDocument(props.subProjectId, props.documentId, dto);
      }
      props.successCb();
      setSubmitting(false);
      message.success("Restored Successfully");
    } catch (error) {
      console.log(error);
      setSubmitting(false);
    }
  };

  return (
    <Spin spinning={submitting}>
      <Row>
        <Col span={24} className="text-align-center">
          <WarningIcon />
        </Col>
        <Col span={24} className="text-align-center">
          <Typography.Paragraph>
            Are you sure want to restore this {props.fileName} document
          </Typography.Paragraph>
        </Col>
      </Row>
      <Form form={props.form} layout="vertical" onFinish={onSubmit}>
        <AlectifyInput
          name="comment"
          type="textarea"
          label="Comment"
          rules={[
            {
              required: true,
              message: MESSAGES.FIELD_RULES.REQUIRED.replace(
                "{fieldName}",
                "Comment",
              ),
            },
          ]}
        />
      </Form>
    </Spin>
  );
};

export default memo(RestoreDocument);
