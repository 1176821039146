import React, { useEffect, useState, useCallback } from "react";
import "./assetDetail.style.scss";
import AlectifyText from "static/texts.json";
import { Col, message, Row, Tooltip, Typography, Upload } from "antd";
import { NO_PHOTO_AVAILABLE } from "components/shared/assets-table/AssetsTable.constants";
import { displayDateTime, truncateText } from "utils/helpers";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "redux/rootReducer";
import { MESSAGES } from "constants/messages";
import { UploadAssetImage } from "services/assets/assets.service";
import { getAssetDetail } from "redux/components/assets/sources";
import { getAssetPackageDetail } from "redux/components/asset-packages/sources";
import { uploadAssetPackageImage } from "services/asset-packages/asset-packages.service";
import { useParams } from "react-router-dom";
import DrawerServiceInstance from "components/shared/CaaS/drawer/DrawerService";
import { DRAWER_CONSTANTS } from "constants/drawer.constants";
import AlectifyDrawer from "components/drawer";
import { CloseOutlined } from "@ant-design/icons";
import PackageDetailAssets from "components/shared/package-detail-assets";

const AssetDetailHeader: React.FC = () => {
  const dispatch = useDispatch();
  const { equipmentId, subProjectId, equipmentType } = useParams();
  const [equipmentDetail, setEquipmentDetail] = useState<any>(undefined);

  const { assetDetail } = useSelector(({ assets }: IRootState) => assets);
  const { assetPackageDetail } = useSelector(
    ({ assetPackage }: IRootState) => assetPackage,
  );
  useEffect(() => {
    if (equipmentType === "asset") {
      // if (assetDetail?.data) {
      //   if (!assetDetail || assetDetail?.data.id !== equipmentId) {
      //     dispatch(getAssetDetail(equipmentId, subProjectId));
      //   }
      // }
      setEquipmentDetail(assetDetail?.data);
    } else {
      // if (assetDetail?.data) {
      //   if (
      //     !assetPackageDetail ||
      //     assetPackageDetail?.data?.id !== equipmentId
      //   ) {
      //     dispatch(getAssetPackageDetail(subProjectId, equipmentId));
      //   }
      // }
      setEquipmentDetail(assetPackageDetail?.data);
    }
  }, [
    dispatch,
    equipmentId,
    subProjectId,
    equipmentType,
    assetDetail,
    assetPackageDetail,
  ]);

  const handleImageUpload = useCallback(
    async (file: any) => {
      const projectId = subProjectId;
      const id = equipmentDetail?.id;

      if (!id) return;

      message.loading(MESSAGES.FILE_UPLOAD_MESSAGES.IMAGE_UPLOADING);

      try {
        const uploadImage =
          equipmentType === "asset"
            ? UploadAssetImage
            : uploadAssetPackageImage;
        const response = await uploadImage(projectId, id, { image: file });

        if (response) {
          dispatch(
            equipmentType === "asset"
              ? getAssetDetail(id, subProjectId)
              : getAssetPackageDetail(projectId, id),
          );
          message.success(MESSAGES.FILE_UPLOAD_MESSAGES.ASSET_IMAGE_UPLOADED);
        }
      } catch (error) {
        message.error(MESSAGES.API_FAILURE.SOMETHING_WENT_WRONG);
        console.error(error);
      }
    },
    [dispatch, equipmentDetail, equipmentType, subProjectId],
  );

  const openPackageDetailAssets = useCallback(() => {
    DrawerServiceInstance.open(AlectifyDrawer, {
      width: 420,
      title: truncateText(equipmentDetail?.name || "", 20),
      name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
      closable: true,
      closeIcon: <CloseOutlined />,
      onClose: () =>
        DrawerServiceInstance.close(AlectifyDrawer, {
          name: DRAWER_CONSTANTS.DRAWER_ASSET_OR_ASSET_PACKAGE,
        }),
      children: (
        <PackageDetailAssets
          subProjectId={subProjectId || ""}
          assetPackageId={equipmentId || ""}
          assetDetailColumn={false}
        />
      ),
    });
  }, [equipmentDetail, equipmentId, subProjectId]);

  const renderImageSection = () => (
    <div className="image-card">
      <div
        style={{
          backgroundImage: `url(${
            equipmentDetail?.image || NO_PHOTO_AVAILABLE
          })`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          width: "100%",
          height: "160px",
          borderRadius: "10px",
        }}
      />

      <Upload
        maxCount={1}
        accept="image/*"
        showUploadList={false}
        beforeUpload={() => false}
        onChange={({ fileList }) => {
          const file = fileList[0]?.originFileObj;
          if (file) {
            handleImageUpload(file);
          }
        }}
      >
        <div className="edit-profile-text">
          {equipmentDetail?.image ? "Change Image" : "Add Image"}
        </div>
      </Upload>
    </div>
  );

  const renderDetailSection = () => (
    <Row className="box-container asset-detail-box">
      <Col span={12} className="border-bottom paddingBox ">
        <p>{AlectifyText.ITEM_NUMBER}</p>
        <span className="number-box">
          {equipmentDetail?.serial_number || "N/A"}
        </span>
      </Col>
      <Col span={12} className="border-left border-bottom paddingBox">
        <p>{AlectifyText.SITE}</p>
        <Tooltip title={equipmentDetail?.master_project_name}>
          <span className="number-box">
            {truncateText(equipmentDetail?.master_project_name || "N/A", 20)}
          </span>
        </Tooltip>
      </Col>
      <Col span={12} className="border-right paddingBox">
        <p>{AlectifyText.ASSET_CATEGORY}</p>
        <Tooltip title={equipmentDetail?.project_name}>
          <span className="number-box">
            {truncateText(equipmentDetail?.project_name || "N/A", 23)}
          </span>
        </Tooltip>
      </Col>
      <Col span={12} className="paddingBox">
        {equipmentType === "asset" ? (
          <>
            <p>Parent Asset</p>
            <Tooltip title={equipmentDetail?.packageroom?.name}>
              <span className="number-box">
                {truncateText(equipmentDetail?.packageroom?.name || "N/A", 25)}
              </span>
            </Tooltip>
          </>
        ) : (
          <>
            <p>Available Sub-Assets</p>
            <span
              onClick={openPackageDetailAssets}
              className={`number-box cursor-pointer ${
                equipmentDetail?.tags_count !== undefined
                  ? "step-number"
                  : "number-box"
              }`}
            >
              {equipmentDetail?.tags_count !== undefined
                ? equipmentDetail.tags_count.toString()
                : "N/A"}
            </span>
          </>
        )}
      </Col>
    </Row>
  );

  const renderDescriptionSection = () => (
    <div className="asset-detail-box-description">
      <Tooltip title={equipmentDetail?.description}>
        <Typography.Paragraph
          ellipsis={{ rows: 7 }}
          className="description-box"
        >
          {equipmentDetail?.description || "N/A"}
        </Typography.Paragraph>
      </Tooltip>
    </div>
  );

  const renderManufactureInfoSection = () => (
    <Row className="box-container manufacture-box">
      <Col span={8} className="paddingBox manufacture-box">
        <p>{AlectifyText.WARRANTY_DATE}</p>
        <span className="number-box">
          {displayDateTime(equipmentDetail?.warranty_date) || "N/A"}
        </span>
      </Col>
      <Col span={8} className="border-left border-right paddingBox">
        <p>{AlectifyText.INSTALLATION_DATE}</p>
        <span className="number-box">
          {displayDateTime(equipmentDetail?.installation_date) || "N/A"}
        </span>
      </Col>
      <Col span={8} className="paddingBox">
        <p>{AlectifyText.SERIAL_NUMBER}</p>
        <span className="number-box">
          {equipmentDetail?.model_serial_number || "N/A"}
        </span>
      </Col>
      <Col span={8} className="border-top paddingBox">
        <p>{AlectifyText.MODEL}</p>
        <span className="number-box">{equipmentDetail?.model || "N/A"}</span>
      </Col>
      <Col span={16} className="border-left paddingBox border-top">
        <p>{AlectifyText.MANUFACTURER}</p>
        <span className="number-box">
          {truncateText(equipmentDetail?.manufacture?.name || "N/A", 18)}
        </span>
      </Col>
    </Row>
  );

  const renderAdditionalDetailSection = () => (
    <Row className="box-container ">
      <Col span={8} className="border-bottom paddingBox">
        <p>{AlectifyText.ASSET_TYPE}</p>
        <span className="number-box">
          {equipmentDetail?.asset_type || "N/A"}
        </span>
      </Col>
      <Col
        span={8}
        className="border-left border-bottom border-right paddingBox"
      >
        <p>{AlectifyText.ASSET_STATUS}</p>
        <span className="number-box">{equipmentDetail?.status || "N/A"}</span>
      </Col>
      <Col span={8} className="border-bottom paddingBox">
        <p>{AlectifyText.GROUP}</p>
        <span className="number-box">{equipmentDetail?.group || "N/A"}</span>
      </Col>
      <Col span={8} className="paddingBox">
        <p>{AlectifyText.LOCATION}</p>
        <Tooltip title={equipmentDetail?.location}>
          <span className="number-box">
            {truncateText(equipmentDetail?.location || "N/A", 14)}
          </span>
        </Tooltip>
      </Col>
      <Col span={16} className="border-left paddingBox">
        <p>{AlectifyText.PREFERRED_SUPPLIER}</p>
        <Tooltip title={equipmentDetail?.preferred_supplier?.name}>
          <span className="number-box">
            {truncateText(
              equipmentDetail?.preferred_supplier?.name || "N/A",
              30,
            )}
          </span>
        </Tooltip>
      </Col>
    </Row>
  );

  return (
    <Row align="middle" gutter={16} className="asset-detail-header-main" wrap>
      <Col span={3}>{renderImageSection()}</Col>
      <Col span={4}>
        <Typography.Title level={5} className="mt-10 asset-sub-heading">
          Asset Detail
        </Typography.Title>
        {renderDetailSection()}
      </Col>
      <Col span={5}>
        <Typography.Title level={5} className="mt-10">
          {AlectifyText.DESCRIPTION}
        </Typography.Title>
        {renderDescriptionSection()}
      </Col>
      <Col span={6} className="manufacture-info-col">
        <Typography.Title level={5} className="mt-10">
          Manufacture Information
        </Typography.Title>
        {renderManufactureInfoSection()}
      </Col>
      <Col span={6}>
        <Typography.Title level={5} className="mt-10">
          Additional Detail
        </Typography.Title>
        {renderAdditionalDetailSection()}
      </Col>
    </Row>
  );
};

export default React.memo(AssetDetailHeader);
